import { ReactElement, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import {
  REACT_APP_MENU_HIDE_3DMAPS,
  REACT_APP_MENU_HIDE_AUTOWALK,
  REACT_APP_MENU_HIDE_MISSION,
  REACT_APP_MENU_HIDE_NAVIGATION_MAPS,
  REACT_APP_MENU_HIDE_RADIATION_MAP,
  REACT_APP_MENU_HIDE_SCHEDULE_MISSIONS,
} from 'lib/constants/env-variables';

import TestRoslibjs from './test/roslibjs/roslibjs';
import SignalRTest from './test/signalR-test';

const HomePage = lazy(() => import('./home/HomePage'));

const OverviewPage = lazy(() => import('./overview/Overview'));
const AutowalkListingPage = lazy(() => import('./autowalk/autowalk-listing/AutowalkListing'));
const AutowalkResultsPage = lazy(() => import('./autowalk/autowalk-results/AutowalkResults'));
const MissionsSchedulePage = lazy(() => import('./missions-schedule/MissionsSchedule'));
const NavigationMapsPage = lazy(() => import('./navigation-maps/NavigationMaps'));
const Maps3DPage = lazy(() => import('./maps-3d/Maps3D'));
const RadiationMapsPage = lazy(() => import('./radiation-maps/RadiationMaps'));
const CarScanListingPage = lazy(() => import('./car-scans/car-scans-listing/CarScansListing'));
const CarScanRunnerPage = lazy(() => import('./car-scans/car-scan-runner/CarScanRunner'));
const CarScanPlannerPage = lazy(() => import('./car-scans/car-scan-planner/CarScanPlanner'));
const ManualControlPage = lazy(() => import('./manual-control/ManualControlPage'));
const PayloadsPage = lazy(() => import('./payloads/Payloads'));
const TestRobotArmPage = lazy(() => import('./test/robot-arm'));
const DirectMethodsTest = lazy(() => import('./test/direct-methods'));
const AcquiredListingData = lazy(
  () => import('./acquired-data/acquired-data-listing/AcquiredDataListing'),
);
const TestSpotActionPage = lazy(() => import('./test/spot-action'));
const TestLocalization = lazy(() => import('./test/localization'));

type PathProps = { path: string; index?: never } | { index: boolean; path?: never };

type ElementProps =
  | { element: ReactElement<any>; children?: never }
  | { element?: never; children: (RouteType | RouteObject)[] };

export type RouteType = RouteObject &
  PathProps &
  ElementProps & {
    disabled?: boolean;
    title?: string;
  };

export const ROOT_ROUTES: Record<string, RouteType> = {
  HOME: {
    path: 'home',
    title: 'Home',
    element: <HomePage />,
  },
  ACQUIRED_DATA: {
    path: 'acquired-data',
    title: 'Acquired Data',
    children: [
      {
        index: true,
        element: <AcquiredListingData />,
      },
      {
        path: '*',
        element: <Navigate to=".." replace />,
      },
    ],
  },
  NAVIGATION_MAPS: {
    path: 'navigation-maps',
    title: 'Navigation Maps',
    element: <NavigationMapsPage />,
    disabled: REACT_APP_MENU_HIDE_NAVIGATION_MAPS == 'true',
  },
  MAPS_3D: {
    path: '3d-maps',
    title: '3D Maps',
    element: <Maps3DPage />,
    disabled: REACT_APP_MENU_HIDE_3DMAPS == 'true',
  },
  RADIATION: {
    path: 'radiation-maps',
    title: 'Radiation Maps',
    element: <RadiationMapsPage />,
    disabled: REACT_APP_MENU_HIDE_RADIATION_MAP == 'true',
  },
  HISTORY_MISSIONS: {
    path: 'history',
    title: 'History of Mission',
    element: <div />,
    disabled: true,
  },
  REDIRECT_TO_HOME: {
    path: '*',
    element: <Navigate to="/home" replace />,
  },
};

export const ROBOT_MANAGEMENT_ROUTES: Record<string, RouteType> = {
  OVERVIEW: {
    path: 'overview',
    title: 'Overview',
    element: <OverviewPage />,
  },
  MANUAL_CONTROL: {
    path: 'manual-control',
    title: 'Manual Control',
    element: <ManualControlPage />,
  },
  AUTOWALK: {
    path: 'autowalk-missions',
    title: 'Autowalk',
    disabled: REACT_APP_MENU_HIDE_AUTOWALK == 'true',
    children: [
      {
        index: true,
        element: <AutowalkListingPage />,
      },
      {
        path: ':autowalkId',
        element: <AutowalkResultsPage />,
      },
      {
        path: '*',
        element: <Navigate to=".." replace />,
      },
    ],
  },
  SCHEDULE_MISSIONS: {
    path: 'scheduled_missions',
    title: 'Scheduled Missions',
    disabled: REACT_APP_MENU_HIDE_SCHEDULE_MISSIONS == 'true',
    element: <MissionsSchedulePage />,
  },
  CAR_SCANS: {
    path: 'tuv/car-scans',
    title: 'Car Scans',
    disabled: REACT_APP_MENU_HIDE_MISSION == 'true',
    children: [
      {
        index: true,
        element: <CarScanListingPage />,
      },
      {
        path: 'planner',
        element: <CarScanPlannerPage />,
      },
      {
        path: ':missionPlanId',
        element: <CarScanRunnerPage />,
      },
      {
        path: '*',
        element: <Navigate to=".." replace />,
      },
    ],
  },
  PAYLOADS: {
    path: 'payloads',
    title: 'Payloads',
    element: <PayloadsPage />,
  },
  TESTS: {
    path: 'test-arm',
    title: 'Test Arm',
    element: <TestRobotArmPage />,
  },
  TEST: {
    path: 'test-signalr',
    title: 'Test',
    element: <SignalRTest />,
  },
  TEST_ROSLIBJS: {
    path: 'test-roslibjs',
    title: 'Test',
    element: <TestRoslibjs />,
  },
  TEST_ACTIONS: {
    path: 'test-spot-action',
    element: <TestSpotActionPage />,
  },
  TEST_DM: {
    path: 'test-dm',
    title: 'Test DMs',
    element: <DirectMethodsTest />,
  },
  TEST_TestLocalization: {
    path: 'test-localization',
    title: 'Test DMs',
    element: <TestLocalization />,
  },
  REDIRECT_TO_OVERVIEW: {
    path: '*',
    element: <Navigate to="overview" replace />,
  },
};
