import { useRecoilState, useSetRecoilState } from 'recoil';

import { useDeviceName } from 'store/my-robot';

import { selectedCameraSourceAtom } from './robot-control.atom';

export function useSelectedCameraSource() {
  const { deviceName } = useDeviceName();
  return useRecoilState(selectedCameraSourceAtom(deviceName));
}

export function useSetSelectedCameraSource() {
  const { deviceName } = useDeviceName();
  return useSetRecoilState(selectedCameraSourceAtom(deviceName));
}
