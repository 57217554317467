import React, { ReactElement, ReactNode, forwardRef } from 'react';
import { Helmet } from 'react-helmet';

import { SPage } from './Page.styled';

import PageHeader from './PageHeader';

/**
 * Page component
 * @param {String} tabTitle - Browser tab title
 * @param {PageHeader} pageHeader - Page header component
 * @param {Node} children - Any node
 */

type PageProps = {
  tabTitle?: string;
  pageHeader?: ReactElement<any>;
  children?: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const Page = Object.assign(
  forwardRef<HTMLDivElement, PageProps>(({ tabTitle, pageHeader, children, ...rest }, ref) => {
    const title = tabTitle || pageHeader?.props?.title;

    return (
      <SPage ref={ref} {...rest}>
        {title && (
          <Helmet>
            <title>Reply RoboticsPlatform Platform - {title}</title>
          </Helmet>
        )}
        {pageHeader && pageHeader}
        {children && children}
      </SPage>
    );
  }),
  {
    Header: PageHeader,
  },
);

export default Page;
