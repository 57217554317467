import { PropsWithChildren, createContext, useContext, useRef, useState } from 'react';
import { Id, toast } from 'react-toastify';

import { gamepadToast } from 'hooks/complex/gamepad/util/handlers';

type RobotArmLockedContextType = {
  locked: boolean;
  toggleArmLocked: VoidFunction;
  triggerArmLoggedToast: VoidFunction;
};

const RobotArmLockedContext = createContext<RobotArmLockedContextType | null>(null);

export function RobotArmLockedProvider({ children }: PropsWithChildren) {
  const [locked, setLocked] = useState<boolean>(true);
  const toastId = useRef<Id | null>(null);

  function toggleArmLocked() {
    setLocked((prev) => !prev);
  }

  function triggerArmLoggedToast() {
    if (toastId.current && toast.isActive(toastId.current)) return;

    toastId.current = gamepadToast('error', 'Arm is locked - press Y to unlock');
  }

  return (
    <RobotArmLockedContext.Provider value={{ locked, toggleArmLocked, triggerArmLoggedToast }}>
      {children}
    </RobotArmLockedContext.Provider>
  );
}

/**
 * The robot arm have to manually be unlocked to prevent accidental movement
 */
export function useRobotArmLocked(): RobotArmLockedContextType {
  const context = useContext(RobotArmLockedContext);
  if (!context) {
    throw new Error('useRobotArmLocked must be used within a RobotArmLockedProvider');
  }
  return context;
}
