import { Outlet } from 'react-router-dom';

import { LazyLoad } from 'components/common';
import Sidebar from 'components/complex/sidebar/Sidebar';

import { SMain, SPageContainer } from '../styled';

const LayoutHome = () => (
  <SMain>
    <Sidebar />
    <SPageContainer>
      <LazyLoad>
        <Outlet />
      </LazyLoad>
    </SPageContainer>
  </SMain>
);

export default LayoutHome;
