import { ROBOT_MANAGEMENT_ROUTES } from 'pages/routes-names';
import pluralize from 'pluralize';
import React, { useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { STypography } from 'components/ui';

import { updateToken } from 'lib/authProvider';

import ConfirmModal from '../confirm-modal/ConfirmModal';

const convertToMilliseconds = (minutes: number) => minutes * 60 * 1000;

// MINUTES
const tokenLife = 60;
const notificationPeriods = [2, 1]; // warning message in minutes until token expires

const timeout = convertToMilliseconds(tokenLife);
const promptBeforeIdle = convertToMilliseconds(notificationPeriods[0]);

const useSessionTimeout = () => {
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentMessage, setCurrentMessage] = useState<number>(notificationPeriods[0]);
  const [isOutdated, setIsOutdated] = useState<boolean>(false);
  const timers = useRef<Record<string, number | undefined>>({});
  const toastId = React.useRef<string | number | null>(null);

  const { pathname } = useLocation();

  const reloadPage = () => {
    window.location.reload();
  };

  const updateSession = async () => {
    setIsLoading(true);
    await updateToken()
      .then(() => {
        // Clean up
        setIsConfirmOpen(false);
        reset();
        cleanIdleTimers();
      })
      .catch((errMessage: string) => {
        console.log('errMessage', errMessage);
        toast.error(errMessage, {
          position: 'top-center',
          autoClose: 10 * 1000,
          pauseOnHover: false,
        });
      });
    setIsLoading(false);
  };

  const cleanIdleTimers = () => {
    toastId.current && toast.dismiss(toastId.current);
    Object.values(timers.current).forEach((timer) => {
      window.clearInterval(timer);
    });
    timers.current = {};
    toastId.current = null;
  };

  const idleHandler = () => {
    if (Object.keys(timers.current).length > 0) {
      cleanIdleTimers();
    }

    const isManualControl = pathname.includes(ROBOT_MANAGEMENT_ROUTES.MANUAL_CONTROL.path ?? '');

    // Show warning modal if in MC page
    if (!isManualControl) {
      setIsConfirmOpen(true);
    }

    notificationPeriods.forEach((period, periodIndex) => {
      timers.current[period] = window.setTimeout(() => {
        // Show warning toast | modal if in MC page
        if (isManualControl) {
          // Open modal if its last warning
          if (periodIndex === notificationPeriods.length - 1) {
            setIsConfirmOpen(true);
          } else {
            // Close old toast if it's still open
            toastId.current && toast.dismiss(toastId.current);

            // Show warning message otherwise
            toastId.current = toast.warning(
              `Your session is about to expire in ${period} ${pluralize('minute', period)}.`,
              {
                position: 'top-center',
                // autoClose: false,
                pauseOnHover: false,
              },
            );
          }
        }
        // Update timeout in modal
        setCurrentMessage(period);
      }, convertToMilliseconds(notificationPeriods[0] - period));
    });
  };

  // Restart idle timer & clean warning message timers
  const onAction = () => {
    if (!toastId.current) {
      reset();
      cleanIdleTimers();
    }
  };

  // Start showing warning messages
  const onPrompt = () => {
    if (!isOutdated) {
      idleHandler();
    }
  };

  // Reloads page so user will sign in again
  const onIdle = () => {
    setIsOutdated(true);
    pause();
    // navigate('/unauthorized');
    // TODO -> stop page render & all logics (redirect to some new route)
  };

  const {
    reset,
    pause,
    // start,
    // activate,
    // resume,
    // isIdle,
    // isPrompted,
    // isLeader,
    // isLastActiveTab,
    // getTabId,
    // getRemainingTime,
    // getElapsedTime,
    // getLastIdleTime,
    // getLastActiveTime,
    // getIdleTime,
    // getTotalIdleTime,
    // getActiveTime,
    // getTotalActiveTime,
  } = useIdleTimer({
    onAction,
    onPrompt,
    onIdle,
    // onActive,
    // onPresenceChange,
    timeout,
    promptBeforeIdle,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
      'focus',
    ],
    // immediateEvents: [],
    // debounce: 1000,
    throttle: 1000,
    // eventsThrottle: 2000, // Check
    // element: document,
    // startOnMount: true,
    // startManually: false,
    // stopOnIdle: false,
    // crossTab: false,
    // name: 'idle-timer',
    // syncTimers: 0,
    // leaderElection: false,
  });

  useEffect(() => {
    return () => {
      cleanIdleTimers();
    };
  }, []);

  const modal = (
    <ConfirmModal
      title="Warning"
      isOpen={isConfirmOpen || isOutdated}
      onConfirm={isOutdated ? reloadPage : updateSession}
      confirmText={isOutdated ? 'Refresh' : "I'm still here"}
      isConfirmLoading={isLoading}
    >
      <STypography variant="body2" align="center" wrap="pre-wrap">
        {isOutdated
          ? 'Session is expired. \n\nYou need to reload the page and might need to sign in again.'
          : `Due to inactivity your session will expire in ${currentMessage} ${pluralize(
              'minute',
              currentMessage,
            )}.\n\n
        You will need to reload the page and might need to sign in again.`}
      </STypography>
    </ConfirmModal>
  );

  return {
    isOutdated,
    modal,
  };
};

export default useSessionTimeout;
