import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { newNotificationsIndicatorAtom } from 'store/notifications/notifications.atom';

import { NotificationSeverity } from 'types/models/Notification';

export const useNewNotificationIndicator = () => {
  const [indicator, setIndicator] = useRecoilState(newNotificationsIndicatorAtom);

  const resetNotificationIndicator = useCallback(() => {
    if (!indicator) {
      return;
    }
    setIndicator(false);
  }, [indicator]);

  const setNewNotificationIndicator = useCallback(
    (severity: NotificationSeverity = NotificationSeverity.Info) => {
      if (indicator || severity !== NotificationSeverity.Error) {
        return;
      }
      setIndicator(true);
    },
    [indicator],
  );

  return {
    newNotificationIndicator: indicator,
    resetNotificationIndicator,
    setNewNotificationIndicator,
  };
};
