import { selector } from 'recoil';

import { deviceNameSelector } from 'store/my-robot/myRobot.selectors';

import { ManipulatorState_CarryState } from 'lib/generated/proto/bosdyn/api/robot_state';

import { manipulatorStateAtom } from './atoms';

export const manipulatorStateStowedSelector = selector({
  key: 'manipulatorStateStowedSelector',
  get: ({ get }) => {
    const deviceName = get(deviceNameSelector);
    const { stowState } = get(manipulatorStateAtom(deviceName));
    return { stowState };
  },
});

export const gripperOpeningPercentageSelector = selector<number>({
  key: 'gripperOpeningPercentageSelector',
  get: ({ get }) => {
    const deviceName = get(deviceNameSelector);
    const { gripperOpenPercentage } = get(manipulatorStateAtom(deviceName));
    return gripperOpenPercentage;
  },
});

export const gripperHoldingItemSelector = selector<boolean>({
  key: 'gripperHoldingItemSelector',
  get: ({ get }) => {
    const deviceName = get(deviceNameSelector);
    const { isGripperHoldingItem } = get(manipulatorStateAtom(deviceName));
    return isGripperHoldingItem;
  },
});

export const carryStateSelector = selector<ManipulatorState_CarryState>({
  key: 'carryStateSelector',
  get: ({ get }) => {
    const deviceName = get(deviceNameSelector);
    const { carryState } = get(manipulatorStateAtom(deviceName));
    return carryState;
  },
});
