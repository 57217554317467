/* eslint-disable */
// These files were generated using "ros-typescript-generator"
export interface IRosTypeActionMsgsCancelGoal {
  request: IRosTypeActionMsgsCancelGoalRequest;
  response: IRosTypeActionMsgsCancelGoalResponse;
}

export interface IRosTypeActionMsgsCancelGoalRequest {
  goal_info: IRosTypeActionMsgsGoalInfo;
}

export interface IRosTypeActionMsgsCancelGoalResponse {
  return_code: number;
  goals_canceling: IRosTypeActionMsgsGoalInfo[];
}

export enum IRosTypeActionMsgsCancelGoalResponseConst {
  ERROR_NONE = 0,
  ERROR_REJECTED = 1,
  ERROR_UNKNOWN_GOAL_ID = 2,
  ERROR_GOAL_TERMINATED = 3,
}

export interface IRosTypeActionMsgsGoalInfo {
  goal_id: IRosTypeUniqueIdentifierMsgsUuid;
  stamp: { sec: number; nanosec: number };
}

export interface IRosTypeActionMsgsGoalStatus {
  goal_info: IRosTypeActionMsgsGoalInfo;
  status: number;
}

export enum IRosTypeActionMsgsGoalStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_ACCEPTED = 1,
  STATUS_EXECUTING = 2,
  STATUS_CANCELING = 3,
  STATUS_SUCCEEDED = 4,
  STATUS_CANCELED = 5,
  STATUS_ABORTED = 6,
}

export interface IRosTypeActionMsgsGoalStatusArray {
  status_list: IRosTypeActionMsgsGoalStatus[];
}

export interface IRosTypeActionlibMsgsGoalId {
  stamp: { sec: number; nanosec: number };
  id: string;
}

export interface IRosTypeActionlibMsgsGoalStatus {
  goal_id: IRosTypeActionlibMsgsGoalId;
  status: number;
  text: string;
}

export enum IRosTypeActionlibMsgsGoalStatusConst {
  PENDING = 0,
  ACTIVE = 1,
  PREEMPTED = 2,
  SUCCEEDED = 3,
  ABORTED = 4,
  REJECTED = 5,
  PREEMPTING = 6,
  RECALLING = 7,
  RECALLED = 8,
  LOST = 9,
}

export interface IRosTypeActionlibMsgsGoalStatusArray {
  header: IRosTypeStdMsgsHeader;
  status_list: IRosTypeActionlibMsgsGoalStatus[];
}

export interface IRosTypeAzureIotInterfacesIotHubModuleConnectionState {
  module_name: string;
  connected: boolean;
}

export interface IRosTypeAzureIotInterfacesIotHubModuleConnectionStateArray {
  connection_states: IRosTypeAzureIotInterfacesIotHubModuleConnectionState[];
}

export interface IRosTypeBosdynApiMsgsAcquireDataRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  action_id: IRosTypeBosdynApiMsgsCaptureActionId;
  metadata: IRosTypeBosdynApiMsgsMetadata;
  acquisition_requests: IRosTypeBosdynApiMsgsAcquisitionRequestList;
  min_timeout: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsAcquireDataRequestConst {
  HEADER_FIELD_SET = 1,
  ACTION_ID_FIELD_SET = 2,
  METADATA_FIELD_SET = 4,
  ACQUISITION_REQUESTS_FIELD_SET = 8,
  MIN_TIMEOUT_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsAcquireDataResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsAcquireDataResponseStatus;
  request_id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsAcquireDataResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsAcquireDataResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsAcquireDataResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_UNKNOWN_CAPTURE_TYPE = 2,
}

export interface IRosTypeBosdynApiMsgsAcquireLeaseRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  resource: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsAcquireLeaseRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsAcquireLeaseResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsAcquireLeaseResponseStatus;
  lease: IRosTypeBosdynApiMsgsLease;
  lease_owner: IRosTypeBosdynApiMsgsLeaseOwner;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsAcquireLeaseResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 4,
  LEASE_OWNER_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsAcquireLeaseResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsAcquireLeaseResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_RESOURCE_ALREADY_CLAIMED = 2,
  STATUS_INVALID_RESOURCE = 3,
  STATUS_NOT_AUTHORITATIVE_SERVICE = 4,
}

export interface IRosTypeBosdynApiMsgsAcquirePluginDataRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  data_id: IRosTypeBosdynApiMsgsDataIdentifier[];
  metadata: IRosTypeBosdynApiMsgsMetadata;
  action_id: IRosTypeBosdynApiMsgsCaptureActionId;
  acquisition_requests: IRosTypeBosdynApiMsgsAcquisitionRequestList;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsAcquirePluginDataRequestConst {
  HEADER_FIELD_SET = 1,
  METADATA_FIELD_SET = 4,
  ACTION_ID_FIELD_SET = 8,
  ACQUISITION_REQUESTS_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsAcquirePluginDataResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsAcquirePluginDataResponseStatus;
  request_id: number;
  timeout_deadline: { sec: number; nanosec: number };
  custom_param_error: IRosTypeBosdynApiMsgsCustomParamError;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsAcquirePluginDataResponseConst {
  HEADER_FIELD_SET = 1,
  TIMEOUT_DEADLINE_FIELD_SET = 8,
  CUSTOM_PARAM_ERROR_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsAcquirePluginDataResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsAcquirePluginDataResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_UNKNOWN_CAPTURE_TYPE = 2,
  STATUS_CUSTOM_PARAMS_ERROR = 3,
}

export interface IRosTypeBosdynApiMsgsAcquisitionCapabilityList {
  data_sources: IRosTypeBosdynApiMsgsDataAcquisitionCapability[];
  image_sources: IRosTypeBosdynApiMsgsImageAcquisitionCapability[];
  network_compute_sources: IRosTypeBosdynApiMsgsNetworkComputeCapability[];
}

export interface IRosTypeBosdynApiMsgsAcquisitionRequestList {
  image_captures: IRosTypeBosdynApiMsgsImageSourceCapture[];
  data_captures: IRosTypeBosdynApiMsgsDataCapture[];
  network_compute_captures: IRosTypeBosdynApiMsgsNetworkComputeCapture[];
}

export interface IRosTypeBosdynApiMsgsActionIdQuery {
  action_ids: IRosTypeBosdynApiMsgsCaptureActionId[];
}

export interface IRosTypeBosdynApiMsgsAlertConditionSpec {
  alert_data: IRosTypeBosdynApiMsgsAlertData;
  type: IRosTypeBosdynApiMsgsAlertConditionSpecOneOfType;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsAlertConditionSpecConst {
  ALERT_DATA_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsAlertConditionSpecOneOfType {
  min: number;
  max: number;
  bounds: IRosTypeBosdynApiMsgsBounds;
  condition: boolean;
  type_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsAlertConditionSpecOneOfTypeConst {
  TYPE_NOT_SET = 0,
  TYPE_MIN_SET = 1,
  TYPE_MAX_SET = 2,
  TYPE_BOUNDS_SET = 3,
  TYPE_CONDITION_SET = 4,
}

export interface IRosTypeBosdynApiMsgsAlertData {
  severity: IRosTypeBosdynApiMsgsAlertDataSeverityLevel;
  title: string;
  source: string;
  additional_data: IRosTypeProto2RosStruct;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsAlertDataConst {
  ADDITIONAL_DATA_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsAlertDataSeverityLevel {
  value: number;
}

export enum IRosTypeBosdynApiMsgsAlertDataSeverityLevelConst {
  SEVERITY_LEVEL_UNKNOWN = 0,
  SEVERITY_LEVEL_INFO = 1,
  SEVERITY_LEVEL_WARN = 2,
  SEVERITY_LEVEL_ERROR = 3,
  SEVERITY_LEVEL_CRITICAL = 4,
}

export interface IRosTypeBosdynApiMsgsAllowableOrientation {
  constraint: IRosTypeBosdynApiMsgsAllowableOrientationOneOfConstraint;
}

export interface IRosTypeBosdynApiMsgsAllowableOrientationOneOfConstraint {
  rotation_with_tolerance: IRosTypeBosdynApiMsgsRotationWithTolerance;
  vector_alignment_with_tolerance: IRosTypeBosdynApiMsgsVectorAlignmentWithTolerance;
  squeeze_grasp: IRosTypeBosdynApiMsgsSqueezeGrasp;
  constraint_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsAllowableOrientationOneOfConstraintConst {
  CONSTRAINT_NOT_SET = 0,
  CONSTRAINT_ROTATION_WITH_TOLERANCE_SET = 1,
  CONSTRAINT_VECTOR_ALIGNMENT_WITH_TOLERANCE_SET = 2,
  CONSTRAINT_SQUEEZE_GRASP_SET = 3,
}

export interface IRosTypeBosdynApiMsgsAngularInterpolation {
  value: number;
}

export enum IRosTypeBosdynApiMsgsAngularInterpolationConst {
  ANG_INTERP_UNKNOWN = 0,
  ANG_INTERP_LINEAR = 1,
  ANG_INTERP_CUBIC_EULER = 2,
}

export interface IRosTypeBosdynApiMsgsApiGraspOverride {
  override_request: IRosTypeBosdynApiMsgsApiGraspOverrideOverride;
}

export interface IRosTypeBosdynApiMsgsApiGraspOverrideOverride {
  value: number;
}

export enum IRosTypeBosdynApiMsgsApiGraspOverrideOverrideConst {
  OVERRIDE_UNKNOWN = 0,
  OVERRIDE_HOLDING = 1,
  OVERRIDE_NOT_HOLDING = 2,
}

export interface IRosTypeBosdynApiMsgsApiGraspOverrideRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  api_grasp_override: IRosTypeBosdynApiMsgsApiGraspOverride;
  carry_state_override: IRosTypeBosdynApiMsgsApiGraspedCarryStateOverride;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsApiGraspOverrideRequestConst {
  HEADER_FIELD_SET = 1,
  API_GRASP_OVERRIDE_FIELD_SET = 2,
  CARRY_STATE_OVERRIDE_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsApiGraspOverrideResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsApiGraspOverrideResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsApiGraspedCarryStateOverride {
  override_request: IRosTypeBosdynApiMsgsManipulatorStateCarryState;
}

export interface IRosTypeBosdynApiMsgsAprilTagProperties {
  tag_id: number;
  dimensions: IRosTypeBosdynApiMsgsVec2;
  frame_name_fiducial: string;
  fiducial_pose_status: IRosTypeBosdynApiMsgsAprilTagPropertiesAprilTagPoseStatus;
  frame_name_fiducial_filtered: string;
  fiducial_filtered_pose_status: IRosTypeBosdynApiMsgsAprilTagPropertiesAprilTagPoseStatus;
  frame_name_camera: string;
  detection_covariance: IRosTypeBosdynApiMsgsSe3Covariance;
  detection_covariance_reference_frame: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsAprilTagPropertiesConst {
  DIMENSIONS_FIELD_SET = 2,
  DETECTION_COVARIANCE_FIELD_SET = 128,
}

export interface IRosTypeBosdynApiMsgsAprilTagPropertiesAprilTagPoseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsAprilTagPropertiesAprilTagPoseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_AMBIGUOUS = 2,
  STATUS_HIGH_ERROR = 3,
}

export interface IRosTypeBosdynApiMsgsArea {
  geometry: IRosTypeBosdynApiMsgsAreaOneOfGeometry;
}

export interface IRosTypeBosdynApiMsgsAreaI {
  geometry: IRosTypeBosdynApiMsgsAreaIOneOfGeometry;
}

export interface IRosTypeBosdynApiMsgsAreaIOneOfGeometry {
  rectangle: IRosTypeBosdynApiMsgsRectangleI;
  geometry_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsAreaIOneOfGeometryConst {
  GEOMETRY_NOT_SET = 0,
  GEOMETRY_RECTANGLE_SET = 1,
}

export interface IRosTypeBosdynApiMsgsAreaOneOfGeometry {
  polygon: IRosTypeBosdynApiMsgsPolygon;
  circle: IRosTypeBosdynApiMsgsCircle;
  geometry_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsAreaOneOfGeometryConst {
  GEOMETRY_NOT_SET = 0,
  GEOMETRY_POLYGON_SET = 1,
  GEOMETRY_CIRCLE_SET = 2,
}

export interface IRosTypeBosdynApiMsgsArmCartesianCommandFeedback {
  status: IRosTypeBosdynApiMsgsArmCartesianCommandFeedbackStatus;
  measured_pos_tracking_error: number;
  measured_rot_tracking_error: number;
  measured_pos_distance_to_goal: number;
  measured_rot_distance_to_goal: number;
}

export interface IRosTypeBosdynApiMsgsArmCartesianCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsArmCartesianCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_TRAJECTORY_COMPLETE = 1,
  STATUS_IN_PROGRESS = 2,
  STATUS_TRAJECTORY_CANCELLED = 3,
  STATUS_TRAJECTORY_STALLED = 4,
}

export interface IRosTypeBosdynApiMsgsArmCartesianCommandRequest {
  root_frame_name: string;
  wrist_tform_tool: IRosTypeGeometryMsgsPose;
  root_tform_task: IRosTypeGeometryMsgsPose;
  pose_trajectory_in_task: IRosTypeBosdynApiMsgsSe3Trajectory;
  maximum_acceleration: IRosTypeStdMsgsFloat64;
  max_linear_velocity: IRosTypeStdMsgsFloat64;
  max_angular_velocity: IRosTypeStdMsgsFloat64;
  max_pos_tracking_error: IRosTypeStdMsgsFloat64;
  max_rot_tracking_error: IRosTypeStdMsgsFloat64;
  x_axis: IRosTypeBosdynApiMsgsArmCartesianCommandRequestAxisMode;
  y_axis: IRosTypeBosdynApiMsgsArmCartesianCommandRequestAxisMode;
  z_axis: IRosTypeBosdynApiMsgsArmCartesianCommandRequestAxisMode;
  rx_axis: IRosTypeBosdynApiMsgsArmCartesianCommandRequestAxisMode;
  ry_axis: IRosTypeBosdynApiMsgsArmCartesianCommandRequestAxisMode;
  rz_axis: IRosTypeBosdynApiMsgsArmCartesianCommandRequestAxisMode;
  wrench_trajectory_in_task: IRosTypeBosdynApiMsgsWrenchTrajectory;
  disable_velocity_limiting: IRosTypeStdMsgsBool;
  joint_configuration: IRosTypeBosdynApiMsgsArmCartesianCommandRequestOneOfJointConfiguration;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmCartesianCommandRequestConst {
  WRIST_TFORM_TOOL_FIELD_SET = 2,
  ROOT_TFORM_TASK_FIELD_SET = 4,
  POSE_TRAJECTORY_IN_TASK_FIELD_SET = 8,
  MAXIMUM_ACCELERATION_FIELD_SET = 16,
  MAX_LINEAR_VELOCITY_FIELD_SET = 32,
  MAX_ANGULAR_VELOCITY_FIELD_SET = 64,
  MAX_POS_TRACKING_ERROR_FIELD_SET = 128,
  MAX_ROT_TRACKING_ERROR_FIELD_SET = 256,
  WRENCH_TRAJECTORY_IN_TASK_FIELD_SET = 131072,
  DISABLE_VELOCITY_LIMITING_FIELD_SET = 262144,
}

export interface IRosTypeBosdynApiMsgsArmCartesianCommandRequestAxisMode {
  value: number;
}

export enum IRosTypeBosdynApiMsgsArmCartesianCommandRequestAxisModeConst {
  AXIS_MODE_POSITION = 0,
  AXIS_MODE_FORCE = 1,
}

export interface IRosTypeBosdynApiMsgsArmCartesianCommandRequestOneOfJointConfiguration {
  force_remain_near_current_joint_configuration: boolean;
  preferred_joint_configuration: IRosTypeBosdynApiMsgsArmJointPosition;
  joint_configuration_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsArmCartesianCommandRequestOneOfJointConfigurationConst {
  JOINT_CONFIGURATION_NOT_SET = 0,
  JOINT_CONFIGURATION_FORCE_REMAIN_NEAR_CURRENT_JOINT_CONFIGURATION_SET = 1,
  JOINT_CONFIGURATION_PREFERRED_JOINT_CONFIGURATION_SET = 2,
}

export interface IRosTypeBosdynApiMsgsArmCommandFeedback {
  status: IRosTypeBosdynApiMsgsRobotCommandFeedbackStatusStatus;
  feedback: IRosTypeBosdynApiMsgsArmCommandFeedbackOneOfFeedback;
}

export interface IRosTypeBosdynApiMsgsArmCommandFeedbackOneOfFeedback {
  arm_cartesian_feedback: IRosTypeBosdynApiMsgsArmCartesianCommandFeedback;
  arm_joint_move_feedback: IRosTypeBosdynApiMsgsArmJointMoveCommandFeedback;
  named_arm_position_feedback: IRosTypeBosdynApiMsgsNamedArmPositionsCommandFeedback;
  arm_gaze_feedback: IRosTypeBosdynApiMsgsGazeCommandFeedback;
  arm_drag_feedback: IRosTypeBosdynApiMsgsArmDragCommandFeedback;
  arm_impedance_feedback: IRosTypeBosdynApiMsgsArmImpedanceCommandFeedback;
  feedback_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsArmCommandFeedbackOneOfFeedbackConst {
  FEEDBACK_NOT_SET = 0,
  FEEDBACK_ARM_CARTESIAN_FEEDBACK_SET = 1,
  FEEDBACK_ARM_JOINT_MOVE_FEEDBACK_SET = 2,
  FEEDBACK_NAMED_ARM_POSITION_FEEDBACK_SET = 3,
  FEEDBACK_ARM_VELOCITY_FEEDBACK_SET = 4,
  FEEDBACK_ARM_GAZE_FEEDBACK_SET = 5,
  FEEDBACK_ARM_STOP_FEEDBACK_SET = 6,
  FEEDBACK_ARM_DRAG_FEEDBACK_SET = 7,
  FEEDBACK_ARM_IMPEDANCE_FEEDBACK_SET = 8,
}

export interface IRosTypeBosdynApiMsgsArmCommandRequest {
  params: IRosTypeBosdynApiMsgsArmParams;
  command: IRosTypeBosdynApiMsgsArmCommandRequestOneOfCommand;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmCommandRequestConst {
  PARAMS_FIELD_SET = 256,
}

export interface IRosTypeBosdynApiMsgsArmCommandRequestOneOfCommand {
  arm_cartesian_command: IRosTypeBosdynApiMsgsArmCartesianCommandRequest;
  arm_joint_move_command: IRosTypeBosdynApiMsgsArmJointMoveCommandRequest;
  named_arm_position_command: IRosTypeBosdynApiMsgsNamedArmPositionsCommandRequest;
  arm_velocity_command: IRosTypeBosdynApiMsgsArmVelocityCommandRequest;
  arm_gaze_command: IRosTypeBosdynApiMsgsGazeCommandRequest;
  arm_impedance_command: IRosTypeBosdynApiMsgsArmImpedanceCommandRequest;
  command_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsArmCommandRequestOneOfCommandConst {
  COMMAND_NOT_SET = 0,
  COMMAND_ARM_CARTESIAN_COMMAND_SET = 1,
  COMMAND_ARM_JOINT_MOVE_COMMAND_SET = 2,
  COMMAND_NAMED_ARM_POSITION_COMMAND_SET = 3,
  COMMAND_ARM_VELOCITY_COMMAND_SET = 4,
  COMMAND_ARM_GAZE_COMMAND_SET = 5,
  COMMAND_ARM_STOP_COMMAND_SET = 6,
  COMMAND_ARM_DRAG_COMMAND_SET = 7,
  COMMAND_ARM_IMPEDANCE_COMMAND_SET = 8,
}

export interface IRosTypeBosdynApiMsgsArmDragCommandFeedback {
  status: IRosTypeBosdynApiMsgsArmDragCommandFeedbackStatus;
}

export interface IRosTypeBosdynApiMsgsArmDragCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsArmDragCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_DRAGGING = 1,
  STATUS_GRASP_FAILED = 2,
  STATUS_OTHER_FAILURE = 3,
}

export interface IRosTypeBosdynApiMsgsArmImpedanceCommandFeedback {
  status: IRosTypeBosdynApiMsgsArmImpedanceCommandFeedbackStatus;
  transforms_snapshot: IRosTypeBosdynApiMsgsFrameTreeSnapshot;
  commanded_wrench_from_stiffness_at_tool_in_desired_tool: IRosTypeGeometryMsgsWrench;
  commanded_wrench_from_damping_at_tool_in_desired_tool: IRosTypeGeometryMsgsWrench;
  commanded_wrench_from_feed_forward_at_tool_in_desired_tool: IRosTypeGeometryMsgsWrench;
  total_commanded_wrench_at_tool_in_desired_tool: IRosTypeGeometryMsgsWrench;
  total_measured_wrench_at_tool_in_desired_tool: IRosTypeGeometryMsgsWrench;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmImpedanceCommandFeedbackConst {
  TRANSFORMS_SNAPSHOT_FIELD_SET = 2,
  COMMANDED_WRENCH_FROM_STIFFNESS_AT_TOOL_IN_DESIRED_TOOL_FIELD_SET = 4,
  COMMANDED_WRENCH_FROM_DAMPING_AT_TOOL_IN_DESIRED_TOOL_FIELD_SET = 8,
  COMMANDED_WRENCH_FROM_FEED_FORWARD_AT_TOOL_IN_DESIRED_TOOL_FIELD_SET = 16,
  TOTAL_COMMANDED_WRENCH_AT_TOOL_IN_DESIRED_TOOL_FIELD_SET = 32,
  TOTAL_MEASURED_WRENCH_AT_TOOL_IN_DESIRED_TOOL_FIELD_SET = 64,
}

export interface IRosTypeBosdynApiMsgsArmImpedanceCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsArmImpedanceCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_TRAJECTORY_COMPLETE = 1,
  STATUS_IN_PROGRESS = 2,
  STATUS_TRAJECTORY_STALLED = 3,
  STATUS_TRAJECTORY_CANCELLED = 4,
}

export interface IRosTypeBosdynApiMsgsArmImpedanceCommandRequest {
  root_frame_name: string;
  root_tform_task: IRosTypeGeometryMsgsPose;
  wrist_tform_tool: IRosTypeGeometryMsgsPose;
  task_tform_desired_tool: IRosTypeBosdynApiMsgsSe3Trajectory;
  feed_forward_wrench_at_tool_in_desired_tool: IRosTypeGeometryMsgsWrench;
  diagonal_stiffness_matrix: IRosTypeBosdynApiMsgsVector;
  diagonal_damping_matrix: IRosTypeBosdynApiMsgsVector;
  max_force_mag: IRosTypeStdMsgsFloat64;
  max_torque_mag: IRosTypeStdMsgsFloat64;
  disable_safety_check: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmImpedanceCommandRequestConst {
  ROOT_TFORM_TASK_FIELD_SET = 2,
  WRIST_TFORM_TOOL_FIELD_SET = 4,
  TASK_TFORM_DESIRED_TOOL_FIELD_SET = 8,
  FEED_FORWARD_WRENCH_AT_TOOL_IN_DESIRED_TOOL_FIELD_SET = 16,
  DIAGONAL_STIFFNESS_MATRIX_FIELD_SET = 32,
  DIAGONAL_DAMPING_MATRIX_FIELD_SET = 64,
  MAX_FORCE_MAG_FIELD_SET = 128,
  MAX_TORQUE_MAG_FIELD_SET = 256,
  DISABLE_SAFETY_CHECK_FIELD_SET = 512,
}

export interface IRosTypeBosdynApiMsgsArmJointMoveCommandFeedback {
  status: IRosTypeBosdynApiMsgsArmJointMoveCommandFeedbackStatus;
  planner_status: IRosTypeBosdynApiMsgsArmJointMoveCommandFeedbackPlannerStatus;
  planned_points: IRosTypeBosdynApiMsgsArmJointTrajectoryPoint[];
  time_to_goal: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmJointMoveCommandFeedbackConst {
  TIME_TO_GOAL_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsArmJointMoveCommandFeedbackPlannerStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsArmJointMoveCommandFeedbackPlannerStatusConst {
  PLANNER_STATUS_UNKNOWN = 0,
  PLANNER_STATUS_SUCCESS = 1,
  PLANNER_STATUS_MODIFIED = 2,
  PLANNER_STATUS_FAILED = 3,
}

export interface IRosTypeBosdynApiMsgsArmJointMoveCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsArmJointMoveCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_COMPLETE = 1,
  STATUS_IN_PROGRESS = 2,
  STATUS_STALLED = 3,
}

export interface IRosTypeBosdynApiMsgsArmJointMoveCommandRequest {
  trajectory: IRosTypeBosdynApiMsgsArmJointTrajectory;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmJointMoveCommandRequestConst {
  TRAJECTORY_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsArmJointPosition {
  sh0: IRosTypeStdMsgsFloat64;
  sh1: IRosTypeStdMsgsFloat64;
  el0: IRosTypeStdMsgsFloat64;
  el1: IRosTypeStdMsgsFloat64;
  wr0: IRosTypeStdMsgsFloat64;
  wr1: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmJointPositionConst {
  SH0_FIELD_SET = 1,
  SH1_FIELD_SET = 2,
  EL0_FIELD_SET = 4,
  EL1_FIELD_SET = 8,
  WR0_FIELD_SET = 16,
  WR1_FIELD_SET = 32,
}

export interface IRosTypeBosdynApiMsgsArmJointTrajectory {
  points: IRosTypeBosdynApiMsgsArmJointTrajectoryPoint[];
  reference_time: { sec: number; nanosec: number };
  maximum_velocity: IRosTypeStdMsgsFloat64;
  maximum_acceleration: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmJointTrajectoryConst {
  REFERENCE_TIME_FIELD_SET = 2,
  MAXIMUM_VELOCITY_FIELD_SET = 4,
  MAXIMUM_ACCELERATION_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsArmJointTrajectoryPoint {
  position: IRosTypeBosdynApiMsgsArmJointPosition;
  velocity: IRosTypeBosdynApiMsgsArmJointVelocity;
  time_since_reference: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmJointTrajectoryPointConst {
  POSITION_FIELD_SET = 1,
  VELOCITY_FIELD_SET = 2,
  TIME_SINCE_REFERENCE_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsArmJointVelocity {
  sh0: IRosTypeStdMsgsFloat64;
  sh1: IRosTypeStdMsgsFloat64;
  el0: IRosTypeStdMsgsFloat64;
  el1: IRosTypeStdMsgsFloat64;
  wr0: IRosTypeStdMsgsFloat64;
  wr1: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmJointVelocityConst {
  SH0_FIELD_SET = 1,
  SH1_FIELD_SET = 2,
  EL0_FIELD_SET = 4,
  EL1_FIELD_SET = 8,
  WR0_FIELD_SET = 16,
  WR1_FIELD_SET = 32,
}

export interface IRosTypeBosdynApiMsgsArmParams {
  disable_body_force_limiter: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmParamsConst {
  DISABLE_BODY_FORCE_LIMITER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsArmSurfaceContactCommand {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  request: IRosTypeBosdynApiMsgsArmSurfaceContactRequest;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmSurfaceContactCommandConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
  REQUEST_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsArmSurfaceContactRequest {
  root_frame_name: string;
  wrist_tform_tool: IRosTypeGeometryMsgsPose;
  root_tform_task: IRosTypeGeometryMsgsPose;
  pose_trajectory_in_task: IRosTypeBosdynApiMsgsSe3Trajectory;
  maximum_acceleration: IRosTypeStdMsgsFloat64;
  max_linear_velocity: IRosTypeStdMsgsFloat64;
  max_angular_velocity: IRosTypeStdMsgsFloat64;
  max_pos_tracking_error: IRosTypeStdMsgsFloat64;
  max_rot_tracking_error: IRosTypeStdMsgsFloat64;
  x_axis: IRosTypeBosdynApiMsgsArmSurfaceContactRequestAxisMode;
  y_axis: IRosTypeBosdynApiMsgsArmSurfaceContactRequestAxisMode;
  z_axis: IRosTypeBosdynApiMsgsArmSurfaceContactRequestAxisMode;
  press_force_percentage: IRosTypeGeometryMsgsVector3;
  xy_admittance: IRosTypeBosdynApiMsgsArmSurfaceContactRequestAdmittanceSetting;
  z_admittance: IRosTypeBosdynApiMsgsArmSurfaceContactRequestAdmittanceSetting;
  xy_to_z_cross_term_admittance: IRosTypeBosdynApiMsgsArmSurfaceContactRequestAdmittanceSetting;
  bias_force_ewrt_body: IRosTypeGeometryMsgsVector3;
  gripper_command: IRosTypeBosdynApiMsgsClawGripperCommandRequest;
  is_robot_following_hand: boolean;
  joint_configuration: IRosTypeBosdynApiMsgsArmSurfaceContactRequestOneOfJointConfiguration;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmSurfaceContactRequestConst {
  WRIST_TFORM_TOOL_FIELD_SET = 2,
  ROOT_TFORM_TASK_FIELD_SET = 4,
  POSE_TRAJECTORY_IN_TASK_FIELD_SET = 8,
  MAXIMUM_ACCELERATION_FIELD_SET = 16,
  MAX_LINEAR_VELOCITY_FIELD_SET = 32,
  MAX_ANGULAR_VELOCITY_FIELD_SET = 64,
  MAX_POS_TRACKING_ERROR_FIELD_SET = 128,
  MAX_ROT_TRACKING_ERROR_FIELD_SET = 256,
  PRESS_FORCE_PERCENTAGE_FIELD_SET = 16384,
  BIAS_FORCE_EWRT_BODY_FIELD_SET = 262144,
  GRIPPER_COMMAND_FIELD_SET = 524288,
}

export interface IRosTypeBosdynApiMsgsArmSurfaceContactRequestAdmittanceSetting {
  value: number;
}

export enum IRosTypeBosdynApiMsgsArmSurfaceContactRequestAdmittanceSettingConst {
  ADMITTANCE_SETTING_UNKNOWN = 0,
  ADMITTANCE_SETTING_OFF = 1,
  ADMITTANCE_SETTING_NORMAL = 2,
  ADMITTANCE_SETTING_LOOSE = 3,
  ADMITTANCE_SETTING_STIFF = 4,
  ADMITTANCE_SETTING_VERY_STIFF = 5,
}

export interface IRosTypeBosdynApiMsgsArmSurfaceContactRequestAxisMode {
  value: number;
}

export enum IRosTypeBosdynApiMsgsArmSurfaceContactRequestAxisModeConst {
  AXIS_MODE_POSITION = 0,
  AXIS_MODE_FORCE = 1,
}

export interface IRosTypeBosdynApiMsgsArmSurfaceContactRequestOneOfJointConfiguration {
  force_remain_near_current_joint_configuration: boolean;
  preferred_joint_configuration: IRosTypeBosdynApiMsgsArmJointPosition;
  joint_configuration_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsArmSurfaceContactRequestOneOfJointConfigurationConst {
  JOINT_CONFIGURATION_NOT_SET = 0,
  JOINT_CONFIGURATION_FORCE_REMAIN_NEAR_CURRENT_JOINT_CONFIGURATION_SET = 1,
  JOINT_CONFIGURATION_PREFERRED_JOINT_CONFIGURATION_SET = 2,
}

export interface IRosTypeBosdynApiMsgsArmSurfaceContactResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmSurfaceContactResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsArmVelocityCommandCartesianVelocity {
  frame_name: string;
  velocity_in_frame_name: IRosTypeGeometryMsgsVector3;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmVelocityCommandCartesianVelocityConst {
  VELOCITY_IN_FRAME_NAME_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsArmVelocityCommandCylindricalVelocity {
  linear_velocity: IRosTypeBosdynApiMsgsCylindricalCoordinate;
  max_linear_velocity: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmVelocityCommandCylindricalVelocityConst {
  LINEAR_VELOCITY_FIELD_SET = 1,
  MAX_LINEAR_VELOCITY_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsArmVelocityCommandRequest {
  angular_velocity_of_hand_rt_odom_in_hand: IRosTypeGeometryMsgsVector3;
  maximum_acceleration: IRosTypeStdMsgsFloat64;
  end_time: { sec: number; nanosec: number };
  command: IRosTypeBosdynApiMsgsArmVelocityCommandRequestOneOfCommand;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsArmVelocityCommandRequestConst {
  ANGULAR_VELOCITY_OF_HAND_RT_ODOM_IN_HAND_FIELD_SET = 4,
  MAXIMUM_ACCELERATION_FIELD_SET = 8,
  END_TIME_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsArmVelocityCommandRequestOneOfCommand {
  cylindrical_velocity: IRosTypeBosdynApiMsgsArmVelocityCommandCylindricalVelocity;
  cartesian_velocity: IRosTypeBosdynApiMsgsArmVelocityCommandCartesianVelocity;
  command_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsArmVelocityCommandRequestOneOfCommandConst {
  COMMAND_NOT_SET = 0,
  COMMAND_CYLINDRICAL_VELOCITY_SET = 1,
  COMMAND_CARTESIAN_VELOCITY_SET = 2,
}

export interface IRosTypeBosdynApiMsgsAssociatedAlertData {
  reference_id: IRosTypeBosdynApiMsgsDataIdentifier;
  alert_data: IRosTypeBosdynApiMsgsAlertData;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsAssociatedAlertDataConst {
  REFERENCE_ID_FIELD_SET = 1,
  ALERT_DATA_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsAssociatedMetadata {
  reference_id: IRosTypeBosdynApiMsgsDataIdentifier;
  metadata: IRosTypeBosdynApiMsgsMetadata;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsAssociatedMetadataConst {
  REFERENCE_ID_FIELD_SET = 1,
  METADATA_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsAssociation {
  mac_address: string;
  connected_time: { sec: number; nanosec: number };
  rx_signal_dbm: number;
  rx_signal_avg_dbm: number;
  rx_beacon_signal_avg_dbm: number;
  expected_bits_per_second: number;
  rx_bytes: number;
  rx_packets: number;
  rx_bits_per_second: number;
  tx_bytes: number;
  tx_packets: number;
  tx_bits_per_second: number;
  tx_retries: number;
  tx_failed: number;
  beacons_received: number;
  beacon_loss_count: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsAssociationConst {
  CONNECTED_TIME_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsAvailableModels {
  data: IRosTypeBosdynApiMsgsModelData[];
}

export interface IRosTypeBosdynApiMsgsBatteryChangePoseCommandFeedback {
  status: IRosTypeBosdynApiMsgsBatteryChangePoseCommandFeedbackStatus;
}

export interface IRosTypeBosdynApiMsgsBatteryChangePoseCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsBatteryChangePoseCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_COMPLETED = 1,
  STATUS_IN_PROGRESS = 2,
  STATUS_FAILED = 3,
}

export interface IRosTypeBosdynApiMsgsBatteryChangePoseCommandRequest {
  direction_hint: IRosTypeBosdynApiMsgsBatteryChangePoseCommandRequestDirectionHint;
}

export interface IRosTypeBosdynApiMsgsBatteryChangePoseCommandRequestDirectionHint {
  value: number;
}

export enum IRosTypeBosdynApiMsgsBatteryChangePoseCommandRequestDirectionHintConst {
  HINT_UNKNOWN = 0,
  HINT_RIGHT = 1,
  HINT_LEFT = 2,
}

export interface IRosTypeBosdynApiMsgsBatteryState {
  timestamp: { sec: number; nanosec: number };
  identifier: string;
  charge_percentage: IRosTypeStdMsgsFloat64;
  estimated_runtime: { sec: number; nanosec: number };
  current: IRosTypeStdMsgsFloat64;
  voltage: IRosTypeStdMsgsFloat64;
  temperatures: number[];
  status: IRosTypeBosdynApiMsgsBatteryStateStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsBatteryStateConst {
  TIMESTAMP_FIELD_SET = 1,
  CHARGE_PERCENTAGE_FIELD_SET = 4,
  ESTIMATED_RUNTIME_FIELD_SET = 8,
  CURRENT_FIELD_SET = 16,
  VOLTAGE_FIELD_SET = 32,
}

export interface IRosTypeBosdynApiMsgsBatteryStateStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsBatteryStateStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_MISSING = 1,
  STATUS_CHARGING = 2,
  STATUS_DISCHARGING = 3,
  STATUS_BOOTING = 4,
}

export interface IRosTypeBosdynApiMsgsBehaviorFault {
  behavior_fault_id: number;
  onset_timestamp: { sec: number; nanosec: number };
  cause: IRosTypeBosdynApiMsgsBehaviorFaultCause;
  status: IRosTypeBosdynApiMsgsBehaviorFaultStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsBehaviorFaultConst {
  ONSET_TIMESTAMP_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsBehaviorFaultCause {
  value: number;
}

export enum IRosTypeBosdynApiMsgsBehaviorFaultCauseConst {
  CAUSE_UNKNOWN = 0,
  CAUSE_FALL = 1,
  CAUSE_HARDWARE = 2,
  CAUSE_LEASE_TIMEOUT = 3,
}

export interface IRosTypeBosdynApiMsgsBehaviorFaultState {
  faults: IRosTypeBosdynApiMsgsBehaviorFault[];
}

export interface IRosTypeBosdynApiMsgsBehaviorFaultStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsBehaviorFaultStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_CLEARABLE = 1,
  STATUS_UNCLEARABLE = 2,
}

export interface IRosTypeBosdynApiMsgsBlobPage {
  spec: IRosTypeBosdynApiMsgsBlobSpec;
  page: IRosTypeBosdynApiMsgsPageInfo;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsBlobPageConst {
  SPEC_FIELD_SET = 1,
  PAGE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsBlobPages {
  time_range: IRosTypeBosdynApiMsgsTimeRange;
  pages: IRosTypeBosdynApiMsgsBlobPage[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsBlobPagesConst {
  TIME_RANGE_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsBlobSpec {
  source: string;
  message_type: string;
  channel: string;
  channel_glob: string;
}

export interface IRosTypeBosdynApiMsgsBoolParam {
  value: boolean;
}

export interface IRosTypeBosdynApiMsgsBoolParamSpec {
  default_value: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsBoolParamSpecConst {
  DEFAULT_VALUE_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsBoundingBoxProperties {
  size_ewrt_frame: IRosTypeGeometryMsgsVector3;
  frame: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsBoundingBoxPropertiesConst {
  SIZE_EWRT_FRAME_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsBounds {
  lower: number;
  upper: number;
}

export interface IRosTypeBosdynApiMsgsBox2 {
  size: IRosTypeBosdynApiMsgsVec2;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsBox2Const {
  SIZE_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsBox2WithFrame {
  box: IRosTypeBosdynApiMsgsBox2;
  frame_name: string;
  frame_name_tform_box: IRosTypeGeometryMsgsPose;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsBox2WithFrameConst {
  BOX_FIELD_SET = 1,
  FRAME_NAME_TFORM_BOX_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsBox3 {
  size: IRosTypeGeometryMsgsVector3;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsBox3Const {
  SIZE_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsBox3WithFrame {
  box: IRosTypeBosdynApiMsgsBox3;
  frame_name: string;
  frame_name_tform_box: IRosTypeGeometryMsgsPose;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsBox3WithFrameConst {
  BOX_FIELD_SET = 1,
  FRAME_NAME_TFORM_BOX_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsCancelAcquisitionRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  request_id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsCancelAcquisitionRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsCancelAcquisitionResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsCancelAcquisitionResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsCancelAcquisitionResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsCancelAcquisitionResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsCancelAcquisitionResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_FAILED_TO_CANCEL = 2,
  STATUS_REQUEST_ID_DOES_NOT_EXIST = 3,
}

export interface IRosTypeBosdynApiMsgsCaptureActionId {
  action_name: string;
  group_name: string;
  timestamp: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsCaptureActionIdConst {
  TIMESTAMP_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsCaptureParameters {
  exposure_duration: { sec: number; nanosec: number };
  gain: number;
  custom_params: IRosTypeBosdynApiMsgsDictParam;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsCaptureParametersConst {
  EXPOSURE_DURATION_FIELD_SET = 1,
  CUSTOM_PARAMS_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsCircle {
  center_pt: IRosTypeBosdynApiMsgsVec2;
  radius: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsCircleConst {
  CENTER_PT_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsClawGripperCommandFeedback {
  status: IRosTypeBosdynApiMsgsClawGripperCommandFeedbackStatus;
}

export interface IRosTypeBosdynApiMsgsClawGripperCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsClawGripperCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_IN_PROGRESS = 1,
  STATUS_AT_GOAL = 2,
  STATUS_APPLYING_FORCE = 3,
}

export interface IRosTypeBosdynApiMsgsClawGripperCommandRequest {
  trajectory: IRosTypeBosdynApiMsgsScalarTrajectory;
  maximum_open_close_velocity: IRosTypeStdMsgsFloat64;
  maximum_open_close_acceleration: IRosTypeStdMsgsFloat64;
  maximum_torque: IRosTypeStdMsgsFloat64;
  disable_force_on_contact: boolean;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsClawGripperCommandRequestConst {
  TRAJECTORY_FIELD_SET = 1,
  MAXIMUM_OPEN_CLOSE_VELOCITY_FIELD_SET = 2,
  MAXIMUM_OPEN_CLOSE_ACCELERATION_FIELD_SET = 4,
  MAXIMUM_TORQUE_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsClearBehaviorFaultRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  behavior_fault_id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsClearBehaviorFaultRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsClearBehaviorFaultResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  status: IRosTypeBosdynApiMsgsClearBehaviorFaultResponseStatus;
  behavior_fault: IRosTypeBosdynApiMsgsBehaviorFault;
  blocking_system_faults: IRosTypeBosdynApiMsgsSystemFault[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsClearBehaviorFaultResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
  BEHAVIOR_FAULT_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsClearBehaviorFaultResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsClearBehaviorFaultResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_CLEARED = 1,
  STATUS_NOT_CLEARED = 2,
}

export interface IRosTypeBosdynApiMsgsClearServiceFaultRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  fault_id: IRosTypeBosdynApiMsgsServiceFaultId;
  clear_all_service_faults: boolean;
  clear_all_payload_faults: boolean;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsClearServiceFaultRequestConst {
  HEADER_FIELD_SET = 1,
  FAULT_ID_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsClearServiceFaultResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsClearServiceFaultResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsClearServiceFaultResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsClearServiceFaultResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsClearServiceFaultResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_FAULT_NOT_ACTIVE = 2,
}

export interface IRosTypeBosdynApiMsgsCombinedJointStates {
  acquisition_timestamp: { sec: number; nanosec: number };
  position: number[];
  velocity: number[];
  load: number[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsCombinedJointStatesConst {
  ACQUISITION_TIMESTAMP_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsCommonError {
  code: IRosTypeBosdynApiMsgsCommonErrorCode;
  message: string;
  data: IRosTypeProto2RosAnyProto;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsCommonErrorConst {
  DATA_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsCommonErrorCode {
  value: number;
}

export enum IRosTypeBosdynApiMsgsCommonErrorCodeConst {
  CODE_UNSPECIFIED = 0,
  CODE_OK = 1,
  CODE_INTERNAL_SERVER_ERROR = 2,
  CODE_INVALID_REQUEST = 3,
}

export interface IRosTypeBosdynApiMsgsCommsState {
  timestamp: { sec: number; nanosec: number };
  state: IRosTypeBosdynApiMsgsCommsStateOneOfState;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsCommsStateConst {
  TIMESTAMP_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsCommsStateOneOfState {
  wifi_state: IRosTypeBosdynApiMsgsWiFiState;
  state_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsCommsStateOneOfStateConst {
  STATE_NOT_SET = 0,
  STATE_WIFI_STATE_SET = 1,
}

export interface IRosTypeBosdynApiMsgsComputeParameters {
  other_data: IRosTypeProto2RosAnyProto;
  model_name: string;
  reference_images: IRosTypeBosdynApiMsgsImageCaptureAndSource[];
  custom_params: IRosTypeBosdynApiMsgsDictParam;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsComputeParametersConst {
  OTHER_DATA_FIELD_SET = 1,
  CUSTOM_PARAMS_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsConfigRange {
  id_start: number;
  id_end: number;
  type: IRosTypeBosdynApiMsgsDockType;
}

export interface IRosTypeBosdynApiMsgsConstrainedManipulationCommandFeedback {
  status: IRosTypeBosdynApiMsgsConstrainedManipulationCommandFeedbackStatus;
  desired_wrench_odom_frame: IRosTypeGeometryMsgsWrench;
  estimation_activated: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsConstrainedManipulationCommandFeedbackConst {
  DESIRED_WRENCH_ODOM_FRAME_FIELD_SET = 2,
  ESTIMATION_ACTIVATED_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsConstrainedManipulationCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsConstrainedManipulationCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_RUNNING = 1,
  STATUS_ARM_IS_STUCK = 2,
  STATUS_GRASP_IS_LOST = 3,
}

export interface IRosTypeBosdynApiMsgsConstrainedManipulationCommandRequest {
  frame_name: string;
  init_wrench_direction_in_frame_name: IRosTypeGeometryMsgsWrench;
  force_limit: IRosTypeStdMsgsFloat64;
  torque_limit: IRosTypeStdMsgsFloat64;
  task_type: IRosTypeBosdynApiMsgsConstrainedManipulationCommandRequestTaskType;
  end_time: { sec: number; nanosec: number };
  enable_robot_locomotion: IRosTypeStdMsgsBool;
  control_mode: IRosTypeBosdynApiMsgsConstrainedManipulationCommandRequestControlMode;
  accel_limit: IRosTypeStdMsgsFloat64;
  reset_estimator: IRosTypeStdMsgsBool;
  task_speed: IRosTypeBosdynApiMsgsConstrainedManipulationCommandRequestOneOfTaskSpeed;
  task_target_position: IRosTypeBosdynApiMsgsConstrainedManipulationCommandRequestOneOfTaskTargetPosition;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsConstrainedManipulationCommandRequestConst {
  INIT_WRENCH_DIRECTION_IN_FRAME_NAME_FIELD_SET = 2,
  FORCE_LIMIT_FIELD_SET = 16,
  TORQUE_LIMIT_FIELD_SET = 32,
  END_TIME_FIELD_SET = 128,
  ENABLE_ROBOT_LOCOMOTION_FIELD_SET = 256,
  ACCEL_LIMIT_FIELD_SET = 4096,
  RESET_ESTIMATOR_FIELD_SET = 8192,
}

export interface IRosTypeBosdynApiMsgsConstrainedManipulationCommandRequestControlMode {
  value: number;
}

export enum IRosTypeBosdynApiMsgsConstrainedManipulationCommandRequestControlModeConst {
  CONTROL_MODE_UNKNOWN = 0,
  CONTROL_MODE_POSITION = 1,
  CONTROL_MODE_VELOCITY = 2,
}

export interface IRosTypeBosdynApiMsgsConstrainedManipulationCommandRequestOneOfTaskSpeed {
  tangential_speed: number;
  rotational_speed: number;
  task_speed_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsConstrainedManipulationCommandRequestOneOfTaskSpeedConst {
  TASK_SPEED_NOT_SET = 0,
  TASK_SPEED_TANGENTIAL_SPEED_SET = 1,
  TASK_SPEED_ROTATIONAL_SPEED_SET = 2,
}

export interface IRosTypeBosdynApiMsgsConstrainedManipulationCommandRequestOneOfTaskTargetPosition {
  target_linear_position: number;
  target_angle: number;
  task_target_position_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsConstrainedManipulationCommandRequestOneOfTaskTargetPositionConst {
  TASK_TARGET_POSITION_NOT_SET = 0,
  TASK_TARGET_POSITION_TARGET_LINEAR_POSITION_SET = 1,
  TASK_TARGET_POSITION_TARGET_ANGLE_SET = 2,
}

export interface IRosTypeBosdynApiMsgsConstrainedManipulationCommandRequestTaskType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsConstrainedManipulationCommandRequestTaskTypeConst {
  TASK_TYPE_UNKNOWN = 0,
  TASK_TYPE_SE3_CIRCLE_FORCE_TORQUE = 1,
  TASK_TYPE_R3_CIRCLE_EXTRADOF_FORCE = 2,
  TASK_TYPE_SE3_ROTATIONAL_TORQUE = 3,
  TASK_TYPE_R3_CIRCLE_FORCE = 4,
  TASK_TYPE_R3_LINEAR_FORCE = 5,
  TASK_TYPE_HOLD_POSE = 6,
}

export interface IRosTypeBosdynApiMsgsCustomParam {
  value: IRosTypeBosdynApiMsgsCustomParamOneOfValue;
}

export interface IRosTypeBosdynApiMsgsCustomParamCollection {
  specs: IRosTypeBosdynApiMsgsDictParamSpec;
  values: IRosTypeBosdynApiMsgsDictParam;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsCustomParamCollectionConst {
  SPECS_FIELD_SET = 1,
  VALUES_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsCustomParamError {
  status: IRosTypeBosdynApiMsgsCustomParamErrorStatus;
  error_messages: string[];
}

export interface IRosTypeBosdynApiMsgsCustomParamErrorStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsCustomParamErrorStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_COMBINATION = 2,
  STATUS_UNSUPPORTED_PARAMETER = 3,
  STATUS_INVALID_VALUE = 4,
  STATUS_INVALID_TYPE = 5,
}

export interface IRosTypeBosdynApiMsgsCustomParamOneOfValue {
  dict_value: IRosTypeProto2RosAny;
  list_value: IRosTypeProto2RosAny;
  int_value: IRosTypeBosdynApiMsgsInt64Param;
  double_value: IRosTypeBosdynApiMsgsDoubleParam;
  string_value: IRosTypeBosdynApiMsgsStringParam;
  roi_value: IRosTypeBosdynApiMsgsRegionOfInterestParam;
  bool_value: IRosTypeBosdynApiMsgsBoolParam;
  one_of_value: IRosTypeProto2RosAny;
  value_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsCustomParamOneOfValueConst {
  VALUE_NOT_SET = 0,
  VALUE_DICT_VALUE_SET = 1,
  VALUE_LIST_VALUE_SET = 2,
  VALUE_INT_VALUE_SET = 3,
  VALUE_DOUBLE_VALUE_SET = 4,
  VALUE_STRING_VALUE_SET = 5,
  VALUE_ROI_VALUE_SET = 6,
  VALUE_BOOL_VALUE_SET = 7,
  VALUE_ONE_OF_VALUE_SET = 8,
}

export interface IRosTypeBosdynApiMsgsCustomParamSpec {
  spec: IRosTypeBosdynApiMsgsCustomParamSpecOneOfSpec;
}

export interface IRosTypeBosdynApiMsgsCustomParamSpecOneOfSpec {
  dict_spec: IRosTypeBosdynApiMsgsDictParamSpec;
  list_spec: IRosTypeBosdynApiMsgsListParamSpec;
  int_spec: IRosTypeBosdynApiMsgsInt64ParamSpec;
  double_spec: IRosTypeBosdynApiMsgsDoubleParamSpec;
  string_spec: IRosTypeBosdynApiMsgsStringParamSpec;
  roi_spec: IRosTypeBosdynApiMsgsRegionOfInterestParamSpec;
  bool_spec: IRosTypeBosdynApiMsgsBoolParamSpec;
  one_of_spec: IRosTypeBosdynApiMsgsOneOfParamSpec;
  spec_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsCustomParamSpecOneOfSpecConst {
  SPEC_NOT_SET = 0,
  SPEC_DICT_SPEC_SET = 1,
  SPEC_LIST_SPEC_SET = 2,
  SPEC_INT_SPEC_SET = 3,
  SPEC_DOUBLE_SPEC_SET = 4,
  SPEC_STRING_SPEC_SET = 5,
  SPEC_ROI_SPEC_SET = 6,
  SPEC_BOOL_SPEC_SET = 7,
  SPEC_ONE_OF_SPEC_SET = 8,
}

export interface IRosTypeBosdynApiMsgsCylindricalCoordinate {
  r: number;
  theta: number;
  z: number;
}

export interface IRosTypeBosdynApiMsgsDataAcquisitionCapability {
  name: string;
  description: string;
  channel_name: string;
  service_name: string;
  custom_params: IRosTypeBosdynApiMsgsDictParamSpec;
  has_live_data: boolean;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDataAcquisitionCapabilityConst {
  CUSTOM_PARAMS_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsDataBlob {
  timestamp: { sec: number; nanosec: number };
  channel: string;
  type_id: string;
  data: number[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDataBlobConst {
  TIMESTAMP_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsDataBufferStatus {
  num_data_buffer_pages: number;
  data_buffer_total_bytes: number;
  num_comments: number;
  num_events: number;
  blob_specs: IRosTypeBosdynApiMsgsBlobSpec[];
}

export interface IRosTypeBosdynApiMsgsDataCapture {
  name: string;
  custom_params: IRosTypeBosdynApiMsgsDictParam;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDataCaptureConst {
  CUSTOM_PARAMS_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsDataChunk {
  total_size: number;
  data: number[];
}

export interface IRosTypeBosdynApiMsgsDataDescriptor {
  series_index: number;
  timestamp: { sec: number; nanosec: number };
  additional_indexes: number[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDataDescriptorConst {
  TIMESTAMP_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsDataError {
  data_id: IRosTypeBosdynApiMsgsDataIdentifier;
  error_message: string;
  error_data: IRosTypeProto2RosAnyProto;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDataErrorConst {
  DATA_ID_FIELD_SET = 1,
  ERROR_DATA_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsDataIdentifier {
  action_id: IRosTypeBosdynApiMsgsCaptureActionId;
  channel: string;
  data_name: string;
  id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDataIdentifierConst {
  ACTION_ID_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsDataIndex {
  time_range: IRosTypeBosdynApiMsgsTimeRange;
  blobs: IRosTypeBosdynApiMsgsBlobPages[];
  text_messages: IRosTypeBosdynApiMsgsPagesAndTimestamp;
  events: IRosTypeBosdynApiMsgsPagesAndTimestamp;
  comments: IRosTypeBosdynApiMsgsPagesAndTimestamp;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDataIndexConst {
  TIME_RANGE_FIELD_SET = 1,
  TEXT_MESSAGES_FIELD_SET = 4,
  EVENTS_FIELD_SET = 8,
  COMMENTS_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsDataQuery {
  time_range: IRosTypeBosdynApiMsgsTimeRange;
  blobs: IRosTypeBosdynApiMsgsBlobSpec[];
  text_messages: boolean;
  events: boolean;
  comments: boolean;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDataQueryConst {
  TIME_RANGE_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsDataQueryParams {
  query: IRosTypeBosdynApiMsgsDataQueryParamsOneOfQuery;
}

export interface IRosTypeBosdynApiMsgsDataQueryParamsOneOfQuery {
  time_range: IRosTypeBosdynApiMsgsTimeRangeQuery;
  action_ids: IRosTypeBosdynApiMsgsActionIdQuery;
  query_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsDataQueryParamsOneOfQueryConst {
  QUERY_NOT_SET = 0,
  QUERY_TIME_RANGE_SET = 1,
  QUERY_ACTION_IDS_SET = 2,
}

export interface IRosTypeBosdynApiMsgsDeleteDataPagesRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  time_range: IRosTypeBosdynApiMsgsTimeRange;
  page_ids: string[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDeleteDataPagesRequestConst {
  HEADER_FIELD_SET = 1,
  TIME_RANGE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsDeleteDataPagesResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  bytes_deleted: number;
  status: IRosTypeBosdynApiMsgsDeletePageStatus[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDeleteDataPagesResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsDeletePageStatus {
  page_id: string;
  status: IRosTypeBosdynApiMsgsDeletePageStatusStatus;
}

export interface IRosTypeBosdynApiMsgsDeletePageStatusStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsDeletePageStatusStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_DELETED = 1,
  STATUS_DELETION_FAILED = 2,
  STATUS_NOT_FOUND = 3,
}

export interface IRosTypeBosdynApiMsgsDeregisterEstopEndpointRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  target_endpoint: IRosTypeBosdynApiMsgsEstopEndpoint;
  target_config_id: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDeregisterEstopEndpointRequestConst {
  HEADER_FIELD_SET = 1,
  TARGET_ENDPOINT_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsDeregisterEstopEndpointResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  request: IRosTypeBosdynApiMsgsDeregisterEstopEndpointRequest;
  status: IRosTypeBosdynApiMsgsDeregisterEstopEndpointResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDeregisterEstopEndpointResponseConst {
  HEADER_FIELD_SET = 1,
  REQUEST_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsDeregisterEstopEndpointResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsDeregisterEstopEndpointResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_SUCCESS = 1,
  STATUS_ENDPOINT_MISMATCH = 2,
  STATUS_CONFIG_MISMATCH = 3,
  STATUS_MOTORS_ON = 4,
}

export interface IRosTypeBosdynApiMsgsDescriptorBlock {
  descriptor_type: IRosTypeBosdynApiMsgsDescriptorBlockOneOfDescriptorType;
}

export interface IRosTypeBosdynApiMsgsDescriptorBlockOneOfDescriptorType {
  file_descriptor: IRosTypeBosdynApiMsgsFileFormatDescriptor;
  series_descriptor: IRosTypeBosdynApiMsgsSeriesDescriptor;
  series_block_index: IRosTypeBosdynApiMsgsSeriesBlockIndex;
  file_index: IRosTypeBosdynApiMsgsFileIndex;
  descriptor_type_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsDescriptorBlockOneOfDescriptorTypeConst {
  DESCRIPTOR_TYPE_NOT_SET = 0,
  DESCRIPTOR_TYPE_FILE_DESCRIPTOR_SET = 1,
  DESCRIPTOR_TYPE_SERIES_DESCRIPTOR_SET = 2,
  DESCRIPTOR_TYPE_SERIES_BLOCK_INDEX_SET = 3,
  DESCRIPTOR_TYPE_FILE_INDEX_SET = 4,
}

export interface IRosTypeBosdynApiMsgsDictParam {
  values: IRosTypeBosdynApiMsgsDictParamValuesEntry[];
}

export interface IRosTypeBosdynApiMsgsDictParamChildSpec {
  spec: IRosTypeProto2RosAny;
  ui_info: IRosTypeBosdynApiMsgsUserInterfaceInfo;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDictParamChildSpecConst {
  SPEC_FIELD_SET = 1,
  UI_INFO_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsDictParamSpec {
  specs: IRosTypeBosdynApiMsgsDictParamSpecSpecsEntry[];
  is_hidden_by_default: boolean;
}

export interface IRosTypeBosdynApiMsgsDictParamSpecSpecsEntry {
  key: string;
  value: IRosTypeBosdynApiMsgsDictParamChildSpec;
}

export interface IRosTypeBosdynApiMsgsDictParamValuesEntry {
  key: string;
  value: IRosTypeBosdynApiMsgsCustomParam;
}

export interface IRosTypeBosdynApiMsgsDockProperties {
  dock_id: number;
  type: IRosTypeBosdynApiMsgsDockType;
  frame_name_dock: string;
  unavailable: boolean;
  from_prior: boolean;
}

export interface IRosTypeBosdynApiMsgsDockState {
  status: IRosTypeBosdynApiMsgsDockStateDockedStatus;
  dock_type: IRosTypeBosdynApiMsgsDockType;
  dock_id: number;
  power_status: IRosTypeBosdynApiMsgsDockStateLinkStatus;
}

export interface IRosTypeBosdynApiMsgsDockStateDockedStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsDockStateDockedStatusConst {
  DOCK_STATUS_UNKNOWN = 0,
  DOCK_STATUS_DOCKED = 1,
  DOCK_STATUS_DOCKING = 2,
  DOCK_STATUS_UNDOCKED = 3,
  DOCK_STATUS_UNDOCKING = 4,
}

export interface IRosTypeBosdynApiMsgsDockStateLinkStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsDockStateLinkStatusConst {
  LINK_STATUS_UNKNOWN = 0,
  LINK_STATUS_DETECTING = 3,
  LINK_STATUS_CONNECTED = 1,
  LINK_STATUS_ERROR = 2,
}

export interface IRosTypeBosdynApiMsgsDockType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsDockTypeConst {
  DOCK_TYPE_UNKNOWN = 0,
  DOCK_TYPE_CONTACT_PROTOTYPE = 2,
  DOCK_TYPE_SPOT_DOCK = 3,
  DOCK_TYPE_SPOT_DOGHOUSE = 4,
}

export interface IRosTypeBosdynApiMsgsDockingCommandFeedbackRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  docking_command_id: number;
  update_docking_params: IRosTypeBosdynApiMsgsUpdateDockingParams;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDockingCommandFeedbackRequestConst {
  HEADER_FIELD_SET = 1,
  UPDATE_DOCKING_PARAMS_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsDockingCommandFeedbackResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  status: IRosTypeBosdynApiMsgsDockingCommandFeedbackResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDockingCommandFeedbackResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsDockingCommandFeedbackResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsDockingCommandFeedbackResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_IN_PROGRESS = 1,
  STATUS_DOCKED = 2,
  STATUS_AT_PREP_POSE = 11,
  STATUS_MISALIGNED = 10,
  STATUS_OLD_DOCKING_COMMAND = 3,
  STATUS_ERROR_DOCK_LOST = 4,
  STATUS_ERROR_LEASE = 5,
  STATUS_ERROR_COMMAND_TIMED_OUT = 6,
  STATUS_ERROR_NO_TIMESYNC = 7,
  STATUS_ERROR_TOO_DISTANT = 8,
  STATUS_ERROR_NOT_AVAILABLE = 12,
  STATUS_ERROR_UNREFINED_PRIOR = 13,
  STATUS_ERROR_STUCK = 14,
  STATUS_ERROR_SYSTEM = 9,
}

export interface IRosTypeBosdynApiMsgsDockingCommandRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  docking_station_id: number;
  clock_identifier: string;
  end_time: { sec: number; nanosec: number };
  prep_pose_behavior: IRosTypeBosdynApiMsgsPrepPoseBehavior;
  require_fiducial: boolean;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDockingCommandRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
  END_TIME_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsDockingCommandResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  status: IRosTypeBosdynApiMsgsDockingCommandResponseStatus;
  docking_command_id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDockingCommandResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsDockingCommandResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsDockingCommandResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_ERROR_LEASE = 4,
  STATUS_ERROR_DOCK_NOT_FOUND = 5,
  STATUS_ERROR_NOT_DOCKED = 6,
  STATUS_ERROR_GRIPPER_HOLDING_ITEM = 8,
  STATUS_ERROR_NOT_AVAILABLE = 9,
  STATUS_ERROR_SYSTEM = 7,
}

export interface IRosTypeBosdynApiMsgsDoubleParam {
  value: number;
}

export interface IRosTypeBosdynApiMsgsDoubleParamSpec {
  default_value: IRosTypeStdMsgsFloat64;
  units: IRosTypeBosdynApiMsgsUnits;
  min_value: IRosTypeStdMsgsFloat64;
  max_value: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDoubleParamSpecConst {
  DEFAULT_VALUE_FIELD_SET = 1,
  UNITS_FIELD_SET = 2,
  MIN_VALUE_FIELD_SET = 4,
  MAX_VALUE_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsDrawableArrow {
  direction: IRosTypeGeometryMsgsVector3;
  radius: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDrawableArrowConst {
  DIRECTION_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsDrawableBox {
  size: IRosTypeGeometryMsgsVector3;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDrawableBoxConst {
  SIZE_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsDrawableCapsule {
  direction: IRosTypeGeometryMsgsVector3;
  radius: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDrawableCapsuleConst {
  DIRECTION_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsDrawableCylinder {
  direction: IRosTypeGeometryMsgsVector3;
  radius: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDrawableCylinderConst {
  DIRECTION_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsDrawableFrame {
  arrow_length: number;
  arrow_radius: number;
}

export interface IRosTypeBosdynApiMsgsDrawableLineStrip {
  points: IRosTypeGeometryMsgsVector3;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDrawableLineStripConst {
  POINTS_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsDrawablePoints {
  points: IRosTypeGeometryMsgsVector3[];
}

export interface IRosTypeBosdynApiMsgsDrawableProperties {
  color: IRosTypeBosdynApiMsgsDrawablePropertiesColor;
  label: string;
  wireframe: boolean;
  frame_name_drawable: string;
  drawable: IRosTypeBosdynApiMsgsDrawablePropertiesOneOfDrawable;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsDrawablePropertiesConst {
  COLOR_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsDrawablePropertiesColor {
  r: number;
  g: number;
  b: number;
  a: number;
}

export interface IRosTypeBosdynApiMsgsDrawablePropertiesOneOfDrawable {
  frame: IRosTypeBosdynApiMsgsDrawableFrame;
  sphere: IRosTypeBosdynApiMsgsDrawableSphere;
  box: IRosTypeBosdynApiMsgsDrawableBox;
  arrow: IRosTypeBosdynApiMsgsDrawableArrow;
  capsule: IRosTypeBosdynApiMsgsDrawableCapsule;
  cylinder: IRosTypeBosdynApiMsgsDrawableCylinder;
  linestrip: IRosTypeBosdynApiMsgsDrawableLineStrip;
  points: IRosTypeBosdynApiMsgsDrawablePoints;
  drawable_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsDrawablePropertiesOneOfDrawableConst {
  DRAWABLE_NOT_SET = 0,
  DRAWABLE_FRAME_SET = 1,
  DRAWABLE_SPHERE_SET = 2,
  DRAWABLE_BOX_SET = 3,
  DRAWABLE_ARROW_SET = 4,
  DRAWABLE_CAPSULE_SET = 5,
  DRAWABLE_CYLINDER_SET = 6,
  DRAWABLE_LINESTRIP_SET = 7,
  DRAWABLE_POINTS_SET = 8,
}

export interface IRosTypeBosdynApiMsgsDrawableSphere {
  radius: number;
}

export interface IRosTypeBosdynApiMsgsEStopState {
  timestamp: { sec: number; nanosec: number };
  name: string;
  type: IRosTypeBosdynApiMsgsEStopStateType;
  state: IRosTypeBosdynApiMsgsEStopStateState;
  state_description: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsEStopStateConst {
  TIMESTAMP_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsEStopStateState {
  value: number;
}

export enum IRosTypeBosdynApiMsgsEStopStateStateConst {
  STATE_UNKNOWN = 0,
  STATE_ESTOPPED = 1,
  STATE_NOT_ESTOPPED = 2,
}

export interface IRosTypeBosdynApiMsgsEStopStateType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsEStopStateTypeConst {
  TYPE_UNKNOWN = 0,
  TYPE_HARDWARE = 1,
  TYPE_SOFTWARE = 2,
}

export interface IRosTypeBosdynApiMsgsEndpoint {
  host_ip: string;
  port: number;
}

export interface IRosTypeBosdynApiMsgsEstopCheckInRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  endpoint: IRosTypeBosdynApiMsgsEstopEndpoint;
  challenge: number;
  response: number;
  stop_level: IRosTypeBosdynApiMsgsEstopStopLevel;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsEstopCheckInRequestConst {
  HEADER_FIELD_SET = 1,
  ENDPOINT_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsEstopCheckInResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  request: IRosTypeBosdynApiMsgsEstopCheckInRequest;
  challenge: number;
  status: IRosTypeBosdynApiMsgsEstopCheckInResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsEstopCheckInResponseConst {
  HEADER_FIELD_SET = 1,
  REQUEST_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsEstopCheckInResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsEstopCheckInResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_ENDPOINT_UNKNOWN = 2,
  STATUS_INCORRECT_CHALLENGE_RESPONSE = 5,
}

export interface IRosTypeBosdynApiMsgsEstopConfig {
  endpoints: IRosTypeBosdynApiMsgsEstopEndpoint[];
  unique_id: string;
}

export interface IRosTypeBosdynApiMsgsEstopEndpoint {
  role: string;
  name: string;
  unique_id: string;
  timeout: { sec: number; nanosec: number };
  cut_power_timeout: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsEstopEndpointConst {
  TIMEOUT_FIELD_SET = 8,
  CUT_POWER_TIMEOUT_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsEstopEndpointWithStatus {
  endpoint: IRosTypeBosdynApiMsgsEstopEndpoint;
  stop_level: IRosTypeBosdynApiMsgsEstopStopLevel;
  time_since_valid_response: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsEstopEndpointWithStatusConst {
  ENDPOINT_FIELD_SET = 1,
  TIME_SINCE_VALID_RESPONSE_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsEstopStopLevel {
  value: number;
}

export enum IRosTypeBosdynApiMsgsEstopStopLevelConst {
  ESTOP_LEVEL_UNKNOWN = 0,
  ESTOP_LEVEL_CUT = 1,
  ESTOP_LEVEL_SETTLE_THEN_CUT = 2,
  ESTOP_LEVEL_NONE = 4,
}

export interface IRosTypeBosdynApiMsgsEstopSystemStatus {
  endpoints: IRosTypeBosdynApiMsgsEstopEndpointWithStatus[];
  stop_level: IRosTypeBosdynApiMsgsEstopStopLevel;
  stop_level_details: string;
}

export interface IRosTypeBosdynApiMsgsEvent {
  type: string;
  description: string;
  source: string;
  id: string;
  start_time: { sec: number; nanosec: number };
  end_time: { sec: number; nanosec: number };
  level: IRosTypeBosdynApiMsgsEventLevel;
  parameters: IRosTypeBosdynApiMsgsParameter[];
  log_preserve_hint: IRosTypeBosdynApiMsgsEventLogPreserveHint;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsEventConst {
  START_TIME_FIELD_SET = 16,
  END_TIME_FIELD_SET = 32,
}

export interface IRosTypeBosdynApiMsgsEventLevel {
  value: number;
}

export enum IRosTypeBosdynApiMsgsEventLevelConst {
  LEVEL_UNSET = 0,
  LEVEL_LOW = 1,
  LEVEL_MEDIUM = 2,
  LEVEL_HIGH = 3,
  LEVEL_MISSION_CRITICAL = 4,
  LEVEL_SYSTEM_CRITICAL = 5,
}

export interface IRosTypeBosdynApiMsgsEventLogPreserveHint {
  value: number;
}

export enum IRosTypeBosdynApiMsgsEventLogPreserveHintConst {
  LOG_PRESERVE_HINT_UNSET = 0,
  LOG_PRESERVE_HINT_NORMAL = 1,
  LOG_PRESERVE_HINT_PRESERVE = 2,
}

export interface IRosTypeBosdynApiMsgsEventSpec {
  source: string;
  type: string;
  level: IRosTypeStdMsgsInt32;
  log_preserve_hint: IRosTypeBosdynApiMsgsEventLogPreserveHint;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsEventSpecConst {
  LEVEL_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsEventsComments {
  time_range: IRosTypeBosdynApiMsgsTimeRange;
  events: IRosTypeBosdynApiMsgsEvent[];
  operator_comments: IRosTypeBosdynApiMsgsOperatorComment[];
  events_limited: boolean;
  operator_comments_limited: boolean;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsEventsCommentsConst {
  TIME_RANGE_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsEventsCommentsSpec {
  time_range: IRosTypeBosdynApiMsgsTimeRange;
  events: IRosTypeBosdynApiMsgsEventSpec[];
  comments: boolean;
  max_events: number;
  max_comments: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsEventsCommentsSpecConst {
  TIME_RANGE_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsFanPowerCommandFeedbackRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  command_id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsFanPowerCommandFeedbackRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsFanPowerCommandFeedbackResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsFanPowerCommandFeedbackResponseStatus;
  desired_end_time: { sec: number; nanosec: number };
  early_stop_time: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsFanPowerCommandFeedbackResponseConst {
  HEADER_FIELD_SET = 1,
  DESIRED_END_TIME_FIELD_SET = 4,
  EARLY_STOP_TIME_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsFanPowerCommandFeedbackResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsFanPowerCommandFeedbackResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_COMPLETE = 1,
  STATUS_RUNNING = 2,
  STATUS_TEMPERATURE_STOP = 3,
  STATUS_OVERRIDDEN_BY_COMMAND = 4,
}

export interface IRosTypeBosdynApiMsgsFanPowerCommandRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  percent_power: number;
  duration: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsFanPowerCommandRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
  DURATION_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsFanPowerCommandResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  status: IRosTypeBosdynApiMsgsFanPowerCommandResponseStatus;
  desired_end_time: { sec: number; nanosec: number };
  command_id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsFanPowerCommandResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
  DESIRED_END_TIME_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsFanPowerCommandResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsFanPowerCommandResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_TEMPERATURE_TOO_HIGH = 2,
}

export interface IRosTypeBosdynApiMsgsFileFormatDescriptor {
  version: IRosTypeBosdynApiMsgsFileFormatVersion;
  annotations: IRosTypeBosdynApiMsgsFileFormatDescriptorAnnotationsEntry[];
  checksum_type: IRosTypeBosdynApiMsgsFileFormatDescriptorCheckSumType;
  checksum_num_bytes: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsFileFormatDescriptorConst {
  VERSION_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsFileFormatDescriptorAnnotationsEntry {
  key: string;
  value: string;
}

export interface IRosTypeBosdynApiMsgsFileFormatDescriptorCheckSumType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsFileFormatDescriptorCheckSumTypeConst {
  CHECKSUM_TYPE_UNKNOWN = 0,
  CHECKSUM_TYPE_NONE = 1,
  CHECKSUM_TYPE_SHA1 = 2,
}

export interface IRosTypeBosdynApiMsgsFileFormatVersion {
  major_version: number;
  minor_version: number;
  patch_level: number;
}

export interface IRosTypeBosdynApiMsgsFileIndex {
  series_identifiers: IRosTypeBosdynApiMsgsSeriesIdentifier[];
  series_block_index_offsets: number[];
  series_identifier_hashes: number[];
}

export interface IRosTypeBosdynApiMsgsFollowArmCommandRequest {
  body_offset_from_hand: IRosTypeGeometryMsgsVector3;
  disable_walking: boolean;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsFollowArmCommandRequestConst {
  BODY_OFFSET_FROM_HAND_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsFootState {
  foot_position_rt_body: IRosTypeGeometryMsgsVector3;
  contact: IRosTypeBosdynApiMsgsFootStateContact;
  terrain: IRosTypeBosdynApiMsgsFootStateTerrainState;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsFootStateConst {
  FOOT_POSITION_RT_BODY_FIELD_SET = 1,
  TERRAIN_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsFootStateContact {
  value: number;
}

export enum IRosTypeBosdynApiMsgsFootStateContactConst {
  CONTACT_UNKNOWN = 0,
  CONTACT_MADE = 1,
  CONTACT_LOST = 2,
}

export interface IRosTypeBosdynApiMsgsFootStateTerrainState {
  ground_mu_est: number;
  frame_name: string;
  foot_slip_distance_rt_frame: IRosTypeGeometryMsgsVector3;
  foot_slip_velocity_rt_frame: IRosTypeGeometryMsgsVector3;
  ground_contact_normal_rt_frame: IRosTypeGeometryMsgsVector3;
  visual_surface_ground_penetration_mean: number;
  visual_surface_ground_penetration_std: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsFootStateTerrainStateConst {
  FOOT_SLIP_DISTANCE_RT_FRAME_FIELD_SET = 4,
  FOOT_SLIP_VELOCITY_RT_FRAME_FIELD_SET = 8,
  GROUND_CONTACT_NORMAL_RT_FRAME_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsFrameTreeSnapshot {
  child_to_parent_edge_map: IRosTypeBosdynApiMsgsFrameTreeSnapshotChildToParentEdgeMapEntry[];
}

export interface IRosTypeBosdynApiMsgsFrameTreeSnapshotChildToParentEdgeMapEntry {
  key: string;
  value: IRosTypeBosdynApiMsgsFrameTreeSnapshotParentEdge;
}

export interface IRosTypeBosdynApiMsgsFrameTreeSnapshotParentEdge {
  parent_frame_name: string;
  parent_tform_child: IRosTypeGeometryMsgsPose;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsFrameTreeSnapshotParentEdgeConst {
  PARENT_TFORM_CHILD_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsFullBodyCommandFeedback {
  status: IRosTypeBosdynApiMsgsRobotCommandFeedbackStatusStatus;
  feedback: IRosTypeBosdynApiMsgsFullBodyCommandFeedbackOneOfFeedback;
}

export interface IRosTypeBosdynApiMsgsFullBodyCommandFeedbackOneOfFeedback {
  selfright_feedback: IRosTypeBosdynApiMsgsSelfRightCommandFeedback;
  safe_power_off_feedback: IRosTypeBosdynApiMsgsSafePowerOffCommandFeedback;
  battery_change_pose_feedback: IRosTypeBosdynApiMsgsBatteryChangePoseCommandFeedback;
  payload_estimation_feedback: IRosTypeBosdynApiMsgsPayloadEstimationCommandFeedback;
  constrained_manipulation_feedback: IRosTypeBosdynApiMsgsConstrainedManipulationCommandFeedback;
  joint_feedback: IRosTypeBosdynApiMsgsJointCommandFeedback;
  feedback_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsFullBodyCommandFeedbackOneOfFeedbackConst {
  FEEDBACK_NOT_SET = 0,
  FEEDBACK_STOP_FEEDBACK_SET = 1,
  FEEDBACK_FREEZE_FEEDBACK_SET = 2,
  FEEDBACK_SELFRIGHT_FEEDBACK_SET = 3,
  FEEDBACK_SAFE_POWER_OFF_FEEDBACK_SET = 4,
  FEEDBACK_BATTERY_CHANGE_POSE_FEEDBACK_SET = 5,
  FEEDBACK_PAYLOAD_ESTIMATION_FEEDBACK_SET = 6,
  FEEDBACK_CONSTRAINED_MANIPULATION_FEEDBACK_SET = 7,
  FEEDBACK_JOINT_FEEDBACK_SET = 8,
}

export interface IRosTypeBosdynApiMsgsFullBodyCommandRequest {
  params: IRosTypeProto2RosAnyProto;
  command: IRosTypeBosdynApiMsgsFullBodyCommandRequestOneOfCommand;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsFullBodyCommandRequestConst {
  PARAMS_FIELD_SET = 256,
}

export interface IRosTypeBosdynApiMsgsFullBodyCommandRequestOneOfCommand {
  safe_power_off_request: IRosTypeBosdynApiMsgsSafePowerOffCommandRequest;
  battery_change_pose_request: IRosTypeBosdynApiMsgsBatteryChangePoseCommandRequest;
  constrained_manipulation_request: IRosTypeBosdynApiMsgsConstrainedManipulationCommandRequest;
  command_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsFullBodyCommandRequestOneOfCommandConst {
  COMMAND_NOT_SET = 0,
  COMMAND_STOP_REQUEST_SET = 1,
  COMMAND_FREEZE_REQUEST_SET = 2,
  COMMAND_SELFRIGHT_REQUEST_SET = 3,
  COMMAND_SAFE_POWER_OFF_REQUEST_SET = 4,
  COMMAND_BATTERY_CHANGE_POSE_REQUEST_SET = 5,
  COMMAND_PAYLOAD_ESTIMATION_REQUEST_SET = 6,
  COMMAND_CONSTRAINED_MANIPULATION_REQUEST_SET = 7,
  COMMAND_JOINT_REQUEST_SET = 8,
}

export interface IRosTypeBosdynApiMsgsGazeCommandFeedback {
  status: IRosTypeBosdynApiMsgsGazeCommandFeedbackStatus;
  gazing_at_target: boolean;
  gaze_to_target_rotation_measured: number;
  hand_position_at_goal: boolean;
  hand_distance_to_goal_measured: number;
  hand_roll_at_goal: boolean;
  hand_roll_to_target_roll_measured: number;
}

export interface IRosTypeBosdynApiMsgsGazeCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsGazeCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_TRAJECTORY_COMPLETE = 1,
  STATUS_IN_PROGRESS = 2,
  STATUS_TOOL_TRAJECTORY_STALLED = 3,
}

export interface IRosTypeBosdynApiMsgsGazeCommandRequest {
  target_trajectory_in_frame1: IRosTypeBosdynApiMsgsVec3Trajectory;
  frame1_name: string;
  tool_trajectory_in_frame2: IRosTypeBosdynApiMsgsSe3Trajectory;
  frame2_name: string;
  wrist_tform_tool: IRosTypeGeometryMsgsPose;
  target_trajectory_initial_velocity: IRosTypeStdMsgsFloat64;
  maximum_acceleration: IRosTypeStdMsgsFloat64;
  max_linear_velocity: IRosTypeStdMsgsFloat64;
  max_angular_velocity: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGazeCommandRequestConst {
  TARGET_TRAJECTORY_IN_FRAME1_FIELD_SET = 1,
  TOOL_TRAJECTORY_IN_FRAME2_FIELD_SET = 4,
  WRIST_TFORM_TOOL_FIELD_SET = 16,
  TARGET_TRAJECTORY_INITIAL_VELOCITY_FIELD_SET = 32,
  MAXIMUM_ACCELERATION_FIELD_SET = 64,
  MAX_LINEAR_VELOCITY_FIELD_SET = 128,
  MAX_ANGULAR_VELOCITY_FIELD_SET = 256,
}

export interface IRosTypeBosdynApiMsgsGetAuthTokenRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  username: string;
  password: string;
  token: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetAuthTokenRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetAuthTokenResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsGetAuthTokenResponseStatus;
  token: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetAuthTokenResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetAuthTokenResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsGetAuthTokenResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_LOGIN = 2,
  STATUS_INVALID_TOKEN = 3,
  STATUS_TEMPORARILY_LOCKED_OUT = 4,
}

export interface IRosTypeBosdynApiMsgsGetDataBufferStatusRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  get_blob_specs: boolean;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetDataBufferStatusRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetDataBufferStatusResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  data_buffer_status: IRosTypeBosdynApiMsgsDataBufferStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetDataBufferStatusResponseConst {
  HEADER_FIELD_SET = 1,
  DATA_BUFFER_STATUS_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsGetDataIndexRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  data_query: IRosTypeBosdynApiMsgsDataQuery;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetDataIndexRequestConst {
  HEADER_FIELD_SET = 1,
  DATA_QUERY_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsGetDataIndexResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  data_index: IRosTypeBosdynApiMsgsDataIndex;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetDataIndexResponseConst {
  HEADER_FIELD_SET = 1,
  DATA_INDEX_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsGetDataPagesRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  time_range: IRosTypeBosdynApiMsgsTimeRange;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetDataPagesRequestConst {
  HEADER_FIELD_SET = 1,
  TIME_RANGE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsGetDataPagesResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  pages: IRosTypeBosdynApiMsgsPageInfo[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetDataPagesResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetDockingConfigRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetDockingConfigRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetDockingConfigResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  dock_configs: IRosTypeBosdynApiMsgsConfigRange[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetDockingConfigResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetDockingStateRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetDockingStateRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetDockingStateResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  dock_state: IRosTypeBosdynApiMsgsDockState;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetDockingStateResponseConst {
  HEADER_FIELD_SET = 1,
  DOCK_STATE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsGetEstopConfigRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  target_config_id: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetEstopConfigRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetEstopConfigResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  request: IRosTypeBosdynApiMsgsGetEstopConfigRequest;
  active_config: IRosTypeBosdynApiMsgsEstopConfig;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetEstopConfigResponseConst {
  HEADER_FIELD_SET = 1,
  REQUEST_FIELD_SET = 2,
  ACTIVE_CONFIG_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsGetEstopSystemStatusRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetEstopSystemStatusRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetEstopSystemStatusResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsEstopSystemStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetEstopSystemStatusResponseConst {
  HEADER_FIELD_SET = 1,
  STATUS_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsGetEventsCommentsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  event_comment_request: IRosTypeBosdynApiMsgsEventsCommentsSpec;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetEventsCommentsRequestConst {
  HEADER_FIELD_SET = 1,
  EVENT_COMMENT_REQUEST_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsGetEventsCommentsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  events_comments: IRosTypeBosdynApiMsgsEventsComments;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetEventsCommentsResponseConst {
  HEADER_FIELD_SET = 1,
  EVENTS_COMMENTS_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsGetFeatureEnabledRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  feature_codes: string[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetFeatureEnabledRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetFeatureEnabledResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  feature_enabled: IRosTypeBosdynApiMsgsGetFeatureEnabledResponseFeatureEnabledEntry[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetFeatureEnabledResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetFeatureEnabledResponseFeatureEnabledEntry {
  key: string;
  value: boolean;
}

export interface IRosTypeBosdynApiMsgsGetImageRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  image_requests: IRosTypeBosdynApiMsgsImageRequest[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetImageRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetImageResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  image_responses: IRosTypeBosdynApiMsgsImageResponse[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetImageResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetLicenseInfoRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetLicenseInfoRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetLicenseInfoResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  license: IRosTypeBosdynApiMsgsLicenseInfo;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetLicenseInfoResponseConst {
  HEADER_FIELD_SET = 1,
  LICENSE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsGetLocalGridTypesRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetLocalGridTypesRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetLocalGridTypesResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  local_grid_type: IRosTypeBosdynApiMsgsLocalGridType[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetLocalGridTypesResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetLocalGridsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  local_grid_requests: IRosTypeBosdynApiMsgsLocalGridRequest[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetLocalGridsRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetLocalGridsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  local_grid_responses: IRosTypeBosdynApiMsgsLocalGridResponse[];
  num_local_grid_errors: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetLocalGridsResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetLocationRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetLocationRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetLocationResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsGetLocationResponseStatus;
  registration: IRosTypeBosdynApiMsgsRegistration;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetLocationResponseConst {
  HEADER_FIELD_SET = 1,
  REGISTRATION_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsGetLocationResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsGetLocationResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NEED_DEVICE = 2,
}

export interface IRosTypeBosdynApiMsgsGetPayloadAuthTokenRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  payload_credentials: IRosTypeBosdynApiMsgsPayloadCredentials;
  payload_guid: string;
  payload_secret: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetPayloadAuthTokenRequestConst {
  HEADER_FIELD_SET = 1,
  PAYLOAD_CREDENTIALS_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsGetPayloadAuthTokenResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsGetPayloadAuthTokenResponseStatus;
  token: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetPayloadAuthTokenResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetPayloadAuthTokenResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsGetPayloadAuthTokenResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_CREDENTIALS = 2,
  STATUS_PAYLOAD_NOT_AUTHORIZED = 3,
}

export interface IRosTypeBosdynApiMsgsGetPointCloudRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  point_cloud_requests: IRosTypeBosdynApiMsgsPointCloudRequest[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetPointCloudRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetPointCloudResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  point_cloud_responses: IRosTypeBosdynApiMsgsPointCloudResponse[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetPointCloudResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetServiceEntryRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  service_name: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetServiceEntryRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetServiceEntryResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsGetServiceEntryResponseStatus;
  service_entry: IRosTypeBosdynApiMsgsServiceEntry;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetServiceEntryResponseConst {
  HEADER_FIELD_SET = 1,
  SERVICE_ENTRY_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsGetServiceEntryResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsGetServiceEntryResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NONEXISTENT_SERVICE = 2,
}

export interface IRosTypeBosdynApiMsgsGetServiceInfoRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetServiceInfoRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetServiceInfoResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  capabilities: IRosTypeBosdynApiMsgsAcquisitionCapabilityList;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetServiceInfoResponseConst {
  HEADER_FIELD_SET = 1,
  CAPABILITIES_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsGetStatusRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  request_id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetStatusRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetStatusResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsGetStatusResponseStatus;
  data_saved: IRosTypeBosdynApiMsgsDataIdentifier[];
  data_errors: IRosTypeBosdynApiMsgsDataError[];
  service_errors: IRosTypeBosdynApiMsgsPluginServiceError[];
  network_compute_errors: IRosTypeBosdynApiMsgsNetworkComputeError[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGetStatusResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGetStatusResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsGetStatusResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_ACQUIRING = 1,
  STATUS_SAVING = 2,
  STATUS_COMPLETE = 3,
  STATUS_CANCEL_IN_PROGRESS = 4,
  STATUS_ACQUISITION_CANCELLED = 5,
  STATUS_DATA_ERROR = 10,
  STATUS_TIMEDOUT = 11,
  STATUS_INTERNAL_ERROR = 12,
  STATUS_CANCEL_ACQUISITION_FAILED = 30,
  STATUS_REQUEST_ID_DOES_NOT_EXIST = 20,
}

export interface IRosTypeBosdynApiMsgsGpsDataPoint {
  llh: IRosTypeBosdynApiMsgsLlh;
  ecef: IRosTypeGeometryMsgsVector3;
  yaw: IRosTypeStdMsgsFloat64;
  heading: IRosTypeStdMsgsFloat64;
  accuracy: IRosTypeBosdynApiMsgsGpsDataPointAccuracy;
  satellites: IRosTypeBosdynApiMsgsGpsDataPointSatellite[];
  mode: IRosTypeBosdynApiMsgsGpsDataPointFixMode;
  timestamp_gps: { sec: number; nanosec: number };
  filter: IRosTypeBosdynApiMsgsGpsDataPointFilter;
  timestamp_client: { sec: number; nanosec: number };
  timestamp_robot: { sec: number; nanosec: number };
  body_tform_gps: IRosTypeGeometryMsgsPose;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGpsDataPointConst {
  LLH_FIELD_SET = 1,
  ECEF_FIELD_SET = 2,
  YAW_FIELD_SET = 4,
  HEADING_FIELD_SET = 8,
  ACCURACY_FIELD_SET = 16,
  MODE_FIELD_SET = 64,
  TIMESTAMP_GPS_FIELD_SET = 128,
  TIMESTAMP_CLIENT_FIELD_SET = 512,
  TIMESTAMP_ROBOT_FIELD_SET = 1024,
  BODY_TFORM_GPS_FIELD_SET = 2048,
}

export interface IRosTypeBosdynApiMsgsGpsDataPointAccuracy {
  horizontal: number;
  vertical: number;
}

export interface IRosTypeBosdynApiMsgsGpsDataPointFilter {
  value: number;
}

export enum IRosTypeBosdynApiMsgsGpsDataPointFilterConst {
  FILTER_UNKNOWN = 0,
  FILTER_NONE = 1,
  FILTER_DURO_INS = 2,
}

export interface IRosTypeBosdynApiMsgsGpsDataPointFixMode {
  value: IRosTypeBosdynApiMsgsGpsDataPointFixModeMode;
}

export interface IRosTypeBosdynApiMsgsGpsDataPointFixModeMode {
  value: number;
}

export enum IRosTypeBosdynApiMsgsGpsDataPointFixModeModeConst {
  INVALID = 0,
  SPP = 1,
  DGNSS = 2,
  PPS = 3,
  FIXED_RTK = 4,
  FLOAT_RTK = 5,
  DEAD_RECKONING = 6,
  FIXED_POSITION = 7,
  SIMULATED = 8,
  SBAS = 9,
}

export interface IRosTypeBosdynApiMsgsGpsDataPointSatellite {
  prn: number;
  elevation: number;
  azimuth: number;
  snr: number;
  constellation: IRosTypeBosdynApiMsgsGpsDataPointSatelliteConstellation;
}

export interface IRosTypeBosdynApiMsgsGpsDataPointSatelliteConstellation {
  value: number;
}

export enum IRosTypeBosdynApiMsgsGpsDataPointSatelliteConstellationConst {
  UNKNOWN = 0,
  GPS_L1CA = 1,
  GPS_L2CM = 2,
  SBAS_L1CA = 3,
  GLONASS_L1CA = 4,
  GLONASS_L2CA = 5,
  GPS_L1P = 6,
  GPS_L2P = 7,
  BDS2_B1 = 8,
  BDS2_B2 = 9,
  GALILEO_E1B = 10,
  GALILEO_E7I = 11,
}

export interface IRosTypeBosdynApiMsgsGpsDevice {
  name: string;
}

export interface IRosTypeBosdynApiMsgsGpsProperties {
  registration: IRosTypeBosdynApiMsgsRegistration;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGpsPropertiesConst {
  REGISTRATION_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGpsState {
  latest_data: IRosTypeBosdynApiMsgsGpsDataPoint;
  gps_device: IRosTypeBosdynApiMsgsGpsDevice;
  ecef_p_body: IRosTypeGeometryMsgsVector3;
  historical_data: IRosTypeBosdynApiMsgsGpsDataPoint[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGpsStateConst {
  LATEST_DATA_FIELD_SET = 1,
  GPS_DEVICE_FIELD_SET = 2,
  ECEF_P_BODY_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsGraspParams {
  grasp_palm_to_fingertip: number;
  grasp_params_frame_name: string;
  allowable_orientation: IRosTypeBosdynApiMsgsAllowableOrientation[];
  position_constraint: IRosTypeBosdynApiMsgsGraspPositionConstraint;
  manipulation_camera_source: IRosTypeBosdynApiMsgsManipulationCameraSource;
}

export interface IRosTypeBosdynApiMsgsGraspPositionConstraint {
  value: number;
}

export enum IRosTypeBosdynApiMsgsGraspPositionConstraintConst {
  GRASP_POSITION_CONSTRAINT_UNKNOWN = 0,
  GRASP_POSITION_CONSTRAINT_NORMAL = 1,
  GRASP_POSITION_CONSTRAINT_FIXED_AT_USER_POSITION = 2,
}

export interface IRosTypeBosdynApiMsgsGripperCameraGetParamRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGripperCameraGetParamRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGripperCameraGetParamResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  params: IRosTypeBosdynApiMsgsGripperCameraParams;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGripperCameraGetParamResponseConst {
  HEADER_FIELD_SET = 1,
  PARAMS_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsGripperCameraParamRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  params: IRosTypeBosdynApiMsgsGripperCameraParams;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGripperCameraParamRequestConst {
  HEADER_FIELD_SET = 1,
  PARAMS_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsGripperCameraParamResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGripperCameraParamResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGripperCameraParams {
  camera_mode: IRosTypeBosdynApiMsgsGripperCameraParamsCameraMode;
  brightness: IRosTypeStdMsgsFloat32;
  contrast: IRosTypeStdMsgsFloat32;
  saturation: IRosTypeStdMsgsFloat32;
  gain: IRosTypeStdMsgsFloat32;
  exposure_auto: IRosTypeStdMsgsBool;
  exposure_absolute: IRosTypeStdMsgsFloat32;
  exposure_roi: IRosTypeBosdynApiMsgsRoiParameters;
  focus_auto: IRosTypeStdMsgsBool;
  focus_absolute: IRosTypeStdMsgsFloat32;
  focus_roi: IRosTypeBosdynApiMsgsRoiParameters;
  draw_focus_roi_rectangle: IRosTypeStdMsgsBool;
  hdr: IRosTypeBosdynApiMsgsHdrParameters;
  led_mode: IRosTypeBosdynApiMsgsGripperCameraParamsLedMode;
  led_torch_brightness: IRosTypeStdMsgsFloat32;
  white_balance_temperature_auto: IRosTypeStdMsgsBool;
  gamma: IRosTypeStdMsgsFloat32;
  white_balance_temperature: IRosTypeStdMsgsFloat32;
  sharpness: IRosTypeStdMsgsFloat32;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGripperCameraParamsConst {
  BRIGHTNESS_FIELD_SET = 2,
  CONTRAST_FIELD_SET = 4,
  SATURATION_FIELD_SET = 8,
  GAIN_FIELD_SET = 16,
  EXPOSURE_AUTO_FIELD_SET = 32,
  EXPOSURE_ABSOLUTE_FIELD_SET = 64,
  EXPOSURE_ROI_FIELD_SET = 128,
  FOCUS_AUTO_FIELD_SET = 256,
  FOCUS_ABSOLUTE_FIELD_SET = 512,
  FOCUS_ROI_FIELD_SET = 1024,
  DRAW_FOCUS_ROI_RECTANGLE_FIELD_SET = 2048,
  LED_TORCH_BRIGHTNESS_FIELD_SET = 16384,
  WHITE_BALANCE_TEMPERATURE_AUTO_FIELD_SET = 32768,
  GAMMA_FIELD_SET = 65536,
  WHITE_BALANCE_TEMPERATURE_FIELD_SET = 131072,
  SHARPNESS_FIELD_SET = 262144,
}

export interface IRosTypeBosdynApiMsgsGripperCameraParamsCameraMode {
  value: number;
}

export enum IRosTypeBosdynApiMsgsGripperCameraParamsCameraModeConst {
  MODE_UNKNOWN = 0,
  MODE_640_480 = 11,
  MODE_640_480_120FPS_UYVY = 11,
  MODE_1280_720 = 1,
  MODE_1280_720_60FPS_UYVY = 1,
  MODE_1920_1080 = 14,
  MODE_1920_1080_60FPS_MJPG = 14,
  MODE_3840_2160 = 15,
  MODE_3840_2160_30FPS_MJPG = 15,
  MODE_4096_2160 = 17,
  MODE_4096_2160_30FPS_MJPG = 17,
  MODE_4208_3120 = 16,
  MODE_4208_3120_20FPS_MJPG = 16,
}

export interface IRosTypeBosdynApiMsgsGripperCameraParamsLedMode {
  value: number;
}

export enum IRosTypeBosdynApiMsgsGripperCameraParamsLedModeConst {
  LED_MODE_UNKNOWN = 0,
  LED_MODE_OFF = 1,
  LED_MODE_TORCH = 2,
}

export interface IRosTypeBosdynApiMsgsGripperCommandFeedback {
  status: IRosTypeBosdynApiMsgsRobotCommandFeedbackStatusStatus;
  command: IRosTypeBosdynApiMsgsGripperCommandFeedbackOneOfCommand;
}

export interface IRosTypeBosdynApiMsgsGripperCommandFeedbackOneOfCommand {
  claw_gripper_feedback: IRosTypeBosdynApiMsgsClawGripperCommandFeedback;
  command_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsGripperCommandFeedbackOneOfCommandConst {
  COMMAND_NOT_SET = 0,
  COMMAND_CLAW_GRIPPER_FEEDBACK_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGripperCommandRequest {
  command: IRosTypeBosdynApiMsgsGripperCommandRequestOneOfCommand;
}

export interface IRosTypeBosdynApiMsgsGripperCommandRequestOneOfCommand {
  claw_gripper_command: IRosTypeBosdynApiMsgsClawGripperCommandRequest;
  command_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsGripperCommandRequestOneOfCommandConst {
  COMMAND_NOT_SET = 0,
  COMMAND_CLAW_GRIPPER_COMMAND_SET = 1,
}

export interface IRosTypeBosdynApiMsgsGrpcPages {
  time_range: IRosTypeBosdynApiMsgsTimeRange;
  spec: IRosTypeBosdynApiMsgsGrpcSpec;
  pages: IRosTypeBosdynApiMsgsPageInfo[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsGrpcPagesConst {
  TIME_RANGE_FIELD_SET = 1,
  SPEC_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsGrpcSpec {
  service_name: string;
}

export interface IRosTypeBosdynApiMsgsHardwareConfiguration {
  skeleton: IRosTypeBosdynApiMsgsSkeleton;
  can_power_command_request_off_robot: boolean;
  can_power_command_request_cycle_robot: boolean;
  can_power_command_request_payload_ports: boolean;
  can_power_command_request_wifi_radio: boolean;
  has_audio_visual_system: boolean;
  redundant_safety_stop_enabled: boolean;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsHardwareConfigurationConst {
  SKELETON_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsHdrParameters {
  value: number;
}

export enum IRosTypeBosdynApiMsgsHdrParametersConst {
  HDR_UNKNOWN = 0,
  HDR_OFF = 1,
  HDR_AUTO = 2,
  HDR_MANUAL_1 = 3,
  HDR_MANUAL_2 = 4,
  HDR_MANUAL_3 = 5,
  HDR_MANUAL_4 = 6,
}

export interface IRosTypeBosdynApiMsgsImage {
  cols: number;
  rows: number;
  data: number[];
  format: IRosTypeBosdynApiMsgsImageFormat;
  pixel_format: IRosTypeBosdynApiMsgsImagePixelFormat;
}

export interface IRosTypeBosdynApiMsgsImageAcquisitionCapability {
  service_name: string;
  image_source_names: string[];
  image_sources: IRosTypeBosdynApiMsgsImageSource[];
}

export interface IRosTypeBosdynApiMsgsImageCapture {
  acquisition_time: { sec: number; nanosec: number };
  transforms_snapshot: IRosTypeBosdynApiMsgsFrameTreeSnapshot;
  frame_name_image_sensor: string;
  image: IRosTypeBosdynApiMsgsImage;
  capture_params: IRosTypeBosdynApiMsgsCaptureParameters;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsImageCaptureConst {
  ACQUISITION_TIME_FIELD_SET = 1,
  TRANSFORMS_SNAPSHOT_FIELD_SET = 2,
  IMAGE_FIELD_SET = 8,
  CAPTURE_PARAMS_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsImageCaptureAndSource {
  shot: IRosTypeBosdynApiMsgsImageCapture;
  source: IRosTypeBosdynApiMsgsImageSource;
  image_service: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsImageCaptureAndSourceConst {
  SHOT_FIELD_SET = 1,
  SOURCE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsImageFormat {
  value: number;
}

export enum IRosTypeBosdynApiMsgsImageFormatConst {
  FORMAT_UNKNOWN = 0,
  FORMAT_JPEG = 1,
  FORMAT_RAW = 2,
  FORMAT_RLE = 3,
}

export interface IRosTypeBosdynApiMsgsImagePixelFormat {
  value: number;
}

export enum IRosTypeBosdynApiMsgsImagePixelFormatConst {
  PIXEL_FORMAT_UNKNOWN = 0,
  PIXEL_FORMAT_GREYSCALE_U8 = 1,
  PIXEL_FORMAT_RGB_U8 = 3,
  PIXEL_FORMAT_RGBA_U8 = 4,
  PIXEL_FORMAT_DEPTH_U16 = 5,
  PIXEL_FORMAT_GREYSCALE_U16 = 6,
}

export interface IRosTypeBosdynApiMsgsImageProperties {
  camera_source: string;
  image_source: IRosTypeBosdynApiMsgsImageSource;
  image_capture: IRosTypeBosdynApiMsgsImageCapture;
  frame_name_image_coordinates: string;
  image_data: IRosTypeBosdynApiMsgsImagePropertiesOneOfImageData;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsImagePropertiesConst {
  IMAGE_SOURCE_FIELD_SET = 8,
  IMAGE_CAPTURE_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsImagePropertiesOneOfImageData {
  coordinates: IRosTypeBosdynApiMsgsPolygon;
  keypoints: IRosTypeBosdynApiMsgsKeypointSet;
  image_data_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsImagePropertiesOneOfImageDataConst {
  IMAGE_DATA_NOT_SET = 0,
  IMAGE_DATA_COORDINATES_SET = 1,
  IMAGE_DATA_KEYPOINTS_SET = 2,
}

export interface IRosTypeBosdynApiMsgsImageRequest {
  image_source_name: string;
  quality_percent: number;
  image_format: IRosTypeBosdynApiMsgsImageFormat;
  resize_ratio: number;
  pixel_format: IRosTypeBosdynApiMsgsImagePixelFormat;
  custom_params: IRosTypeBosdynApiMsgsDictParam;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsImageRequestConst {
  CUSTOM_PARAMS_FIELD_SET = 32,
}

export interface IRosTypeBosdynApiMsgsImageResponse {
  shot: IRosTypeBosdynApiMsgsImageCapture;
  source: IRosTypeBosdynApiMsgsImageSource;
  status: IRosTypeBosdynApiMsgsImageResponseStatus;
  custom_param_error: IRosTypeBosdynApiMsgsCustomParamError;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsImageResponseConst {
  SHOT_FIELD_SET = 1,
  SOURCE_FIELD_SET = 2,
  CUSTOM_PARAM_ERROR_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsImageResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsImageResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_UNKNOWN_CAMERA = 2,
  STATUS_SOURCE_DATA_ERROR = 3,
  STATUS_IMAGE_DATA_ERROR = 4,
  STATUS_UNSUPPORTED_IMAGE_FORMAT_REQUESTED = 5,
  STATUS_UNSUPPORTED_PIXEL_FORMAT_REQUESTED = 6,
  STATUS_UNSUPPORTED_RESIZE_RATIO_REQUESTED = 7,
  STATUS_CUSTOM_PARAMS_ERROR = 8,
}

export interface IRosTypeBosdynApiMsgsImageSource {
  name: string;
  cols: number;
  rows: number;
  depth_scale: number;
  image_type: IRosTypeBosdynApiMsgsImageSourceImageType;
  pixel_formats: IRosTypeBosdynApiMsgsImagePixelFormat[];
  image_formats: IRosTypeBosdynApiMsgsImageFormat[];
  custom_params: IRosTypeBosdynApiMsgsDictParamSpec;
  camera_models: IRosTypeBosdynApiMsgsImageSourceOneOfCameraModels;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsImageSourceConst {
  CUSTOM_PARAMS_FIELD_SET = 256,
}

export interface IRosTypeBosdynApiMsgsImageSourceAndService {
  image_service: string;
  request_data: IRosTypeBosdynApiMsgsImageSourceAndServiceOneOfRequestData;
}

export interface IRosTypeBosdynApiMsgsImageSourceAndServiceOneOfRequestData {
  image_source: string;
  image_request: IRosTypeBosdynApiMsgsImageRequest;
  request_data_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsImageSourceAndServiceOneOfRequestDataConst {
  REQUEST_DATA_NOT_SET = 0,
  REQUEST_DATA_IMAGE_SOURCE_SET = 1,
  REQUEST_DATA_IMAGE_REQUEST_SET = 2,
}

export interface IRosTypeBosdynApiMsgsImageSourceCapture {
  image_service: string;
  image_request: IRosTypeBosdynApiMsgsImageRequest;
  image_source: string;
  pixel_format: IRosTypeBosdynApiMsgsImagePixelFormat;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsImageSourceCaptureConst {
  IMAGE_REQUEST_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsImageSourceImageType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsImageSourceImageTypeConst {
  IMAGE_TYPE_UNKNOWN = 0,
  IMAGE_TYPE_VISUAL = 1,
  IMAGE_TYPE_DEPTH = 2,
}

export interface IRosTypeBosdynApiMsgsImageSourceOneOfCameraModels {
  pinhole: IRosTypeBosdynApiMsgsImageSourcePinholeModel;
  camera_models_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsImageSourceOneOfCameraModelsConst {
  CAMERA_MODELS_NOT_SET = 0,
  CAMERA_MODELS_PINHOLE_SET = 1,
}

export interface IRosTypeBosdynApiMsgsImageSourcePinholeModel {
  intrinsics: IRosTypeBosdynApiMsgsImageSourcePinholeModelCameraIntrinsics;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsImageSourcePinholeModelConst {
  INTRINSICS_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsImageSourcePinholeModelCameraIntrinsics {
  focal_length: IRosTypeBosdynApiMsgsVec2;
  principal_point: IRosTypeBosdynApiMsgsVec2;
  skew: IRosTypeBosdynApiMsgsVec2;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsImageSourcePinholeModelCameraIntrinsicsConst {
  FOCAL_LENGTH_FIELD_SET = 1,
  PRINCIPAL_POINT_FIELD_SET = 2,
  SKEW_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsImuState {
  packet_rate: number;
  packets: IRosTypeBosdynApiMsgsImuStatePacket[];
  identifier: string;
  mounting_link_name: string;
  position_imu_rt_link: IRosTypeGeometryMsgsVector3;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsImuStateConst {
  POSITION_IMU_RT_LINK_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsImuStatePacket {
  acceleration_rt_odom_in_link_frame: IRosTypeGeometryMsgsVector3;
  angular_velocity_rt_odom_in_link_frame: IRosTypeGeometryMsgsVector3;
  odom_rot_link: IRosTypeGeometryMsgsQuaternion;
  timestamp: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsImuStatePacketConst {
  ACCELERATION_RT_ODOM_IN_LINK_FRAME_FIELD_SET = 1,
  ANGULAR_VELOCITY_RT_ODOM_IN_LINK_FRAME_FIELD_SET = 2,
  ODOM_ROT_LINK_FIELD_SET = 4,
  TIMESTAMP_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsInt64Param {
  value: number;
}

export interface IRosTypeBosdynApiMsgsInt64ParamSpec {
  default_value: IRosTypeStdMsgsInt64;
  units: IRosTypeBosdynApiMsgsUnits;
  min_value: IRosTypeStdMsgsInt64;
  max_value: IRosTypeStdMsgsInt64;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsInt64ParamSpecConst {
  DEFAULT_VALUE_FIELD_SET = 1,
  UNITS_FIELD_SET = 2,
  MIN_VALUE_FIELD_SET = 4,
  MAX_VALUE_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsIrEnableDisableRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  request: IRosTypeBosdynApiMsgsIrEnableDisableRequestRequest;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsIrEnableDisableRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsIrEnableDisableRequestRequest {
  value: number;
}

export enum IRosTypeBosdynApiMsgsIrEnableDisableRequestRequestConst {
  REQUEST_UNKNOWN = 0,
  REQUEST_OFF = 1,
  REQUEST_ON = 2,
}

export interface IRosTypeBosdynApiMsgsIrEnableDisableResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsIrEnableDisableResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsJointCommandFeedback {
  status: IRosTypeBosdynApiMsgsJointCommandFeedbackStatus;
  num_messages_received: number;
}

export interface IRosTypeBosdynApiMsgsJointCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsJointCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_ACTIVE = 1,
  STATUS_ERROR = 2,
}

export interface IRosTypeBosdynApiMsgsJointCommandUpdateRequest {
  end_time: { sec: number; nanosec: number };
  reference_time: { sec: number; nanosec: number };
  extrapolation_duration: { sec: number; nanosec: number };
  position: number[];
  velocity: number[];
  load: number[];
  gains: IRosTypeBosdynApiMsgsJointCommandUpdateRequestGains;
  user_command_key: number;
  velocity_safety_limit: IRosTypeStdMsgsFloat32;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsJointCommandUpdateRequestConst {
  END_TIME_FIELD_SET = 1,
  REFERENCE_TIME_FIELD_SET = 2,
  EXTRAPOLATION_DURATION_FIELD_SET = 4,
  GAINS_FIELD_SET = 64,
  VELOCITY_SAFETY_LIMIT_FIELD_SET = 256,
}

export interface IRosTypeBosdynApiMsgsJointCommandUpdateRequestGains {
  k_q_p: number[];
  k_qd_p: number[];
}

export interface IRosTypeBosdynApiMsgsJointControlStreamRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  joint_command: IRosTypeBosdynApiMsgsJointCommandUpdateRequest;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsJointControlStreamRequestConst {
  HEADER_FIELD_SET = 1,
  JOINT_COMMAND_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsJointControlStreamResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsJointControlStreamResponseStatus;
  message: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsJointControlStreamResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsJointControlStreamResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsJointControlStreamResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_REQUEST = 2,
  STATUS_INACTIVE = 3,
  STATUS_EXPIRED = 4,
  STATUS_TOO_DISTANT = 5,
}

export interface IRosTypeBosdynApiMsgsJointLimits {
  label: string;
  hy: number[];
  hx: number[];
}

export interface IRosTypeBosdynApiMsgsJointState {
  name: string;
  position: IRosTypeStdMsgsFloat64;
  velocity: IRosTypeStdMsgsFloat64;
  acceleration: IRosTypeStdMsgsFloat64;
  load: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsJointStateConst {
  POSITION_FIELD_SET = 2,
  VELOCITY_FIELD_SET = 4,
  ACCELERATION_FIELD_SET = 8,
  LOAD_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsKeypoint {
  coordinates: IRosTypeBosdynApiMsgsVec2;
  binary_descriptor: number[];
  score: number;
  size: number;
  angle: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsKeypointConst {
  COORDINATES_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsKeypointMatches {
  reference_keypoints: IRosTypeBosdynApiMsgsKeypointSet;
  live_keypoints: IRosTypeBosdynApiMsgsKeypointSet;
  matches: IRosTypeBosdynApiMsgsMatch[];
  type: IRosTypeBosdynApiMsgsKeypointMatchesMatchType;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsKeypointMatchesConst {
  REFERENCE_KEYPOINTS_FIELD_SET = 1,
  LIVE_KEYPOINTS_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsKeypointMatchesMatchType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsKeypointMatchesMatchTypeConst {
  MATCH_UNKNOWN = 0,
  MATCH_ORB = 1,
  MATCH_LIGHTGLUE = 2,
}

export interface IRosTypeBosdynApiMsgsKeypointSet {
  keypoints: IRosTypeBosdynApiMsgsKeypoint[];
  type: IRosTypeBosdynApiMsgsKeypointSetKeypointType;
}

export interface IRosTypeBosdynApiMsgsKeypointSetKeypointType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsKeypointSetKeypointTypeConst {
  KEYPOINT_UNKNOWN = 0,
  KEYPOINT_SIMPLE = 1,
  KEYPOINT_ORB = 2,
  KEYPOINT_DISK = 3,
}

export interface IRosTypeBosdynApiMsgsKinematicState {
  joint_states: IRosTypeBosdynApiMsgsJointState[];
  acquisition_timestamp: { sec: number; nanosec: number };
  transforms_snapshot: IRosTypeBosdynApiMsgsFrameTreeSnapshot;
  velocity_of_body_in_vision: IRosTypeGeometryMsgsTwist;
  velocity_of_body_in_odom: IRosTypeGeometryMsgsTwist;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsKinematicStateConst {
  ACQUISITION_TIMESTAMP_FIELD_SET = 2,
  TRANSFORMS_SNAPSHOT_FIELD_SET = 4,
  VELOCITY_OF_BODY_IN_VISION_FIELD_SET = 8,
  VELOCITY_OF_BODY_IN_ODOM_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsLease {
  resource: string;
  epoch: string;
  sequence: number[];
  client_names: string[];
}

export interface IRosTypeBosdynApiMsgsLeaseOwner {
  client_name: string;
  user_name: string;
}

export interface IRosTypeBosdynApiMsgsLeaseResource {
  resource: string;
  lease: IRosTypeBosdynApiMsgsLease;
  lease_owner: IRosTypeBosdynApiMsgsLeaseOwner;
  stale_time: { sec: number; nanosec: number };
  is_stale: boolean;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsLeaseResourceConst {
  LEASE_FIELD_SET = 2,
  LEASE_OWNER_FIELD_SET = 4,
  STALE_TIME_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsLeaseUseResult {
  status: IRosTypeBosdynApiMsgsLeaseUseResultStatus;
  owner: IRosTypeBosdynApiMsgsLeaseOwner;
  attempted_lease: IRosTypeBosdynApiMsgsLease;
  previous_lease: IRosTypeBosdynApiMsgsLease;
  latest_known_lease: IRosTypeBosdynApiMsgsLease;
  latest_resources: IRosTypeBosdynApiMsgsLease[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsLeaseUseResultConst {
  OWNER_FIELD_SET = 2,
  ATTEMPTED_LEASE_FIELD_SET = 4,
  PREVIOUS_LEASE_FIELD_SET = 8,
  LATEST_KNOWN_LEASE_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsLeaseUseResultStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsLeaseUseResultStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_LEASE = 2,
  STATUS_OLDER = 3,
  STATUS_REVOKED = 4,
  STATUS_UNMANAGED = 5,
  STATUS_WRONG_EPOCH = 6,
}

export interface IRosTypeBosdynApiMsgsLicenseInfo {
  status: IRosTypeBosdynApiMsgsLicenseInfoStatus;
  id: string;
  robot_serial: string;
  not_valid_before: { sec: number; nanosec: number };
  not_valid_after: { sec: number; nanosec: number };
  licensed_features: string[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsLicenseInfoConst {
  NOT_VALID_BEFORE_FIELD_SET = 8,
  NOT_VALID_AFTER_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsLicenseInfoStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsLicenseInfoStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_VALID = 1,
  STATUS_EXPIRED = 2,
  STATUS_NOT_YET_VALID = 3,
  STATUS_MALFORMED = 4,
  STATUS_SERIAL_MISMATCH = 5,
  STATUS_NO_LICENSE = 6,
}

export interface IRosTypeBosdynApiMsgsListAvailableModelsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  server_config: IRosTypeBosdynApiMsgsNetworkComputeServerConfiguration;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListAvailableModelsRequestConst {
  HEADER_FIELD_SET = 1,
  SERVER_CONFIG_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsListAvailableModelsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  available_models: string[];
  labels: IRosTypeBosdynApiMsgsModelLabels[];
  models: IRosTypeBosdynApiMsgsAvailableModels;
  status: IRosTypeBosdynApiMsgsListAvailableModelsStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListAvailableModelsResponseConst {
  HEADER_FIELD_SET = 1,
  MODELS_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsListAvailableModelsStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsListAvailableModelsStatusConst {
  LIST_AVAILABLE_MODELS_STATUS_UNKNOWN = 0,
  LIST_AVAILABLE_MODELS_STATUS_SUCCESS = 1,
  LIST_AVAILABLE_MODELS_STATUS_EXTERNAL_SERVICE_NOT_FOUND = 2,
  LIST_AVAILABLE_MODELS_STATUS_EXTERNAL_SERVER_ERROR = 3,
}

export interface IRosTypeBosdynApiMsgsListCaptureActionsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  query: IRosTypeBosdynApiMsgsDataQueryParams;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListCaptureActionsRequestConst {
  HEADER_FIELD_SET = 1,
  QUERY_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsListCaptureActionsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  action_ids: IRosTypeBosdynApiMsgsCaptureActionId[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListCaptureActionsResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsListImageSourcesRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListImageSourcesRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsListImageSourcesResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  image_sources: IRosTypeBosdynApiMsgsImageSource[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListImageSourcesResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsListLeasesRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  include_full_lease_info: boolean;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListLeasesRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsListLeasesResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  resources: IRosTypeBosdynApiMsgsLeaseResource[];
  resource_tree: IRosTypeBosdynApiMsgsResourceTree;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListLeasesResponseConst {
  HEADER_FIELD_SET = 1,
  RESOURCE_TREE_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsListParam {
  values: IRosTypeBosdynApiMsgsCustomParam[];
}

export interface IRosTypeBosdynApiMsgsListParamSpec {
  element_spec: IRosTypeProto2RosAny;
  min_number_of_values: IRosTypeStdMsgsInt64;
  max_number_of_values: IRosTypeStdMsgsInt64;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListParamSpecConst {
  ELEMENT_SPEC_FIELD_SET = 1,
  MIN_NUMBER_OF_VALUES_FIELD_SET = 2,
  MAX_NUMBER_OF_VALUES_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsListPayloadsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListPayloadsRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsListPayloadsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  payloads: IRosTypeBosdynApiMsgsPayload[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListPayloadsResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsListPointCloudSourcesRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListPointCloudSourcesRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsListPointCloudSourcesResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  point_cloud_sources: IRosTypeBosdynApiMsgsPointCloudSource[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListPointCloudSourcesResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsListServiceEntriesRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListServiceEntriesRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsListServiceEntriesResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  service_entries: IRosTypeBosdynApiMsgsServiceEntry[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListServiceEntriesResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsListStoredAlertDataRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  query: IRosTypeBosdynApiMsgsDataQueryParams;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListStoredAlertDataRequestConst {
  HEADER_FIELD_SET = 1,
  QUERY_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsListStoredAlertDataResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  data_ids: IRosTypeBosdynApiMsgsDataIdentifier[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListStoredAlertDataResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsListStoredDataRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  query: IRosTypeBosdynApiMsgsDataQueryParams;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListStoredDataRequestConst {
  HEADER_FIELD_SET = 1,
  QUERY_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsListStoredDataResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  data_ids: IRosTypeBosdynApiMsgsDataIdentifier[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListStoredDataResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsListStoredImagesRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  query: IRosTypeBosdynApiMsgsDataQueryParams;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListStoredImagesRequestConst {
  HEADER_FIELD_SET = 1,
  QUERY_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsListStoredImagesResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  data_ids: IRosTypeBosdynApiMsgsDataIdentifier[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListStoredImagesResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsListStoredMetadataRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  query: IRosTypeBosdynApiMsgsDataQueryParams;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListStoredMetadataRequestConst {
  HEADER_FIELD_SET = 1,
  QUERY_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsListStoredMetadataResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  data_ids: IRosTypeBosdynApiMsgsDataIdentifier[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListStoredMetadataResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsListWorldObjectRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  object_type: IRosTypeBosdynApiMsgsWorldObjectType[];
  timestamp_filter: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListWorldObjectRequestConst {
  HEADER_FIELD_SET = 1,
  TIMESTAMP_FILTER_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsListWorldObjectResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  world_objects: IRosTypeBosdynApiMsgsWorldObject[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsListWorldObjectResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsLiveDataRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  data_captures: IRosTypeBosdynApiMsgsDataCapture[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsLiveDataRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsLiveDataResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  live_data: IRosTypeBosdynApiMsgsLiveDataResponseCapabilityLiveData[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsLiveDataResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsLiveDataResponseCapabilityLiveData {
  signals: IRosTypeBosdynApiMsgsLiveDataResponseCapabilityLiveDataSignalsEntry[];
  name: string;
  status: IRosTypeBosdynApiMsgsLiveDataResponseCapabilityLiveDataStatus;
  custom_param_error: IRosTypeBosdynApiMsgsCustomParamError;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsLiveDataResponseCapabilityLiveDataConst {
  CUSTOM_PARAM_ERROR_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsLiveDataResponseCapabilityLiveDataSignalsEntry {
  key: string;
  value: IRosTypeBosdynApiMsgsSignal;
}

export interface IRosTypeBosdynApiMsgsLiveDataResponseCapabilityLiveDataStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsLiveDataResponseCapabilityLiveDataStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_UNKNOWN_CAPTURE_TYPE = 2,
  STATUS_CUSTOM_PARAMS_ERROR = 3,
  STATUS_INTERNAL_ERROR = 4,
}

export interface IRosTypeBosdynApiMsgsLlh {
  latitude: number;
  longitude: number;
  height: number;
}

export interface IRosTypeBosdynApiMsgsLocalGrid {
  local_grid_type_name: string;
  acquisition_time: { sec: number; nanosec: number };
  transforms_snapshot: IRosTypeBosdynApiMsgsFrameTreeSnapshot;
  frame_name_local_grid_data: string;
  extent: IRosTypeBosdynApiMsgsLocalGridExtent;
  cell_format: IRosTypeBosdynApiMsgsLocalGridCellFormat;
  encoding: IRosTypeBosdynApiMsgsLocalGridEncoding;
  data: number[];
  rle_counts: number[];
  cell_value_scale: number;
  cell_value_offset: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsLocalGridConst {
  ACQUISITION_TIME_FIELD_SET = 2,
  TRANSFORMS_SNAPSHOT_FIELD_SET = 4,
  EXTENT_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsLocalGridCellFormat {
  value: number;
}

export enum IRosTypeBosdynApiMsgsLocalGridCellFormatConst {
  CELL_FORMAT_UNKNOWN = 0,
  CELL_FORMAT_FLOAT32 = 1,
  CELL_FORMAT_FLOAT64 = 2,
  CELL_FORMAT_INT8 = 3,
  CELL_FORMAT_UINT8 = 4,
  CELL_FORMAT_INT16 = 5,
  CELL_FORMAT_UINT16 = 6,
}

export interface IRosTypeBosdynApiMsgsLocalGridEncoding {
  value: number;
}

export enum IRosTypeBosdynApiMsgsLocalGridEncodingConst {
  ENCODING_UNKNOWN = 0,
  ENCODING_RAW = 1,
  ENCODING_RLE = 2,
}

export interface IRosTypeBosdynApiMsgsLocalGridExtent {
  cell_size: number;
  num_cells_x: number;
  num_cells_y: number;
}

export interface IRosTypeBosdynApiMsgsLocalGridRequest {
  local_grid_type_name: string;
}

export interface IRosTypeBosdynApiMsgsLocalGridResponse {
  local_grid_type_name: string;
  status: IRosTypeBosdynApiMsgsLocalGridResponseStatus;
  local_grid: IRosTypeBosdynApiMsgsLocalGrid;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsLocalGridResponseConst {
  LOCAL_GRID_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsLocalGridResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsLocalGridResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NO_SUCH_GRID = 2,
  STATUS_DATA_UNAVAILABLE = 3,
  STATUS_DATA_INVALID = 4,
}

export interface IRosTypeBosdynApiMsgsLocalGridType {
  name: string;
}

export interface IRosTypeBosdynApiMsgsLocationAndGpsDevice {
  data_point: IRosTypeBosdynApiMsgsGpsDataPoint;
  device: IRosTypeBosdynApiMsgsGpsDevice;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsLocationAndGpsDeviceConst {
  DATA_POINT_FIELD_SET = 1,
  DEVICE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsManipulationApiFeedbackRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  manipulation_cmd_id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsManipulationApiFeedbackRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsManipulationApiFeedbackResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  manipulation_cmd_id: number;
  current_state: IRosTypeBosdynApiMsgsManipulationFeedbackState;
  transforms_snapshot_manipulation_data: IRosTypeBosdynApiMsgsFrameTreeSnapshot;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsManipulationApiFeedbackResponseConst {
  HEADER_FIELD_SET = 1,
  TRANSFORMS_SNAPSHOT_MANIPULATION_DATA_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsManipulationApiRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  manipulation_cmd: IRosTypeBosdynApiMsgsManipulationApiRequestOneOfManipulationCmd;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsManipulationApiRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsManipulationApiRequestOneOfManipulationCmd {
  walk_to_object_ray_in_world: IRosTypeBosdynApiMsgsWalkToObjectRayInWorld;
  walk_to_object_in_image: IRosTypeBosdynApiMsgsWalkToObjectInImage;
  pick_object: IRosTypeBosdynApiMsgsPickObject;
  pick_object_in_image: IRosTypeBosdynApiMsgsPickObjectInImage;
  pick_object_ray_in_world: IRosTypeBosdynApiMsgsPickObjectRayInWorld;
  manipulation_cmd_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsManipulationApiRequestOneOfManipulationCmdConst {
  MANIPULATION_CMD_NOT_SET = 0,
  MANIPULATION_CMD_WALK_TO_OBJECT_RAY_IN_WORLD_SET = 1,
  MANIPULATION_CMD_WALK_TO_OBJECT_IN_IMAGE_SET = 2,
  MANIPULATION_CMD_PICK_OBJECT_SET = 3,
  MANIPULATION_CMD_PICK_OBJECT_IN_IMAGE_SET = 4,
  MANIPULATION_CMD_PICK_OBJECT_RAY_IN_WORLD_SET = 5,
  MANIPULATION_CMD_PICK_OBJECT_EXECUTE_PLAN_SET = 6,
}

export interface IRosTypeBosdynApiMsgsManipulationApiResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  manipulation_cmd_id: number;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsManipulationApiResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsManipulationCameraSource {
  value: number;
}

export enum IRosTypeBosdynApiMsgsManipulationCameraSourceConst {
  MANIPULATION_CAMERA_SOURCE_UNKNOWN = 0,
  MANIPULATION_CAMERA_SOURCE_STEREO = 1,
  MANIPULATION_CAMERA_SOURCE_HAND = 2,
}

export interface IRosTypeBosdynApiMsgsManipulationFeedbackState {
  value: number;
}

export enum IRosTypeBosdynApiMsgsManipulationFeedbackStateConst {
  MANIP_STATE_UNKNOWN = 0,
  MANIP_STATE_DONE = 1,
  MANIP_STATE_SEARCHING_FOR_GRASP = 2,
  MANIP_STATE_MOVING_TO_GRASP = 3,
  MANIP_STATE_GRASPING_OBJECT = 4,
  MANIP_STATE_PLACING_OBJECT = 5,
  MANIP_STATE_GRASP_SUCCEEDED = 6,
  MANIP_STATE_GRASP_FAILED = 7,
  MANIP_STATE_GRASP_PLANNING_SUCCEEDED = 11,
  MANIP_STATE_GRASP_PLANNING_NO_SOLUTION = 8,
  MANIP_STATE_GRASP_FAILED_TO_RAYCAST_INTO_MAP = 9,
  MANIP_STATE_GRASP_PLANNING_WAITING_DATA_AT_EDGE = 13,
  MANIP_STATE_WALKING_TO_OBJECT = 10,
  MANIP_STATE_ATTEMPTING_RAYCASTING = 12,
  MANIP_STATE_MOVING_TO_PLACE = 14,
  MANIP_STATE_PLACE_FAILED_TO_RAYCAST_INTO_MAP = 15,
  MANIP_STATE_PLACE_SUCCEEDED = 16,
  MANIP_STATE_PLACE_FAILED = 17,
}

export interface IRosTypeBosdynApiMsgsManipulatorState {
  gripper_open_percentage: number;
  is_gripper_holding_item: boolean;
  estimated_end_effector_force_in_hand: IRosTypeGeometryMsgsVector3;
  stow_state: IRosTypeBosdynApiMsgsManipulatorStateStowState;
  velocity_of_hand_in_vision: IRosTypeGeometryMsgsTwist;
  velocity_of_hand_in_odom: IRosTypeGeometryMsgsTwist;
  carry_state: IRosTypeBosdynApiMsgsManipulatorStateCarryState;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsManipulatorStateConst {
  ESTIMATED_END_EFFECTOR_FORCE_IN_HAND_FIELD_SET = 4,
  VELOCITY_OF_HAND_IN_VISION_FIELD_SET = 16,
  VELOCITY_OF_HAND_IN_ODOM_FIELD_SET = 32,
}

export interface IRosTypeBosdynApiMsgsManipulatorStateCarryState {
  value: number;
}

export enum IRosTypeBosdynApiMsgsManipulatorStateCarryStateConst {
  CARRY_STATE_UNKNOWN = 0,
  CARRY_STATE_NOT_CARRIABLE = 1,
  CARRY_STATE_CARRIABLE = 2,
  CARRY_STATE_CARRIABLE_AND_STOWABLE = 3,
}

export interface IRosTypeBosdynApiMsgsManipulatorStateStowState {
  value: number;
}

export enum IRosTypeBosdynApiMsgsManipulatorStateStowStateConst {
  STOWSTATE_UNKNOWN = 0,
  STOWSTATE_STOWED = 1,
  STOWSTATE_DEPLOYED = 2,
}

export interface IRosTypeBosdynApiMsgsMatch {
  reference_index: number;
  live_index: number;
  distance: number;
}

export interface IRosTypeBosdynApiMsgsMatrix {
  rows: number;
  cols: number;
  values: number[];
}

export interface IRosTypeBosdynApiMsgsMatrixInt32 {
  rows: number;
  cols: number;
  values: number[];
}

export interface IRosTypeBosdynApiMsgsMatrixInt64 {
  rows: number;
  cols: number;
  values: number[];
}

export interface IRosTypeBosdynApiMsgsMatrixf {
  rows: number;
  cols: number;
  values: number[];
}

export interface IRosTypeBosdynApiMsgsMessageTypeDescriptor {
  content_type: string;
  type_name: string;
  is_metadata: boolean;
}

export interface IRosTypeBosdynApiMsgsMetadata {
  data: IRosTypeProto2RosStruct;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsMetadataConst {
  DATA_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsMobilityCommandFeedback {
  status: IRosTypeBosdynApiMsgsRobotCommandFeedbackStatusStatus;
  feedback: IRosTypeBosdynApiMsgsMobilityCommandFeedbackOneOfFeedback;
}

export interface IRosTypeBosdynApiMsgsMobilityCommandFeedbackOneOfFeedback {
  se2_trajectory_feedback: IRosTypeBosdynApiMsgsSe2TrajectoryCommandFeedback;
  sit_feedback: IRosTypeBosdynApiMsgsSitCommandFeedback;
  stand_feedback: IRosTypeBosdynApiMsgsStandCommandFeedback;
  stance_feedback: IRosTypeBosdynApiMsgsStanceCommandFeedback;
  feedback_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsMobilityCommandFeedbackOneOfFeedbackConst {
  FEEDBACK_NOT_SET = 0,
  FEEDBACK_SE2_TRAJECTORY_FEEDBACK_SET = 1,
  FEEDBACK_SE2_VELOCITY_FEEDBACK_SET = 2,
  FEEDBACK_SIT_FEEDBACK_SET = 3,
  FEEDBACK_STAND_FEEDBACK_SET = 4,
  FEEDBACK_STANCE_FEEDBACK_SET = 5,
  FEEDBACK_STOP_FEEDBACK_SET = 6,
  FEEDBACK_FOLLOW_ARM_FEEDBACK_SET = 7,
}

export interface IRosTypeBosdynApiMsgsMobilityCommandRequest {
  params: IRosTypeProto2RosAnyProto;
  command: IRosTypeBosdynApiMsgsMobilityCommandRequestOneOfCommand;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsMobilityCommandRequestConst {
  PARAMS_FIELD_SET = 128,
}

export interface IRosTypeBosdynApiMsgsMobilityCommandRequestOneOfCommand {
  se2_trajectory_request: IRosTypeBosdynApiMsgsSe2TrajectoryCommandRequest;
  se2_velocity_request: IRosTypeBosdynApiMsgsSe2VelocityCommandRequest;
  stance_request: IRosTypeBosdynApiMsgsStanceCommandRequest;
  follow_arm_request: IRosTypeBosdynApiMsgsFollowArmCommandRequest;
  command_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsMobilityCommandRequestOneOfCommandConst {
  COMMAND_NOT_SET = 0,
  COMMAND_SE2_TRAJECTORY_REQUEST_SET = 1,
  COMMAND_SE2_VELOCITY_REQUEST_SET = 2,
  COMMAND_SIT_REQUEST_SET = 3,
  COMMAND_STAND_REQUEST_SET = 4,
  COMMAND_STANCE_REQUEST_SET = 5,
  COMMAND_STOP_REQUEST_SET = 6,
  COMMAND_FOLLOW_ARM_REQUEST_SET = 7,
}

export interface IRosTypeBosdynApiMsgsModelData {
  model_name: string;
  available_labels: string[];
  output_image_spec: IRosTypeBosdynApiMsgsOutputImageSpec[];
  custom_params: IRosTypeBosdynApiMsgsDictParamSpec;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsModelDataConst {
  CUSTOM_PARAMS_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsModelLabels {
  model_name: string;
  available_labels: string[];
}

export interface IRosTypeBosdynApiMsgsMomentOfIntertia {
  xx: number;
  yy: number;
  zz: number;
  xy: number;
  xz: number;
  yz: number;
}

export interface IRosTypeBosdynApiMsgsMotorTemperature {
  name: string;
  temperature: number;
}

export interface IRosTypeBosdynApiMsgsMountFrameName {
  value: number;
}

export enum IRosTypeBosdynApiMsgsMountFrameNameConst {
  MOUNT_FRAME_UNKNOWN = 0,
  MOUNT_FRAME_BODY_PAYLOAD = 1,
  MOUNT_FRAME_GRIPPER_PAYLOAD = 2,
  MOUNT_FRAME_WR1 = 3,
}

export interface IRosTypeBosdynApiMsgsMutateWorldObjectRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  mutation: IRosTypeBosdynApiMsgsMutateWorldObjectRequestMutation;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsMutateWorldObjectRequestConst {
  HEADER_FIELD_SET = 1,
  MUTATION_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsMutateWorldObjectRequestAction {
  value: number;
}

export enum IRosTypeBosdynApiMsgsMutateWorldObjectRequestActionConst {
  ACTION_UNKNOWN = 0,
  ACTION_ADD = 1,
  ACTION_CHANGE = 2,
  ACTION_DELETE = 3,
}

export interface IRosTypeBosdynApiMsgsMutateWorldObjectRequestMutation {
  action: IRosTypeBosdynApiMsgsMutateWorldObjectRequestAction;
  object: IRosTypeBosdynApiMsgsWorldObject;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsMutateWorldObjectRequestMutationConst {
  OBJECT_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsMutateWorldObjectResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsMutateWorldObjectResponseStatus;
  mutated_object_id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsMutateWorldObjectResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsMutateWorldObjectResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsMutateWorldObjectResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_MUTATION_ID = 2,
  STATUS_NO_PERMISSION = 3,
  STATUS_INVALID_WORLD_OBJECT = 4,
}

export interface IRosTypeBosdynApiMsgsNamedArmPositionsCommandFeedback {
  status: IRosTypeBosdynApiMsgsNamedArmPositionsCommandFeedbackStatus;
}

export interface IRosTypeBosdynApiMsgsNamedArmPositionsCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsNamedArmPositionsCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_COMPLETE = 1,
  STATUS_IN_PROGRESS = 2,
  STATUS_STALLED_HOLDING_ITEM = 3,
}

export interface IRosTypeBosdynApiMsgsNamedArmPositionsCommandPositions {
  value: number;
}

export enum IRosTypeBosdynApiMsgsNamedArmPositionsCommandPositionsConst {
  POSITIONS_UNKNOWN = 0,
  POSITIONS_CARRY = 1,
  POSITIONS_READY = 2,
  POSITIONS_STOW = 3,
}

export interface IRosTypeBosdynApiMsgsNamedArmPositionsCommandRequest {
  position: IRosTypeBosdynApiMsgsNamedArmPositionsCommandPositions;
}

export interface IRosTypeBosdynApiMsgsNetworkComputeCapability {
  server_config: IRosTypeBosdynApiMsgsNetworkComputeServerConfiguration;
  available_models: string[];
  labels: IRosTypeBosdynApiMsgsModelLabels[];
  models: IRosTypeBosdynApiMsgsAvailableModels;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsNetworkComputeCapabilityConst {
  SERVER_CONFIG_FIELD_SET = 1,
  MODELS_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsNetworkComputeCapture {
  server_config: IRosTypeBosdynApiMsgsNetworkComputeServerConfiguration;
  input: IRosTypeBosdynApiMsgsNetworkComputeCaptureOneOfInput;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsNetworkComputeCaptureConst {
  SERVER_CONFIG_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsNetworkComputeCaptureOneOfInput {
  input_data: IRosTypeBosdynApiMsgsNetworkComputeInputData;
  input_data_bridge: IRosTypeBosdynApiMsgsNetworkComputeInputDataBridge;
  input_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsNetworkComputeCaptureOneOfInputConst {
  INPUT_NOT_SET = 0,
  INPUT_INPUT_DATA_SET = 1,
  INPUT_INPUT_DATA_BRIDGE_SET = 2,
}

export interface IRosTypeBosdynApiMsgsNetworkComputeError {
  service_name: string;
  error: IRosTypeBosdynApiMsgsNetworkComputeErrorErrorCode;
  network_compute_status: IRosTypeBosdynApiMsgsNetworkComputeStatus;
  message: string;
}

export interface IRosTypeBosdynApiMsgsNetworkComputeErrorErrorCode {
  value: number;
}

export enum IRosTypeBosdynApiMsgsNetworkComputeErrorErrorCodeConst {
  STATUS_UNKNOWN = 0,
  STATUS_REQUEST_ERROR = 1,
  STATUS_NETWORK_ERROR = 2,
  STATUS_INTERNAL_ERROR = 3,
}

export interface IRosTypeBosdynApiMsgsNetworkComputeInputData {
  other_data: IRosTypeProto2RosAnyProto;
  model_name: string;
  min_confidence: number;
  rotate_image: IRosTypeBosdynApiMsgsNetworkComputeInputDataRotateImage;
  input: IRosTypeBosdynApiMsgsNetworkComputeInputDataOneOfInput;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsNetworkComputeInputDataConst {
  OTHER_DATA_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsNetworkComputeInputDataBridge {
  image_sources_and_services: IRosTypeBosdynApiMsgsImageSourceAndService[];
  parameters: IRosTypeBosdynApiMsgsComputeParameters;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsNetworkComputeInputDataBridgeConst {
  PARAMETERS_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsNetworkComputeInputDataOneOfInput {
  image_source_and_service: IRosTypeBosdynApiMsgsImageSourceAndService;
  image: IRosTypeBosdynApiMsgsImage;
  input_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsNetworkComputeInputDataOneOfInputConst {
  INPUT_NOT_SET = 0,
  INPUT_IMAGE_SOURCE_AND_SERVICE_SET = 1,
  INPUT_IMAGE_SET = 2,
}

export interface IRosTypeBosdynApiMsgsNetworkComputeInputDataRotateImage {
  value: number;
}

export enum IRosTypeBosdynApiMsgsNetworkComputeInputDataRotateImageConst {
  ROTATE_IMAGE_UNKNOWN = 0,
  ROTATE_IMAGE_NO_ROTATION = 3,
  ROTATE_IMAGE_ALIGN_HORIZONTAL = 1,
  ROTATE_IMAGE_ALIGN_WITH_BODY = 2,
}

export interface IRosTypeBosdynApiMsgsNetworkComputeInputDataWorker {
  images: IRosTypeBosdynApiMsgsImageCaptureAndSource[];
  parameters: IRosTypeBosdynApiMsgsComputeParameters;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsNetworkComputeInputDataWorkerConst {
  PARAMETERS_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsNetworkComputeRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  server_config: IRosTypeBosdynApiMsgsNetworkComputeServerConfiguration;
  input: IRosTypeBosdynApiMsgsNetworkComputeRequestOneOfInput;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsNetworkComputeRequestConst {
  HEADER_FIELD_SET = 1,
  SERVER_CONFIG_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsNetworkComputeRequestOneOfInput {
  input_data: IRosTypeBosdynApiMsgsNetworkComputeInputData;
  input_data_bridge: IRosTypeBosdynApiMsgsNetworkComputeInputDataBridge;
  input_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsNetworkComputeRequestOneOfInputConst {
  INPUT_NOT_SET = 0,
  INPUT_INPUT_DATA_SET = 1,
  INPUT_INPUT_DATA_BRIDGE_SET = 2,
}

export interface IRosTypeBosdynApiMsgsNetworkComputeResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  object_in_image: IRosTypeBosdynApiMsgsWorldObject[];
  image_response: IRosTypeBosdynApiMsgsImageResponse;
  image_responses: IRosTypeBosdynApiMsgsImageCaptureAndSource[];
  image_rotation_angle: number;
  other_data: IRosTypeProto2RosAnyProto;
  status: IRosTypeBosdynApiMsgsNetworkComputeStatus;
  custom_param_error: IRosTypeBosdynApiMsgsCustomParamError;
  alert_data: IRosTypeBosdynApiMsgsAlertData;
  output_images: IRosTypeBosdynApiMsgsNetworkComputeResponseOutputImagesEntry[];
  roi_output_data: IRosTypeBosdynApiMsgsNetworkComputeResponseRoiOutputDataEntry[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsNetworkComputeResponseConst {
  HEADER_FIELD_SET = 1,
  IMAGE_RESPONSE_FIELD_SET = 4,
  OTHER_DATA_FIELD_SET = 32,
  CUSTOM_PARAM_ERROR_FIELD_SET = 128,
  ALERT_DATA_FIELD_SET = 256,
}

export interface IRosTypeBosdynApiMsgsNetworkComputeResponseOutputImagesEntry {
  key: string;
  value: IRosTypeBosdynApiMsgsOutputImage;
}

export interface IRosTypeBosdynApiMsgsNetworkComputeResponseRoiOutputDataEntry {
  key: string;
  value: IRosTypeBosdynApiMsgsOutputData;
}

export interface IRosTypeBosdynApiMsgsNetworkComputeServerConfiguration {
  service_name: string;
}

export interface IRosTypeBosdynApiMsgsNetworkComputeStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsNetworkComputeStatusConst {
  NETWORK_COMPUTE_STATUS_UNKNOWN = 0,
  NETWORK_COMPUTE_STATUS_SUCCESS = 1,
  NETWORK_COMPUTE_STATUS_EXTERNAL_SERVICE_NOT_FOUND = 2,
  NETWORK_COMPUTE_STATUS_EXTERNAL_SERVER_ERROR = 3,
  NETWORK_COMPUTE_STATUS_ROTATION_ERROR = 4,
  NETWORK_COMPUTE_STATUS_CUSTOM_PARAMS_ERROR = 5,
  NETWORK_COMPUTE_STATUS_ANALYSIS_FAILED = 6,
}

export interface IRosTypeBosdynApiMsgsNewGpsDataRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  data_points: IRosTypeBosdynApiMsgsGpsDataPoint[];
  gps_device: IRosTypeBosdynApiMsgsGpsDevice;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsNewGpsDataRequestConst {
  HEADER_FIELD_SET = 1,
  GPS_DEVICE_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsNewGpsDataResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsNewGpsDataResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsNoGoRegionProperties {
  disable_foot_obstacle_generation: boolean;
  disable_body_obstacle_generation: boolean;
  disable_foot_obstacle_inflation: boolean;
  region: IRosTypeBosdynApiMsgsNoGoRegionPropertiesOneOfRegion;
}

export interface IRosTypeBosdynApiMsgsNoGoRegionPropertiesOneOfRegion {
  box: IRosTypeBosdynApiMsgsBox2WithFrame;
  region_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsNoGoRegionPropertiesOneOfRegionConst {
  REGION_NOT_SET = 0,
  REGION_BOX_SET = 1,
}

export interface IRosTypeBosdynApiMsgsOneOfParam {
  key: string;
  values: IRosTypeBosdynApiMsgsOneOfParamValuesEntry[];
}

export interface IRosTypeBosdynApiMsgsOneOfParamChildSpec {
  spec: IRosTypeBosdynApiMsgsDictParamSpec;
  ui_info: IRosTypeBosdynApiMsgsUserInterfaceInfo;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsOneOfParamChildSpecConst {
  SPEC_FIELD_SET = 1,
  UI_INFO_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsOneOfParamSpec {
  specs: IRosTypeBosdynApiMsgsOneOfParamSpecSpecsEntry[];
  default_key: string;
}

export interface IRosTypeBosdynApiMsgsOneOfParamSpecSpecsEntry {
  key: string;
  value: IRosTypeBosdynApiMsgsOneOfParamChildSpec;
}

export interface IRosTypeBosdynApiMsgsOneOfParamValuesEntry {
  key: string;
  value: IRosTypeBosdynApiMsgsDictParam;
}

export interface IRosTypeBosdynApiMsgsOperatorComment {
  message: string;
  timestamp: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsOperatorCommentConst {
  TIMESTAMP_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsOutputData {
  metadata: IRosTypeProto2RosStruct;
  object_in_image: IRosTypeBosdynApiMsgsWorldObject[];
  alert_data: IRosTypeBosdynApiMsgsAlertData;
  other_data: IRosTypeProto2RosAnyProto;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsOutputDataConst {
  METADATA_FIELD_SET = 1,
  ALERT_DATA_FIELD_SET = 4,
  OTHER_DATA_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsOutputImage {
  image_response: IRosTypeBosdynApiMsgsImageResponse;
  metadata: IRosTypeProto2RosStruct;
  object_in_image: IRosTypeBosdynApiMsgsWorldObject[];
  alert_data: IRosTypeBosdynApiMsgsAlertData;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsOutputImageConst {
  IMAGE_RESPONSE_FIELD_SET = 1,
  METADATA_FIELD_SET = 2,
  ALERT_DATA_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsOutputImageSpec {
  key: string;
  name: string;
}

export interface IRosTypeBosdynApiMsgsPageInfo {
  id: string;
  path: string;
  source: string;
  time_range: IRosTypeBosdynApiMsgsTimeRange;
  num_ticks: number;
  total_bytes: number;
  format: IRosTypeBosdynApiMsgsPageInfoPageFormat;
  compression: IRosTypeBosdynApiMsgsPageInfoCompression;
  is_open: boolean;
  is_downloaded: boolean;
  deleted_timestamp: { sec: number; nanosec: number };
  download_started_timestamp: { sec: number; nanosec: number };
  request_preserve: boolean;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPageInfoConst {
  TIME_RANGE_FIELD_SET = 8,
  DELETED_TIMESTAMP_FIELD_SET = 1024,
  DOWNLOAD_STARTED_TIMESTAMP_FIELD_SET = 2048,
}

export interface IRosTypeBosdynApiMsgsPageInfoCompression {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPageInfoCompressionConst {
  COMPRESSION_UNKNOWN = 0,
  COMPRESSION_NONE = 1,
  COMPRESSION_GZIP = 2,
  COMPRESSION_ZSTD = 3,
}

export interface IRosTypeBosdynApiMsgsPageInfoPageFormat {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPageInfoPageFormatConst {
  FORMAT_UNKNOWN = 0,
  FORMAT_BDDF_FILE = 1,
}

export interface IRosTypeBosdynApiMsgsPagesAndTimestamp {
  time_range: IRosTypeBosdynApiMsgsTimeRange;
  pages: IRosTypeBosdynApiMsgsPageInfo[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPagesAndTimestampConst {
  TIME_RANGE_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsParameter {
  label: string;
  units: string;
  notes: string;
  values: IRosTypeBosdynApiMsgsParameterOneOfValues;
}

export interface IRosTypeBosdynApiMsgsParameterOneOfValues {
  int_value: number;
  float_value: number;
  timestamp: { sec: number; nanosec: number };
  duration: { sec: number; nanosec: number };
  string_value: string;
  bool_value: boolean;
  uint_value: number;
  values_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsParameterOneOfValuesConst {
  VALUES_NOT_SET = 0,
  VALUES_INT_VALUE_SET = 1,
  VALUES_FLOAT_VALUE_SET = 2,
  VALUES_TIMESTAMP_SET = 3,
  VALUES_DURATION_SET = 4,
  VALUES_STRING_VALUE_SET = 5,
  VALUES_BOOL_VALUE_SET = 6,
  VALUES_UINT_VALUE_SET = 7,
}

export interface IRosTypeBosdynApiMsgsPayload {
  guid: string;
  name: string;
  description: string;
  label_prefix: string[];
  is_authorized: boolean;
  is_enabled: boolean;
  is_noncompute_payload: boolean;
  version: IRosTypeBosdynApiMsgsSoftwareVersion;
  body_tform_payload: IRosTypeGeometryMsgsPose;
  mount_tform_payload: IRosTypeGeometryMsgsPose;
  mount_frame_name: IRosTypeBosdynApiMsgsMountFrameName;
  liveness_timeout_secs: number;
  ipv4_address: string;
  link_speed: number;
  mass_volume_properties: IRosTypeBosdynApiMsgsPayloadMassVolumeProperties;
  preset_configurations: IRosTypeBosdynApiMsgsPayloadPreset[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPayloadConst {
  VERSION_FIELD_SET = 128,
  BODY_TFORM_PAYLOAD_FIELD_SET = 256,
  MOUNT_TFORM_PAYLOAD_FIELD_SET = 512,
  MASS_VOLUME_PROPERTIES_FIELD_SET = 16384,
}

export interface IRosTypeBosdynApiMsgsPayloadCredentials {
  guid: string;
  secret: string;
}

export interface IRosTypeBosdynApiMsgsPayloadEstimationCommandFeedback {
  status: IRosTypeBosdynApiMsgsPayloadEstimationCommandFeedbackStatus;
  progress: number;
  error: IRosTypeBosdynApiMsgsPayloadEstimationCommandFeedbackError;
  estimated_payload: IRosTypeBosdynApiMsgsPayload;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPayloadEstimationCommandFeedbackConst {
  ESTIMATED_PAYLOAD_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsPayloadEstimationCommandFeedbackError {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPayloadEstimationCommandFeedbackErrorConst {
  ERROR_UNKNOWN = 0,
  ERROR_NONE = 1,
  ERROR_FAILED_STAND = 2,
  ERROR_NO_RESULTS = 3,
}

export interface IRosTypeBosdynApiMsgsPayloadEstimationCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPayloadEstimationCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_COMPLETED = 1,
  STATUS_SMALL_MASS = 2,
  STATUS_IN_PROGRESS = 3,
  STATUS_ERROR = 4,
}

export interface IRosTypeBosdynApiMsgsPayloadMassVolumeProperties {
  total_mass: number;
  com_pos_rt_payload: IRosTypeGeometryMsgsVector3;
  moi_tensor: IRosTypeBosdynApiMsgsMomentOfIntertia;
  bounding_box: IRosTypeBosdynApiMsgsBox3WithFrame[];
  joint_limits: IRosTypeBosdynApiMsgsJointLimits[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPayloadMassVolumePropertiesConst {
  COM_POS_RT_PAYLOAD_FIELD_SET = 2,
  MOI_TENSOR_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsPayloadPreset {
  preset_name: string;
  description: string;
  mount_tform_payload: IRosTypeGeometryMsgsPose;
  mount_frame_name: IRosTypeBosdynApiMsgsMountFrameName;
  mass_volume_properties: IRosTypeBosdynApiMsgsPayloadMassVolumeProperties;
  label_prefix: string[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPayloadPresetConst {
  MOUNT_TFORM_PAYLOAD_FIELD_SET = 4,
  MASS_VOLUME_PROPERTIES_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsPickObject {
  frame_name: string;
  object_rt_frame: IRosTypeGeometryMsgsVector3;
  grasp_params: IRosTypeBosdynApiMsgsGraspParams;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPickObjectConst {
  OBJECT_RT_FRAME_FIELD_SET = 2,
  GRASP_PARAMS_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsPickObjectInImage {
  pixel_xy: IRosTypeBosdynApiMsgsVec2;
  transforms_snapshot_for_camera: IRosTypeBosdynApiMsgsFrameTreeSnapshot;
  frame_name_image_sensor: string;
  camera_model: IRosTypeBosdynApiMsgsImageSourcePinholeModel;
  grasp_params: IRosTypeBosdynApiMsgsGraspParams;
  walk_gaze_mode: IRosTypeBosdynApiMsgsWalkGazeMode;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPickObjectInImageConst {
  PIXEL_XY_FIELD_SET = 1,
  TRANSFORMS_SNAPSHOT_FOR_CAMERA_FIELD_SET = 2,
  CAMERA_MODEL_FIELD_SET = 8,
  GRASP_PARAMS_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsPickObjectRayInWorld {
  ray_start_rt_frame: IRosTypeGeometryMsgsVector3;
  ray_end_rt_frame: IRosTypeGeometryMsgsVector3;
  frame_name: string;
  grasp_params: IRosTypeBosdynApiMsgsGraspParams;
  walk_gaze_mode: IRosTypeBosdynApiMsgsWalkGazeMode;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPickObjectRayInWorldConst {
  RAY_START_RT_FRAME_FIELD_SET = 1,
  RAY_END_RT_FRAME_FIELD_SET = 2,
  GRASP_PARAMS_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsPlane {
  point: IRosTypeGeometryMsgsVector3;
  normal: IRosTypeGeometryMsgsVector3;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPlaneConst {
  POINT_FIELD_SET = 1,
  NORMAL_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsPluginServiceError {
  service_name: string;
  error: IRosTypeBosdynApiMsgsPluginServiceErrorErrorCode;
  message: string;
}

export interface IRosTypeBosdynApiMsgsPluginServiceErrorErrorCode {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPluginServiceErrorErrorCodeConst {
  STATUS_UNKNOWN = 0,
  STATUS_REQUEST_ERROR = 1,
  STATUS_GETSTATUS_ERROR = 2,
  STATUS_INTERNAL_ERROR = 3,
}

export interface IRosTypeBosdynApiMsgsPodTypeDescriptor {
  pod_type: IRosTypeBosdynApiMsgsPodTypeEnum;
  dimension: number[];
}

export interface IRosTypeBosdynApiMsgsPodTypeEnum {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPodTypeEnumConst {
  TYPE_UNSPECIFIED = 0,
  TYPE_INT8 = 1,
  TYPE_INT16 = 2,
  TYPE_INT32 = 3,
  TYPE_INT64 = 4,
  TYPE_UINT8 = 5,
  TYPE_UINT16 = 6,
  TYPE_UINT32 = 7,
  TYPE_UINT64 = 8,
  TYPE_FLOAT32 = 9,
  TYPE_FLOAT64 = 10,
}

export interface IRosTypeBosdynApiMsgsPointCloud {
  source: IRosTypeBosdynApiMsgsPointCloudSource;
  num_points: number;
  encoding: IRosTypeBosdynApiMsgsPointCloudEncoding;
  encoding_parameters: IRosTypeBosdynApiMsgsPointCloudEncodingParameters;
  data: number[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPointCloudConst {
  SOURCE_FIELD_SET = 1,
  ENCODING_PARAMETERS_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsPointCloudEncoding {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPointCloudEncodingConst {
  ENCODING_UNKNOWN = 0,
  ENCODING_XYZ_32F = 1,
  ENCODING_XYZ_4SC = 2,
  ENCODING_XYZ_5SC = 3,
}

export interface IRosTypeBosdynApiMsgsPointCloudEncodingParameters {
  scale_factor: number;
  max_x: number;
  max_y: number;
  max_z: number;
  remapping_constant: number;
  bytes_per_point: number;
}

export interface IRosTypeBosdynApiMsgsPointCloudRequest {
  point_cloud_source_name: string;
}

export interface IRosTypeBosdynApiMsgsPointCloudResponse {
  status: IRosTypeBosdynApiMsgsPointCloudResponseStatus;
  point_cloud: IRosTypeBosdynApiMsgsPointCloud;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPointCloudResponseConst {
  POINT_CLOUD_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsPointCloudResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPointCloudResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_SOURCE_DATA_ERROR = 2,
  STATUS_POINT_CLOUD_DATA_ERROR = 3,
  STATUS_UNKNOWN_SOURCE = 4,
}

export interface IRosTypeBosdynApiMsgsPointCloudSource {
  name: string;
  frame_name_sensor: string;
  acquisition_time: { sec: number; nanosec: number };
  transforms_snapshot: IRosTypeBosdynApiMsgsFrameTreeSnapshot;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPointCloudSourceConst {
  ACQUISITION_TIME_FIELD_SET = 4,
  TRANSFORMS_SNAPSHOT_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsPolyLine {
  points: IRosTypeBosdynApiMsgsVec2[];
}

export interface IRosTypeBosdynApiMsgsPolygon {
  vertexes: IRosTypeBosdynApiMsgsVec2[];
}

export interface IRosTypeBosdynApiMsgsPolygonWithExclusions {
  inclusion: IRosTypeBosdynApiMsgsPolygon;
  exclusions: IRosTypeBosdynApiMsgsPolygon[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPolygonWithExclusionsConst {
  INCLUSION_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsPositionalInterpolation {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPositionalInterpolationConst {
  POS_INTERP_UNKNOWN = 0,
  POS_INTERP_LINEAR = 1,
  POS_INTERP_CUBIC = 2,
}

export interface IRosTypeBosdynApiMsgsPowerCommandFeedbackRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  power_command_id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPowerCommandFeedbackRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsPowerCommandFeedbackResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsPowerCommandStatus;
  blocking_faults: IRosTypeBosdynApiMsgsSystemFault[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPowerCommandFeedbackResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsPowerCommandRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  request: IRosTypeBosdynApiMsgsPowerCommandRequestRequest;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPowerCommandRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsPowerCommandRequestRequest {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPowerCommandRequestRequestConst {
  REQUEST_UNKNOWN = 0,
  REQUEST_OFF = 1,
  REQUEST_ON = 2,
  REQUEST_OFF_MOTORS = 1,
  REQUEST_ON_MOTORS = 2,
  REQUEST_OFF_ROBOT = 3,
  REQUEST_CYCLE_ROBOT = 4,
  REQUEST_OFF_PAYLOAD_PORTS = 5,
  REQUEST_ON_PAYLOAD_PORTS = 6,
  REQUEST_OFF_WIFI_RADIO = 7,
  REQUEST_ON_WIFI_RADIO = 8,
}

export interface IRosTypeBosdynApiMsgsPowerCommandResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  status: IRosTypeBosdynApiMsgsPowerCommandStatus;
  power_command_id: number;
  license_status: IRosTypeBosdynApiMsgsLicenseInfoStatus;
  blocking_faults: IRosTypeBosdynApiMsgsSystemFault[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPowerCommandResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsPowerCommandStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPowerCommandStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_IN_PROGRESS = 1,
  STATUS_SUCCESS = 2,
  STATUS_SHORE_POWER_CONNECTED = 3,
  STATUS_BATTERY_MISSING = 4,
  STATUS_COMMAND_IN_PROGRESS = 5,
  STATUS_ESTOPPED = 6,
  STATUS_FAULTED = 7,
  STATUS_INTERNAL_ERROR = 8,
  STATUS_LICENSE_ERROR = 9,
  INCOMPATIBLE_HARDWARE_ERROR = 10,
  STATUS_OVERRIDDEN = 11,
  STATUS_KEEPALIVE_MOTORS_OFF = 12,
}

export interface IRosTypeBosdynApiMsgsPowerState {
  timestamp: { sec: number; nanosec: number };
  motor_power_state: IRosTypeBosdynApiMsgsPowerStateMotorPowerState;
  shore_power_state: IRosTypeBosdynApiMsgsPowerStateShorePowerState;
  robot_power_state: IRosTypeBosdynApiMsgsPowerStateRobotPowerState;
  payload_ports_power_state: IRosTypeBosdynApiMsgsPowerStatePayloadPortsPowerState;
  wifi_radio_power_state: IRosTypeBosdynApiMsgsPowerStateWifiRadioPowerState;
  locomotion_charge_percentage: IRosTypeStdMsgsFloat64;
  locomotion_estimated_runtime: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsPowerStateConst {
  TIMESTAMP_FIELD_SET = 1,
  LOCOMOTION_CHARGE_PERCENTAGE_FIELD_SET = 64,
  LOCOMOTION_ESTIMATED_RUNTIME_FIELD_SET = 128,
}

export interface IRosTypeBosdynApiMsgsPowerStateMotorPowerState {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPowerStateMotorPowerStateConst {
  STATE_UNKNOWN = 0,
  MOTOR_POWER_STATE_UNKNOWN = 0,
  STATE_OFF = 1,
  MOTOR_POWER_STATE_OFF = 1,
  STATE_ON = 2,
  MOTOR_POWER_STATE_ON = 2,
  STATE_POWERING_ON = 3,
  MOTOR_POWER_STATE_POWERING_ON = 3,
  STATE_POWERING_OFF = 4,
  MOTOR_POWER_STATE_POWERING_OFF = 4,
  STATE_ERROR = 5,
  MOTOR_POWER_STATE_ERROR = 5,
}

export interface IRosTypeBosdynApiMsgsPowerStatePayloadPortsPowerState {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPowerStatePayloadPortsPowerStateConst {
  PAYLOAD_PORTS_POWER_STATE_UNKNOWN = 0,
  PAYLOAD_PORTS_POWER_STATE_ON = 1,
  PAYLOAD_PORTS_POWER_STATE_OFF = 2,
}

export interface IRosTypeBosdynApiMsgsPowerStateRobotPowerState {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPowerStateRobotPowerStateConst {
  ROBOT_POWER_STATE_UNKNOWN = 0,
  ROBOT_POWER_STATE_ON = 1,
  ROBOT_POWER_STATE_OFF = 2,
}

export interface IRosTypeBosdynApiMsgsPowerStateShorePowerState {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPowerStateShorePowerStateConst {
  STATE_UNKNOWN_SHORE_POWER = 0,
  SHORE_POWER_STATE_UNKNOWN = 0,
  STATE_ON_SHORE_POWER = 1,
  SHORE_POWER_STATE_ON = 1,
  STATE_OFF_SHORE_POWER = 2,
  SHORE_POWER_STATE_OFF = 2,
}

export interface IRosTypeBosdynApiMsgsPowerStateWifiRadioPowerState {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPowerStateWifiRadioPowerStateConst {
  WIFI_RADIO_POWER_STATE_UNKNOWN = 0,
  WIFI_RADIO_POWER_STATE_ON = 1,
  WIFI_RADIO_POWER_STATE_OFF = 2,
}

export interface IRosTypeBosdynApiMsgsPrepPoseBehavior {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPrepPoseBehaviorConst {
  PREP_POSE_UNKNOWN = 0,
  PREP_POSE_USE_POSE = 1,
  PREP_POSE_SKIP_POSE = 2,
  PREP_POSE_ONLY_POSE = 3,
  PREP_POSE_UNDOCK = 4,
}

export interface IRosTypeBosdynApiMsgsPressureEnum {
  value: number;
}

export enum IRosTypeBosdynApiMsgsPressureEnumConst {
  PRESSURE_UNKNOWN = 0,
  PRESSURE_PSI = 1,
  PRESSURE_KPA = 2,
  PRESSURE_BAR = 3,
}

export interface IRosTypeBosdynApiMsgsQuad {
  pose: IRosTypeGeometryMsgsPose;
  size: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsQuadConst {
  POSE_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsQueryMaxCaptureIdRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  query: IRosTypeBosdynApiMsgsQueryParameters;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsQueryMaxCaptureIdRequestConst {
  HEADER_FIELD_SET = 1,
  QUERY_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsQueryMaxCaptureIdResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  max_capture_id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsQueryMaxCaptureIdResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsQueryParameters {
  time_range: IRosTypeBosdynApiMsgsTimeRangeQuery;
  action_ids: IRosTypeBosdynApiMsgsCaptureActionId[];
  channels: string[];
  captures_from_id: number;
  only_include_identifiers: boolean;
  include_images: boolean;
  include_data: boolean;
  include_metadata: boolean;
  include_alerts: boolean;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsQueryParametersConst {
  TIME_RANGE_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsQueryStoredCaptureResult {
  data_id: IRosTypeBosdynApiMsgsDataIdentifier;
  result: IRosTypeBosdynApiMsgsQueryStoredCaptureResultOneOfResult;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsQueryStoredCaptureResultConst {
  DATA_ID_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsQueryStoredCaptureResultOneOfResult {
  image: IRosTypeBosdynApiMsgsImageCapture;
  metadata: IRosTypeBosdynApiMsgsAssociatedMetadata;
  alert_data: IRosTypeBosdynApiMsgsAssociatedAlertData;
  data: IRosTypeBosdynApiMsgsStoredCapturedData;
  result_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsQueryStoredCaptureResultOneOfResultConst {
  RESULT_NOT_SET = 0,
  RESULT_IMAGE_SET = 1,
  RESULT_METADATA_SET = 2,
  RESULT_ALERT_DATA_SET = 3,
  RESULT_DATA_SET = 4,
}

export interface IRosTypeBosdynApiMsgsQueryStoredCapturesRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  query: IRosTypeBosdynApiMsgsQueryParameters;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsQueryStoredCapturesRequestConst {
  HEADER_FIELD_SET = 1,
  QUERY_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsQueryStoredCapturesResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  action_ids: IRosTypeBosdynApiMsgsCaptureActionId[];
  results: IRosTypeBosdynApiMsgsQueryStoredCaptureResult[];
  max_capture_id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsQueryStoredCapturesResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRay {
  origin: IRosTypeGeometryMsgsVector3;
  direction: IRosTypeGeometryMsgsVector3;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRayConst {
  ORIGIN_FIELD_SET = 1,
  DIRECTION_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsRayIntersection {
  type: IRosTypeBosdynApiMsgsRayIntersectionType;
  hit_position_in_hit_frame: IRosTypeGeometryMsgsVector3;
  distance_meters: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRayIntersectionConst {
  HIT_POSITION_IN_HIT_FRAME_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsRayIntersectionType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsRayIntersectionTypeConst {
  TYPE_UNKNOWN = 0,
  TYPE_GROUND_PLANE = 1,
  TYPE_TERRAIN_MAP = 2,
  TYPE_VOXEL_MAP = 3,
  TYPE_HAND_DEPTH = 4,
}

export interface IRosTypeBosdynApiMsgsRayProperties {
  ray: IRosTypeBosdynApiMsgsRay;
  frame: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRayPropertiesConst {
  RAY_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRaycastRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  ray_frame_name: string;
  ray: IRosTypeBosdynApiMsgsRay;
  min_intersection_distance: number;
  intersection_types: IRosTypeBosdynApiMsgsRayIntersectionType[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRaycastRequestConst {
  HEADER_FIELD_SET = 1,
  RAY_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsRaycastResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsRaycastResponseStatus;
  message: string;
  hit_frame_name: string;
  hits: IRosTypeBosdynApiMsgsRayIntersection[];
  transforms_snapshot: IRosTypeBosdynApiMsgsFrameTreeSnapshot;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRaycastResponseConst {
  HEADER_FIELD_SET = 1,
  TRANSFORMS_SNAPSHOT_FIELD_SET = 32,
}

export interface IRosTypeBosdynApiMsgsRaycastResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsRaycastResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_REQUEST = 2,
  STATUS_INVALID_INTERSECTION_TYPE = 3,
  STATUS_UNKNOWN_FRAME = 4,
}

export interface IRosTypeBosdynApiMsgsRecordDataBlobsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  blob_data: IRosTypeBosdynApiMsgsDataBlob[];
  sync: boolean;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRecordDataBlobsRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRecordDataBlobsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  errors: IRosTypeBosdynApiMsgsRecordDataBlobsResponseError[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRecordDataBlobsResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRecordDataBlobsResponseError {
  type: IRosTypeBosdynApiMsgsRecordDataBlobsResponseErrorType;
  message: string;
  index: number;
}

export interface IRosTypeBosdynApiMsgsRecordDataBlobsResponseErrorType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsRecordDataBlobsResponseErrorTypeConst {
  NONE = 0,
  CLIENT_ERROR = 1,
  SERVER_ERROR = 2,
}

export interface IRosTypeBosdynApiMsgsRecordEventsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  events: IRosTypeBosdynApiMsgsEvent[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRecordEventsRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRecordEventsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  errors: IRosTypeBosdynApiMsgsRecordEventsResponseError[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRecordEventsResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRecordEventsResponseError {
  type: IRosTypeBosdynApiMsgsRecordEventsResponseErrorType;
  message: string;
  index: number;
}

export interface IRosTypeBosdynApiMsgsRecordEventsResponseErrorType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsRecordEventsResponseErrorTypeConst {
  NONE = 0,
  CLIENT_ERROR = 1,
  SERVER_ERROR = 2,
}

export interface IRosTypeBosdynApiMsgsRecordOperatorCommentsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  operator_comments: IRosTypeBosdynApiMsgsOperatorComment[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRecordOperatorCommentsRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRecordOperatorCommentsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  errors: IRosTypeBosdynApiMsgsRecordOperatorCommentsResponseError[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRecordOperatorCommentsResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRecordOperatorCommentsResponseError {
  type: IRosTypeBosdynApiMsgsRecordOperatorCommentsResponseErrorType;
  message: string;
  index: number;
}

export interface IRosTypeBosdynApiMsgsRecordOperatorCommentsResponseErrorType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsRecordOperatorCommentsResponseErrorTypeConst {
  NONE = 0,
  CLIENT_ERROR = 1,
  SERVER_ERROR = 2,
}

export interface IRosTypeBosdynApiMsgsRecordSignalTicksRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  tick_data: IRosTypeBosdynApiMsgsSignalTick[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRecordSignalTicksRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRecordSignalTicksResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  errors: IRosTypeBosdynApiMsgsRecordSignalTicksResponseError[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRecordSignalTicksResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRecordSignalTicksResponseError {
  type: IRosTypeBosdynApiMsgsRecordSignalTicksResponseErrorType;
  message: string;
  index: number;
}

export interface IRosTypeBosdynApiMsgsRecordSignalTicksResponseErrorType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsRecordSignalTicksResponseErrorTypeConst {
  NONE = 0,
  CLIENT_ERROR = 1,
  SERVER_ERROR = 2,
  INVALID_SCHEMA_ID = 3,
}

export interface IRosTypeBosdynApiMsgsRecordTextMessagesRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  text_messages: IRosTypeBosdynApiMsgsTextMessage[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRecordTextMessagesRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRecordTextMessagesResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  errors: IRosTypeBosdynApiMsgsRecordTextMessagesResponseError[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRecordTextMessagesResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRecordTextMessagesResponseError {
  type: IRosTypeBosdynApiMsgsRecordTextMessagesResponseErrorType;
  message: string;
  index: number;
}

export interface IRosTypeBosdynApiMsgsRecordTextMessagesResponseErrorType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsRecordTextMessagesResponseErrorTypeConst {
  NONE = 0,
  CLIENT_ERROR = 1,
  SERVER_ERROR = 2,
}

export interface IRosTypeBosdynApiMsgsRectangleI {
  x: number;
  y: number;
  cols: number;
  rows: number;
}

export interface IRosTypeBosdynApiMsgsRegionOfInterestParam {
  area: IRosTypeBosdynApiMsgsAreaI;
  service_and_source: IRosTypeBosdynApiMsgsRegionOfInterestParamServiceAndSource;
  image_cols: number;
  image_rows: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRegionOfInterestParamConst {
  AREA_FIELD_SET = 1,
  SERVICE_AND_SOURCE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsRegionOfInterestParamServiceAndSource {
  service: string;
  source: string;
}

export interface IRosTypeBosdynApiMsgsRegionOfInterestParamSpec {
  service_and_source: IRosTypeBosdynApiMsgsRegionOfInterestParamServiceAndSource;
  default_area: IRosTypeBosdynApiMsgsAreaI;
  allows_rectangle: boolean;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRegionOfInterestParamSpecConst {
  SERVICE_AND_SOURCE_FIELD_SET = 1,
  DEFAULT_AREA_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsRegisterEstopEndpointRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  target_endpoint: IRosTypeBosdynApiMsgsEstopEndpoint;
  target_config_id: string;
  new_endpoint: IRosTypeBosdynApiMsgsEstopEndpoint;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRegisterEstopEndpointRequestConst {
  HEADER_FIELD_SET = 1,
  TARGET_ENDPOINT_FIELD_SET = 2,
  NEW_ENDPOINT_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsRegisterEstopEndpointResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  request: IRosTypeBosdynApiMsgsRegisterEstopEndpointRequest;
  new_endpoint: IRosTypeBosdynApiMsgsEstopEndpoint;
  status: IRosTypeBosdynApiMsgsRegisterEstopEndpointResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRegisterEstopEndpointResponseConst {
  HEADER_FIELD_SET = 1,
  REQUEST_FIELD_SET = 2,
  NEW_ENDPOINT_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsRegisterEstopEndpointResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsRegisterEstopEndpointResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_SUCCESS = 1,
  STATUS_ENDPOINT_MISMATCH = 2,
  STATUS_CONFIG_MISMATCH = 3,
  STATUS_INVALID_ENDPOINT = 4,
}

export interface IRosTypeBosdynApiMsgsRegisterPayloadRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  payload: IRosTypeBosdynApiMsgsPayload;
  payload_secret: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRegisterPayloadRequestConst {
  HEADER_FIELD_SET = 1,
  PAYLOAD_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsRegisterPayloadResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsRegisterPayloadResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRegisterPayloadResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRegisterPayloadResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsRegisterPayloadResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_ALREADY_EXISTS = 2,
}

export interface IRosTypeBosdynApiMsgsRegisterServiceRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  endpoint: IRosTypeBosdynApiMsgsEndpoint;
  service_entry: IRosTypeBosdynApiMsgsServiceEntry;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRegisterServiceRequestConst {
  HEADER_FIELD_SET = 1,
  ENDPOINT_FIELD_SET = 2,
  SERVICE_ENTRY_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsRegisterServiceResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsRegisterServiceResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRegisterServiceResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRegisterServiceResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsRegisterServiceResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_ALREADY_EXISTS = 2,
}

export interface IRosTypeBosdynApiMsgsRegisterSignalSchemaRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  schema: IRosTypeBosdynApiMsgsSignalSchema;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRegisterSignalSchemaRequestConst {
  HEADER_FIELD_SET = 1,
  SCHEMA_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsRegisterSignalSchemaResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  schema_id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRegisterSignalSchemaResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRegistration {
  status: IRosTypeBosdynApiMsgsRegistrationStatus;
  timestamp: { sec: number; nanosec: number };
  transforms_snapshot: IRosTypeBosdynApiMsgsFrameTreeSnapshot;
  robot_body_location: IRosTypeBosdynApiMsgsLlh;
  gps_states: IRosTypeBosdynApiMsgsGpsState[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRegistrationConst {
  TIMESTAMP_FIELD_SET = 2,
  TRANSFORMS_SNAPSHOT_FIELD_SET = 4,
  ROBOT_BODY_LOCATION_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsRegistrationStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsRegistrationStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NEED_DATA = 2,
  STATUS_NEED_MORE_DATA = 3,
  STATUS_STALE = 4,
}

export interface IRosTypeBosdynApiMsgsRequestHeader {
  request_timestamp: { sec: number; nanosec: number };
  client_name: string;
  disable_rpc_logging: boolean;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRequestHeaderConst {
  REQUEST_TIMESTAMP_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsResetRegistrationRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsResetRegistrationRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsResetRegistrationResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsResetRegistrationResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsResetSafetyStopRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  safety_stop_type: IRosTypeBosdynApiMsgsResetSafetyStopRequestSafetyStopType;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsResetSafetyStopRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsResetSafetyStopRequestSafetyStopType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsResetSafetyStopRequestSafetyStopTypeConst {
  SAFETY_STOP_UNKNOWN = 0,
  SAFETY_STOP_PRIMARY = 1,
  SAFETY_STOP_REDUNDANT = 2,
}

export interface IRosTypeBosdynApiMsgsResetSafetyStopResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  status: IRosTypeBosdynApiMsgsResetSafetyStopResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsResetSafetyStopResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsResetSafetyStopResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsResetSafetyStopResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INCOMPATIBLE_HARDWARE_ERROR = 2,
  STATUS_FAILED = 3,
  STATUS_UNKNOWN_STOP_TYPE = 4,
}

export interface IRosTypeBosdynApiMsgsResourceTree {
  resource: string;
  sub_resources: IRosTypeProto2RosAny[];
}

export interface IRosTypeBosdynApiMsgsResponseHeader {
  request_header: IRosTypeBosdynApiMsgsRequestHeader;
  request_received_timestamp: { sec: number; nanosec: number };
  response_timestamp: { sec: number; nanosec: number };
  error: IRosTypeBosdynApiMsgsCommonError;
  request: IRosTypeProto2RosAnyProto;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsResponseHeaderConst {
  REQUEST_HEADER_FIELD_SET = 1,
  REQUEST_RECEIVED_TIMESTAMP_FIELD_SET = 2,
  RESPONSE_TIMESTAMP_FIELD_SET = 4,
  ERROR_FIELD_SET = 8,
  REQUEST_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsRetainLeaseRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRetainLeaseRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsRetainLeaseResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRetainLeaseResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsReturnLeaseRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsReturnLeaseRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsReturnLeaseResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsReturnLeaseResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsReturnLeaseResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsReturnLeaseResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsReturnLeaseResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_RESOURCE = 2,
  STATUS_NOT_ACTIVE_LEASE = 3,
  STATUS_NOT_AUTHORITATIVE_SERVICE = 4,
}

export interface IRosTypeBosdynApiMsgsRobotCommand {
  command: IRosTypeBosdynApiMsgsRobotCommandOneOfCommand;
}

export interface IRosTypeBosdynApiMsgsRobotCommandFeedback {
  command: IRosTypeBosdynApiMsgsRobotCommandFeedbackOneOfCommand;
}

export interface IRosTypeBosdynApiMsgsRobotCommandFeedbackOneOfCommand {
  full_body_feedback: IRosTypeBosdynApiMsgsFullBodyCommandFeedback;
  synchronized_feedback: IRosTypeBosdynApiMsgsSynchronizedCommandFeedback;
  command_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsRobotCommandFeedbackOneOfCommandConst {
  COMMAND_NOT_SET = 0,
  COMMAND_FULL_BODY_FEEDBACK_SET = 1,
  COMMAND_SYNCHRONIZED_FEEDBACK_SET = 2,
}

export interface IRosTypeBosdynApiMsgsRobotCommandFeedbackRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  robot_command_id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotCommandFeedbackRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRobotCommandFeedbackResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  feedback: IRosTypeBosdynApiMsgsRobotCommandFeedback;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotCommandFeedbackResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
  FEEDBACK_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsRobotCommandFeedbackStatusStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsRobotCommandFeedbackStatusStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_PROCESSING = 1,
  STATUS_COMMAND_OVERRIDDEN = 2,
  STATUS_COMMAND_TIMED_OUT = 3,
  STATUS_ROBOT_FROZEN = 4,
  STATUS_INCOMPATIBLE_HARDWARE = 5,
}

export interface IRosTypeBosdynApiMsgsRobotCommandOneOfCommand {
  full_body_command: IRosTypeBosdynApiMsgsFullBodyCommandRequest;
  synchronized_command: IRosTypeBosdynApiMsgsSynchronizedCommandRequest;
  command_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsRobotCommandOneOfCommandConst {
  COMMAND_NOT_SET = 0,
  COMMAND_FULL_BODY_COMMAND_SET = 1,
  COMMAND_SYNCHRONIZED_COMMAND_SET = 2,
}

export interface IRosTypeBosdynApiMsgsRobotCommandRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  command: IRosTypeBosdynApiMsgsRobotCommand;
  clock_identifier: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotCommandRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
  COMMAND_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsRobotCommandResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  status: IRosTypeBosdynApiMsgsRobotCommandResponseStatus;
  message: string;
  robot_command_id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotCommandResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsRobotCommandResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsRobotCommandResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_REQUEST = 2,
  STATUS_UNSUPPORTED = 3,
  STATUS_NO_TIMESYNC = 4,
  STATUS_EXPIRED = 5,
  STATUS_TOO_DISTANT = 6,
  STATUS_NOT_POWERED_ON = 7,
  STATUS_BEHAVIOR_FAULT = 9,
  STATUS_DOCKED = 10,
  STATUS_UNKNOWN_FRAME = 8,
}

export interface IRosTypeBosdynApiMsgsRobotHardwareConfigurationRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotHardwareConfigurationRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRobotHardwareConfigurationResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  hardware_configuration: IRosTypeBosdynApiMsgsHardwareConfiguration;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotHardwareConfigurationResponseConst {
  HEADER_FIELD_SET = 1,
  HARDWARE_CONFIGURATION_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsRobotId {
  serial_number: string;
  species: string;
  version: string;
  software_release: IRosTypeBosdynApiMsgsRobotSoftwareRelease;
  nickname: string;
  computer_serial_number: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotIdConst {
  SOFTWARE_RELEASE_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsRobotIdRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotIdRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRobotIdResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  robot_id: IRosTypeBosdynApiMsgsRobotId;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotIdResponseConst {
  HEADER_FIELD_SET = 1,
  ROBOT_ID_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsRobotImpairedState {
  impaired_status: IRosTypeBosdynApiMsgsRobotImpairedStateImpairedStatus;
  system_faults: IRosTypeBosdynApiMsgsSystemFault[];
  service_faults: IRosTypeBosdynApiMsgsServiceFault[];
  behavior_faults: IRosTypeBosdynApiMsgsBehaviorFault[];
}

export interface IRosTypeBosdynApiMsgsRobotImpairedStateImpairedStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsRobotImpairedStateImpairedStatusConst {
  IMPAIRED_STATUS_UNKNOWN = 0,
  IMPAIRED_STATUS_OK = 1,
  IMPAIRED_STATUS_NO_ROBOT_DATA = 2,
  IMPAIRED_STATUS_SYSTEM_FAULT = 3,
  IMPAIRED_STATUS_NO_MOTOR_POWER = 4,
  IMPAIRED_STATUS_REMOTE_CLOUDS_NOT_WORKING = 5,
  IMPAIRED_STATUS_SERVICE_FAULT = 6,
  IMPAIRED_STATUS_BEHAVIOR_FAULT = 7,
  IMPAIRED_STATUS_ENTITY_DETECTOR_NOT_WORKING = 8,
}

export interface IRosTypeBosdynApiMsgsRobotLinkModelRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  link_name: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotLinkModelRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRobotLinkModelResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  link_model: IRosTypeBosdynApiMsgsSkeletonLinkObjModel;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotLinkModelResponseConst {
  HEADER_FIELD_SET = 1,
  LINK_MODEL_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsRobotMetrics {
  timestamp: { sec: number; nanosec: number };
  metrics: IRosTypeBosdynApiMsgsParameter[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotMetricsConst {
  TIMESTAMP_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRobotMetricsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotMetricsRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRobotMetricsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  robot_metrics: IRosTypeBosdynApiMsgsRobotMetrics;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotMetricsResponseConst {
  HEADER_FIELD_SET = 1,
  ROBOT_METRICS_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsRobotSoftwareRelease {
  version: IRosTypeBosdynApiMsgsSoftwareVersion;
  name: string;
  type: string;
  changeset_date: { sec: number; nanosec: number };
  changeset: string;
  api_version: string;
  build_information: string;
  install_date: { sec: number; nanosec: number };
  parameters: IRosTypeBosdynApiMsgsParameter[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotSoftwareReleaseConst {
  VERSION_FIELD_SET = 1,
  CHANGESET_DATE_FIELD_SET = 8,
  INSTALL_DATE_FIELD_SET = 128,
}

export interface IRosTypeBosdynApiMsgsRobotState {
  power_state: IRosTypeBosdynApiMsgsPowerState;
  battery_states: IRosTypeBosdynApiMsgsBatteryState[];
  comms_states: IRosTypeBosdynApiMsgsCommsState[];
  system_fault_state: IRosTypeBosdynApiMsgsSystemFaultState;
  estop_states: IRosTypeBosdynApiMsgsEStopState[];
  kinematic_state: IRosTypeBosdynApiMsgsKinematicState;
  behavior_fault_state: IRosTypeBosdynApiMsgsBehaviorFaultState;
  foot_state: IRosTypeBosdynApiMsgsFootState[];
  manipulator_state: IRosTypeBosdynApiMsgsManipulatorState;
  service_fault_state: IRosTypeBosdynApiMsgsServiceFaultState;
  terrain_state: IRosTypeBosdynApiMsgsTerrainState;
  system_state: IRosTypeBosdynApiMsgsSystemState;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotStateConst {
  POWER_STATE_FIELD_SET = 1,
  SYSTEM_FAULT_STATE_FIELD_SET = 8,
  KINEMATIC_STATE_FIELD_SET = 32,
  BEHAVIOR_FAULT_STATE_FIELD_SET = 64,
  MANIPULATOR_STATE_FIELD_SET = 256,
  SERVICE_FAULT_STATE_FIELD_SET = 512,
  TERRAIN_STATE_FIELD_SET = 1024,
  SYSTEM_STATE_FIELD_SET = 2048,
}

export interface IRosTypeBosdynApiMsgsRobotStateRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotStateRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRobotStateResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  robot_state: IRosTypeBosdynApiMsgsRobotState;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotStateResponseConst {
  HEADER_FIELD_SET = 1,
  ROBOT_STATE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsRobotStateStreamRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotStateStreamRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRobotStateStreamResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  joint_states: IRosTypeBosdynApiMsgsCombinedJointStates;
  inertial_state: IRosTypeBosdynApiMsgsImuState;
  last_command: IRosTypeBosdynApiMsgsRobotStateStreamResponseCommandState;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotStateStreamResponseConst {
  HEADER_FIELD_SET = 1,
  JOINT_STATES_FIELD_SET = 2,
  INERTIAL_STATE_FIELD_SET = 4,
  LAST_COMMAND_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsRobotStateStreamResponseCommandState {
  user_command_key: number;
  received_timestamp: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRobotStateStreamResponseCommandStateConst {
  RECEIVED_TIMESTAMP_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsRoiParameters {
  roi_percentage_in_image: IRosTypeBosdynApiMsgsVec2;
  window_size: IRosTypeBosdynApiMsgsRoiParametersRoiWindowSize;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRoiParametersConst {
  ROI_PERCENTAGE_IN_IMAGE_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsRoiParametersRoiWindowSize {
  value: number;
}

export enum IRosTypeBosdynApiMsgsRoiParametersRoiWindowSizeConst {
  ROI_WINDOW_SIZE_UNKNOWN = 0,
  ROI_WINDOW_SIZE_1 = 1,
  ROI_WINDOW_SIZE_2 = 2,
  ROI_WINDOW_SIZE_3 = 3,
  ROI_WINDOW_SIZE_4 = 4,
  ROI_WINDOW_SIZE_5 = 5,
  ROI_WINDOW_SIZE_6 = 6,
  ROI_WINDOW_SIZE_7 = 7,
  ROI_WINDOW_SIZE_8 = 8,
}

export interface IRosTypeBosdynApiMsgsRotationWithTolerance {
  rotation_ewrt_frame: IRosTypeGeometryMsgsQuaternion;
  threshold_radians: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsRotationWithToleranceConst {
  ROTATION_EWRT_FRAME_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsSafePowerOffCommandFeedback {
  status: IRosTypeBosdynApiMsgsSafePowerOffCommandFeedbackStatus;
}

export interface IRosTypeBosdynApiMsgsSafePowerOffCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsSafePowerOffCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_POWERED_OFF = 1,
  STATUS_IN_PROGRESS = 2,
}

export interface IRosTypeBosdynApiMsgsSafePowerOffCommandRequest {
  unsafe_action: IRosTypeBosdynApiMsgsSafePowerOffCommandRequestUnsafeAction;
}

export interface IRosTypeBosdynApiMsgsSafePowerOffCommandRequestUnsafeAction {
  value: number;
}

export enum IRosTypeBosdynApiMsgsSafePowerOffCommandRequestUnsafeActionConst {
  UNSAFE_UNKNOWN = 0,
  UNSAFE_MOVE_TO_SAFE_POSITION = 1,
  UNSAFE_FORCE_COMMAND = 2,
}

export interface IRosTypeBosdynApiMsgsScalarTrajectory {
  points: IRosTypeBosdynApiMsgsScalarTrajectoryPoint[];
  reference_time: { sec: number; nanosec: number };
  interpolation: IRosTypeBosdynApiMsgsPositionalInterpolation;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsScalarTrajectoryConst {
  REFERENCE_TIME_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsScalarTrajectoryPoint {
  point: number;
  velocity: IRosTypeStdMsgsFloat64;
  time_since_reference: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsScalarTrajectoryPointConst {
  VELOCITY_FIELD_SET = 2,
  TIME_SINCE_REFERENCE_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsSe2Pose {
  position: IRosTypeBosdynApiMsgsVec2;
  angle: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSe2PoseConst {
  POSITION_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsSe2Trajectory {
  points: IRosTypeBosdynApiMsgsSe2TrajectoryPoint[];
  reference_time: { sec: number; nanosec: number };
  interpolation: IRosTypeBosdynApiMsgsPositionalInterpolation;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSe2TrajectoryConst {
  REFERENCE_TIME_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsSe2TrajectoryCommandFeedback {
  status: IRosTypeBosdynApiMsgsSe2TrajectoryCommandFeedbackStatus;
  body_movement_status: IRosTypeBosdynApiMsgsSe2TrajectoryCommandFeedbackBodyMovementStatus;
  final_goal_status: IRosTypeBosdynApiMsgsSe2TrajectoryCommandFeedbackFinalGoalStatus;
}

export interface IRosTypeBosdynApiMsgsSe2TrajectoryCommandFeedbackBodyMovementStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsSe2TrajectoryCommandFeedbackBodyMovementStatusConst {
  BODY_STATUS_UNKNOWN = 0,
  BODY_STATUS_MOVING = 1,
  BODY_STATUS_SETTLED = 2,
}

export interface IRosTypeBosdynApiMsgsSe2TrajectoryCommandFeedbackFinalGoalStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsSe2TrajectoryCommandFeedbackFinalGoalStatusConst {
  FINAL_GOAL_STATUS_UNKNOWN = 0,
  FINAL_GOAL_STATUS_IN_PROGRESS = 1,
  FINAL_GOAL_STATUS_ACHIEVABLE = 2,
  FINAL_GOAL_STATUS_BLOCKED = 3,
}

export interface IRosTypeBosdynApiMsgsSe2TrajectoryCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsSe2TrajectoryCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_STOPPED = 1,
  STATUS_STOPPING = 3,
  STATUS_IN_PROGRESS = 2,
  STATUS_AT_GOAL = 1,
  STATUS_NEAR_GOAL = 3,
  STATUS_GOING_TO_GOAL = 2,
}

export interface IRosTypeBosdynApiMsgsSe2TrajectoryCommandRequest {
  end_time: { sec: number; nanosec: number };
  se2_frame_name: string;
  trajectory: IRosTypeBosdynApiMsgsSe2Trajectory;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSe2TrajectoryCommandRequestConst {
  END_TIME_FIELD_SET = 1,
  TRAJECTORY_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsSe2TrajectoryPoint {
  pose: IRosTypeBosdynApiMsgsSe2Pose;
  time_since_reference: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSe2TrajectoryPointConst {
  POSE_FIELD_SET = 1,
  TIME_SINCE_REFERENCE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsSe2Velocity {
  linear: IRosTypeBosdynApiMsgsVec2;
  angular: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSe2VelocityConst {
  LINEAR_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsSe2VelocityCommandRequest {
  end_time: { sec: number; nanosec: number };
  se2_frame_name: string;
  velocity: IRosTypeBosdynApiMsgsSe2Velocity;
  slew_rate_limit: IRosTypeBosdynApiMsgsSe2Velocity;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSe2VelocityCommandRequestConst {
  END_TIME_FIELD_SET = 1,
  VELOCITY_FIELD_SET = 4,
  SLEW_RATE_LIMIT_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsSe2VelocityLimit {
  max_vel: IRosTypeBosdynApiMsgsSe2Velocity;
  min_vel: IRosTypeBosdynApiMsgsSe2Velocity;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSe2VelocityLimitConst {
  MAX_VEL_FIELD_SET = 1,
  MIN_VEL_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsSe3Covariance {
  matrix: IRosTypeBosdynApiMsgsMatrix;
  yaw_variance: number;
  cov_xx: number;
  cov_xy: number;
  cov_xz: number;
  cov_yx: number;
  cov_yy: number;
  cov_yz: number;
  cov_zx: number;
  cov_zy: number;
  cov_zz: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSe3CovarianceConst {
  MATRIX_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsSe3Trajectory {
  points: IRosTypeBosdynApiMsgsSe3TrajectoryPoint[];
  reference_time: { sec: number; nanosec: number };
  pos_interpolation: IRosTypeBosdynApiMsgsPositionalInterpolation;
  ang_interpolation: IRosTypeBosdynApiMsgsAngularInterpolation;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSe3TrajectoryConst {
  REFERENCE_TIME_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsSe3TrajectoryPoint {
  pose: IRosTypeGeometryMsgsPose;
  velocity: IRosTypeGeometryMsgsTwist;
  time_since_reference: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSe3TrajectoryPointConst {
  POSE_FIELD_SET = 1,
  VELOCITY_FIELD_SET = 2,
  TIME_SINCE_REFERENCE_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsSelfRightCommandFeedback {
  status: IRosTypeBosdynApiMsgsSelfRightCommandFeedbackStatus;
}

export interface IRosTypeBosdynApiMsgsSelfRightCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsSelfRightCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_COMPLETED = 1,
  STATUS_IN_PROGRESS = 2,
}

export interface IRosTypeBosdynApiMsgsSensorOutputSpec {
  bounds: IRosTypeBosdynApiMsgsBounds;
  resolution: IRosTypeStdMsgsFloat64;
  units: IRosTypeBosdynApiMsgsUnits;
  sample_rate: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSensorOutputSpecConst {
  BOUNDS_FIELD_SET = 1,
  RESOLUTION_FIELD_SET = 2,
  UNITS_FIELD_SET = 4,
  SAMPLE_RATE_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsSeriesBlockIndex {
  series_index: number;
  descriptor_file_offset: number;
  block_entries: IRosTypeBosdynApiMsgsSeriesBlockIndexBlockEntry[];
  total_bytes: number;
}

export interface IRosTypeBosdynApiMsgsSeriesBlockIndexBlockEntry {
  timestamp: { sec: number; nanosec: number };
  file_offset: number;
  additional_indexes: number[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSeriesBlockIndexBlockEntryConst {
  TIMESTAMP_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsSeriesDescriptor {
  series_index: number;
  series_identifier: IRosTypeBosdynApiMsgsSeriesIdentifier;
  identifier_hash: number;
  annotations: IRosTypeBosdynApiMsgsSeriesDescriptorAnnotationsEntry[];
  additional_index_names: string[];
  description: string;
  data_type: IRosTypeBosdynApiMsgsSeriesDescriptorOneOfDataType;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSeriesDescriptorConst {
  SERIES_IDENTIFIER_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsSeriesDescriptorAnnotationsEntry {
  key: string;
  value: string;
}

export interface IRosTypeBosdynApiMsgsSeriesDescriptorOneOfDataType {
  message_type: IRosTypeBosdynApiMsgsMessageTypeDescriptor;
  pod_type: IRosTypeBosdynApiMsgsPodTypeDescriptor;
  struct_type: IRosTypeBosdynApiMsgsStructTypeDescriptor;
  data_type_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsSeriesDescriptorOneOfDataTypeConst {
  DATA_TYPE_NOT_SET = 0,
  DATA_TYPE_MESSAGE_TYPE_SET = 1,
  DATA_TYPE_POD_TYPE_SET = 2,
  DATA_TYPE_STRUCT_TYPE_SET = 3,
}

export interface IRosTypeBosdynApiMsgsSeriesIdentifier {
  series_type: string;
  spec: IRosTypeBosdynApiMsgsSeriesIdentifierSpecEntry[];
}

export interface IRosTypeBosdynApiMsgsSeriesIdentifierSpecEntry {
  key: string;
  value: string;
}

export interface IRosTypeBosdynApiMsgsServiceEntry {
  name: string;
  authority: string;
  last_update: { sec: number; nanosec: number };
  user_token_required: boolean;
  permission_required: string;
  liveness_timeout_secs: number;
  host_payload_guid: string;
  service_type: IRosTypeBosdynApiMsgsServiceEntryOneOfServiceType;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsServiceEntryConst {
  LAST_UPDATE_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsServiceEntryOneOfServiceType {
  type: string;
  service_type_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsServiceEntryOneOfServiceTypeConst {
  SERVICE_TYPE_NOT_SET = 0,
  SERVICE_TYPE_TYPE_SET = 1,
}

export interface IRosTypeBosdynApiMsgsServiceFault {
  fault_id: IRosTypeBosdynApiMsgsServiceFaultId;
  error_message: string;
  attributes: string[];
  severity: IRosTypeBosdynApiMsgsServiceFaultSeverity;
  onset_timestamp: { sec: number; nanosec: number };
  duration: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsServiceFaultConst {
  FAULT_ID_FIELD_SET = 1,
  ONSET_TIMESTAMP_FIELD_SET = 16,
  DURATION_FIELD_SET = 32,
}

export interface IRosTypeBosdynApiMsgsServiceFaultId {
  fault_name: string;
  service_name: string;
  payload_guid: string;
}

export interface IRosTypeBosdynApiMsgsServiceFaultSeverity {
  value: number;
}

export enum IRosTypeBosdynApiMsgsServiceFaultSeverityConst {
  SEVERITY_UNKNOWN = 0,
  SEVERITY_INFO = 1,
  SEVERITY_WARN = 2,
  SEVERITY_CRITICAL = 3,
}

export interface IRosTypeBosdynApiMsgsServiceFaultState {
  faults: IRosTypeBosdynApiMsgsServiceFault[];
  historical_faults: IRosTypeBosdynApiMsgsServiceFault[];
  aggregated: IRosTypeBosdynApiMsgsServiceFaultStateAggregatedEntry[];
}

export interface IRosTypeBosdynApiMsgsServiceFaultStateAggregatedEntry {
  key: string;
  value: IRosTypeBosdynApiMsgsServiceFaultSeverity;
}

export interface IRosTypeBosdynApiMsgsSetEstopConfigRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  config: IRosTypeBosdynApiMsgsEstopConfig;
  target_config_id: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSetEstopConfigRequestConst {
  HEADER_FIELD_SET = 1,
  CONFIG_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsSetEstopConfigResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  request: IRosTypeBosdynApiMsgsSetEstopConfigRequest;
  active_config: IRosTypeBosdynApiMsgsEstopConfig;
  status: IRosTypeBosdynApiMsgsSetEstopConfigResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSetEstopConfigResponseConst {
  HEADER_FIELD_SET = 1,
  REQUEST_FIELD_SET = 2,
  ACTIVE_CONFIG_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsSetEstopConfigResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsSetEstopConfigResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_SUCCESS = 1,
  STATUS_INVALID_ID = 2,
  STATUS_MOTORS_ON = 4,
}

export interface IRosTypeBosdynApiMsgsSignal {
  signal_spec: IRosTypeBosdynApiMsgsSignalSpec;
  signal_data: IRosTypeBosdynApiMsgsSignalData;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSignalConst {
  SIGNAL_SPEC_FIELD_SET = 1,
  SIGNAL_DATA_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsSignalData {
  data: IRosTypeBosdynApiMsgsSignalDataData;
  timestamp: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSignalDataConst {
  DATA_FIELD_SET = 1,
  TIMESTAMP_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsSignalDataData {
  value: IRosTypeBosdynApiMsgsSignalDataDataOneOfValue;
}

export interface IRosTypeBosdynApiMsgsSignalDataDataOneOfValue {
  double_field: number;
  int_field: number;
  string: string;
  bool_field: boolean;
  value_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsSignalDataDataOneOfValueConst {
  VALUE_NOT_SET = 0,
  VALUE_DOUBLE_FIELD_SET = 1,
  VALUE_INT_FIELD_SET = 2,
  VALUE_STRING_SET = 3,
  VALUE_BOOL_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsSignalDisplayInfo {
  name: string;
  description: string;
  order: number;
}

export interface IRosTypeBosdynApiMsgsSignalSchema {
  vars: IRosTypeBosdynApiMsgsSignalSchemaVariable[];
  schema_name: string;
}

export interface IRosTypeBosdynApiMsgsSignalSchemaId {
  schema_id: number;
  schema: IRosTypeBosdynApiMsgsSignalSchema;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSignalSchemaIdConst {
  SCHEMA_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsSignalSchemaVariable {
  name: string;
  type: IRosTypeBosdynApiMsgsSignalSchemaVariableType;
  is_time: boolean;
}

export interface IRosTypeBosdynApiMsgsSignalSchemaVariableType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsSignalSchemaVariableTypeConst {
  TYPE_UNKNOWN = 0,
  TYPE_INT8 = 1,
  TYPE_INT16 = 2,
  TYPE_INT32 = 3,
  TYPE_INT64 = 4,
  TYPE_UINT8 = 5,
  TYPE_UINT16 = 6,
  TYPE_UINT32 = 7,
  TYPE_UINT64 = 8,
  TYPE_FLOAT32 = 9,
  TYPE_FLOAT64 = 10,
}

export interface IRosTypeBosdynApiMsgsSignalSpec {
  info: IRosTypeBosdynApiMsgsSignalDisplayInfo;
  sensor: IRosTypeBosdynApiMsgsSensorOutputSpec;
  alerts: IRosTypeBosdynApiMsgsAlertConditionSpec[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSignalSpecConst {
  INFO_FIELD_SET = 1,
  SENSOR_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsSignalTick {
  sequence_id: number;
  timestamp: { sec: number; nanosec: number };
  source: string;
  schema_id: number;
  encoding: IRosTypeBosdynApiMsgsSignalTickEncoding;
  data: number[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSignalTickConst {
  TIMESTAMP_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsSignalTickEncoding {
  value: number;
}

export enum IRosTypeBosdynApiMsgsSignalTickEncodingConst {
  ENCODING_UNKNOWN = 0,
  ENCODING_RAW = 1,
}

export interface IRosTypeBosdynApiMsgsSitCommandFeedback {
  status: IRosTypeBosdynApiMsgsSitCommandFeedbackStatus;
}

export interface IRosTypeBosdynApiMsgsSitCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsSitCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_IS_SITTING = 1,
  STATUS_IN_PROGRESS = 2,
}

export interface IRosTypeBosdynApiMsgsSkeleton {
  links: IRosTypeBosdynApiMsgsSkeletonLink[];
  urdf: string;
}

export interface IRosTypeBosdynApiMsgsSkeletonLink {
  name: string;
  obj_model: IRosTypeBosdynApiMsgsSkeletonLinkObjModel;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSkeletonLinkConst {
  OBJ_MODEL_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsSkeletonLinkObjModel {
  file_name: string;
  file_contents: string;
}

export interface IRosTypeBosdynApiMsgsSoftwareVersion {
  major_version: number;
  minor_version: number;
  patch_level: number;
}

export interface IRosTypeBosdynApiMsgsSqueezeGrasp {
  squeeze_grasp_disallowed: boolean;
}

export interface IRosTypeBosdynApiMsgsStairTransform {
  frame_tform_stairs: IRosTypeGeometryMsgsPose;
  frame_name: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStairTransformConst {
  FRAME_TFORM_STAIRS_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsStaircase {
  knowledge_type: IRosTypeBosdynApiMsgsStaircaseKnowledgeType;
  stair_tform: IRosTypeBosdynApiMsgsStairTransform;
  number_of_steps: number;
  average_rise: number;
  average_run: number;
  average_width: IRosTypeBosdynApiMsgsStaircaseWidth;
  steps: IRosTypeBosdynApiMsgsStaircaseStep[];
  id: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStaircaseConst {
  STAIR_TFORM_FIELD_SET = 2,
  AVERAGE_WIDTH_FIELD_SET = 32,
}

export interface IRosTypeBosdynApiMsgsStaircaseKnowledgeType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsStaircaseKnowledgeTypeConst {
  KNOWLEDGE_TYPE_UNKNOWN = 0,
  KNOWLEDGE_TYPE_MAPPED = 1,
  KNOWLEDGE_TYPE_TRACKED_ONGOING = 2,
  KNOWLEDGE_TYPE_TRACKED_COMPLETED = 3,
  KNOWLEDGE_TYPE_OTHER = 4,
}

export interface IRosTypeBosdynApiMsgsStaircaseLanding {
  stairs_tform_landing_center: IRosTypeGeometryMsgsPose;
  landing_extent_x: number;
  landing_extent_y: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStaircaseLandingConst {
  STAIRS_TFORM_LANDING_CENTER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsStaircaseProperties {
  staircase: IRosTypeBosdynApiMsgsStaircase;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStaircasePropertiesConst {
  STAIRCASE_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsStaircaseStep {
  point: IRosTypeGeometryMsgsVector3;
  north: IRosTypeBosdynApiMsgsVec2;
  width: IRosTypeBosdynApiMsgsStaircaseWidth;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStaircaseStepConst {
  POINT_FIELD_SET = 1,
  NORTH_FIELD_SET = 2,
  WIDTH_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsStaircaseWidth {
  width: number;
  bounded_width: IRosTypeBosdynApiMsgsStaircaseWidthBoundedWidth;
}

export interface IRosTypeBosdynApiMsgsStaircaseWidthBoundedWidth {
  value: number;
}

export enum IRosTypeBosdynApiMsgsStaircaseWidthBoundedWidthConst {
  BOUNDED_WIDTH_UNKNOWN = 0,
  BOUNDED_WIDTH_NEITHER = 1,
  BOUNDED_WIDTH_WEST = 2,
  BOUNDED_WIDTH_EAST = 3,
  BOUNDED_WIDTH_BOTH = 4,
}

export interface IRosTypeBosdynApiMsgsStaircaseWithLandings {
  bottom_landing: IRosTypeBosdynApiMsgsStaircaseLanding;
  staircase: IRosTypeBosdynApiMsgsStaircase;
  top_landing: IRosTypeBosdynApiMsgsStaircaseLanding;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStaircaseWithLandingsConst {
  BOTTOM_LANDING_FIELD_SET = 1,
  STAIRCASE_FIELD_SET = 2,
  TOP_LANDING_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsStance {
  se2_frame_name: string;
  foot_positions: IRosTypeBosdynApiMsgsStanceFootPositionsEntry[];
  accuracy: number;
}

export interface IRosTypeBosdynApiMsgsStanceCommandFeedback {
  status: IRosTypeBosdynApiMsgsStanceCommandFeedbackStatus;
}

export interface IRosTypeBosdynApiMsgsStanceCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsStanceCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_STANCED = 1,
  STATUS_GOING_TO_STANCE = 2,
  STATUS_TOO_FAR_AWAY = 3,
}

export interface IRosTypeBosdynApiMsgsStanceCommandRequest {
  end_time: { sec: number; nanosec: number };
  stance: IRosTypeBosdynApiMsgsStance;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStanceCommandRequestConst {
  END_TIME_FIELD_SET = 1,
  STANCE_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsStanceFootPositionsEntry {
  key: string;
  value: IRosTypeBosdynApiMsgsVec2;
}

export interface IRosTypeBosdynApiMsgsStandCommandFeedback {
  status: IRosTypeBosdynApiMsgsStandCommandFeedbackStatus;
  standing_state: IRosTypeBosdynApiMsgsStandCommandFeedbackStandingState;
}

export interface IRosTypeBosdynApiMsgsStandCommandFeedbackStandingState {
  value: number;
}

export enum IRosTypeBosdynApiMsgsStandCommandFeedbackStandingStateConst {
  STANDING_UNKNOWN = 0,
  STANDING_CONTROLLED = 1,
  STANDING_FROZEN = 2,
}

export interface IRosTypeBosdynApiMsgsStandCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsStandCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_IS_STANDING = 1,
  STATUS_IN_PROGRESS = 2,
}

export interface IRosTypeBosdynApiMsgsStoreAlertDataRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  alert_data: IRosTypeBosdynApiMsgsAssociatedAlertData;
  data_id: IRosTypeBosdynApiMsgsDataIdentifier;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStoreAlertDataRequestConst {
  HEADER_FIELD_SET = 1,
  ALERT_DATA_FIELD_SET = 2,
  DATA_ID_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsStoreAlertDataResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStoreAlertDataResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsStoreDataRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  data: number[];
  data_id: IRosTypeBosdynApiMsgsDataIdentifier;
  file_extension: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStoreDataRequestConst {
  HEADER_FIELD_SET = 1,
  DATA_ID_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsStoreDataResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStoreDataResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsStoreImageRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  image: IRosTypeBosdynApiMsgsImageCapture;
  data_id: IRosTypeBosdynApiMsgsDataIdentifier;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStoreImageRequestConst {
  HEADER_FIELD_SET = 1,
  IMAGE_FIELD_SET = 2,
  DATA_ID_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsStoreImageResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStoreImageResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsStoreMetadataRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  metadata: IRosTypeBosdynApiMsgsAssociatedMetadata;
  data_id: IRosTypeBosdynApiMsgsDataIdentifier;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStoreMetadataRequestConst {
  HEADER_FIELD_SET = 1,
  METADATA_FIELD_SET = 2,
  DATA_ID_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsStoreMetadataResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  id: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStoreMetadataResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsStoredCapturedData {
  data: number[];
  file_extension: string;
}

export interface IRosTypeBosdynApiMsgsStraightStaircase {
  stairs: IRosTypeBosdynApiMsgsStraightStaircaseStair[];
  bottom_landing: IRosTypeBosdynApiMsgsStraightStaircaseLanding;
  top_landing: IRosTypeBosdynApiMsgsStraightStaircaseLanding;
  location: IRosTypeBosdynApiMsgsStraightStaircaseOneOfLocation;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStraightStaircaseConst {
  BOTTOM_LANDING_FIELD_SET = 8,
  TOP_LANDING_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsStraightStaircaseLanding {
  stairs_tform_landing_center: IRosTypeGeometryMsgsPose;
  landing_extent_x: number;
  landing_extent_y: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsStraightStaircaseLandingConst {
  STAIRS_TFORM_LANDING_CENTER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsStraightStaircaseOneOfLocation {
  from_ko_tform_stairs: IRosTypeGeometryMsgsPose;
  tform: IRosTypeBosdynApiMsgsStairTransform;
  location_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsStraightStaircaseOneOfLocationConst {
  LOCATION_NOT_SET = 0,
  LOCATION_FROM_KO_TFORM_STAIRS_SET = 1,
  LOCATION_TFORM_SET = 2,
}

export interface IRosTypeBosdynApiMsgsStraightStaircaseStair {
  rise: number;
  run: number;
}

export interface IRosTypeBosdynApiMsgsStringParam {
  value: string;
}

export interface IRosTypeBosdynApiMsgsStringParamSpec {
  options: string[];
  editable: boolean;
  default_value: string;
}

export interface IRosTypeBosdynApiMsgsStructTypeDescriptor {
  key_to_series_identifier_hash: IRosTypeBosdynApiMsgsStructTypeDescriptorKeyToSeriesIdentifierHashEntry[];
}

export interface IRosTypeBosdynApiMsgsStructTypeDescriptorKeyToSeriesIdentifierHashEntry {
  key: string;
  value: number;
}

export interface IRosTypeBosdynApiMsgsSynchronizedCommandFeedback {
  arm_command_feedback: IRosTypeBosdynApiMsgsArmCommandFeedback;
  mobility_command_feedback: IRosTypeBosdynApiMsgsMobilityCommandFeedback;
  gripper_command_feedback: IRosTypeBosdynApiMsgsGripperCommandFeedback;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSynchronizedCommandFeedbackConst {
  ARM_COMMAND_FEEDBACK_FIELD_SET = 1,
  MOBILITY_COMMAND_FEEDBACK_FIELD_SET = 2,
  GRIPPER_COMMAND_FEEDBACK_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsSynchronizedCommandRequest {
  arm_command: IRosTypeBosdynApiMsgsArmCommandRequest;
  mobility_command: IRosTypeBosdynApiMsgsMobilityCommandRequest;
  gripper_command: IRosTypeBosdynApiMsgsGripperCommandRequest;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSynchronizedCommandRequestConst {
  ARM_COMMAND_FIELD_SET = 1,
  MOBILITY_COMMAND_FIELD_SET = 2,
  GRIPPER_COMMAND_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsSystemFault {
  name: string;
  onset_timestamp: { sec: number; nanosec: number };
  duration: { sec: number; nanosec: number };
  code: number;
  uid: number;
  error_message: string;
  attributes: string[];
  severity: IRosTypeBosdynApiMsgsSystemFaultSeverity;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsSystemFaultConst {
  ONSET_TIMESTAMP_FIELD_SET = 2,
  DURATION_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsSystemFaultSeverity {
  value: number;
}

export enum IRosTypeBosdynApiMsgsSystemFaultSeverityConst {
  SEVERITY_UNKNOWN = 0,
  SEVERITY_INFO = 1,
  SEVERITY_WARN = 2,
  SEVERITY_CRITICAL = 3,
}

export interface IRosTypeBosdynApiMsgsSystemFaultState {
  faults: IRosTypeBosdynApiMsgsSystemFault[];
  historical_faults: IRosTypeBosdynApiMsgsSystemFault[];
  aggregated: IRosTypeBosdynApiMsgsSystemFaultStateAggregatedEntry[];
}

export interface IRosTypeBosdynApiMsgsSystemFaultStateAggregatedEntry {
  key: string;
  value: IRosTypeBosdynApiMsgsSystemFaultSeverity;
}

export interface IRosTypeBosdynApiMsgsSystemState {
  motor_temperatures: IRosTypeBosdynApiMsgsMotorTemperature[];
}

export interface IRosTypeBosdynApiMsgsTakeLeaseRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  resource: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsTakeLeaseRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsTakeLeaseResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsTakeLeaseResponseStatus;
  lease: IRosTypeBosdynApiMsgsLease;
  lease_owner: IRosTypeBosdynApiMsgsLeaseOwner;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsTakeLeaseResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 4,
  LEASE_OWNER_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsTakeLeaseResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsTakeLeaseResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_RESOURCE = 2,
  STATUS_NOT_AUTHORITATIVE_SERVICE = 3,
}

export interface IRosTypeBosdynApiMsgsTemperatureEnum {
  value: number;
}

export enum IRosTypeBosdynApiMsgsTemperatureEnumConst {
  TEMPERATURE_UNKNOWN = 0,
  TEMPERATURE_KELVIN = 1,
  TEMPERATURE_CELSIUS = 2,
  TEMPERATURE_FAHRENHEIT = 3,
}

export interface IRosTypeBosdynApiMsgsTerrainState {
  is_unsafe_to_sit: boolean;
}

export interface IRosTypeBosdynApiMsgsTextMessage {
  message: string;
  timestamp: { sec: number; nanosec: number };
  source: string;
  level: IRosTypeBosdynApiMsgsTextMessageLevel;
  tag: string;
  filename: string;
  line_number: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsTextMessageConst {
  TIMESTAMP_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsTextMessageLevel {
  value: number;
}

export enum IRosTypeBosdynApiMsgsTextMessageLevelConst {
  LEVEL_UNKNOWN = 0,
  LEVEL_DEBUG = 1,
  LEVEL_INFO = 2,
  LEVEL_WARN = 3,
  LEVEL_ERROR = 4,
}

export interface IRosTypeBosdynApiMsgsTimeRange {
  start: { sec: number; nanosec: number };
  end: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsTimeRangeConst {
  START_FIELD_SET = 1,
  END_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsTimeRangeQuery {
  from_timestamp: { sec: number; nanosec: number };
  to_timestamp: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsTimeRangeQueryConst {
  FROM_TIMESTAMP_FIELD_SET = 1,
  TO_TIMESTAMP_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsTimeSyncEstimate {
  round_trip_time: { sec: number; nanosec: number };
  clock_skew: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsTimeSyncEstimateConst {
  ROUND_TRIP_TIME_FIELD_SET = 1,
  CLOCK_SKEW_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsTimeSyncRoundTrip {
  client_tx: { sec: number; nanosec: number };
  server_rx: { sec: number; nanosec: number };
  server_tx: { sec: number; nanosec: number };
  client_rx: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsTimeSyncRoundTripConst {
  CLIENT_TX_FIELD_SET = 1,
  SERVER_RX_FIELD_SET = 2,
  SERVER_TX_FIELD_SET = 4,
  CLIENT_RX_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsTimeSyncState {
  best_estimate: IRosTypeBosdynApiMsgsTimeSyncEstimate;
  status: IRosTypeBosdynApiMsgsTimeSyncStateStatus;
  measurement_time: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsTimeSyncStateConst {
  BEST_ESTIMATE_FIELD_SET = 1,
  MEASUREMENT_TIME_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsTimeSyncStateStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsTimeSyncStateStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_MORE_SAMPLES_NEEDED = 2,
  STATUS_SERVICE_NOT_READY = 3,
}

export interface IRosTypeBosdynApiMsgsTimeSyncUpdateRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  previous_round_trip: IRosTypeBosdynApiMsgsTimeSyncRoundTrip;
  clock_identifier: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsTimeSyncUpdateRequestConst {
  HEADER_FIELD_SET = 1,
  PREVIOUS_ROUND_TRIP_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsTimeSyncUpdateResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  previous_estimate: IRosTypeBosdynApiMsgsTimeSyncEstimate;
  state: IRosTypeBosdynApiMsgsTimeSyncState;
  clock_identifier: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsTimeSyncUpdateResponseConst {
  HEADER_FIELD_SET = 1,
  PREVIOUS_ESTIMATE_FIELD_SET = 2,
  STATE_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsTriggerServiceFaultRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  fault: IRosTypeBosdynApiMsgsServiceFault;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsTriggerServiceFaultRequestConst {
  HEADER_FIELD_SET = 1,
  FAULT_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsTriggerServiceFaultResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsTriggerServiceFaultResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsTriggerServiceFaultResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsTriggerServiceFaultResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsTriggerServiceFaultResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_FAULT_ALREADY_ACTIVE = 2,
}

export interface IRosTypeBosdynApiMsgsUnits {
  is_relative: boolean;
  units: IRosTypeBosdynApiMsgsUnitsOneOfUnits;
}

export interface IRosTypeBosdynApiMsgsUnitsOneOfUnits {
  name: string;
  temp: IRosTypeBosdynApiMsgsTemperatureEnum;
  press: IRosTypeBosdynApiMsgsPressureEnum;
  units_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsUnitsOneOfUnitsConst {
  UNITS_NOT_SET = 0,
  UNITS_NAME_SET = 1,
  UNITS_TEMP_SET = 2,
  UNITS_PRESS_SET = 3,
}

export interface IRosTypeBosdynApiMsgsUnregisterServiceRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  service_name: string;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsUnregisterServiceRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsUnregisterServiceResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsUnregisterServiceResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsUnregisterServiceResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsUnregisterServiceResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsUnregisterServiceResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NONEXISTENT_SERVICE = 2,
}

export interface IRosTypeBosdynApiMsgsUpdateDockingParams {
  end_time: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsUpdateDockingParamsConst {
  END_TIME_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsUpdatePayloadAttachedRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  payload_credentials: IRosTypeBosdynApiMsgsPayloadCredentials;
  request: IRosTypeBosdynApiMsgsUpdatePayloadAttachedRequestRequest;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsUpdatePayloadAttachedRequestConst {
  HEADER_FIELD_SET = 1,
  PAYLOAD_CREDENTIALS_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsUpdatePayloadAttachedRequestRequest {
  value: number;
}

export enum IRosTypeBosdynApiMsgsUpdatePayloadAttachedRequestRequestConst {
  REQUEST_UNKNOWN = 0,
  REQUEST_ATTACH = 1,
  REQUEST_DETACH = 2,
}

export interface IRosTypeBosdynApiMsgsUpdatePayloadAttachedResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsUpdatePayloadAttachedResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsUpdatePayloadAttachedResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsUpdatePayloadAttachedResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsUpdatePayloadAttachedResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_DOES_NOT_EXIST = 2,
  STATUS_INVALID_CREDENTIALS = 3,
  STATUS_PAYLOAD_NOT_AUTHORIZED = 4,
}

export interface IRosTypeBosdynApiMsgsUpdatePayloadVersionRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  payload_credentials: IRosTypeBosdynApiMsgsPayloadCredentials;
  payload_guid: string;
  payload_secret: string;
  updated_version: IRosTypeBosdynApiMsgsSoftwareVersion;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsUpdatePayloadVersionRequestConst {
  HEADER_FIELD_SET = 1,
  PAYLOAD_CREDENTIALS_FIELD_SET = 2,
  UPDATED_VERSION_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsUpdatePayloadVersionResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsUpdatePayloadVersionResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsUpdatePayloadVersionResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsUpdatePayloadVersionResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsUpdatePayloadVersionResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_DOES_NOT_EXIST = 2,
  STATUS_INVALID_CREDENTIALS = 3,
}

export interface IRosTypeBosdynApiMsgsUpdateServiceRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  endpoint: IRosTypeBosdynApiMsgsEndpoint;
  service_entry: IRosTypeBosdynApiMsgsServiceEntry;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsUpdateServiceRequestConst {
  HEADER_FIELD_SET = 1,
  ENDPOINT_FIELD_SET = 2,
  SERVICE_ENTRY_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsUpdateServiceResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsUpdateServiceResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsUpdateServiceResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynApiMsgsUpdateServiceResponseStatus {
  value: number;
}

export enum IRosTypeBosdynApiMsgsUpdateServiceResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NONEXISTENT_SERVICE = 2,
}

export interface IRosTypeBosdynApiMsgsUserInterfaceInfo {
  display_name: string;
  description: string;
  display_order: number;
}

export interface IRosTypeBosdynApiMsgsVec2 {
  x: number;
  y: number;
}

export interface IRosTypeBosdynApiMsgsVec2Value {
  x: IRosTypeStdMsgsFloat64;
  y: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsVec2ValueConst {
  X_FIELD_SET = 1,
  Y_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsVec3Trajectory {
  points: IRosTypeBosdynApiMsgsVec3TrajectoryPoint[];
  reference_time: { sec: number; nanosec: number };
  pos_interpolation: IRosTypeBosdynApiMsgsPositionalInterpolation;
  starting_velocity: IRosTypeGeometryMsgsVector3;
  ending_velocity: IRosTypeGeometryMsgsVector3;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsVec3TrajectoryConst {
  REFERENCE_TIME_FIELD_SET = 2,
  STARTING_VELOCITY_FIELD_SET = 8,
  ENDING_VELOCITY_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsVec3TrajectoryPoint {
  point: IRosTypeGeometryMsgsVector3;
  linear_speed: number;
  time_since_reference: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsVec3TrajectoryPointConst {
  POINT_FIELD_SET = 1,
  TIME_SINCE_REFERENCE_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsVec3Value {
  x: IRosTypeStdMsgsFloat64;
  y: IRosTypeStdMsgsFloat64;
  z: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsVec3ValueConst {
  X_FIELD_SET = 1,
  Y_FIELD_SET = 2,
  Z_FIELD_SET = 4,
}

export interface IRosTypeBosdynApiMsgsVector {
  values: number[];
}

export interface IRosTypeBosdynApiMsgsVectorAlignmentWithTolerance {
  axis_on_gripper_ewrt_gripper: IRosTypeGeometryMsgsVector3;
  axis_to_align_with_ewrt_frame: IRosTypeGeometryMsgsVector3;
  threshold_radians: number;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsVectorAlignmentWithToleranceConst {
  AXIS_ON_GRIPPER_EWRT_GRIPPER_FIELD_SET = 1,
  AXIS_TO_ALIGN_WITH_EWRT_FRAME_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsVolume {
  geometry: IRosTypeBosdynApiMsgsVolumeOneOfGeometry;
}

export interface IRosTypeBosdynApiMsgsVolumeOneOfGeometry {
  box: IRosTypeGeometryMsgsVector3;
  geometry_choice: number;
  which: number;
}

export enum IRosTypeBosdynApiMsgsVolumeOneOfGeometryConst {
  GEOMETRY_NOT_SET = 0,
  GEOMETRY_BOX_SET = 1,
}

export interface IRosTypeBosdynApiMsgsWalkGazeMode {
  value: number;
}

export enum IRosTypeBosdynApiMsgsWalkGazeModeConst {
  PICK_WALK_GAZE_UNKNOWN = 0,
  PICK_AUTO_WALK_AND_GAZE = 1,
  PICK_AUTO_GAZE = 2,
  PICK_NO_AUTO_WALK_OR_GAZE = 3,
  PICK_PLAN_ONLY = 4,
}

export interface IRosTypeBosdynApiMsgsWalkToObjectInImage {
  pixel_xy: IRosTypeBosdynApiMsgsVec2;
  transforms_snapshot_for_camera: IRosTypeBosdynApiMsgsFrameTreeSnapshot;
  frame_name_image_sensor: string;
  camera_model: IRosTypeBosdynApiMsgsImageSourcePinholeModel;
  offset_distance: IRosTypeStdMsgsFloat32;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsWalkToObjectInImageConst {
  PIXEL_XY_FIELD_SET = 1,
  TRANSFORMS_SNAPSHOT_FOR_CAMERA_FIELD_SET = 2,
  CAMERA_MODEL_FIELD_SET = 8,
  OFFSET_DISTANCE_FIELD_SET = 16,
}

export interface IRosTypeBosdynApiMsgsWalkToObjectRayInWorld {
  ray_start_rt_frame: IRosTypeGeometryMsgsVector3;
  ray_end_rt_frame: IRosTypeGeometryMsgsVector3;
  frame_name: string;
  offset_distance: IRosTypeStdMsgsFloat32;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsWalkToObjectRayInWorldConst {
  RAY_START_RT_FRAME_FIELD_SET = 1,
  RAY_END_RT_FRAME_FIELD_SET = 2,
  OFFSET_DISTANCE_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsWiFiState {
  current_mode: IRosTypeBosdynApiMsgsWiFiStateMode;
  essid: string;
}

export interface IRosTypeBosdynApiMsgsWiFiStateMode {
  value: number;
}

export enum IRosTypeBosdynApiMsgsWiFiStateModeConst {
  MODE_UNKNOWN = 0,
  MODE_ACCESS_POINT = 1,
  MODE_CLIENT = 2,
}

export interface IRosTypeBosdynApiMsgsWifiDevice {
  type: IRosTypeBosdynApiMsgsWifiDeviceType;
  name: string;
  mac_address: string;
  ssid: string;
  tx_power_dbm: number;
  associations: IRosTypeBosdynApiMsgsAssociation[];
}

export interface IRosTypeBosdynApiMsgsWifiDeviceType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsWifiDeviceTypeConst {
  UNKNOWN = 0,
  AP = 1,
  CLIENT = 2,
}

export interface IRosTypeBosdynApiMsgsWifiStats {
  hostname: string;
  devices: IRosTypeBosdynApiMsgsWifiDevice[];
}

export interface IRosTypeBosdynApiMsgsWorkerComputeRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  input_data: IRosTypeBosdynApiMsgsNetworkComputeInputDataWorker;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsWorkerComputeRequestConst {
  HEADER_FIELD_SET = 1,
  INPUT_DATA_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsWorkerComputeResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  other_data: IRosTypeProto2RosAnyProto;
  status: IRosTypeBosdynApiMsgsNetworkComputeStatus;
  custom_param_error: IRosTypeBosdynApiMsgsCustomParamError;
  output_images: IRosTypeBosdynApiMsgsWorkerComputeResponseOutputImagesEntry[];
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsWorkerComputeResponseConst {
  HEADER_FIELD_SET = 1,
  OTHER_DATA_FIELD_SET = 2,
  CUSTOM_PARAM_ERROR_FIELD_SET = 8,
}

export interface IRosTypeBosdynApiMsgsWorkerComputeResponseOutputImagesEntry {
  key: string;
  value: IRosTypeBosdynApiMsgsOutputImage;
}

export interface IRosTypeBosdynApiMsgsWorldObject {
  id: number;
  name: string;
  acquisition_time: { sec: number; nanosec: number };
  transforms_snapshot: IRosTypeBosdynApiMsgsFrameTreeSnapshot;
  object_lifetime: { sec: number; nanosec: number };
  drawable_properties: IRosTypeBosdynApiMsgsDrawableProperties[];
  apriltag_properties: IRosTypeBosdynApiMsgsAprilTagProperties;
  nogo_region_properties: IRosTypeBosdynApiMsgsNoGoRegionProperties;
  image_properties: IRosTypeBosdynApiMsgsImageProperties;
  dock_properties: IRosTypeBosdynApiMsgsDockProperties;
  ray_properties: IRosTypeBosdynApiMsgsRayProperties;
  bounding_box_properties: IRosTypeBosdynApiMsgsBoundingBoxProperties;
  staircase_properties: IRosTypeBosdynApiMsgsStaircaseProperties;
  gps_properties: IRosTypeBosdynApiMsgsGpsProperties;
  additional_properties: IRosTypeProto2RosAnyProto;
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsWorldObjectConst {
  ACQUISITION_TIME_FIELD_SET = 4,
  TRANSFORMS_SNAPSHOT_FIELD_SET = 8,
  OBJECT_LIFETIME_FIELD_SET = 16,
  APRILTAG_PROPERTIES_FIELD_SET = 64,
  NOGO_REGION_PROPERTIES_FIELD_SET = 128,
  IMAGE_PROPERTIES_FIELD_SET = 256,
  DOCK_PROPERTIES_FIELD_SET = 512,
  RAY_PROPERTIES_FIELD_SET = 1024,
  BOUNDING_BOX_PROPERTIES_FIELD_SET = 2048,
  STAIRCASE_PROPERTIES_FIELD_SET = 4096,
  GPS_PROPERTIES_FIELD_SET = 8192,
  ADDITIONAL_PROPERTIES_FIELD_SET = 16384,
}

export interface IRosTypeBosdynApiMsgsWorldObjectType {
  value: number;
}

export enum IRosTypeBosdynApiMsgsWorldObjectTypeConst {
  WORLD_OBJECT_UNKNOWN = 0,
  WORLD_OBJECT_DRAWABLE = 1,
  WORLD_OBJECT_APRILTAG = 2,
  WORLD_OBJECT_IMAGE_COORDINATES = 5,
  WORLD_OBJECT_DOCK = 6,
  WORLD_OBJECT_USER_NOGO = 8,
  WORLD_OBJECT_STAIRCASE = 9,
}

export interface IRosTypeBosdynApiMsgsWrenchTrajectory {
  points: IRosTypeBosdynApiMsgsWrenchTrajectoryPoint[];
  reference_time: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsWrenchTrajectoryConst {
  REFERENCE_TIME_FIELD_SET = 2,
}

export interface IRosTypeBosdynApiMsgsWrenchTrajectoryPoint {
  wrench: IRosTypeGeometryMsgsWrench;
  time_since_reference: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynApiMsgsWrenchTrajectoryPointConst {
  WRENCH_FIELD_SET = 1,
  TIME_SINCE_REFERENCE_FIELD_SET = 2,
}

export interface IRosTypeBosdynAutoReturnApiMsgsConfigureRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  leases: IRosTypeBosdynApiMsgsLease[];
  params: IRosTypeBosdynAutoReturnApiMsgsParams;
  clear_buffer: boolean;
  has_field: number;
}

export enum IRosTypeBosdynAutoReturnApiMsgsConfigureRequestConst {
  HEADER_FIELD_SET = 1,
  PARAMS_FIELD_SET = 4,
}

export interface IRosTypeBosdynAutoReturnApiMsgsConfigureResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynAutoReturnApiMsgsConfigureResponseStatus;
  invalid_params: IRosTypeBosdynAutoReturnApiMsgsParams;
  has_field: number;
}

export enum IRosTypeBosdynAutoReturnApiMsgsConfigureResponseConst {
  HEADER_FIELD_SET = 1,
  INVALID_PARAMS_FIELD_SET = 4,
}

export interface IRosTypeBosdynAutoReturnApiMsgsConfigureResponseStatus {
  value: number;
}

export enum IRosTypeBosdynAutoReturnApiMsgsConfigureResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_PARAMS = 2,
}

export interface IRosTypeBosdynAutoReturnApiMsgsGetConfigurationRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynAutoReturnApiMsgsGetConfigurationRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynAutoReturnApiMsgsGetConfigurationResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  enabled: boolean;
  request: IRosTypeBosdynAutoReturnApiMsgsConfigureRequest;
  has_field: number;
}

export enum IRosTypeBosdynAutoReturnApiMsgsGetConfigurationResponseConst {
  HEADER_FIELD_SET = 1,
  REQUEST_FIELD_SET = 4,
}

export interface IRosTypeBosdynAutoReturnApiMsgsParams {
  mobility_params: IRosTypeProto2RosAnyProto;
  max_displacement: number;
  max_duration: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynAutoReturnApiMsgsParamsConst {
  MOBILITY_PARAMS_FIELD_SET = 1,
  MAX_DURATION_FIELD_SET = 4,
}

export interface IRosTypeBosdynAutoReturnApiMsgsStartRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  leases: IRosTypeBosdynApiMsgsLease[];
  params: IRosTypeBosdynAutoReturnApiMsgsParams;
  has_field: number;
}

export enum IRosTypeBosdynAutoReturnApiMsgsStartRequestConst {
  HEADER_FIELD_SET = 1,
  PARAMS_FIELD_SET = 4,
}

export interface IRosTypeBosdynAutoReturnApiMsgsStartResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynAutoReturnApiMsgsStartResponseStatus;
  invalid_params: IRosTypeBosdynAutoReturnApiMsgsParams;
  has_field: number;
}

export enum IRosTypeBosdynAutoReturnApiMsgsStartResponseConst {
  HEADER_FIELD_SET = 1,
  INVALID_PARAMS_FIELD_SET = 4,
}

export interface IRosTypeBosdynAutoReturnApiMsgsStartResponseStatus {
  value: number;
}

export enum IRosTypeBosdynAutoReturnApiMsgsStartResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_PARAMS = 2,
}

export interface IRosTypeBosdynAutowalkApiMsgsAction {
  action: IRosTypeBosdynAutowalkApiMsgsActionOneOfAction;
}

export interface IRosTypeBosdynAutowalkApiMsgsActionDataAcquisition {
  acquire_data_request: IRosTypeBosdynApiMsgsAcquireDataRequest;
  completion_behavior: IRosTypeBosdynMissionApiMsgsDataAcquisitionCompletionBehavior;
  last_known_capabilities: IRosTypeBosdynApiMsgsAcquisitionCapabilityList;
  record_time_images: IRosTypeBosdynApiMsgsImageCaptureAndSource[];
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsActionDataAcquisitionConst {
  ACQUIRE_DATA_REQUEST_FIELD_SET = 1,
  LAST_KNOWN_CAPABILITIES_FIELD_SET = 4,
}

export interface IRosTypeBosdynAutowalkApiMsgsActionExecuteChoreography {
  sequence_name: string;
}

export interface IRosTypeBosdynAutowalkApiMsgsActionOneOfAction {
  sleep: IRosTypeBosdynAutowalkApiMsgsActionSleep;
  data_acquisition: IRosTypeBosdynAutowalkApiMsgsActionDataAcquisition;
  remote_grpc: IRosTypeBosdynAutowalkApiMsgsActionRemoteGrpc;
  execute_choreography: IRosTypeBosdynAutowalkApiMsgsActionExecuteChoreography;
  node: IRosTypeBosdynMissionApiMsgsNode;
  action_choice: number;
  which: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsActionOneOfActionConst {
  ACTION_NOT_SET = 0,
  ACTION_SLEEP_SET = 1,
  ACTION_DATA_ACQUISITION_SET = 2,
  ACTION_REMOTE_GRPC_SET = 3,
  ACTION_EXECUTE_CHOREOGRAPHY_SET = 4,
  ACTION_NODE_SET = 5,
}

export interface IRosTypeBosdynAutowalkApiMsgsActionRemoteGrpc {
  service_name: string;
  rpc_timeout: { sec: number; nanosec: number };
  lease_resources: string[];
  inputs: IRosTypeBosdynMissionApiMsgsKeyValue[];
  parameters: IRosTypeBosdynApiMsgsCustomParamCollection;
  record_time_images: IRosTypeBosdynApiMsgsImageCaptureAndSource[];
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsActionRemoteGrpcConst {
  RPC_TIMEOUT_FIELD_SET = 2,
  PARAMETERS_FIELD_SET = 16,
}

export interface IRosTypeBosdynAutowalkApiMsgsActionSleep {
  duration: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsActionSleepConst {
  DURATION_FIELD_SET = 1,
}

export interface IRosTypeBosdynAutowalkApiMsgsActionWrapper {
  robot_body_pose: IRosTypeBosdynAutowalkApiMsgsActionWrapperRobotBodyPose;
  spot_cam_led: IRosTypeBosdynAutowalkApiMsgsActionWrapperSpotCamLed;
  spot_cam_ptz: IRosTypeBosdynAutowalkApiMsgsActionWrapperSpotCamPtz;
  arm_sensor_pointing: IRosTypeBosdynAutowalkApiMsgsActionWrapperArmSensorPointing;
  spot_cam_alignment: IRosTypeBosdynAutowalkApiMsgsActionWrapperSpotCamAlignment;
  gripper_camera_params: IRosTypeBosdynAutowalkApiMsgsActionWrapperGripperCameraParams;
  gripper_command: IRosTypeBosdynAutowalkApiMsgsActionWrapperGripperCommand;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsActionWrapperConst {
  ROBOT_BODY_SIT_FIELD_SET = 1,
  ROBOT_BODY_POSE_FIELD_SET = 2,
  SPOT_CAM_LED_FIELD_SET = 4,
  SPOT_CAM_PTZ_FIELD_SET = 8,
  ARM_SENSOR_POINTING_FIELD_SET = 16,
  SPOT_CAM_ALIGNMENT_FIELD_SET = 32,
  GRIPPER_CAMERA_PARAMS_FIELD_SET = 64,
  GRIPPER_COMMAND_FIELD_SET = 128,
}

export interface IRosTypeBosdynAutowalkApiMsgsActionWrapperArmSensorPointing {
  joint_trajectory: IRosTypeBosdynApiMsgsArmJointTrajectory;
  wrist_tform_tool: IRosTypeGeometryMsgsPose;
  pose_trajectory_rt_target: IRosTypeBosdynApiMsgsSe3Trajectory;
  target_tform_measured_offset: IRosTypeBosdynApiMsgsSe2Pose;
  body_assist_params: IRosTypeBosdynSpotApiMsgsBodyControlParamsBodyAssistForManipulation;
  force_stow_override: boolean;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsActionWrapperArmSensorPointingConst {
  JOINT_TRAJECTORY_FIELD_SET = 1,
  WRIST_TFORM_TOOL_FIELD_SET = 2,
  POSE_TRAJECTORY_RT_TARGET_FIELD_SET = 4,
  TARGET_TFORM_MEASURED_OFFSET_FIELD_SET = 8,
  BODY_ASSIST_PARAMS_FIELD_SET = 16,
}

export interface IRosTypeBosdynAutowalkApiMsgsActionWrapperGripperCameraParams {
  params: IRosTypeBosdynApiMsgsGripperCameraParams;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsActionWrapperGripperCameraParamsConst {
  PARAMS_FIELD_SET = 1,
}

export interface IRosTypeBosdynAutowalkApiMsgsActionWrapperGripperCommand {
  request: IRosTypeBosdynApiMsgsGripperCommandRequest;
  disable_post_action_close: boolean;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsActionWrapperGripperCommandConst {
  REQUEST_FIELD_SET = 1,
}

export interface IRosTypeBosdynAutowalkApiMsgsActionWrapperRobotBodyPose {
  target_tform_body: IRosTypeGeometryMsgsPose;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsActionWrapperRobotBodyPoseConst {
  TARGET_TFORM_BODY_FIELD_SET = 1,
}

export interface IRosTypeBosdynAutowalkApiMsgsActionWrapperSpotCamAlignment {
  alignments: IRosTypeBosdynAutowalkApiMsgsActionWrapperSpotCamAlignmentAlignment[];
  target_tform_sensor: IRosTypeGeometryMsgsPose;
  final_zoom: number;
  target_sensor_ids: string[];
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsActionWrapperSpotCamAlignmentConst {
  TARGET_TFORM_SENSOR_FIELD_SET = 2,
}

export interface IRosTypeBosdynAutowalkApiMsgsActionWrapperSpotCamAlignmentAlignment {
  zoom: number;
  sensor_id: string;
  is_skipped: boolean;
  focus_state: IRosTypeBosdynSpotCamApiMsgsPtzFocusState;
  reference: IRosTypeBosdynAutowalkApiMsgsActionWrapperSpotCamAlignmentAlignmentOneOfReference;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsActionWrapperSpotCamAlignmentAlignmentConst {
  FOCUS_STATE_FIELD_SET = 16,
}

export interface IRosTypeBosdynAutowalkApiMsgsActionWrapperSpotCamAlignmentAlignmentOneOfReference {
  scene_object_id: string;
  reference_choice: number;
  which: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsActionWrapperSpotCamAlignmentAlignmentOneOfReferenceConst {
  REFERENCE_NOT_SET = 0,
  REFERENCE_SCENE_OBJECT_ID_SET = 1,
}

export interface IRosTypeBosdynAutowalkApiMsgsActionWrapperSpotCamLed {
  brightnesses: IRosTypeBosdynAutowalkApiMsgsActionWrapperSpotCamLedBrightnessesEntry[];
}

export interface IRosTypeBosdynAutowalkApiMsgsActionWrapperSpotCamLedBrightnessesEntry {
  key: number;
  value: number;
}

export interface IRosTypeBosdynAutowalkApiMsgsActionWrapperSpotCamPtz {
  ptz_position: IRosTypeBosdynSpotCamApiMsgsPtzPosition;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsActionWrapperSpotCamPtzConst {
  PTZ_POSITION_FIELD_SET = 1,
}

export interface IRosTypeBosdynAutowalkApiMsgsBatteryMonitor {
  battery_start_threshold: number;
  battery_stop_threshold: number;
}

export interface IRosTypeBosdynAutowalkApiMsgsChoreographyItems {
  choreography_sequences: IRosTypeBosdynSpotApiMsgsChoreographySequence[];
  animated_moves: IRosTypeBosdynSpotApiMsgsAnimation[];
}

export interface IRosTypeBosdynAutowalkApiMsgsCompileAutowalkRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  walk: IRosTypeBosdynAutowalkApiMsgsWalk;
  treat_warnings_as_errors: boolean;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsCompileAutowalkRequestConst {
  HEADER_FIELD_SET = 1,
  WALK_FIELD_SET = 2,
}

export interface IRosTypeBosdynAutowalkApiMsgsCompileAutowalkResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynAutowalkApiMsgsCompileAutowalkResponseStatus;
  root: IRosTypeBosdynMissionApiMsgsNode;
  element_identifiers: IRosTypeBosdynAutowalkApiMsgsElementIdentifiers[];
  failed_elements: IRosTypeBosdynAutowalkApiMsgsCompileAutowalkResponseFailedElementsEntry[];
  docking_node: IRosTypeBosdynAutowalkApiMsgsNodeIdentifier;
  loop_node: IRosTypeBosdynAutowalkApiMsgsNodeIdentifier;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsCompileAutowalkResponseConst {
  HEADER_FIELD_SET = 1,
  ROOT_FIELD_SET = 4,
  DOCKING_NODE_FIELD_SET = 32,
  LOOP_NODE_FIELD_SET = 64,
}

export interface IRosTypeBosdynAutowalkApiMsgsCompileAutowalkResponseFailedElementsEntry {
  key: number;
  value: IRosTypeBosdynAutowalkApiMsgsFailedElement;
}

export interface IRosTypeBosdynAutowalkApiMsgsCompileAutowalkResponseStatus {
  value: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsCompileAutowalkResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_COMPILE_ERROR = 2,
}

export interface IRosTypeBosdynAutowalkApiMsgsDock {
  dock_id: number;
  docked_waypoint_id: string;
  target_prep_pose: IRosTypeBosdynAutowalkApiMsgsTarget;
  prompt_duration: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsDockConst {
  TARGET_PREP_POSE_FIELD_SET = 4,
  PROMPT_DURATION_FIELD_SET = 8,
}

export interface IRosTypeBosdynAutowalkApiMsgsElement {
  name: string;
  target: IRosTypeBosdynAutowalkApiMsgsTarget;
  target_failure_behavior: IRosTypeBosdynAutowalkApiMsgsFailureBehavior;
  action: IRosTypeBosdynAutowalkApiMsgsAction;
  action_wrapper: IRosTypeBosdynAutowalkApiMsgsActionWrapper;
  action_failure_behavior: IRosTypeBosdynAutowalkApiMsgsFailureBehavior;
  is_skipped: boolean;
  battery_monitor: IRosTypeBosdynAutowalkApiMsgsBatteryMonitor;
  action_duration: { sec: number; nanosec: number };
  id: string;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsElementConst {
  TARGET_FIELD_SET = 2,
  TARGET_FAILURE_BEHAVIOR_FIELD_SET = 4,
  ACTION_FIELD_SET = 8,
  ACTION_WRAPPER_FIELD_SET = 16,
  ACTION_FAILURE_BEHAVIOR_FIELD_SET = 32,
  BATTERY_MONITOR_FIELD_SET = 128,
  ACTION_DURATION_FIELD_SET = 256,
}

export interface IRosTypeBosdynAutowalkApiMsgsElementIdentifiers {
  root_id: IRosTypeBosdynAutowalkApiMsgsNodeIdentifier;
  action_id: IRosTypeBosdynAutowalkApiMsgsNodeIdentifier;
  navigation_id: IRosTypeBosdynAutowalkApiMsgsNodeIdentifier;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsElementIdentifiersConst {
  ROOT_ID_FIELD_SET = 1,
  ACTION_ID_FIELD_SET = 2,
  NAVIGATION_ID_FIELD_SET = 4,
}

export interface IRosTypeBosdynAutowalkApiMsgsFailedElement {
  errors: string[];
  warnings: string[];
}

export interface IRosTypeBosdynAutowalkApiMsgsFailureBehavior {
  retry_count: number;
  prompt_duration: { sec: number; nanosec: number };
  default_behavior: IRosTypeBosdynAutowalkApiMsgsFailureBehaviorOneOfDefaultBehavior;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsFailureBehaviorConst {
  PROMPT_DURATION_FIELD_SET = 2,
}

export interface IRosTypeBosdynAutowalkApiMsgsFailureBehaviorOneOfDefaultBehavior {
  safe_power_off: IRosTypeBosdynAutowalkApiMsgsFailureBehaviorSafePowerOff;
  return_to_start_and_try_again_later: IRosTypeBosdynAutowalkApiMsgsFailureBehaviorReturnToStartAndTryAgainLater;
  default_behavior_choice: number;
  which: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsFailureBehaviorOneOfDefaultBehaviorConst {
  DEFAULT_BEHAVIOR_NOT_SET = 0,
  DEFAULT_BEHAVIOR_SAFE_POWER_OFF_SET = 1,
  DEFAULT_BEHAVIOR_PROCEED_IF_ABLE_SET = 2,
  DEFAULT_BEHAVIOR_RETURN_TO_START_AND_TRY_AGAIN_LATER_SET = 3,
  DEFAULT_BEHAVIOR_RETURN_TO_START_AND_TERMINATE_SET = 4,
}

export interface IRosTypeBosdynAutowalkApiMsgsFailureBehaviorReturnToStartAndTryAgainLater {
  try_again_delay: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsFailureBehaviorReturnToStartAndTryAgainLaterConst {
  TRY_AGAIN_DELAY_FIELD_SET = 1,
}

export interface IRosTypeBosdynAutowalkApiMsgsFailureBehaviorSafePowerOff {
  request: IRosTypeBosdynApiMsgsSafePowerOffCommandRequest;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsFailureBehaviorSafePowerOffConst {
  REQUEST_FIELD_SET = 1,
}

export interface IRosTypeBosdynAutowalkApiMsgsGlobalParameters {
  group_name: string;
  should_autofocus_ptz: boolean;
  self_right_attempts: number;
  post_mission_callbacks: IRosTypeBosdynAutowalkApiMsgsActionRemoteGrpc[];
  skip_actions: boolean;
}

export interface IRosTypeBosdynAutowalkApiMsgsLoadAutowalkRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  walk: IRosTypeBosdynAutowalkApiMsgsWalk;
  leases: IRosTypeBosdynApiMsgsLease[];
  treat_warnings_as_errors: boolean;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsLoadAutowalkRequestConst {
  HEADER_FIELD_SET = 1,
  WALK_FIELD_SET = 2,
}

export interface IRosTypeBosdynAutowalkApiMsgsLoadAutowalkResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynAutowalkApiMsgsLoadAutowalkResponseStatus;
  lease_use_results: IRosTypeBosdynApiMsgsLeaseUseResult[];
  failed_nodes: IRosTypeBosdynMissionApiMsgsFailedNode[];
  element_identifiers: IRosTypeBosdynAutowalkApiMsgsElementIdentifiers[];
  failed_elements: IRosTypeBosdynAutowalkApiMsgsLoadAutowalkResponseFailedElementsEntry[];
  mission_id: number;
  docking_node: IRosTypeBosdynAutowalkApiMsgsNodeIdentifier;
  loop_node: IRosTypeBosdynAutowalkApiMsgsNodeIdentifier;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsLoadAutowalkResponseConst {
  HEADER_FIELD_SET = 1,
  DOCKING_NODE_FIELD_SET = 128,
  LOOP_NODE_FIELD_SET = 256,
}

export interface IRosTypeBosdynAutowalkApiMsgsLoadAutowalkResponseFailedElementsEntry {
  key: number;
  value: IRosTypeBosdynAutowalkApiMsgsFailedElement;
}

export interface IRosTypeBosdynAutowalkApiMsgsLoadAutowalkResponseStatus {
  value: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsLoadAutowalkResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_COMPILE_ERROR = 2,
  STATUS_VALIDATE_ERROR = 3,
}

export interface IRosTypeBosdynAutowalkApiMsgsNodeIdentifier {
  node_id: number;
  user_data_id: string;
}

export interface IRosTypeBosdynAutowalkApiMsgsPlaybackMode {
  mode: IRosTypeBosdynAutowalkApiMsgsPlaybackModeOneOfMode;
}

export interface IRosTypeBosdynAutowalkApiMsgsPlaybackModeOnce {
  skip_docking_after_completion: boolean;
}

export interface IRosTypeBosdynAutowalkApiMsgsPlaybackModeOneOfMode {
  once: IRosTypeBosdynAutowalkApiMsgsPlaybackModeOnce;
  periodic: IRosTypeBosdynAutowalkApiMsgsPlaybackModePeriodic;
  mode_choice: number;
  which: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsPlaybackModeOneOfModeConst {
  MODE_NOT_SET = 0,
  MODE_ONCE_SET = 1,
  MODE_PERIODIC_SET = 2,
  MODE_CONTINUOUS_SET = 3,
}

export interface IRosTypeBosdynAutowalkApiMsgsPlaybackModePeriodic {
  interval: { sec: number; nanosec: number };
  repetitions: number;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsPlaybackModePeriodicConst {
  INTERVAL_FIELD_SET = 1,
}

export interface IRosTypeBosdynAutowalkApiMsgsTarget {
  relocalize: IRosTypeBosdynAutowalkApiMsgsTargetRelocalize;
  target_stow_behavior: IRosTypeBosdynAutowalkApiMsgsTargetTargetStowBehavior;
  target: IRosTypeBosdynAutowalkApiMsgsTargetOneOfTarget;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsTargetConst {
  RELOCALIZE_FIELD_SET = 4,
}

export interface IRosTypeBosdynAutowalkApiMsgsTargetNavigateRoute {
  route: IRosTypeBosdynGraphNavApiMsgsRoute;
  travel_params: IRosTypeBosdynGraphNavApiMsgsTravelParams;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsTargetNavigateRouteConst {
  ROUTE_FIELD_SET = 1,
  TRAVEL_PARAMS_FIELD_SET = 2,
}

export interface IRosTypeBosdynAutowalkApiMsgsTargetNavigateTo {
  destination_waypoint_id: string;
  travel_params: IRosTypeBosdynGraphNavApiMsgsTravelParams;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsTargetNavigateToConst {
  TRAVEL_PARAMS_FIELD_SET = 2,
}

export interface IRosTypeBosdynAutowalkApiMsgsTargetOneOfTarget {
  navigate_to: IRosTypeBosdynAutowalkApiMsgsTargetNavigateTo;
  navigate_route: IRosTypeBosdynAutowalkApiMsgsTargetNavigateRoute;
  target_choice: number;
  which: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsTargetOneOfTargetConst {
  TARGET_NOT_SET = 0,
  TARGET_NAVIGATE_TO_SET = 1,
  TARGET_NAVIGATE_ROUTE_SET = 2,
}

export interface IRosTypeBosdynAutowalkApiMsgsTargetRelocalize {
  set_localization_request: IRosTypeBosdynGraphNavApiMsgsSetLocalizationRequest;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsTargetRelocalizeConst {
  SET_LOCALIZATION_REQUEST_FIELD_SET = 1,
}

export interface IRosTypeBosdynAutowalkApiMsgsTargetTargetStowBehavior {
  value: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsTargetTargetStowBehaviorConst {
  TARGET_STOW_BEHAVIOR_UNKNOWN = 0,
  TARGET_STOW_BEHAVIOR_AUTO = 1,
  TARGET_STOW_BEHAVIOR_NEVER = 2,
  TARGET_STOW_BEHAVIOR_ALWAYS = 3,
}

export interface IRosTypeBosdynAutowalkApiMsgsWalk {
  global_parameters: IRosTypeBosdynAutowalkApiMsgsGlobalParameters;
  playback_mode: IRosTypeBosdynAutowalkApiMsgsPlaybackMode;
  map_name: string;
  mission_name: string;
  elements: IRosTypeBosdynAutowalkApiMsgsElement[];
  docks: IRosTypeBosdynAutowalkApiMsgsDock[];
  id: string;
  choreography_items: IRosTypeBosdynAutowalkApiMsgsChoreographyItems;
  has_field: number;
}

export enum IRosTypeBosdynAutowalkApiMsgsWalkConst {
  GLOBAL_PARAMETERS_FIELD_SET = 1,
  PLAYBACK_MODE_FIELD_SET = 2,
  CHOREOGRAPHY_ITEMS_FIELD_SET = 128,
}

export interface IRosTypeBosdynGraphNavApiMsgsAnchor {
  id: string;
  seed_tform_waypoint: IRosTypeGeometryMsgsPose;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsAnchorConst {
  SEED_TFORM_WAYPOINT_FIELD_SET = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsAnchorHintUncertainty {
  uncertainty: IRosTypeBosdynGraphNavApiMsgsAnchorHintUncertaintyOneOfUncertainty;
}

export interface IRosTypeBosdynGraphNavApiMsgsAnchorHintUncertaintyOneOfUncertainty {
  se3_covariance: IRosTypeBosdynApiMsgsSe3Covariance;
  confidence_bounds: IRosTypeBosdynGraphNavApiMsgsPoseBounds;
  uncertainty_choice: number;
  which: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsAnchorHintUncertaintyOneOfUncertaintyConst {
  UNCERTAINTY_NOT_SET = 0,
  UNCERTAINTY_SE3_COVARIANCE_SET = 1,
  UNCERTAINTY_CONFIDENCE_BOUNDS_SET = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsAnchoredWorldObject {
  id: string;
  seed_tform_object: IRosTypeGeometryMsgsPose;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsAnchoredWorldObjectConst {
  SEED_TFORM_OBJECT_FIELD_SET = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsAnchoring {
  anchors: IRosTypeBosdynGraphNavApiMsgsAnchor[];
  objects: IRosTypeBosdynGraphNavApiMsgsAnchoredWorldObject[];
}

export interface IRosTypeBosdynGraphNavApiMsgsAnchoringHint {
  waypoint_anchors: IRosTypeBosdynGraphNavApiMsgsWaypointAnchorHint[];
  world_objects: IRosTypeBosdynGraphNavApiMsgsWorldObjectAnchorHint[];
}

export interface IRosTypeBosdynGraphNavApiMsgsAnnotationState {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsAnnotationStateConst {
  ANNOTATION_STATE_UNKNOWN = 0,
  ANNOTATION_STATE_SET = 1,
  ANNOTATION_STATE_NONE = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsAreaCallbackData {
  config_data: IRosTypeProto2RosAnyProto;
  custom_params: IRosTypeBosdynApiMsgsDictParam;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsAreaCallbackDataConst {
  CONFIG_DATA_FIELD_SET = 1,
  CUSTOM_PARAMS_FIELD_SET = 2,
  MAP_CONFIG_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsAreaCallbackError {
  service_name: string;
  error: IRosTypeBosdynGraphNavApiMsgsAreaCallbackErrorCallError;
  response_error: IRosTypeBosdynGraphNavApiMsgsAreaCallbackErrorOneOfResponseError;
}

export interface IRosTypeBosdynGraphNavApiMsgsAreaCallbackErrorCallError {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsAreaCallbackErrorCallErrorConst {
  ERROR_UNKNOWN = 0,
  ERROR_TRANSPORT = 1,
  ERROR_RESPONSE = 2,
  ERROR_SERVICE = 3,
}

export interface IRosTypeBosdynGraphNavApiMsgsAreaCallbackErrorOneOfResponseError {
  begin_callback: IRosTypeBosdynGraphNavApiMsgsBeginCallbackResponse;
  begin_control: IRosTypeBosdynGraphNavApiMsgsBeginControlResponse;
  update_callback: IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponse;
  end_callback: IRosTypeBosdynGraphNavApiMsgsEndCallbackResponse;
  response_error_choice: number;
  which: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsAreaCallbackErrorOneOfResponseErrorConst {
  RESPONSE_ERROR_NOT_SET = 0,
  RESPONSE_ERROR_BEGIN_CALLBACK_SET = 1,
  RESPONSE_ERROR_BEGIN_CONTROL_SET = 2,
  RESPONSE_ERROR_UPDATE_CALLBACK_SET = 3,
  RESPONSE_ERROR_END_CALLBACK_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsAreaCallbackInformation {
  required_lease_resources: string[];
  custom_params: IRosTypeBosdynApiMsgsDictParamSpec;
  blockage: IRosTypeBosdynGraphNavApiMsgsAreaCallbackInformationBlockage;
  impairment_check: IRosTypeBosdynGraphNavApiMsgsAreaCallbackInformationImpairment;
  entity_waiting: IRosTypeBosdynGraphNavApiMsgsAreaCallbackInformationEntityWaiting;
  default_stop: IRosTypeBosdynGraphNavApiMsgsStopConfiguration;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsAreaCallbackInformationConst {
  CUSTOM_PARAMS_FIELD_SET = 2,
  DEFAULT_STOP_FIELD_SET = 32,
  MAP_CONFIG_FIELD_SET = 64,
}

export interface IRosTypeBosdynGraphNavApiMsgsAreaCallbackInformationBlockage {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsAreaCallbackInformationBlockageConst {
  BLOCKAGE_UNKNOWN = 0,
  BLOCKAGE_SKIP = 1,
  BLOCKAGE_CHECK = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsAreaCallbackInformationEntityWaiting {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsAreaCallbackInformationEntityWaitingConst {
  ENTITY_WAITING_UNKNOWN = 0,
  ENTITY_WAITING_DISABLE = 1,
  ENTITY_WAITING_ENABLE = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsAreaCallbackInformationImpairment {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsAreaCallbackInformationImpairmentConst {
  IMPAIRMENT_UNKNOWN = 0,
  IMPAIRMENT_SKIP = 1,
  IMPAIRMENT_CHECK = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsAreaCallbackInformationRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsAreaCallbackInformationRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsAreaCallbackInformationResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  info: IRosTypeBosdynGraphNavApiMsgsAreaCallbackInformation;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsAreaCallbackInformationResponseConst {
  HEADER_FIELD_SET = 1,
  INFO_FIELD_SET = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsAreaCallbackRegion {
  service_name: string;
  description: string;
  recorded_data: IRosTypeBosdynGraphNavApiMsgsAreaCallbackData;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsAreaCallbackRegionConst {
  RECORDED_DATA_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsAreaCallbackServiceError {
  missing_services: string[];
  faulted_services: IRosTypeBosdynApiMsgsServiceFault[];
}

export interface IRosTypeBosdynGraphNavApiMsgsBeginCallbackRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  region_info: IRosTypeBosdynGraphNavApiMsgsRegionInformation;
  end_time: { sec: number; nanosec: number };
  recorded_data: IRosTypeBosdynGraphNavApiMsgsAreaCallbackData;
  custom_params: IRosTypeBosdynApiMsgsDictParam;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsBeginCallbackRequestConst {
  HEADER_FIELD_SET = 1,
  REGION_INFO_FIELD_SET = 2,
  END_TIME_FIELD_SET = 4,
  RECORDED_DATA_FIELD_SET = 8,
  CUSTOM_PARAMS_FIELD_SET = 16,
}

export interface IRosTypeBosdynGraphNavApiMsgsBeginCallbackResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynGraphNavApiMsgsBeginCallbackResponseStatus;
  command_id: number;
  custom_param_error: IRosTypeBosdynApiMsgsCustomParamError;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsBeginCallbackResponseConst {
  HEADER_FIELD_SET = 1,
  CUSTOM_PARAM_ERROR_FIELD_SET = 8,
}

export interface IRosTypeBosdynGraphNavApiMsgsBeginCallbackResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsBeginCallbackResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_CONFIGURATION = 2,
  STATUS_EXPIRED_END_TIME = 3,
  STATUS_CUSTOM_PARAMS_ERROR = 8,
}

export interface IRosTypeBosdynGraphNavApiMsgsBeginControlRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  leases: IRosTypeBosdynApiMsgsLease[];
  command_id: number;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsBeginControlRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsBeginControlResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_results: IRosTypeBosdynApiMsgsLeaseUseResult[];
  status: IRosTypeBosdynGraphNavApiMsgsBeginControlResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsBeginControlResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsBeginControlResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsBeginControlResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_COMMAND_ID = 2,
  STATUS_MISSING_LEASE_RESOURCES = 3,
  STATUS_LEASE_ERROR = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsClearGraphRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsClearGraphRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsClearGraphResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  status: IRosTypeBosdynGraphNavApiMsgsClearGraphResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsClearGraphResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsClearGraphResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsClearGraphResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_RECORDING = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsClientMetadata {
  session_name: string;
  client_username: string;
  client_software_version: string;
  client_id: string;
  client_type: string;
}

export interface IRosTypeBosdynGraphNavApiMsgsCompletedRoute {
  waypoint_ids: string[];
  edges: IRosTypeBosdynGraphNavApiMsgsCompletedRouteCompletedEdge[];
}

export interface IRosTypeBosdynGraphNavApiMsgsCompletedRouteCompletedEdge {
  edge_id: IRosTypeBosdynGraphNavApiMsgsEdgeId;
  not_in_map: boolean;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsCompletedRouteCompletedEdgeConst {
  EDGE_ID_FIELD_SET = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsCreateEdgeRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  edge: IRosTypeBosdynGraphNavApiMsgsEdge;
  lease: IRosTypeBosdynApiMsgsLease;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsCreateEdgeRequestConst {
  HEADER_FIELD_SET = 1,
  EDGE_FIELD_SET = 2,
  LEASE_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsCreateEdgeResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynGraphNavApiMsgsCreateEdgeResponseStatus;
  error_existing_edge: IRosTypeBosdynGraphNavApiMsgsEdge;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  map_stats: IRosTypeBosdynGraphNavApiMsgsMapStats;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsCreateEdgeResponseConst {
  HEADER_FIELD_SET = 1,
  ERROR_EXISTING_EDGE_FIELD_SET = 4,
  LEASE_USE_RESULT_FIELD_SET = 8,
  MAP_STATS_FIELD_SET = 16,
}

export interface IRosTypeBosdynGraphNavApiMsgsCreateEdgeResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsCreateEdgeResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_EXISTS = 2,
  STATUS_NOT_RECORDING = 3,
  STATUS_UNKNOWN_WAYPOINT = 4,
  STATUS_MISSING_TRANSFORM = 5,
}

export interface IRosTypeBosdynGraphNavApiMsgsCreateWaypointRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  waypoint_name: string;
  recording_environment: IRosTypeBosdynGraphNavApiMsgsRecordingEnvironment;
  lease: IRosTypeBosdynApiMsgsLease;
  require_fiducials: number[];
  world_objects: IRosTypeBosdynApiMsgsWorldObject[];
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsCreateWaypointRequestConst {
  HEADER_FIELD_SET = 1,
  RECORDING_ENVIRONMENT_FIELD_SET = 4,
  LEASE_FIELD_SET = 8,
}

export interface IRosTypeBosdynGraphNavApiMsgsCreateWaypointResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  created_waypoint: IRosTypeBosdynGraphNavApiMsgsWaypoint;
  created_edge: IRosTypeBosdynGraphNavApiMsgsEdge;
  status: IRosTypeBosdynGraphNavApiMsgsCreateWaypointResponseStatus;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  missing_fiducials: number[];
  bad_pose_fiducials: number[];
  license_status: IRosTypeBosdynApiMsgsLicenseInfoStatus;
  map_stats: IRosTypeBosdynGraphNavApiMsgsMapStats;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsCreateWaypointResponseConst {
  HEADER_FIELD_SET = 1,
  CREATED_WAYPOINT_FIELD_SET = 2,
  CREATED_EDGE_FIELD_SET = 4,
  LEASE_USE_RESULT_FIELD_SET = 16,
  MAP_STATS_FIELD_SET = 256,
}

export interface IRosTypeBosdynGraphNavApiMsgsCreateWaypointResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsCreateWaypointResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NOT_RECORDING = 2,
  STATUS_COULD_NOT_CREATE_WAYPOINT = 3,
  STATUS_MISSING_FIDUCIALS = 4,
  STATUS_MAP_TOO_LARGE_LICENSE = 5,
  STATUS_REMOTE_CLOUD_FAILURE_NOT_IN_DIRECTORY = 6,
  STATUS_REMOTE_CLOUD_FAILURE_NO_DATA = 7,
  STATUS_FIDUCIAL_POSE_NOT_OK = 8,
}

export interface IRosTypeBosdynGraphNavApiMsgsDownloadEdgeSnapshotRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  edge_snapshot_id: string;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsDownloadEdgeSnapshotRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsDownloadEdgeSnapshotResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynGraphNavApiMsgsDownloadEdgeSnapshotResponseStatus;
  edge_snapshot_id: string;
  chunk: IRosTypeBosdynApiMsgsDataChunk;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsDownloadEdgeSnapshotResponseConst {
  HEADER_FIELD_SET = 1,
  CHUNK_FIELD_SET = 8,
}

export interface IRosTypeBosdynGraphNavApiMsgsDownloadEdgeSnapshotResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsDownloadEdgeSnapshotResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_SNAPSHOT_DOES_NOT_EXIST = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsDownloadGraphRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsDownloadGraphRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsDownloadGraphResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  graph: IRosTypeBosdynGraphNavApiMsgsGraph;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsDownloadGraphResponseConst {
  HEADER_FIELD_SET = 1,
  GRAPH_FIELD_SET = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsDownloadWaypointSnapshotRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  waypoint_snapshot_id: string;
  download_images: boolean;
  compress_point_cloud: boolean;
  do_not_download_point_cloud: boolean;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsDownloadWaypointSnapshotRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsDownloadWaypointSnapshotResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynGraphNavApiMsgsDownloadWaypointSnapshotResponseStatus;
  waypoint_snapshot_id: string;
  chunk: IRosTypeBosdynApiMsgsDataChunk;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsDownloadWaypointSnapshotResponseConst {
  HEADER_FIELD_SET = 1,
  CHUNK_FIELD_SET = 8,
}

export interface IRosTypeBosdynGraphNavApiMsgsDownloadWaypointSnapshotResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsDownloadWaypointSnapshotResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_SNAPSHOT_DOES_NOT_EXIST = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsEdge {
  id: IRosTypeBosdynGraphNavApiMsgsEdgeId;
  snapshot_id: string;
  from_tform_to: IRosTypeGeometryMsgsPose;
  annotations: IRosTypeBosdynGraphNavApiMsgsEdgeAnnotations;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsEdgeConst {
  ID_FIELD_SET = 1,
  FROM_TFORM_TO_FIELD_SET = 4,
  ANNOTATIONS_FIELD_SET = 8,
}

export interface IRosTypeBosdynGraphNavApiMsgsEdgeAnnotations {
  stairs: IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsStairData;
  direction_constraint: IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsDirectionConstraint;
  require_alignment: IRosTypeStdMsgsBool;
  flat_ground: IRosTypeStdMsgsBool;
  override_mobility_params: IRosTypeProto2RosAnyProto;
  mobility_params: IRosTypeBosdynSpotApiMsgsMobilityParams;
  cost: IRosTypeStdMsgsFloat64;
  edge_source: IRosTypeBosdynGraphNavApiMsgsEdgeEdgeSource;
  disable_alternate_route_finding: boolean;
  path_following_mode: IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsPathFollowingMode;
  disable_directed_exploration: boolean;
  area_callbacks: IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsAreaCallbacksEntry[];
  ground_clutter_mode: IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsGroundClutterAvoidanceMode;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsConst {
  STAIRS_FIELD_SET = 1,
  REQUIRE_ALIGNMENT_FIELD_SET = 4,
  FLAT_GROUND_FIELD_SET = 8,
  OVERRIDE_MOBILITY_PARAMS_FIELD_SET = 16,
  MOBILITY_PARAMS_FIELD_SET = 32,
  COST_FIELD_SET = 64,
}

export interface IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsAreaCallbacksEntry {
  key: string;
  value: IRosTypeBosdynGraphNavApiMsgsAreaCallbackRegion;
}

export interface IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsDirectionConstraint {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsDirectionConstraintConst {
  DIRECTION_CONSTRAINT_UNKNOWN = 0,
  DIRECTION_CONSTRAINT_NO_TURN = 1,
  DIRECTION_CONSTRAINT_FORWARD = 2,
  DIRECTION_CONSTRAINT_REVERSE = 3,
  DIRECTION_CONSTRAINT_NONE = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsGroundClutterAvoidanceMode {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsGroundClutterAvoidanceModeConst {
  GROUND_CLUTTER_UNKNOWN = 0,
  GROUND_CLUTTER_OFF = 1,
  GROUND_CLUTTER_FROM_FOOTFALLS = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsPathFollowingMode {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsPathFollowingModeConst {
  PATH_MODE_UNKNOWN = 0,
  PATH_MODE_DEFAULT = 1,
  PATH_MODE_STRICT = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsStairData {
  state: IRosTypeBosdynGraphNavApiMsgsAnnotationState;
  straight_staircase: IRosTypeBosdynApiMsgsStraightStaircase;
  staircase_with_landings: IRosTypeBosdynApiMsgsStaircaseWithLandings;
  descent_preference: IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsStairDataDescentPreference;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsStairDataConst {
  STRAIGHT_STAIRCASE_FIELD_SET = 2,
  STAIRCASE_WITH_LANDINGS_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsStairDataDescentPreference {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsStairDataDescentPreferenceConst {
  DESCENT_PREFERENCE_UNKNOWN = 0,
  DESCENT_PREFERENCE_PREFER_REVERSE = 1,
  DESCENT_PREFERENCE_ALWAYS_REVERSE = 2,
  DESCENT_PREFERENCE_NONE = 3,
}

export interface IRosTypeBosdynGraphNavApiMsgsEdgeEdgeSource {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsEdgeEdgeSourceConst {
  EDGE_SOURCE_UNKNOWN = 0,
  EDGE_SOURCE_ODOMETRY = 1,
  EDGE_SOURCE_SMALL_LOOP_CLOSURE = 2,
  EDGE_SOURCE_FIDUCIAL_LOOP_CLOSURE = 3,
  EDGE_SOURCE_ALTERNATE_ROUTE_FINDING = 4,
  EDGE_SOURCE_USER_REQUEST = 5,
  EDGE_SOURCE_LOCALIZATION = 6,
}

export interface IRosTypeBosdynGraphNavApiMsgsEdgeId {
  from_waypoint: string;
  to_waypoint: string;
}

export interface IRosTypeBosdynGraphNavApiMsgsEdgeSnapshot {
  id: string;
  stances: IRosTypeBosdynGraphNavApiMsgsEdgeSnapshotStance[];
  area_callbacks: IRosTypeBosdynGraphNavApiMsgsEdgeSnapshotAreaCallbacksEntry[];
}

export interface IRosTypeBosdynGraphNavApiMsgsEdgeSnapshotAreaCallbacksEntry {
  key: string;
  value: IRosTypeBosdynGraphNavApiMsgsAreaCallbackData;
}

export interface IRosTypeBosdynGraphNavApiMsgsEdgeSnapshotStance {
  timestamp: { sec: number; nanosec: number };
  foot_states: IRosTypeBosdynApiMsgsFootState[];
  ko_tform_body: IRosTypeGeometryMsgsPose;
  vision_tform_body: IRosTypeGeometryMsgsPose;
  planar_ground: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsEdgeSnapshotStanceConst {
  TIMESTAMP_FIELD_SET = 1,
  KO_TFORM_BODY_FIELD_SET = 4,
  VISION_TFORM_BODY_FIELD_SET = 8,
  PLANAR_GROUND_FIELD_SET = 16,
}

export interface IRosTypeBosdynGraphNavApiMsgsEndCallbackRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  command_id: number;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsEndCallbackRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsEndCallbackResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynGraphNavApiMsgsEndCallbackResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsEndCallbackResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsEndCallbackResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsEndCallbackResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_COMMAND_ID = 2,
  STATUS_SHUTDOWN_CALLBACK_FAILED = 3,
}

export interface IRosTypeBosdynGraphNavApiMsgsGetLocalizationStateRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  waypoint_id: string;
  request_live_point_cloud: boolean;
  request_live_images: boolean;
  request_live_terrain_maps: boolean;
  request_live_world_objects: boolean;
  request_live_robot_state: boolean;
  compress_live_point_cloud: boolean;
  request_gps_state: boolean;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsGetLocalizationStateRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsGetLocalizationStateResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  localization: IRosTypeBosdynGraphNavApiMsgsLocalization;
  robot_kinematics: IRosTypeBosdynApiMsgsKinematicState;
  remote_cloud_status: IRosTypeBosdynGraphNavApiMsgsRemotePointCloudStatus[];
  live_data: IRosTypeBosdynGraphNavApiMsgsWaypointSnapshot;
  lost_detector_state: IRosTypeBosdynGraphNavApiMsgsLostDetectorState;
  gps: IRosTypeBosdynGraphNavApiMsgsGpsLocalization;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsGetLocalizationStateResponseConst {
  HEADER_FIELD_SET = 1,
  LOCALIZATION_FIELD_SET = 2,
  ROBOT_KINEMATICS_FIELD_SET = 4,
  LIVE_DATA_FIELD_SET = 16,
  LOST_DETECTOR_STATE_FIELD_SET = 32,
  GPS_FIELD_SET = 64,
}

export interface IRosTypeBosdynGraphNavApiMsgsGetRecordStatusRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsGetRecordStatusRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsGetRecordStatusResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  is_recording: boolean;
  recording_environment: IRosTypeBosdynGraphNavApiMsgsRecordingEnvironment;
  map_state: IRosTypeBosdynGraphNavApiMsgsGetRecordStatusResponseMapState;
  status: IRosTypeBosdynGraphNavApiMsgsGetRecordStatusResponseStatus;
  impaired_state: IRosTypeBosdynApiMsgsRobotImpairedState;
  session_start_time: { sec: number; nanosec: number };
  map_stats: IRosTypeBosdynGraphNavApiMsgsMapStats;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsGetRecordStatusResponseConst {
  HEADER_FIELD_SET = 1,
  RECORDING_ENVIRONMENT_FIELD_SET = 4,
  IMPAIRED_STATE_FIELD_SET = 32,
  SESSION_START_TIME_FIELD_SET = 64,
  MAP_STATS_FIELD_SET = 128,
}

export interface IRosTypeBosdynGraphNavApiMsgsGetRecordStatusResponseMapState {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsGetRecordStatusResponseMapStateConst {
  MAP_STATE_UNKNOWN = 0,
  MAP_STATE_OK = 1,
  MAP_STATE_TOO_LARGE_FOR_LICENSE = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsGetRecordStatusResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsGetRecordStatusResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_ROBOT_IMPAIRED = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsGpsLocalization {
  live_gps_state: IRosTypeBosdynGraphNavApiMsgsGpsLocalizationState;
  map_gps_state: IRosTypeBosdynGraphNavApiMsgsGpsLocalizationState;
  ecef_tform_body: IRosTypeGeometryMsgsPose;
  latitude_longitude_height: IRosTypeBosdynApiMsgsLlh;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsGpsLocalizationConst {
  ECEF_TFORM_BODY_FIELD_SET = 4,
  LATITUDE_LONGITUDE_HEIGHT_FIELD_SET = 8,
}

export interface IRosTypeBosdynGraphNavApiMsgsGpsLocalizationState {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsGpsLocalizationStateConst {
  STATE_UNKNOWN = 0,
  STATE_OK = 1,
  STATE_BAD_FRAMES = 2,
  STATE_NO_GPS_OBJECTS = 3,
  STATE_REGISTRATION_NOT_OK = 4,
  STATE_NO_GPS_STATES = 5,
  STATE_NOT_ENOUGH_SATELLITES = 6,
  STATE_NO_ECEF_FRAME = 7,
  STATE_HIGH_ERROR = 8,
  STATE_STALE = 9,
  STATE_INTERNAL_ERROR = 10,
}

export interface IRosTypeBosdynGraphNavApiMsgsGpsNavigationParams {
  goal_llh: IRosTypeBosdynApiMsgsLlh;
  goal_yaw: IRosTypeStdMsgsFloat64;
  max_distance_from_map: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsGpsNavigationParamsConst {
  GOAL_LLH_FIELD_SET = 1,
  GOAL_YAW_FIELD_SET = 2,
  MAX_DISTANCE_FROM_MAP_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsGraph {
  waypoints: IRosTypeBosdynGraphNavApiMsgsWaypoint[];
  edges: IRosTypeBosdynGraphNavApiMsgsEdge[];
  anchoring: IRosTypeBosdynGraphNavApiMsgsAnchoring;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsGraphConst {
  ANCHORING_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsLocalization {
  waypoint_id: string;
  waypoint_tform_body: IRosTypeGeometryMsgsPose;
  seed_tform_body: IRosTypeGeometryMsgsPose;
  timestamp: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsLocalizationConst {
  WAYPOINT_TFORM_BODY_FIELD_SET = 2,
  SEED_TFORM_BODY_FIELD_SET = 4,
  TIMESTAMP_FIELD_SET = 8,
}

export interface IRosTypeBosdynGraphNavApiMsgsLostDetectorState {
  is_lost: boolean;
}

export interface IRosTypeBosdynGraphNavApiMsgsMapStats {
  waypoints: IRosTypeBosdynGraphNavApiMsgsMapStatsStat;
  waypoint_snapshots: IRosTypeBosdynGraphNavApiMsgsMapStatsStat;
  alternate_waypoints: IRosTypeBosdynGraphNavApiMsgsMapStatsStat;
  edges: IRosTypeBosdynGraphNavApiMsgsMapStatsStat;
  edge_snapshots: IRosTypeBosdynGraphNavApiMsgsMapStatsStat;
  alternate_edges: IRosTypeBosdynGraphNavApiMsgsMapStatsStat;
  waypoint_anchors: IRosTypeBosdynGraphNavApiMsgsMapStatsStat;
  object_anchors: IRosTypeBosdynGraphNavApiMsgsMapStatsStat;
  total_path_length: number;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsMapStatsConst {
  WAYPOINTS_FIELD_SET = 1,
  WAYPOINT_SNAPSHOTS_FIELD_SET = 2,
  ALTERNATE_WAYPOINTS_FIELD_SET = 4,
  EDGES_FIELD_SET = 8,
  EDGE_SNAPSHOTS_FIELD_SET = 16,
  ALTERNATE_EDGES_FIELD_SET = 32,
  WAYPOINT_ANCHORS_FIELD_SET = 64,
  OBJECT_ANCHORS_FIELD_SET = 128,
}

export interface IRosTypeBosdynGraphNavApiMsgsMapStatsStat {
  count: number;
  num_bytes: number;
}

export interface IRosTypeBosdynGraphNavApiMsgsModifyNavigationResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_results: IRosTypeBosdynApiMsgsLeaseUseResult[];
  status: IRosTypeBosdynGraphNavApiMsgsModifyNavigationResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsModifyNavigationResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsModifyNavigationResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsModifyNavigationResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_UNRECOGNIZED_COMMAND = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigateRouteRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  leases: IRosTypeBosdynApiMsgsLease[];
  route: IRosTypeBosdynGraphNavApiMsgsRoute;
  route_follow_params: IRosTypeBosdynGraphNavApiMsgsRouteFollowingParams;
  travel_params: IRosTypeBosdynGraphNavApiMsgsTravelParams;
  end_time: { sec: number; nanosec: number };
  clock_identifier: string;
  destination_waypoint_tform_body_goal: IRosTypeBosdynApiMsgsSe2Pose;
  command_id: number;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigateRouteRequestConst {
  HEADER_FIELD_SET = 1,
  ROUTE_FIELD_SET = 4,
  ROUTE_FOLLOW_PARAMS_FIELD_SET = 8,
  TRAVEL_PARAMS_FIELD_SET = 16,
  END_TIME_FIELD_SET = 32,
  DESTINATION_WAYPOINT_TFORM_BODY_GOAL_FIELD_SET = 128,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigateRouteResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_results: IRosTypeBosdynApiMsgsLeaseUseResult[];
  status: IRosTypeBosdynGraphNavApiMsgsNavigateRouteResponseStatus;
  impaired_state: IRosTypeBosdynApiMsgsRobotImpairedState;
  command_id: number;
  error_waypoint_ids: string[];
  error_edge_ids: IRosTypeBosdynGraphNavApiMsgsEdgeId[];
  area_callback_error: IRosTypeBosdynGraphNavApiMsgsAreaCallbackServiceError;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigateRouteResponseConst {
  HEADER_FIELD_SET = 1,
  IMPAIRED_STATE_FIELD_SET = 8,
  AREA_CALLBACK_ERROR_FIELD_SET = 128,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigateRouteResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigateRouteResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NO_TIMESYNC = 2,
  STATUS_EXPIRED = 3,
  STATUS_TOO_DISTANT = 4,
  STATUS_ROBOT_IMPAIRED = 5,
  STATUS_RECORDING = 6,
  STATUS_UNKNOWN_ROUTE_ELEMENTS = 8,
  STATUS_INVALID_EDGE = 9,
  STATUS_NO_PATH = 20,
  STATUS_CONSTRAINT_FAULT = 11,
  STATUS_FEATURE_DESERT = 13,
  STATUS_LOST = 14,
  STATUS_NOT_LOCALIZED_TO_ROUTE = 16,
  STATUS_NOT_LOCALIZED_TO_MAP = 19,
  STATUS_COULD_NOT_UPDATE_ROUTE = 15,
  STATUS_STUCK = 17,
  STATUS_UNRECOGNIZED_COMMAND = 18,
  STATUS_AREA_CALLBACK_ERROR = 21,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigateToAnchorRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  leases: IRosTypeBosdynApiMsgsLease[];
  goal_waypoint_rt_seed_ewrt_seed_tolerance: IRosTypeGeometryMsgsVector3;
  travel_params: IRosTypeBosdynGraphNavApiMsgsTravelParams;
  end_time: { sec: number; nanosec: number };
  clock_identifier: string;
  command_id: number;
  goal: IRosTypeBosdynGraphNavApiMsgsNavigateToAnchorRequestOneOfGoal;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigateToAnchorRequestConst {
  HEADER_FIELD_SET = 1,
  GOAL_WAYPOINT_RT_SEED_EWRT_SEED_TOLERANCE_FIELD_SET = 16,
  ROUTE_PARAMS_FIELD_SET = 32,
  TRAVEL_PARAMS_FIELD_SET = 64,
  END_TIME_FIELD_SET = 128,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigateToAnchorRequestOneOfGoal {
  seed_tform_goal: IRosTypeGeometryMsgsPose;
  gps_navigation_params: IRosTypeBosdynGraphNavApiMsgsGpsNavigationParams;
  goal_choice: number;
  which: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigateToAnchorRequestOneOfGoalConst {
  GOAL_NOT_SET = 0,
  GOAL_SEED_TFORM_GOAL_SET = 1,
  GOAL_GPS_NAVIGATION_PARAMS_SET = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigateToAnchorResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_results: IRosTypeBosdynApiMsgsLeaseUseResult[];
  status: IRosTypeBosdynGraphNavApiMsgsNavigateToAnchorResponseStatus;
  impaired_state: IRosTypeBosdynApiMsgsRobotImpairedState;
  command_id: number;
  error_waypoint_ids: string[];
  area_callback_error: IRosTypeBosdynGraphNavApiMsgsAreaCallbackServiceError;
  gps_status: IRosTypeBosdynGraphNavApiMsgsNavigateToAnchorResponseGpsStatus;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigateToAnchorResponseConst {
  HEADER_FIELD_SET = 1,
  IMPAIRED_STATE_FIELD_SET = 8,
  AREA_CALLBACK_ERROR_FIELD_SET = 64,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigateToAnchorResponseGpsStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigateToAnchorResponseGpsStatusConst {
  GPS_STATUS_UNKNOWN = 0,
  GPS_STATUS_OK = 1,
  GPS_STATUS_NO_COORDS_IN_MAP = 2,
  GPS_STATUS_TOO_FAR_FROM_MAP = 3,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigateToAnchorResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigateToAnchorResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NO_TIMESYNC = 2,
  STATUS_EXPIRED = 3,
  STATUS_TOO_DISTANT = 4,
  STATUS_ROBOT_IMPAIRED = 5,
  STATUS_RECORDING = 6,
  STATUS_NO_ANCHORING = 7,
  STATUS_NO_PATH = 8,
  STATUS_FEATURE_DESERT = 10,
  STATUS_LOST = 11,
  STATUS_NOT_LOCALIZED_TO_MAP = 13,
  STATUS_COULD_NOT_UPDATE_ROUTE = 12,
  STATUS_STUCK = 14,
  STATUS_UNRECOGNIZED_COMMAND = 15,
  STATUS_INVALID_POSE = 16,
  STATUS_AREA_CALLBACK_ERROR = 17,
  STATUS_INVALID_GPS_COMMAND = 18,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigateToRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  leases: IRosTypeBosdynApiMsgsLease[];
  destination_waypoint_id: string;
  travel_params: IRosTypeBosdynGraphNavApiMsgsTravelParams;
  end_time: { sec: number; nanosec: number };
  clock_identifier: string;
  destination_waypoint_tform_body_goal: IRosTypeBosdynApiMsgsSe2Pose;
  command_id: number;
  route_blocked_behavior: IRosTypeBosdynGraphNavApiMsgsRouteFollowingParamsRouteBlockedBehavior;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigateToRequestConst {
  HEADER_FIELD_SET = 1,
  ROUTE_PARAMS_FIELD_SET = 8,
  TRAVEL_PARAMS_FIELD_SET = 16,
  END_TIME_FIELD_SET = 32,
  DESTINATION_WAYPOINT_TFORM_BODY_GOAL_FIELD_SET = 128,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigateToResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_results: IRosTypeBosdynApiMsgsLeaseUseResult[];
  status: IRosTypeBosdynGraphNavApiMsgsNavigateToResponseStatus;
  impaired_state: IRosTypeBosdynApiMsgsRobotImpairedState;
  command_id: number;
  error_waypoint_ids: string[];
  area_callback_error: IRosTypeBosdynGraphNavApiMsgsAreaCallbackServiceError;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigateToResponseConst {
  HEADER_FIELD_SET = 1,
  IMPAIRED_STATE_FIELD_SET = 8,
  AREA_CALLBACK_ERROR_FIELD_SET = 64,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigateToResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigateToResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NO_TIMESYNC = 2,
  STATUS_EXPIRED = 3,
  STATUS_TOO_DISTANT = 4,
  STATUS_ROBOT_IMPAIRED = 5,
  STATUS_RECORDING = 6,
  STATUS_UNKNOWN_WAYPOINT = 7,
  STATUS_NO_PATH = 8,
  STATUS_FEATURE_DESERT = 10,
  STATUS_LOST = 11,
  STATUS_NOT_LOCALIZED_TO_MAP = 13,
  STATUS_COULD_NOT_UPDATE_ROUTE = 12,
  STATUS_STUCK = 14,
  STATUS_UNRECOGNIZED_COMMAND = 15,
  STATUS_AREA_CALLBACK_ERROR = 16,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  command_id: number;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseStatus;
  impaired_state: IRosTypeBosdynApiMsgsRobotImpairedState;
  area_callback_errors: IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseAreaCallbackErrorsEntry[];
  remaining_route: IRosTypeBosdynGraphNavApiMsgsRoute;
  completed_route: IRosTypeBosdynGraphNavApiMsgsCompletedRoute;
  remaining_route_length: number;
  command_id: number;
  last_ko_tform_goal: IRosTypeGeometryMsgsPose;
  body_movement_status: IRosTypeBosdynApiMsgsSe2TrajectoryCommandFeedbackBodyMovementStatus;
  path_following_mode: IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsPathFollowingMode;
  active_region_information: IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseActiveRegionInformationEntry[];
  route_following_status: IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseRouteFollowingStatus;
  blockage_status: IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseBlockageStatus;
  stuck_reason: IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseStuckReason;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseConst {
  HEADER_FIELD_SET = 1,
  IMPAIRED_STATE_FIELD_SET = 4,
  REMAINING_ROUTE_FIELD_SET = 16,
  COMPLETED_ROUTE_FIELD_SET = 32,
  LAST_KO_TFORM_GOAL_FIELD_SET = 256,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseActiveRegionInformation {
  description: string;
  service_name: string;
  region_status: IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseActiveRegionInformationAreaCallbackStatus;
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseActiveRegionInformationAreaCallbackStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseActiveRegionInformationAreaCallbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_NAVIGATING = 1,
  STATUS_WAITING = 2,
  STATUS_CALLBACK_IN_CONTROL = 3,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseActiveRegionInformationEntry {
  key: string;
  value: IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseActiveRegionInformation;
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseAreaCallbackErrorsEntry {
  key: string;
  value: IRosTypeBosdynGraphNavApiMsgsAreaCallbackError;
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseBlockageStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseBlockageStatusConst {
  BLOCKAGE_STATUS_UNKNOWN = 0,
  BLOCKAGE_STATUS_ROUTE_CLEAR = 1,
  BLOCKAGE_STATUS_ROUTE_BLOCKED_TEMPORARILY = 2,
  BLOCKAGE_STATUS_STUCK = 3,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseRouteFollowingStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseRouteFollowingStatusConst {
  ROUTE_FOLLOWING_STATUS_UNKNOWN = 0,
  ROUTE_FOLLOWING_STATUS_FOLLOWING_ROUTE = 1,
  ROUTE_FOLLOWING_STATUS_RETURNING_TO_ROUTE = 2,
  ROUTE_FOLLOWING_STATUS_FOLLOWING_ALTERNATE_ROUTE = 3,
  ROUTE_FOLLOWING_STATUS_EXPLORING = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_FOLLOWING_ROUTE = 1,
  STATUS_REACHED_GOAL = 2,
  STATUS_NO_ROUTE = 3,
  STATUS_NO_LOCALIZATION = 4,
  STATUS_LOST = 5,
  STATUS_STUCK = 6,
  STATUS_COMMAND_TIMED_OUT = 7,
  STATUS_ROBOT_IMPAIRED = 8,
  STATUS_CONSTRAINT_FAULT = 11,
  STATUS_COMMAND_OVERRIDDEN = 12,
  STATUS_NOT_LOCALIZED_TO_ROUTE = 13,
  STATUS_LEASE_ERROR = 14,
  STATUS_AREA_CALLBACK_ERROR = 15,
}

export interface IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseStuckReason {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsNavigationFeedbackResponseStuckReasonConst {
  STUCK_REASON_UNKNOWN = 0,
  STUCK_REASON_OBSTACLE = 1,
  STUCK_REASON_AREA_CALLBACK_BLOCKED = 2,
  STUCK_REASON_AREA_CALLBACK_FAILED = 3,
  STUCK_REASON_GOAL_BLOCKED = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsPoseBounds {
  x_bounds: number;
  y_bounds: number;
  z_bounds: number;
  yaw_bounds: number;
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessAnchoringRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  params: IRosTypeBosdynGraphNavApiMsgsProcessAnchoringRequestParams;
  initial_hint: IRosTypeBosdynGraphNavApiMsgsAnchoringHint;
  modify_anchoring_on_server: boolean;
  stream_intermediate_results: boolean;
  apply_gps_result_to_waypoints_on_server: boolean;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsProcessAnchoringRequestConst {
  HEADER_FIELD_SET = 1,
  PARAMS_FIELD_SET = 2,
  INITIAL_HINT_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessAnchoringRequestParams {
  optimizer_params: IRosTypeBosdynGraphNavApiMsgsProcessAnchoringRequestParamsOptimizerParams;
  measurement_params: IRosTypeBosdynGraphNavApiMsgsProcessAnchoringRequestParamsMeasurementParams;
  weights: IRosTypeBosdynGraphNavApiMsgsProcessAnchoringRequestParamsWeights;
  optimize_existing_anchoring: IRosTypeStdMsgsBool;
  gravity_ewrt_seed: IRosTypeGeometryMsgsVector3;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsProcessAnchoringRequestParamsConst {
  OPTIMIZER_PARAMS_FIELD_SET = 1,
  MEASUREMENT_PARAMS_FIELD_SET = 2,
  WEIGHTS_FIELD_SET = 4,
  OPTIMIZE_EXISTING_ANCHORING_FIELD_SET = 8,
  GRAVITY_EWRT_SEED_FIELD_SET = 16,
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessAnchoringRequestParamsMeasurementParams {
  use_kinematic_odometry: IRosTypeStdMsgsBool;
  use_visual_odometry: IRosTypeStdMsgsBool;
  use_gyroscope_measurements: IRosTypeStdMsgsBool;
  use_loop_closures: IRosTypeStdMsgsBool;
  use_world_objects: IRosTypeStdMsgsBool;
  use_gps: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsProcessAnchoringRequestParamsMeasurementParamsConst {
  USE_KINEMATIC_ODOMETRY_FIELD_SET = 1,
  USE_VISUAL_ODOMETRY_FIELD_SET = 2,
  USE_GYROSCOPE_MEASUREMENTS_FIELD_SET = 4,
  USE_LOOP_CLOSURES_FIELD_SET = 8,
  USE_WORLD_OBJECTS_FIELD_SET = 16,
  USE_GPS_FIELD_SET = 32,
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessAnchoringRequestParamsOptimizerParams {
  max_iters: IRosTypeStdMsgsInt32;
  max_time_seconds: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsProcessAnchoringRequestParamsOptimizerParamsConst {
  MAX_ITERS_FIELD_SET = 1,
  MAX_TIME_SECONDS_FIELD_SET = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessAnchoringRequestParamsWeights {
  kinematic_odometry_weight: number;
  visual_odometry_weight: number;
  world_object_weight: number;
  hint_weight: number;
  gyroscope_weight: number;
  loop_closure_weight: number;
  gps_weight: number;
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessAnchoringResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynGraphNavApiMsgsProcessAnchoringResponseStatus;
  waypoint_results: IRosTypeBosdynGraphNavApiMsgsAnchor[];
  world_object_results: IRosTypeBosdynGraphNavApiMsgsAnchoredWorldObject[];
  anchoring_on_server_was_modified: boolean;
  iteration: number;
  cost: number;
  final_iteration: boolean;
  violated_waypoint_constraints: IRosTypeBosdynGraphNavApiMsgsWaypointAnchorHint[];
  violated_object_constraints: IRosTypeBosdynGraphNavApiMsgsWorldObjectAnchorHint[];
  missing_snapshot_ids: string[];
  missing_waypoint_ids: string[];
  invalid_hints: string[];
  inconsistent_edges: IRosTypeBosdynGraphNavApiMsgsEdgeId[];
  gps_result: IRosTypeBosdynGraphNavApiMsgsProcessAnchoringResponseGpsResult;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsProcessAnchoringResponseConst {
  HEADER_FIELD_SET = 1,
  GPS_RESULT_FIELD_SET = 16384,
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessAnchoringResponseGpsResult {
  status: IRosTypeBosdynGraphNavApiMsgsProcessAnchoringResponseGpsResultGpsStatus;
  ecef_tform_seed: IRosTypeGeometryMsgsPose;
  num_measurements_used: number;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsProcessAnchoringResponseGpsResultConst {
  ECEF_TFORM_SEED_FIELD_SET = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessAnchoringResponseGpsResultGpsStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsProcessAnchoringResponseGpsResultGpsStatusConst {
  GPS_STATUS_UNKNOWN = 0,
  GPS_STATUS_OK = 1,
  GPS_STATUS_NOT_ENOUGH_MEASUREMENTS = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessAnchoringResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsProcessAnchoringResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_MISSING_WAYPOINT_SNAPSHOTS = 2,
  STATUS_INVALID_GRAPH = 3,
  STATUS_OPTIMIZATION_FAILURE = 4,
  STATUS_INVALID_PARAMS = 5,
  STATUS_CONSTRAINT_VIOLATION = 6,
  STATUS_MAX_ITERATIONS = 7,
  STATUS_MAX_TIME = 8,
  STATUS_INVALID_HINTS = 9,
  STATUS_MAP_MODIFIED_DURING_PROCESSING = 10,
  STATUS_INVALID_GRAVITY_ALIGNMENT = 11,
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  params: IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequestParams;
  modify_map_on_server: boolean;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequestConst {
  HEADER_FIELD_SET = 1,
  PARAMS_FIELD_SET = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequestCollisionCheckingParams {
  check_edges_for_collision: IRosTypeStdMsgsBool;
  collision_check_robot_radius: IRosTypeStdMsgsFloat64;
  collision_check_height_variation: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequestCollisionCheckingParamsConst {
  CHECK_EDGES_FOR_COLLISION_FIELD_SET = 1,
  COLLISION_CHECK_ROBOT_RADIUS_FIELD_SET = 2,
  COLLISION_CHECK_HEIGHT_VARIATION_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequestFiducialLoopClosureParams {
  min_loop_closure_path_length: IRosTypeStdMsgsFloat64;
  max_loop_closure_edge_length: IRosTypeStdMsgsFloat64;
  max_fiducial_distance: IRosTypeStdMsgsFloat64;
  max_loop_closure_height_change: IRosTypeStdMsgsFloat64;
  prune_edges: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequestFiducialLoopClosureParamsConst {
  MIN_LOOP_CLOSURE_PATH_LENGTH_FIELD_SET = 1,
  MAX_LOOP_CLOSURE_EDGE_LENGTH_FIELD_SET = 2,
  MAX_FIDUCIAL_DISTANCE_FIELD_SET = 4,
  MAX_LOOP_CLOSURE_HEIGHT_CHANGE_FIELD_SET = 8,
  PRUNE_EDGES_FIELD_SET = 16,
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequestIcpParams {
  icp_iters: IRosTypeStdMsgsInt32;
  max_point_match_distance: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequestIcpParamsConst {
  ICP_ITERS_FIELD_SET = 1,
  MAX_POINT_MATCH_DISTANCE_FIELD_SET = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequestOdometryLoopClosureParams {
  max_loop_closure_path_length: IRosTypeStdMsgsFloat64;
  min_loop_closure_path_length: IRosTypeStdMsgsFloat64;
  max_loop_closure_height_change: IRosTypeStdMsgsFloat64;
  max_loop_closure_edge_length: IRosTypeStdMsgsFloat64;
  num_extra_loop_closure_iterations: IRosTypeStdMsgsInt32;
  prune_edges: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequestOdometryLoopClosureParamsConst {
  MAX_LOOP_CLOSURE_PATH_LENGTH_FIELD_SET = 1,
  MIN_LOOP_CLOSURE_PATH_LENGTH_FIELD_SET = 2,
  MAX_LOOP_CLOSURE_HEIGHT_CHANGE_FIELD_SET = 4,
  MAX_LOOP_CLOSURE_EDGE_LENGTH_FIELD_SET = 8,
  NUM_EXTRA_LOOP_CLOSURE_ITERATIONS_FIELD_SET = 16,
  PRUNE_EDGES_FIELD_SET = 32,
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequestParams {
  do_odometry_loop_closure: IRosTypeStdMsgsBool;
  odometry_loop_closure_params: IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequestOdometryLoopClosureParams;
  icp_params: IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequestIcpParams;
  do_fiducial_loop_closure: IRosTypeStdMsgsBool;
  fiducial_loop_closure_params: IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequestFiducialLoopClosureParams;
  collision_check_params: IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequestCollisionCheckingParams;
  timeout_seconds: number;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsProcessTopologyRequestParamsConst {
  DO_ODOMETRY_LOOP_CLOSURE_FIELD_SET = 1,
  ODOMETRY_LOOP_CLOSURE_PARAMS_FIELD_SET = 2,
  ICP_PARAMS_FIELD_SET = 4,
  DO_FIDUCIAL_LOOP_CLOSURE_FIELD_SET = 8,
  FIDUCIAL_LOOP_CLOSURE_PARAMS_FIELD_SET = 16,
  COLLISION_CHECK_PARAMS_FIELD_SET = 32,
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessTopologyResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynGraphNavApiMsgsProcessTopologyResponseStatus;
  new_subgraph: IRosTypeBosdynGraphNavApiMsgsGraph;
  map_on_server_was_modified: boolean;
  missing_snapshot_ids: string[];
  missing_waypoint_ids: string[];
  timed_out: boolean;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsProcessTopologyResponseConst {
  HEADER_FIELD_SET = 1,
  NEW_SUBGRAPH_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsProcessTopologyResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsProcessTopologyResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_MISSING_WAYPOINT_SNAPSHOTS = 2,
  STATUS_INVALID_GRAPH = 3,
  STATUS_MAP_MODIFIED_DURING_PROCESSING = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsRecordingEnvironment {
  name_prefix: string;
  waypoint_environment: IRosTypeBosdynGraphNavApiMsgsWaypointAnnotations;
  edge_environment: IRosTypeBosdynGraphNavApiMsgsEdgeAnnotations;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsRecordingEnvironmentConst {
  WAYPOINT_ENVIRONMENT_FIELD_SET = 2,
  EDGE_ENVIRONMENT_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsRegionInformation {
  region_id: string;
  description: string;
  route: IRosTypeBosdynGraphNavApiMsgsRoute;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsRegionInformationConst {
  ROUTE_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsRemotePointCloudStatus {
  service_name: string;
  exists_in_directory: boolean;
  has_data: boolean;
}

export interface IRosTypeBosdynGraphNavApiMsgsRoute {
  waypoint_id: string[];
  edge_id: IRosTypeBosdynGraphNavApiMsgsEdgeId[];
}

export interface IRosTypeBosdynGraphNavApiMsgsRouteChangeRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  command_id: number;
  route: IRosTypeBosdynGraphNavApiMsgsRoute;
  unfinished_route: IRosTypeBosdynGraphNavApiMsgsRoute;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsRouteChangeRequestConst {
  HEADER_FIELD_SET = 1,
  ROUTE_FIELD_SET = 4,
  UNFINISHED_ROUTE_FIELD_SET = 8,
}

export interface IRosTypeBosdynGraphNavApiMsgsRouteChangeResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynGraphNavApiMsgsRouteChangeResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsRouteChangeResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsRouteChangeResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsRouteChangeResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_COMMAND_ID = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsRouteFollowingParams {
  new_cmd_behavior: IRosTypeBosdynGraphNavApiMsgsRouteFollowingParamsStartRouteBehavior;
  existing_cmd_behavior: IRosTypeBosdynGraphNavApiMsgsRouteFollowingParamsResumeBehavior;
  route_blocked_behavior: IRosTypeBosdynGraphNavApiMsgsRouteFollowingParamsRouteBlockedBehavior;
}

export interface IRosTypeBosdynGraphNavApiMsgsRouteFollowingParamsResumeBehavior {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsRouteFollowingParamsResumeBehaviorConst {
  RESUME_UNKNOWN = 0,
  RESUME_RETURN_TO_UNFINISHED_ROUTE = 1,
  RESUME_FAIL_WHEN_NOT_ON_ROUTE = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsRouteFollowingParamsRouteBlockedBehavior {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsRouteFollowingParamsRouteBlockedBehaviorConst {
  ROUTE_BLOCKED_UNKNOWN = 0,
  ROUTE_BLOCKED_REROUTE = 1,
  ROUTE_BLOCKED_FAIL = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsRouteFollowingParamsStartRouteBehavior {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsRouteFollowingParamsStartRouteBehaviorConst {
  START_UNKNOWN = 0,
  START_GOTO_START = 1,
  START_GOTO_ROUTE = 2,
  START_FAIL_WHEN_NOT_ON_ROUTE = 3,
}

export interface IRosTypeBosdynGraphNavApiMsgsSensorCompatibilityStatus {
  map_has_lidar_data: boolean;
  robot_configured_for_lidar: boolean;
}

export interface IRosTypeBosdynGraphNavApiMsgsSetLocalizationRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  initial_guess: IRosTypeBosdynGraphNavApiMsgsLocalization;
  ko_tform_body: IRosTypeGeometryMsgsPose;
  max_distance: number;
  max_yaw: number;
  fiducial_init: IRosTypeBosdynGraphNavApiMsgsSetLocalizationRequestFiducialInit;
  use_fiducial_id: number;
  do_ambiguity_check: boolean;
  restrict_fiducial_detections_to_target_waypoint: boolean;
  refinement: IRosTypeBosdynGraphNavApiMsgsSetLocalizationRequestOneOfRefinement;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsSetLocalizationRequestConst {
  HEADER_FIELD_SET = 1,
  INITIAL_GUESS_FIELD_SET = 2,
  KO_TFORM_BODY_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsSetLocalizationRequestFiducialInit {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsSetLocalizationRequestFiducialInitConst {
  FIDUCIAL_INIT_UNKNOWN = 0,
  FIDUCIAL_INIT_NO_FIDUCIAL = 1,
  FIDUCIAL_INIT_NEAREST = 2,
  FIDUCIAL_INIT_NEAREST_AT_TARGET = 3,
  FIDUCIAL_INIT_SPECIFIC = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsSetLocalizationRequestOneOfRefinement {
  refine_fiducial_result_with_icp: boolean;
  refine_with_visual_features: IRosTypeBosdynGraphNavApiMsgsVisualRefinementOptions;
  refinement_choice: number;
  which: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsSetLocalizationRequestOneOfRefinementConst {
  REFINEMENT_NOT_SET = 0,
  REFINEMENT_REFINE_FIDUCIAL_RESULT_WITH_ICP_SET = 1,
  REFINEMENT_REFINE_WITH_VISUAL_FEATURES_SET = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsSetLocalizationResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  status: IRosTypeBosdynGraphNavApiMsgsSetLocalizationResponseStatus;
  error_report: string;
  localization: IRosTypeBosdynGraphNavApiMsgsLocalization;
  suspected_ambiguity: IRosTypeBosdynGraphNavApiMsgsSetLocalizationResponseSuspectedAmbiguity;
  impaired_state: IRosTypeBosdynApiMsgsRobotImpairedState;
  sensor_status: IRosTypeBosdynGraphNavApiMsgsSensorCompatibilityStatus;
  quality_check_result: IRosTypeBosdynGraphNavApiMsgsSetLocalizationResponseQualityCheckResult;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsSetLocalizationResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
  LOCALIZATION_FIELD_SET = 16,
  SUSPECTED_AMBIGUITY_FIELD_SET = 32,
  IMPAIRED_STATE_FIELD_SET = 64,
  SENSOR_STATUS_FIELD_SET = 128,
}

export interface IRosTypeBosdynGraphNavApiMsgsSetLocalizationResponseQualityCheckResult {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsSetLocalizationResponseQualityCheckResultConst {
  QUALITY_CHECK_UNKNOWN = 0,
  QUALITY_CHECK_SUCCESS = 1,
  QUALITY_CHECK_POOR_POINT_CLOUD_MATCH = 2,
  QUALITY_CHECK_POOR_GRAVITY_ALIGNMENT = 3,
  QUALITY_CHECK_SKIPPED = 4,
  QUALITY_CHECK_BAD_HEIGHT = 5,
}

export interface IRosTypeBosdynGraphNavApiMsgsSetLocalizationResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsSetLocalizationResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_ROBOT_IMPAIRED = 2,
  STATUS_UNKNOWN_WAYPOINT = 3,
  STATUS_ABORTED = 4,
  STATUS_FAILED = 5,
  STATUS_FIDUCIAL_TOO_FAR_AWAY = 6,
  STATUS_FIDUCIAL_TOO_OLD = 7,
  STATUS_NO_MATCHING_FIDUCIAL = 8,
  STATUS_FIDUCIAL_POSE_UNCERTAIN = 9,
  STATUS_INCOMPATIBLE_SENSORS = 10,
  STATUS_VISUAL_ALIGNMENT_FAILED = 11,
}

export interface IRosTypeBosdynGraphNavApiMsgsSetLocalizationResponseSuspectedAmbiguity {
  alternate_robot_tform_waypoint: IRosTypeGeometryMsgsPose;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsSetLocalizationResponseSuspectedAmbiguityConst {
  ALTERNATE_ROBOT_TFORM_WAYPOINT_FIELD_SET = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsSetRecordingEnvironmentRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  environment: IRosTypeBosdynGraphNavApiMsgsRecordingEnvironment;
  lease: IRosTypeBosdynApiMsgsLease;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsSetRecordingEnvironmentRequestConst {
  HEADER_FIELD_SET = 1,
  ENVIRONMENT_FIELD_SET = 2,
  LEASE_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsSetRecordingEnvironmentResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsSetRecordingEnvironmentResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsStartRecordingRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  recording_environment: IRosTypeBosdynGraphNavApiMsgsRecordingEnvironment;
  require_fiducials: number[];
  session_start_time: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsStartRecordingRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
  RECORDING_ENVIRONMENT_FIELD_SET = 4,
  SESSION_START_TIME_FIELD_SET = 16,
}

export interface IRosTypeBosdynGraphNavApiMsgsStartRecordingResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  created_waypoint: IRosTypeBosdynGraphNavApiMsgsWaypoint;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  status: IRosTypeBosdynGraphNavApiMsgsStartRecordingResponseStatus;
  missing_fiducials: number[];
  bad_pose_fiducials: number[];
  license_status: IRosTypeBosdynApiMsgsLicenseInfoStatus;
  impaired_state: IRosTypeBosdynApiMsgsRobotImpairedState;
  session_start_time: { sec: number; nanosec: number };
  map_stats: IRosTypeBosdynGraphNavApiMsgsMapStats;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsStartRecordingResponseConst {
  HEADER_FIELD_SET = 1,
  CREATED_WAYPOINT_FIELD_SET = 2,
  LEASE_USE_RESULT_FIELD_SET = 4,
  IMPAIRED_STATE_FIELD_SET = 128,
  SESSION_START_TIME_FIELD_SET = 256,
  MAP_STATS_FIELD_SET = 512,
}

export interface IRosTypeBosdynGraphNavApiMsgsStartRecordingResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsStartRecordingResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_COULD_NOT_CREATE_WAYPOINT = 2,
  STATUS_FOLLOWING_ROUTE = 3,
  STATUS_NOT_LOCALIZED_TO_EXISTING_MAP = 4,
  STATUS_MISSING_FIDUCIALS = 5,
  STATUS_MAP_TOO_LARGE_LICENSE = 6,
  STATUS_REMOTE_CLOUD_FAILURE_NOT_IN_DIRECTORY = 7,
  STATUS_REMOTE_CLOUD_FAILURE_NO_DATA = 8,
  STATUS_FIDUCIAL_POSE_NOT_OK = 9,
  STATUS_TOO_FAR_FROM_EXISTING_MAP = 10,
  STATUS_ROBOT_IMPAIRED = 11,
}

export interface IRosTypeBosdynGraphNavApiMsgsStopConfiguration {
  face_direction: IRosTypeBosdynGraphNavApiMsgsStopConfigurationFaceDirection;
  face_stairs_if_present: boolean;
  face_yaw_offset: IRosTypeStdMsgsFloat64;
  max_distance: IRosTypeStdMsgsFloat64;
  max_yaw: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsStopConfigurationConst {
  FACE_YAW_OFFSET_FIELD_SET = 4,
  MAX_DISTANCE_FIELD_SET = 8,
  MAX_YAW_FIELD_SET = 16,
}

export interface IRosTypeBosdynGraphNavApiMsgsStopConfigurationFaceDirection {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsStopConfigurationFaceDirectionConst {
  FACE_DIRECTION_UNKNOWN = 0,
  FACE_DIRECTION_ALONG_ROUTE = 1,
  FACE_DIRECTION_WAYPOINT_EXACT = 2,
  FACE_DIRECTION_WAYPOINT_AUTO = 3,
  FACE_DIRECTION_REGION_END = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsStopRecordingRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsStopRecordingRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsStopRecordingResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynGraphNavApiMsgsStopRecordingResponseStatus;
  error_waypoint_localized_id: string;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  session_start_time: { sec: number; nanosec: number };
  map_stats: IRosTypeBosdynGraphNavApiMsgsMapStats;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsStopRecordingResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 8,
  SESSION_START_TIME_FIELD_SET = 16,
  MAP_STATS_FIELD_SET = 32,
}

export interface IRosTypeBosdynGraphNavApiMsgsStopRecordingResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsStopRecordingResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NOT_LOCALIZED_TO_END = 2,
  STATUS_NOT_READY_YET = 3,
}

export interface IRosTypeBosdynGraphNavApiMsgsTravelParams {
  max_distance: number;
  max_yaw: number;
  velocity_limit: IRosTypeBosdynApiMsgsSe2VelocityLimit;
  ignore_final_yaw: boolean;
  feature_quality_tolerance: IRosTypeBosdynGraphNavApiMsgsTravelParamsFeatureQualityTolerance;
  disable_directed_exploration: boolean;
  disable_alternate_route_finding: boolean;
  path_following_mode: IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsPathFollowingMode;
  blocked_path_wait_time: { sec: number; nanosec: number };
  ground_clutter_mode: IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsGroundClutterAvoidanceMode;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsTravelParamsConst {
  VELOCITY_LIMIT_FIELD_SET = 4,
  BLOCKED_PATH_WAIT_TIME_FIELD_SET = 256,
}

export interface IRosTypeBosdynGraphNavApiMsgsTravelParamsFeatureQualityTolerance {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsTravelParamsFeatureQualityToleranceConst {
  TOLERANCE_UNKNOWN = 0,
  TOLERANCE_DEFAULT = 1,
  TOLERANCE_IGNORE_POOR_FEATURE_QUALITY = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsUpdateCallbackRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  command_id: number;
  end_time: { sec: number; nanosec: number };
  stage: IRosTypeBosdynGraphNavApiMsgsUpdateCallbackRequestStage;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUpdateCallbackRequestConst {
  HEADER_FIELD_SET = 1,
  END_TIME_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsUpdateCallbackRequestStage {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUpdateCallbackRequestStageConst {
  STAGE_UNKNOWN = 0,
  STAGE_TO_START = 1,
  STAGE_AT_START = 2,
  STAGE_TO_END = 3,
  STAGE_AT_END = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseStatus;
  localization: IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseUpdateLocalization;
  response: IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseOneOfResponse;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseConst {
  HEADER_FIELD_SET = 1,
  LOCALIZATION_FIELD_SET = 32,
}

export interface IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseError {
  error: IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseErrorErrorType;
  lease_use_results: IRosTypeBosdynApiMsgsLeaseUseResult[];
}

export interface IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseErrorErrorType {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseErrorErrorTypeConst {
  ERROR_UNKNOWN = 0,
  ERROR_BLOCKED = 1,
  ERROR_CALLBACK_FAILED = 2,
  ERROR_LEASE = 3,
  ERROR_TIMED_OUT = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseNavPolicy {
  at_start: IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseNavPolicyOption;
  at_end: IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseNavPolicyOption;
  end_config: IRosTypeBosdynGraphNavApiMsgsStopConfiguration;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseNavPolicyConst {
  END_CONFIG_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseNavPolicyOption {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseNavPolicyOptionConst {
  OPTION_UNKNOWN = 0,
  OPTION_CONTINUE = 1,
  OPTION_STOP = 2,
  OPTION_CONTROL = 3,
}

export interface IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseOneOfResponse {
  policy: IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseNavPolicy;
  error: IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseError;
  response_choice: number;
  which: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseOneOfResponseConst {
  RESPONSE_NOT_SET = 0,
  RESPONSE_POLICY_SET = 1,
  RESPONSE_ERROR_SET = 2,
  RESPONSE_COMPLETE_SET = 3,
}

export interface IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_COMMAND_ID = 2,
  STATUS_EXPIRED_END_TIME = 3,
}

export interface IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseUpdateLocalization {
  change: IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseUpdateLocalizationLocalizationChange;
}

export interface IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseUpdateLocalizationLocalizationChange {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUpdateCallbackResponseUpdateLocalizationLocalizationChangeConst {
  LOCALIZATION_UNKNOWN = 0,
  LOCALIZATION_AT_END = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsUploadEdgeSnapshotRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  chunk: IRosTypeBosdynApiMsgsDataChunk;
  lease: IRosTypeBosdynApiMsgsLease;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUploadEdgeSnapshotRequestConst {
  HEADER_FIELD_SET = 1,
  CHUNK_FIELD_SET = 2,
  LEASE_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsUploadEdgeSnapshotResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  map_stats: IRosTypeBosdynGraphNavApiMsgsMapStats;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUploadEdgeSnapshotResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
  MAP_STATS_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsUploadGraphRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  graph: IRosTypeBosdynGraphNavApiMsgsGraph;
  lease: IRosTypeBosdynApiMsgsLease;
  generate_new_anchoring: boolean;
  treat_validation_warnings_as_errors: boolean;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUploadGraphRequestConst {
  HEADER_FIELD_SET = 1,
  GRAPH_FIELD_SET = 2,
  LEASE_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsUploadGraphResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynGraphNavApiMsgsUploadGraphResponseStatus;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  loaded_waypoint_snapshot_ids: string[];
  unknown_waypoint_snapshot_ids: string[];
  loaded_edge_snapshot_ids: string[];
  unknown_edge_snapshot_ids: string[];
  license_status: IRosTypeBosdynApiMsgsLicenseInfoStatus;
  sensor_status: IRosTypeBosdynGraphNavApiMsgsSensorCompatibilityStatus;
  area_callback_error: IRosTypeBosdynGraphNavApiMsgsAreaCallbackServiceError;
  map_stats: IRosTypeBosdynGraphNavApiMsgsMapStats;
  validation_status: IRosTypeBosdynGraphNavApiMsgsUploadGraphResponseValidationStatus;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUploadGraphResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 4,
  SENSOR_STATUS_FIELD_SET = 256,
  AREA_CALLBACK_ERROR_FIELD_SET = 512,
  MAP_STATS_FIELD_SET = 1024,
  VALIDATION_STATUS_FIELD_SET = 2048,
}

export interface IRosTypeBosdynGraphNavApiMsgsUploadGraphResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUploadGraphResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_MAP_TOO_LARGE_LICENSE = 3,
  STATUS_INVALID_GRAPH = 4,
  STATUS_INCOMPATIBLE_SENSORS = 5,
  STATUS_AREA_CALLBACK_ERROR = 6,
}

export interface IRosTypeBosdynGraphNavApiMsgsUploadGraphResponseValidationStatus {
  missing_waypoint_ids_in_edges: string[];
  missing_waypoint_ids_in_anchors: string[];
  edge_ids_invalid_transform: IRosTypeBosdynGraphNavApiMsgsEdgeId[];
  waypoint_anchors_invalid_transform: string[];
  object_anchors_invalid_transform: string[];
  duplicate_waypoint_ids: string[];
  duplicate_waypoint_anchor_ids: string[];
  duplicate_object_anchor_ids: string[];
  duplicate_edge_ids: IRosTypeBosdynGraphNavApiMsgsEdgeId[];
  invalid_waypoint_ids_self_edges: string[];
  has_empty_waypoint_ids: boolean;
  has_empty_edge_ids: boolean;
  has_empty_waypoint_anchor_ids: boolean;
  has_empty_object_anchor_ids: boolean;
  malformed_staircase_edge_ids: IRosTypeBosdynGraphNavApiMsgsEdgeId[];
}

export interface IRosTypeBosdynGraphNavApiMsgsUploadWaypointSnapshotRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  chunk: IRosTypeBosdynApiMsgsDataChunk;
  lease: IRosTypeBosdynApiMsgsLease;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUploadWaypointSnapshotRequestConst {
  HEADER_FIELD_SET = 1,
  CHUNK_FIELD_SET = 2,
  LEASE_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsUploadWaypointSnapshotResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  status: IRosTypeBosdynGraphNavApiMsgsUploadWaypointSnapshotResponseStatus;
  sensor_status: IRosTypeBosdynGraphNavApiMsgsSensorCompatibilityStatus;
  map_stats: IRosTypeBosdynGraphNavApiMsgsMapStats;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUploadWaypointSnapshotResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
  SENSOR_STATUS_FIELD_SET = 8,
  MAP_STATS_FIELD_SET = 16,
}

export interface IRosTypeBosdynGraphNavApiMsgsUploadWaypointSnapshotResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsUploadWaypointSnapshotResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INCOMPATIBLE_SENSORS = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsValidateGraphRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsValidateGraphRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynGraphNavApiMsgsValidateGraphResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynGraphNavApiMsgsValidateGraphResponseStatus;
  sensor_status: IRosTypeBosdynGraphNavApiMsgsSensorCompatibilityStatus;
  area_callback_error: IRosTypeBosdynGraphNavApiMsgsAreaCallbackServiceError;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsValidateGraphResponseConst {
  HEADER_FIELD_SET = 1,
  SENSOR_STATUS_FIELD_SET = 4,
  AREA_CALLBACK_ERROR_FIELD_SET = 8,
}

export interface IRosTypeBosdynGraphNavApiMsgsValidateGraphResponseStatus {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsValidateGraphResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INCOMPATIBLE_SENSORS = 5,
  STATUS_AREA_CALLBACK_ERROR = 6,
}

export interface IRosTypeBosdynGraphNavApiMsgsVisualRefinementOptions {
  verify_refinement_quality: boolean;
}

export interface IRosTypeBosdynGraphNavApiMsgsWaypoint {
  id: string;
  snapshot_id: string;
  waypoint_tform_ko: IRosTypeGeometryMsgsPose;
  annotations: IRosTypeBosdynGraphNavApiMsgsWaypointAnnotations;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsWaypointConst {
  WAYPOINT_TFORM_KO_FIELD_SET = 4,
  ANNOTATIONS_FIELD_SET = 8,
}

export interface IRosTypeBosdynGraphNavApiMsgsWaypointAnchorHint {
  waypoint_anchor: IRosTypeBosdynGraphNavApiMsgsAnchor;
  seed_tform_waypoint_uncertainty: IRosTypeBosdynGraphNavApiMsgsAnchorHintUncertainty;
  seed_tform_waypoint_constraint: IRosTypeBosdynGraphNavApiMsgsPoseBounds;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsWaypointAnchorHintConst {
  WAYPOINT_ANCHOR_FIELD_SET = 1,
  SEED_TFORM_WAYPOINT_UNCERTAINTY_FIELD_SET = 2,
  SEED_TFORM_WAYPOINT_CONSTRAINT_FIELD_SET = 4,
}

export interface IRosTypeBosdynGraphNavApiMsgsWaypointAnnotations {
  name: string;
  creation_time: { sec: number; nanosec: number };
  icp_variance: IRosTypeBosdynApiMsgsSe3Covariance;
  scan_match_region: IRosTypeBosdynGraphNavApiMsgsWaypointAnnotationsLocalizeRegion;
  waypoint_source: IRosTypeBosdynGraphNavApiMsgsWaypointWaypointSource;
  client_metadata: IRosTypeBosdynGraphNavApiMsgsClientMetadata;
  loop_closure_settings: IRosTypeBosdynGraphNavApiMsgsWaypointAnnotationsLoopClosureSettings;
  gps_settings: IRosTypeBosdynGraphNavApiMsgsWaypointAnnotationsGpsSettings;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsWaypointAnnotationsConst {
  CREATION_TIME_FIELD_SET = 2,
  ICP_VARIANCE_FIELD_SET = 4,
  SCAN_MATCH_REGION_FIELD_SET = 8,
  CLIENT_METADATA_FIELD_SET = 32,
  LOOP_CLOSURE_SETTINGS_FIELD_SET = 64,
  GPS_SETTINGS_FIELD_SET = 128,
}

export interface IRosTypeBosdynGraphNavApiMsgsWaypointAnnotationsGpsSettings {
  state: IRosTypeBosdynGraphNavApiMsgsAnnotationState;
  ecef_tform_waypoint: IRosTypeGeometryMsgsPose;
  disable_gps_localization: boolean;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsWaypointAnnotationsGpsSettingsConst {
  ECEF_TFORM_WAYPOINT_FIELD_SET = 2,
}

export interface IRosTypeBosdynGraphNavApiMsgsWaypointAnnotationsLocalizeRegion {
  state: IRosTypeBosdynGraphNavApiMsgsAnnotationState;
  region: IRosTypeBosdynGraphNavApiMsgsWaypointAnnotationsLocalizeRegionOneOfRegion;
}

export interface IRosTypeBosdynGraphNavApiMsgsWaypointAnnotationsLocalizeRegionCircle2D {
  dist_2d: number;
}

export interface IRosTypeBosdynGraphNavApiMsgsWaypointAnnotationsLocalizeRegionOneOfRegion {
  circle: IRosTypeBosdynGraphNavApiMsgsWaypointAnnotationsLocalizeRegionCircle2D;
  region_choice: number;
  which: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsWaypointAnnotationsLocalizeRegionOneOfRegionConst {
  REGION_NOT_SET = 0,
  REGION_DEFAULT_REGION_SET = 1,
  REGION_EMPTY_SET = 2,
  REGION_CIRCLE_SET = 3,
}

export interface IRosTypeBosdynGraphNavApiMsgsWaypointAnnotationsLoopClosureSettings {
  disable_loop_closure: boolean;
  disable_collision_check: boolean;
  max_edge_length: number;
  max_odometry_path_length: number;
}

export interface IRosTypeBosdynGraphNavApiMsgsWaypointSnapshot {
  id: string;
  images: IRosTypeBosdynApiMsgsImageResponse[];
  point_cloud: IRosTypeBosdynApiMsgsPointCloud;
  objects: IRosTypeBosdynApiMsgsWorldObject[];
  robot_state: IRosTypeBosdynApiMsgsRobotState;
  robot_local_grids: IRosTypeBosdynApiMsgsLocalGrid[];
  is_point_cloud_processed: boolean;
  version_id: string;
  has_remote_point_cloud_sensor: boolean;
  body_tform_remote_point_cloud_sensor: IRosTypeGeometryMsgsPose;
  payloads: IRosTypeBosdynApiMsgsPayload[];
  robot_id: IRosTypeBosdynApiMsgsRobotId;
  recording_started_on: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsWaypointSnapshotConst {
  POINT_CLOUD_FIELD_SET = 4,
  ROBOT_STATE_FIELD_SET = 16,
  BODY_TFORM_REMOTE_POINT_CLOUD_SENSOR_FIELD_SET = 512,
  ROBOT_ID_FIELD_SET = 2048,
  RECORDING_STARTED_ON_FIELD_SET = 4096,
}

export interface IRosTypeBosdynGraphNavApiMsgsWaypointWaypointSource {
  value: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsWaypointWaypointSourceConst {
  WAYPOINT_SOURCE_UNKNOWN = 0,
  WAYPOINT_SOURCE_ROBOT_PATH = 1,
  WAYPOINT_SOURCE_USER_REQUEST = 2,
  WAYPOINT_SOURCE_ALTERNATE_ROUTE_FINDING = 3,
}

export interface IRosTypeBosdynGraphNavApiMsgsWorldObjectAnchorHint {
  object_anchor: IRosTypeBosdynGraphNavApiMsgsAnchoredWorldObject;
  seed_tform_object_uncertainty: IRosTypeBosdynGraphNavApiMsgsAnchorHintUncertainty;
  seed_tform_object_constraint: IRosTypeBosdynGraphNavApiMsgsPoseBounds;
  has_field: number;
}

export enum IRosTypeBosdynGraphNavApiMsgsWorldObjectAnchorHintConst {
  OBJECT_ANCHOR_FIELD_SET = 1,
  SEED_TFORM_OBJECT_UNCERTAINTY_FIELD_SET = 2,
  SEED_TFORM_OBJECT_CONSTRAINT_FIELD_SET = 4,
}

export interface IRosTypeBosdynKeepaliveApiMsgsActionAfter {
  after: { sec: number; nanosec: number };
  action: IRosTypeBosdynKeepaliveApiMsgsActionAfterOneOfAction;
  has_field: number;
}

export enum IRosTypeBosdynKeepaliveApiMsgsActionAfterConst {
  AFTER_FIELD_SET = 32,
}

export interface IRosTypeBosdynKeepaliveApiMsgsActionAfterAutoReturn {
  leases: IRosTypeBosdynApiMsgsLease[];
  params: IRosTypeBosdynAutoReturnApiMsgsParams;
  has_field: number;
}

export enum IRosTypeBosdynKeepaliveApiMsgsActionAfterAutoReturnConst {
  PARAMS_FIELD_SET = 2,
}

export interface IRosTypeBosdynKeepaliveApiMsgsActionAfterLeaseStale {
  leases: IRosTypeBosdynApiMsgsLease[];
}

export interface IRosTypeBosdynKeepaliveApiMsgsActionAfterOneOfAction {
  record_event: IRosTypeBosdynKeepaliveApiMsgsActionAfterRecordEvent;
  auto_return: IRosTypeBosdynKeepaliveApiMsgsActionAfterAutoReturn;
  lease_stale: IRosTypeBosdynKeepaliveApiMsgsActionAfterLeaseStale;
  action_choice: number;
  which: number;
}

export enum IRosTypeBosdynKeepaliveApiMsgsActionAfterOneOfActionConst {
  ACTION_NOT_SET = 0,
  ACTION_RECORD_EVENT_SET = 1,
  ACTION_AUTO_RETURN_SET = 2,
  ACTION_CONTROLLED_MOTORS_OFF_SET = 3,
  ACTION_IMMEDIATE_ROBOT_OFF_SET = 4,
  ACTION_LEASE_STALE_SET = 5,
}

export interface IRosTypeBosdynKeepaliveApiMsgsActionAfterRecordEvent {
  events: IRosTypeBosdynApiMsgsEvent[];
}

export interface IRosTypeBosdynKeepaliveApiMsgsCheckInRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  policy_id: number;
  has_field: number;
}

export enum IRosTypeBosdynKeepaliveApiMsgsCheckInRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynKeepaliveApiMsgsCheckInResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  last_checkin: { sec: number; nanosec: number };
  status: IRosTypeBosdynKeepaliveApiMsgsCheckInResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynKeepaliveApiMsgsCheckInResponseConst {
  HEADER_FIELD_SET = 1,
  LAST_CHECKIN_FIELD_SET = 2,
}

export interface IRosTypeBosdynKeepaliveApiMsgsCheckInResponseStatus {
  value: number;
}

export enum IRosTypeBosdynKeepaliveApiMsgsCheckInResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_POLICY_ID = 2,
}

export interface IRosTypeBosdynKeepaliveApiMsgsGetStatusRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynKeepaliveApiMsgsGetStatusRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynKeepaliveApiMsgsGetStatusResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynKeepaliveApiMsgsLivePolicy[];
  active_control_actions: IRosTypeBosdynKeepaliveApiMsgsGetStatusResponsePolicyControlAction[];
  has_field: number;
}

export enum IRosTypeBosdynKeepaliveApiMsgsGetStatusResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynKeepaliveApiMsgsGetStatusResponsePolicyControlAction {
  value: number;
}

export enum IRosTypeBosdynKeepaliveApiMsgsGetStatusResponsePolicyControlActionConst {
  POLICY_CONTROL_ACTION_UNKNOWN = 0,
  POLICY_CONTROL_ACTION_AUTO_RETURN = 1,
  POLICY_CONTROL_ACTION_MOTORS_OFF = 2,
  POLICY_CONTROL_ACTION_ROBOT_OFF = 3,
}

export interface IRosTypeBosdynKeepaliveApiMsgsLivePolicy {
  policy_id: number;
  policy: IRosTypeBosdynKeepaliveApiMsgsPolicy;
  last_checkin: { sec: number; nanosec: number };
  client_name: string;
  has_field: number;
}

export enum IRosTypeBosdynKeepaliveApiMsgsLivePolicyConst {
  POLICY_FIELD_SET = 2,
  LAST_CHECKIN_FIELD_SET = 4,
}

export interface IRosTypeBosdynKeepaliveApiMsgsModifyPolicyRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  to_add: IRosTypeBosdynKeepaliveApiMsgsPolicy;
  policy_ids_to_remove: number[];
  has_field: number;
}

export enum IRosTypeBosdynKeepaliveApiMsgsModifyPolicyRequestConst {
  HEADER_FIELD_SET = 1,
  TO_ADD_FIELD_SET = 2,
}

export interface IRosTypeBosdynKeepaliveApiMsgsModifyPolicyResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynKeepaliveApiMsgsModifyPolicyResponseStatus;
  added_policy: IRosTypeBosdynKeepaliveApiMsgsLivePolicy;
  removed_policies: IRosTypeBosdynKeepaliveApiMsgsLivePolicy[];
  has_field: number;
}

export enum IRosTypeBosdynKeepaliveApiMsgsModifyPolicyResponseConst {
  HEADER_FIELD_SET = 1,
  ADDED_POLICY_FIELD_SET = 4,
}

export interface IRosTypeBosdynKeepaliveApiMsgsModifyPolicyResponseStatus {
  value: number;
}

export enum IRosTypeBosdynKeepaliveApiMsgsModifyPolicyResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_POLICY_ID = 2,
  STATUS_INVALID_LEASE = 3,
}

export interface IRosTypeBosdynKeepaliveApiMsgsPolicy {
  name: string;
  actions: IRosTypeBosdynKeepaliveApiMsgsActionAfter[];
  associated_leases: IRosTypeBosdynApiMsgsLease[];
  user_id: string;
}

export interface IRosTypeBosdynLogStatusApiMsgsGetActiveLogStatusesRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsGetActiveLogStatusesRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynLogStatusApiMsgsGetActiveLogStatusesResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynLogStatusApiMsgsGetActiveLogStatusesResponseStatus;
  log_statuses: IRosTypeBosdynLogStatusApiMsgsLogStatus[];
  has_field: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsGetActiveLogStatusesResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynLogStatusApiMsgsGetActiveLogStatusesResponseStatus {
  value: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsGetActiveLogStatusesResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
}

export interface IRosTypeBosdynLogStatusApiMsgsGetLogStatusRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  id: string;
  has_field: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsGetLogStatusRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynLogStatusApiMsgsGetLogStatusResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynLogStatusApiMsgsGetLogStatusResponseStatus;
  log_status: IRosTypeBosdynLogStatusApiMsgsLogStatus;
  has_field: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsGetLogStatusResponseConst {
  HEADER_FIELD_SET = 1,
  LOG_STATUS_FIELD_SET = 4,
}

export interface IRosTypeBosdynLogStatusApiMsgsGetLogStatusResponseStatus {
  value: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsGetLogStatusResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_ID_NOT_FOUND = 2,
}

export interface IRosTypeBosdynLogStatusApiMsgsLogStatus {
  id: string;
  status: IRosTypeBosdynLogStatusApiMsgsLogStatusStatus;
  type: IRosTypeBosdynLogStatusApiMsgsLogStatusType;
}

export interface IRosTypeBosdynLogStatusApiMsgsLogStatusStatus {
  value: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsLogStatusStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_RECEIVED = 1,
  STATUS_IN_PROGRESS = 2,
  STATUS_SYNCING = 3,
  STATUS_DONE = 100,
  STATUS_FAILED = 101,
  STATUS_TERMINATED = 102,
}

export interface IRosTypeBosdynLogStatusApiMsgsLogStatusType {
  value: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsLogStatusTypeConst {
  TYPE_UNKNOWN = 0,
  TYPE_EXPERIMENT = 1,
  TYPE_RETRO = 2,
}

export interface IRosTypeBosdynLogStatusApiMsgsStartExperimentLogRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  keep_alive: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsStartExperimentLogRequestConst {
  HEADER_FIELD_SET = 1,
  KEEP_ALIVE_FIELD_SET = 2,
}

export interface IRosTypeBosdynLogStatusApiMsgsStartExperimentLogResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynLogStatusApiMsgsStartExperimentLogResponseStatus;
  log_status: IRosTypeBosdynLogStatusApiMsgsLogStatus;
  end_time: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsStartExperimentLogResponseConst {
  HEADER_FIELD_SET = 1,
  LOG_STATUS_FIELD_SET = 4,
  END_TIME_FIELD_SET = 8,
}

export interface IRosTypeBosdynLogStatusApiMsgsStartExperimentLogResponseStatus {
  value: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsStartExperimentLogResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_EXPERIMENT_LOG_RUNNING = 2,
}

export interface IRosTypeBosdynLogStatusApiMsgsStartRetroLogRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  past_duration: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsStartRetroLogRequestConst {
  HEADER_FIELD_SET = 1,
  PAST_DURATION_FIELD_SET = 2,
}

export interface IRosTypeBosdynLogStatusApiMsgsStartRetroLogResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynLogStatusApiMsgsStartRetroLogResponseStatus;
  log_status: IRosTypeBosdynLogStatusApiMsgsLogStatus;
  end_time: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsStartRetroLogResponseConst {
  HEADER_FIELD_SET = 1,
  LOG_STATUS_FIELD_SET = 4,
  END_TIME_FIELD_SET = 8,
}

export interface IRosTypeBosdynLogStatusApiMsgsStartRetroLogResponseStatus {
  value: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsStartRetroLogResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_EXPERIMENT_LOG_RUNNING = 2,
  STATUS_CONCURRENCY_LIMIT_REACHED = 3,
}

export interface IRosTypeBosdynLogStatusApiMsgsTerminateLogRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  id: string;
  has_field: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsTerminateLogRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynLogStatusApiMsgsTerminateLogResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynLogStatusApiMsgsTerminateLogResponseStatus;
  log_status: IRosTypeBosdynLogStatusApiMsgsLogStatus;
  has_field: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsTerminateLogResponseConst {
  HEADER_FIELD_SET = 1,
  LOG_STATUS_FIELD_SET = 4,
}

export interface IRosTypeBosdynLogStatusApiMsgsTerminateLogResponseStatus {
  value: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsTerminateLogResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_ID_NOT_FOUND = 2,
}

export interface IRosTypeBosdynLogStatusApiMsgsUpdateExperimentLogRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  keep_alive: { sec: number; nanosec: number };
  id: string;
  has_field: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsUpdateExperimentLogRequestConst {
  HEADER_FIELD_SET = 1,
  KEEP_ALIVE_FIELD_SET = 2,
}

export interface IRosTypeBosdynLogStatusApiMsgsUpdateExperimentLogResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynLogStatusApiMsgsUpdateExperimentLogResponseStatus;
  log_status: IRosTypeBosdynLogStatusApiMsgsLogStatus;
  end_time: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsUpdateExperimentLogResponseConst {
  HEADER_FIELD_SET = 1,
  LOG_STATUS_FIELD_SET = 4,
  END_TIME_FIELD_SET = 8,
}

export interface IRosTypeBosdynLogStatusApiMsgsUpdateExperimentLogResponseStatus {
  value: number;
}

export enum IRosTypeBosdynLogStatusApiMsgsUpdateExperimentLogResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_ID_NOT_FOUND = 2,
  STATUS_LOG_TERMINATED = 3,
}

export interface IRosTypeBosdynMetricsLoggingApiMsgsAbsoluteMetricsSnapshot {
  timestamp_start: { sec: number; nanosec: number };
  timestamp_end: { sec: number; nanosec: number };
  sequence_number: number;
  robot_serial_number: string;
  robot_species: string;
  parameters: IRosTypeBosdynApiMsgsParameter[];
  events: IRosTypeBosdynApiMsgsEvent[];
  has_field: number;
}

export enum IRosTypeBosdynMetricsLoggingApiMsgsAbsoluteMetricsSnapshotConst {
  TIMESTAMP_START_FIELD_SET = 1,
  TIMESTAMP_END_FIELD_SET = 2,
}

export interface IRosTypeBosdynMetricsLoggingApiMsgsGetAbsoluteMetricSnapshotRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  sequence_numbers: number[];
  has_field: number;
}

export enum IRosTypeBosdynMetricsLoggingApiMsgsGetAbsoluteMetricSnapshotRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMetricsLoggingApiMsgsGetAbsoluteMetricSnapshotResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  snapshots: IRosTypeBosdynMetricsLoggingApiMsgsSignedProto[];
  has_field: number;
}

export enum IRosTypeBosdynMetricsLoggingApiMsgsGetAbsoluteMetricSnapshotResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMetricsLoggingApiMsgsGetMetricsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  keys: string[];
  include_events: boolean;
  has_field: number;
}

export enum IRosTypeBosdynMetricsLoggingApiMsgsGetMetricsRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMetricsLoggingApiMsgsGetMetricsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  parameters: IRosTypeBosdynApiMsgsParameter[];
  missing_keys: string[];
  events: IRosTypeBosdynApiMsgsEvent[];
  has_field: number;
}

export enum IRosTypeBosdynMetricsLoggingApiMsgsGetMetricsResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMetricsLoggingApiMsgsGetStoreSequenceRangeRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynMetricsLoggingApiMsgsGetStoreSequenceRangeRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMetricsLoggingApiMsgsGetStoreSequenceRangeResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  first_sequence_number: number;
  last_sequence_number: number;
  has_field: number;
}

export enum IRosTypeBosdynMetricsLoggingApiMsgsGetStoreSequenceRangeResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMetricsLoggingApiMsgsSignedProto {
  data: number[];
}

export interface IRosTypeBosdynMissionApiMsgsAnswerQuestionRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  question_id: number;
  answer: IRosTypeBosdynMissionApiMsgsAnswerQuestionRequestOneOfAnswer;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsAnswerQuestionRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMissionApiMsgsAnswerQuestionRequestOneOfAnswer {
  code: number;
  custom_params: IRosTypeBosdynApiMsgsDictParam;
  answer_choice: number;
  which: number;
}

export enum IRosTypeBosdynMissionApiMsgsAnswerQuestionRequestOneOfAnswerConst {
  ANSWER_NOT_SET = 0,
  ANSWER_CODE_SET = 1,
  ANSWER_CUSTOM_PARAMS_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsAnswerQuestionResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynMissionApiMsgsAnswerQuestionResponseStatus;
  custom_param_error: IRosTypeBosdynApiMsgsCustomParamError;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsAnswerQuestionResponseConst {
  HEADER_FIELD_SET = 1,
  CUSTOM_PARAM_ERROR_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsAnswerQuestionResponseStatus {
  value: number;
}

export enum IRosTypeBosdynMissionApiMsgsAnswerQuestionResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_QUESTION_ID = 2,
  STATUS_INVALID_CODE = 3,
  STATUS_ALREADY_ANSWERED = 4,
  STATUS_CUSTOM_PARAMS_ERROR = 5,
  STATUS_INCOMPATIBLE_ANSWER = 6,
}

export interface IRosTypeBosdynMissionApiMsgsBosdynDockState {
  service_name: string;
  host: string;
  child: IRosTypeBosdynMissionApiMsgsNode;
  state_name: string;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsBosdynDockStateConst {
  CHILD_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsBosdynGraphNavLocalize {
  service_name: string;
  host: string;
  localization_request: IRosTypeBosdynGraphNavApiMsgsSetLocalizationRequest;
  allow_bad_quality: boolean;
  response_bb_key: string;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsBosdynGraphNavLocalizeConst {
  LOCALIZATION_REQUEST_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsBosdynGraphNavState {
  service_name: string;
  host: string;
  child: IRosTypeProto2RosAny;
  state_name: string;
  waypoint_id: string;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsBosdynGraphNavStateConst {
  CHILD_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsBosdynGripperCameraParamsState {
  service_name: string;
  host: string;
  child: IRosTypeBosdynMissionApiMsgsNode;
  state_name: string;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsBosdynGripperCameraParamsStateConst {
  CHILD_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsBosdynNavigateRoute {
  service_name: string;
  host: string;
  route: IRosTypeBosdynGraphNavApiMsgsRoute;
  route_follow_params: IRosTypeBosdynGraphNavApiMsgsRouteFollowingParams;
  travel_params: IRosTypeBosdynGraphNavApiMsgsTravelParams;
  navigation_feedback_response_blackboard_key: string;
  navigate_route_response_blackboard_key: string;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsBosdynNavigateRouteConst {
  ROUTE_FIELD_SET = 4,
  ROUTE_FOLLOW_PARAMS_FIELD_SET = 8,
  TRAVEL_PARAMS_FIELD_SET = 16,
}

export interface IRosTypeBosdynMissionApiMsgsBosdynNavigateTo {
  service_name: string;
  host: string;
  destination_waypoint_id: string;
  travel_params: IRosTypeBosdynGraphNavApiMsgsTravelParams;
  navigation_feedback_response_blackboard_key: string;
  navigate_to_response_blackboard_key: string;
  route_blocked_behavior: IRosTypeBosdynGraphNavApiMsgsRouteFollowingParamsRouteBlockedBehavior;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsBosdynNavigateToConst {
  ROUTE_GEN_PARAMS_FIELD_SET = 8,
  TRAVEL_PARAMS_FIELD_SET = 16,
}

export interface IRosTypeBosdynMissionApiMsgsBosdynPowerRequest {
  service_name: string;
  host: string;
  request: IRosTypeBosdynApiMsgsPowerCommandRequestRequest;
}

export interface IRosTypeBosdynMissionApiMsgsBosdynRecordEvent {
  service_name: string;
  host: string;
  event: IRosTypeBosdynApiMsgsEvent;
  succeed_early: boolean;
  additional_parameters: IRosTypeBosdynMissionApiMsgsBosdynRecordEventAdditionalParametersEntry[];
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsBosdynRecordEventConst {
  EVENT_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsBosdynRecordEventAdditionalParametersEntry {
  key: string;
  value: IRosTypeBosdynMissionApiMsgsValue;
}

export interface IRosTypeBosdynMissionApiMsgsBosdynRobotCommand {
  service_name: string;
  host: string;
  command: IRosTypeBosdynApiMsgsRobotCommand;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsBosdynRobotCommandConst {
  COMMAND_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsBosdynRobotState {
  service_name: string;
  host: string;
  child: IRosTypeProto2RosAny;
  state_name: string;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsBosdynRobotStateConst {
  CHILD_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsClearBehaviorFaults {
  service_name: string;
  host: string;
  robot_state_blackboard_name: string;
  cleared_cause_fall_blackboard_name: string;
  cleared_cause_hardware_blackboard_name: string;
  cleared_cause_lease_timeout_blackboard_name: string;
}

export interface IRosTypeBosdynMissionApiMsgsCondition {
  lhs: IRosTypeBosdynMissionApiMsgsConditionOperand;
  rhs: IRosTypeBosdynMissionApiMsgsConditionOperand;
  operation: IRosTypeBosdynMissionApiMsgsConditionCompare;
  handle_staleness: IRosTypeBosdynMissionApiMsgsConditionHandleStaleness;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsConditionConst {
  LHS_FIELD_SET = 1,
  RHS_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsConditionCompare {
  value: number;
}

export enum IRosTypeBosdynMissionApiMsgsConditionCompareConst {
  COMPARE_UNKNOWN = 0,
  COMPARE_EQ = 1,
  COMPARE_NE = 2,
  COMPARE_LT = 3,
  COMPARE_GT = 4,
  COMPARE_LE = 5,
  COMPARE_GE = 6,
}

export interface IRosTypeBosdynMissionApiMsgsConditionHandleStaleness {
  value: number;
}

export enum IRosTypeBosdynMissionApiMsgsConditionHandleStalenessConst {
  HANDLE_STALE_UNKNOWN = 0,
  HANDLE_STALE_READ_ANYWAY = 1,
  HANDLE_STALE_RUN_UNTIL_FRESH = 2,
  HANDLE_STALE_FAIL = 3,
}

export interface IRosTypeBosdynMissionApiMsgsConditionOperand {
  type: IRosTypeBosdynMissionApiMsgsConditionOperandOneOfType;
}

export interface IRosTypeBosdynMissionApiMsgsConditionOperandOneOfType {
  var: IRosTypeBosdynMissionApiMsgsVariableDeclaration;
  const_field: IRosTypeBosdynMissionApiMsgsConstantValue;
  type_choice: number;
  which: number;
}

export enum IRosTypeBosdynMissionApiMsgsConditionOperandOneOfTypeConst {
  TYPE_NOT_SET = 0,
  TYPE_VAR_SET = 1,
  TYPE_CONST_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsConstantResult {
  result: IRosTypeBosdynMissionApiMsgsResult;
}

export interface IRosTypeBosdynMissionApiMsgsConstantValue {
  value: IRosTypeBosdynMissionApiMsgsConstantValueOneOfValue;
}

export interface IRosTypeBosdynMissionApiMsgsConstantValueOneOfValue {
  float_value: number;
  string_value: string;
  int_value: number;
  bool_value: boolean;
  msg_value: IRosTypeProto2RosAnyProto;
  value_choice: number;
  which: number;
}

export enum IRosTypeBosdynMissionApiMsgsConstantValueOneOfValueConst {
  VALUE_NOT_SET = 0,
  VALUE_FLOAT_VALUE_SET = 1,
  VALUE_STRING_VALUE_SET = 2,
  VALUE_INT_VALUE_SET = 3,
  VALUE_BOOL_VALUE_SET = 4,
  VALUE_MSG_VALUE_SET = 5,
}

export interface IRosTypeBosdynMissionApiMsgsDataAcquisition {
  service_name: string;
  host: string;
  request: IRosTypeBosdynApiMsgsAcquireDataRequest;
  completion_behavior: IRosTypeBosdynMissionApiMsgsDataAcquisitionCompletionBehavior;
  group_name_format: string;
  request_name_in_blackboard: string;
  metadata_name_in_blackboard: string;
  action_name_format: string;
  disable_cancel_on_pause_or_stop: boolean;
  format_metadata: boolean;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsDataAcquisitionConst {
  REQUEST_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsDataAcquisitionCompletionBehavior {
  value: number;
}

export enum IRosTypeBosdynMissionApiMsgsDataAcquisitionCompletionBehaviorConst {
  COMPLETE_UNKNOWN = 0,
  COMPLETE_AFTER_SAVED = 1,
  COMPLETE_AFTER_ACQUIRED = 2,
}

export interface IRosTypeBosdynMissionApiMsgsDataAcquisitionOnInterruption {
  child: IRosTypeBosdynMissionApiMsgsNode;
  request_when_interrupted: IRosTypeBosdynMissionApiMsgsDataAcquisition;
  pause_mission_metadata: IRosTypeBosdynApiMsgsMetadata;
  restart_mission_metadata: IRosTypeBosdynApiMsgsMetadata;
  load_mission_metadata: IRosTypeBosdynApiMsgsMetadata;
  stop_mission_metadata: IRosTypeBosdynApiMsgsMetadata;
  lease_use_error_metadata: IRosTypeBosdynApiMsgsMetadata;
  play_mission_timeout_metadata: IRosTypeBosdynApiMsgsMetadata;
  child_node_error_metadata: IRosTypeBosdynApiMsgsMetadata;
  child_node_exception_metadata: IRosTypeBosdynApiMsgsMetadata;
  default_metadata: IRosTypeBosdynApiMsgsMetadata;
  keys_for_lease_use_error_message: string[];
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsDataAcquisitionOnInterruptionConst {
  CHILD_FIELD_SET = 1,
  REQUEST_WHEN_INTERRUPTED_FIELD_SET = 2,
  PAUSE_MISSION_METADATA_FIELD_SET = 4,
  RESTART_MISSION_METADATA_FIELD_SET = 8,
  LOAD_MISSION_METADATA_FIELD_SET = 16,
  STOP_MISSION_METADATA_FIELD_SET = 32,
  LEASE_USE_ERROR_METADATA_FIELD_SET = 64,
  PLAY_MISSION_TIMEOUT_METADATA_FIELD_SET = 128,
  CHILD_NODE_ERROR_METADATA_FIELD_SET = 256,
  CHILD_NODE_EXCEPTION_METADATA_FIELD_SET = 512,
  DEFAULT_METADATA_FIELD_SET = 1024,
}

export interface IRosTypeBosdynMissionApiMsgsDateToBlackboard {
  key: string;
}

export interface IRosTypeBosdynMissionApiMsgsDefineBlackboard {
  blackboard_variables: IRosTypeBosdynMissionApiMsgsKeyValue[];
  child: IRosTypeProto2RosAny;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsDefineBlackboardConst {
  CHILD_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsDock {
  service_name: string;
  host: string;
  docking_station_id: number;
  child: IRosTypeBosdynMissionApiMsgsNode;
  command_status_name: string;
  feedback_status_name: string;
  prep_pose_behavior: IRosTypeBosdynApiMsgsPrepPoseBehavior;
  docking_command_feedback_response_blackboard_key: string;
  docking_command_response_blackboard_key: string;
  require_fiducial: boolean;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsDockConst {
  CHILD_FIELD_SET = 8,
}

export interface IRosTypeBosdynMissionApiMsgsEstablishSessionRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  leases: IRosTypeBosdynApiMsgsLease[];
  inputs: IRosTypeBosdynMissionApiMsgsVariableDeclaration[];
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsEstablishSessionRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMissionApiMsgsEstablishSessionResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynMissionApiMsgsEstablishSessionResponseStatus;
  session_id: string;
  missing_lease_resources: string[];
  lease_use_results: IRosTypeBosdynApiMsgsLeaseUseResult[];
  missing_inputs: IRosTypeBosdynMissionApiMsgsVariableDeclaration[];
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsEstablishSessionResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMissionApiMsgsEstablishSessionResponseStatus {
  value: number;
}

export enum IRosTypeBosdynMissionApiMsgsEstablishSessionResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_MISSING_LEASES = 2,
  STATUS_MISSING_INPUTS = 3,
}

export interface IRosTypeBosdynMissionApiMsgsExecuteChoreography {
  service_name: string;
  host: string;
  sequence_name: string;
}

export interface IRosTypeBosdynMissionApiMsgsFailedNode {
  name: string;
  error: string;
  impl_typename: string;
}

export interface IRosTypeBosdynMissionApiMsgsForDuration {
  duration: { sec: number; nanosec: number };
  child: IRosTypeBosdynMissionApiMsgsNode;
  time_remaining_name: string;
  timeout_child: IRosTypeBosdynMissionApiMsgsNode;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsForDurationConst {
  DURATION_FIELD_SET = 1,
  CHILD_FIELD_SET = 2,
  TIMEOUT_CHILD_FIELD_SET = 8,
}

export interface IRosTypeBosdynMissionApiMsgsFormatBlackboard {
  key: string;
  format: string;
}

export interface IRosTypeBosdynMissionApiMsgsGetInfoRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsGetInfoRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMissionApiMsgsGetInfoResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  mission_info: IRosTypeBosdynMissionApiMsgsMissionInfo;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsGetInfoResponseConst {
  HEADER_FIELD_SET = 1,
  MISSION_INFO_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsGetMissionRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsGetMissionRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMissionApiMsgsGetMissionResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  root: IRosTypeBosdynMissionApiMsgsNode;
  id: number;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsGetMissionResponseConst {
  HEADER_FIELD_SET = 1,
  ROOT_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsGetRemoteMissionServiceInfoRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsGetRemoteMissionServiceInfoRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMissionApiMsgsGetRemoteMissionServiceInfoResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  custom_params: IRosTypeBosdynApiMsgsDictParamSpec;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsGetRemoteMissionServiceInfoResponseConst {
  HEADER_FIELD_SET = 1,
  CUSTOM_PARAMS_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsGetStateRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  history_upper_tick_bound: IRosTypeStdMsgsInt64;
  lower_bound: IRosTypeBosdynMissionApiMsgsGetStateRequestOneOfLowerBound;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsGetStateRequestConst {
  HEADER_FIELD_SET = 1,
  HISTORY_UPPER_TICK_BOUND_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsGetStateRequestOneOfLowerBound {
  history_lower_tick_bound: number;
  history_past_ticks: number;
  lower_bound_choice: number;
  which: number;
}

export enum IRosTypeBosdynMissionApiMsgsGetStateRequestOneOfLowerBoundConst {
  LOWER_BOUND_NOT_SET = 0,
  LOWER_BOUND_HISTORY_LOWER_TICK_BOUND_SET = 1,
  LOWER_BOUND_HISTORY_PAST_TICKS_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsGetStateResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  state: IRosTypeBosdynMissionApiMsgsState;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsGetStateResponseConst {
  HEADER_FIELD_SET = 1,
  STATE_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsKeyValue {
  key: string;
  value: IRosTypeBosdynMissionApiMsgsValue;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsKeyValueConst {
  VALUE_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsLoadMissionRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  root: IRosTypeBosdynMissionApiMsgsNode;
  leases: IRosTypeBosdynApiMsgsLease[];
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsLoadMissionRequestConst {
  HEADER_FIELD_SET = 1,
  ROOT_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsLoadMissionResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynMissionApiMsgsLoadMissionResponseStatus;
  lease_use_results: IRosTypeBosdynApiMsgsLeaseUseResult[];
  mission_info: IRosTypeBosdynMissionApiMsgsMissionInfo;
  failed_nodes: IRosTypeBosdynMissionApiMsgsFailedNode[];
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsLoadMissionResponseConst {
  HEADER_FIELD_SET = 1,
  MISSION_INFO_FIELD_SET = 8,
}

export interface IRosTypeBosdynMissionApiMsgsLoadMissionResponseStatus {
  value: number;
}

export enum IRosTypeBosdynMissionApiMsgsLoadMissionResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_COMPILE_ERROR = 2,
  STATUS_VALIDATE_ERROR = 3,
}

export interface IRosTypeBosdynMissionApiMsgsMissionInfo {
  id: number;
  root: IRosTypeBosdynMissionApiMsgsNodeInfo;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsMissionInfoConst {
  ROOT_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsMissionUploadChoreography {
  service_name: string;
  host: string;
  choreography_sequences: IRosTypeBosdynSpotApiMsgsChoreographySequence[];
  animated_moves: IRosTypeBosdynSpotApiMsgsAnimation[];
}

export interface IRosTypeBosdynMissionApiMsgsNode {
  name: string;
  user_data: IRosTypeBosdynMissionApiMsgsUserData;
  reference_id: string;
  parameter_values: IRosTypeBosdynMissionApiMsgsKeyValue[];
  overrides: IRosTypeBosdynMissionApiMsgsKeyValue[];
  parameters: IRosTypeBosdynMissionApiMsgsVariableDeclaration[];
  type: IRosTypeBosdynMissionApiMsgsNodeOneOfType;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsNodeConst {
  USER_DATA_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsNodeInfo {
  id: number;
  name: string;
  user_data: IRosTypeBosdynMissionApiMsgsUserData;
  children: IRosTypeProto2RosAny[];
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsNodeInfoConst {
  USER_DATA_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsNodeOneOfType {
  impl: IRosTypeProto2RosAnyProto;
  node_reference: string;
  condition: IRosTypeBosdynMissionApiMsgsCondition;
  sequence: IRosTypeProto2RosAny;
  selector: IRosTypeBosdynMissionApiMsgsSelector;
  repeat: IRosTypeProto2RosAny;
  retain_lease: IRosTypeBosdynMissionApiMsgsRetainLease;
  retry: IRosTypeBosdynMissionApiMsgsRetry;
  for_duration: IRosTypeProto2RosAny;
  bosdyn_dock_state: IRosTypeProto2RosAny;
  bosdyn_power_request: IRosTypeBosdynMissionApiMsgsBosdynPowerRequest;
  bosdyn_robot_state: IRosTypeBosdynMissionApiMsgsBosdynRobotState;
  bosdyn_robot_command: IRosTypeBosdynMissionApiMsgsBosdynRobotCommand;
  remote_grpc: IRosTypeBosdynMissionApiMsgsRemoteGrpc;
  sleep: IRosTypeBosdynMissionApiMsgsSleep;
  prompt: IRosTypeProto2RosAny;
  set_blackboard: IRosTypeBosdynMissionApiMsgsSetBlackboard;
  date_to_blackboard: IRosTypeBosdynMissionApiMsgsDateToBlackboard;
  define_blackboard: IRosTypeBosdynMissionApiMsgsDefineBlackboard;
  format_blackboard: IRosTypeBosdynMissionApiMsgsFormatBlackboard;
  constant_result: IRosTypeBosdynMissionApiMsgsConstantResult;
  bosdyn_navigate_route: IRosTypeBosdynMissionApiMsgsBosdynNavigateRoute;
  bosdyn_navigate_to: IRosTypeBosdynMissionApiMsgsBosdynNavigateTo;
  bosdyn_graph_nav_state: IRosTypeBosdynMissionApiMsgsBosdynGraphNavState;
  bosdyn_graph_nav_localize: IRosTypeBosdynMissionApiMsgsBosdynGraphNavLocalize;
  bosdyn_record_event: IRosTypeBosdynMissionApiMsgsBosdynRecordEvent;
  simple_parallel: IRosTypeProto2RosAny;
  spot_cam_ptz: IRosTypeBosdynMissionApiMsgsSpotCamPtz;
  spot_cam_store_media: IRosTypeBosdynMissionApiMsgsSpotCamStoreMedia;
  spot_cam_led: IRosTypeBosdynMissionApiMsgsSpotCamLed;
  spot_cam_focus_state: IRosTypeBosdynMissionApiMsgsSpotCamFocusState;
  spot_cam_reset_autofocus: IRosTypeBosdynMissionApiMsgsSpotCamResetAutofocus;
  store_metadata: IRosTypeBosdynMissionApiMsgsStoreMetadata;
  switch_field: IRosTypeBosdynMissionApiMsgsSwitch;
  data_acquisition: IRosTypeBosdynMissionApiMsgsDataAcquisition;
  data_acquisition_on_interruption: IRosTypeProto2RosAny;
  dock: IRosTypeProto2RosAny;
  restart_when_paused: IRosTypeProto2RosAny;
  clear_behavior_faults: IRosTypeBosdynMissionApiMsgsClearBehaviorFaults;
  bosdyn_gripper_camera_params_state: IRosTypeProto2RosAny;
  set_gripper_camera_params: IRosTypeBosdynMissionApiMsgsSetGripperCameraParams;
  parallel_and: IRosTypeBosdynMissionApiMsgsParallelAnd;
  set_grasp_override: IRosTypeBosdynMissionApiMsgsSetGraspOverride;
  execute_choreography: IRosTypeBosdynMissionApiMsgsExecuteChoreography;
  mission_upload_choreography: IRosTypeBosdynMissionApiMsgsMissionUploadChoreography;
  type_choice: number;
  which: number;
}

export enum IRosTypeBosdynMissionApiMsgsNodeOneOfTypeConst {
  TYPE_NOT_SET = 0,
  TYPE_IMPL_SET = 1,
  TYPE_NODE_REFERENCE_SET = 2,
  TYPE_CONDITION_SET = 3,
  TYPE_SEQUENCE_SET = 4,
  TYPE_SELECTOR_SET = 5,
  TYPE_REPEAT_SET = 6,
  TYPE_RETAIN_LEASE_SET = 7,
  TYPE_RETRY_SET = 8,
  TYPE_FOR_DURATION_SET = 9,
  TYPE_BOSDYN_DOCK_STATE_SET = 10,
  TYPE_BOSDYN_POWER_REQUEST_SET = 11,
  TYPE_BOSDYN_ROBOT_STATE_SET = 12,
  TYPE_BOSDYN_ROBOT_COMMAND_SET = 13,
  TYPE_REMOTE_GRPC_SET = 14,
  TYPE_SLEEP_SET = 15,
  TYPE_PROMPT_SET = 16,
  TYPE_SET_BLACKBOARD_SET = 17,
  TYPE_DATE_TO_BLACKBOARD_SET = 18,
  TYPE_DEFINE_BLACKBOARD_SET = 19,
  TYPE_FORMAT_BLACKBOARD_SET = 20,
  TYPE_CONSTANT_RESULT_SET = 21,
  TYPE_BOSDYN_NAVIGATE_ROUTE_SET = 22,
  TYPE_BOSDYN_NAVIGATE_TO_SET = 23,
  TYPE_BOSDYN_GRAPH_NAV_STATE_SET = 24,
  TYPE_BOSDYN_GRAPH_NAV_LOCALIZE_SET = 25,
  TYPE_BOSDYN_RECORD_EVENT_SET = 26,
  TYPE_SIMPLE_PARALLEL_SET = 27,
  TYPE_SPOT_CAM_PTZ_SET = 28,
  TYPE_SPOT_CAM_STORE_MEDIA_SET = 29,
  TYPE_SPOT_CAM_LED_SET = 30,
  TYPE_SPOT_CAM_FOCUS_STATE_SET = 31,
  TYPE_SPOT_CAM_RESET_AUTOFOCUS_SET = 32,
  TYPE_STORE_METADATA_SET = 33,
  TYPE_SWITCH_FIELD_SET = 34,
  TYPE_DATA_ACQUISITION_SET = 35,
  TYPE_DATA_ACQUISITION_ON_INTERRUPTION_SET = 36,
  TYPE_DOCK_SET = 37,
  TYPE_RESTART_WHEN_PAUSED_SET = 38,
  TYPE_CLEAR_BEHAVIOR_FAULTS_SET = 39,
  TYPE_BOSDYN_GRIPPER_CAMERA_PARAMS_STATE_SET = 40,
  TYPE_SET_GRIPPER_CAMERA_PARAMS_SET = 41,
  TYPE_PARALLEL_AND_SET = 42,
  TYPE_SET_GRASP_OVERRIDE_SET = 43,
  TYPE_EXECUTE_CHOREOGRAPHY_SET = 44,
  TYPE_MISSION_UPLOAD_CHOREOGRAPHY_SET = 45,
}

export interface IRosTypeBosdynMissionApiMsgsParallelAnd {
  children: IRosTypeProto2RosAny[];
  finish_every_node: boolean;
}

export interface IRosTypeBosdynMissionApiMsgsPauseMissionRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsPauseMissionRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsPauseMissionResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynMissionApiMsgsPauseMissionResponseStatus;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsPauseMissionResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsPauseMissionResponseStatus {
  value: number;
}

export enum IRosTypeBosdynMissionApiMsgsPauseMissionResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NO_MISSION_PLAYING = 2,
}

export interface IRosTypeBosdynMissionApiMsgsPlayMissionRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  pause_time: { sec: number; nanosec: number };
  leases: IRosTypeBosdynApiMsgsLease[];
  settings: IRosTypeBosdynMissionApiMsgsPlaySettings;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsPlayMissionRequestConst {
  HEADER_FIELD_SET = 1,
  PAUSE_TIME_FIELD_SET = 2,
  SETTINGS_FIELD_SET = 8,
}

export interface IRosTypeBosdynMissionApiMsgsPlayMissionResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynMissionApiMsgsPlayMissionResponseStatus;
  lease_use_results: IRosTypeBosdynApiMsgsLeaseUseResult[];
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsPlayMissionResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMissionApiMsgsPlayMissionResponseStatus {
  value: number;
}

export enum IRosTypeBosdynMissionApiMsgsPlayMissionResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NO_MISSION = 2,
}

export interface IRosTypeBosdynMissionApiMsgsPlaySettings {
  velocity_limit: IRosTypeBosdynApiMsgsSe2VelocityLimit;
  disable_directed_exploration: boolean;
  disable_alternate_route_finding: boolean;
  path_following_mode: IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsPathFollowingMode;
  ground_clutter_mode: IRosTypeBosdynGraphNavApiMsgsEdgeAnnotationsGroundClutterAvoidanceMode;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsPlaySettingsConst {
  VELOCITY_LIMIT_FIELD_SET = 1,
}

export interface IRosTypeBosdynMissionApiMsgsPrompt {
  always_reprompt: boolean;
  text: string;
  source: string;
  options: IRosTypeBosdynMissionApiMsgsPromptOption[];
  child: IRosTypeBosdynMissionApiMsgsNode;
  for_autonomous_processing: boolean;
  severity: IRosTypeBosdynApiMsgsAlertDataSeverityLevel;
  question_name_in_blackboard: string;
  answer_spec: IRosTypeBosdynMissionApiMsgsPromptOneOfAnswerSpec;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsPromptConst {
  CHILD_FIELD_SET = 64,
}

export interface IRosTypeBosdynMissionApiMsgsPromptOneOfAnswerSpec {
  options_list: IRosTypeBosdynMissionApiMsgsPromptOptionsList;
  custom_params: IRosTypeBosdynApiMsgsDictParamSpec;
  answer_spec_choice: number;
  which: number;
}

export enum IRosTypeBosdynMissionApiMsgsPromptOneOfAnswerSpecConst {
  ANSWER_SPEC_NOT_SET = 0,
  ANSWER_SPEC_OPTIONS_LIST_SET = 1,
  ANSWER_SPEC_CUSTOM_PARAMS_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsPromptOption {
  text: string;
  answer_code: number;
}

export interface IRosTypeBosdynMissionApiMsgsPromptOptionsList {
  options: IRosTypeBosdynMissionApiMsgsPromptOption[];
}

export interface IRosTypeBosdynMissionApiMsgsQuestion {
  id: number;
  source: string;
  text: string;
  options: IRosTypeBosdynMissionApiMsgsPromptOption[];
  custom_params: IRosTypeBosdynApiMsgsDictParamSpec;
  for_autonomous_processing: boolean;
  severity: IRosTypeBosdynApiMsgsAlertDataSeverityLevel;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsQuestionConst {
  CUSTOM_PARAMS_FIELD_SET = 16,
}

export interface IRosTypeBosdynMissionApiMsgsRemoteGrpc {
  host: string;
  service_name: string;
  timeout: number;
  lease_resources: string[];
  inputs: IRosTypeBosdynMissionApiMsgsKeyValue[];
  group_name_format: string;
  params: IRosTypeBosdynApiMsgsDictParam;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsRemoteGrpcConst {
  PARAMS_FIELD_SET = 64,
}

export interface IRosTypeBosdynMissionApiMsgsRepeat {
  max_starts: number;
  child: IRosTypeBosdynMissionApiMsgsNode;
  start_counter_state_name: string;
  respect_child_failure: boolean;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsRepeatConst {
  CHILD_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsRestartMissionRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  pause_time: { sec: number; nanosec: number };
  leases: IRosTypeBosdynApiMsgsLease[];
  settings: IRosTypeBosdynMissionApiMsgsPlaySettings;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsRestartMissionRequestConst {
  HEADER_FIELD_SET = 1,
  PAUSE_TIME_FIELD_SET = 2,
  SETTINGS_FIELD_SET = 8,
}

export interface IRosTypeBosdynMissionApiMsgsRestartMissionResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynMissionApiMsgsRestartMissionResponseStatus;
  lease_use_results: IRosTypeBosdynApiMsgsLeaseUseResult[];
  failed_nodes: IRosTypeBosdynMissionApiMsgsFailedNode[];
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsRestartMissionResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMissionApiMsgsRestartMissionResponseStatus {
  value: number;
}

export enum IRosTypeBosdynMissionApiMsgsRestartMissionResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NO_MISSION = 2,
  STATUS_VALIDATE_ERROR = 3,
}

export interface IRosTypeBosdynMissionApiMsgsRestartWhenPaused {
  child: IRosTypeBosdynMissionApiMsgsNode;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsRestartWhenPausedConst {
  CHILD_FIELD_SET = 1,
}

export interface IRosTypeBosdynMissionApiMsgsResult {
  value: number;
}

export enum IRosTypeBosdynMissionApiMsgsResultConst {
  RESULT_UNKNOWN = 0,
  RESULT_FAILURE = 1,
  RESULT_RUNNING = 2,
  RESULT_SUCCESS = 3,
  RESULT_ERROR = 4,
}

export interface IRosTypeBosdynMissionApiMsgsRetainLease {
  service_name: string;
  host: string;
}

export interface IRosTypeBosdynMissionApiMsgsRetry {
  max_attempts: number;
  child: IRosTypeProto2RosAny;
  attempt_counter_state_name: string;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsRetryConst {
  CHILD_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsSelector {
  always_restart: boolean;
  children: IRosTypeProto2RosAny[];
}

export interface IRosTypeBosdynMissionApiMsgsSequence {
  always_restart: boolean;
  children: IRosTypeBosdynMissionApiMsgsNode[];
}

export interface IRosTypeBosdynMissionApiMsgsSetBlackboard {
  blackboard_variables: IRosTypeBosdynMissionApiMsgsKeyValue[];
}

export interface IRosTypeBosdynMissionApiMsgsSetGraspOverride {
  service_name: string;
  host: string;
  grasp_override_request: IRosTypeBosdynApiMsgsApiGraspOverrideRequest;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsSetGraspOverrideConst {
  GRASP_OVERRIDE_REQUEST_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsSetGripperCameraParams {
  service_name: string;
  host: string;
  params: IRosTypeBosdynMissionApiMsgsSetGripperCameraParamsOneOfParams;
}

export interface IRosTypeBosdynMissionApiMsgsSetGripperCameraParamsOneOfParams {
  params_in_blackboard_key: string;
  new_params: IRosTypeBosdynApiMsgsGripperCameraParams;
  params_choice: number;
  which: number;
}

export enum IRosTypeBosdynMissionApiMsgsSetGripperCameraParamsOneOfParamsConst {
  PARAMS_NOT_SET = 0,
  PARAMS_PARAMS_IN_BLACKBOARD_KEY_SET = 1,
  PARAMS_NEW_PARAMS_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsSimpleParallel {
  primary: IRosTypeBosdynMissionApiMsgsNode;
  secondary: IRosTypeBosdynMissionApiMsgsNode;
  run_secondary_node_once: boolean;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsSimpleParallelConst {
  PRIMARY_FIELD_SET = 1,
  SECONDARY_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsSleep {
  seconds: number;
  restart_after_stop: boolean;
}

export interface IRosTypeBosdynMissionApiMsgsSpotCamFocusState {
  service_name: string;
  host: string;
  focus_state: IRosTypeBosdynSpotCamApiMsgsPtzFocusState;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsSpotCamFocusStateConst {
  FOCUS_STATE_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsSpotCamLed {
  service_name: string;
  host: string;
  brightnesses: IRosTypeBosdynMissionApiMsgsSpotCamLedBrightnessesEntry[];
}

export interface IRosTypeBosdynMissionApiMsgsSpotCamLedBrightnessesEntry {
  key: number;
  value: number;
}

export interface IRosTypeBosdynMissionApiMsgsSpotCamPtz {
  service_name: string;
  host: string;
  ptz_position: IRosTypeBosdynSpotCamApiMsgsPtzPosition;
  adjust_parameters: IRosTypeBosdynMissionApiMsgsSpotCamPtzAdjustParameters;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsSpotCamPtzConst {
  PTZ_POSITION_FIELD_SET = 4,
  ADJUST_PARAMETERS_FIELD_SET = 8,
}

export interface IRosTypeBosdynMissionApiMsgsSpotCamPtzAdjustParameters {
  localization_varname: string;
  waypoint_id: string;
  waypoint_tform_body: IRosTypeGeometryMsgsPose;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsSpotCamPtzAdjustParametersConst {
  WAYPOINT_TFORM_BODY_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsSpotCamResetAutofocus {
  service_name: string;
  host: string;
}

export interface IRosTypeBosdynMissionApiMsgsSpotCamStoreMedia {
  service_name: string;
  host: string;
  camera: IRosTypeBosdynSpotCamApiMsgsCamera;
  type: IRosTypeBosdynSpotCamApiMsgsLogpointRecordType;
  tag: string;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsSpotCamStoreMediaConst {
  CAMERA_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsState {
  questions: IRosTypeBosdynMissionApiMsgsQuestion[];
  answered_questions: IRosTypeBosdynMissionApiMsgsStateAnsweredQuestion[];
  history: IRosTypeBosdynMissionApiMsgsStateNodeStatesAtTick[];
  status: IRosTypeBosdynMissionApiMsgsStateStatus;
  error: string;
  tick_counter: number;
  mission_id: number;
}

export interface IRosTypeBosdynMissionApiMsgsStateAnsweredQuestion {
  question: IRosTypeBosdynMissionApiMsgsQuestion;
  accepted_answer: IRosTypeBosdynMissionApiMsgsStateAnsweredQuestionOneOfAcceptedAnswer;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsStateAnsweredQuestionConst {
  QUESTION_FIELD_SET = 1,
}

export interface IRosTypeBosdynMissionApiMsgsStateAnsweredQuestionOneOfAcceptedAnswer {
  accepted_answer_code: number;
  custom_params: IRosTypeBosdynApiMsgsDictParam;
  accepted_answer_choice: number;
  which: number;
}

export enum IRosTypeBosdynMissionApiMsgsStateAnsweredQuestionOneOfAcceptedAnswerConst {
  ACCEPTED_ANSWER_NOT_SET = 0,
  ACCEPTED_ANSWER_ACCEPTED_ANSWER_CODE_SET = 1,
  ACCEPTED_ANSWER_CUSTOM_PARAMS_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsStateNodeStatesAtTick {
  tick_counter: number;
  tick_start_timestamp: { sec: number; nanosec: number };
  node_states: IRosTypeBosdynMissionApiMsgsStateNodeStatesAtTickNodeState[];
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsStateNodeStatesAtTickConst {
  TICK_START_TIMESTAMP_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsStateNodeStatesAtTickNodeState {
  result: IRosTypeBosdynMissionApiMsgsResult;
  error: string;
  id: number;
  blackboard: IRosTypeBosdynMissionApiMsgsStateNodeStatesAtTickNodeStateBlackboardState;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsStateNodeStatesAtTickNodeStateConst {
  BLACKBOARD_FIELD_SET = 8,
}

export interface IRosTypeBosdynMissionApiMsgsStateNodeStatesAtTickNodeStateBlackboardState {
  variables: IRosTypeBosdynMissionApiMsgsKeyValue[];
}

export interface IRosTypeBosdynMissionApiMsgsStateStatus {
  value: number;
}

export enum IRosTypeBosdynMissionApiMsgsStateStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_FAILURE = 1,
  STATUS_RUNNING = 2,
  STATUS_SUCCESS = 3,
  STATUS_PAUSED = 4,
  STATUS_ERROR = 5,
  STATUS_NONE = 6,
  STATUS_STOPPED = 7,
}

export interface IRosTypeBosdynMissionApiMsgsStopMissionRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsStopMissionRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsStopMissionResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynMissionApiMsgsStopMissionResponseStatus;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsStopMissionResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsStopMissionResponseStatus {
  value: number;
}

export enum IRosTypeBosdynMissionApiMsgsStopMissionResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NO_MISSION_PLAYING = 2,
}

export interface IRosTypeBosdynMissionApiMsgsStopRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  session_id: string;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsStopRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMissionApiMsgsStopResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynMissionApiMsgsStopResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsStopResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMissionApiMsgsStopResponseStatus {
  value: number;
}

export enum IRosTypeBosdynMissionApiMsgsStopResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_SESSION_ID = 2,
}

export interface IRosTypeBosdynMissionApiMsgsStoreMetadata {
  service_name: string;
  host: string;
  acquire_data_request_name: string;
  metadata_channel: string;
  data: IRosTypeBosdynMissionApiMsgsStoreMetadataOneOfData;
}

export interface IRosTypeBosdynMissionApiMsgsStoreMetadataOneOfData {
  metadata_name: string;
  metadata_json: IRosTypeProto2RosStruct;
  data_choice: number;
  which: number;
}

export enum IRosTypeBosdynMissionApiMsgsStoreMetadataOneOfDataConst {
  DATA_NOT_SET = 0,
  DATA_METADATA_NAME_SET = 1,
  DATA_METADATA_JSON_SET = 2,
}

export interface IRosTypeBosdynMissionApiMsgsSwitch {
  pivot_value: IRosTypeBosdynMissionApiMsgsValue;
  always_restart: boolean;
  int_children: IRosTypeProto2RosAny[];
  default_child: IRosTypeProto2RosAny;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsSwitchConst {
  PIVOT_VALUE_FIELD_SET = 1,
  DEFAULT_CHILD_FIELD_SET = 8,
}

export interface IRosTypeBosdynMissionApiMsgsSwitchIntChildrenEntry {
  key: number;
  value: IRosTypeBosdynMissionApiMsgsNode;
}

export interface IRosTypeBosdynMissionApiMsgsTeardownSessionRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  session_id: string;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsTeardownSessionRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMissionApiMsgsTeardownSessionResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynMissionApiMsgsTeardownSessionResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsTeardownSessionResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynMissionApiMsgsTeardownSessionResponseStatus {
  value: number;
}

export enum IRosTypeBosdynMissionApiMsgsTeardownSessionResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_SESSION_ID = 2,
}

export interface IRosTypeBosdynMissionApiMsgsTickRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  session_id: string;
  leases: IRosTypeBosdynApiMsgsLease[];
  inputs: IRosTypeBosdynMissionApiMsgsKeyValue[];
  params: IRosTypeBosdynApiMsgsDictParam;
  group_name: string;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsTickRequestConst {
  HEADER_FIELD_SET = 1,
  PARAMS_FIELD_SET = 16,
}

export interface IRosTypeBosdynMissionApiMsgsTickResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynMissionApiMsgsTickResponseStatus;
  missing_lease_resources: string[];
  lease_use_results: IRosTypeBosdynApiMsgsLeaseUseResult[];
  missing_inputs: IRosTypeBosdynMissionApiMsgsVariableDeclaration[];
  error_message: string;
  custom_param_error: IRosTypeBosdynApiMsgsCustomParamError;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsTickResponseConst {
  HEADER_FIELD_SET = 1,
  CUSTOM_PARAM_ERROR_FIELD_SET = 64,
}

export interface IRosTypeBosdynMissionApiMsgsTickResponseStatus {
  value: number;
}

export enum IRosTypeBosdynMissionApiMsgsTickResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_FAILURE = 1,
  STATUS_RUNNING = 2,
  STATUS_SUCCESS = 3,
  STATUS_INVALID_SESSION_ID = 4,
  STATUS_MISSING_LEASES = 5,
  STATUS_MISSING_INPUTS = 6,
  STATUS_CUSTOM_PARAMS_ERROR = 7,
}

export interface IRosTypeBosdynMissionApiMsgsUserData {
  id: string;
  bytestring: number[];
  source_representation: IRosTypeProto2RosAnyProto;
  has_field: number;
}

export enum IRosTypeBosdynMissionApiMsgsUserDataConst {
  SOURCE_REPRESENTATION_FIELD_SET = 4,
}

export interface IRosTypeBosdynMissionApiMsgsValue {
  source: IRosTypeBosdynMissionApiMsgsValueOneOfSource;
}

export interface IRosTypeBosdynMissionApiMsgsValueOneOfSource {
  constant: IRosTypeBosdynMissionApiMsgsConstantValue;
  runtime_var: IRosTypeBosdynMissionApiMsgsVariableDeclaration;
  parameter: IRosTypeBosdynMissionApiMsgsVariableDeclaration;
  source_choice: number;
  which: number;
}

export enum IRosTypeBosdynMissionApiMsgsValueOneOfSourceConst {
  SOURCE_NOT_SET = 0,
  SOURCE_CONSTANT_SET = 1,
  SOURCE_RUNTIME_VAR_SET = 2,
  SOURCE_PARAMETER_SET = 3,
}

export interface IRosTypeBosdynMissionApiMsgsVariableDeclaration {
  name: string;
  type: IRosTypeBosdynMissionApiMsgsVariableDeclarationType;
}

export interface IRosTypeBosdynMissionApiMsgsVariableDeclarationType {
  value: number;
}

export enum IRosTypeBosdynMissionApiMsgsVariableDeclarationTypeConst {
  TYPE_UNKNOWN = 0,
  TYPE_FLOAT = 1,
  TYPE_STRING = 2,
  TYPE_INT = 3,
  TYPE_BOOL = 4,
  TYPE_MESSAGE = 5,
}

export interface IRosTypeBosdynSpotApiMsgsActiveMove {
  move: IRosTypeBosdynSpotApiMsgsMoveParams;
  command_limits: IRosTypeBosdynSpotApiMsgsActiveMoveOneOfCommandLimits;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsActiveMoveConst {
  MOVE_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsActiveMoveOneOfCommandLimits {
  custom_gait_command_limits: IRosTypeBosdynSpotApiMsgsCustomGaitCommandLimits;
  command_limits_choice: number;
  which: number;
}

export enum IRosTypeBosdynSpotApiMsgsActiveMoveOneOfCommandLimitsConst {
  COMMAND_LIMITS_NOT_SET = 0,
  COMMAND_LIMITS_CUSTOM_GAIT_COMMAND_LIMITS_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsAnimateArm {
  arm: IRosTypeBosdynSpotApiMsgsAnimateArmOneOfArm;
}

export interface IRosTypeBosdynSpotApiMsgsAnimateArmHandPose {
  position: IRosTypeBosdynApiMsgsVec3Value;
  orientation: IRosTypeBosdynSpotApiMsgsAnimateArmHandPoseOneOfOrientation;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsAnimateArmHandPoseConst {
  POSITION_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsAnimateArmHandPoseOneOfOrientation {
  euler_angles: IRosTypeBosdynSpotApiMsgsEulerZyxValue;
  quaternion: IRosTypeGeometryMsgsQuaternion;
  orientation_choice: number;
  which: number;
}

export enum IRosTypeBosdynSpotApiMsgsAnimateArmHandPoseOneOfOrientationConst {
  ORIENTATION_NOT_SET = 0,
  ORIENTATION_EULER_ANGLES_SET = 1,
  ORIENTATION_QUATERNION_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsAnimateArmOneOfArm {
  joint_angles: IRosTypeBosdynSpotApiMsgsArmJointAngles;
  hand_pose: IRosTypeBosdynSpotApiMsgsAnimateArmHandPose;
  arm_choice: number;
  which: number;
}

export enum IRosTypeBosdynSpotApiMsgsAnimateArmOneOfArmConst {
  ARM_NOT_SET = 0,
  ARM_JOINT_ANGLES_SET = 1,
  ARM_HAND_POSE_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsAnimateBody {
  position: IRosTypeBosdynSpotApiMsgsAnimateBodyOneOfPosition;
  orientation: IRosTypeBosdynSpotApiMsgsAnimateBodyOneOfOrientation;
}

export interface IRosTypeBosdynSpotApiMsgsAnimateBodyOneOfOrientation {
  euler_angles: IRosTypeBosdynSpotApiMsgsEulerZyxValue;
  quaternion: IRosTypeGeometryMsgsQuaternion;
  orientation_choice: number;
  which: number;
}

export enum IRosTypeBosdynSpotApiMsgsAnimateBodyOneOfOrientationConst {
  ORIENTATION_NOT_SET = 0,
  ORIENTATION_EULER_ANGLES_SET = 1,
  ORIENTATION_QUATERNION_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsAnimateBodyOneOfPosition {
  body_pos: IRosTypeBosdynApiMsgsVec3Value;
  com_pos: IRosTypeBosdynApiMsgsVec3Value;
  position_choice: number;
  which: number;
}

export enum IRosTypeBosdynSpotApiMsgsAnimateBodyOneOfPositionConst {
  POSITION_NOT_SET = 0,
  POSITION_BODY_POS_SET = 1,
  POSITION_COM_POS_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsAnimateGripper {
  gripper_angle: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsAnimateGripperConst {
  GRIPPER_ANGLE_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsAnimateLegs {
  fl: IRosTypeBosdynSpotApiMsgsAnimateSingleLeg;
  fr: IRosTypeBosdynSpotApiMsgsAnimateSingleLeg;
  hl: IRosTypeBosdynSpotApiMsgsAnimateSingleLeg;
  hr: IRosTypeBosdynSpotApiMsgsAnimateSingleLeg;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsAnimateLegsConst {
  FL_FIELD_SET = 1,
  FR_FIELD_SET = 2,
  HL_FIELD_SET = 4,
  HR_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotApiMsgsAnimateParams {
  animation_name: string;
  body_entry_slices: IRosTypeStdMsgsFloat64;
  body_exit_slices: IRosTypeStdMsgsFloat64;
  translation_multiplier: IRosTypeBosdynApiMsgsVec3Value;
  rotation_multiplier: IRosTypeBosdynSpotApiMsgsEulerZyxValue;
  arm_entry_slices: IRosTypeStdMsgsFloat64;
  shoulder_0_offset: IRosTypeStdMsgsFloat64;
  shoulder_1_offset: IRosTypeStdMsgsFloat64;
  elbow_0_offset: IRosTypeStdMsgsFloat64;
  elbow_1_offset: IRosTypeStdMsgsFloat64;
  wrist_0_offset: IRosTypeStdMsgsFloat64;
  wrist_1_offset: IRosTypeStdMsgsFloat64;
  gripper_offset: IRosTypeStdMsgsFloat64;
  speed: IRosTypeStdMsgsFloat64;
  offset_slices: IRosTypeStdMsgsFloat64;
  gripper_multiplier: IRosTypeStdMsgsFloat64;
  gripper_strength_fraction: IRosTypeStdMsgsFloat64;
  arm_dance_frame_id: IRosTypeStdMsgsInt32;
  body_tracking_stiffness: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsAnimateParamsConst {
  BODY_ENTRY_SLICES_FIELD_SET = 2,
  BODY_EXIT_SLICES_FIELD_SET = 4,
  TRANSLATION_MULTIPLIER_FIELD_SET = 8,
  ROTATION_MULTIPLIER_FIELD_SET = 16,
  ARM_ENTRY_SLICES_FIELD_SET = 32,
  SHOULDER_0_OFFSET_FIELD_SET = 64,
  SHOULDER_1_OFFSET_FIELD_SET = 128,
  ELBOW_0_OFFSET_FIELD_SET = 256,
  ELBOW_1_OFFSET_FIELD_SET = 512,
  WRIST_0_OFFSET_FIELD_SET = 1024,
  WRIST_1_OFFSET_FIELD_SET = 2048,
  GRIPPER_OFFSET_FIELD_SET = 4096,
  SPEED_FIELD_SET = 8192,
  OFFSET_SLICES_FIELD_SET = 16384,
  GRIPPER_MULTIPLIER_FIELD_SET = 32768,
  GRIPPER_STRENGTH_FRACTION_FIELD_SET = 65536,
  ARM_DANCE_FRAME_ID_FIELD_SET = 131072,
  BODY_TRACKING_STIFFNESS_FIELD_SET = 262144,
}

export interface IRosTypeBosdynSpotApiMsgsAnimateSingleLeg {
  stance: IRosTypeStdMsgsBool;
  leg: IRosTypeBosdynSpotApiMsgsAnimateSingleLegOneOfLeg;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsAnimateSingleLegConst {
  STANCE_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsAnimateSingleLegOneOfLeg {
  joint_angles: IRosTypeBosdynSpotApiMsgsLegJointAngles;
  foot_pos: IRosTypeBosdynApiMsgsVec3Value;
  leg_choice: number;
  which: number;
}

export enum IRosTypeBosdynSpotApiMsgsAnimateSingleLegOneOfLegConst {
  LEG_NOT_SET = 0,
  LEG_JOINT_ANGLES_SET = 1,
  LEG_FOOT_POS_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsAnimatedCycleParams {
  animation_name: IRosTypeStdMsgsString;
  enable_animation_duration: IRosTypeStdMsgsBool;
  enable_leg_timing: IRosTypeStdMsgsBool;
  enable_stance_shape: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsAnimatedCycleParamsConst {
  ANIMATION_NAME_FIELD_SET = 1,
  ENABLE_ANIMATION_DURATION_FIELD_SET = 2,
  ENABLE_LEG_TIMING_FIELD_SET = 4,
  ENABLE_STANCE_SHAPE_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotApiMsgsAnimation {
  name: string;
  animation_keyframes: IRosTypeBosdynSpotApiMsgsAnimationKeyframe[];
  controls_arm: boolean;
  controls_legs: boolean;
  controls_body: boolean;
  controls_gripper: boolean;
  track_swing_trajectories: boolean;
  assume_zero_roll_and_pitch: boolean;
  arm_playback: IRosTypeBosdynSpotApiMsgsAnimationArmPlayback;
  bpm: number;
  retime_to_integer_slices: boolean;
  minimum_parameters: IRosTypeBosdynSpotApiMsgsAnimateParams;
  default_parameters: IRosTypeBosdynSpotApiMsgsAnimateParams;
  maximum_parameters: IRosTypeBosdynSpotApiMsgsAnimateParams;
  truncatable: boolean;
  extendable: boolean;
  neutral_start: boolean;
  precise_steps: boolean;
  precise_timing: boolean;
  timing_adjustability: number;
  arm_required: boolean;
  arm_prohibited: boolean;
  no_looping: boolean;
  starts_sitting: boolean;
  custom_gait_cycle: boolean;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsAnimationConst {
  MINIMUM_PARAMETERS_FIELD_SET = 2048,
  DEFAULT_PARAMETERS_FIELD_SET = 4096,
  MAXIMUM_PARAMETERS_FIELD_SET = 8192,
}

export interface IRosTypeBosdynSpotApiMsgsAnimationArmPlayback {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsAnimationArmPlaybackConst {
  ARM_PLAYBACK_DEFAULT = 0,
  ARM_PLAYBACK_JOINTSPACE = 1,
  ARM_PLAYBACK_WORKSPACE = 2,
  ARM_PLAYBACK_WORKSPACE_DANCE_FRAME = 3,
}

export interface IRosTypeBosdynSpotApiMsgsAnimationKeyframe {
  time: number;
  gripper: IRosTypeBosdynSpotApiMsgsAnimateGripper;
  arm: IRosTypeBosdynSpotApiMsgsAnimateArm;
  body: IRosTypeBosdynSpotApiMsgsAnimateBody;
  legs: IRosTypeBosdynSpotApiMsgsAnimateLegs;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsAnimationKeyframeConst {
  GRIPPER_FIELD_SET = 2,
  ARM_FIELD_SET = 4,
  BODY_FIELD_SET = 8,
  LEGS_FIELD_SET = 16,
}

export interface IRosTypeBosdynSpotApiMsgsAreaCallbackDoorConfig {
  forward_command: IRosTypeBosdynSpotApiMsgsDoorCommandRequest;
  reverse_command: IRosTypeBosdynSpotApiMsgsDoorCommandRequest;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsAreaCallbackDoorConfigConst {
  FORWARD_COMMAND_FIELD_SET = 1,
  REVERSE_COMMAND_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsArmJointAngles {
  shoulder_0: IRosTypeStdMsgsFloat64;
  shoulder_1: IRosTypeStdMsgsFloat64;
  elbow_0: IRosTypeStdMsgsFloat64;
  elbow_1: IRosTypeStdMsgsFloat64;
  wrist_0: IRosTypeStdMsgsFloat64;
  wrist_1: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsArmJointAnglesConst {
  SHOULDER_0_FIELD_SET = 1,
  SHOULDER_1_FIELD_SET = 2,
  ELBOW_0_FIELD_SET = 4,
  ELBOW_1_FIELD_SET = 8,
  WRIST_0_FIELD_SET = 16,
  WRIST_1_FIELD_SET = 32,
}

export interface IRosTypeBosdynSpotApiMsgsArmMoveFrame {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsArmMoveFrameConst {
  ARM_MOVE_FRAME_UNKNOWN = 0,
  ARM_MOVE_FRAME_CENTER_OF_FOOTPRINT = 1,
  ARM_MOVE_FRAME_HAND = 2,
  ARM_MOVE_FRAME_BODY = 3,
  ARM_MOVE_FRAME_SHOULDER = 4,
  ARM_MOVE_FRAME_SHADOW = 5,
  ARM_MOVE_FRAME_DANCE = 6,
}

export interface IRosTypeBosdynSpotApiMsgsArmMoveParams {
  shoulder_0: IRosTypeStdMsgsFloat64;
  shoulder_1: IRosTypeStdMsgsFloat64;
  elbow_0: IRosTypeStdMsgsFloat64;
  elbow_1: IRosTypeStdMsgsFloat64;
  wrist_0: IRosTypeStdMsgsFloat64;
  wrist_1: IRosTypeStdMsgsFloat64;
  easing: IRosTypeBosdynSpotApiMsgsEasing;
  gripper: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsArmMoveParamsConst {
  SHOULDER_0_FIELD_SET = 1,
  SHOULDER_1_FIELD_SET = 2,
  ELBOW_0_FIELD_SET = 4,
  ELBOW_1_FIELD_SET = 8,
  WRIST_0_FIELD_SET = 16,
  WRIST_1_FIELD_SET = 32,
  GRIPPER_FIELD_SET = 128,
}

export interface IRosTypeBosdynSpotApiMsgsBodyControlParams {
  rotation_setting: IRosTypeBosdynSpotApiMsgsBodyControlParamsRotationSetting;
  param: IRosTypeBosdynSpotApiMsgsBodyControlParamsOneOfParam;
}

export interface IRosTypeBosdynSpotApiMsgsBodyControlParamsBodyAssistForManipulation {
  enable_body_yaw_assist: boolean;
  enable_hip_height_assist: boolean;
}

export interface IRosTypeBosdynSpotApiMsgsBodyControlParamsBodyPose {
  root_frame_name: string;
  base_offset_rt_root: IRosTypeBosdynApiMsgsSe3Trajectory;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsBodyControlParamsBodyPoseConst {
  BASE_OFFSET_RT_ROOT_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsBodyControlParamsOneOfParam {
  base_offset_rt_footprint: IRosTypeBosdynApiMsgsSe3Trajectory;
  body_assist_for_manipulation: IRosTypeBosdynSpotApiMsgsBodyControlParamsBodyAssistForManipulation;
  body_pose: IRosTypeBosdynSpotApiMsgsBodyControlParamsBodyPose;
  param_choice: number;
  which: number;
}

export enum IRosTypeBosdynSpotApiMsgsBodyControlParamsOneOfParamConst {
  PARAM_NOT_SET = 0,
  PARAM_BASE_OFFSET_RT_FOOTPRINT_SET = 1,
  PARAM_BODY_ASSIST_FOR_MANIPULATION_SET = 2,
  PARAM_BODY_POSE_SET = 3,
}

export interface IRosTypeBosdynSpotApiMsgsBodyControlParamsRotationSetting {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsBodyControlParamsRotationSettingConst {
  ROTATION_SETTING_UNKNOWN = 0,
  ROTATION_SETTING_OFFSET = 1,
  ROTATION_SETTING_ABSOLUTE = 2,
}

export interface IRosTypeBosdynSpotApiMsgsBodyExternalForceParams {
  external_force_indicator: IRosTypeBosdynSpotApiMsgsBodyExternalForceParamsExternalForceIndicator;
  frame_name: string;
  external_force_override: IRosTypeGeometryMsgsVector3;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsBodyExternalForceParamsConst {
  EXTERNAL_FORCE_OVERRIDE_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsBodyExternalForceParamsExternalForceIndicator {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsBodyExternalForceParamsExternalForceIndicatorConst {
  EXTERNAL_FORCE_NONE = 0,
  EXTERNAL_FORCE_USE_ESTIMATE = 1,
  EXTERNAL_FORCE_USE_OVERRIDE = 2,
}

export interface IRosTypeBosdynSpotApiMsgsBodyHoldParams {
  rotation: IRosTypeBosdynSpotApiMsgsEulerZyxValue;
  translation: IRosTypeBosdynApiMsgsVec3Value;
  entry_slices: IRosTypeStdMsgsFloat64;
  exit_slices: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsBodyHoldParamsConst {
  ROTATION_FIELD_SET = 1,
  TRANSLATION_FIELD_SET = 2,
  ENTRY_SLICES_FIELD_SET = 4,
  EXIT_SLICES_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotApiMsgsBourreeParams {
  velocity: IRosTypeBosdynApiMsgsVec2Value;
  yaw_rate: IRosTypeStdMsgsFloat64;
  stance_length: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsBourreeParamsConst {
  VELOCITY_FIELD_SET = 1,
  YAW_RATE_FIELD_SET = 2,
  STANCE_LENGTH_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsButtCircleParams {
  radius: IRosTypeStdMsgsFloat64;
  beats_per_circle: IRosTypeStdMsgsFloat64;
  number_of_circles: IRosTypeStdMsgsFloat64;
  pivot: IRosTypeBosdynSpotApiMsgsPivot;
  clockwise: IRosTypeStdMsgsBool;
  starting_angle: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsButtCircleParamsConst {
  RADIUS_FIELD_SET = 1,
  BEATS_PER_CIRCLE_FIELD_SET = 2,
  NUMBER_OF_CIRCLES_FIELD_SET = 4,
  CLOCKWISE_FIELD_SET = 16,
  STARTING_ANGLE_FIELD_SET = 32,
}

export interface IRosTypeBosdynSpotApiMsgsCameraCalibrationCommandRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  command: IRosTypeBosdynSpotApiMsgsCameraCalibrationCommandRequestCommand;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsCameraCalibrationCommandRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsCameraCalibrationCommandRequestCommand {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsCameraCalibrationCommandRequestCommandConst {
  COMMAND_UNKNOWN = 0,
  COMMAND_START = 1,
  COMMAND_CANCEL = 2,
}

export interface IRosTypeBosdynSpotApiMsgsCameraCalibrationCommandResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsCameraCalibrationCommandResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsCameraCalibrationFeedbackRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsCameraCalibrationFeedbackRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsCameraCalibrationFeedbackResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynSpotApiMsgsCameraCalibrationFeedbackResponseStatus;
  progress: number;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsCameraCalibrationFeedbackResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsCameraCalibrationFeedbackResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsCameraCalibrationFeedbackResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_PROCESSING = 1,
  STATUS_SUCCESS = 2,
  STATUS_USER_CANCELED = 3,
  STATUS_POWER_ERROR = 4,
  STATUS_LEASE_ERROR = 5,
  STATUS_ROBOT_COMMAND_ERROR = 7,
  STATUS_CALIBRATION_ERROR = 8,
  STATUS_INTERNAL_ERROR = 9,
  STATUS_CAMERA_FOCUS_ERROR = 14,
  STATUS_TARGET_NOT_CENTERED = 6,
  STATUS_TARGET_NOT_IN_VIEW = 11,
  STATUS_TARGET_NOT_GRAVITY_ALIGNED = 12,
  STATUS_TARGET_UPSIDE_DOWN = 13,
  STATUS_NEVER_RUN = 10,
  STATUS_CAMERA_NOT_DETECTED = 15,
  STATUS_INTRINSIC_WRITE_FAILED = 16,
  STATUS_EXTRINSIC_WRITE_FAILED = 17,
  STATUS_CALIBRATION_VERIFICATION_FAILED = 18,
}

export interface IRosTypeBosdynSpotApiMsgsChickenHeadParams {
  bob_magnitude: IRosTypeBosdynApiMsgsVec3Value;
  beats_per_cycle: IRosTypeStdMsgsInt32;
  follow: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsChickenHeadParamsConst {
  BOB_MAGNITUDE_FIELD_SET = 1,
  BEATS_PER_CYCLE_FIELD_SET = 2,
  FOLLOW_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsChoreographerDisplayInfo {
  color: IRosTypeBosdynSpotApiMsgsChoreographerDisplayInfoColor;
  markers: number[];
  description: string;
  image: string;
  category: IRosTypeBosdynSpotApiMsgsChoreographerDisplayInfoCategory;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsChoreographerDisplayInfoConst {
  COLOR_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsChoreographerDisplayInfoCategory {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsChoreographerDisplayInfoCategoryConst {
  CATEGORY_UNKNOWN = 0,
  CATEGORY_BODY = 1,
  CATEGORY_STEP = 2,
  CATEGORY_DYNAMIC = 3,
  CATEGORY_TRANSITION = 4,
  CATEGORY_KNEEL = 5,
  CATEGORY_ARM = 6,
  CATEGORY_ANIMATION = 7,
  CATEGORY_MPC = 8,
  CATEGORY_LIGHTS = 9,
  CATEGORY_ANNOTATIONS = 10,
}

export interface IRosTypeBosdynSpotApiMsgsChoreographerDisplayInfoColor {
  r: number;
  g: number;
  b: number;
  a: number;
}

export interface IRosTypeBosdynSpotApiMsgsChoreographerSave {
  choreography_sequence: IRosTypeBosdynSpotApiMsgsChoreographySequence;
  music_file: string;
  music_start_slice: number;
  choreography_start_slice: number;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsChoreographerSaveConst {
  CHOREOGRAPHY_SEQUENCE_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsChoreographyCommandRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  commands: IRosTypeBosdynSpotApiMsgsMoveCommand[];
  lease: IRosTypeBosdynApiMsgsLease;
  command_end_time: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsChoreographyCommandRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 4,
  COMMAND_END_TIME_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotApiMsgsChoreographyCommandResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  status: IRosTypeBosdynSpotApiMsgsChoreographyCommandResponseStatus[];
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsChoreographyCommandResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsChoreographyCommandResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsChoreographyCommandResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_ACCEPTED_WITH_MODIFICATION = 2,
  STATUS_LEASE_ERROR = 3,
  STATUS_NO_MATCHING_MOVE = 4,
  STATUS_INVALID_COMMAND = 5,
  STATUS_ALREADY_EXPIRED = 6,
}

export interface IRosTypeBosdynSpotApiMsgsChoreographyInfo {
  labels: string[];
}

export interface IRosTypeBosdynSpotApiMsgsChoreographySequence {
  name: string;
  slices_per_minute: number;
  moves: IRosTypeBosdynSpotApiMsgsMoveParams[];
  choreography_info: IRosTypeBosdynSpotApiMsgsChoreographyInfo;
  entrance_state: IRosTypeBosdynSpotApiMsgsMoveInfoTransitionState;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsChoreographySequenceConst {
  CHOREOGRAPHY_INFO_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotApiMsgsChoreographyStateLog {
  key_frames: IRosTypeBosdynSpotApiMsgsLoggedStateKeyFrame[];
}

export interface IRosTypeBosdynSpotApiMsgsChoreographyStatusRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsChoreographyStatusRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsChoreographyStatusResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynSpotApiMsgsChoreographyStatusResponseStatus;
  execution_id: number;
  current_slice: number;
  active_moves: IRosTypeBosdynSpotApiMsgsActiveMove[];
  sequence_slices: number;
  sequence_slices_per_minute: number;
  validity_time: { sec: number; nanosec: number };
  sequence_name: string;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsChoreographyStatusResponseConst {
  HEADER_FIELD_SET = 1,
  VALIDITY_TIME_FIELD_SET = 128,
}

export interface IRosTypeBosdynSpotApiMsgsChoreographyStatusResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsChoreographyStatusResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_DANCING = 1,
  STATUS_COMPLETED_SEQUENCE = 2,
  STATUS_PREPPING = 3,
  STATUS_WAITING_FOR_START_TIME = 4,
  STATUS_VALIDATING = 5,
  STATUS_INTERRUPTED = 6,
  STATUS_FALLEN = 7,
  STATUS_POWERED_OFF = 8,
  STATUS_OTHER = 9,
}

export interface IRosTypeBosdynSpotApiMsgsChoreographyTimeAdjustRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  override_start_time: { sec: number; nanosec: number };
  acceptable_time_difference: { sec: number; nanosec: number };
  validity_time: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsChoreographyTimeAdjustRequestConst {
  HEADER_FIELD_SET = 1,
  OVERRIDE_START_TIME_FIELD_SET = 2,
  ACCEPTABLE_TIME_DIFFERENCE_FIELD_SET = 4,
  VALIDITY_TIME_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotApiMsgsChoreographyTimeAdjustResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynSpotApiMsgsChoreographyTimeAdjustResponseStatus;
  warnings: string[];
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsChoreographyTimeAdjustResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsChoreographyTimeAdjustResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsChoreographyTimeAdjustResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_BEFORE_CURRENT_TIME = 2,
  STATUS_EXCEEDS_VALIDITY_TIME = 3,
  STATUS_OVERRIDE_TIME_UNSET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsClapParams {
  direction: IRosTypeBosdynApiMsgsVec3Value;
  location: IRosTypeBosdynApiMsgsVec3Value;
  speed: IRosTypeStdMsgsFloat64;
  clap_distance: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsClapParamsConst {
  DIRECTION_FIELD_SET = 1,
  LOCATION_FIELD_SET = 2,
  SPEED_FIELD_SET = 4,
  CLAP_DISTANCE_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotApiMsgsClearAllSequenceFilesRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsClearAllSequenceFilesRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsClearAllSequenceFilesResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynSpotApiMsgsClearAllSequenceFilesResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsClearAllSequenceFilesResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsClearAllSequenceFilesResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsClearAllSequenceFilesResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_FAILED_TO_DELETE = 2,
}

export interface IRosTypeBosdynSpotApiMsgsColor {
  red: IRosTypeStdMsgsFloat64;
  green: IRosTypeStdMsgsFloat64;
  blue: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsColorConst {
  RED_FIELD_SET = 1,
  GREEN_FIELD_SET = 2,
  BLUE_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsCrawlParams {
  swing_slices: IRosTypeStdMsgsFloat64;
  velocity: IRosTypeBosdynApiMsgsVec2Value;
  stance_width: IRosTypeStdMsgsFloat64;
  stance_length: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsCrawlParamsConst {
  SWING_SLICES_FIELD_SET = 1,
  VELOCITY_FIELD_SET = 2,
  STANCE_WIDTH_FIELD_SET = 4,
  STANCE_LENGTH_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotApiMsgsCustomGaitCommand {
  drive_velocity_body: IRosTypeBosdynApiMsgsSe2Velocity;
  finished: boolean;
  body_translation_offset: IRosTypeGeometryMsgsVector3;
  body_orientation_offset: IRosTypeBosdynSpotApiMsgsEulerZyx;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsCustomGaitCommandConst {
  DRIVE_VELOCITY_BODY_FIELD_SET = 1,
  BODY_TRANSLATION_OFFSET_FIELD_SET = 4,
  BODY_ORIENTATION_OFFSET_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotApiMsgsCustomGaitCommandLimits {
  maximum_drive_velocity_body: IRosTypeBosdynApiMsgsSe2Velocity;
  maximum_body_translation_offset: IRosTypeGeometryMsgsVector3;
  maximum_body_orientation_offset: IRosTypeBosdynSpotApiMsgsEulerZyx;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsCustomGaitCommandLimitsConst {
  MAXIMUM_DRIVE_VELOCITY_BODY_FIELD_SET = 1,
  MAXIMUM_BODY_TRANSLATION_OFFSET_FIELD_SET = 2,
  MAXIMUM_BODY_ORIENTATION_OFFSET_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsCustomGaitParams {
  max_velocity: IRosTypeBosdynApiMsgsVec2Value;
  max_yaw_rate: IRosTypeStdMsgsFloat64;
  acceleration_scaling: IRosTypeStdMsgsFloat64;
  cycle_duration: IRosTypeStdMsgsFloat64;
  fl_swing: IRosTypeBosdynSpotApiMsgsSwingPhases;
  two_fl_swings: IRosTypeStdMsgsBool;
  second_fl_swing: IRosTypeBosdynSpotApiMsgsSwingPhases;
  fr_swing: IRosTypeBosdynSpotApiMsgsSwingPhases;
  two_fr_swings: IRosTypeStdMsgsBool;
  second_fr_swing: IRosTypeBosdynSpotApiMsgsSwingPhases;
  hl_swing: IRosTypeBosdynSpotApiMsgsSwingPhases;
  two_hl_swings: IRosTypeStdMsgsBool;
  second_hl_swing: IRosTypeBosdynSpotApiMsgsSwingPhases;
  hr_swing: IRosTypeBosdynSpotApiMsgsSwingPhases;
  two_hr_swings: IRosTypeStdMsgsBool;
  second_hr_swing: IRosTypeBosdynSpotApiMsgsSwingPhases;
  show_stance_shape: IRosTypeStdMsgsBool;
  stance_shape: IRosTypeBosdynSpotApiMsgsStanceShape;
  com_height: IRosTypeStdMsgsFloat64;
  body_translation_offset: IRosTypeBosdynApiMsgsVec3Value;
  body_rotation_offset: IRosTypeBosdynSpotApiMsgsEulerZyxValue;
  low_speed_body_fraction: IRosTypeStdMsgsFloat64;
  general_swing_params: IRosTypeBosdynSpotApiMsgsSwingParams;
  use_fl_swing_params: IRosTypeStdMsgsBool;
  fl_swing_params: IRosTypeBosdynSpotApiMsgsSwingParams;
  use_fr_swing_params: IRosTypeStdMsgsBool;
  fr_swing_params: IRosTypeBosdynSpotApiMsgsSwingParams;
  use_hl_swing_params: IRosTypeStdMsgsBool;
  hl_swing_params: IRosTypeBosdynSpotApiMsgsSwingParams;
  use_hr_swing_params: IRosTypeStdMsgsBool;
  hr_swing_params: IRosTypeBosdynSpotApiMsgsSwingParams;
  stand_in_place: IRosTypeStdMsgsBool;
  standard_final_stance: IRosTypeStdMsgsBool;
  show_stability_params: IRosTypeStdMsgsBool;
  mu: IRosTypeStdMsgsFloat64;
  timing_stiffness: IRosTypeStdMsgsFloat64;
  step_position_stiffness: IRosTypeStdMsgsFloat64;
  enable_perception_obstacle_avoidance: IRosTypeStdMsgsBool;
  obstacle_avoidance_padding: IRosTypeStdMsgsFloat64;
  enable_perception_terrain_height: IRosTypeStdMsgsBool;
  enable_perception_step_placement: IRosTypeStdMsgsBool;
  maximum_stumble_distance: IRosTypeStdMsgsFloat64;
  trip_sensitivity: IRosTypeStdMsgsFloat64;
  animated_cycle_params: IRosTypeBosdynSpotApiMsgsAnimatedCycleParams;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsCustomGaitParamsConst {
  MAX_VELOCITY_FIELD_SET = 1,
  MAX_YAW_RATE_FIELD_SET = 2,
  ACCELERATION_SCALING_FIELD_SET = 4,
  CYCLE_DURATION_FIELD_SET = 8,
  FL_SWING_FIELD_SET = 16,
  TWO_FL_SWINGS_FIELD_SET = 32,
  SECOND_FL_SWING_FIELD_SET = 64,
  FR_SWING_FIELD_SET = 128,
  TWO_FR_SWINGS_FIELD_SET = 256,
  SECOND_FR_SWING_FIELD_SET = 512,
  HL_SWING_FIELD_SET = 1024,
  TWO_HL_SWINGS_FIELD_SET = 2048,
  SECOND_HL_SWING_FIELD_SET = 4096,
  HR_SWING_FIELD_SET = 8192,
  TWO_HR_SWINGS_FIELD_SET = 16384,
  SECOND_HR_SWING_FIELD_SET = 32768,
  SHOW_STANCE_SHAPE_FIELD_SET = 65536,
  STANCE_SHAPE_FIELD_SET = 131072,
  COM_HEIGHT_FIELD_SET = 262144,
  BODY_TRANSLATION_OFFSET_FIELD_SET = 524288,
  BODY_ROTATION_OFFSET_FIELD_SET = 1048576,
  LOW_SPEED_BODY_FRACTION_FIELD_SET = 2097152,
  GENERAL_SWING_PARAMS_FIELD_SET = 4194304,
  USE_FL_SWING_PARAMS_FIELD_SET = 8388608,
  FL_SWING_PARAMS_FIELD_SET = 16777216,
  USE_FR_SWING_PARAMS_FIELD_SET = 33554432,
  FR_SWING_PARAMS_FIELD_SET = 67108864,
  USE_HL_SWING_PARAMS_FIELD_SET = 134217728,
  HL_SWING_PARAMS_FIELD_SET = 268435456,
  USE_HR_SWING_PARAMS_FIELD_SET = 536870912,
  HR_SWING_PARAMS_FIELD_SET = 1073741824,
  STAND_IN_PLACE_FIELD_SET = 2147483648,
  STANDARD_FINAL_STANCE_FIELD_SET = 4294967296,
  SHOW_STABILITY_PARAMS_FIELD_SET = 8589934592,
  MU_FIELD_SET = 17179869184,
  TIMING_STIFFNESS_FIELD_SET = 34359738368,
  STEP_POSITION_STIFFNESS_FIELD_SET = 68719476736,
  ENABLE_PERCEPTION_OBSTACLE_AVOIDANCE_FIELD_SET = 137438953472,
  OBSTACLE_AVOIDANCE_PADDING_FIELD_SET = 274877906944,
  ENABLE_PERCEPTION_TERRAIN_HEIGHT_FIELD_SET = 549755813888,
  ENABLE_PERCEPTION_STEP_PLACEMENT_FIELD_SET = 1099511627776,
  MAXIMUM_STUMBLE_DISTANCE_FIELD_SET = 2199023255552,
  TRIP_SENSITIVITY_FIELD_SET = 4398046511104,
  ANIMATED_CYCLE_PARAMS_FIELD_SET = 8796093022208,
}

export interface IRosTypeBosdynSpotApiMsgsDeleteSequenceRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  sequence_name: string;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsDeleteSequenceRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsDeleteSequenceResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynSpotApiMsgsDeleteSequenceResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsDeleteSequenceResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsDeleteSequenceResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsDeleteSequenceResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_UNKNOWN_SEQUENCE = 2,
  STATUS_ALREADY_TEMPORARY = 3,
  STATUS_PERMANENT_SEQUENCE = 4,
}

export interface IRosTypeBosdynSpotApiMsgsDepthPlaneSpotCheckResult {
  status: IRosTypeBosdynSpotApiMsgsDepthPlaneSpotCheckResultStatus;
  severity_score: number;
}

export interface IRosTypeBosdynSpotApiMsgsDepthPlaneSpotCheckResultStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsDepthPlaneSpotCheckResultStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_WARNING = 2,
  STATUS_ERROR = 3,
}

export interface IRosTypeBosdynSpotApiMsgsDoorCommandAutoGraspCommand {
  frame_name: string;
  search_ray_start_in_frame: IRosTypeGeometryMsgsVector3;
  search_ray_end_in_frame: IRosTypeGeometryMsgsVector3;
  hinge_side: IRosTypeBosdynSpotApiMsgsDoorCommandHingeSide;
  swing_direction: IRosTypeBosdynSpotApiMsgsDoorCommandSwingDirection;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsDoorCommandAutoGraspCommandConst {
  SEARCH_RAY_START_IN_FRAME_FIELD_SET = 2,
  SEARCH_RAY_END_IN_FRAME_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsDoorCommandAutoPushCommand {
  frame_name: string;
  push_point_in_frame: IRosTypeGeometryMsgsVector3;
  hinge_side: IRosTypeBosdynSpotApiMsgsDoorCommandHingeSide;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsDoorCommandAutoPushCommandConst {
  PUSH_POINT_IN_FRAME_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsDoorCommandFeedback {
  status: IRosTypeBosdynSpotApiMsgsDoorCommandFeedbackStatus;
  distance_past_threshold: number;
}

export interface IRosTypeBosdynSpotApiMsgsDoorCommandFeedbackStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsDoorCommandFeedbackStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_COMPLETED = 1,
  STATUS_IN_PROGRESS = 2,
  STATUS_STALLED = 3,
  STATUS_NOT_DETECTED = 4,
}

export interface IRosTypeBosdynSpotApiMsgsDoorCommandHandleType {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsDoorCommandHandleTypeConst {
  HANDLE_TYPE_UNKNOWN = 0,
  HANDLE_TYPE_LEVER = 1,
  HANDLE_TYPE_KNOB = 2,
  HANDLE_TYPE_FIXED_GRASP = 3,
}

export interface IRosTypeBosdynSpotApiMsgsDoorCommandHingeSide {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsDoorCommandHingeSideConst {
  HINGE_SIDE_UNKNOWN = 0,
  HINGE_SIDE_LEFT = 1,
  HINGE_SIDE_RIGHT = 2,
}

export interface IRosTypeBosdynSpotApiMsgsDoorCommandRequest {
  command: IRosTypeBosdynSpotApiMsgsDoorCommandRequestOneOfCommand;
}

export interface IRosTypeBosdynSpotApiMsgsDoorCommandRequestOneOfCommand {
  auto_grasp_command: IRosTypeBosdynSpotApiMsgsDoorCommandAutoGraspCommand;
  warmstart_command: IRosTypeBosdynSpotApiMsgsDoorCommandWarmstartCommand;
  auto_push_command: IRosTypeBosdynSpotApiMsgsDoorCommandAutoPushCommand;
  command_choice: number;
  which: number;
}

export enum IRosTypeBosdynSpotApiMsgsDoorCommandRequestOneOfCommandConst {
  COMMAND_NOT_SET = 0,
  COMMAND_AUTO_GRASP_COMMAND_SET = 1,
  COMMAND_WARMSTART_COMMAND_SET = 2,
  COMMAND_AUTO_PUSH_COMMAND_SET = 3,
}

export interface IRosTypeBosdynSpotApiMsgsDoorCommandSwingDirection {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsDoorCommandSwingDirectionConst {
  SWING_DIRECTION_UNKNOWN = 0,
  SWING_DIRECTION_INSWING = 1,
  SWING_DIRECTION_PULL = 1,
  SWING_DIRECTION_OUTSWING = 2,
  SWING_DIRECTION_PUSH = 2,
}

export interface IRosTypeBosdynSpotApiMsgsDoorCommandWarmstartCommand {
  hinge_side: IRosTypeBosdynSpotApiMsgsDoorCommandHingeSide;
  swing_direction: IRosTypeBosdynSpotApiMsgsDoorCommandSwingDirection;
  handle_type: IRosTypeBosdynSpotApiMsgsDoorCommandHandleType;
}

export interface IRosTypeBosdynSpotApiMsgsDownloadRobotStateLogRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  log_type: IRosTypeBosdynSpotApiMsgsDownloadRobotStateLogRequestLogType;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsDownloadRobotStateLogRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsDownloadRobotStateLogRequestLogType {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsDownloadRobotStateLogRequestLogTypeConst {
  LOG_TYPE_UNKNOWN = 0,
  LOG_TYPE_MANUAL = 1,
  LOG_TYPE_LAST_CHOREOGRAPHY = 2,
}

export interface IRosTypeBosdynSpotApiMsgsDownloadRobotStateLogResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynSpotApiMsgsDownloadRobotStateLogResponseStatus;
  chunk: IRosTypeBosdynApiMsgsDataChunk;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsDownloadRobotStateLogResponseConst {
  HEADER_FIELD_SET = 1,
  CHUNK_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsDownloadRobotStateLogResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsDownloadRobotStateLogResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NO_RECORDED_INFORMATION = 2,
  STATUS_INCOMPLETE_DATA = 3,
}

export interface IRosTypeBosdynSpotApiMsgsEasing {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsEasingConst {
  EASING_UNKNOWN = 0,
  EASING_LINEAR = 1,
  EASING_QUADRATIC_INPUT = 2,
  EASING_QUADRATIC_OUTPUT = 3,
  EASING_QUADRATIC_IN_OUT = 4,
  EASING_CUBIC_INPUT = 5,
  EASING_CUBIC_OUTPUT = 6,
  EASING_CUBIC_IN_OUT = 7,
  EASING_EXPONENTIAL_INPUT = 8,
  EASING_EXPONENTIAL_OUTPUT = 9,
  EASING_EXPONENTIAL_IN_OUT = 10,
}

export interface IRosTypeBosdynSpotApiMsgsEulerRateZyxValue {
  roll: IRosTypeStdMsgsFloat64;
  pitch: IRosTypeStdMsgsFloat64;
  yaw: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsEulerRateZyxValueConst {
  ROLL_FIELD_SET = 1,
  PITCH_FIELD_SET = 2,
  YAW_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsEulerZyx {
  roll: number;
  pitch: number;
  yaw: number;
}

export interface IRosTypeBosdynSpotApiMsgsEulerZyxValue {
  roll: IRosTypeStdMsgsFloat64;
  pitch: IRosTypeStdMsgsFloat64;
  yaw: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsEulerZyxValueConst {
  ROLL_FIELD_SET = 1,
  PITCH_FIELD_SET = 2,
  YAW_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsExecuteChoreographyRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  choreography_sequence_name: string;
  start_time: { sec: number; nanosec: number };
  choreography_starting_slice: number;
  lease: IRosTypeBosdynApiMsgsLease;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsExecuteChoreographyRequestConst {
  HEADER_FIELD_SET = 1,
  START_TIME_FIELD_SET = 4,
  LEASE_FIELD_SET = 16,
}

export interface IRosTypeBosdynSpotApiMsgsExecuteChoreographyResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  status: IRosTypeBosdynSpotApiMsgsExecuteChoreographyResponseStatus;
  execution_id: number;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsExecuteChoreographyResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsExecuteChoreographyResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsExecuteChoreographyResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_INVALID_UPLOADED_CHOREOGRAPHY = 2,
  STATUS_ROBOT_COMMAND_ISSUES = 3,
  STATUS_LEASE_ERROR = 4,
  STATUS_UNKNOWN_SEQUENCE = 5,
}

export interface IRosTypeBosdynSpotApiMsgsFadeColorParams {
  top_color: IRosTypeBosdynSpotApiMsgsColor;
  bottom_color: IRosTypeBosdynSpotApiMsgsColor;
  fade_in_slices: IRosTypeStdMsgsFloat64;
  fade_out_slices: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsFadeColorParamsConst {
  TOP_COLOR_FIELD_SET = 1,
  BOTTOM_COLOR_FIELD_SET = 2,
  FADE_IN_SLICES_FIELD_SET = 4,
  FADE_OUT_SLICES_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotApiMsgsFidgetStandParams {
  preset: IRosTypeBosdynSpotApiMsgsFidgetStandParamsFidgetPreset;
  min_gaze_pitch: IRosTypeStdMsgsFloat64;
  max_gaze_pitch: IRosTypeStdMsgsFloat64;
  gaze_mean_period: IRosTypeStdMsgsFloat64;
  gaze_center_cfp: IRosTypeBosdynApiMsgsVec3Value;
  shift_mean_period: IRosTypeStdMsgsFloat64;
  shift_max_transition_time: IRosTypeStdMsgsFloat64;
  breath_min_z: IRosTypeStdMsgsFloat64;
  breath_max_z: IRosTypeStdMsgsFloat64;
  breath_max_period: IRosTypeStdMsgsFloat64;
  leg_gesture_mean_period: IRosTypeStdMsgsFloat64;
  gaze_slew_rate: IRosTypeStdMsgsFloat64;
  gaze_position_generation_gain: IRosTypeBosdynApiMsgsVec3Value;
  gaze_roll_generation_gain: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsFidgetStandParamsConst {
  MIN_GAZE_PITCH_FIELD_SET = 2,
  MAX_GAZE_PITCH_FIELD_SET = 4,
  GAZE_MEAN_PERIOD_FIELD_SET = 8,
  GAZE_CENTER_CFP_FIELD_SET = 16,
  SHIFT_MEAN_PERIOD_FIELD_SET = 32,
  SHIFT_MAX_TRANSITION_TIME_FIELD_SET = 64,
  BREATH_MIN_Z_FIELD_SET = 128,
  BREATH_MAX_Z_FIELD_SET = 256,
  BREATH_MAX_PERIOD_FIELD_SET = 512,
  LEG_GESTURE_MEAN_PERIOD_FIELD_SET = 1024,
  GAZE_SLEW_RATE_FIELD_SET = 2048,
  GAZE_POSITION_GENERATION_GAIN_FIELD_SET = 4096,
  GAZE_ROLL_GENERATION_GAIN_FIELD_SET = 8192,
}

export interface IRosTypeBosdynSpotApiMsgsFidgetStandParamsFidgetPreset {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsFidgetStandParamsFidgetPresetConst {
  PRESET_UNKNOWN = 0,
  PRESET_CUSTOM = 1,
  PRESET_INTEREST = 2,
  PRESET_PLAYFUL = 3,
  PRESET_FEAR = 4,
  PRESET_NERVOUS = 5,
  PRESET_EXHAUSTED = 6,
}

export interface IRosTypeBosdynSpotApiMsgsFigure8Params {
  height: IRosTypeStdMsgsFloat64;
  width: IRosTypeStdMsgsFloat64;
  beats_per_cycle: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsFigure8ParamsConst {
  HEIGHT_FIELD_SET = 1,
  WIDTH_FIELD_SET = 2,
  BEATS_PER_CYCLE_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsFootHeightCheckResult {
  status: IRosTypeBosdynSpotApiMsgsFootHeightCheckResultStatus;
  foot_height_error_from_mean: number;
}

export interface IRosTypeBosdynSpotApiMsgsFootHeightCheckResultStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsFootHeightCheckResultStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_WARNING = 2,
  STATUS_ERROR = 3,
}

export interface IRosTypeBosdynSpotApiMsgsFrameSnapshotParams {
  frame_id: IRosTypeStdMsgsInt32;
  fiducial_number: IRosTypeStdMsgsInt32;
  include_front_left_leg: IRosTypeBosdynSpotApiMsgsFrameSnapshotParamsInclusion;
  include_front_right_leg: IRosTypeBosdynSpotApiMsgsFrameSnapshotParamsInclusion;
  include_hind_left_leg: IRosTypeBosdynSpotApiMsgsFrameSnapshotParamsInclusion;
  include_hind_right_leg: IRosTypeBosdynSpotApiMsgsFrameSnapshotParamsInclusion;
  compensated: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsFrameSnapshotParamsConst {
  FRAME_ID_FIELD_SET = 1,
  FIDUCIAL_NUMBER_FIELD_SET = 2,
  COMPENSATED_FIELD_SET = 64,
}

export interface IRosTypeBosdynSpotApiMsgsFrameSnapshotParamsInclusion {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsFrameSnapshotParamsInclusionConst {
  INCLUSION_UNKNOWN = 0,
  INCLUSION_IF_STANCE = 1,
  INCLUSION_INCLUDED = 2,
  INCLUSION_EXCLUDED = 3,
}

export interface IRosTypeBosdynSpotApiMsgsFrontUpParams {
  mirror: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsFrontUpParamsConst {
  MIRROR_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsGetChoreographySequenceRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  sequence_name: string;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsGetChoreographySequenceRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsGetChoreographySequenceResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynSpotApiMsgsGetChoreographySequenceResponseStatus;
  choreography_sequence: IRosTypeBosdynSpotApiMsgsChoreographySequence;
  animated_moves: IRosTypeBosdynSpotApiMsgsAnimation[];
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsGetChoreographySequenceResponseConst {
  HEADER_FIELD_SET = 1,
  CHOREOGRAPHY_SEQUENCE_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsGetChoreographySequenceResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsGetChoreographySequenceResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_UNKNOWN_SEQUENCE = 2,
}

export interface IRosTypeBosdynSpotApiMsgsGotoParams {
  absolute_position: IRosTypeBosdynApiMsgsVec2Value;
  absolute_yaw: IRosTypeStdMsgsFloat64;
  step_position_stiffness: IRosTypeStdMsgsFloat64;
  duty_cycle: IRosTypeStdMsgsFloat64;
  link_to_next: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsGotoParamsConst {
  ABSOLUTE_POSITION_FIELD_SET = 1,
  ABSOLUTE_YAW_FIELD_SET = 2,
  STEP_POSITION_STIFFNESS_FIELD_SET = 4,
  DUTY_CYCLE_FIELD_SET = 8,
  LINK_TO_NEXT_FIELD_SET = 16,
}

export interface IRosTypeBosdynSpotApiMsgsGripperCameraCalibrationCommandRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  command: IRosTypeBosdynSpotApiMsgsGripperCameraCalibrationCommandRequestCommand;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsGripperCameraCalibrationCommandRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsGripperCameraCalibrationCommandRequestCommand {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsGripperCameraCalibrationCommandRequestCommandConst {
  COMMAND_UNKNOWN = 0,
  COMMAND_START = 1,
  COMMAND_CANCEL = 2,
}

export interface IRosTypeBosdynSpotApiMsgsGripperCameraCalibrationCommandResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsGripperCameraCalibrationCommandResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsGripperCameraCalibrationFeedbackRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsGripperCameraCalibrationFeedbackRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsGripperCameraCalibrationFeedbackResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynSpotApiMsgsGripperCameraCalibrationFeedbackResponseStatus;
  progress: number;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsGripperCameraCalibrationFeedbackResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotApiMsgsGripperCameraCalibrationFeedbackResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsGripperCameraCalibrationFeedbackResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_PROCESSING = 1,
  STATUS_SUCCESS = 2,
  STATUS_NEVER_RUN = 3,
  STATUS_USER_CANCELED = 4,
  STATUS_POWER_ERROR = 5,
  STATUS_LEASE_ERROR = 6,
  STATUS_CALIBRATION_ERROR = 8,
  STATUS_INITIALIZATION_ERROR = 9,
  STATUS_INTERNAL_ERROR = 10,
  STATUS_TARGET_NOT_CENTERED = 11,
  STATUS_TARGET_NOT_IN_VIEW = 12,
  STATUS_TARGET_NOT_GRAVITY_ALIGNED = 13,
  STATUS_TARGET_UPSIDE_DOWN = 14,
  STATUS_STUCK = 15,
}

export interface IRosTypeBosdynSpotApiMsgsGripperParams {
  angle: IRosTypeStdMsgsFloat64;
  speed: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsGripperParamsConst {
  ANGLE_FIELD_SET = 1,
  SPEED_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsHipRangeOfMotionResult {
  error: IRosTypeBosdynSpotApiMsgsHipRangeOfMotionResultError;
  hx: number[];
  hy: number[];
}

export interface IRosTypeBosdynSpotApiMsgsHipRangeOfMotionResultError {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsHipRangeOfMotionResultErrorConst {
  ERROR_UNKNOWN = 0,
  ERROR_NONE = 1,
  ERROR_OBSTRUCTED = 2,
}

export interface IRosTypeBosdynSpotApiMsgsHopParams {
  velocity: IRosTypeBosdynApiMsgsVec2Value;
  yaw_rate: IRosTypeStdMsgsFloat64;
  stand_time: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsHopParamsConst {
  VELOCITY_FIELD_SET = 1,
  YAW_RATE_FIELD_SET = 2,
  STAND_TIME_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsIndependentColorParams {
  top_left: IRosTypeBosdynSpotApiMsgsColor;
  upper_mid_left: IRosTypeBosdynSpotApiMsgsColor;
  lower_mid_left: IRosTypeBosdynSpotApiMsgsColor;
  bottom_left: IRosTypeBosdynSpotApiMsgsColor;
  top_right: IRosTypeBosdynSpotApiMsgsColor;
  upper_mid_right: IRosTypeBosdynSpotApiMsgsColor;
  lower_mid_right: IRosTypeBosdynSpotApiMsgsColor;
  bottom_right: IRosTypeBosdynSpotApiMsgsColor;
  fade_in_slices: IRosTypeStdMsgsFloat64;
  fade_out_slices: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsIndependentColorParamsConst {
  TOP_LEFT_FIELD_SET = 1,
  UPPER_MID_LEFT_FIELD_SET = 2,
  LOWER_MID_LEFT_FIELD_SET = 4,
  BOTTOM_LEFT_FIELD_SET = 8,
  TOP_RIGHT_FIELD_SET = 16,
  UPPER_MID_RIGHT_FIELD_SET = 32,
  LOWER_MID_RIGHT_FIELD_SET = 64,
  BOTTOM_RIGHT_FIELD_SET = 128,
  FADE_IN_SLICES_FIELD_SET = 256,
  FADE_OUT_SLICES_FIELD_SET = 512,
}

export interface IRosTypeBosdynSpotApiMsgsInverseKinematicsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  root_frame_name: string;
  root_tform_scene: IRosTypeGeometryMsgsPose;
  scene_tform_task: IRosTypeGeometryMsgsPose;
  nominal_arm_configuration: IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestNamedArmConfiguration;
  nominal_arm_configuration_overrides: IRosTypeBosdynApiMsgsArmJointPosition;
  scene_tform_body_nominal: IRosTypeGeometryMsgsPose;
  stance_specification: IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestOneOfStanceSpecification;
  tool_specification: IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestOneOfToolSpecification;
  task_specification: IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestOneOfTaskSpecification;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestConst {
  HEADER_FIELD_SET = 1,
  ROOT_TFORM_SCENE_FIELD_SET = 4,
  SCENE_TFORM_TASK_FIELD_SET = 8,
  NOMINAL_ARM_CONFIGURATION_OVERRIDES_FIELD_SET = 32,
  SCENE_TFORM_BODY_NOMINAL_FIELD_SET = 64,
}

export interface IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestBodyMountedTool {
  body_tform_tool: IRosTypeGeometryMsgsPose;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestBodyMountedToolConst {
  BODY_TFORM_TOOL_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestFixedStance {
  fl_rt_scene: IRosTypeGeometryMsgsVector3;
  fr_rt_scene: IRosTypeGeometryMsgsVector3;
  hl_rt_scene: IRosTypeGeometryMsgsVector3;
  hr_rt_scene: IRosTypeGeometryMsgsVector3;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestFixedStanceConst {
  FL_RT_SCENE_FIELD_SET = 1,
  FR_RT_SCENE_FIELD_SET = 2,
  HL_RT_SCENE_FIELD_SET = 4,
  HR_RT_SCENE_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestNamedArmConfiguration {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestNamedArmConfigurationConst {
  ARM_CONFIG_UNKNOWN = 0,
  ARM_CONFIG_CURRENT = 1,
  ARM_CONFIG_READY = 2,
}

export interface IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestOnGroundPlaneStance {
  scene_tform_ground: IRosTypeGeometryMsgsPose;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestOnGroundPlaneStanceConst {
  SCENE_TFORM_GROUND_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestOneOfStanceSpecification {
  fixed_stance: IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestFixedStance;
  on_ground_plane_stance: IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestOnGroundPlaneStance;
  stance_specification_choice: number;
  which: number;
}

export enum IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestOneOfStanceSpecificationConst {
  STANCE_SPECIFICATION_NOT_SET = 0,
  STANCE_SPECIFICATION_FIXED_STANCE_SET = 1,
  STANCE_SPECIFICATION_ON_GROUND_PLANE_STANCE_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestOneOfTaskSpecification {
  tool_pose_task: IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestToolPoseTask;
  tool_gaze_task: IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestToolGazeTask;
  task_specification_choice: number;
  which: number;
}

export enum IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestOneOfTaskSpecificationConst {
  TASK_SPECIFICATION_NOT_SET = 0,
  TASK_SPECIFICATION_TOOL_POSE_TASK_SET = 1,
  TASK_SPECIFICATION_TOOL_GAZE_TASK_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestOneOfToolSpecification {
  wrist_mounted_tool: IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestWristMountedTool;
  body_mounted_tool: IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestBodyMountedTool;
  tool_specification_choice: number;
  which: number;
}

export enum IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestOneOfToolSpecificationConst {
  TOOL_SPECIFICATION_NOT_SET = 0,
  TOOL_SPECIFICATION_WRIST_MOUNTED_TOOL_SET = 1,
  TOOL_SPECIFICATION_BODY_MOUNTED_TOOL_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestToolGazeTask {
  target_in_task: IRosTypeGeometryMsgsVector3;
  task_tform_desired_tool: IRosTypeGeometryMsgsPose;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestToolGazeTaskConst {
  TARGET_IN_TASK_FIELD_SET = 1,
  TASK_TFORM_DESIRED_TOOL_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestToolPoseTask {
  task_tform_desired_tool: IRosTypeGeometryMsgsPose;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestToolPoseTaskConst {
  TASK_TFORM_DESIRED_TOOL_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestWristMountedTool {
  wrist_tform_tool: IRosTypeGeometryMsgsPose;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsInverseKinematicsRequestWristMountedToolConst {
  WRIST_TFORM_TOOL_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsInverseKinematicsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynSpotApiMsgsInverseKinematicsResponseStatus;
  robot_configuration: IRosTypeBosdynApiMsgsKinematicState;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsInverseKinematicsResponseConst {
  HEADER_FIELD_SET = 1,
  ROBOT_CONFIGURATION_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsInverseKinematicsResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsInverseKinematicsResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_NO_SOLUTION_FOUND = 2,
}

export interface IRosTypeBosdynSpotApiMsgsJointKinematicCheckResult {
  error: IRosTypeBosdynSpotApiMsgsJointKinematicCheckResultError;
  offset: number;
  old_offset: number;
  health_score: number;
}

export interface IRosTypeBosdynSpotApiMsgsJointKinematicCheckResultError {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsJointKinematicCheckResultErrorConst {
  ERROR_UNKNOWN = 0,
  ERROR_NONE = 1,
  ERROR_CLUTCH_SLIP = 2,
  ERROR_INVALID_RANGE_OF_MOTION = 3,
  ERROR_ENCODER_SHIFTED = 4,
  ERROR_COLLISION = 5,
}

export interface IRosTypeBosdynSpotApiMsgsJumpParams {
  yaw: IRosTypeStdMsgsFloat64;
  flight_slices: IRosTypeStdMsgsFloat64;
  stance_width: IRosTypeStdMsgsFloat64;
  stance_length: IRosTypeStdMsgsFloat64;
  translation: IRosTypeBosdynApiMsgsVec2Value;
  split_fraction: IRosTypeStdMsgsFloat64;
  lead_leg_pair: IRosTypeBosdynSpotApiMsgsJumpParamsLead;
  yaw_is_absolute: IRosTypeStdMsgsBool;
  translation_is_absolute: IRosTypeStdMsgsBool;
  absolute_yaw: IRosTypeStdMsgsFloat64;
  absolute_translation: IRosTypeBosdynApiMsgsVec2Value;
  swing_height: IRosTypeStdMsgsFloat64;
  absolute: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsJumpParamsConst {
  YAW_FIELD_SET = 1,
  FLIGHT_SLICES_FIELD_SET = 2,
  STANCE_WIDTH_FIELD_SET = 4,
  STANCE_LENGTH_FIELD_SET = 8,
  TRANSLATION_FIELD_SET = 16,
  SPLIT_FRACTION_FIELD_SET = 32,
  YAW_IS_ABSOLUTE_FIELD_SET = 128,
  TRANSLATION_IS_ABSOLUTE_FIELD_SET = 256,
  ABSOLUTE_YAW_FIELD_SET = 512,
  ABSOLUTE_TRANSLATION_FIELD_SET = 1024,
  SWING_HEIGHT_FIELD_SET = 2048,
  ABSOLUTE_FIELD_SET = 4096,
}

export interface IRosTypeBosdynSpotApiMsgsJumpParamsLead {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsJumpParamsLeadConst {
  LEAD_UNKNOWN = 0,
  LEAD_AUTO = 1,
  LEAD_FRONT = 2,
  LEAD_HIND = 3,
  LEAD_LEFT = 4,
  LEAD_RIGHT = 5,
}

export interface IRosTypeBosdynSpotApiMsgsKneelCircleParams {
  location: IRosTypeBosdynApiMsgsVec3Value;
  beats_per_circle: IRosTypeStdMsgsInt32;
  number_of_circles: IRosTypeStdMsgsFloat64;
  offset: IRosTypeStdMsgsFloat64;
  radius: IRosTypeStdMsgsFloat64;
  reverse: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsKneelCircleParamsConst {
  LOCATION_FIELD_SET = 1,
  BEATS_PER_CIRCLE_FIELD_SET = 2,
  NUMBER_OF_CIRCLES_FIELD_SET = 4,
  OFFSET_FIELD_SET = 8,
  RADIUS_FIELD_SET = 16,
  REVERSE_FIELD_SET = 32,
}

export interface IRosTypeBosdynSpotApiMsgsKneelLegMove2Params {
  left_hip_x: IRosTypeStdMsgsFloat64;
  left_hip_y: IRosTypeStdMsgsFloat64;
  left_knee: IRosTypeStdMsgsFloat64;
  right_hip_x: IRosTypeStdMsgsFloat64;
  right_hip_y: IRosTypeStdMsgsFloat64;
  right_knee: IRosTypeStdMsgsFloat64;
  easing: IRosTypeBosdynSpotApiMsgsEasing;
  link_to_next: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsKneelLegMove2ParamsConst {
  LEFT_HIP_X_FIELD_SET = 1,
  LEFT_HIP_Y_FIELD_SET = 2,
  LEFT_KNEE_FIELD_SET = 4,
  RIGHT_HIP_X_FIELD_SET = 8,
  RIGHT_HIP_Y_FIELD_SET = 16,
  RIGHT_KNEE_FIELD_SET = 32,
  LINK_TO_NEXT_FIELD_SET = 128,
}

export interface IRosTypeBosdynSpotApiMsgsKneelLegMoveParams {
  hip_x: IRosTypeStdMsgsFloat64;
  hip_y: IRosTypeStdMsgsFloat64;
  knee: IRosTypeStdMsgsFloat64;
  mirror: IRosTypeStdMsgsBool;
  easing: IRosTypeBosdynSpotApiMsgsEasing;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsKneelLegMoveParamsConst {
  HIP_X_FIELD_SET = 1,
  HIP_Y_FIELD_SET = 2,
  KNEE_FIELD_SET = 4,
  MIRROR_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotApiMsgsLedLight {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsLedLightConst {
  LED_LIGHT_UNKNOWN = 0,
  LED_LIGHT_LEFT1 = 1,
  LED_LIGHT_LEFT2 = 2,
  LED_LIGHT_LEFT3 = 3,
  LED_LIGHT_LEFT4 = 4,
  LED_LIGHT_RIGHT1 = 5,
  LED_LIGHT_RIGHT2 = 6,
  LED_LIGHT_RIGHT3 = 7,
  LED_LIGHT_RIGHT4 = 8,
}

export interface IRosTypeBosdynSpotApiMsgsLeg {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsLegConst {
  LEG_UNKNOWN = 0,
  LEG_FRONT_LEFT = 1,
  LEG_FRONT_RIGHT = 2,
  LEG_HIND_LEFT = 3,
  LEG_HIND_RIGHT = 4,
  LEG_NO_LEG = -1,
}

export interface IRosTypeBosdynSpotApiMsgsLegJointAngles {
  hip_x: number;
  hip_y: number;
  knee: number;
}

export interface IRosTypeBosdynSpotApiMsgsLegJointParams {
  fl_hx: IRosTypeStdMsgsFloat64;
  fl_hy: IRosTypeStdMsgsFloat64;
  fl_kn: IRosTypeStdMsgsFloat64;
  fr_hx: IRosTypeStdMsgsFloat64;
  fr_hy: IRosTypeStdMsgsFloat64;
  fr_kn: IRosTypeStdMsgsFloat64;
  hl_hx: IRosTypeStdMsgsFloat64;
  hl_hy: IRosTypeStdMsgsFloat64;
  hl_kn: IRosTypeStdMsgsFloat64;
  hr_hx: IRosTypeStdMsgsFloat64;
  hr_hy: IRosTypeStdMsgsFloat64;
  hr_kn: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsLegJointParamsConst {
  FL_HX_FIELD_SET = 1,
  FL_HY_FIELD_SET = 2,
  FL_KN_FIELD_SET = 4,
  FR_HX_FIELD_SET = 8,
  FR_HY_FIELD_SET = 16,
  FR_KN_FIELD_SET = 32,
  HL_HX_FIELD_SET = 64,
  HL_HY_FIELD_SET = 128,
  HL_KN_FIELD_SET = 256,
  HR_HX_FIELD_SET = 512,
  HR_HY_FIELD_SET = 1024,
  HR_KN_FIELD_SET = 2048,
}

export interface IRosTypeBosdynSpotApiMsgsLegPairCheckResult {
  status: IRosTypeBosdynSpotApiMsgsLegPairCheckResultStatus;
  leg_pair_distance_change: number;
}

export interface IRosTypeBosdynSpotApiMsgsLegPairCheckResultStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsLegPairCheckResultStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_WARNING = 2,
  STATUS_ERROR = 3,
}

export interface IRosTypeBosdynSpotApiMsgsListAllMovesRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsListAllMovesRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsListAllMovesResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  moves: IRosTypeBosdynSpotApiMsgsMoveInfo[];
  move_param_config: string;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsListAllMovesResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsListAllSequencesRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsListAllSequencesRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsListAllSequencesResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  known_sequences: string[];
  sequence_info: IRosTypeBosdynSpotApiMsgsSequenceInfo[];
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsListAllSequencesResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsLoadCellSpotCheckResult {
  error: IRosTypeBosdynSpotApiMsgsLoadCellSpotCheckResultError;
  zero: number;
  old_zero: number;
}

export interface IRosTypeBosdynSpotApiMsgsLoadCellSpotCheckResultError {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsLoadCellSpotCheckResultErrorConst {
  ERROR_UNKNOWN = 0,
  ERROR_NONE = 1,
  ERROR_ZERO_OUT_OF_RANGE = 2,
}

export interface IRosTypeBosdynSpotApiMsgsLocomotionHint {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsLocomotionHintConst {
  HINT_UNKNOWN = 0,
  HINT_AUTO = 1,
  HINT_TROT = 2,
  HINT_SPEED_SELECT_TROT = 3,
  HINT_CRAWL = 4,
  HINT_SPEED_SELECT_CRAWL = 10,
  HINT_AMBLE = 5,
  HINT_SPEED_SELECT_AMBLE = 6,
  HINT_JOG = 7,
  HINT_HOP = 8,
  HINT_AUTO_TROT = 3,
  HINT_AUTO_AMBLE = 6,
}

export interface IRosTypeBosdynSpotApiMsgsLoggedFootContacts {
  fr_contact: boolean;
  fl_contact: boolean;
  hr_contact: boolean;
  hl_contact: boolean;
}

export interface IRosTypeBosdynSpotApiMsgsLoggedJoints {
  fl: IRosTypeBosdynSpotApiMsgsLegJointAngles;
  fr: IRosTypeBosdynSpotApiMsgsLegJointAngles;
  hl: IRosTypeBosdynSpotApiMsgsLegJointAngles;
  hr: IRosTypeBosdynSpotApiMsgsLegJointAngles;
  arm: IRosTypeBosdynSpotApiMsgsArmJointAngles;
  gripper_angle: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsLoggedJointsConst {
  FL_FIELD_SET = 1,
  FR_FIELD_SET = 2,
  HL_FIELD_SET = 4,
  HR_FIELD_SET = 8,
  ARM_FIELD_SET = 16,
  GRIPPER_ANGLE_FIELD_SET = 32,
}

export interface IRosTypeBosdynSpotApiMsgsLoggedStateKeyFrame {
  joint_angles: IRosTypeBosdynSpotApiMsgsLoggedJoints;
  foot_contact_state: IRosTypeBosdynSpotApiMsgsLoggedFootContacts;
  animation_tform_body: IRosTypeGeometryMsgsPose;
  timestamp: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsLoggedStateKeyFrameConst {
  JOINT_ANGLES_FIELD_SET = 1,
  FOOT_CONTACT_STATE_FIELD_SET = 2,
  ANIMATION_TFORM_BODY_FIELD_SET = 4,
  TIMESTAMP_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotApiMsgsMobilityParams {
  vel_limit: IRosTypeBosdynApiMsgsSe2VelocityLimit;
  body_control: IRosTypeBosdynSpotApiMsgsBodyControlParams;
  locomotion_hint: IRosTypeBosdynSpotApiMsgsLocomotionHint;
  stair_hint: boolean;
  stairs_mode: IRosTypeBosdynSpotApiMsgsMobilityParamsStairsMode;
  allow_degraded_perception: boolean;
  obstacle_params: IRosTypeBosdynSpotApiMsgsObstacleParams;
  swing_height: IRosTypeBosdynSpotApiMsgsSwingHeight;
  terrain_params: IRosTypeBosdynSpotApiMsgsTerrainParams;
  disallow_stair_tracker: boolean;
  disable_stair_error_auto_descent: boolean;
  external_force_params: IRosTypeBosdynSpotApiMsgsBodyExternalForceParams;
  disallow_non_stairs_pitch_limiting: boolean;
  disable_nearmap_cliff_avoidance: boolean;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsMobilityParamsConst {
  VEL_LIMIT_FIELD_SET = 1,
  BODY_CONTROL_FIELD_SET = 2,
  OBSTACLE_PARAMS_FIELD_SET = 64,
  TERRAIN_PARAMS_FIELD_SET = 256,
  EXTERNAL_FORCE_PARAMS_FIELD_SET = 2048,
}

export interface IRosTypeBosdynSpotApiMsgsMobilityParamsStairsMode {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsMobilityParamsStairsModeConst {
  STAIRS_MODE_UNKNOWN = 0,
  STAIRS_MODE_OFF = 1,
  STAIRS_MODE_ON = 2,
  STAIRS_MODE_AUTO = 3,
}

export interface IRosTypeBosdynSpotApiMsgsModifyChoreographyInfoRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  sequence_name: string;
  add_labels: string[];
  remove_labels: string[];
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsModifyChoreographyInfoRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsModifyChoreographyInfoResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynSpotApiMsgsModifyChoreographyInfoResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsModifyChoreographyInfoResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsModifyChoreographyInfoResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsModifyChoreographyInfoResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_UNKNOWN_SEQUENCE = 2,
  STATUS_PERMANENT_SEQUENCE = 3,
  STATUS_FAILED_TO_UPDATE = 4,
}

export interface IRosTypeBosdynSpotApiMsgsMoveCommand {
  move_type: string;
  move_id: number;
  command: IRosTypeBosdynSpotApiMsgsMoveCommandOneOfCommand;
}

export interface IRosTypeBosdynSpotApiMsgsMoveCommandOneOfCommand {
  custom_gait_command: IRosTypeBosdynSpotApiMsgsCustomGaitCommand;
  command_choice: number;
  which: number;
}

export enum IRosTypeBosdynSpotApiMsgsMoveCommandOneOfCommandConst {
  COMMAND_NOT_SET = 0,
  COMMAND_CUSTOM_GAIT_COMMAND_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsMoveInfo {
  name: string;
  move_length_slices: number;
  move_length_time: number;
  is_extendable: boolean;
  min_move_length_slices: number;
  max_move_length_slices: number;
  min_time: number;
  max_time: number;
  entrance_states: IRosTypeBosdynSpotApiMsgsMoveInfoTransitionState[];
  exit_state: IRosTypeBosdynSpotApiMsgsMoveInfoTransitionState;
  controls_arm: boolean;
  controls_legs: boolean;
  controls_body: boolean;
  controls_gripper: boolean;
  controls_lights: boolean;
  controls_annotations: boolean;
  is_looping: boolean;
  display: IRosTypeBosdynSpotApiMsgsChoreographerDisplayInfo;
  animated_move_generated_id: IRosTypeStdMsgsString;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsMoveInfoConst {
  DISPLAY_FIELD_SET = 131072,
  ANIMATED_MOVE_GENERATED_ID_FIELD_SET = 262144,
}

export interface IRosTypeBosdynSpotApiMsgsMoveInfoTransitionState {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsMoveInfoTransitionStateConst {
  TRANSITION_STATE_UNKNOWN = 0,
  TRANSITION_STATE_STAND = 1,
  TRANSITION_STATE_KNEEL = 2,
  TRANSITION_STATE_SIT = 3,
  TRANSITION_STATE_SPRAWL = 4,
}

export interface IRosTypeBosdynSpotApiMsgsMoveParams {
  type: string;
  start_slice: number;
  requested_slices: number;
  id: number;
  params: IRosTypeBosdynSpotApiMsgsMoveParamsOneOfParams;
}

export interface IRosTypeBosdynSpotApiMsgsMoveParamsOneOfParams {
  jump_params: IRosTypeBosdynSpotApiMsgsJumpParams;
  rotate_body_params: IRosTypeBosdynSpotApiMsgsRotateBodyParams;
  step_params: IRosTypeBosdynSpotApiMsgsStepParams;
  butt_circle_params: IRosTypeBosdynSpotApiMsgsButtCircleParams;
  turn_params: IRosTypeBosdynSpotApiMsgsTurnParams;
  pace_2step_params: IRosTypeBosdynSpotApiMsgsPace2StepParams;
  twerk_params: IRosTypeBosdynSpotApiMsgsTwerkParams;
  chicken_head_params: IRosTypeBosdynSpotApiMsgsChickenHeadParams;
  clap_params: IRosTypeBosdynSpotApiMsgsClapParams;
  front_up_params: IRosTypeBosdynSpotApiMsgsFrontUpParams;
  sway_params: IRosTypeBosdynSpotApiMsgsSwayParams;
  body_hold_params: IRosTypeBosdynSpotApiMsgsBodyHoldParams;
  arm_move_params: IRosTypeBosdynSpotApiMsgsArmMoveParams;
  kneel_leg_move_params: IRosTypeBosdynSpotApiMsgsKneelLegMoveParams;
  running_man_params: IRosTypeBosdynSpotApiMsgsRunningManParams;
  kneel_circle_params: IRosTypeBosdynSpotApiMsgsKneelCircleParams;
  gripper_params: IRosTypeBosdynSpotApiMsgsGripperParams;
  hop_params: IRosTypeBosdynSpotApiMsgsHopParams;
  random_rotate_params: IRosTypeBosdynSpotApiMsgsRandomRotateParams;
  crawl_params: IRosTypeBosdynSpotApiMsgsCrawlParams;
  side_params: IRosTypeBosdynSpotApiMsgsSideParams;
  bourree_params: IRosTypeBosdynSpotApiMsgsBourreeParams;
  workspace_arm_move_params: IRosTypeBosdynSpotApiMsgsWorkspaceArmMoveParams;
  figure8_params: IRosTypeBosdynSpotApiMsgsFigure8Params;
  kneel_leg_move2_params: IRosTypeBosdynSpotApiMsgsKneelLegMove2Params;
  fidget_stand_params: IRosTypeBosdynSpotApiMsgsFidgetStandParams;
  goto_params: IRosTypeBosdynSpotApiMsgsGotoParams;
  frame_snapshot_params: IRosTypeBosdynSpotApiMsgsFrameSnapshotParams;
  set_color_params: IRosTypeBosdynSpotApiMsgsSetColorParams;
  ripple_color_params: IRosTypeBosdynSpotApiMsgsRippleColorParams;
  fade_color_params: IRosTypeBosdynSpotApiMsgsFadeColorParams;
  independent_color_params: IRosTypeBosdynSpotApiMsgsIndependentColorParams;
  custom_gait_params: IRosTypeBosdynSpotApiMsgsCustomGaitParams;
  leg_joint_params: IRosTypeBosdynSpotApiMsgsLegJointParams;
  animate_params: IRosTypeBosdynSpotApiMsgsAnimateParams;
  params_choice: number;
  which: number;
}

export enum IRosTypeBosdynSpotApiMsgsMoveParamsOneOfParamsConst {
  PARAMS_NOT_SET = 0,
  PARAMS_JUMP_PARAMS_SET = 1,
  PARAMS_ROTATE_BODY_PARAMS_SET = 2,
  PARAMS_STEP_PARAMS_SET = 3,
  PARAMS_BUTT_CIRCLE_PARAMS_SET = 4,
  PARAMS_TURN_PARAMS_SET = 5,
  PARAMS_PACE_2STEP_PARAMS_SET = 6,
  PARAMS_TWERK_PARAMS_SET = 7,
  PARAMS_CHICKEN_HEAD_PARAMS_SET = 8,
  PARAMS_CLAP_PARAMS_SET = 9,
  PARAMS_FRONT_UP_PARAMS_SET = 10,
  PARAMS_SWAY_PARAMS_SET = 11,
  PARAMS_BODY_HOLD_PARAMS_SET = 12,
  PARAMS_ARM_MOVE_PARAMS_SET = 13,
  PARAMS_KNEEL_LEG_MOVE_PARAMS_SET = 14,
  PARAMS_RUNNING_MAN_PARAMS_SET = 15,
  PARAMS_KNEEL_CIRCLE_PARAMS_SET = 16,
  PARAMS_GRIPPER_PARAMS_SET = 17,
  PARAMS_HOP_PARAMS_SET = 18,
  PARAMS_RANDOM_ROTATE_PARAMS_SET = 19,
  PARAMS_CRAWL_PARAMS_SET = 20,
  PARAMS_SIDE_PARAMS_SET = 21,
  PARAMS_BOURREE_PARAMS_SET = 22,
  PARAMS_WORKSPACE_ARM_MOVE_PARAMS_SET = 23,
  PARAMS_FIGURE8_PARAMS_SET = 24,
  PARAMS_KNEEL_LEG_MOVE2_PARAMS_SET = 25,
  PARAMS_FIDGET_STAND_PARAMS_SET = 26,
  PARAMS_GOTO_PARAMS_SET = 27,
  PARAMS_FRAME_SNAPSHOT_PARAMS_SET = 28,
  PARAMS_SET_COLOR_PARAMS_SET = 29,
  PARAMS_RIPPLE_COLOR_PARAMS_SET = 30,
  PARAMS_FADE_COLOR_PARAMS_SET = 31,
  PARAMS_INDEPENDENT_COLOR_PARAMS_SET = 32,
  PARAMS_CUSTOM_GAIT_PARAMS_SET = 33,
  PARAMS_LEG_JOINT_PARAMS_SET = 34,
  PARAMS_ANIMATE_PARAMS_SET = 35,
}

export interface IRosTypeBosdynSpotApiMsgsObstacleParams {
  disable_vision_foot_obstacle_avoidance: boolean;
  disable_vision_foot_constraint_avoidance: boolean;
  disable_vision_body_obstacle_avoidance: boolean;
  obstacle_avoidance_padding: number;
  disable_vision_foot_obstacle_body_assist: boolean;
  disable_vision_negative_obstacles: boolean;
}

export interface IRosTypeBosdynSpotApiMsgsOpenDoorCommandRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  door_command: IRosTypeBosdynSpotApiMsgsDoorCommandRequest;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsOpenDoorCommandRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
  DOOR_COMMAND_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsOpenDoorCommandResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  status: IRosTypeBosdynSpotApiMsgsOpenDoorCommandResponseStatus;
  message: string;
  door_command_id: number;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsOpenDoorCommandResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsOpenDoorCommandResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsOpenDoorCommandResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_ROBOT_COMMAND_ERROR = 2,
  STATUS_DOOR_PLANE_NOT_DETECTED = 3,
}

export interface IRosTypeBosdynSpotApiMsgsOpenDoorFeedbackRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  door_command_id: number;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsOpenDoorFeedbackRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsOpenDoorFeedbackResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynApiMsgsRobotCommandFeedbackStatusStatus;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  feedback: IRosTypeBosdynSpotApiMsgsDoorCommandFeedback;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsOpenDoorFeedbackResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 4,
  FEEDBACK_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotApiMsgsPace2StepParams {
  motion: IRosTypeBosdynApiMsgsVec2Value;
  absolute_motion: IRosTypeBosdynApiMsgsVec2Value;
  motion_is_absolute: IRosTypeStdMsgsBool;
  swing_height: IRosTypeStdMsgsFloat64;
  swing_velocity: IRosTypeStdMsgsFloat64;
  yaw: IRosTypeStdMsgsFloat64;
  absolute_yaw: IRosTypeStdMsgsFloat64;
  yaw_is_absolute: IRosTypeStdMsgsBool;
  absolute: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsPace2StepParamsConst {
  MOTION_FIELD_SET = 1,
  ABSOLUTE_MOTION_FIELD_SET = 2,
  MOTION_IS_ABSOLUTE_FIELD_SET = 4,
  SWING_HEIGHT_FIELD_SET = 8,
  SWING_VELOCITY_FIELD_SET = 16,
  YAW_FIELD_SET = 32,
  ABSOLUTE_YAW_FIELD_SET = 64,
  YAW_IS_ABSOLUTE_FIELD_SET = 128,
  ABSOLUTE_FIELD_SET = 256,
}

export interface IRosTypeBosdynSpotApiMsgsPayloadCheckResult {
  error: IRosTypeBosdynSpotApiMsgsPayloadCheckResultError;
  extra_payload: number;
}

export interface IRosTypeBosdynSpotApiMsgsPayloadCheckResultError {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsPayloadCheckResultErrorConst {
  ERROR_UNKNOWN = 0,
  ERROR_NONE = 1,
  ERROR_MASS_DISCREPANCY = 2,
}

export interface IRosTypeBosdynSpotApiMsgsPivot {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsPivotConst {
  PIVOT_UNKNOWN = 0,
  PIVOT_FRONT = 1,
  PIVOT_HIND = 2,
  PIVOT_CENTER = 3,
}

export interface IRosTypeBosdynSpotApiMsgsRandomRotateParams {
  amplitude: IRosTypeBosdynSpotApiMsgsEulerZyxValue;
  speed: IRosTypeBosdynSpotApiMsgsEulerRateZyxValue;
  speed_variation: IRosTypeStdMsgsFloat64;
  num_speed_tiers: IRosTypeStdMsgsInt32;
  tier_variation: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsRandomRotateParamsConst {
  AMPLITUDE_FIELD_SET = 1,
  SPEED_FIELD_SET = 2,
  SPEED_VARIATION_FIELD_SET = 4,
  NUM_SPEED_TIERS_FIELD_SET = 8,
  TIER_VARIATION_FIELD_SET = 16,
}

export interface IRosTypeBosdynSpotApiMsgsRippleColorParams {
  main: IRosTypeBosdynSpotApiMsgsColor;
  secondary: IRosTypeBosdynSpotApiMsgsColor;
  pattern: IRosTypeBosdynSpotApiMsgsRippleColorParamsPattern;
  light_side: IRosTypeBosdynSpotApiMsgsRippleColorParamsLightSide;
  increment_slices: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsRippleColorParamsConst {
  MAIN_FIELD_SET = 1,
  SECONDARY_FIELD_SET = 2,
  INCREMENT_SLICES_FIELD_SET = 16,
}

export interface IRosTypeBosdynSpotApiMsgsRippleColorParamsLightSide {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsRippleColorParamsLightSideConst {
  LIGHT_SIDE_UNKNOWN = 0,
  LIGHT_SIDE_LEFT = 1,
  LIGHT_SIDE_RIGHT = 2,
  LIGHT_SIDE_BOTH_IN_SEQUENCE = 3,
  LIGHT_SIDE_BOTH_MATCHING = 4,
}

export interface IRosTypeBosdynSpotApiMsgsRippleColorParamsPattern {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsRippleColorParamsPatternConst {
  PATTERN_UNKNOWN = 0,
  PATTERN_FLASHING = 1,
  PATTERN_SNAKE = 2,
  PATTERN_ALTERNATE_COLORS = 3,
  PATTERN_FINE_GRAINED_ALTERNATE_COLORS = 4,
}

export interface IRosTypeBosdynSpotApiMsgsRotateBodyParams {
  rotation: IRosTypeBosdynSpotApiMsgsEulerZyxValue;
  return_to_start_pose: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsRotateBodyParamsConst {
  ROTATION_FIELD_SET = 1,
  RETURN_TO_START_POSE_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsRunningManParams {
  velocity: IRosTypeBosdynApiMsgsVec2Value;
  swing_height: IRosTypeStdMsgsFloat64;
  spread: IRosTypeStdMsgsFloat64;
  reverse: IRosTypeStdMsgsBool;
  pre_move_cycles: IRosTypeStdMsgsInt32;
  speed_multiplier: IRosTypeStdMsgsFloat64;
  duty_cycle: IRosTypeStdMsgsFloat64;
  com_height: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsRunningManParamsConst {
  VELOCITY_FIELD_SET = 1,
  SWING_HEIGHT_FIELD_SET = 2,
  SPREAD_FIELD_SET = 4,
  REVERSE_FIELD_SET = 8,
  PRE_MOVE_CYCLES_FIELD_SET = 16,
  SPEED_MULTIPLIER_FIELD_SET = 32,
  DUTY_CYCLE_FIELD_SET = 64,
  COM_HEIGHT_FIELD_SET = 128,
}

export interface IRosTypeBosdynSpotApiMsgsSaveSequenceRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  sequence_name: string;
  add_labels: string[];
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsSaveSequenceRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsSaveSequenceResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynSpotApiMsgsSaveSequenceResponseStatus;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsSaveSequenceResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsSaveSequenceResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsSaveSequenceResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_UNKNOWN_SEQUENCE = 2,
  STATUS_PERMANENT_SEQUENCE = 3,
  STATUS_FAILED_TO_SAVE = 4,
}

export interface IRosTypeBosdynSpotApiMsgsSequenceInfo {
  name: string;
  labels: string[];
  saved_state: IRosTypeBosdynSpotApiMsgsSequenceInfoSavedState;
  exit_state: IRosTypeBosdynSpotApiMsgsMoveInfoTransitionState;
}

export interface IRosTypeBosdynSpotApiMsgsSequenceInfoSavedState {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsSequenceInfoSavedStateConst {
  SAVED_STATE_UNKNOWN = 0,
  SAVED_STATE_TEMPORARY = 1,
  SAVED_STATE_RETAINED = 2,
  SAVED_STATE_PERMANENT = 3,
}

export interface IRosTypeBosdynSpotApiMsgsSetColorParams {
  left_color: IRosTypeBosdynSpotApiMsgsColor;
  right_same_as_left: IRosTypeStdMsgsBool;
  right_color: IRosTypeBosdynSpotApiMsgsColor;
  fade_in_slices: IRosTypeStdMsgsFloat64;
  fade_out_slices: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsSetColorParamsConst {
  LEFT_COLOR_FIELD_SET = 1,
  RIGHT_SAME_AS_LEFT_FIELD_SET = 2,
  RIGHT_COLOR_FIELD_SET = 4,
  FADE_IN_SLICES_FIELD_SET = 8,
  FADE_OUT_SLICES_FIELD_SET = 16,
}

export interface IRosTypeBosdynSpotApiMsgsSideParams {
  side: IRosTypeBosdynSpotApiMsgsSideParamsSide;
}

export interface IRosTypeBosdynSpotApiMsgsSideParamsSide {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsSideParamsSideConst {
  SIDE_UNKNOWN = 0,
  SIDE_LEFT = 1,
  SIDE_RIGHT = 2,
}

export interface IRosTypeBosdynSpotApiMsgsSpotCheckCommandRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  lease: IRosTypeBosdynApiMsgsLease;
  command: IRosTypeBosdynSpotApiMsgsSpotCheckCommandRequestCommand;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsSpotCheckCommandRequestConst {
  HEADER_FIELD_SET = 1,
  LEASE_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsSpotCheckCommandRequestCommand {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsSpotCheckCommandRequestCommandConst {
  COMMAND_UNKNOWN = 0,
  COMMAND_START = 1,
  COMMAND_ABORT = 2,
  COMMAND_REVERT_CAL = 3,
}

export interface IRosTypeBosdynSpotApiMsgsSpotCheckCommandResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  lease_use_result: IRosTypeBosdynApiMsgsLeaseUseResult;
  status: IRosTypeBosdynSpotApiMsgsSpotCheckCommandResponseStatus;
  message: string;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsSpotCheckCommandResponseConst {
  HEADER_FIELD_SET = 1,
  LEASE_USE_RESULT_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsSpotCheckCommandResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsSpotCheckCommandResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_ERROR = 2,
}

export interface IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  state: IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackResponseState;
  last_command: IRosTypeBosdynSpotApiMsgsSpotCheckCommandRequestCommand;
  error: IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackResponseError;
  camera_results: IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackResponseCameraResultsEntry[];
  load_cell_results: IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackResponseLoadCellResultsEntry[];
  kinematic_cal_results: IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackResponseKinematicCalResultsEntry[];
  payload_result: IRosTypeBosdynSpotApiMsgsPayloadCheckResult;
  hip_range_of_motion_results: IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackResponseHipRangeOfMotionResultsEntry[];
  progress: number;
  last_cal_timestamp: { sec: number; nanosec: number };
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackResponseConst {
  HEADER_FIELD_SET = 1,
  PAYLOAD_RESULT_FIELD_SET = 128,
  LAST_CAL_TIMESTAMP_FIELD_SET = 1024,
}

export interface IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackResponseCameraResultsEntry {
  key: string;
  value: IRosTypeBosdynSpotApiMsgsDepthPlaneSpotCheckResult;
}

export interface IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackResponseError {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackResponseErrorConst {
  ERROR_UNKNOWN = 0,
  ERROR_NONE = 1,
  ERROR_UNEXPECTED_POWER_CHANGE = 2,
  ERROR_INIT_IMU_CHECK = 3,
  ERROR_INIT_NOT_SITTING = 4,
  ERROR_LOADCELL_TIMEOUT = 5,
  ERROR_POWER_ON_FAILURE = 6,
  ERROR_ENDSTOP_TIMEOUT = 7,
  ERROR_FAILED_STAND = 8,
  ERROR_CAMERA_TIMEOUT = 9,
  ERROR_GROUND_CHECK = 10,
  ERROR_POWER_OFF_FAILURE = 11,
  ERROR_REVERT_FAILURE = 12,
  ERROR_FGKC_FAILURE = 13,
  ERROR_GRIPPER_CAL_TIMEOUT = 14,
  ERROR_ARM_CHECK_COLLISION = 15,
  ERROR_ARM_CHECK_TIMEOUT = 16,
}

export interface IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackResponseHipRangeOfMotionResultsEntry {
  key: string;
  value: IRosTypeBosdynSpotApiMsgsHipRangeOfMotionResult;
}

export interface IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackResponseKinematicCalResultsEntry {
  key: string;
  value: IRosTypeBosdynSpotApiMsgsJointKinematicCheckResult;
}

export interface IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackResponseLoadCellResultsEntry {
  key: string;
  value: IRosTypeBosdynSpotApiMsgsLoadCellSpotCheckResult;
}

export interface IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackResponseState {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsSpotCheckFeedbackResponseStateConst {
  STATE_UNKNOWN = 0,
  STATE_USER_ABORTED = 1,
  STATE_STARTING = 2,
  STATE_LOADCELL_CAL = 3,
  STATE_ENDSTOP_CAL = 4,
  STATE_CAMERA_CHECK = 5,
  STATE_BODY_POSING = 6,
  STATE_FINISHED = 7,
  STATE_REVERTING_CAL = 8,
  STATE_ERROR = 9,
  STATE_WAITING_FOR_COMMAND = 10,
  STATE_HIP_RANGE_OF_MOTION_CHECK = 11,
  STATE_GRIPPER_CAL = 12,
  STATE_SIT_DOWN_AFTER_RUN = 13,
  STATE_ARM_JOINT_CHECK = 14,
}

export interface IRosTypeBosdynSpotApiMsgsStanceShape {
  length: IRosTypeStdMsgsFloat64;
  width: IRosTypeStdMsgsFloat64;
  front_wider_than_hind: IRosTypeStdMsgsFloat64;
  left_longer_than_right: IRosTypeStdMsgsFloat64;
  left_forward_of_right: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsStanceShapeConst {
  LENGTH_FIELD_SET = 1,
  WIDTH_FIELD_SET = 2,
  FRONT_WIDER_THAN_HIND_FIELD_SET = 4,
  LEFT_LONGER_THAN_RIGHT_FIELD_SET = 8,
  LEFT_FORWARD_OF_RIGHT_FIELD_SET = 16,
}

export interface IRosTypeBosdynSpotApiMsgsStartRecordingStateRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  continue_recording_duration: { sec: number; nanosec: number };
  recording_session_id: number;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsStartRecordingStateRequestConst {
  HEADER_FIELD_SET = 1,
  CONTINUE_RECORDING_DURATION_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsStartRecordingStateResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynSpotApiMsgsStartRecordingStateResponseStatus;
  recording_session_id: number;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsStartRecordingStateResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsStartRecordingStateResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsStartRecordingStateResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_UNKNOWN_RECORDING_SESSION_ID = 2,
  STATUS_RECORDING_BUFFER_FULL = 3,
}

export interface IRosTypeBosdynSpotApiMsgsStepParams {
  foot: IRosTypeBosdynSpotApiMsgsLeg;
  offset: IRosTypeBosdynApiMsgsVec2Value;
  second_foot: IRosTypeBosdynSpotApiMsgsLeg;
  swing_waypoint: IRosTypeBosdynApiMsgsVec3Value;
  swing_height: IRosTypeStdMsgsFloat64;
  liftoff_velocity: IRosTypeStdMsgsFloat64;
  touchdown_velocity: IRosTypeStdMsgsFloat64;
  mirror_x: IRosTypeStdMsgsBool;
  mirror_y: IRosTypeStdMsgsBool;
  mirror: IRosTypeStdMsgsBool;
  waypoint_dwell: IRosTypeStdMsgsFloat64;
  touch: IRosTypeStdMsgsBool;
  touch_offset: IRosTypeBosdynApiMsgsVec2Value;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsStepParamsConst {
  OFFSET_FIELD_SET = 2,
  SWING_WAYPOINT_FIELD_SET = 8,
  SWING_HEIGHT_FIELD_SET = 16,
  LIFTOFF_VELOCITY_FIELD_SET = 32,
  TOUCHDOWN_VELOCITY_FIELD_SET = 64,
  MIRROR_X_FIELD_SET = 128,
  MIRROR_Y_FIELD_SET = 256,
  MIRROR_FIELD_SET = 512,
  WAYPOINT_DWELL_FIELD_SET = 1024,
  TOUCH_FIELD_SET = 2048,
  TOUCH_OFFSET_FIELD_SET = 4096,
}

export interface IRosTypeBosdynSpotApiMsgsStopRecordingStateRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsStopRecordingStateRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsStopRecordingStateResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsStopRecordingStateResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsSwayParams {
  vertical: IRosTypeStdMsgsFloat64;
  horizontal: IRosTypeStdMsgsFloat64;
  roll: IRosTypeStdMsgsFloat64;
  pivot: IRosTypeBosdynSpotApiMsgsPivot;
  style: IRosTypeBosdynSpotApiMsgsSwayParamsSwayStyle;
  pronounced: IRosTypeStdMsgsFloat64;
  hold_zero_axes: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsSwayParamsConst {
  VERTICAL_FIELD_SET = 1,
  HORIZONTAL_FIELD_SET = 2,
  ROLL_FIELD_SET = 4,
  PRONOUNCED_FIELD_SET = 32,
  HOLD_ZERO_AXES_FIELD_SET = 64,
}

export interface IRosTypeBosdynSpotApiMsgsSwayParamsSwayStyle {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsSwayParamsSwayStyleConst {
  SWAY_STYLE_UNKNOWN = 0,
  SWAY_STYLE_STANDARD = 1,
  SWAY_STYLE_FAST_OUT = 2,
  SWAY_STYLE_FAST_RETURN = 3,
  SWAY_STYLE_SQUARE = 4,
  SWAY_STYLE_SPIKE = 5,
  SWAY_STYLE_PLATEAU = 6,
}

export interface IRosTypeBosdynSpotApiMsgsSwingHeight {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsSwingHeightConst {
  SWING_HEIGHT_UNKNOWN = 0,
  SWING_HEIGHT_LOW = 1,
  SWING_HEIGHT_MEDIUM = 2,
  SWING_HEIGHT_HIGH = 3,
  SWING_HEIGHT_AUTO = 4,
}

export interface IRosTypeBosdynSpotApiMsgsSwingParams {
  height: IRosTypeStdMsgsFloat64;
  liftoff_speed: IRosTypeStdMsgsFloat64;
  vertical_speed: IRosTypeStdMsgsFloat64;
  vertical_acceleration: IRosTypeStdMsgsFloat64;
  overlay_outside: IRosTypeStdMsgsFloat64;
  overlay_forward: IRosTypeStdMsgsFloat64;
  low_speed_fraction: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsSwingParamsConst {
  HEIGHT_FIELD_SET = 1,
  LIFTOFF_SPEED_FIELD_SET = 2,
  VERTICAL_SPEED_FIELD_SET = 4,
  VERTICAL_ACCELERATION_FIELD_SET = 8,
  OVERLAY_OUTSIDE_FIELD_SET = 16,
  OVERLAY_FORWARD_FIELD_SET = 32,
  LOW_SPEED_FRACTION_FIELD_SET = 64,
}

export interface IRosTypeBosdynSpotApiMsgsSwingPhases {
  liftoff_phase: IRosTypeStdMsgsFloat64;
  touchdown_phase: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsSwingPhasesConst {
  LIFTOFF_PHASE_FIELD_SET = 1,
  TOUCHDOWN_PHASE_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsTerrainParams {
  ground_mu_hint: IRosTypeStdMsgsFloat64;
  enable_grated_floor: boolean;
  grated_surfaces_mode: IRosTypeBosdynSpotApiMsgsTerrainParamsGratedSurfacesMode;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsTerrainParamsConst {
  GROUND_MU_HINT_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsTerrainParamsGratedSurfacesMode {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsTerrainParamsGratedSurfacesModeConst {
  GRATED_SURFACES_MODE_UNKNOWN = 0,
  GRATED_SURFACES_MODE_OFF = 1,
  GRATED_SURFACES_MODE_ON = 2,
  GRATED_SURFACES_MODE_AUTO = 3,
}

export interface IRosTypeBosdynSpotApiMsgsTurnParams {
  yaw: IRosTypeStdMsgsFloat64;
  absolute_yaw: IRosTypeStdMsgsFloat64;
  yaw_is_absolute: IRosTypeStdMsgsBool;
  swing_height: IRosTypeStdMsgsFloat64;
  swing_velocity: IRosTypeStdMsgsFloat64;
  motion: IRosTypeBosdynApiMsgsVec2Value;
  absolute_motion: IRosTypeBosdynApiMsgsVec2Value;
  motion_is_absolute: IRosTypeStdMsgsBool;
  absolute: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsTurnParamsConst {
  YAW_FIELD_SET = 1,
  ABSOLUTE_YAW_FIELD_SET = 2,
  YAW_IS_ABSOLUTE_FIELD_SET = 4,
  SWING_HEIGHT_FIELD_SET = 8,
  SWING_VELOCITY_FIELD_SET = 16,
  MOTION_FIELD_SET = 32,
  ABSOLUTE_MOTION_FIELD_SET = 64,
  MOTION_IS_ABSOLUTE_FIELD_SET = 128,
  ABSOLUTE_FIELD_SET = 256,
}

export interface IRosTypeBosdynSpotApiMsgsTwerkParams {
  height: IRosTypeStdMsgsFloat64;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsTwerkParamsConst {
  HEIGHT_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsUploadAnimatedMoveRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  animated_move_generated_id: IRosTypeStdMsgsString;
  animated_move: IRosTypeBosdynSpotApiMsgsAnimation;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsUploadAnimatedMoveRequestConst {
  HEADER_FIELD_SET = 1,
  ANIMATED_MOVE_GENERATED_ID_FIELD_SET = 2,
  ANIMATED_MOVE_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotApiMsgsUploadAnimatedMoveResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynSpotApiMsgsUploadAnimatedMoveResponseStatus;
  warnings: string[];
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsUploadAnimatedMoveResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsUploadAnimatedMoveResponseStatus {
  value: number;
}

export enum IRosTypeBosdynSpotApiMsgsUploadAnimatedMoveResponseStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_ANIMATION_VALIDATION_FAILED = 2,
  STATUS_PING_RESPONSE = 3,
}

export interface IRosTypeBosdynSpotApiMsgsUploadChoreographyRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  choreography_sequence: IRosTypeBosdynSpotApiMsgsChoreographySequence;
  non_strict_parsing: boolean;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsUploadChoreographyRequestConst {
  HEADER_FIELD_SET = 1,
  CHOREOGRAPHY_SEQUENCE_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotApiMsgsUploadChoreographyResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  warnings: string[];
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsUploadChoreographyResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotApiMsgsWorkspaceArmMoveParams {
  rotation: IRosTypeBosdynSpotApiMsgsEulerZyxValue;
  translation: IRosTypeBosdynApiMsgsVec3Value;
  absolute: IRosTypeStdMsgsBool;
  frame: IRosTypeBosdynSpotApiMsgsArmMoveFrame;
  easing: IRosTypeBosdynSpotApiMsgsEasing;
  dance_frame_id: IRosTypeStdMsgsInt32;
  has_field: number;
}

export enum IRosTypeBosdynSpotApiMsgsWorkspaceArmMoveParamsConst {
  ROTATION_FIELD_SET = 1,
  TRANSLATION_FIELD_SET = 2,
  ABSOLUTE_FIELD_SET = 4,
  DANCE_FRAME_ID_FIELD_SET = 32,
}

export interface IRosTypeBosdynSpotCamApiMsgsAudioCaptureChannel {
  value: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsAudioCaptureChannelConst {
  AUDIO_CHANNEL_UNKNOWN = 0,
  AUDIO_CHANNEL_INTERNAL_MIC = 1,
  AUDIO_CHANNEL_EXTERNAL_MIC = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsCamera {
  name: string;
  resolution: IRosTypeBosdynApiMsgsVec2;
  base_frame_name: string;
  base_tfrom_sensor: IRosTypeGeometryMsgsPose;
  base_tform_sensor: IRosTypeGeometryMsgsPose;
  intrinsics: IRosTypeBosdynSpotCamApiMsgsCameraOneOfIntrinsics;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsCameraConst {
  RESOLUTION_FIELD_SET = 2,
  BASE_TFROM_SENSOR_FIELD_SET = 8,
  BASE_TFORM_SENSOR_FIELD_SET = 16,
}

export interface IRosTypeBosdynSpotCamApiMsgsCameraOneOfIntrinsics {
  pinhole: IRosTypeBosdynSpotCamApiMsgsCameraPinholeIntrinsics;
  spherical: IRosTypeBosdynSpotCamApiMsgsCameraSphericalLimits;
  intrinsics_choice: number;
  which: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsCameraOneOfIntrinsicsConst {
  INTRINSICS_NOT_SET = 0,
  INTRINSICS_PINHOLE_SET = 1,
  INTRINSICS_SPHERICAL_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsCameraPinholeIntrinsics {
  focal_length: IRosTypeBosdynApiMsgsVec2;
  center_point: IRosTypeBosdynApiMsgsVec2;
  k1: number;
  k2: number;
  k3: number;
  k4: number;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsCameraPinholeIntrinsicsConst {
  FOCAL_LENGTH_FIELD_SET = 1,
  CENTER_POINT_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsCameraSphericalLimits {
  min_angle: IRosTypeBosdynApiMsgsVec2;
  max_angle: IRosTypeBosdynApiMsgsVec2;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsCameraSphericalLimitsConst {
  MIN_ANGLE_FIELD_SET = 1,
  MAX_ANGLE_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsClearBitEventsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsClearBitEventsRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsClearBitEventsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsClearBitEventsResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsCyclePowerRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  status: IRosTypeBosdynSpotCamApiMsgsPowerStatus;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsCyclePowerRequestConst {
  HEADER_FIELD_SET = 1,
  STATUS_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsCyclePowerResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynSpotCamApiMsgsPowerStatus;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsCyclePowerResponseConst {
  HEADER_FIELD_SET = 1,
  STATUS_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsDebugRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  enable_temperature: boolean;
  enable_humidity: boolean;
  enable_bit: boolean;
  enable_shock: boolean;
  enable_system_stat: boolean;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsDebugRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsDebugResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsDebugResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsDeleteRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  point: IRosTypeBosdynSpotCamApiMsgsLogpoint;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsDeleteRequestConst {
  HEADER_FIELD_SET = 1,
  POINT_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsDeleteResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsDeleteResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsDeleteSoundRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  sound: IRosTypeBosdynSpotCamApiMsgsSound;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsDeleteSoundRequestConst {
  HEADER_FIELD_SET = 1,
  SOUND_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsDeleteSoundResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsDeleteSoundResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsEnableCongestionControlRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  enable_congestion_control: boolean;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsEnableCongestionControlRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsEnableCongestionControlResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsEnableCongestionControlResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetAudioCaptureChannelRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetAudioCaptureChannelRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetAudioCaptureChannelResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  channel: IRosTypeBosdynSpotCamApiMsgsAudioCaptureChannel;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetAudioCaptureChannelResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetAudioCaptureGainRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  channel: IRosTypeBosdynSpotCamApiMsgsAudioCaptureChannel;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetAudioCaptureGainRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetAudioCaptureGainResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  gain: number;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetAudioCaptureGainResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetBitStatusRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetBitStatusRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetBitStatusResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  events: IRosTypeBosdynApiMsgsSystemFault[];
  degradations: IRosTypeBosdynSpotCamApiMsgsGetBitStatusResponseDegradation[];
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetBitStatusResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetBitStatusResponseDegradation {
  type: IRosTypeBosdynSpotCamApiMsgsGetBitStatusResponseDegradationDegradationType;
  description: string;
}

export interface IRosTypeBosdynSpotCamApiMsgsGetBitStatusResponseDegradationDegradationType {
  value: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetBitStatusResponseDegradationDegradationTypeConst {
  STORAGE = 0,
  PTZ = 1,
  LED = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetIceConfigurationRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetIceConfigurationRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetIceConfigurationResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  servers: IRosTypeBosdynSpotCamApiMsgsIceServer[];
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetIceConfigurationResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetIrColormapRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetIrColormapRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetIrColormapResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  map: IRosTypeBosdynSpotCamApiMsgsIrColorMap;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetIrColormapResponseConst {
  HEADER_FIELD_SET = 1,
  MAP_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetIrMeterOverlayRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetIrMeterOverlayRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetIrMeterOverlayResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  overlay: IRosTypeBosdynSpotCamApiMsgsIrMeterOverlay;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetIrMeterOverlayResponseConst {
  HEADER_FIELD_SET = 1,
  OVERLAY_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetLedBrightnessRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetLedBrightnessRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetLedBrightnessResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  brightnesses: number[];
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetLedBrightnessResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetNetworkSettingsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetNetworkSettingsRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetNetworkSettingsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  settings: IRosTypeBosdynSpotCamApiMsgsNetworkTuple;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetNetworkSettingsResponseConst {
  HEADER_FIELD_SET = 1,
  SETTINGS_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetPowerStatusRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetPowerStatusRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetPowerStatusResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynSpotCamApiMsgsPowerStatus;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetPowerStatusResponseConst {
  HEADER_FIELD_SET = 1,
  STATUS_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetPtzFocusStateRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetPtzFocusStateRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetPtzFocusStateResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  focus_state: IRosTypeBosdynSpotCamApiMsgsPtzFocusState;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetPtzFocusStateResponseConst {
  HEADER_FIELD_SET = 1,
  FOCUS_STATE_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetPtzPositionRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  ptz: IRosTypeBosdynSpotCamApiMsgsPtzDescription;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetPtzPositionRequestConst {
  HEADER_FIELD_SET = 1,
  PTZ_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetPtzPositionResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  position: IRosTypeBosdynSpotCamApiMsgsPtzPosition;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetPtzPositionResponseConst {
  HEADER_FIELD_SET = 1,
  POSITION_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetPtzVelocityRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  ptz: IRosTypeBosdynSpotCamApiMsgsPtzDescription;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetPtzVelocityRequestConst {
  HEADER_FIELD_SET = 1,
  PTZ_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetPtzVelocityResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  velocity: IRosTypeBosdynSpotCamApiMsgsPtzVelocity;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetPtzVelocityResponseConst {
  HEADER_FIELD_SET = 1,
  VELOCITY_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetScreenRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetScreenRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetScreenResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  name: string;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetScreenResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetSoftwareVersionRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetSoftwareVersionRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetSoftwareVersionResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  version: IRosTypeBosdynApiMsgsSoftwareVersion;
  detail: string;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetSoftwareVersionResponseConst {
  HEADER_FIELD_SET = 1,
  VERSION_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetSslCertRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetSslCertRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetSslCertResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  certificate: string;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetSslCertResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetStatusRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  point: IRosTypeBosdynSpotCamApiMsgsLogpoint;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetStatusRequestConst {
  HEADER_FIELD_SET = 1,
  POINT_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetStatusResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  point: IRosTypeBosdynSpotCamApiMsgsLogpoint;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetStatusResponseConst {
  HEADER_FIELD_SET = 1,
  POINT_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetStreamParamsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetStreamParamsRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetStreamParamsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  params: IRosTypeBosdynSpotCamApiMsgsStreamParams;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetStreamParamsResponseConst {
  HEADER_FIELD_SET = 1,
  PARAMS_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetSystemLogRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetSystemLogRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetSystemLogResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  data: IRosTypeBosdynApiMsgsDataChunk;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetSystemLogResponseConst {
  HEADER_FIELD_SET = 1,
  DATA_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetTemperatureRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetTemperatureRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetTemperatureResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  temps: IRosTypeBosdynSpotCamApiMsgsTemperature[];
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetTemperatureResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetVisibleCamerasRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetVisibleCamerasRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetVisibleCamerasResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  streams: IRosTypeBosdynSpotCamApiMsgsGetVisibleCamerasResponseStream[];
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetVisibleCamerasResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetVisibleCamerasResponseStream {
  window: IRosTypeBosdynSpotCamApiMsgsGetVisibleCamerasResponseStreamWindow;
  camera: IRosTypeBosdynSpotCamApiMsgsCamera;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetVisibleCamerasResponseStreamConst {
  WINDOW_FIELD_SET = 1,
  CAMERA_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetVisibleCamerasResponseStreamWindow {
  xoffset: number;
  yoffset: number;
  width: number;
  height: number;
}

export interface IRosTypeBosdynSpotCamApiMsgsGetVolumeRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetVolumeRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsGetVolumeResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  volume: number;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsGetVolumeResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsIceServer {
  type: IRosTypeBosdynSpotCamApiMsgsIceServerServertype;
  address: string;
  port: number;
  transport: IRosTypeBosdynSpotCamApiMsgsIceServerIcetransport;
  auth: IRosTypeBosdynSpotCamApiMsgsIceServerAuthParams;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsIceServerConst {
  AUTH_FIELD_SET = 16,
}

export interface IRosTypeBosdynSpotCamApiMsgsIceServerAuthParams {
  username: IRosTypeStdMsgsString;
  credential: IRosTypeBosdynSpotCamApiMsgsIceServerAuthParamsOneOfCredential;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsIceServerAuthParamsConst {
  USERNAME_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsIceServerAuthParamsOauthPair {
  mackey: string;
  accesstoken: string;
}

export interface IRosTypeBosdynSpotCamApiMsgsIceServerAuthParamsOneOfCredential {
  oauth: IRosTypeBosdynSpotCamApiMsgsIceServerAuthParamsOauthPair;
  password: IRosTypeStdMsgsString;
  credential_choice: number;
  which: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsIceServerAuthParamsOneOfCredentialConst {
  CREDENTIAL_NOT_SET = 0,
  CREDENTIAL_OAUTH_SET = 1,
  CREDENTIAL_PASSWORD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsIceServerIcetransport {
  value: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsIceServerIcetransportConst {
  TRANSPORT_UNKNOWN = 0,
  TRANSPORT_UDP = 1,
  TRANSPORT_TCP = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsIceServerServertype {
  value: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsIceServerServertypeConst {
  UNKNOWN = 0,
  STUN = 1,
  TURN = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsInitializeLensRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsInitializeLensRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsInitializeLensResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsInitializeLensResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsIrColorMap {
  colormap: IRosTypeBosdynSpotCamApiMsgsIrColorMapColorMap;
  scale: IRosTypeBosdynSpotCamApiMsgsIrColorMapScalingPair;
  auto_scale: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsIrColorMapConst {
  SCALE_FIELD_SET = 2,
  AUTO_SCALE_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotCamApiMsgsIrColorMapColorMap {
  value: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsIrColorMapColorMapConst {
  COLORMAP_UNKNOWN = 0,
  COLORMAP_GREYSCALE = 1,
  COLORMAP_JET = 2,
  COLORMAP_INFERNO = 3,
  COLORMAP_TURBO = 4,
}

export interface IRosTypeBosdynSpotCamApiMsgsIrColorMapScalingPair {
  min: number;
  max: number;
}

export interface IRosTypeBosdynSpotCamApiMsgsIrMeterOverlay {
  enable: boolean;
  coords: IRosTypeBosdynSpotCamApiMsgsIrMeterOverlayNormalizedCoordinates;
  meter: IRosTypeBosdynSpotCamApiMsgsIrMeterOverlayNormalizedCoordinates[];
  unit: IRosTypeBosdynSpotCamApiMsgsIrMeterOverlayTempUnit;
  delta: IRosTypeBosdynSpotCamApiMsgsIrMeterOverlayDeltaPair[];
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsIrMeterOverlayConst {
  COORDS_FIELD_SET = 2,
  UNIT_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotCamApiMsgsIrMeterOverlayDeltaPair {
  a: number;
  b: number;
}

export interface IRosTypeBosdynSpotCamApiMsgsIrMeterOverlayNormalizedCoordinates {
  x: number;
  y: number;
}

export interface IRosTypeBosdynSpotCamApiMsgsIrMeterOverlayTempUnit {
  value: IRosTypeBosdynSpotCamApiMsgsIrMeterOverlayTempUnitTempUnitType;
}

export interface IRosTypeBosdynSpotCamApiMsgsIrMeterOverlayTempUnitTempUnitType {
  value: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsIrMeterOverlayTempUnitTempUnitTypeConst {
  TEMPUNIT_UNKNOWN = 0,
  TEMPUNIT_CELSIUS = 1,
  TEMPUNIT_FAHRENHEIT = 2,
  TEMPUNIT_KELVIN = 3,
}

export interface IRosTypeBosdynSpotCamApiMsgsListCamerasRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsListCamerasRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsListCamerasResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  cameras: IRosTypeBosdynSpotCamApiMsgsCamera[];
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsListCamerasResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsListLogpointsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsListLogpointsRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsListLogpointsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  logpoints: IRosTypeBosdynSpotCamApiMsgsLogpoint[];
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsListLogpointsResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsListPtzRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsListPtzRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsListPtzResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  ptzs: IRosTypeBosdynSpotCamApiMsgsPtzDescription[];
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsListPtzResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsListScreensRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsListScreensRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsListScreensResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  screens: IRosTypeBosdynSpotCamApiMsgsScreenDescription[];
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsListScreensResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsListSoundsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsListSoundsRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsListSoundsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  sounds: IRosTypeBosdynSpotCamApiMsgsSound[];
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsListSoundsResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsLoadSoundRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  sound: IRosTypeBosdynSpotCamApiMsgsSound;
  data: IRosTypeBosdynApiMsgsDataChunk;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsLoadSoundRequestConst {
  HEADER_FIELD_SET = 1,
  SOUND_FIELD_SET = 2,
  DATA_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotCamApiMsgsLoadSoundResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsLoadSoundResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsLogpoint {
  name: string;
  type: IRosTypeBosdynSpotCamApiMsgsLogpointRecordType;
  status: IRosTypeBosdynSpotCamApiMsgsLogpointLogStatus;
  queue_status: IRosTypeBosdynSpotCamApiMsgsLogpointQueueStatus;
  tag: string;
  timestamp: { sec: number; nanosec: number };
  image_params: IRosTypeBosdynSpotCamApiMsgsLogpointImageParams;
  calibration: IRosTypeBosdynSpotCamApiMsgsLogpointCalibration[];
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsLogpointConst {
  TIMESTAMP_FIELD_SET = 32,
  IMAGE_PARAMS_FIELD_SET = 64,
}

export interface IRosTypeBosdynSpotCamApiMsgsLogpointCalibration {
  xoffset: number;
  yoffset: number;
  width: number;
  height: number;
  base_frame_name: string;
  base_tfrom_sensor: IRosTypeGeometryMsgsPose;
  base_tform_sensor: IRosTypeGeometryMsgsPose;
  intrinsics: IRosTypeBosdynSpotCamApiMsgsCameraPinholeIntrinsics;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsLogpointCalibrationConst {
  BASE_TFROM_SENSOR_FIELD_SET = 32,
  BASE_TFORM_SENSOR_FIELD_SET = 64,
  INTRINSICS_FIELD_SET = 128,
}

export interface IRosTypeBosdynSpotCamApiMsgsLogpointImageParams {
  width: number;
  height: number;
  format: IRosTypeBosdynApiMsgsImagePixelFormat;
}

export interface IRosTypeBosdynSpotCamApiMsgsLogpointLogStatus {
  value: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsLogpointLogStatusConst {
  FAILED = 0,
  QUEUED = 1,
  COMPLETE = 2,
  UNKNOWN = -1,
}

export interface IRosTypeBosdynSpotCamApiMsgsLogpointQueueStatus {
  value: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsLogpointQueueStatusConst {
  QUEUED_UNKNOWN = 0,
  QUEUED_RENDER = 1,
  QUEUED_DISK = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsLogpointRecordType {
  value: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsLogpointRecordTypeConst {
  STILLIMAGE = 0,
}

export interface IRosTypeBosdynSpotCamApiMsgsNetworkTuple {
  address: IRosTypeStdMsgsUInt32;
  netmask: IRosTypeStdMsgsUInt32;
  gateway: IRosTypeStdMsgsUInt32;
  mtu: IRosTypeStdMsgsUInt32;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsNetworkTupleConst {
  ADDRESS_FIELD_SET = 1,
  NETMASK_FIELD_SET = 2,
  GATEWAY_FIELD_SET = 4,
  MTU_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotCamApiMsgsPlaySoundRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  sound: IRosTypeBosdynSpotCamApiMsgsSound;
  gain: IRosTypeStdMsgsFloat32;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsPlaySoundRequestConst {
  HEADER_FIELD_SET = 1,
  SOUND_FIELD_SET = 2,
  GAIN_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotCamApiMsgsPlaySoundResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsPlaySoundResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsPowerStatus {
  ptz: IRosTypeStdMsgsBool;
  aux1: IRosTypeStdMsgsBool;
  aux2: IRosTypeStdMsgsBool;
  external_mic: IRosTypeStdMsgsBool;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsPowerStatusConst {
  PTZ_FIELD_SET = 1,
  AUX1_FIELD_SET = 2,
  AUX2_FIELD_SET = 4,
  EXTERNAL_MIC_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotCamApiMsgsPtzDescription {
  name: string;
  pan_limit: IRosTypeBosdynSpotCamApiMsgsPtzDescriptionLimits;
  tilt_limit: IRosTypeBosdynSpotCamApiMsgsPtzDescriptionLimits;
  zoom_limit: IRosTypeBosdynSpotCamApiMsgsPtzDescriptionLimits;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsPtzDescriptionConst {
  PAN_LIMIT_FIELD_SET = 2,
  TILT_LIMIT_FIELD_SET = 4,
  ZOOM_LIMIT_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotCamApiMsgsPtzDescriptionLimits {
  min: IRosTypeStdMsgsFloat32;
  max: IRosTypeStdMsgsFloat32;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsPtzDescriptionLimitsConst {
  MIN_FIELD_SET = 1,
  MAX_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsPtzFocusState {
  mode: IRosTypeBosdynSpotCamApiMsgsPtzFocusStatePtzFocusMode;
  focus_position: IRosTypeStdMsgsInt32;
  approx_distance: IRosTypeStdMsgsFloat32;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsPtzFocusStateConst {
  FOCUS_POSITION_FIELD_SET = 2,
  APPROX_DISTANCE_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotCamApiMsgsPtzFocusStatePtzFocusMode {
  value: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsPtzFocusStatePtzFocusModeConst {
  PTZ_FOCUS_UNDEFINED = 0,
  PTZ_FOCUS_AUTO = 1,
  PTZ_FOCUS_MANUAL = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsPtzPosition {
  ptz: IRosTypeBosdynSpotCamApiMsgsPtzDescription;
  pan: IRosTypeStdMsgsFloat32;
  tilt: IRosTypeStdMsgsFloat32;
  zoom: IRosTypeStdMsgsFloat32;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsPtzPositionConst {
  PTZ_FIELD_SET = 1,
  PAN_FIELD_SET = 2,
  TILT_FIELD_SET = 4,
  ZOOM_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotCamApiMsgsPtzVelocity {
  ptz: IRosTypeBosdynSpotCamApiMsgsPtzDescription;
  pan: IRosTypeStdMsgsFloat32;
  tilt: IRosTypeStdMsgsFloat32;
  zoom: IRosTypeStdMsgsFloat32;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsPtzVelocityConst {
  PTZ_FIELD_SET = 1,
  PAN_FIELD_SET = 2,
  TILT_FIELD_SET = 4,
  ZOOM_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotCamApiMsgsRetrieveRawDataRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  point: IRosTypeBosdynSpotCamApiMsgsLogpoint;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsRetrieveRawDataRequestConst {
  HEADER_FIELD_SET = 1,
  POINT_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsRetrieveRawDataResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  logpoint: IRosTypeBosdynSpotCamApiMsgsLogpoint;
  data: IRosTypeBosdynApiMsgsDataChunk;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsRetrieveRawDataResponseConst {
  HEADER_FIELD_SET = 1,
  LOGPOINT_FIELD_SET = 2,
  DATA_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotCamApiMsgsRetrieveRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  point: IRosTypeBosdynSpotCamApiMsgsLogpoint;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsRetrieveRequestConst {
  HEADER_FIELD_SET = 1,
  POINT_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsRetrieveResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  logpoint: IRosTypeBosdynSpotCamApiMsgsLogpoint;
  data: IRosTypeBosdynApiMsgsDataChunk;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsRetrieveResponseConst {
  HEADER_FIELD_SET = 1,
  LOGPOINT_FIELD_SET = 2,
  DATA_FIELD_SET = 4,
}

export interface IRosTypeBosdynSpotCamApiMsgsScreenDescription {
  name: string;
}

export interface IRosTypeBosdynSpotCamApiMsgsSetAudioCaptureChannelRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  channel: IRosTypeBosdynSpotCamApiMsgsAudioCaptureChannel;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetAudioCaptureChannelRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetAudioCaptureChannelResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetAudioCaptureChannelResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetAudioCaptureGainRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  channel: IRosTypeBosdynSpotCamApiMsgsAudioCaptureChannel;
  gain: number;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetAudioCaptureGainRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetAudioCaptureGainResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetAudioCaptureGainResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetIceConfigurationRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  servers: IRosTypeBosdynSpotCamApiMsgsIceServer[];
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetIceConfigurationRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetIceConfigurationResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetIceConfigurationResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetIrColormapRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  map: IRosTypeBosdynSpotCamApiMsgsIrColorMap;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetIrColormapRequestConst {
  HEADER_FIELD_SET = 1,
  MAP_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetIrColormapResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetIrColormapResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetIrMeterOverlayRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  overlay: IRosTypeBosdynSpotCamApiMsgsIrMeterOverlay;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetIrMeterOverlayRequestConst {
  HEADER_FIELD_SET = 1,
  OVERLAY_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetIrMeterOverlayResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetIrMeterOverlayResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetLedBrightnessRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  brightnesses: IRosTypeBosdynSpotCamApiMsgsSetLedBrightnessRequestBrightnessesEntry[];
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetLedBrightnessRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetLedBrightnessRequestBrightnessesEntry {
  key: number;
  value: number;
}

export interface IRosTypeBosdynSpotCamApiMsgsSetLedBrightnessResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetLedBrightnessResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetPassphraseRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  passphrase: string;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetPassphraseRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetPassphraseResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetPassphraseResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetPowerStatusRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  status: IRosTypeBosdynSpotCamApiMsgsPowerStatus;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetPowerStatusRequestConst {
  HEADER_FIELD_SET = 1,
  STATUS_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetPowerStatusResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  status: IRosTypeBosdynSpotCamApiMsgsPowerStatus;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetPowerStatusResponseConst {
  HEADER_FIELD_SET = 1,
  STATUS_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetPtzFocusStateRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  focus_state: IRosTypeBosdynSpotCamApiMsgsPtzFocusState;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetPtzFocusStateRequestConst {
  HEADER_FIELD_SET = 1,
  FOCUS_STATE_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetPtzFocusStateResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetPtzFocusStateResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetPtzPositionRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  position: IRosTypeBosdynSpotCamApiMsgsPtzPosition;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetPtzPositionRequestConst {
  HEADER_FIELD_SET = 1,
  POSITION_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetPtzPositionResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  position: IRosTypeBosdynSpotCamApiMsgsPtzPosition;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetPtzPositionResponseConst {
  HEADER_FIELD_SET = 1,
  POSITION_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetPtzVelocityRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  velocity: IRosTypeBosdynSpotCamApiMsgsPtzVelocity;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetPtzVelocityRequestConst {
  HEADER_FIELD_SET = 1,
  VELOCITY_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetPtzVelocityResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  velocity: IRosTypeBosdynSpotCamApiMsgsPtzVelocity;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetPtzVelocityResponseConst {
  HEADER_FIELD_SET = 1,
  VELOCITY_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetScreenRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  name: string;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetScreenRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetScreenResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  name: string;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetScreenResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetStreamParamsRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  params: IRosTypeBosdynSpotCamApiMsgsStreamParams;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetStreamParamsRequestConst {
  HEADER_FIELD_SET = 1,
  PARAMS_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetStreamParamsResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  params: IRosTypeBosdynSpotCamApiMsgsStreamParams;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetStreamParamsResponseConst {
  HEADER_FIELD_SET = 1,
  PARAMS_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetVolumeRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  volume: number;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetVolumeRequestConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSetVolumeResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsSetVolumeResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsSound {
  name: string;
}

export interface IRosTypeBosdynSpotCamApiMsgsStoreRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  camera: IRosTypeBosdynSpotCamApiMsgsCamera;
  type: IRosTypeBosdynSpotCamApiMsgsLogpointRecordType;
  tag: string;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsStoreRequestConst {
  HEADER_FIELD_SET = 1,
  CAMERA_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsStoreResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  point: IRosTypeBosdynSpotCamApiMsgsLogpoint;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsStoreResponseConst {
  HEADER_FIELD_SET = 1,
  POINT_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsStreamParams {
  targetbitrate: IRosTypeStdMsgsInt64;
  refreshinterval: IRosTypeStdMsgsInt64;
  idrinterval: IRosTypeStdMsgsInt64;
  awb: IRosTypeBosdynSpotCamApiMsgsStreamParamsAwbMode;
  exposure: IRosTypeBosdynSpotCamApiMsgsStreamParamsOneOfExposure;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsStreamParamsConst {
  TARGETBITRATE_FIELD_SET = 1,
  REFRESHINTERVAL_FIELD_SET = 2,
  IDRINTERVAL_FIELD_SET = 4,
  AWB_FIELD_SET = 8,
}

export interface IRosTypeBosdynSpotCamApiMsgsStreamParamsAwbMode {
  awb: IRosTypeBosdynSpotCamApiMsgsStreamParamsAwbModeEnum;
}

export interface IRosTypeBosdynSpotCamApiMsgsStreamParamsAwbModeEnum {
  value: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsStreamParamsAwbModeEnumConst {
  OFF = 0,
  AUTO = 1,
  INCANDESCENT = 2,
  FLUORESCENT = 3,
  WARM_FLUORESCENT = 4,
  DAYLIGHT = 5,
  CLOUDY = 6,
  TWILIGHT = 7,
  SHADE = 8,
  DARK = 9,
}

export interface IRosTypeBosdynSpotCamApiMsgsStreamParamsManualExposure {
  exposure: { sec: number; nanosec: number };
  gain: IRosTypeStdMsgsFloat32;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsStreamParamsManualExposureConst {
  EXPOSURE_FIELD_SET = 1,
  GAIN_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsStreamParamsOneOfExposure {
  sync_exposure: IRosTypeBosdynSpotCamApiMsgsStreamParamsSyncAutoExposure;
  manual_exposure: IRosTypeBosdynSpotCamApiMsgsStreamParamsManualExposure;
  exposure_choice: number;
  which: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsStreamParamsOneOfExposureConst {
  EXPOSURE_NOT_SET = 0,
  EXPOSURE_AUTO_EXPOSURE_SET = 1,
  EXPOSURE_SYNC_EXPOSURE_SET = 2,
  EXPOSURE_MANUAL_EXPOSURE_SET = 3,
}

export interface IRosTypeBosdynSpotCamApiMsgsStreamParamsSyncAutoExposure {
  brightness_target: IRosTypeStdMsgsInt32;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsStreamParamsSyncAutoExposureConst {
  BRIGHTNESS_TARGET_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsTagRequest {
  header: IRosTypeBosdynApiMsgsRequestHeader;
  point: IRosTypeBosdynSpotCamApiMsgsLogpoint;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsTagRequestConst {
  HEADER_FIELD_SET = 1,
  POINT_FIELD_SET = 2,
}

export interface IRosTypeBosdynSpotCamApiMsgsTagResponse {
  header: IRosTypeBosdynApiMsgsResponseHeader;
  has_field: number;
}

export enum IRosTypeBosdynSpotCamApiMsgsTagResponseConst {
  HEADER_FIELD_SET = 1,
}

export interface IRosTypeBosdynSpotCamApiMsgsTemperature {
  channel_name: string;
  temperature: number;
}

export interface IRosTypeBuiltinInterfacesDuration {
  sec: number;
  nanosec: number;
}

export interface IRosTypeBuiltinInterfacesTime {
  sec: number;
  nanosec: number;
}

export interface IRosTypeCommonPayloadMessagesActionExecutorActionFeedback {
  feedback_msg: string;
}

export interface IRosTypeCommonPayloadMessagesActionExecutorActionGoal {
  action_request: IRosTypeCommonPayloadMessagesMissionAction;
}

export interface IRosTypeCommonPayloadMessagesActionExecutorActionResult {
  success: boolean;
  message: string;
}

export interface IRosTypeCommonPayloadMessagesActionPair {
  action_id: string;
  action_server_name: string;
}

export interface IRosTypeCommonPayloadMessagesAvailableActions {
  available_actions: string[];
  backend: string;
}

export interface IRosTypeCommonPayloadMessagesAvailableInternalBackendActions {
  available_actions: IRosTypeCommonPayloadMessagesActionPair[];
}

export interface IRosTypeCommonPayloadMessagesExecuteAction {
  request: IRosTypeCommonPayloadMessagesExecuteActionRequest;
  response: IRosTypeCommonPayloadMessagesExecuteActionResponse;
}

export interface IRosTypeCommonPayloadMessagesExecuteActionRequest {
  action_request: IRosTypeCommonPayloadMessagesMissionAction;
}

export interface IRosTypeCommonPayloadMessagesExecuteActionResponse {
  success: boolean;
  request_parsed_successfully: boolean;
  msg: string;
}

export interface IRosTypeCommonPayloadMessagesLoadDatabase {
  request: IRosTypeCommonPayloadMessagesLoadDatabaseRequest;
}

export interface IRosTypeCommonPayloadMessagesLoadDatabaseRequest {
  database_path: string;
  clear: boolean;
}

export interface IRosTypeCommonPayloadMessagesMissionAction {
  id: string;
  params: IRosTypeCommonPayloadMessagesMissionActionParameter[];
  has_location: boolean;
  location: IRosTypeGeometryMsgsVector3;
  robot_action_id: number;
}

export interface IRosTypeCommonPayloadMessagesMissionActionParameter {
  key: string;
  value: string;
}

export interface IRosTypeCommonPayloadMessagesMissionExecutorActionFeedback {
  mission_accepted: boolean;
  message: string;
  completed_action: number;
  total_actions: number;
}

export interface IRosTypeCommonPayloadMessagesMissionExecutorActionGoal {
  map_id: string;
  mission_id: string;
  actions: IRosTypeCommonPayloadMessagesMissionAction[];
}

export interface IRosTypeCommonPayloadMessagesMissionExecutorActionResult {
  msg: string;
}

export interface IRosTypeCommonPayloadMessagesMissionModuleStatus {
  mission_status: IRosTypeCommonPayloadMessagesMissionStatus;
  mission_plan_id: string;
  mission_session_id: string;
  available_actions: string[];
}

export interface IRosTypeCommonPayloadMessagesMissionProgress {
  completed_action: number;
  total_actions: number;
}

export interface IRosTypeCommonPayloadMessagesMissionStatus {
  mission_plan_id: string;
  mission_session_id: string;
  running: boolean;
  success: boolean;
  error: string;
  progress: IRosTypeCommonPayloadMessagesMissionProgress;
}

export interface IRosTypeCommonPayloadMessagesRegisterAction {
  request: IRosTypeCommonPayloadMessagesRegisterActionRequest;
  response: IRosTypeCommonPayloadMessagesRegisterActionResponse;
}

export interface IRosTypeCommonPayloadMessagesRegisterActionRequest {
  action_id: string;
  action_server_name: string;
  is_active: boolean;
}

export interface IRosTypeCommonPayloadMessagesRegisterActionResponse {
  success: boolean;
  feedback: string;
}

export interface IRosTypeCompositionInterfacesListNodes {
  response: IRosTypeCompositionInterfacesListNodesResponse;
}

export interface IRosTypeCompositionInterfacesListNodesResponse {
  full_node_names: string[];
  unique_ids: number[];
}

export interface IRosTypeCompositionInterfacesLoadNode {
  request: IRosTypeCompositionInterfacesLoadNodeRequest;
  response: IRosTypeCompositionInterfacesLoadNodeResponse;
}

export interface IRosTypeCompositionInterfacesLoadNodeRequest {
  package_name: string;
  plugin_name: string;
  node_name: string;
  node_namespace: string;
  log_level: number;
  remap_rules: string[];
  parameters: IRosTypeRclInterfacesParameter[];
  extra_arguments: IRosTypeRclInterfacesParameter[];
}

export interface IRosTypeCompositionInterfacesLoadNodeResponse {
  success: boolean;
  error_message: string;
  full_node_name: string;
  unique_id: number;
}

export interface IRosTypeCompositionInterfacesUnloadNode {
  request: IRosTypeCompositionInterfacesUnloadNodeRequest;
  response: IRosTypeCompositionInterfacesUnloadNodeResponse;
}

export interface IRosTypeCompositionInterfacesUnloadNodeRequest {
  unique_id: number;
}

export interface IRosTypeCompositionInterfacesUnloadNodeResponse {
  success: boolean;
  error_message: string;
}

export interface IRosTypeDataAcquisitionInterfacesAcquiredDataUploadFeedback {
  filename: string;
  success: boolean;
  message: string;
}

export interface IRosTypeDataAcquisitionInterfacesAcquiredDataUploadPendingFiles {
  json_file_name: string[];
}

export interface IRosTypeDataAcquisitionInterfacesAcquiredDataUploadPendingSize {
  no_total_pending_files: number;
  total_pending_files_gb: number;
}

export interface IRosTypeDataAcquisitionInterfacesAcquiredDataUploadRequestUpload {
  request: IRosTypeDataAcquisitionInterfacesAcquiredDataUploadRequestUploadRequest;
  response: IRosTypeDataAcquisitionInterfacesAcquiredDataUploadRequestUploadResponse;
}

export interface IRosTypeDataAcquisitionInterfacesAcquiredDataUploadRequestUploadAll {
  response: IRosTypeDataAcquisitionInterfacesAcquiredDataUploadRequestUploadAllResponse;
}

export interface IRosTypeDataAcquisitionInterfacesAcquiredDataUploadRequestUploadAllResponse {
  success: boolean;
  failed_to_queue: string[];
  succeeded_to_queue: string[];
}

export interface IRosTypeDataAcquisitionInterfacesAcquiredDataUploadRequestUploadRequest {
  requested_json_file: string;
  priority: number;
}

export enum IRosTypeDataAcquisitionInterfacesAcquiredDataUploadRequestUploadRequestConst {
  NO_PRIORITY = 100,
  HIGHEST_PRIORITY = 0,
}

export interface IRosTypeDataAcquisitionInterfacesAcquiredDataUploadRequestUploadResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeDataAcquisitionInterfacesDataAcquisitionData {
  data_type: string;
  data: string;
}

export interface IRosTypeDataAcquisitionInterfacesDataAcquisitionDataFrame {
  data: IRosTypeDataAcquisitionInterfacesDataAcquisitionData;
  timestamp: string;
  location: IRosTypeSpotLocalizationHandlerInterfacesLocalizationLocation;
  unique_session_id: string;
  external_reference: string;
  robot_action_id: number;
}

export interface IRosTypeDataAcquisitionInterfacesGetCurrentDataFrame {
  response: IRosTypeDataAcquisitionInterfacesGetCurrentDataFrameResponse;
}

export interface IRosTypeDataAcquisitionInterfacesGetCurrentDataFrameResponse {
  frame: IRosTypeDataAcquisitionInterfacesDataAcquisitionDataFrame;
}

export interface IRosTypeDataAcquisitionInterfacesSendData {
  request: IRosTypeDataAcquisitionInterfacesSendDataRequest;
  response: IRosTypeDataAcquisitionInterfacesSendDataResponse;
}

export interface IRosTypeDataAcquisitionInterfacesSendDataFrame {
  request: IRosTypeDataAcquisitionInterfacesSendDataFrameRequest;
  response: IRosTypeDataAcquisitionInterfacesSendDataFrameResponse;
}

export interface IRosTypeDataAcquisitionInterfacesSendDataFrameRequest {
  frame: IRosTypeDataAcquisitionInterfacesDataAcquisitionDataFrame;
}

export interface IRosTypeDataAcquisitionInterfacesSendDataFrameResponse {
  success: boolean;
  acquired_data_id: number;
}

export interface IRosTypeDataAcquisitionInterfacesSendDataRequest {
  data: IRosTypeDataAcquisitionInterfacesDataAcquisitionData;
}

export interface IRosTypeDataAcquisitionInterfacesSendDataResponse {
  success: boolean;
  acquired_data_id: number;
}

export interface IRosTypeDiagnosticMsgsAddDiagnostics {
  request: IRosTypeDiagnosticMsgsAddDiagnosticsRequest;
  response: IRosTypeDiagnosticMsgsAddDiagnosticsResponse;
}

export interface IRosTypeDiagnosticMsgsAddDiagnosticsRequest {
  load_namespace: string;
}

export interface IRosTypeDiagnosticMsgsAddDiagnosticsResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeDiagnosticMsgsDiagnosticArray {
  header: IRosTypeStdMsgsHeader;
  status: IRosTypeDiagnosticMsgsDiagnosticStatus[];
}

export interface IRosTypeDiagnosticMsgsDiagnosticStatus {
  level: number;
  name: string;
  message: string;
  hardware_id: string;
  values: IRosTypeDiagnosticMsgsKeyValue[];
}

export enum IRosTypeDiagnosticMsgsDiagnosticStatusConst {
  OK = 0,
  WARN = 1,
  ERROR = 2,
  STALE = 3,
}

export interface IRosTypeDiagnosticMsgsKeyValue {
  key: string;
  value: string;
}

export interface IRosTypeDiagnosticMsgsSelfTest {
  response: IRosTypeDiagnosticMsgsSelfTestResponse;
}

export interface IRosTypeDiagnosticMsgsSelfTestResponse {
  id: string;
  passed: number;
  status: IRosTypeDiagnosticMsgsDiagnosticStatus[];
}

export interface IRosTypeGeigerCounterInterfacesCurrentRadiation {
  current_radiation: number;
  accumulated_radiation: number;
}

export interface IRosTypeGeometryMsgsAccel {
  linear: IRosTypeGeometryMsgsVector3;
  angular: IRosTypeGeometryMsgsVector3;
}

export interface IRosTypeGeometryMsgsAccelStamped {
  header: IRosTypeStdMsgsHeader;
  accel: IRosTypeGeometryMsgsAccel;
}

export interface IRosTypeGeometryMsgsAccelWithCovariance {
  accel: IRosTypeGeometryMsgsAccel;
  covariance: number[];
}

export interface IRosTypeGeometryMsgsAccelWithCovarianceStamped {
  header: IRosTypeStdMsgsHeader;
  accel: IRosTypeGeometryMsgsAccelWithCovariance;
}

export interface IRosTypeGeometryMsgsInertia {
  m: number;
  com: IRosTypeGeometryMsgsVector3;
  ixx: number;
  ixy: number;
  ixz: number;
  iyy: number;
  iyz: number;
  izz: number;
}

export interface IRosTypeGeometryMsgsInertiaStamped {
  header: IRosTypeStdMsgsHeader;
  inertia: IRosTypeGeometryMsgsInertia;
}

export interface IRosTypeGeometryMsgsPoint {
  x: number;
  y: number;
  z: number;
}

export interface IRosTypeGeometryMsgsPoint32 {
  x: number;
  y: number;
  z: number;
}

export interface IRosTypeGeometryMsgsPointStamped {
  header: IRosTypeStdMsgsHeader;
  point: IRosTypeGeometryMsgsPoint;
}

export interface IRosTypeGeometryMsgsPolygon {
  points: IRosTypeGeometryMsgsPoint32[];
}

export interface IRosTypeGeometryMsgsPolygonStamped {
  header: IRosTypeStdMsgsHeader;
  polygon: IRosTypeGeometryMsgsPolygon;
}

export interface IRosTypeGeometryMsgsPose {
  position: IRosTypeGeometryMsgsPoint;
  orientation: IRosTypeGeometryMsgsQuaternion;
}

export interface IRosTypeGeometryMsgsPose2D {
  x: number;
  y: number;
  theta: number;
}

export interface IRosTypeGeometryMsgsPoseArray {
  header: IRosTypeStdMsgsHeader;
  poses: IRosTypeGeometryMsgsPose[];
}

export interface IRosTypeGeometryMsgsPoseStamped {
  header: IRosTypeStdMsgsHeader;
  pose: IRosTypeGeometryMsgsPose;
}

export interface IRosTypeGeometryMsgsPoseWithCovariance {
  pose: IRosTypeGeometryMsgsPose;
  covariance: number[];
}

export interface IRosTypeGeometryMsgsPoseWithCovarianceStamped {
  header: IRosTypeStdMsgsHeader;
  pose: IRosTypeGeometryMsgsPoseWithCovariance;
}

export interface IRosTypeGeometryMsgsQuaternion {
  x: number;
  y: number;
  z: number;
  w: number;
}

export interface IRosTypeGeometryMsgsQuaternionStamped {
  header: IRosTypeStdMsgsHeader;
  quaternion: IRosTypeGeometryMsgsQuaternion;
}

export interface IRosTypeGeometryMsgsTransform {
  translation: IRosTypeGeometryMsgsVector3;
  rotation: IRosTypeGeometryMsgsQuaternion;
}

export interface IRosTypeGeometryMsgsTransformStamped {
  header: IRosTypeStdMsgsHeader;
  child_frame_id: string;
  transform: IRosTypeGeometryMsgsTransform;
}

export interface IRosTypeGeometryMsgsTwist {
  linear: IRosTypeGeometryMsgsVector3;
  angular: IRosTypeGeometryMsgsVector3;
}

export interface IRosTypeGeometryMsgsTwistStamped {
  header: IRosTypeStdMsgsHeader;
  twist: IRosTypeGeometryMsgsTwist;
}

export interface IRosTypeGeometryMsgsTwistWithCovariance {
  twist: IRosTypeGeometryMsgsTwist;
  covariance: number[];
}

export interface IRosTypeGeometryMsgsTwistWithCovarianceStamped {
  header: IRosTypeStdMsgsHeader;
  twist: IRosTypeGeometryMsgsTwistWithCovariance;
}

export interface IRosTypeGeometryMsgsVector3 {
  x: number;
  y: number;
  z: number;
}

export interface IRosTypeGeometryMsgsVector3Stamped {
  header: IRosTypeStdMsgsHeader;
  vector: IRosTypeGeometryMsgsVector3;
}

export interface IRosTypeGeometryMsgsVelocityStamped {
  header: IRosTypeStdMsgsHeader;
  body_frame_id: string;
  reference_frame_id: string;
  velocity: IRosTypeGeometryMsgsTwist;
}

export interface IRosTypeGeometryMsgsWrench {
  force: IRosTypeGeometryMsgsVector3;
  torque: IRosTypeGeometryMsgsVector3;
}

export interface IRosTypeGeometryMsgsWrenchStamped {
  header: IRosTypeStdMsgsHeader;
  wrench: IRosTypeGeometryMsgsWrench;
}

export interface IRosTypeJanusStreamingInterfacesJanusSourceType {
  name: string;
  type: string;
}

export interface IRosTypeJanusStreamingInterfacesJanusSourceTypesList {
  available_janus_streams: IRosTypeJanusStreamingInterfacesJanusSourceType[];
}

export interface IRosTypeJanusStreamingInterfacesStreamChange {
  request: IRosTypeJanusStreamingInterfacesStreamChangeRequest;
  response: IRosTypeJanusStreamingInterfacesStreamChangeResponse;
}

export interface IRosTypeJanusStreamingInterfacesStreamChangeRequest {
  json_request: string;
}

export interface IRosTypeJanusStreamingInterfacesStreamChangeResponse {
  message: string;
}

export interface IRosTypeJanusStreamingInterfacesStreamState {
  response: IRosTypeJanusStreamingInterfacesStreamStateResponse;
}

export interface IRosTypeJanusStreamingInterfacesStreamStateResponse {
  message: string;
}

export interface IRosTypeLifecycleMsgsChangeState {
  request: IRosTypeLifecycleMsgsChangeStateRequest;
  response: IRosTypeLifecycleMsgsChangeStateResponse;
}

export interface IRosTypeLifecycleMsgsChangeStateRequest {
  transition: IRosTypeLifecycleMsgsTransition;
}

export interface IRosTypeLifecycleMsgsChangeStateResponse {
  success: boolean;
}

export interface IRosTypeLifecycleMsgsGetAvailableStates {
  response: IRosTypeLifecycleMsgsGetAvailableStatesResponse;
}

export interface IRosTypeLifecycleMsgsGetAvailableStatesResponse {
  available_states: IRosTypeLifecycleMsgsState[];
}

export interface IRosTypeLifecycleMsgsGetAvailableTransitions {
  response: IRosTypeLifecycleMsgsGetAvailableTransitionsResponse;
}

export interface IRosTypeLifecycleMsgsGetAvailableTransitionsResponse {
  available_transitions: IRosTypeLifecycleMsgsTransitionDescription[];
}

export interface IRosTypeLifecycleMsgsGetState {
  response: IRosTypeLifecycleMsgsGetStateResponse;
}

export interface IRosTypeLifecycleMsgsGetStateResponse {
  current_state: IRosTypeLifecycleMsgsState;
}

export interface IRosTypeLifecycleMsgsState {
  id: number;
  label: string;
}

export enum IRosTypeLifecycleMsgsStateConst {
  PRIMARY_STATE_UNKNOWN = 0,
  PRIMARY_STATE_UNCONFIGURED = 1,
  PRIMARY_STATE_INACTIVE = 2,
  PRIMARY_STATE_ACTIVE = 3,
  PRIMARY_STATE_FINALIZED = 4,
  TRANSITION_STATE_CONFIGURING = 10,
  TRANSITION_STATE_CLEANINGUP = 11,
  TRANSITION_STATE_SHUTTINGDOWN = 12,
  TRANSITION_STATE_ACTIVATING = 13,
  TRANSITION_STATE_DEACTIVATING = 14,
  TRANSITION_STATE_ERRORPROCESSING = 15,
}

export interface IRosTypeLifecycleMsgsTransition {
  id: number;
  label: string;
}

export enum IRosTypeLifecycleMsgsTransitionConst {
  TRANSITION_CREATE = 0,
  TRANSITION_CONFIGURE = 1,
  TRANSITION_CLEANUP = 2,
  TRANSITION_ACTIVATE = 3,
  TRANSITION_DEACTIVATE = 4,
  TRANSITION_UNCONFIGURED_SHUTDOWN = 5,
  TRANSITION_INACTIVE_SHUTDOWN = 6,
  TRANSITION_ACTIVE_SHUTDOWN = 7,
  TRANSITION_DESTROY = 8,
  TRANSITION_ON_CONFIGURE_SUCCESS = 10,
  TRANSITION_ON_CONFIGURE_FAILURE = 11,
  TRANSITION_ON_CONFIGURE_ERROR = 12,
  TRANSITION_ON_CLEANUP_SUCCESS = 20,
  TRANSITION_ON_CLEANUP_FAILURE = 21,
  TRANSITION_ON_CLEANUP_ERROR = 22,
  TRANSITION_ON_ACTIVATE_SUCCESS = 30,
  TRANSITION_ON_ACTIVATE_FAILURE = 31,
  TRANSITION_ON_ACTIVATE_ERROR = 32,
  TRANSITION_ON_DEACTIVATE_SUCCESS = 40,
  TRANSITION_ON_DEACTIVATE_FAILURE = 41,
  TRANSITION_ON_DEACTIVATE_ERROR = 42,
  TRANSITION_ON_SHUTDOWN_SUCCESS = 50,
  TRANSITION_ON_SHUTDOWN_FAILURE = 51,
  TRANSITION_ON_SHUTDOWN_ERROR = 52,
  TRANSITION_ON_ERROR_SUCCESS = 60,
  TRANSITION_ON_ERROR_FAILURE = 61,
  TRANSITION_ON_ERROR_ERROR = 62,
  TRANSITION_CALLBACK_SUCCESS = 97,
  TRANSITION_CALLBACK_FAILURE = 98,
  TRANSITION_CALLBACK_ERROR = 99,
}

export interface IRosTypeLifecycleMsgsTransitionDescription {
  transition: IRosTypeLifecycleMsgsTransition;
  start_state: IRosTypeLifecycleMsgsState;
  goal_state: IRosTypeLifecycleMsgsState;
}

export interface IRosTypeLifecycleMsgsTransitionEvent {
  timestamp: number;
  transition: IRosTypeLifecycleMsgsTransition;
  start_state: IRosTypeLifecycleMsgsState;
  goal_state: IRosTypeLifecycleMsgsState;
}

export interface IRosTypeNavMsgsGetMap {
  response: IRosTypeNavMsgsGetMapResponse;
}

export interface IRosTypeNavMsgsGetMapResponse {
  map: IRosTypeNavMsgsOccupancyGrid;
}

export interface IRosTypeNavMsgsGetPlan {
  request: IRosTypeNavMsgsGetPlanRequest;
  response: IRosTypeNavMsgsGetPlanResponse;
}

export interface IRosTypeNavMsgsGetPlanRequest {
  start: IRosTypeGeometryMsgsPoseStamped;
  goal: IRosTypeGeometryMsgsPoseStamped;
  tolerance: number;
}

export interface IRosTypeNavMsgsGetPlanResponse {
  plan: IRosTypeNavMsgsPath;
}

export interface IRosTypeNavMsgsGridCells {
  header: IRosTypeStdMsgsHeader;
  cell_width: number;
  cell_height: number;
  cells: IRosTypeGeometryMsgsPoint[];
}

export interface IRosTypeNavMsgsLoadMap {
  request: IRosTypeNavMsgsLoadMapRequest;
  response: IRosTypeNavMsgsLoadMapResponse;
}

export interface IRosTypeNavMsgsLoadMapRequest {
  map_url: string;
}

export interface IRosTypeNavMsgsLoadMapResponse {
  map: IRosTypeNavMsgsOccupancyGrid;
  result: number;
}

export enum IRosTypeNavMsgsLoadMapResponseConst {
  RESULT_SUCCESS = 0,
  RESULT_MAP_DOES_NOT_EXIST = 1,
  RESULT_INVALID_MAP_DATA = 2,
  RESULT_INVALID_MAP_METADATA = 3,
  RESULT_UNDEFINED_FAILURE = 255,
}

export interface IRosTypeNavMsgsMapMetaData {
  map_load_time: { sec: number; nanosec: number };
  resolution: number;
  width: number;
  height: number;
  origin: IRosTypeGeometryMsgsPose;
}

export interface IRosTypeNavMsgsOccupancyGrid {
  header: IRosTypeStdMsgsHeader;
  info: IRosTypeNavMsgsMapMetaData;
  data: number[];
}

export interface IRosTypeNavMsgsOdometry {
  header: IRosTypeStdMsgsHeader;
  child_frame_id: string;
  pose: IRosTypeGeometryMsgsPoseWithCovariance;
  twist: IRosTypeGeometryMsgsTwistWithCovariance;
}

export interface IRosTypeNavMsgsPath {
  header: IRosTypeStdMsgsHeader;
  poses: IRosTypeGeometryMsgsPoseStamped[];
}

export interface IRosTypeNavMsgsSetMap {
  request: IRosTypeNavMsgsSetMapRequest;
  response: IRosTypeNavMsgsSetMapResponse;
}

export interface IRosTypeNavMsgsSetMapRequest {
  map: IRosTypeNavMsgsOccupancyGrid;
  initial_pose: IRosTypeGeometryMsgsPoseWithCovarianceStamped;
}

export interface IRosTypeNavMsgsSetMapResponse {
  success: boolean;
}

export enum IRosTypeNetworkAnalysisInterfacesNetworkStatusConst {
  STATUS_ERROR = 0,
  STATUS_NO_CONNECTION = 1,
  STATUS_BAD_CONNECTION = 2,
  STATUS_AVERAGE_CONNECTION = 3,
  STATUS_GOOD_CONNECTION = 4,
}

export interface IRosTypeNetworkAnalysisInterfacesNetworkAnalysis {
  request: IRosTypeNetworkAnalysisInterfacesNetworkAnalysisRequest;
  response: IRosTypeNetworkAnalysisInterfacesNetworkAnalysisResponse;
}

export interface IRosTypeNetworkAnalysisInterfacesNetworkAnalysisRequest {
  do_action: boolean;
}

export interface IRosTypeNetworkAnalysisInterfacesNetworkAnalysisResponse {
  ping_state: number;
  port_state: number;
  download_speed_state: number;
  dump: string;
}

export interface IRosTypeProto2RosAny {
  type_name: string;
  value: number[];
}

export interface IRosTypeProto2RosAnyProto {
  type_url: string;
  value: number[];
}

export interface IRosTypeProto2RosBytes {
  data: number[];
}

export interface IRosTypeProto2RosList {
  values: IRosTypeProto2RosValue[];
}

export interface IRosTypeProto2RosStruct {
  fields: IRosTypeProto2RosStructEntry[];
}

export interface IRosTypeProto2RosStructEntry {
  key: string;
  value: IRosTypeProto2RosValue;
}

export interface IRosTypeProto2RosValue {
  kind: number;
  number_value: number;
  string_value: string;
  bool_value: boolean;
  struct_value: IRosTypeProto2RosAny;
  list_value: IRosTypeProto2RosAny;
}

export enum IRosTypeProto2RosValueConst {
  NO_VALUE_SET = 0,
  NUMBER_VALUE_SET = 1,
  STRING_VALUE_SET = 2,
  BOOL_VALUE_SET = 3,
  STRUCT_VALUE_SET = 4,
  LIST_VALUE_SET = 5,
}

export enum IRosTypeRclInterfacesParameterTypeConst {
  PARAMETER_NOT_SET = 0,
  PARAMETER_BOOL = 1,
  PARAMETER_INTEGER = 2,
  PARAMETER_DOUBLE = 3,
  PARAMETER_STRING = 4,
  PARAMETER_BYTE_ARRAY = 5,
  PARAMETER_BOOL_ARRAY = 6,
  PARAMETER_INTEGER_ARRAY = 7,
  PARAMETER_DOUBLE_ARRAY = 8,
  PARAMETER_STRING_ARRAY = 9,
}

export interface IRosTypeRclInterfacesDescribeParameters {
  request: IRosTypeRclInterfacesDescribeParametersRequest;
  response: IRosTypeRclInterfacesDescribeParametersResponse;
}

export interface IRosTypeRclInterfacesDescribeParametersRequest {
  names: string[];
}

export interface IRosTypeRclInterfacesDescribeParametersResponse {
  descriptors: IRosTypeRclInterfacesParameterDescriptor[];
}

export interface IRosTypeRclInterfacesFloatingPointRange {
  from_value: number;
  to_value: number;
  step: number;
}

export interface IRosTypeRclInterfacesGetParameterTypes {
  request: IRosTypeRclInterfacesGetParameterTypesRequest;
  response: IRosTypeRclInterfacesGetParameterTypesResponse;
}

export interface IRosTypeRclInterfacesGetParameterTypesRequest {
  names: string[];
}

export interface IRosTypeRclInterfacesGetParameterTypesResponse {
  types: number[];
}

export interface IRosTypeRclInterfacesGetParameters {
  request: IRosTypeRclInterfacesGetParametersRequest;
  response: IRosTypeRclInterfacesGetParametersResponse;
}

export interface IRosTypeRclInterfacesGetParametersRequest {
  names: string[];
}

export interface IRosTypeRclInterfacesGetParametersResponse {
  values: IRosTypeRclInterfacesParameterValue[];
}

export interface IRosTypeRclInterfacesIntegerRange {
  from_value: number;
  to_value: number;
  step: number;
}

export interface IRosTypeRclInterfacesListParameters {
  request: IRosTypeRclInterfacesListParametersRequest;
  response: IRosTypeRclInterfacesListParametersResponse;
}

export interface IRosTypeRclInterfacesListParametersRequest {
  prefixes: string[];
  depth: number;
}

export enum IRosTypeRclInterfacesListParametersRequestConst {
  DEPTH_RECURSIVE = 0,
}

export interface IRosTypeRclInterfacesListParametersResponse {
  result: IRosTypeRclInterfacesListParametersResult;
}

export interface IRosTypeRclInterfacesListParametersResult {
  names: string[];
  prefixes: string[];
}

export interface IRosTypeRclInterfacesLog {
  stamp: { sec: number; nanosec: number };
  level: number;
  name: string;
  msg: string;
  file: string;
  function: string;
  line: number;
}

export enum IRosTypeRclInterfacesLogConst {
  DEBUG = 10,
  INFO = 20,
  WARN = 30,
  ERROR = 40,
  FATAL = 50,
}

export interface IRosTypeRclInterfacesParameter {
  name: string;
  value: IRosTypeRclInterfacesParameterValue;
}

export interface IRosTypeRclInterfacesParameterDescriptor {
  name: string;
  type: number;
  description: string;
  additional_constraints: string;
  read_only: boolean;
  dynamic_typing: boolean;
  floating_point_range: IRosTypeRclInterfacesFloatingPointRange[];
  integer_range: IRosTypeRclInterfacesIntegerRange[];
}

export interface IRosTypeRclInterfacesParameterEvent {
  stamp: { sec: number; nanosec: number };
  node: string;
  new_parameters: IRosTypeRclInterfacesParameter[];
  changed_parameters: IRosTypeRclInterfacesParameter[];
  deleted_parameters: IRosTypeRclInterfacesParameter[];
}

export interface IRosTypeRclInterfacesParameterEventDescriptors {
  new_parameters: IRosTypeRclInterfacesParameterDescriptor[];
  changed_parameters: IRosTypeRclInterfacesParameterDescriptor[];
  deleted_parameters: IRosTypeRclInterfacesParameterDescriptor[];
}

export interface IRosTypeRclInterfacesParameterValue {
  type: number;
  bool_value: boolean;
  integer_value: number;
  double_value: number;
  string_value: string;
  byte_array_value: number[];
  bool_array_value: boolean[];
  integer_array_value: number[];
  double_array_value: number[];
  string_array_value: string[];
}

export interface IRosTypeRclInterfacesSetParameters {
  request: IRosTypeRclInterfacesSetParametersRequest;
  response: IRosTypeRclInterfacesSetParametersResponse;
}

export interface IRosTypeRclInterfacesSetParametersAtomically {
  request: IRosTypeRclInterfacesSetParametersAtomicallyRequest;
  response: IRosTypeRclInterfacesSetParametersAtomicallyResponse;
}

export interface IRosTypeRclInterfacesSetParametersAtomicallyRequest {
  parameters: IRosTypeRclInterfacesParameter[];
}

export interface IRosTypeRclInterfacesSetParametersAtomicallyResponse {
  result: IRosTypeRclInterfacesSetParametersResult;
}

export interface IRosTypeRclInterfacesSetParametersRequest {
  parameters: IRosTypeRclInterfacesParameter[];
}

export interface IRosTypeRclInterfacesSetParametersResponse {
  results: IRosTypeRclInterfacesSetParametersResult[];
}

export interface IRosTypeRclInterfacesSetParametersResult {
  successful: boolean;
  reason: string;
}

export interface IRosTypeRmwDdsCommonGid {
  data: number[];
}

export interface IRosTypeRmwDdsCommonNodeEntitiesInfo {
  node_namespace: string;
  node_name: string;
  reader_gid_seq: IRosTypeRmwDdsCommonGid[];
  writer_gid_seq: IRosTypeRmwDdsCommonGid[];
}

export interface IRosTypeRmwDdsCommonParticipantEntitiesInfo {
  gid: IRosTypeRmwDdsCommonGid;
  node_entities_info_seq: IRosTypeRmwDdsCommonNodeEntitiesInfo[];
}

export interface IRosTypeRosapiMsgsDeleteParam {
  request: IRosTypeRosapiMsgsDeleteParamRequest;
}

export interface IRosTypeRosapiMsgsDeleteParamRequest {
  name: string;
}

export interface IRosTypeRosapiMsgsGetActionServers {
  response: IRosTypeRosapiMsgsGetActionServersResponse;
}

export interface IRosTypeRosapiMsgsGetActionServersResponse {
  action_servers: string[];
}

export interface IRosTypeRosapiMsgsGetParam {
  request: IRosTypeRosapiMsgsGetParamRequest;
  response: IRosTypeRosapiMsgsGetParamResponse;
}

export interface IRosTypeRosapiMsgsGetParamNames {
  response: IRosTypeRosapiMsgsGetParamNamesResponse;
}

export interface IRosTypeRosapiMsgsGetParamNamesResponse {
  names: string[];
}

export interface IRosTypeRosapiMsgsGetParamRequest {
  name: string;
  default_value: string;
}

export interface IRosTypeRosapiMsgsGetParamResponse {
  value: string;
}

export interface IRosTypeRosapiMsgsGetRosVersion {
  response: IRosTypeRosapiMsgsGetRosVersionResponse;
}

export interface IRosTypeRosapiMsgsGetRosVersionResponse {
  version: number;
  distro: string;
}

export interface IRosTypeRosapiMsgsGetTime {
  response: IRosTypeRosapiMsgsGetTimeResponse;
}

export interface IRosTypeRosapiMsgsGetTimeResponse {
  time: { sec: number; nanosec: number };
}

export interface IRosTypeRosapiMsgsHasParam {
  request: IRosTypeRosapiMsgsHasParamRequest;
  response: IRosTypeRosapiMsgsHasParamResponse;
}

export interface IRosTypeRosapiMsgsHasParamRequest {
  name: string;
}

export interface IRosTypeRosapiMsgsHasParamResponse {
  exists: boolean;
}

export interface IRosTypeRosapiMsgsMessageDetails {
  request: IRosTypeRosapiMsgsMessageDetailsRequest;
  response: IRosTypeRosapiMsgsMessageDetailsResponse;
}

export interface IRosTypeRosapiMsgsMessageDetailsRequest {
  type: string;
}

export interface IRosTypeRosapiMsgsMessageDetailsResponse {
  typedefs: IRosTypeRosapiMsgsTypeDef[];
}

export interface IRosTypeRosapiMsgsNodeDetails {
  request: IRosTypeRosapiMsgsNodeDetailsRequest;
  response: IRosTypeRosapiMsgsNodeDetailsResponse;
}

export interface IRosTypeRosapiMsgsNodeDetailsRequest {
  node: string;
}

export interface IRosTypeRosapiMsgsNodeDetailsResponse {
  subscribing: string[];
  publishing: string[];
  services: string[];
}

export interface IRosTypeRosapiMsgsNodes {
  response: IRosTypeRosapiMsgsNodesResponse;
}

export interface IRosTypeRosapiMsgsNodesResponse {
  nodes: string[];
}

export interface IRosTypeRosapiMsgsPublishers {
  request: IRosTypeRosapiMsgsPublishersRequest;
  response: IRosTypeRosapiMsgsPublishersResponse;
}

export interface IRosTypeRosapiMsgsPublishersRequest {
  topic: string;
}

export interface IRosTypeRosapiMsgsPublishersResponse {
  publishers: string[];
}

export interface IRosTypeRosapiMsgsServiceNode {
  request: IRosTypeRosapiMsgsServiceNodeRequest;
  response: IRosTypeRosapiMsgsServiceNodeResponse;
}

export interface IRosTypeRosapiMsgsServiceNodeRequest {
  service: string;
}

export interface IRosTypeRosapiMsgsServiceNodeResponse {
  node: string;
}

export interface IRosTypeRosapiMsgsServiceProviders {
  request: IRosTypeRosapiMsgsServiceProvidersRequest;
  response: IRosTypeRosapiMsgsServiceProvidersResponse;
}

export interface IRosTypeRosapiMsgsServiceProvidersRequest {
  service: string;
}

export interface IRosTypeRosapiMsgsServiceProvidersResponse {
  providers: string[];
}

export interface IRosTypeRosapiMsgsServiceRequestDetails {
  request: IRosTypeRosapiMsgsServiceRequestDetailsRequest;
  response: IRosTypeRosapiMsgsServiceRequestDetailsResponse;
}

export interface IRosTypeRosapiMsgsServiceRequestDetailsRequest {
  type: string;
}

export interface IRosTypeRosapiMsgsServiceRequestDetailsResponse {
  typedefs: IRosTypeRosapiMsgsTypeDef[];
}

export interface IRosTypeRosapiMsgsServiceResponseDetails {
  request: IRosTypeRosapiMsgsServiceResponseDetailsRequest;
  response: IRosTypeRosapiMsgsServiceResponseDetailsResponse;
}

export interface IRosTypeRosapiMsgsServiceResponseDetailsRequest {
  type: string;
}

export interface IRosTypeRosapiMsgsServiceResponseDetailsResponse {
  typedefs: IRosTypeRosapiMsgsTypeDef[];
}

export interface IRosTypeRosapiMsgsServiceType {
  request: IRosTypeRosapiMsgsServiceTypeRequest;
  response: IRosTypeRosapiMsgsServiceTypeResponse;
}

export interface IRosTypeRosapiMsgsServiceTypeRequest {
  service: string;
}

export interface IRosTypeRosapiMsgsServiceTypeResponse {
  type: string;
}

export interface IRosTypeRosapiMsgsServices {
  response: IRosTypeRosapiMsgsServicesResponse;
}

export interface IRosTypeRosapiMsgsServicesForType {
  request: IRosTypeRosapiMsgsServicesForTypeRequest;
  response: IRosTypeRosapiMsgsServicesForTypeResponse;
}

export interface IRosTypeRosapiMsgsServicesForTypeRequest {
  type: string;
}

export interface IRosTypeRosapiMsgsServicesForTypeResponse {
  services: string[];
}

export interface IRosTypeRosapiMsgsServicesResponse {
  services: string[];
}

export interface IRosTypeRosapiMsgsSetParam {
  request: IRosTypeRosapiMsgsSetParamRequest;
}

export interface IRosTypeRosapiMsgsSetParamRequest {
  name: string;
  value: string;
}

export interface IRosTypeRosapiMsgsSubscribers {
  request: IRosTypeRosapiMsgsSubscribersRequest;
  response: IRosTypeRosapiMsgsSubscribersResponse;
}

export interface IRosTypeRosapiMsgsSubscribersRequest {
  topic: string;
}

export interface IRosTypeRosapiMsgsSubscribersResponse {
  subscribers: string[];
}

export interface IRosTypeRosapiMsgsTopicType {
  request: IRosTypeRosapiMsgsTopicTypeRequest;
  response: IRosTypeRosapiMsgsTopicTypeResponse;
}

export interface IRosTypeRosapiMsgsTopicTypeRequest {
  topic: string;
}

export interface IRosTypeRosapiMsgsTopicTypeResponse {
  type: string;
}

export interface IRosTypeRosapiMsgsTopics {
  response: IRosTypeRosapiMsgsTopicsResponse;
}

export interface IRosTypeRosapiMsgsTopicsAndRawTypes {
  response: IRosTypeRosapiMsgsTopicsAndRawTypesResponse;
}

export interface IRosTypeRosapiMsgsTopicsAndRawTypesResponse {
  topics: string[];
  types: string[];
  typedefs_full_text: string[];
}

export interface IRosTypeRosapiMsgsTopicsForType {
  request: IRosTypeRosapiMsgsTopicsForTypeRequest;
  response: IRosTypeRosapiMsgsTopicsForTypeResponse;
}

export interface IRosTypeRosapiMsgsTopicsForTypeRequest {
  type: string;
}

export interface IRosTypeRosapiMsgsTopicsForTypeResponse {
  topics: string[];
}

export interface IRosTypeRosapiMsgsTopicsResponse {
  topics: string[];
  types: string[];
}

export interface IRosTypeRosapiMsgsTypeDef {
  type: string;
  fieldnames: string[];
  fieldtypes: string[];
  fieldarraylen: number[];
  examples: string[];
  constnames: string[];
  constvalues: string[];
}

export interface IRosTypeRosbag2InterfacesBurst {
  request: IRosTypeRosbag2InterfacesBurstRequest;
  response: IRosTypeRosbag2InterfacesBurstResponse;
}

export interface IRosTypeRosbag2InterfacesBurstRequest {
  num_messages: number;
}

export interface IRosTypeRosbag2InterfacesBurstResponse {
  actually_burst: number;
}

export interface IRosTypeRosbag2InterfacesGetRate {
  response: IRosTypeRosbag2InterfacesGetRateResponse;
}

export interface IRosTypeRosbag2InterfacesGetRateResponse {
  rate: number;
}

export interface IRosTypeRosbag2InterfacesIsPaused {
  response: IRosTypeRosbag2InterfacesIsPausedResponse;
}

export interface IRosTypeRosbag2InterfacesIsPausedResponse {
  paused: boolean;
}

export interface IRosTypeRosbag2InterfacesPlayNext {
  response: IRosTypeRosbag2InterfacesPlayNextResponse;
}

export interface IRosTypeRosbag2InterfacesPlayNextResponse {
  success: boolean;
}

export interface IRosTypeRosbag2InterfacesReadSplitEvent {
  closed_file: string;
  opened_file: string;
}

export interface IRosTypeRosbag2InterfacesSeek {
  request: IRosTypeRosbag2InterfacesSeekRequest;
  response: IRosTypeRosbag2InterfacesSeekResponse;
}

export interface IRosTypeRosbag2InterfacesSeekRequest {
  time: { sec: number; nanosec: number };
}

export interface IRosTypeRosbag2InterfacesSeekResponse {
  success: boolean;
}

export interface IRosTypeRosbag2InterfacesSetRate {
  request: IRosTypeRosbag2InterfacesSetRateRequest;
  response: IRosTypeRosbag2InterfacesSetRateResponse;
}

export interface IRosTypeRosbag2InterfacesSetRateRequest {
  rate: number;
}

export interface IRosTypeRosbag2InterfacesSetRateResponse {
  success: boolean;
}

export interface IRosTypeRosbag2InterfacesSnapshot {
  response: IRosTypeRosbag2InterfacesSnapshotResponse;
}

export interface IRosTypeRosbag2InterfacesSnapshotResponse {
  success: boolean;
}

export interface IRosTypeRosbag2InterfacesWriteSplitEvent {
  closed_file: string;
  opened_file: string;
}

export interface IRosTypeRosbridgeMsgsConnectedClient {
  ip_address: string;
  connection_time: { sec: number; nanosec: number };
}

export interface IRosTypeRosbridgeMsgsConnectedClients {
  clients: IRosTypeRosbridgeMsgsConnectedClient[];
}

export interface IRosTypeRosbridgeTestMsgsAddTwoInts {
  request: IRosTypeRosbridgeTestMsgsAddTwoIntsRequest;
  response: IRosTypeRosbridgeTestMsgsAddTwoIntsResponse;
}

export interface IRosTypeRosbridgeTestMsgsAddTwoIntsRequest {
  a: number;
  b: number;
}

export interface IRosTypeRosbridgeTestMsgsAddTwoIntsResponse {
  sum: number;
}

export interface IRosTypeRosbridgeTestMsgsNum {
  num: number;
}

export interface IRosTypeRosbridgeTestMsgsSendBytes {
  request: IRosTypeRosbridgeTestMsgsSendBytesRequest;
  response: IRosTypeRosbridgeTestMsgsSendBytesResponse;
}

export interface IRosTypeRosbridgeTestMsgsSendBytesRequest {
  count: number;
}

export interface IRosTypeRosbridgeTestMsgsSendBytesResponse {
  data: string;
}

export interface IRosTypeRosbridgeTestMsgsTestArrayRequest {
  request: IRosTypeRosbridgeTestMsgsTestArrayRequestRequest;
}

export interface IRosTypeRosbridgeTestMsgsTestArrayRequestRequest {
  int_values: number[];
}

export interface IRosTypeRosbridgeTestMsgsTestChar {
  data: number[];
}

export interface IRosTypeRosbridgeTestMsgsTestDurationArray {
  durations: { sec: number; nanosec: number }[];
}

export interface IRosTypeRosbridgeTestMsgsTestFeedbackAndResultActionFeedback {
  data: number;
}

export interface IRosTypeRosbridgeTestMsgsTestFloat32Array {
  data: number[];
}

export interface IRosTypeRosbridgeTestMsgsTestFloat32BoundedArray {
  data: number[];
}

export interface IRosTypeRosbridgeTestMsgsTestGoalAndResultActionFeedback {
  data: number;
}

export interface IRosTypeRosbridgeTestMsgsTestGoalAndResultActionGoal {
  data: number;
}

export interface IRosTypeRosbridgeTestMsgsTestGoalFeedbackAndResultActionFeedback {
  data: number;
}

export interface IRosTypeRosbridgeTestMsgsTestGoalFeedbackAndResultActionGoal {
  data: number;
}

export interface IRosTypeRosbridgeTestMsgsTestGoalFeedbackAndResultActionResult {
  data: number;
}

export interface IRosTypeRosbridgeTestMsgsTestGoalOnlyActionGoal {
  data: number;
}

export interface IRosTypeRosbridgeTestMsgsTestHeader {
  header: IRosTypeStdMsgsHeader;
}

export interface IRosTypeRosbridgeTestMsgsTestHeaderArray {
  header: IRosTypeStdMsgsHeader[];
}

export interface IRosTypeRosbridgeTestMsgsTestHeaderTwo {
  header: IRosTypeStdMsgsHeader;
}

export interface IRosTypeRosbridgeTestMsgsTestMultipleGoalFieldsActionGoal {
  int_value: number;
  float_value: number;
  string: string;
  bool_value: boolean;
}

export interface IRosTypeRosbridgeTestMsgsTestMultipleRequestFields {
  request: IRosTypeRosbridgeTestMsgsTestMultipleRequestFieldsRequest;
}

export interface IRosTypeRosbridgeTestMsgsTestMultipleRequestFieldsRequest {
  int_value: number;
  float_value: number;
  string: string;
  bool_value: boolean;
}

export interface IRosTypeRosbridgeTestMsgsTestMultipleResponseFields {
  response: IRosTypeRosbridgeTestMsgsTestMultipleResponseFieldsResponse;
}

export interface IRosTypeRosbridgeTestMsgsTestMultipleResponseFieldsResponse {
  int_value: number;
  float_value: number;
  string: string;
  bool_value: boolean;
}

export interface IRosTypeRosbridgeTestMsgsTestNestedService {
  request: IRosTypeRosbridgeTestMsgsTestNestedServiceRequest;
  response: IRosTypeRosbridgeTestMsgsTestNestedServiceResponse;
}

export interface IRosTypeRosbridgeTestMsgsTestNestedServiceRequest {
  pose: IRosTypeGeometryMsgsPose;
}

export interface IRosTypeRosbridgeTestMsgsTestNestedServiceResponse {
  data: IRosTypeStdMsgsFloat64;
}

export interface IRosTypeRosbridgeTestMsgsTestRequestAndResponse {
  request: IRosTypeRosbridgeTestMsgsTestRequestAndResponseRequest;
  response: IRosTypeRosbridgeTestMsgsTestRequestAndResponseResponse;
}

export interface IRosTypeRosbridgeTestMsgsTestRequestAndResponseRequest {
  data: number;
}

export interface IRosTypeRosbridgeTestMsgsTestRequestAndResponseResponse {
  data: number;
}

export interface IRosTypeRosbridgeTestMsgsTestRequestOnly {
  request: IRosTypeRosbridgeTestMsgsTestRequestOnlyRequest;
}

export interface IRosTypeRosbridgeTestMsgsTestRequestOnlyRequest {
  data: number;
}

export interface IRosTypeRosbridgeTestMsgsTestResponseOnly {
  response: IRosTypeRosbridgeTestMsgsTestResponseOnlyResponse;
}

export interface IRosTypeRosbridgeTestMsgsTestResponseOnlyResponse {
  data: number;
}

export interface IRosTypeRosbridgeTestMsgsTestResultOnlyActionFeedback {
  data: number;
}

export interface IRosTypeRosbridgeTestMsgsTestTimeArray {
  times: { sec: number; nanosec: number }[];
}

export interface IRosTypeRosbridgeTestMsgsTestUInt8 {
  data: number[];
}

export interface IRosTypeRosbridgeTestMsgsTestUInt8FixedSizeArray16 {
  data: number[];
}

export interface IRosTypeRosgraphMsgsClock {
  clock: { sec: number; nanosec: number };
}

export interface IRosTypeSensorMsgsBatteryState {
  header: IRosTypeStdMsgsHeader;
  voltage: number;
  temperature: number;
  current: number;
  charge: number;
  capacity: number;
  design_capacity: number;
  percentage: number;
  power_supply_status: number;
  power_supply_health: number;
  power_supply_technology: number;
  present: boolean;
  cell_voltage: number[];
  cell_temperature: number[];
  location: string;
  serial_number: string;
}

export enum IRosTypeSensorMsgsBatteryStateConst {
  POWER_SUPPLY_STATUS_UNKNOWN = 0,
  POWER_SUPPLY_STATUS_CHARGING = 1,
  POWER_SUPPLY_STATUS_DISCHARGING = 2,
  POWER_SUPPLY_STATUS_NOT_CHARGING = 3,
  POWER_SUPPLY_STATUS_FULL = 4,
  POWER_SUPPLY_HEALTH_UNKNOWN = 0,
  POWER_SUPPLY_HEALTH_GOOD = 1,
  POWER_SUPPLY_HEALTH_OVERHEAT = 2,
  POWER_SUPPLY_HEALTH_DEAD = 3,
  POWER_SUPPLY_HEALTH_OVERVOLTAGE = 4,
  POWER_SUPPLY_HEALTH_UNSPEC_FAILURE = 5,
  POWER_SUPPLY_HEALTH_COLD = 6,
  POWER_SUPPLY_HEALTH_WATCHDOG_TIMER_EXPIRE = 7,
  POWER_SUPPLY_HEALTH_SAFETY_TIMER_EXPIRE = 8,
  POWER_SUPPLY_TECHNOLOGY_UNKNOWN = 0,
  POWER_SUPPLY_TECHNOLOGY_NIMH = 1,
  POWER_SUPPLY_TECHNOLOGY_LION = 2,
  POWER_SUPPLY_TECHNOLOGY_LIPO = 3,
  POWER_SUPPLY_TECHNOLOGY_LIFE = 4,
  POWER_SUPPLY_TECHNOLOGY_NICD = 5,
  POWER_SUPPLY_TECHNOLOGY_LIMN = 6,
}

export interface IRosTypeSensorMsgsCameraInfo {
  header: IRosTypeStdMsgsHeader;
  height: number;
  width: number;
  distortion_model: string;
  d: number[];
  k: number[];
  r: number[];
  p: number[];
  binning_x: number;
  binning_y: number;
  roi: IRosTypeSensorMsgsRegionOfInterest;
}

export interface IRosTypeSensorMsgsChannelFloat32 {
  name: string;
  values: number[];
}

export interface IRosTypeSensorMsgsCompressedImage {
  header: IRosTypeStdMsgsHeader;
  format: string;
  data: number[];
}

export interface IRosTypeSensorMsgsFluidPressure {
  header: IRosTypeStdMsgsHeader;
  fluid_pressure: number;
  variance: number;
}

export interface IRosTypeSensorMsgsIlluminance {
  header: IRosTypeStdMsgsHeader;
  illuminance: number;
  variance: number;
}

export interface IRosTypeSensorMsgsImage {
  header: IRosTypeStdMsgsHeader;
  height: number;
  width: number;
  encoding: string;
  is_bigendian: number;
  step: number;
  data: number[];
}

export interface IRosTypeSensorMsgsImu {
  header: IRosTypeStdMsgsHeader;
  orientation: IRosTypeGeometryMsgsQuaternion;
  orientation_covariance: number[];
  angular_velocity: IRosTypeGeometryMsgsVector3;
  angular_velocity_covariance: number[];
  linear_acceleration: IRosTypeGeometryMsgsVector3;
  linear_acceleration_covariance: number[];
}

export interface IRosTypeSensorMsgsJointState {
  header: IRosTypeStdMsgsHeader;
  name: string[];
  position: number[];
  velocity: number[];
  effort: number[];
}

export interface IRosTypeSensorMsgsJoy {
  header: IRosTypeStdMsgsHeader;
  axes: number[];
  buttons: number[];
}

export interface IRosTypeSensorMsgsJoyFeedback {
  type: number;
  id: number;
  intensity: number;
}

export enum IRosTypeSensorMsgsJoyFeedbackConst {
  TYPE_LED = 0,
  TYPE_RUMBLE = 1,
  TYPE_BUZZER = 2,
}

export interface IRosTypeSensorMsgsJoyFeedbackArray {
  array: IRosTypeSensorMsgsJoyFeedback[];
}

export interface IRosTypeSensorMsgsLaserEcho {
  echoes: number[];
}

export interface IRosTypeSensorMsgsLaserScan {
  header: IRosTypeStdMsgsHeader;
  angle_min: number;
  angle_max: number;
  angle_increment: number;
  time_increment: number;
  scan_time: number;
  range_min: number;
  range_max: number;
  ranges: number[];
  intensities: number[];
}

export interface IRosTypeSensorMsgsMagneticField {
  header: IRosTypeStdMsgsHeader;
  magnetic_field: IRosTypeGeometryMsgsVector3;
  magnetic_field_covariance: number[];
}

export interface IRosTypeSensorMsgsMultiDofJointState {
  header: IRosTypeStdMsgsHeader;
  joint_names: string[];
  transforms: IRosTypeGeometryMsgsTransform[];
  twist: IRosTypeGeometryMsgsTwist[];
  wrench: IRosTypeGeometryMsgsWrench[];
}

export interface IRosTypeSensorMsgsMultiEchoLaserScan {
  header: IRosTypeStdMsgsHeader;
  angle_min: number;
  angle_max: number;
  angle_increment: number;
  time_increment: number;
  scan_time: number;
  range_min: number;
  range_max: number;
  ranges: IRosTypeSensorMsgsLaserEcho[];
  intensities: IRosTypeSensorMsgsLaserEcho[];
}

export interface IRosTypeSensorMsgsNavSatFix {
  header: IRosTypeStdMsgsHeader;
  status: IRosTypeSensorMsgsNavSatStatus;
  latitude: number;
  longitude: number;
  altitude: number;
  position_covariance: number[];
  position_covariance_type: number;
}

export enum IRosTypeSensorMsgsNavSatFixConst {
  COVARIANCE_TYPE_UNKNOWN = 0,
  COVARIANCE_TYPE_APPROXIMATED = 1,
  COVARIANCE_TYPE_DIAGONAL_KNOWN = 2,
  COVARIANCE_TYPE_KNOWN = 3,
}

export interface IRosTypeSensorMsgsNavSatStatus {
  status: number;
  service: number;
}

export enum IRosTypeSensorMsgsNavSatStatusConst {
  STATUS_NO_FIX = -1,
  STATUS_FIX = 0,
  STATUS_SBAS_FIX = 1,
  STATUS_GBAS_FIX = 2,
  SERVICE_GPS = 1,
  SERVICE_GLONASS = 2,
  SERVICE_COMPASS = 4,
  SERVICE_GALILEO = 8,
}

export interface IRosTypeSensorMsgsPointCloud {
  header: IRosTypeStdMsgsHeader;
  points: IRosTypeGeometryMsgsPoint32[];
  channels: IRosTypeSensorMsgsChannelFloat32[];
}

export interface IRosTypeSensorMsgsPointCloud2 {
  header: IRosTypeStdMsgsHeader;
  height: number;
  width: number;
  fields: IRosTypeSensorMsgsPointField[];
  is_bigendian: boolean;
  point_step: number;
  row_step: number;
  data: number[];
  is_dense: boolean;
}

export interface IRosTypeSensorMsgsPointField {
  name: string;
  offset: number;
  datatype: number;
  count: number;
}

export enum IRosTypeSensorMsgsPointFieldConst {
  INT8 = 1,
  UINT8 = 2,
  INT16 = 3,
  UINT16 = 4,
  INT32 = 5,
  UINT32 = 6,
  FLOAT32 = 7,
  FLOAT64 = 8,
}

export interface IRosTypeSensorMsgsRange {
  header: IRosTypeStdMsgsHeader;
  radiation_type: number;
  field_of_view: number;
  min_range: number;
  max_range: number;
  range: number;
}

export enum IRosTypeSensorMsgsRangeConst {
  ULTRASOUND = 0,
  INFRARED = 1,
}

export interface IRosTypeSensorMsgsRegionOfInterest {
  x_offset: number;
  y_offset: number;
  height: number;
  width: number;
  do_rectify: boolean;
}

export interface IRosTypeSensorMsgsRelativeHumidity {
  header: IRosTypeStdMsgsHeader;
  relative_humidity: number;
  variance: number;
}

export interface IRosTypeSensorMsgsSetCameraInfo {
  request: IRosTypeSensorMsgsSetCameraInfoRequest;
  response: IRosTypeSensorMsgsSetCameraInfoResponse;
}

export interface IRosTypeSensorMsgsSetCameraInfoRequest {
  camera_info: IRosTypeSensorMsgsCameraInfo;
}

export interface IRosTypeSensorMsgsSetCameraInfoResponse {
  success: boolean;
  status_message: string;
}

export interface IRosTypeSensorMsgsTemperature {
  header: IRosTypeStdMsgsHeader;
  temperature: number;
  variance: number;
}

export interface IRosTypeSensorMsgsTimeReference {
  header: IRosTypeStdMsgsHeader;
  time_ref: { sec: number; nanosec: number };
  source: string;
}

export interface IRosTypeShapeMsgsMesh {
  triangles: IRosTypeShapeMsgsMeshTriangle[];
  vertices: IRosTypeGeometryMsgsPoint[];
}

export interface IRosTypeShapeMsgsMeshTriangle {
  vertex_indices: number[];
}

export interface IRosTypeShapeMsgsPlane {
  coef: number[];
}

export interface IRosTypeShapeMsgsSolidPrimitive {
  type: number;
  dimensions: number[];
  polygon: IRosTypeGeometryMsgsPolygon;
}

export enum IRosTypeShapeMsgsSolidPrimitiveConst {
  BOX = 1,
  SPHERE = 2,
  CYLINDER = 3,
  CONE = 4,
  PRISM = 5,
  BOX_X = 0,
  BOX_Y = 1,
  BOX_Z = 2,
  SPHERE_RADIUS = 0,
  CYLINDER_HEIGHT = 0,
  CYLINDER_RADIUS = 1,
  CONE_HEIGHT = 0,
  CONE_RADIUS = 1,
  PRISM_HEIGHT = 0,
}

export interface IRosTypeSignalrRosclientInterfacesCallHubMethod {
  request: IRosTypeSignalrRosclientInterfacesCallHubMethodRequest;
  response: IRosTypeSignalrRosclientInterfacesCallHubMethodResponse;
}

export interface IRosTypeSignalrRosclientInterfacesCallHubMethodRequest {
  hub_name: string;
  method_name: string;
  payload: string;
  timeout: number;
}

export interface IRosTypeSignalrRosclientInterfacesCallHubMethodResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSignalrRosclientInterfacesNetworkHealthUnit {
  name: string;
  network_state: number;
  message: string;
}

export enum IRosTypeSignalrRosclientInterfacesNetworkHealthUnitConst {
  UNKNOWN = 0,
  CONNECTED = 1,
  DISCONNECTED = 2,
}

export interface IRosTypeSpotControlInterfacesBatteryStateData {
  battery_state: IRosTypeSpotMsgsBatteryState;
  battery_state_temperatures: number;
  last_update_time_utc: { sec: number; nanosec: number };
}

export interface IRosTypeSpotControlInterfacesCallBackCommand {
  command_name: string;
  value: string;
  last_update_time_utc: { sec: number; nanosec: number };
}

export interface IRosTypeSpotControlInterfacesClaimState {
  power_state: number;
  claimed: boolean;
  owner: string;
}

export interface IRosTypeSpotControlInterfacesCoordinates {
  map_name: string;
  waypoint_name: string;
  coordinates: IRosTypeGeometryMsgsTwist;
  last_update_time_utc: { sec: number; nanosec: number };
}

export interface IRosTypeSpotControlInterfacesDockState {
  is_docked: boolean;
  dock_id: number;
}

export interface IRosTypeSpotControlInterfacesEStopStateData {
  estop_state_hardware: IRosTypeSpotMsgsEStopState;
  estop_state_software: IRosTypeSpotMsgsEStopState;
}

export interface IRosTypeSpotControlInterfacesFileMetadata {
  map_name: string;
  file_path: string;
  coordinates: IRosTypeGeometryMsgsTwist;
  last_update_time_utc: { sec: number; nanosec: number };
}

export interface IRosTypeSpotControlInterfacesMission {
  position_order: number;
  map_name: string;
  mission_id: number;
  route_name: string;
  last_update_time_utc: { sec: number; nanosec: number };
}

export interface IRosTypeSpotControlInterfacesMissionCompleted {
  position_order: number;
  mission_id: number;
  last_update_time_utc: { sec: number; nanosec: number };
}

export interface IRosTypeSpotControlInterfacesMissionQueue {
  mission_queue: string;
  last_update_time_utc: { sec: number; nanosec: number };
}

export interface IRosTypeSpotControlInterfacesNavigate {
  map_name: string;
  mission_id: string;
  waypoints: IRosTypeSpotControlInterfacesWaypoint[];
  last_update_time_utc: { sec: number; nanosec: number };
}

export interface IRosTypeSpotControlInterfacesStartupReported {
  status: string;
  errormessage: string;
  last_update_time_utc: { sec: number; nanosec: number };
}

export interface IRosTypeSpotControlInterfacesStatusData {
  current_mission_id: number;
  last_error_message: string;
  last_error_time_utc: { sec: number; nanosec: number };
  last_update_time_utc: { sec: number; nanosec: number };
  idle: string;
  status: string;
  charge_percentage: number;
}

export interface IRosTypeSpotControlInterfacesWaypoint {
  waypoint_id: string;
  waypoint_name: string;
  last_update_time_utc: string;
}

export interface IRosTypeSpotControlInterfacesWaypointReached {
  current_mission_id: number;
  waypoint_name: string;
  last_update_time_utc: { sec: number; nanosec: number };
}

export interface IRosTypeSpotFaultsInterfacesActiveFault {
  error_message: string;
  fault_type: number;
  timestamp: string;
  fault_id: number;
  clearable: boolean;
}

export interface IRosTypeSpotFaultsInterfacesActiveFaultArray {
  active_faults: IRosTypeSpotFaultsInterfacesActiveFault[];
}

export interface IRosTypeSpotFaultsInterfacesNotification {
  severity_level: number;
  message: string;
  trigger_alert: boolean;
}

export enum IRosTypeSpotFaultsInterfacesNotificationConst {
  SEVERITY_INFO = 0,
  SEVERITY_WARNING = 1,
  SEVERITY_ERROR = 2,
}

export interface IRosTypeSpotImageRecorderInterfacesImageRecorder {
  request: IRosTypeSpotImageRecorderInterfacesImageRecorderRequest;
  response: IRosTypeSpotImageRecorderInterfacesImageRecorderResponse;
}

export interface IRosTypeSpotImageRecorderInterfacesImageRecorderRequest {
  path: string;
}

export interface IRosTypeSpotImageRecorderInterfacesImageRecorderResponse {
  success: boolean;
}

export interface IRosTypeSpotLocalizationHandlerInterfacesGraphNavStartMapRecording {
  response: IRosTypeSpotLocalizationHandlerInterfacesGraphNavStartMapRecordingResponse;
}

export interface IRosTypeSpotLocalizationHandlerInterfacesGraphNavStartMapRecordingResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotLocalizationHandlerInterfacesGraphNavStopMapRecording {
  response: IRosTypeSpotLocalizationHandlerInterfacesGraphNavStopMapRecordingResponse;
}

export interface IRosTypeSpotLocalizationHandlerInterfacesGraphNavStopMapRecordingResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotLocalizationHandlerInterfacesLocalizationActionFeedback {
  message: string;
}

export interface IRosTypeSpotLocalizationHandlerInterfacesLocalizationActionGoal {
  mapobject: IRosTypeSpotLocalizationHandlerInterfacesMapObjectList;
}

export interface IRosTypeSpotLocalizationHandlerInterfacesLocalizationActionResult {
  success: boolean;
  new_map: string;
}

export interface IRosTypeSpotLocalizationHandlerInterfacesLocalizationLocation {
  x: number;
  y: number;
  z: number;
  map_id: string;
}

export interface IRosTypeSpotLocalizationHandlerInterfacesMapObject {
  map_id: string;
  map_name: string;
  fiducials: number[];
  map_blob_key: string;
  img_blob_key: string;
  is_map_ready: boolean;
}

export interface IRosTypeSpotLocalizationHandlerInterfacesMapObjectList {
  map_objects: IRosTypeSpotLocalizationHandlerInterfacesMapObject[];
}

export interface IRosTypeSpotLocalizationHandlerInterfacesMapRecorderActionFeedback {
  message: string;
}

export interface IRosTypeSpotLocalizationHandlerInterfacesMapRecorderActionGoal {
  map_name: string;
}

export interface IRosTypeSpotLocalizationHandlerInterfacesMapRecorderActionResult {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMonitorInterfacesBatteryStateWithDuration {
  battery_state: IRosTypeSensorMsgsBatteryState;
  duration: { sec: number; nanosec: number };
  duration_valid: boolean;
}

export interface IRosTypeSpotMonitorInterfacesNetworkHealthUnit {
  name: string;
  network_state: number;
  message: string;
}

export enum IRosTypeSpotMonitorInterfacesNetworkHealthUnitConst {
  UNKNOWN = 0,
  CONNECTED = 1,
  DISCONNECTED = 2,
}

export interface IRosTypeSpotMonitorInterfacesOperatingStatus {
  header: IRosTypeStdMsgsHeader;
  status: number;
}

export enum IRosTypeSpotMonitorInterfacesOperatingStatusConst {
  STATUS_UNKNOWN = 0,
  STATUS_IDLE = 1,
  STATUS_TELEOPERATION = 2,
  STATUS_ON_MISSION = 3,
  STATUS_EXTERNAL_USE = 4,
  STATUS_BOOTING = 5,
}

export enum IRosTypeSpotMsgsGetChoreographyStatusRequestConst {
  STATUS_UNKNOWN = 0,
  STATUS_DANCING = 1,
  STATUS_COMPLETED_SEQUENCE = 2,
  STATUS_PREPPING = 3,
  STATUS_WAITING_FOR_START_TIME = 4,
  STATUS_VALIDATING = 5,
  STATUS_INTERRUPTED = 6,
  STATUS_FALLEN = 7,
  STATUS_POWERED_OFF = 8,
  STATUS_OTHER = 9,
}

export interface IRosTypeSpotMsgsBatteryState {
  header: IRosTypeStdMsgsHeader;
  identifier: string;
  charge_percentage: number;
  estimated_runtime: { sec: number; nanosec: number };
  current: number;
  voltage: number;
  temperatures: number[];
  status: number;
}

export enum IRosTypeSpotMsgsBatteryStateConst {
  STATUS_UNKNOWN = 0,
  STATUS_MISSING = 1,
  STATUS_CHARGING = 2,
  STATUS_DISCHARGING = 3,
  STATUS_BOOTING = 4,
}

export interface IRosTypeSpotMsgsBatteryStateArray {
  battery_states: IRosTypeSpotMsgsBatteryState[];
}

export interface IRosTypeSpotMsgsBehaviorFault {
  header: IRosTypeStdMsgsHeader;
  behavior_fault_id: number;
  cause: number;
  status: number;
}

export enum IRosTypeSpotMsgsBehaviorFaultConst {
  CAUSE_UNKNOWN = 0,
  CAUSE_FALL = 1,
  CAUSE_HARDWARE = 2,
  STATUS_UNKNOWN = 0,
  STATUS_CLEARABLE = 1,
  STATUS_UNCLEARABLE = 2,
}

export interface IRosTypeSpotMsgsBehaviorFaultState {
  faults: IRosTypeSpotMsgsBehaviorFault[];
}

export interface IRosTypeSpotMsgsBodyPose {
  yaw: number;
  pitch: number;
  roll: number;
  height: number;
}

export enum IRosTypeSpotMsgsBodyPoseConst {
  MIN_ROLL = -0.265,
  MAX_ROLL = 0.265,
  MIN_YAW = -0.36,
  MAX_YAW = 0.36,
  MIN_PITCH = -0.435,
  MAX_PITCH = 0.435,
  MIN_HEIGHT = -0.15,
  MAX_HEIGHT = 0.15,
}

export interface IRosTypeSpotMsgsChoreographyRecordedStateToAnimation {
  request: IRosTypeSpotMsgsChoreographyRecordedStateToAnimationRequest;
  response: IRosTypeSpotMsgsChoreographyRecordedStateToAnimationResponse;
}

export interface IRosTypeSpotMsgsChoreographyRecordedStateToAnimationRequest {
  has_arm: boolean;
}

export interface IRosTypeSpotMsgsChoreographyRecordedStateToAnimationResponse {
  success: boolean;
  message: string;
  animation_file_contents: string;
}

export interface IRosTypeSpotMsgsChoreographyStartRecordingState {
  request: IRosTypeSpotMsgsChoreographyStartRecordingStateRequest;
  response: IRosTypeSpotMsgsChoreographyStartRecordingStateResponse;
}

export interface IRosTypeSpotMsgsChoreographyStartRecordingStateRequest {
  duration_seconds: number;
}

export enum IRosTypeSpotMsgsChoreographyStartRecordingStateRequestConst {
  STATUS_UNKNOWN = 0,
  STATUS_OK = 1,
  STATUS_UNKNOWN_RECORDING_SESSION_ID = 2,
  STATUS_RECORDING_BUFFER_FULL = 3,
}

export interface IRosTypeSpotMsgsChoreographyStartRecordingStateResponse {
  success: boolean;
  message: string;
  status: number;
  recording_session_id: number;
}

export interface IRosTypeSpotMsgsChoreographyStopRecordingState {
  response: IRosTypeSpotMsgsChoreographyStopRecordingStateResponse;
}

export interface IRosTypeSpotMsgsChoreographyStopRecordingStateResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsClearBehaviorFault {
  request: IRosTypeSpotMsgsClearBehaviorFaultRequest;
  response: IRosTypeSpotMsgsClearBehaviorFaultResponse;
}

export interface IRosTypeSpotMsgsClearBehaviorFaultRequest {
  id: number;
}

export interface IRosTypeSpotMsgsClearBehaviorFaultResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsDeleteLogpoint {
  request: IRosTypeSpotMsgsDeleteLogpointRequest;
  response: IRosTypeSpotMsgsDeleteLogpointResponse;
}

export interface IRosTypeSpotMsgsDeleteLogpointRequest {
  name: string;
}

export interface IRosTypeSpotMsgsDeleteLogpointResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsDeleteSound {
  request: IRosTypeSpotMsgsDeleteSoundRequest;
  response: IRosTypeSpotMsgsDeleteSoundResponse;
}

export interface IRosTypeSpotMsgsDeleteSoundRequest {
  name: string;
}

export interface IRosTypeSpotMsgsDeleteSoundResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsDock {
  request: IRosTypeSpotMsgsDockRequest;
  response: IRosTypeSpotMsgsDockResponse;
}

export interface IRosTypeSpotMsgsDockRequest {
  dock_id: number;
}

export interface IRosTypeSpotMsgsDockResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsDoorCommandActionFeedback {
  feedback: IRosTypeBosdynSpotApiMsgsOpenDoorFeedbackResponse;
}

export interface IRosTypeSpotMsgsDoorCommandActionGoal {
  command: IRosTypeBosdynSpotApiMsgsOpenDoorCommandRequest;
}

export interface IRosTypeSpotMsgsDoorCommandActionResult {
  result: IRosTypeBosdynSpotApiMsgsOpenDoorFeedbackResponse;
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsEStopState {
  header: IRosTypeStdMsgsHeader;
  name: string;
  type: number;
  state: number;
  state_description: string;
}

export enum IRosTypeSpotMsgsEStopStateConst {
  TYPE_UNKNOWN = 0,
  TYPE_HARDWARE = 1,
  TYPE_SOFTWARE = 2,
  STATE_UNKNOWN = 0,
  STATE_ESTOPPED = 1,
  STATE_NOT_ESTOPPED = 2,
}

export interface IRosTypeSpotMsgsEStopStateArray {
  estop_states: IRosTypeSpotMsgsEStopState[];
}

export interface IRosTypeSpotMsgsExecuteDanceActionFeedback {
  is_dancing: boolean;
}

export interface IRosTypeSpotMsgsExecuteDanceActionGoal {
  choreo_name: string;
  choreo_file_content: string;
  choreo_sequence_serialized: number[];
  start_slice: number;
}

export interface IRosTypeSpotMsgsExecuteDanceActionResult {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsFeedback {
  standing: boolean;
  sitting: boolean;
  moving: boolean;
  docked: boolean;
  dock_id: number;
  serial_number: string;
  species: string;
  version: string;
  nickname: string;
  computer_serial_number: string;
}

export enum IRosTypeSpotMsgsFeedbackConst {
  NOT_DOCKED_DOCK_ID = 0,
}

export interface IRosTypeSpotMsgsFootState {
  foot_position_rt_body: IRosTypeGeometryMsgsPoint;
  contact: number;
}

export enum IRosTypeSpotMsgsFootStateConst {
  CONTACT_UNKNOWN = 0,
  CONTACT_MADE = 1,
  CONTACT_LOST = 2,
}

export interface IRosTypeSpotMsgsFootStateArray {
  states: IRosTypeSpotMsgsFootState[];
}

export interface IRosTypeSpotMsgsGetChoreographyStatus {
  request: IRosTypeSpotMsgsGetChoreographyStatusRequestConst;
  response: IRosTypeSpotMsgsGetChoreographyStatusResponse;
}

export interface IRosTypeSpotMsgsGetChoreographyStatusResponse {
  success: boolean;
  message: string;
  status: number;
  execution_id: number;
}

export interface IRosTypeSpotMsgsGetGripperCameraParameters {
  request: IRosTypeSpotMsgsGetGripperCameraParametersRequest;
  response: IRosTypeSpotMsgsGetGripperCameraParametersResponse;
}

export interface IRosTypeSpotMsgsGetGripperCameraParametersRequest {
  request: IRosTypeBosdynApiMsgsGripperCameraGetParamRequest;
}

export interface IRosTypeSpotMsgsGetGripperCameraParametersResponse {
  response: IRosTypeBosdynApiMsgsGripperCameraGetParamResponse;
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsGetInverseKinematicSolutions {
  request: IRosTypeSpotMsgsGetInverseKinematicSolutionsRequest;
  response: IRosTypeSpotMsgsGetInverseKinematicSolutionsResponse;
}

export interface IRosTypeSpotMsgsGetInverseKinematicSolutionsRequest {
  request: IRosTypeBosdynSpotApiMsgsInverseKinematicsRequest;
}

export interface IRosTypeSpotMsgsGetInverseKinematicSolutionsResponse {
  response: IRosTypeBosdynSpotApiMsgsInverseKinematicsResponse;
}

export interface IRosTypeSpotMsgsGetLedBrightness {
  response: IRosTypeSpotMsgsGetLedBrightnessResponse;
}

export interface IRosTypeSpotMsgsGetLedBrightnessResponse {
  success: boolean;
  message: string;
  brightness: number[];
}

export interface IRosTypeSpotMsgsGetLogpointStatus {
  request: IRosTypeSpotMsgsGetLogpointStatusRequest;
  response: IRosTypeSpotMsgsGetLogpointStatusResponse;
}

export interface IRosTypeSpotMsgsGetLogpointStatusRequest {
  name: string;
}

export interface IRosTypeSpotMsgsGetLogpointStatusResponse {
  success: boolean;
  message: string;
  status: IRosTypeBosdynSpotCamApiMsgsLogpointLogStatus;
}

export interface IRosTypeSpotMsgsGetPtzPosition {
  request: IRosTypeSpotMsgsGetPtzPositionRequest;
  response: IRosTypeSpotMsgsGetPtzPositionResponse;
}

export interface IRosTypeSpotMsgsGetPtzPositionRequest {
  name: string;
}

export interface IRosTypeSpotMsgsGetPtzPositionResponse {
  success: boolean;
  message: string;
  position: IRosTypeBosdynSpotCamApiMsgsPtzPosition;
}

export interface IRosTypeSpotMsgsGetVolume {
  response: IRosTypeSpotMsgsGetVolumeResponse;
}

export interface IRosTypeSpotMsgsGetVolumeResponse {
  volume: number;
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsGraphNavClearGraph {
  response: IRosTypeSpotMsgsGraphNavClearGraphResponse;
}

export interface IRosTypeSpotMsgsGraphNavClearGraphResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsGraphNavGetLocalizationPose {
  response: IRosTypeSpotMsgsGraphNavGetLocalizationPoseResponse;
}

export interface IRosTypeSpotMsgsGraphNavGetLocalizationPoseResponse {
  success: boolean;
  message: string;
  pose: IRosTypeGeometryMsgsPoseStamped;
}

export interface IRosTypeSpotMsgsGraphNavSetLocalization {
  request: IRosTypeSpotMsgsGraphNavSetLocalizationRequest;
  response: IRosTypeSpotMsgsGraphNavSetLocalizationResponse;
}

export interface IRosTypeSpotMsgsGraphNavSetLocalizationRequest {
  method: string;
  waypoint_id: string;
}

export interface IRosTypeSpotMsgsGraphNavSetLocalizationResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsGraphNavUploadGraph {
  request: IRosTypeSpotMsgsGraphNavUploadGraphRequest;
  response: IRosTypeSpotMsgsGraphNavUploadGraphResponse;
}

export interface IRosTypeSpotMsgsGraphNavUploadGraphRequest {
  upload_filepath: string;
}

export interface IRosTypeSpotMsgsGraphNavUploadGraphResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsInitializeLens {
  response: IRosTypeSpotMsgsInitializeLensResponse;
}

export interface IRosTypeSpotMsgsInitializeLensResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsLease {
  resource: string;
  epoch: string;
  sequence: number[];
}

export interface IRosTypeSpotMsgsLeaseArray {
  resources: IRosTypeSpotMsgsLeaseResource[];
}

export interface IRosTypeSpotMsgsLeaseOwner {
  client_name: string;
  user_name: string;
}

export interface IRosTypeSpotMsgsLeaseResource {
  resource: string;
  lease: IRosTypeSpotMsgsLease;
  lease_owner: IRosTypeSpotMsgsLeaseOwner;
}

export interface IRosTypeSpotMsgsListAllDances {
  response: IRosTypeSpotMsgsListAllDancesResponse;
}

export interface IRosTypeSpotMsgsListAllDancesResponse {
  success: boolean;
  message: string;
  dances: string[];
}

export interface IRosTypeSpotMsgsListAllMoves {
  response: IRosTypeSpotMsgsListAllMovesResponse;
}

export interface IRosTypeSpotMsgsListAllMovesResponse {
  success: boolean;
  message: string;
  moves: string[];
}

export interface IRosTypeSpotMsgsListCameras {
  response: IRosTypeSpotMsgsListCamerasResponse;
}

export interface IRosTypeSpotMsgsListCamerasResponse {
  success: boolean;
  message: string;
  cameras: IRosTypeBosdynSpotCamApiMsgsCamera[];
}

export interface IRosTypeSpotMsgsListGraph {
  request: IRosTypeSpotMsgsListGraphRequest;
  response: IRosTypeSpotMsgsListGraphResponse;
}

export interface IRosTypeSpotMsgsListGraphRequest {
  upload_filepath: string;
}

export interface IRosTypeSpotMsgsListGraphResponse {
  waypoint_ids: string[];
}

export interface IRosTypeSpotMsgsListLogpoints {
  response: IRosTypeSpotMsgsListLogpointsResponse;
}

export interface IRosTypeSpotMsgsListLogpointsResponse {
  success: boolean;
  message: string;
  logpoints: IRosTypeBosdynSpotCamApiMsgsLogpoint[];
}

export interface IRosTypeSpotMsgsListPtz {
  response: IRosTypeSpotMsgsListPtzResponse;
}

export interface IRosTypeSpotMsgsListPtzResponse {
  success: boolean;
  message: string;
  descriptions: IRosTypeBosdynSpotCamApiMsgsPtzDescription[];
}

export interface IRosTypeSpotMsgsListSounds {
  response: IRosTypeSpotMsgsListSoundsResponse;
}

export interface IRosTypeSpotMsgsListSoundsResponse {
  names: string[];
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsListWorldObjects {
  request: IRosTypeSpotMsgsListWorldObjectsRequest;
  response: IRosTypeSpotMsgsListWorldObjectsResponse;
}

export interface IRosTypeSpotMsgsListWorldObjectsRequest {
  request: IRosTypeBosdynApiMsgsListWorldObjectRequest;
}

export interface IRosTypeSpotMsgsListWorldObjectsResponse {
  response: IRosTypeBosdynApiMsgsListWorldObjectResponse;
}

export interface IRosTypeSpotMsgsLoadSound {
  request: IRosTypeSpotMsgsLoadSoundRequest;
  response: IRosTypeSpotMsgsLoadSoundResponse;
}

export interface IRosTypeSpotMsgsLoadSoundRequest {
  name: string;
  wav_path: string;
}

export interface IRosTypeSpotMsgsLoadSoundResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsManipulationActionFeedback {
  feedback: IRosTypeBosdynApiMsgsManipulationApiFeedbackResponse;
}

export interface IRosTypeSpotMsgsManipulationActionGoal {
  command: IRosTypeBosdynApiMsgsManipulationApiRequest;
}

export interface IRosTypeSpotMsgsManipulationActionResult {
  result: IRosTypeBosdynApiMsgsManipulationApiFeedbackResponse;
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsMetrics {
  header: IRosTypeStdMsgsHeader;
  distance: number;
  gait_cycles: number;
  time_moving: { sec: number; nanosec: number };
  electric_power: { sec: number; nanosec: number };
}

export interface IRosTypeSpotMsgsMobilityParams {
  body_control: IRosTypeGeometryMsgsPose;
  locomotion_hint: number;
  stair_hint: boolean;
}

export interface IRosTypeSpotMsgsNavigateToActionFeedback {
  waypoint_id: string;
}

export interface IRosTypeSpotMsgsNavigateToActionGoal {
  upload_path: string;
  navigate_to: string;
  initial_localization_fiducial: boolean;
  initial_localization_waypoint: string;
}

export interface IRosTypeSpotMsgsNavigateToActionResult {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsOpenDoorFromImageActionFeedback {
  feedback: string;
}

export interface IRosTypeSpotMsgsOpenDoorFromImageActionGoal {
  handle_position_x: number;
  handle_position_y: number;
  spot_image_source: string;
  hinge_side: IRosTypeBosdynSpotApiMsgsDoorCommandHingeSide;
  swing_direction: IRosTypeBosdynSpotApiMsgsDoorCommandSwingDirection;
}

export interface IRosTypeSpotMsgsOpenDoorFromImageActionResult {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsPickUpObjectActionFeedback {
  feedback: string;
}

export interface IRosTypeSpotMsgsPickUpObjectActionGoal {
  pick_position_x: number;
  pick_position_y: number;
  spot_image_source: string;
}

export interface IRosTypeSpotMsgsPickUpObjectActionResult {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsPlaySound {
  request: IRosTypeSpotMsgsPlaySoundRequest;
  response: IRosTypeSpotMsgsPlaySoundResponse;
}

export interface IRosTypeSpotMsgsPlaySoundRequest {
  name: string;
  volume_multiplier: number;
}

export interface IRosTypeSpotMsgsPlaySoundResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsPowerState {
  header: IRosTypeStdMsgsHeader;
  motor_power_state: number;
  shore_power_state: number;
  locomotion_charge_percentage: number;
  locomotion_estimated_runtime: { sec: number; nanosec: number };
}

export enum IRosTypeSpotMsgsPowerStateConst {
  STATE_UNKNOWN = 0,
  STATE_OFF = 1,
  STATE_ON = 2,
  STATE_POWERING_ON = 3,
  STATE_POWERING_OFF = 4,
  STATE_ERROR = 5,
  STATE_UNKNOWN_SHORE_POWER = 0,
  STATE_ON_SHORE_POWER = 1,
  STATE_OFF_SHORE_POWER = 2,
}

export interface IRosTypeSpotMsgsRetrieveLogpoint {
  request: IRosTypeSpotMsgsRetrieveLogpointRequest;
  response: IRosTypeSpotMsgsRetrieveLogpointResponse;
}

export interface IRosTypeSpotMsgsRetrieveLogpointRequest {
  name: string;
  raw: boolean;
}

export interface IRosTypeSpotMsgsRetrieveLogpointResponse {
  success: boolean;
  message: string;
  logpoint: IRosTypeBosdynSpotCamApiMsgsLogpoint;
  data: number[];
}

export interface IRosTypeSpotMsgsRobotCommand {
  request: IRosTypeSpotMsgsRobotCommandRequest;
  response: IRosTypeSpotMsgsRobotCommandResponse;
}

export interface IRosTypeSpotMsgsRobotCommandActionFeedback {
  feedback: IRosTypeBosdynApiMsgsRobotCommandFeedback;
}

export interface IRosTypeSpotMsgsRobotCommandActionGoal {
  command: IRosTypeBosdynApiMsgsRobotCommand;
}

export interface IRosTypeSpotMsgsRobotCommandActionResult {
  result: IRosTypeBosdynApiMsgsRobotCommandFeedback;
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsRobotCommandRequest {
  command: IRosTypeBosdynApiMsgsRobotCommand;
  duration: { sec: number; nanosec: number };
}

export interface IRosTypeSpotMsgsRobotCommandResponse {
  success: boolean;
  message: string;
  robot_command_id: number;
}

export interface IRosTypeSpotMsgsSetGripperCameraParameters {
  request: IRosTypeSpotMsgsSetGripperCameraParametersRequest;
  response: IRosTypeSpotMsgsSetGripperCameraParametersResponse;
}

export interface IRosTypeSpotMsgsSetGripperCameraParametersRequest {
  request: IRosTypeBosdynApiMsgsGripperCameraParamRequest;
}

export interface IRosTypeSpotMsgsSetGripperCameraParametersResponse {
  response: IRosTypeBosdynApiMsgsGripperCameraParamResponse;
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsSetLedBrightness {
  request: IRosTypeSpotMsgsSetLedBrightnessRequest;
  response: IRosTypeSpotMsgsSetLedBrightnessResponse;
}

export interface IRosTypeSpotMsgsSetLedBrightnessRequest {
  brightness: number;
}

export interface IRosTypeSpotMsgsSetLedBrightnessResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsSetLocomotion {
  request: IRosTypeSpotMsgsSetLocomotionRequest;
  response: IRosTypeSpotMsgsSetLocomotionResponse;
}

export interface IRosTypeSpotMsgsSetLocomotionRequest {
  locomotion_mode: number;
}

export interface IRosTypeSpotMsgsSetLocomotionResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsSetPtzPosition {
  request: IRosTypeSpotMsgsSetPtzPositionRequest;
  response: IRosTypeSpotMsgsSetPtzPositionResponse;
}

export interface IRosTypeSpotMsgsSetPtzPositionRequest {
  name: string;
  pan: number;
  tilt: number;
  zoom: number;
}

export interface IRosTypeSpotMsgsSetPtzPositionResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsSetVelocity {
  request: IRosTypeSpotMsgsSetVelocityRequest;
  response: IRosTypeSpotMsgsSetVelocityResponse;
}

export interface IRosTypeSpotMsgsSetVelocityRequest {
  velocity_limit: IRosTypeGeometryMsgsTwist;
}

export interface IRosTypeSpotMsgsSetVelocityResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsSetVolume {
  request: IRosTypeSpotMsgsSetVolumeRequest;
  response: IRosTypeSpotMsgsSetVolumeResponse;
}

export interface IRosTypeSpotMsgsSetVolumeRequest {
  volume: number;
}

export interface IRosTypeSpotMsgsSetVolumeResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsStoreLogpoint {
  request: IRosTypeSpotMsgsStoreLogpointRequest;
  response: IRosTypeSpotMsgsStoreLogpointResponse;
}

export interface IRosTypeSpotMsgsStoreLogpointRequest {
  name: string;
  tag: string;
}

export interface IRosTypeSpotMsgsStoreLogpointResponse {
  success: boolean;
  message: string;
  logpoint: IRosTypeBosdynSpotCamApiMsgsLogpoint;
}

export interface IRosTypeSpotMsgsSystemFault {
  header: IRosTypeStdMsgsHeader;
  name: string;
  duration: { sec: number; nanosec: number };
  code: number;
  uid: number;
  error_message: string;
  attributes: string[];
  severity: number;
}

export enum IRosTypeSpotMsgsSystemFaultConst {
  SEVERITY_UNKNOWN = 0,
  SEVERITY_INFO = 1,
  SEVERITY_WARN = 2,
  SEVERITY_CRITICAL = 3,
}

export interface IRosTypeSpotMsgsSystemFaultState {
  faults: IRosTypeSpotMsgsSystemFault[];
  historical_faults: IRosTypeSpotMsgsSystemFault[];
}

export interface IRosTypeSpotMsgsTagLogpoint {
  request: IRosTypeSpotMsgsTagLogpointRequest;
  response: IRosTypeSpotMsgsTagLogpointResponse;
}

export interface IRosTypeSpotMsgsTagLogpointRequest {
  name: string;
  tag: string;
}

export interface IRosTypeSpotMsgsTagLogpointResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsTrajectoryActionFeedback {
  feedback: string;
}

export interface IRosTypeSpotMsgsTrajectoryActionGoal {
  target_pose: IRosTypeGeometryMsgsPoseStamped;
  duration: { sec: number; nanosec: number };
  precise_positioning: boolean;
}

export interface IRosTypeSpotMsgsTrajectoryActionResult {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsUploadAnimation {
  request: IRosTypeSpotMsgsUploadAnimationRequest;
  response: IRosTypeSpotMsgsUploadAnimationResponse;
}

export interface IRosTypeSpotMsgsUploadAnimationRequest {
  animation_name: string;
  animation_file_content: string;
  animation_proto_serialized: number[];
}

export interface IRosTypeSpotMsgsUploadAnimationResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsUploadSequence {
  request: IRosTypeSpotMsgsUploadSequenceRequest;
  response: IRosTypeSpotMsgsUploadSequenceResponse;
}

export interface IRosTypeSpotMsgsUploadSequenceRequest {
  sequence_proto_serialized: number[];
}

export interface IRosTypeSpotMsgsUploadSequenceResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotMsgsWiFiState {
  current_mode: number;
  essid: string;
}

export enum IRosTypeSpotMsgsWiFiStateConst {
  MODE_UNKNOWN = 0,
  MODE_ACCESS_POINT = 1,
  MODE_CLIENT = 2,
}

export interface IRosTypeSpotNcbRmsActionsInterfacesAvailableModels {
  model_data: IRosTypeSpotNcbRmsActionsInterfacesModelData[];
}

export interface IRosTypeSpotNcbRmsActionsInterfacesModelData {
  model_name: string;
  available_labels: string;
  output_image_spec: IRosTypeSpotNcbRmsActionsInterfacesOutputImageSpec;
  custom_params: string;
}

export interface IRosTypeSpotNcbRmsActionsInterfacesNetworkComputeCapability {
  service_name: string;
  models: IRosTypeSpotNcbRmsActionsInterfacesAvailableModels;
}

export interface IRosTypeSpotNcbRmsActionsInterfacesNetworkComputeCapabilityList {
  services_list: IRosTypeSpotNcbRmsActionsInterfacesNetworkComputeCapability[];
}

export interface IRosTypeSpotNcbRmsActionsInterfacesOutputImageSpec {
  key: string;
  name: string;
}

export interface IRosTypeSpotNcbRmsActionsInterfacesRemoteMissionService {
  service_name: string;
  custom_params: string;
}

export interface IRosTypeSpotNcbRmsActionsInterfacesRemoteMissionServiceList {
  services_list: IRosTypeSpotNcbRmsActionsInterfacesRemoteMissionService[];
}

export interface IRosTypeSpotSpotcamPtzInterfacesAvailableCameras {
  available_cameras: string[];
}

export interface IRosTypeSpotSpotcamPtzInterfacesCaptureImage {
  response: IRosTypeSpotSpotcamPtzInterfacesCaptureImageResponse;
}

export interface IRosTypeSpotSpotcamPtzInterfacesCaptureImageDataAcquisition {
  request: IRosTypeSpotSpotcamPtzInterfacesCaptureImageDataAcquisitionRequest;
  response: IRosTypeSpotSpotcamPtzInterfacesCaptureImageDataAcquisitionResponse;
}

export interface IRosTypeSpotSpotcamPtzInterfacesCaptureImageDataAcquisitionRequest {
  spotcam_image_source: string;
}

export enum IRosTypeSpotSpotcamPtzInterfacesCaptureImageDataAcquisitionRequestConst {
  PTZ_SOURCE = 'ptz',
  PANO_SOURCE = 'pano',
}

export interface IRosTypeSpotSpotcamPtzInterfacesCaptureImageDataAcquisitionResponse {
  blob_storage_tag: string;
  data_acquisition_id: string;
  error_message: string;
  success: boolean;
}

export interface IRosTypeSpotSpotcamPtzInterfacesCaptureImageResponse {
  image: IRosTypeSensorMsgsImage;
}

export interface IRosTypeSpotSpotcamPtzInterfacesCurrentStream {
  camera: string;
  compositor_screen: string;
  show_surround_view: boolean;
}

export interface IRosTypeSpotSpotcamPtzInterfacesGetLighting {
  response: IRosTypeSpotSpotcamPtzInterfacesGetLightingResponse;
}

export interface IRosTypeSpotSpotcamPtzInterfacesGetLightingResponse {
  light_intensity_reported: number;
}

export interface IRosTypeSpotSpotcamPtzInterfacesGetVolume {
  response: IRosTypeSpotSpotcamPtzInterfacesGetVolumeResponse;
}

export interface IRosTypeSpotSpotcamPtzInterfacesGetVolumeResponse {
  volume: number;
  success: boolean;
  message: string;
}

export interface IRosTypeSpotSpotcamPtzInterfacesListSounds {
  response: IRosTypeSpotSpotcamPtzInterfacesListSoundsResponse;
}

export interface IRosTypeSpotSpotcamPtzInterfacesListSoundsResponse {
  names: string[];
  success: boolean;
  message: string;
}

export interface IRosTypeSpotSpotcamPtzInterfacesLoadSound {
  request: IRosTypeSpotSpotcamPtzInterfacesLoadSoundRequest;
  response: IRosTypeSpotSpotcamPtzInterfacesLoadSoundResponse;
}

export interface IRosTypeSpotSpotcamPtzInterfacesLoadSoundRequest {
  name: string;
  wav_path: string;
}

export interface IRosTypeSpotSpotcamPtzInterfacesLoadSoundResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotSpotcamPtzInterfacesPlaySound {
  request: IRosTypeSpotSpotcamPtzInterfacesPlaySoundRequest;
  response: IRosTypeSpotSpotcamPtzInterfacesPlaySoundResponse;
}

export interface IRosTypeSpotSpotcamPtzInterfacesPlaySoundRequest {
  name: string;
  volume_multiplier: number;
}

export interface IRosTypeSpotSpotcamPtzInterfacesPlaySoundResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeSpotSpotcamPtzInterfacesPointFromPanoClick {
  request: IRosTypeSpotSpotcamPtzInterfacesPointFromPanoClickRequest;
  response: IRosTypeSpotSpotcamPtzInterfacesPointFromPanoClickResponse;
}

export interface IRosTypeSpotSpotcamPtzInterfacesPointFromPanoClickRequest {
  click_position_relative_x: number;
  click_position_relative_y: number;
}

export interface IRosTypeSpotSpotcamPtzInterfacesPointFromPanoClickResponse {
  position: IRosTypeSpotSpotcamPtzInterfacesPtzPosition;
  success: boolean;
  error_message: string;
}

export interface IRosTypeSpotSpotcamPtzInterfacesPtzAdjustPosition {
  request: IRosTypeSpotSpotcamPtzInterfacesPtzAdjustPositionRequest;
  response: IRosTypeSpotSpotcamPtzInterfacesPtzAdjustPositionResponse;
}

export interface IRosTypeSpotSpotcamPtzInterfacesPtzAdjustPositionRequest {
  position_delta: IRosTypeSpotSpotcamPtzInterfacesPtzPosition;
}

export interface IRosTypeSpotSpotcamPtzInterfacesPtzAdjustPositionResponse {
  success: boolean;
}

export interface IRosTypeSpotSpotcamPtzInterfacesPtzGetPosition {
  response: IRosTypeSpotSpotcamPtzInterfacesPtzGetPositionResponse;
}

export interface IRosTypeSpotSpotcamPtzInterfacesPtzGetPositionResponse {
  position: IRosTypeSpotSpotcamPtzInterfacesPtzPosition;
}

export interface IRosTypeSpotSpotcamPtzInterfacesPtzPosition {
  pan: number;
  tilt: number;
  zoom: number;
}

export interface IRosTypeSpotSpotcamPtzInterfacesPtzSetPosition {
  request: IRosTypeSpotSpotcamPtzInterfacesPtzSetPositionRequest;
  response: IRosTypeSpotSpotcamPtzInterfacesPtzSetPositionResponse;
}

export interface IRosTypeSpotSpotcamPtzInterfacesPtzSetPositionRequest {
  position: IRosTypeSpotSpotcamPtzInterfacesPtzPosition;
}

export interface IRosTypeSpotSpotcamPtzInterfacesPtzSetPositionResponse {
  success: boolean;
  position: IRosTypeSpotSpotcamPtzInterfacesPtzPosition;
}

export interface IRosTypeSpotSpotcamPtzInterfacesSetCamera {
  request: IRosTypeSpotSpotcamPtzInterfacesSetCameraRequest;
  response: IRosTypeSpotSpotcamPtzInterfacesSetCameraResponse;
}

export interface IRosTypeSpotSpotcamPtzInterfacesSetCameraRequest {
  camera: string;
  always_treat_as_compositor_string: boolean;
}

export interface IRosTypeSpotSpotcamPtzInterfacesSetCameraResponse {
  active_screen: string;
  success: boolean;
  error_message: string;
}

export interface IRosTypeSpotSpotcamPtzInterfacesSetLighting {
  request: IRosTypeSpotSpotcamPtzInterfacesSetLightingRequest;
  response: IRosTypeSpotSpotcamPtzInterfacesSetLightingResponse;
}

export interface IRosTypeSpotSpotcamPtzInterfacesSetLightingRequest {
  light_intensity: number;
}

export interface IRosTypeSpotSpotcamPtzInterfacesSetLightingResponse {
  light_intensity_reported: number;
}

export interface IRosTypeSpotSpotcamPtzInterfacesSetVolume {
  request: IRosTypeSpotSpotcamPtzInterfacesSetVolumeRequest;
  response: IRosTypeSpotSpotcamPtzInterfacesSetVolumeResponse;
}

export interface IRosTypeSpotSpotcamPtzInterfacesSetVolumeRequest {
  volume: number;
}

export interface IRosTypeSpotSpotcamPtzInterfacesSetVolumeResponse {
  success: boolean;
  message: string;
}

export enum IRosTypeStatisticsMsgsStatisticDataTypeConst {
  STATISTICS_DATA_TYPE_UNINITIALIZED = 0,
  STATISTICS_DATA_TYPE_AVERAGE = 1,
  STATISTICS_DATA_TYPE_MINIMUM = 2,
  STATISTICS_DATA_TYPE_MAXIMUM = 3,
  STATISTICS_DATA_TYPE_STDDEV = 4,
  STATISTICS_DATA_TYPE_SAMPLE_COUNT = 5,
}

export interface IRosTypeStatisticsMsgsMetricsMessage {
  measurement_source_name: string;
  metrics_source: string;
  unit: string;
  window_start: { sec: number; nanosec: number };
  window_stop: { sec: number; nanosec: number };
  statistics: IRosTypeStatisticsMsgsStatisticDataPoint[];
}

export interface IRosTypeStatisticsMsgsStatisticDataPoint {
  data_type: number;
  data: number;
}

export interface IRosTypeStdMsgsBool {
  data: boolean;
}

export interface IRosTypeStdMsgsByte {
  data: number;
}

export interface IRosTypeStdMsgsByteMultiArray {
  layout: IRosTypeStdMsgsMultiArrayLayout;
  data: number[];
}

export interface IRosTypeStdMsgsChar {
  data: number;
}

export interface IRosTypeStdMsgsColorRgba {
  r: number;
  g: number;
  b: number;
  a: number;
}

export interface IRosTypeStdMsgsFloat32 {
  data: number;
}

export interface IRosTypeStdMsgsFloat32MultiArray {
  layout: IRosTypeStdMsgsMultiArrayLayout;
  data: number[];
}

export interface IRosTypeStdMsgsFloat64 {
  data: number;
}

export interface IRosTypeStdMsgsFloat64MultiArray {
  layout: IRosTypeStdMsgsMultiArrayLayout;
  data: number[];
}

export interface IRosTypeStdMsgsHeader {
  stamp: { sec: number; nanosec: number };
  frame_id: string;
}

export interface IRosTypeStdMsgsInt16 {
  data: number;
}

export interface IRosTypeStdMsgsInt16MultiArray {
  layout: IRosTypeStdMsgsMultiArrayLayout;
  data: number[];
}

export interface IRosTypeStdMsgsInt32 {
  data: number;
}

export interface IRosTypeStdMsgsInt32MultiArray {
  layout: IRosTypeStdMsgsMultiArrayLayout;
  data: number[];
}

export interface IRosTypeStdMsgsInt64 {
  data: number;
}

export interface IRosTypeStdMsgsInt64MultiArray {
  layout: IRosTypeStdMsgsMultiArrayLayout;
  data: number[];
}

export interface IRosTypeStdMsgsInt8 {
  data: number;
}

export interface IRosTypeStdMsgsInt8MultiArray {
  layout: IRosTypeStdMsgsMultiArrayLayout;
  data: number[];
}

export interface IRosTypeStdMsgsMultiArrayDimension {
  label: string;
  size: number;
  stride: number;
}

export interface IRosTypeStdMsgsMultiArrayLayout {
  dim: IRosTypeStdMsgsMultiArrayDimension[];
  data_offset: number;
}

export interface IRosTypeStdMsgsString {
  data: string;
}

export interface IRosTypeStdMsgsUInt16 {
  data: number;
}

export interface IRosTypeStdMsgsUInt16MultiArray {
  layout: IRosTypeStdMsgsMultiArrayLayout;
  data: number[];
}

export interface IRosTypeStdMsgsUInt32 {
  data: number;
}

export interface IRosTypeStdMsgsUInt32MultiArray {
  layout: IRosTypeStdMsgsMultiArrayLayout;
  data: number[];
}

export interface IRosTypeStdMsgsUInt64 {
  data: number;
}

export interface IRosTypeStdMsgsUInt64MultiArray {
  layout: IRosTypeStdMsgsMultiArrayLayout;
  data: number[];
}

export interface IRosTypeStdMsgsUInt8 {
  data: number;
}

export interface IRosTypeStdMsgsUInt8MultiArray {
  layout: IRosTypeStdMsgsMultiArrayLayout;
  data: number[];
}

export interface IRosTypeStdSrvsSetBool {
  request: IRosTypeStdSrvsSetBoolRequest;
  response: IRosTypeStdSrvsSetBoolResponse;
}

export interface IRosTypeStdSrvsSetBoolRequest {
  data: boolean;
}

export interface IRosTypeStdSrvsSetBoolResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeStdSrvsTrigger {
  response: IRosTypeStdSrvsTriggerResponse;
}

export interface IRosTypeStdSrvsTriggerResponse {
  success: boolean;
  message: string;
}

export interface IRosTypeStereoMsgsDisparityImage {
  header: IRosTypeStdMsgsHeader;
  image: IRosTypeSensorMsgsImage;
  f: number;
  t: number;
  valid_window: IRosTypeSensorMsgsRegionOfInterest;
  min_disparity: number;
  max_disparity: number;
  delta_d: number;
}

export interface IRosTypeTf2MsgsFrameGraph {
  response: IRosTypeTf2MsgsFrameGraphResponse;
}

export interface IRosTypeTf2MsgsFrameGraphResponse {
  frame_yaml: string;
}

export interface IRosTypeTf2MsgsLookupTransformActionGoal {
  target_frame: string;
  source_frame: string;
  source_time: { sec: number; nanosec: number };
  timeout: { sec: number; nanosec: number };
  target_time: { sec: number; nanosec: number };
  fixed_frame: string;
  advanced: boolean;
}

export interface IRosTypeTf2MsgsLookupTransformActionResult {
  transform: IRosTypeGeometryMsgsTransformStamped;
  error: IRosTypeTf2MsgsTf2Error;
}

export interface IRosTypeTf2MsgsTf2Error {
  error: number;
  error_string: string;
}

export enum IRosTypeTf2MsgsTf2ErrorConst {
  NO_ERROR = 0,
  LOOKUP_ERROR = 1,
  CONNECTIVITY_ERROR = 2,
  EXTRAPOLATION_ERROR = 3,
  INVALID_ARGUMENT_ERROR = 4,
  TIMEOUT_ERROR = 5,
  TRANSFORM_ERROR = 6,
}

export interface IRosTypeTf2MsgsTfMessage {
  transforms: IRosTypeGeometryMsgsTransformStamped[];
}

export interface IRosTypeTopicToolsInterfacesMuxAdd {
  request: IRosTypeTopicToolsInterfacesMuxAddRequest;
  response: IRosTypeTopicToolsInterfacesMuxAddResponse;
}

export interface IRosTypeTopicToolsInterfacesMuxAddRequest {
  topic: string;
}

export interface IRosTypeTopicToolsInterfacesMuxAddResponse {
  success: boolean;
}

export interface IRosTypeTopicToolsInterfacesMuxDelete {
  request: IRosTypeTopicToolsInterfacesMuxDeleteRequest;
  response: IRosTypeTopicToolsInterfacesMuxDeleteResponse;
}

export interface IRosTypeTopicToolsInterfacesMuxDeleteRequest {
  topic: string;
}

export interface IRosTypeTopicToolsInterfacesMuxDeleteResponse {
  success: boolean;
}

export interface IRosTypeTopicToolsInterfacesMuxList {
  response: IRosTypeTopicToolsInterfacesMuxListResponse;
}

export interface IRosTypeTopicToolsInterfacesMuxListResponse {
  topics: string[];
}

export interface IRosTypeTopicToolsInterfacesMuxSelect {
  request: IRosTypeTopicToolsInterfacesMuxSelectRequest;
  response: IRosTypeTopicToolsInterfacesMuxSelectResponse;
}

export interface IRosTypeTopicToolsInterfacesMuxSelectRequest {
  topic: string;
}

export interface IRosTypeTopicToolsInterfacesMuxSelectResponse {
  prev_topic: string;
  success: boolean;
}

export interface IRosTypeTrajectoryMsgsJointTrajectory {
  header: IRosTypeStdMsgsHeader;
  joint_names: string[];
  points: IRosTypeTrajectoryMsgsJointTrajectoryPoint[];
}

export interface IRosTypeTrajectoryMsgsJointTrajectoryPoint {
  positions: number[];
  velocities: number[];
  accelerations: number[];
  effort: number[];
  time_from_start: { sec: number; nanosec: number };
}

export interface IRosTypeTrajectoryMsgsMultiDofJointTrajectory {
  header: IRosTypeStdMsgsHeader;
  joint_names: string[];
  points: IRosTypeTrajectoryMsgsMultiDofJointTrajectoryPoint[];
}

export interface IRosTypeTrajectoryMsgsMultiDofJointTrajectoryPoint {
  transforms: IRosTypeGeometryMsgsTransform[];
  velocities: IRosTypeGeometryMsgsTwist[];
  accelerations: IRosTypeGeometryMsgsTwist[];
  time_from_start: { sec: number; nanosec: number };
}

export interface IRosTypeUniqueIdentifierMsgsUuid {
  uuid: number[];
}

export interface IRosTypeVisualizationMsgsGetInteractiveMarkers {
  response: IRosTypeVisualizationMsgsGetInteractiveMarkersResponse;
}

export interface IRosTypeVisualizationMsgsGetInteractiveMarkersResponse {
  sequence_number: number;
  markers: IRosTypeVisualizationMsgsInteractiveMarker[];
}

export interface IRosTypeVisualizationMsgsImageMarker {
  header: IRosTypeStdMsgsHeader;
  ns: string;
  id: number;
  type: number;
  action: number;
  position: IRosTypeGeometryMsgsPoint;
  scale: number;
  outline_color: IRosTypeStdMsgsColorRgba;
  filled: number;
  fill_color: IRosTypeStdMsgsColorRgba;
  lifetime: { sec: number; nanosec: number };
  points: IRosTypeGeometryMsgsPoint[];
  outline_colors: IRosTypeStdMsgsColorRgba[];
}

export enum IRosTypeVisualizationMsgsImageMarkerConst {
  CIRCLE = 0,
  LINE_STRIP = 1,
  LINE_LIST = 2,
  POLYGON = 3,
  POINTS = 4,
  ADD = 0,
  REMOVE = 1,
}

export interface IRosTypeVisualizationMsgsInteractiveMarker {
  header: IRosTypeStdMsgsHeader;
  pose: IRosTypeGeometryMsgsPose;
  name: string;
  description: string;
  scale: number;
  menu_entries: IRosTypeVisualizationMsgsMenuEntry[];
  controls: IRosTypeVisualizationMsgsInteractiveMarkerControl[];
}

export interface IRosTypeVisualizationMsgsInteractiveMarkerControl {
  name: string;
  orientation: IRosTypeGeometryMsgsQuaternion;
  orientation_mode: number;
  interaction_mode: number;
  always_visible: boolean;
  markers: IRosTypeVisualizationMsgsMarker[];
  independent_marker_orientation: boolean;
  description: string;
}

export enum IRosTypeVisualizationMsgsInteractiveMarkerControlConst {
  INHERIT = 0,
  FIXED = 1,
  VIEW_FACING = 2,
  NONE = 0,
  MENU = 1,
  BUTTON = 2,
  MOVE_AXIS = 3,
  MOVE_PLANE = 4,
  ROTATE_AXIS = 5,
  MOVE_ROTATE = 6,
  MOVE_3D = 7,
  ROTATE_3D = 8,
  MOVE_ROTATE_3D = 9,
}

export interface IRosTypeVisualizationMsgsInteractiveMarkerFeedback {
  header: IRosTypeStdMsgsHeader;
  client_id: string;
  marker_name: string;
  control_name: string;
  event_type: number;
  pose: IRosTypeGeometryMsgsPose;
  menu_entry_id: number;
  mouse_point: IRosTypeGeometryMsgsPoint;
  mouse_point_valid: boolean;
}

export enum IRosTypeVisualizationMsgsInteractiveMarkerFeedbackConst {
  KEEP_ALIVE = 0,
  POSE_UPDATE = 1,
  MENU_SELECT = 2,
  BUTTON_CLICK = 3,
  MOUSE_DOWN = 4,
  MOUSE_UP = 5,
}

export interface IRosTypeVisualizationMsgsInteractiveMarkerInit {
  server_id: string;
  seq_num: number;
  markers: IRosTypeVisualizationMsgsInteractiveMarker[];
}

export interface IRosTypeVisualizationMsgsInteractiveMarkerPose {
  header: IRosTypeStdMsgsHeader;
  pose: IRosTypeGeometryMsgsPose;
  name: string;
}

export interface IRosTypeVisualizationMsgsInteractiveMarkerUpdate {
  server_id: string;
  seq_num: number;
  type: number;
  markers: IRosTypeVisualizationMsgsInteractiveMarker[];
  poses: IRosTypeVisualizationMsgsInteractiveMarkerPose[];
  erases: string[];
}

export enum IRosTypeVisualizationMsgsInteractiveMarkerUpdateConst {
  KEEP_ALIVE = 0,
  UPDATE = 1,
}

export interface IRosTypeVisualizationMsgsMarker {
  header: IRosTypeStdMsgsHeader;
  ns: string;
  id: number;
  type: number;
  action: number;
  pose: IRosTypeGeometryMsgsPose;
  scale: IRosTypeGeometryMsgsVector3;
  color: IRosTypeStdMsgsColorRgba;
  lifetime: { sec: number; nanosec: number };
  frame_locked: boolean;
  points: IRosTypeGeometryMsgsPoint[];
  colors: IRosTypeStdMsgsColorRgba[];
  texture_resource: string;
  texture: IRosTypeSensorMsgsCompressedImage;
  uv_coordinates: IRosTypeVisualizationMsgsUvCoordinate[];
  text: string;
  mesh_resource: string;
  mesh_file: IRosTypeVisualizationMsgsMeshFile;
  mesh_use_embedded_materials: boolean;
}

export enum IRosTypeVisualizationMsgsMarkerConst {
  ARROW = 0,
  CUBE = 1,
  SPHERE = 2,
  CYLINDER = 3,
  LINE_STRIP = 4,
  LINE_LIST = 5,
  CUBE_LIST = 6,
  SPHERE_LIST = 7,
  POINTS = 8,
  TEXT_VIEW_FACING = 9,
  MESH_RESOURCE = 10,
  TRIANGLE_LIST = 11,
  ADD = 0,
  MODIFY = 0,
  DELETE = 2,
  DELETEALL = 3,
}

export interface IRosTypeVisualizationMsgsMarkerArray {
  markers: IRosTypeVisualizationMsgsMarker[];
}

export interface IRosTypeVisualizationMsgsMenuEntry {
  id: number;
  parent_id: number;
  title: string;
  command: string;
  command_type: number;
}

export enum IRosTypeVisualizationMsgsMenuEntryConst {
  FEEDBACK = 0,
  ROSRUN = 1,
  ROSLAUNCH = 2,
}

export interface IRosTypeVisualizationMsgsMeshFile {
  filename: string;
  data: number[];
}

export interface IRosTypeVisualizationMsgsUvCoordinate {
  u: number;
  v: number;
}
