import { axiosRequest } from 'lib/api/axios';
import { ManualControlUrls } from 'lib/api/urls';
import { AzureADState } from 'lib/constants/state';

export type MainOperator = {
  userName?: string;
  currentUserIsMainOperator: boolean;
  otherUserIsMainOperator: boolean;
  lastAcivityTime?: string;
};

export type ManualControlCacheItem = {
  ioTHubDeviceName?: string;
  userName?: string;
  /**
   * Only if there is a main operator in general - its just a helper internally
   */
  isMainOperator?: boolean;
  lastAcivityTime?: string;
};

export async function handleGetCurrentMainOperator(
  deviceName: string,
): Promise<ManualControlCacheItem> {
  const { method, url } = ManualControlUrls.GetCurrentMainOperator;
  const { data } = await axiosRequest<ManualControlCacheItem>(method, url(deviceName));
  return data;
}

/**
 * This will only work if there is nobody currently main operator
 *
 * @param deviceName
 * @returns username - the current main operator
 */
export async function handleSetCurrentActivity(deviceName: string): Promise<string> {
  const { method, url } = ManualControlUrls.SetCurrentActivity;
  const { data: username } = await axiosRequest<string>(method, url(deviceName));
  return username;
}

/**
 * Note: we receive also isMainOperator - but we current design say we should distinguish this in client
 * @param data
 */
export function parseMainOperatorData(data: ManualControlCacheItem): MainOperator {
  const userName = data?.userName;
  const lastAcivityTime = data?.lastAcivityTime;
  const currentUserIsMainOperator = userName === AzureADState.accountInfo?.userName;
  const otherUserIsMainOperator = !currentUserIsMainOperator && !!userName;

  return {
    userName,
    currentUserIsMainOperator,
    otherUserIsMainOperator,
    lastAcivityTime,
  };
}
