import { cssVar } from 'polished';

/**
 * Read the hsl value from a css variable and return it as a hsl string
 * Note: The helper functions from polished are not working with decimal values well: https://github.com/styled-components/polished/issues/634
 */
export function hsLFromCssVar(cssV: string) {
  const matchCssVar = cssV.match(/(--[^)]+)/g)?.[0];
  if (!matchCssVar) return cssV;
  const cssVarValue = cssVar(matchCssVar) as string;
  const hslValues = cssVarValue.split(' ');

  const hue = +parseFloat(hslValues[0]).toFixed(2);
  const saturation = +parseFloat(hslValues[1].replace('%', '')).toFixed(1);
  const lightness = +parseFloat(hslValues[2].replace('%', '')).toFixed(1);

  return `hsl(${hue}deg ${saturation}% ${lightness}%)`;
}
