import type { IothubEvent } from './useIothubHub';
import { RosbridgeHubEvents } from './useRosbridgeHub';
import { SpotcontrolHubEvents } from './useSpotcontrolHub';
import { TelemetryHubEvents } from './useTelemetryHub';
import { TeleoperationHubEvents } from './useTeleoperationHub';

/**
 * All available topics for all hubs
 */
export type MethodName =
  | TelemetryHubEvents
  | IothubEvent
  | TeleoperationHubEvents
  | SpotcontrolHubEvents
  | RosbridgeHubEvents;

export enum HUB_URL {
  TELEMETRY = 'telemetry',
  TELEOPERATION = 'teleoperation',
  SPOTCONTROL = 'spotcontrol',
  IOTHUB = 'iothub',
  ROSBRIDGE = 'rosbridge',
}

export const HUB_URLS = Object.values(HUB_URL);

export type InvokeMethod = { args?: unknown; callback?: (data: unknown) => void };
export type ListenMethod = (data: unknown | any) => void;
