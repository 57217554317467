import { useDeviceName } from 'store/my-robot';

import { HUB_URL, InvokeMethod, ListenMethod } from './const';
import { useSignalRHub } from './context/useSignalRHub';

export enum RosbridgeHubEvents {
  ReceiveRosBridgeMessage = 'ReceiveRosBridgeMessage',
  SendRosBridgeMessage = 'SendRosBridgeMessage',
}

/**
 * Hook to use for the Rosbridge hub
 */
export function useRosbridgeHub({
  listenMethods,
  invokeMethods,
}: {
  invokeMethods?: Partial<Record<RosbridgeHubEvents, InvokeMethod>>;
  listenMethods?: Partial<Record<RosbridgeHubEvents, ListenMethod>>;
}) {
  const hub = useSignalRHub(HUB_URL.ROSBRIDGE, invokeMethods, listenMethods);
  const { deviceName } = useDeviceName();

  async function sendToRosbridge(data: any) {
    return await hub.connection.invoke(RosbridgeHubEvents.SendRosBridgeMessage, data, deviceName);
  }

  const RosbridgeHubClient = {
    sendToRosbridge,
  };

  return { ...hub, RosbridgeHubClient };
}
