import { toast } from 'react-toastify';
import { ToastOptions, TypeOptions } from 'react-toastify/dist/types';

import {
  GamepadStickValues,
  STICK_TRIGGER_SENSITIVITY,
  XboxOneGamepadAxis,
  XboxOneGamepadStick,
  XboxOneGamepadStickAdditionalOption,
} from 'types/models/gamepad/GamepadButtons';
import { GamepadAxisAction, GamepadKey, GamepadMapper } from 'types/models/gamepad/GamepadMapper';

import { GAMEPAD_TOAST_POSITION } from './consts';

export const consoleLogKeyPressed = (key: string) => () => {
  console.log(key);
};

export const getComboKey = (keys: GamepadKey[]) => keys.join('+');

export const checkIsKeyPressed = (key: GamepadKey, buttons: readonly GamepadButton[]) =>
  buttons[key as number]?.pressed;

export const checkIsComboPressed = (comboKeys: GamepadKey[], buttons: readonly GamepadButton[]) =>
  comboKeys.map((comboKey) => buttons[comboKey as number].pressed).every((pressed) => pressed);

type LastPressedButtonState = {
  button: string | null;
  numberOfIntervals: number;
};

const AXIS_THROTTLE = 200;

let timerFlag: ReturnType<typeof setTimeout> | null = null; // Variable to keep track of the timer
function throttle(mainFunction: GamepadAxisAction, delay = 50) {
  // Returning a throttled version
  return (...args: any[]) => {
    if (timerFlag === null) {
      // If there is no timer currently running
      mainFunction(...args); // Execute the main function
      timerFlag = setTimeout(() => {
        // Set a timer to clear the timerFlag after the specified delay
        timerFlag = null; // Clear the timerFlag to allow the main function to be executed again
      }, delay);
    }
  };
}

export const handleControllerInputs = (
  buttons: readonly GamepadButton[],
  mapper: GamepadMapper,
  lastPressed: LastPressedButtonState,
) => {
  let currentPress: LastPressedButtonState = {
    button: null,
    numberOfIntervals: 0,
  };
  let isComboExecuted = false;

  for (const combo of mapper.combos) {
    if (checkIsComboPressed(combo, buttons)) {
      const comboKey = getComboKey(combo);
      // already triggered - just increment
      if (lastPressed.button === comboKey) {
        currentPress.numberOfIntervals = lastPressed.numberOfIntervals + 1;
      } else {
        // call actions
        mapper.actions[comboKey]?.({ x: 0, y: 0 });
        currentPress.numberOfIntervals = 1;
      }
      currentPress.button = comboKey;
      isComboExecuted = true;
      break;
    }
  }

  if (isComboExecuted) {
    return currentPress;
  }

  for (const key of mapper.keys) {
    if (checkIsKeyPressed(key, buttons)) {
      const stringKey = key.toString();
      if (lastPressed.button === stringKey) {
        currentPress.numberOfIntervals = lastPressed.numberOfIntervals + 1;
      } else {
        mapper.actions[key]?.({ x: 0, y: 0 });
        currentPress.numberOfIntervals = 1;
      }
      currentPress.button = stringKey;
      break;
    }
  }

  return currentPress;
};

type LastMovedAxesState = {
  axis: XboxOneGamepadStick | XboxOneGamepadStickAdditionalOption | null;
};

export const handleAxesInputs = (
  mapper: GamepadMapper,
  axes: readonly number[],
  lastMovedAxes: LastMovedAxesState,
) => {
  const leftStick = {
    x: axes[XboxOneGamepadAxis.LSB_HORIZONTAL] ?? 0,
    y: axes[XboxOneGamepadAxis.LSB_VERTICAL] ?? 0,
  };
  const rightStick = {
    x: axes[XboxOneGamepadAxis.RSB_HORIZONTAL] ?? 0,
    y: axes[XboxOneGamepadAxis.RSB_VERTICAL] ?? 0,
  };

  const isStickActive = (stick: GamepadStickValues) =>
    [Math.abs(stick.x), Math.abs(stick.y)].some((value) => value > STICK_TRIGGER_SENSITIVITY);

  const onStickAction = (stick: GamepadStickValues, stickKey: XboxOneGamepadStick) => {
    const action = mapper.actions[stickKey];
    action?.(stick);
  };

  const onBothStickAction = (leftStick: GamepadStickValues, rightStick: GamepadStickValues) => {
    const action = mapper.actions[XboxOneGamepadStickAdditionalOption.LEFT_AND_RIGHT];
    action?.(leftStick, rightStick);
  };

  const isLeftActive = isStickActive(leftStick);
  const isRightActive = isStickActive(rightStick);
  const isBothActive = isLeftActive && isRightActive;

  const lastAxis = lastMovedAxes.axis;

  if (lastAxis) {
    const lastAxis = lastMovedAxes.axis;
    if (lastAxis === XboxOneGamepadStick.LSB && !isLeftActive && !isBothActive) {
      mapper.actions[XboxOneGamepadStickAdditionalOption.LSB_STOPPED]?.();
    } else if (lastAxis === XboxOneGamepadStick.RSB && !isRightActive && !isBothActive) {
      mapper.actions[XboxOneGamepadStickAdditionalOption.RSB_STOPPED]?.();
    } else if (lastAxis === XboxOneGamepadStickAdditionalOption.LEFT_AND_RIGHT && !isBothActive) {
      mapper.actions[XboxOneGamepadStickAdditionalOption.LSB_STOPPED]?.();
      mapper.actions[XboxOneGamepadStickAdditionalOption.RSB_STOPPED]?.();
    }
  }

  if (isBothActive) {
    throttle(() => onBothStickAction(leftStick, rightStick), AXIS_THROTTLE)();
    return { axis: XboxOneGamepadStickAdditionalOption.LEFT_AND_RIGHT };
  } else if (isLeftActive) {
    throttle(() => onStickAction(leftStick, XboxOneGamepadStick.LSB), AXIS_THROTTLE)();
    return { axis: XboxOneGamepadStick.LSB };
  } else if (isRightActive) {
    throttle(() => onStickAction(rightStick, XboxOneGamepadStick.RSB), AXIS_THROTTLE)();
    return { axis: XboxOneGamepadStick.RSB };
  }

  return { axis: null };
};

export const gamepadToast = (type: TypeOptions, message: string, options?: ToastOptions) => {
  const defaultOptions: ToastOptions = {
    autoClose: 400,
    hideProgressBar: true,
    closeButton: false,
  };
  const allOptions = { ...defaultOptions, ...options };

  return toast(message, {
    position: GAMEPAD_TOAST_POSITION,
    type,
    ...allOptions,
  });
};
