import { useQuery, useQueryClient } from '@tanstack/react-query';
import { spot_localization_handler_interfaces } from 'rclnodejs';

import { RobotRosTopic } from '../types';

export type LocalizationLocation = spot_localization_handler_interfaces.msg.LocalizationLocation;

const queryKey = ['relayTopics', RobotRosTopic.CURRENT_LOCATION];

export function parseLocation(location?: LocalizationLocation) {
  const { map_id } = location ?? {};
  const mapId = map_id && +map_id ? +map_id : -1;

  // by design in the ros world
  const isLocalized = mapId !== -1;

  return { mapId, isLocalized };
}

export function useCurrentLocation() {
  // todo - what happen if we do suspense here?
  const queryClient = useQuery<LocalizationLocation>({
    queryKey,
  });

  const currentLocation = queryClient?.data;

  const { mapId, isLocalized } = parseLocation(currentLocation);
  return { mapId, isLocalized };
}

export function useCurrentLocationSetter() {
  const queryClient = useQueryClient();

  function updateCurrentLocation(data: unknown) {
    const currentLocation = data as LocalizationLocation;
    queryClient.setQueryData(queryKey, currentLocation);
  }

  return { updateCurrentLocation };
}
