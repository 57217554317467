import { PropsWithChildren } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { LazyLoad, Loader } from 'components/common';
import RobotErrorScreen from 'components/complex/robot-error-screen/RobotErrorScreen';
import Sidebar from 'components/complex/sidebar/Sidebar';
import { RosBridgeProvider } from 'components/provider';
import { Text } from 'components/ui/typography';

import { useGetMyRobot } from 'hooks';

import { useReceiveRobotMainModuleReportedData } from 'hooks/complex/signal-r/useReceiveRobotMainModuleReportedData';
import {
  HUB_URL,
  SignalRHubProvider,
  useIothubHub,
  useSpotcontrolHub,
  useTelemetryHub,
  useTeleoperationHub,
} from 'hooks/signalR';
import { useRosbridgeHub } from 'hooks/signalR/useRosbridgeHub';

import { isPositiveInteger } from 'utils/helpers';

import { SMain, SPageContainer } from '../styled';

/**
 *  Subscribe to receiving data - e.g. the battery state with is always visible in the sidem enu
 *
 *  Todo: review all the state related to this to find a bedder place for this.
 */
function ReceiveRobotMainModuleReportedData() {
  useReceiveRobotMainModuleReportedData();
  return null;
}

// todo - the unmount/mount problem is not related to outlet
// We regularly get updates from the robot but need the device name to connect to the correct SignalR hub
function SignalREnsureConnectionWrapper({ children }: PropsWithChildren) {
  const iothub = useIothubHub({});
  const telemetryHub = useTelemetryHub({});
  const teleoperationHub = useTeleoperationHub({});
  const spotcontrolHub = useSpotcontrolHub({});
  const rosbridgeHub = useRosbridgeHub({});

  const isConnected =
    iothub?.isConnected &&
    telemetryHub?.isConnected &&
    teleoperationHub.isConnected &&
    spotcontrolHub.isConnected &&
    rosbridgeHub.isConnected;

  if (!isConnected) {
    let hubs = [];
    if (!iothub.isConnected) hubs.push(HUB_URL.IOTHUB);
    if (!telemetryHub.isConnected) hubs.push(HUB_URL.TELEMETRY);
    if (!teleoperationHub.isConnected) hubs.push(HUB_URL.TELEOPERATION);
    if (!spotcontrolHub.isConnected) hubs.push(HUB_URL.SPOTCONTROL);
    if (!rosbridgeHub.isConnected) hubs.push(HUB_URL.ROSBRIDGE);
    return (
      <div className="pl-2 h-full flex flex-col justify-end">
        <Text>Connection to hub(s): {hubs.join(', ')}</Text>
        <Loader fixed />
      </div>
    );
  }

  return children;
}

/**
 * This protects every page with path: '/:id/*' for not having basic information about the robot
 * If (with is currently not possible because switching the robot is not part this following area)
 * the id would change the data would get reloaded and every children would be recreated
 *
 * Todo: SignalRHubProvider get remounted but this not - properly related to a suspense?
 */
function LoadMyRobotData({ robotId }: { robotId: number }) {
  const { isFetched, error } = useGetMyRobot(robotId);

  if (error) {
    return (
      <SPageContainer>
        <RobotErrorScreen error={error?.title} />
      </SPageContainer>
    );
  }

  if (!isFetched) {
    return (
      <SPageContainer>
        <Loader fixed />
      </SPageContainer>
    );
  }

  return (
    <>
      <Sidebar isRobotPage />
      <SPageContainer>
        <LazyLoad>
          <SignalRHubProvider>
            <SignalREnsureConnectionWrapper>
              <RosBridgeProvider>
                <ReceiveRobotMainModuleReportedData />
                <Outlet />
              </RosBridgeProvider>
            </SignalREnsureConnectionWrapper>
          </SignalRHubProvider>
        </LazyLoad>
      </SPageContainer>
    </>
  );
}

const LayoutRobotManagement = () => {
  const params = useParams();
  const robotId = params?.id ? +params.id : -1;
  const urlParamValid = isPositiveInteger(robotId);

  return (
    <SMain>
      {!urlParamValid ? (
        <SPageContainer>
          <RobotErrorScreen error="If you used a custom URL, please make sure it is correct." />
        </SPageContainer>
      ) : (
        <LoadMyRobotData robotId={robotId} />
      )}
    </SMain>
  );
};

export default LayoutRobotManagement;
