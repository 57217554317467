import Carousel, {
  ReactElasticCarouselProps,
  RenderArrowProps,
  RenderPaginationProps,
} from '@itseasy21/react-elastic-carousel';
import { ReactNode, memo, useCallback } from 'react';

import { Icon } from 'components/common/index';
import { SBox } from 'components/ui';

import {
  SCarouselButton,
  SCarouselContainer,
  SCarouselPaginationButton,
} from './CarouselComponent.styled';

const defaultConfigs: ReactElasticCarouselProps = {
  itemsToShow: 3,
  itemsToScroll: 3,
  initialActiveIndex: 0,
  itemPadding: [10, 10],
  // breakPoints: [
  //     {
  //         width: 1,
  //         itemsToShow: 1
  //     },
  //     {
  //         width: 550,
  //         itemsToShow: 2,
  //         itemsToScroll: 2
  //     },
  //     {
  //         width: 850,
  //         itemsToShow: 3
  //     },
  //     {
  //         width: 1150,
  //         itemsToShow: 4,
  //         itemsToScroll: 2
  //     },
  //     {
  //         width: 1450,
  //         itemsToShow: 5
  //     },
  //     {
  //         width: 1750,
  //         itemsToShow: 6
  //     }
  // ],
  showEmptySlots: true,
  isRTL: false,
};

const CarouselComponent = memo<{
  configs?: Partial<ReactElasticCarouselProps>;
  children: ReactNode;
}>(({ configs, children }) => {
  const myArrow = useCallback(
    ({ type, onClick, isEdge }: RenderArrowProps) => (
      <SBox display="flex" align="center" p={2}>
        <SCarouselButton onClick={onClick} disabled={isEdge} round type="button">
          {type === 'PREV' ? (
            <Icon icon="arrow_left" />
          ) : (
            <Icon icon="arrow_left" style={{ transform: 'scale(-1)' }} />
          )}
        </SCarouselButton>
      </SBox>
    ),
    [],
  );

  const renderPagination = useCallback(
    ({ pages, activePage, onClick }: RenderPaginationProps) => (
      <SBox display="flex" w="calc(100% - (70px * 2))">
        {pages.map((page) => (
          <SCarouselPaginationButton
            key={page}
            onClick={() => {
              onClick(`${page}`);
            }}
            isActive={activePage === page}
            type="button"
            // round
          />
        ))}
      </SBox>
    ),
    [],
  );

  return (
    <SCarouselContainer>
      <Carousel
        renderArrow={myArrow}
        renderPagination={renderPagination}
        {...defaultConfigs}
        {...configs}
      >
        {children}
      </Carousel>
    </SCarouselContainer>
  );
});

export default CarouselComponent;
