import { atomFamily } from 'recoil';

import { DeviceName } from 'types/models';

import { SpotMobilityParamsPayload } from './spot-mobility-params';
import { RobotRosTopic, SpotClaimPayload, SpotFeedbackPayload } from './types';

type RobotRelayState = {
  [RobotRosTopic.SPOT_FEEDBACK]?: SpotFeedbackPayload;
  [RobotRosTopic.SPOT_MOBILITY_PARAMS]?: SpotMobilityParamsPayload;
  [RobotRosTopic.SPOT_CLAIM]?: SpotClaimPayload;
};

export const relayTopicsAtom = atomFamily<RobotRelayState, DeviceName>({
  key: 'relayTopicsAtom',
  default: {
    [RobotRosTopic.SPOT_FEEDBACK]: undefined,
    [RobotRosTopic.SPOT_MOBILITY_PARAMS]: undefined,
    [RobotRosTopic.SPOT_CLAIM]: undefined,
  },
});
