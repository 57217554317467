import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';
import { ReactNode } from 'react';
import { cn } from 'utils';

import { LoadingIcon } from 'components/common/icon';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 relative',
  // 'border border-transparent inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background',
  {
    variants: {
      variant: {
        // default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        default: 'bg-primary text-primary-foreground shadow hover:bg-primary/90',
        // destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        destructive: 'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        // outline: 'border-border hover:bg-accent hover:text-accent-foreground bg-background',
        outline:
          'border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
        // secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        secondary: 'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        // default: 'h-10 py-2 px-4',
        default: 'h-9 px-4 py-2',
        // sm: 'h-7 px-3 rounded-md font-normal',
        sm: 'h-8 rounded-md px-3 text-xs',
        // lg: 'h-11 px-8 rounded-md',
        lg: 'h-10 rounded-md px-8',
        icon: 'h-9 w-9',
        // icon2: 'p-0.5 rounded-md transition-transform active:scale-[0.90]',
        iconSm: 'h-7 w-7 rounded-lg',
        round: 'h-7 px-1.5 rounded-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export type ButtonVariants = VariantProps<typeof buttonVariants>['variant'];

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  icon?: ReactNode;
  iconRight?: boolean;
  shake?: boolean;
  onlyIcon?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      icon,
      iconRight = false,
      loading,
      shake,
      asChild = false,
      children,
      onlyIcon = false,
      type = 'button',
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    const _icon = icon && (
      <span
        className={
          onlyIcon ? '' : cn(iconRight ? 'ml-[7px]' : 'mr-[7px]', size === 'round' && 'ml-[7px]')
        }
      >
        {icon}
      </span>
    );

    const childrenWithIcon = iconRight ? (
      <>
        {children}
        {_icon}
      </>
    ) : (
      <>
        {_icon}
        {children}
      </>
    );

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }), shake && 'shake')}
        ref={ref}
        {...props}
        type={type}
      >
        <span className={cn('inline-flex items-center justify-center', loading && 'invisible')}>
          {childrenWithIcon}
        </span>
        {loading && (
          <i className={cn('px-4', 'absolute z-10')}>
            <LoadingIcon />
          </i>
        )}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
