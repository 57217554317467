import { get } from 'lodash-es';
import styled, { css } from 'styled-components';

import Icon from 'components/common/icon/Icon';
import { SBox } from 'components/ui';

const MAX_HEIGHT = 20;
const TOP_POSITION = 4;
const BOTTOM_POSITION = 2;

export const SIconContainer = styled(SBox)<{ level: number }>`
  position: relative;
  width: 10px;
  height: ${MAX_HEIGHT}px;
  margin-right: ${(props) => props.theme.spacing(1)};

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: ${BOTTOM_POSITION}px;
    left: 0;
    width: 100%;
    background-color: ${(props) =>
      props.color ? get(props.theme.colors, props.color) : props.theme.colors.neutral};
    border-radius: 3px;

    ${(props) =>
      props.level &&
      css`
        height: ${(props.level * (MAX_HEIGHT - (BOTTOM_POSITION + TOP_POSITION))) / 100}px;
      `}
  }
`;

export const SBatteryIcon = styled(Icon)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const SChargeIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  filter: drop-shadow(0 0 1px white) drop-shadow(0 0 3px white);
`;
