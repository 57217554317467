import { PropsWithChildren, createContext, useContext, useState } from 'react';

type RobotArmLoadingContextType = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

const RobotArmLoadingContext = createContext<RobotArmLoadingContextType | null>(null);

export function RobotArmLoadingProvider({ children }: PropsWithChildren) {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <RobotArmLoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </RobotArmLoadingContext.Provider>
  );
}

export function useRobotArmLoading(): RobotArmLoadingContextType {
  const context = useContext(RobotArmLoadingContext);
  if (!context) {
    throw new Error('useRobotArmLoading must be used within a RobotArmLoadingProvider');
  }
  return context;
}
