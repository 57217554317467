import { Dispatch, SetStateAction } from 'react';

import { debugJanus } from 'utils/debugger';

import {
  JanusEventType,
  JanusPluginHandle,
  JanusStreamStatus,
  Publisher,
  PublisherId,
  Tracks,
} from '../types';

type HandleRemoteFeedCallbackProps = {
  setRemoteFeed: Dispatch<SetStateAction<JanusPluginHandle | null>>;
  setTracks: Dispatch<SetStateAction<Tracks>>;
  updatePlayerState: (id: PublisherId, status: JanusStreamStatus) => void;
};

export type RemoteFeedCallback = ReturnType<typeof createRemoteFeedCallback>;

/**
 * HOC for creating a callback for plugin handle
 * TODO: _remoteFeed will never change but updated here everytime a callback get triggered. Maybe put it into an atom?
 */
export function createRemoteFeedCallback({
  setRemoteFeed,
  setTracks,
  updatePlayerState,
}: HandleRemoteFeedCallbackProps) {
  return (
    publisher: Publisher,
    _remoteFeed: JanusPluginHandle | null,
    eventType: JanusEventType,
    data?: MediaStream,
  ) => {
    // here
    debugJanus(`remoteFeed:callback -> ${eventType}`, data);
    debugJanus(`remoteFeed::callback:publisher`, publisher);

    setRemoteFeed(_remoteFeed);
    if (eventType === JanusEventType.ON_REMOTE_STREAM) {
      setTracks((prevState) => ({
        ...prevState,
        [publisher.id]: {
          stream: data,
          publisher,
        },
      }));

      // data is currently only empty if JanusEventType.ON_CLEANUP
      // todo - no its not - we see this always when we switch to spot cam stream which will than show error
      const videoTracks = data!.getVideoTracks();
      debugJanus(`remoteFeed:videoTracks`, videoTracks);

      if (!videoTracks?.length) {
        // a callback
        updatePlayerState(String(publisher.id), JanusStreamStatus.ERROR);
      } else {
        updatePlayerState(String(publisher.id), JanusStreamStatus.LIVE);
      }
    } else if (eventType === JanusEventType.ON_CLEANUP) {
      updatePlayerState(String(publisher.id), JanusStreamStatus.PAUSED);
    } else if (eventType === JanusEventType.ERROR) {
      updatePlayerState(String(publisher.id), JanusStreamStatus.ERROR);
    }
  };
}
