import { useRecoilValue } from 'recoil';

import { manipulatorState_CarryStateToJSON } from 'lib/generated/proto/bosdyn/api/robot_state';

import { carryStateSelector, gripperHoldingItemSelector } from './selectors';

export function useCarryState() {
  const carryState = useRecoilValue(carryStateSelector);
  const _carryStateString = manipulatorState_CarryStateToJSON(carryState);
  return { carryState, _carryStateString };
}

export function useGripperHoldingItem() {
  const gripperHoldingItem = useRecoilValue(gripperHoldingItemSelector);
  return { gripperHoldingItem };
}
