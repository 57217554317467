import { isEqual } from 'lodash-es';
import { useEffect, useState } from 'react';

import { usePrevious } from 'hooks';

function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const prevValue = usePrevious(value);

  useEffect(() => {
    let timer: number;
    if (!isEqual(value, prevValue)) {
      timer = window.setTimeout(() => setDebouncedValue(value), delay || 500);
    }

    return () => {
      timer && window.clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;
