import { PayloadDTO } from './Payload';
import { RobotTemplateDTO } from './RobotAction';

/**
 * Iothub device name is the unique identifier of the robot in the Azure IoT Hub.
 */
export type DeviceName = string;

export enum SystemType {
  LINUX = 'Linux',
  WINDOWS = 'Windows',
}

export const ROBOT_MODEL = {
  SPOT: 'spot',
  MAGNI: 'magni',
  TURTLEBOT: 'turtlebot',
} as const;

export type ROBOT_MODEL = (typeof ROBOT_MODEL)[keyof typeof ROBOT_MODEL];

export enum MyRobotConfigurationStatusEnum {
  NOT_INITIALIZED_YET = 0, //Robot has been added (db, azure iot, ...) but not initalized yet (e.g.: a script has been not created yet or the script has been not run on the robot yet)
  READY = 1, //Robot has been added and initialized (script installed on robot) and is able to run (there is no reason why it should not work)
  DISABLED = 2, //Disabled from different reasons
  DAMAGED = 3, //Damaged
}

export type MyRobotsPrimaryInformationResponse = {
  myRobotId: number;
  iotHubDeviceName: string;
  modelName: string; // enum on backend so it's safe to use it as type of the robot
  thumbnailURL: string;
  configurationStatus: MyRobotConfigurationStatusEnum;
  robotInfo: RobotInfo;
};

/**
 * Extended version for the frontend having the image URL
 */
export type MyRobotsPrimaryInformationResponseDTO = MyRobotsPrimaryInformationResponse & {
  imageUrl: string;
  modelName: ROBOT_MODEL; // it was validated at this point
};

// Robot primary information data structure
export type RobotInfo = {
  robotStatus: RobotStatus;
  connectionState: ConnectionState;
};

export type ConnectionState = {
  state: string; // Connected | Disconnected
  lastUpdatedTime: string;
};

export type RobotStatus = {
  operatingStatus: OperatingStatus;
  isEmergencyStop: boolean;
  isMapRecording: boolean;
  healthStatus: HealthStatus;
  batteryStatus: BatteryStatus;
  networkStatus: NetworkStatus;
};

export type HealthStatus = {
  status: string; // OK | ERROR | HW_ESTOP
  message: string;
};

export type BatteryStatus = {
  chargePercentage: number;
  status: 'CHARGING' | 'DISCHARGING' | 'FULL';
  remainingRuntime: number; // in seconds, 0 if charging or unknown
};

export type NetworkStatus = {
  latency: string;
  arePortsOpen: string;
  downloadSPeed: string;
  lastUpdateTime: string;
  fullStateBlobKey: string;
};

export enum OperatingStatus {
  IDLE = 'IDLE', // connected and ready to do something
  TELEOPERATION = 'TELEOPERATION', // someone is actively controlling this robot through teleoperation
  ON_MISSION = 'ON_MISSION', // the robot is carrying out an autonomous routine
  EXTERNAL_USE = 'EXTERNAL_USE', // the robot is carrying out an autonomous routine
}

type MyRobotToPayloadDTO = {
  myRobotToPayloadId: number;
  isActive: boolean;
  devicePath: string;
  myRobotId: number;
  // todo - that will cause a loop and currently now sure if payload wil be send here anyway
  // MyRobot: MyRobotDTO;
  payloadId: number;
  payload: PayloadDTO;
};

export type MyRobotDTO = {
  myRobotId: number;
  configurationStatus: MyRobotConfigurationStatusEnum;
  systemType: SystemType;
  serialNumber: string;
  defaultLiveStreamChannel: string;
  iotHubDeviceName: string;
  companyDomain: string;
  robotTemplateId: number;
  robotTemplate: RobotTemplateDTO;
  myRobotToPayloads: MyRobotToPayloadDTO[];
};
