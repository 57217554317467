export enum DefaultCameraSource {
  OFF = 'Off',
  // keep in mind, this is hardcoded data and in the future it should be fetched from the server
  SpotCamStream = 'SpotCamStream',
  GripperCam = 'Gripper',
}

// old - but used as a reduced version of StreamSource
export type CameraSource = {
  value: string;
  label: string;
};

/**
 * These are not the communicated types from marco - they are hardcoded where the
 */
export enum StreamSourceType {
  SpotImageSource = 'SpotImageSource',
  SpotCAM = 'SpotCAM',
  ROS = 'ROS',
}

export type StreamSource = {
  /**
   * Always true because cloud filters for this
   */
  available: boolean;
  name: string;
  type: string;
  nativeWidth: number;
  nativeHeight: number;
  spotServiceName: StreamSourceType;
};

export type StreamSourceName = StreamSource['name'];
