import { useQuery, useQueryClient } from '@tanstack/react-query';
import { spot_localization_handler_interfaces } from 'rclnodejs';

import { RobotRosTopic } from '../types';

export type FiducialList = spot_localization_handler_interfaces.msg.FiducialList;
export type Fiducials = spot_localization_handler_interfaces.msg.FiducialList['fiducials'];

const queryKey = ['relayTopics', RobotRosTopic.FIDUCIALS];

/**
 * Todo Test if maps get filtered again when this changes - (should change while robot is moving)
 * Todo - check if re renders happens every state update or only when data really changes
 */
export function useFiducials() {
  const { data } = useQuery<Fiducials>({
    queryKey,
  });

  const fiducials = data ?? [];

  return { fiducials };
}

export function useFiducialsSetter() {
  const queryClient = useQueryClient();

  function updateFiducials(data: unknown) {
    const { fiducials } = data as FiducialList;
    queryClient.setQueryData(queryKey, fiducials);
  }

  return { updateFiducials };
}
