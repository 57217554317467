import { atom } from 'recoil';

import { GamepadMode } from 'types/models/gamepad/GamepadMapper';

/**
 * Atom for the current gamepad https://developer.mozilla.org/en-US/docs/Web/API/Gamepad
 */
export const gamepadAtom = atom<Gamepad | undefined>({
  key: 'gamepadAtom',
  default: undefined,
});

/**
 * Atom for the current gamepad mode
 */
export const gamepadModeAtom = atom<GamepadMode>({
  key: 'gamepadModeAtom',
  default: GamepadMode.MOVEMENT,
});

/**
 * Atom for the gamepad instruction opened state
 */
export const gamepadInstructionOpenedAtom = atom<boolean>({
  key: 'gamepadInstructionOpenedAtom',
  default: false,
});
