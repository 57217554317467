import { selector } from 'recoil';

import { axiosRequest } from 'lib/api/axios';
import { getMyRobotInfoConfig, getMyRobotToPayloadsForImagesConfig } from 'lib/api/urls';

import { RobotInfo } from 'types/models';
import { PayloadDTO, PayloadItem } from 'types/models/Payload';
import { SpotFault } from 'types/models/Spot';

import { deviceNameSelector, myRobotIdSelector } from '../myRobot.selectors';
import { spotControlDataAtom } from './manualControl.atoms';

export const spotFaultsSelector = selector<SpotFault[]>({
  key: 'spotFaultsSelector',
  get: ({ get }) => {
    const spotControlData = get(spotControlDataAtom);
    return spotControlData?.faults ?? [];
  },
});

const myRobotToPayloadsForImagesQuery = selector({
  key: 'myRobotToPayloadsForImagesQuery',
  get: async ({ get }) => {
    const myRobotId = get(myRobotIdSelector);
    const { method, url } = getMyRobotToPayloadsForImagesConfig(myRobotId);
    const { data } = await axiosRequest<PayloadItem[]>(method, url);
    return data;
  },
});

export const isPTZAvailableSelector = selector({
  key: 'isPtzAvailableSelector',
  get: async ({ get }) => {
    const myRobotToPayloadsForImages = get(myRobotToPayloadsForImagesQuery);
    return myRobotToPayloadsForImages.some(
      (v: { payload: PayloadDTO }) => v.payload.azureIoTPayloadModuleType === 0,
    );
  },
});
export const myRobotInfoQuery = selector<RobotInfo>({
  key: 'myRobotInfoQuery',
  get: async ({ get }) => {
    const deviceName = get(deviceNameSelector);
    const { method, url } = getMyRobotInfoConfig(deviceName);
    const { data } = await axiosRequest<RobotInfo>(method, url);
    return data;
  },
});
export const myRobotIsConnectedSelector = selector({
  key: 'myRobotIsConnectedSelector',
  get: ({ get }) => {
    const myRobotInfo = get(myRobotInfoQuery);
    return myRobotInfo?.connectionState.state === 'Connected';
  },
});
