import { selector } from 'recoil';

import { deviceNameSelector } from 'store/my-robot';

import { IRosTypeSensorMsgsBatteryStatePowerSupplyStatus } from 'lib/generated/ros2';

import { batteryStatusAtom } from './battery-status.atom';

const batteryStatusSelector = selector({
  key: 'batteryStatusSelector',
  get: ({ get }) => {
    const deviceName = get(deviceNameSelector);
    const state = get(batteryStatusAtom(deviceName));
    // not defined before received onse
    if (!state) return undefined;
    return state.battery_state;
  },
});

export const receivedNewBatteryStatusSelector = selector({
  key: 'receivedNewBatteryStatusSelector',
  get: ({ get }) => {
    const batteryStatus = get(batteryStatusSelector);
    return !!batteryStatus;
  },
});

export const batteryStatusChargingSelector = selector({
  key: 'batteryStatusChargingSelector',
  get: ({ get }) => {
    const batteryStatus = get(batteryStatusSelector);
    return (
      batteryStatus?.power_supply_status ===
      IRosTypeSensorMsgsBatteryStatePowerSupplyStatus.CHARGING
    );
  },
});

export const batteryStatusPercentageSelector = selector({
  key: 'batteryStatusPercentageSelector',
  get: ({ get }) => {
    const batteryStatus = get(batteryStatusSelector);
    return (batteryStatus?.percentage ?? -0.01) * 100;
  },
});
