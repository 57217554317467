import { HUB_URL, InvokeMethod, ListenMethod } from './const';
import { useSignalRHub } from './context/useSignalRHub';

export enum SpotcontrolHubEvents {
  SEND_SPOT_CONTROL_DATA = 'SendSpotControlData', // invoke
  RECEIVE_SPOT_CONTROL_REPORTED_DATA = 'ReceiveSpotControlReportedData', // listen
}

/**
 * Hook to use for the Spotcontrol hub
 */
export function useSpotcontrolHub({
  listenMethods,
  invokeMethods,
}: {
  invokeMethods?: Partial<Record<SpotcontrolHubEvents, InvokeMethod>>;
  listenMethods?: Partial<Record<SpotcontrolHubEvents, ListenMethod>>;
}) {
  return useSignalRHub(HUB_URL.SPOTCONTROL, invokeMethods, listenMethods);
}
