import { useRecoilValue } from 'recoil';

import { userRolesAtom } from 'store/user/user.atoms';

import RoleTypes from 'lib/constants/role-types';

function useRole() {
  const userRoles = useRecoilValue(userRolesAtom);

  const checkRole = (role: RoleTypes) => userRoles.includes(role);

  return { checkRole };
}

export default useRole;
