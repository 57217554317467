import styled from 'styled-components';

import { STypography } from 'components/ui';

export const SLabel = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding-left: ${(props) => props.theme.spacing(1)};
  padding-right: ${(props) => props.theme.spacing(1)};

  ${STypography} {
    user-select: none;
    padding-left: ${(props) => props.theme.spacing(1)};
  }
`;

export const SCheckboxIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const SHiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const SCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: ${(props) =>
    props.checked ? props.theme.colors.primary.light : props.theme.colors.neutral};
  border-radius: 3px;
  transition: all 150ms;

  ${SHiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 2px ${(props) => props.theme.colors.primary.dark};
  }

  ${SCheckboxIcon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;
