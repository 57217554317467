import { useRecoilValue, useSetRecoilState } from 'recoil';

import { gamepadModeAtom } from './gamepad.atom';

// helper hook to update the gamepad mode without subscribing to state change
function useUpdateGamepadMode() {
  return useSetRecoilState(gamepadModeAtom);
}

export function useGamepadMode() {
  const gamepadMode = useRecoilValue(gamepadModeAtom);
  const updateGamepadMode = useUpdateGamepadMode();
  return { gamepadMode, updateGamepadMode };
}
