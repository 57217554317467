import { DeviceName } from 'types/models';
import { ArmGripperVelocity } from 'types/models/arm-control/ArmControl';
import { TeleoperationPayloadDataDTO } from 'types/models/ptz-camera/PtzCamera';

import { HUB_URL, InvokeMethod, ListenMethod } from './const';
import { useSignalRHub } from './context/useSignalRHub';

export enum TeleoperationHubEvents {
  AUTH = 'Authenticate', // invoke
  SEND_TELEOPERATION_MOVEMENT_DATA = 'SendTeleoperationMovementData', // invoke
  SEND_EMERGENCY_STOP_DATA = 'SendEmergencyStopData', // invoke
  // Ptz
  SEND_TELEOPERATION_PTZ_CAMERA_DATA = 'SendTeleoperationPtzCameraData', // invoke
  RECEIVE_TELEOPERATION_PTZ_CAMERA_REPORTED_DATA = 'ReceiveTeleoperationPtzCameraReportedData', // listen
  // Controls
  RECEIVE_CONTROL_PERMISSION_REQUEST = 'ReceiveControlPermissionRequest', // listen
  RECEIVE_CONTROL_PERMISSION_RESPONSE = 'ReceiveControlPermissionResponse', // listen
  RECEIVE_NEWLY_SET_MAIN_OPERATOR = 'ReceiveNewlySetMainOperator', // listen
  RECEIVE_NEWLY_SET_VIDEO_STREAM_NAME = 'ReceiveNewlySetVideoStreamName', // listen
  SEND_ARM_GRIPPER_VELOCITY = 'SendArmGripperVelocity', // invoke
  RECEIVE_ARM_GRIPPER_VELOCITY = 'ReceiveArmGripperVelocity', // listen
}

type Rotation = {
  rX: number;
  sY: number;
  tZ: number;
};

type Translation = {
  x: number;
  y: number;
  z: number;
};

export type Movement = {
  velocityScaling: number;
  rotation: Rotation;
  translation: Translation;
};

export type TeleoperationMovementDTO = {
  deviceName: DeviceName;
  movement: Movement;
};

type EmergencyStopDTO = {
  deviceName: DeviceName;
  emergencyStop: boolean;
};

export type TeleoperationArmVelocityDTO = {
  deviceName: DeviceName;
  armGripperVelocity: ArmGripperVelocity;
};

/**
 * Hook to use for the Teleportation hub
 */
export function useTeleoperationHub({
  listenMethods,
  invokeMethods,
}: {
  invokeMethods?: Partial<Record<TeleoperationHubEvents, InvokeMethod>>;
  listenMethods?: Partial<Record<TeleoperationHubEvents, ListenMethod>>;
}) {
  const hub = useSignalRHub(HUB_URL.TELEOPERATION, invokeMethods, listenMethods);

  async function sendEmergencyStopData(data: EmergencyStopDTO) {
    return hub.invoke(TeleoperationHubEvents.SEND_EMERGENCY_STOP_DATA, data);
  }

  async function sendArmGripperVelocity(data: TeleoperationArmVelocityDTO) {
    return hub.invoke(TeleoperationHubEvents.SEND_ARM_GRIPPER_VELOCITY, data);
  }

  async function sendTeleoperationPtzCameraData(data: TeleoperationPayloadDataDTO) {
    return hub.invoke(TeleoperationHubEvents.SEND_TELEOPERATION_PTZ_CAMERA_DATA, data);
  }

  async function sendTeleoperationMovementData(data: TeleoperationMovementDTO) {
    return hub.invoke(TeleoperationHubEvents.SEND_TELEOPERATION_MOVEMENT_DATA, data);
  }

  const TeleoperationHubClient = {
    sendEmergencyStopData,
    sendArmGripperVelocity,
    sendTeleoperationPtzCameraData,
    sendTeleoperationMovementData,
  };

  return { ...hub, TeleoperationHubClient };
}
