import { FC, lazy } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';

import { withAsyncState } from 'components/common/HOC/with-async-state/withAsyncState';
import { LayoutHome, LayoutRobotManagement } from 'components/layouts';

import { ROBOT_MANAGEMENT_ROUTES, ROOT_ROUTES } from './routes-names';
import TestPage from './test';
import TestGamepadPage from './test/gamepad';

const RecoilTestsPage = lazy(() => import('./test/recoil'));

type RouteType = {
  path: string;
  title: string;
  children?: (RouteType & RouteObject)[];
  disabled?: boolean;
} & RouteObject;

export const parseRoutes = (routes: RouteType[]): RouteType[] => {
  const result: RouteType[] = [];

  for (const route of routes) {
    if (!route.disabled) {
      if (route.children) {
        const children = parseRoutes(route.children);
        if (children.length > 0) {
          result.push({
            ...route,
            children,
          });
        }
      } else {
        result.push(route);
      }
    }
  }

  return result;
};

const Routes: FC = () =>
  useRoutes([
    {
      path: '/*',
      element: <LayoutHome />,
      children: Object.values(ROOT_ROUTES),
    },
    {
      path: '/:id/*',
      element: <LayoutRobotManagement />,
      children: parseRoutes(Object.values(ROBOT_MANAGEMENT_ROUTES) as RouteType[]),
    },
    // Test route
    {
      path: '/test',
      element: <TestPage />,
    },
    {
      path: '/test/gamepad',
      element: <TestGamepadPage />,
    },
    {
      path: '/test/recoil',
      element: <RecoilTestsPage />,
    },
  ]);

const AppRoutes: FC = () => <Routes />;

export default withAsyncState(AppRoutes);
