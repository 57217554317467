import { useEffect, useState } from 'react';

const useTabActive = (): boolean => {
  const [isActive, setIsActive] = useState<boolean>(true);

  useEffect(() => {
    window.onblur = function () {
      setIsActive(false);
    };
    window.onfocus = function () {
      setIsActive(true);
    };
  }, []);

  return isActive;
};

export default useTabActive;
