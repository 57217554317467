import Janus from './janus';
import { JanusJS } from './janus/janus';

/**
 * Currently the JanusJS is not in the correct version to our "janus.js" - but bedder than nothing
 */
export type JanusPluginOptions = JanusJS.PluginOptions;
export type JanusPluginHandle = JanusJS.PluginHandle;
export type JanusInitOptions = JanusJS.InitOptions;
export type JanusConstructorOptions = JanusJS.ConstructorOptions;
export type JanusPluginMessage = JanusJS.PluginMessage;
export type JanusJSEP = JanusJS.JSEP;

export type JanusType = Janus;

export type JanusCallbacks = {};

export type JanusVideoCamPluginHandler = {
  id: number;
  plugin: JanusType;
  consentDialog: (on: unknown) => void;
  createAnswers: (callbacks: JanusCallbacks) => void;
  createOffer: (callbacks: JanusCallbacks) => void;
  data: (callbacks: JanusCallbacks) => unknown;
  detach: (callbacks: JanusCallbacks) => void;
  detached: boolean;
  dtmf: (callbacks: JanusCallbacks) => void;
  getBitrate: () => unknown;
  getId: () => number;
  getLocalVolume: () => unknown;
  getRemoteVolume: () => unknown;
  getVolume: () => unknown;
  getPlugin: () => unknown;
  handleRemoteJsep: (callbacks: JanusCallbacks) => void;
  hangup: (callbacks: JanusCallbacks) => void;
  iceState: () => unknown;
  isAudioMuted: () => unknown;
  isVideoMuted: () => unknown;
  mediaState: (medium: unknown, on: unknown) => unknown;
  muteAudio: () => void;
  muteVideo: () => void;
  oncleanup: () => void;
  ondata: () => void;
  ondataopen: () => void;
  ondetached: () => void;
  onlocalstream: () => void;
  onmessage: () => void;
  onremotestream: () => void;
  send: (callbacks: JanusCallbacks) => void;
  session: JanusType;
  slowLink: () => unknown;
  token: string | null;
  unmuteAudio: () => void;
  unmuteVideo: () => void;
  webrtcState: (on: unknown) => unknown;
};

// this comes from janus
export type PublisherId = string;

// this are our stream names
export type SelectedStreamPublisherId = PublisherId | undefined;

// this reflects the state of the stream - not janus
export enum JanusStreamStatus {
  READY = 'Ready', // Ready is like nothing else ´
  LIVE = 'Live', // what is the difference between live and ready?
  PAUSED = 'Paused',
  ERROR = 'Error',
}

export enum JanusEventType {
  JOINED = 'joined',
  PUBLISHERS = 'publishers',
  LEAVING = 'leaving',
  UNPUBLISHED = 'unpublished',
  ON_REMOTE_STREAM = 'onremotestream',
  ON_CLEANUP = 'oncleanup',
  ERROR = 'error',
}

/**
 * This keep track of the state of the player related to each publisher
 * Note: This is based on our own business logic and not Janus.
 * In the beginning this is an empty object
 */
export type PlayerStates = {
  [key: PublisherId]: JanusStreamStatus;
};

type Track = {
  stream: MediaStream;
  publisher: Publisher;
};

/**
 * This is a collection all the tracks for each publisher
 */
export type Tracks = {
  [key: PublisherId]: Track;
};

enum StreamType {
  AUDIO = 'audio',
  VIDEO = 'video',
  DATA = 'data',
}

type Stream = {
  type: StreamType;
  codec: string;
  mid: string;
  mindex: number;
};

// Also VideoRoomPublisher but there video_codec/audio_codec are missing
// todo - Publisher type is not fully correct
export type Publisher = {
  audio_codec: string;
  display: string; // currently like "robot@rbv_coreIO_1@PrimaryStream" but also 2222 or what is on the test server - we match with our streamName
  id: number;
  streams: Stream[];
  talking: boolean;
  video_codec: string;
};

export type VideoRoomPublisher = {
  id: number;
  display: string; // currently like "robot@rbv_coreIO_1@PrimaryStream" but also 2222
  talking: boolean;
  streams: Stream[];
};

type VideoRoomData = {
  room?: number;
  videoroom?: string;
  error?: string;
  error_code?: number;
};

type Attendee = {
  id: number;
  display: string;
};

export type VideoRoomPublishersData = VideoRoomData & {
  attendees: Attendee[];
  description: string;
  id: number;
  private_id: number;
  publishers: Publisher[];
};

export type VideoRoomUnpublishData = VideoRoomData & {
  unpublished: number;
};
