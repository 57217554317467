import { HUB_URL, InvokeMethod, ListenMethod } from './const';
import { useSignalRHub } from './context/useSignalRHub';

export const IothubEvent = {
  RECEIVE_ROBOT_MAIN_MODULE_REPORTED_DATA: 'ReceiveRobotMainModuleReportedData',
  RECEIVE_TOPIC_RELAY: 'ReceiveTopicRelay',
} as const;

export type IothubEvent = (typeof IothubEvent)[keyof typeof IothubEvent];

/**
 * Hook to use for the Iothub hub
 *
 */
export function useIothubHub({
  invokeMethods,
  listenMethods,
}: {
  invokeMethods?: Partial<Record<IothubEvent, InvokeMethod>>;
  listenMethods?: Partial<Record<IothubEvent, ListenMethod>>;
}) {
  return useSignalRHub(HUB_URL.IOTHUB, invokeMethods, listenMethods);
}
