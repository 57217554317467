import { useRecoilState, useSetRecoilState } from 'recoil';

import { useTeleoperationHub } from 'hooks/signalR';

import { useDeviceName } from '../my-robot';
import { robotEmergencyStopSelector } from './robot-control.atom';

/**
 * Note: This is not refreshing when state change to use where state is not necessary and reduce rendering
 */
export function useSendEmergencyStop() {
  const setIsEmergencyStopState = useSetRecoilState(robotEmergencyStopSelector);
  const { TeleoperationHubClient } = useTeleoperationHub({});
  const { deviceName } = useDeviceName();

  async function sendEmergencyStop() {
    setIsEmergencyStopState((oldState) => {
      const newState = !oldState;
      TeleoperationHubClient.sendEmergencyStopData({
        deviceName,
        emergencyStop: newState,
      });
      return newState;
    });
  }

  return { setIsEmergencyStopState, sendEmergencyStop };
}

export function useEmergencyStop() {
  const [isEmergencyStop, setIsEmergencyStopState] = useRecoilState(robotEmergencyStopSelector);
  const { sendEmergencyStop } = useSendEmergencyStop();

  return { isEmergencyStop, setIsEmergencyStopState, sendEmergencyStop };
}
