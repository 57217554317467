import { ROBOT_MANAGEMENT_ROUTES, ROOT_ROUTES } from 'pages/routes-names';

import RoleTypes from 'lib/constants/role-types';

type NavigationListItem = {
  icon: string;
  path?: string;
  title?: string;
  role?: RoleTypes;
  disabled?: boolean;
};

const navigationRootList: NavigationListItem[] = [
  {
    path: ROOT_ROUTES.HOME.path,
    title: ROOT_ROUTES.HOME.title,
    icon: 'home',
  },
  {
    path: ROOT_ROUTES.ACQUIRED_DATA.path,
    title: ROOT_ROUTES.ACQUIRED_DATA.title,
    icon: 'database',
  },
  {
    path: ROOT_ROUTES.NAVIGATION_MAPS.path,
    title: ROOT_ROUTES.NAVIGATION_MAPS.title,
    icon: 'map',
  },
  {
    path: ROOT_ROUTES.MAPS_3D.path,
    title: ROOT_ROUTES.MAPS_3D.title,
    icon: 'map_3d',
  },
  {
    path: ROOT_ROUTES.RADIATION.path,
    title: ROOT_ROUTES.RADIATION.title,
    icon: 'radiation',
  },
  {
    path: ROOT_ROUTES.HISTORY_MISSIONS.path,
    title: ROOT_ROUTES.HISTORY_MISSIONS.title,
    icon: 'history_mission',
  },
].filter((route) => {
  const findRoute = Object.values(ROOT_ROUTES).find((r) => r.path === route.path);
  return !findRoute?.disabled;
});

const navigationRobotList: NavigationListItem[] = [
  {
    path: ROBOT_MANAGEMENT_ROUTES.OVERVIEW.path,
    title: ROBOT_MANAGEMENT_ROUTES.OVERVIEW.title,
    icon: 'overview',
  },
  {
    path: ROBOT_MANAGEMENT_ROUTES.MANUAL_CONTROL.path,
    title: ROBOT_MANAGEMENT_ROUTES.MANUAL_CONTROL.title,
    icon: 'manual_controller',
    role: RoleTypes.TELEOP_VIEWER,
  },
  {
    path: ROBOT_MANAGEMENT_ROUTES.AUTOWALK.path,
    title: ROBOT_MANAGEMENT_ROUTES.AUTOWALK.title,
    icon: 'route',
  },
  {
    path: ROBOT_MANAGEMENT_ROUTES.SCHEDULE_MISSIONS.path,
    title: ROBOT_MANAGEMENT_ROUTES.SCHEDULE_MISSIONS.title,
    icon: 'pending_actions',
  },
  {
    path: ROBOT_MANAGEMENT_ROUTES.CAR_SCANS.path,
    title: ROBOT_MANAGEMENT_ROUTES.CAR_SCANS.title,
    icon: 'car',
  },
  {
    path: ROBOT_MANAGEMENT_ROUTES.PAYLOADS.path,
    title: ROBOT_MANAGEMENT_ROUTES.PAYLOADS.title,
    icon: 'payload',
  },
].filter((route) => {
  const findRoute = Object.values(ROBOT_MANAGEMENT_ROUTES).find((r) => r.path === route.path);
  return !findRoute?.disabled;
});

export { navigationRootList, navigationRobotList };
