import { XboxOneGamepadButton } from 'types/models/gamepad/GamepadButtons';
import { GamepadMapper, GamepadMapperPreprocessed } from 'types/models/gamepad/GamepadMapper';

import { consoleLogKeyPressed } from '../handlers';
import { mapFromPreprocessedToMapper } from './preprocessedToMapper';

export const DEFAULT_MAPPER: GamepadMapperPreprocessed = {
  keys: [],
  combos: [
    {
      keys: [XboxOneGamepadButton.LB, XboxOneGamepadButton.RB],
      action: consoleLogKeyPressed('Combo: LB + RB (L1 + R1)'),
    },
  ],
  axes: [],
};

export const STANDING_PREPROCESSED_MAPPER: GamepadMapperPreprocessed = {
  keys: [],
  combos: [],
  axes: [],
};

export const ARM_CONTROL_PREPROCESSED_MAPPER: GamepadMapperPreprocessed = {
  keys: [],
  combos: [],
  axes: [],
};

export const MOVEMENT_PREPROCESSED_MAPPER: GamepadMapperPreprocessed = {
  keys: [
    {
      key: XboxOneGamepadButton.A,
      action: consoleLogKeyPressed('A (X)'),
    },
    {
      key: XboxOneGamepadButton.B,
      action: consoleLogKeyPressed('B (Circle)'),
    },
    {
      key: XboxOneGamepadButton.X,
      action: consoleLogKeyPressed('X (Square)'),
    },
    {
      key: XboxOneGamepadButton.Y,
      action: consoleLogKeyPressed('Y (Triangle)'),
    },
    {
      key: XboxOneGamepadButton.LB,
      action: consoleLogKeyPressed('LB (L1)'),
    },
    {
      key: XboxOneGamepadButton.RB,
      action: consoleLogKeyPressed('RB (R1)'),
    },
    {
      key: XboxOneGamepadButton.LT,
      action: consoleLogKeyPressed('LT (L2)'),
    },
    {
      key: XboxOneGamepadButton.RT,
      action: consoleLogKeyPressed('RT (R2)'),
    },
    {
      key: XboxOneGamepadButton.Back,
      action: consoleLogKeyPressed('Back (Share)'),
    },
    {
      key: XboxOneGamepadButton.Start,
      action: consoleLogKeyPressed('Start (Options)'),
    },
    {
      key: XboxOneGamepadButton.LSB,
      action: consoleLogKeyPressed('LSB (LS)'),
    },
    {
      key: XboxOneGamepadButton.RSB,
      action: consoleLogKeyPressed('RSB (RS)'),
    },
    {
      key: XboxOneGamepadButton.D_PAD_UP,
      action: consoleLogKeyPressed('UP (D-Pad)'),
    },
    {
      key: XboxOneGamepadButton.D_PAD_DOWN,
      action: consoleLogKeyPressed('DOWN (D-Pad)'),
    },
    {
      key: XboxOneGamepadButton.D_PAD_RIGHT,
      action: consoleLogKeyPressed('RIGHT (D-Pad)'),
    },
    {
      key: XboxOneGamepadButton.D_PAD_LEFT,
      action: consoleLogKeyPressed('LEFT (D-Pad)'),
    },
  ],
  combos: [
    {
      keys: [XboxOneGamepadButton.LT, XboxOneGamepadButton.RT],
      action: consoleLogKeyPressed('Combo: LT + RT (L2 + R2)'),
    },
    {
      keys: [XboxOneGamepadButton.LT, XboxOneGamepadButton.RT, XboxOneGamepadButton.A],
      action: consoleLogKeyPressed('Combo: LT + RT + A (L2 + R2 + X)'),
    },
  ],
  axes: [],
};
export const MOVEMENT_MAPPER: GamepadMapper = mapFromPreprocessedToMapper(
  MOVEMENT_PREPROCESSED_MAPPER,
);

export const PAYLOAD_PTZ_PREPROCESSED_MAPPER: GamepadMapperPreprocessed = {
  keys: [],
  combos: [],
  axes: [],
};
