import { debounce } from 'lodash-es';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import useDidUpdate from './useDidUpdate';
import usePrevious from './usePrevious';

interface ResizeObserverOptions {
  withHeight?: boolean;
}

type ResizeObserverCallback = (width: number | undefined, height: number | undefined) => void;

const useResizeObserver = (
  elementRef: RefObject<HTMLElement | null>,
  callback: ResizeObserverCallback,
  debounceWait = 300,
  options?: ResizeObserverOptions,
) => {
  const { withHeight } = options || {};

  const [elementWidth, setElementWidth] = useState<number | undefined>(undefined);
  const [elementHeight, setElementHeight] = useState<number | undefined>(undefined);
  const elementPreviousWidth = usePrevious<number | undefined>(elementWidth);
  const elementPreviousHeight = usePrevious<number | undefined>(elementWidth);

  const debouncedUpdate = useCallback(
    debounce((width: number, height: number | undefined) => {
      setElementWidth(width);
      withHeight && setElementHeight(height);
    }, debounceWait),
    [],
  );

  const observer = useRef<ResizeObserver>(
    new ResizeObserver((entries) => {
      // Only care about the first element, we expect one element to be watched
      const { width, height } = entries[0].contentRect;
      debouncedUpdate(width, height);
    }),
  );

  useDidUpdate(() => {
    if (elementPreviousWidth !== undefined) {
      callback && callback(elementWidth, elementHeight);
    }
  }, [elementWidth]);

  useDidUpdate(() => {
    if (withHeight && elementPreviousHeight !== undefined) {
      callback && callback(elementWidth, elementHeight);
    }
  }, [elementHeight]);

  useEffect(() => {
    if (elementRef.current) {
      observer.current.observe(elementRef.current);
    }

    return () => {
      observer.current.disconnect();
    };
  }, [elementRef, observer]);
};

export default useResizeObserver;
