import { LogLevel } from '@microsoft/signalr';

import { LOCAL_STORAGE } from 'lib/constants/local-storage';

/**
 * Use this outside of react where hooks are not possible. Dependencies need a refresh after change
 * @type {boolean}
 */
export const DEBUG: boolean = localStorage.getItem(LOCAL_STORAGE.DEBUG.ALL) === 'true';

export const SIGNAL_R_LOG_LEVEL: LogLevel = (function () {
  const logLevelString = localStorage.getItem(LOCAL_STORAGE.DEBUG.SIGNAL_R_LOG_LEVEL);
  if (!logLevelString) return LogLevel.Error;
  const logLevel = LogLevel[logLevelString as keyof typeof LogLevel];
  if (logLevel) return logLevel;
  return LogLevel.Error;
})();
