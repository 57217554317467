import { AppConfigurationClient } from '@azure/app-configuration';
import { AdalConfig, AuthenticationContext, adalGetToken } from 'react-adal';

import { ROBOTICSPLATFORM_CORE_APP_SETTINGS_CS } from './constants/env-variables';

let authContext: AuthenticationContext;

const getAzureAppSettings = async () => {
  const azureAppConfigurationConnectionString = ROBOTICSPLATFORM_CORE_APP_SETTINGS_CS ?? '';
  return new AppConfigurationClient(azureAppConfigurationConnectionString);
};

const getAzureADClientId = async (appSettings: AppConfigurationClient) => {
  const retrievedSetting = await appSettings.getConfigurationSetting({
    key: 'AAD_ClientID',
  });

  return retrievedSetting.value ?? '';
};

const getAzureADTenantId = async (appSettings: AppConfigurationClient) => {
  const retrievedSetting = await appSettings.getConfigurationSetting({
    key: 'AAD_TenantId',
  });

  return retrievedSetting.value;
};

const adalConfig: AdalConfig = {
  redirectUri: `${window.location.origin}/signin-oidc`,
  cacheLocation: 'sessionStorage',
  clientId: '',
};

export const getAuthContext = async () => {
  if (!authContext) {
    const appSettings = await getAzureAppSettings();
    adalConfig.clientId = await getAzureADClientId(appSettings);
    adalConfig.tenant = await getAzureADTenantId(appSettings);
    authContext = new AuthenticationContext(adalConfig);
  }

  return authContext;
};

export const getToken = () => authContext.getCachedToken(adalConfig.clientId) ?? '';

export const updateToken = () => {
  return adalGetToken(authContext, adalConfig.clientId)
    .then((newToken) => {
      return newToken;
    })
    .catch((errMessage) => {
      throw errMessage?.message;
    });
};
