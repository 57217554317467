import {
  GamepadStickValues,
  XboxOneGamepadButton,
  XboxOneGamepadStick,
  XboxOneGamepadStickAdditionalOption,
} from './GamepadButtons';

export type GamepadAxisKey = XboxOneGamepadStick | XboxOneGamepadStickAdditionalOption;
export type GamepadKey = XboxOneGamepadButton | GamepadAxisKey;
export type GamepadAction = VoidFunction;
export type GamepadAxisAction = (axis1?: GamepadStickValues, axis2?: GamepadStickValues) => void;

export type GamepadMapperKeysPreprocessed = {
  key: GamepadKey;
  action: GamepadAction;
};

export type GamepadMapperComboPreprocessed = {
  keys: GamepadKey[];
  action: GamepadAction;
};

export type GamepadMapperAxisPreprocessed = {
  axis: GamepadAxisKey;
  action: GamepadAxisAction;
};

export type GamepadKeysPreProcessed = GamepadMapperKeysPreprocessed[];
export type GamepadCombosPreProcessed = GamepadMapperComboPreprocessed[];
export type GamepadAxisPreProcessed = GamepadMapperAxisPreprocessed[];

export type GamepadMapperPreprocessed = {
  keys: GamepadKeysPreProcessed;
  combos: GamepadCombosPreProcessed;
  axes: GamepadAxisPreProcessed;
};

export type GamepadMapperActions = Record<string, GamepadAction | GamepadAxisAction>;

export type GamepadMapper = {
  keys: GamepadKey[];
  combos: GamepadKey[][];
  actions: GamepadMapperActions;
};

export enum GamepadMode {
  MOVEMENT = 'movement',
  PAYLOAD_PTZ = 'payload_ptz',
  STANDING = 'standing',
  ARM_CONTROL = 'arm_control',
}

export const GAMEPAD_NAME_BY_MODE = {
  [GamepadMode.MOVEMENT]: 'Movement Mode',
  [GamepadMode.PAYLOAD_PTZ]: 'Payload PTZ Mode',
  [GamepadMode.STANDING]: 'Standing Mode',
  [GamepadMode.ARM_CONTROL]: 'Arm Control Mode',
};
