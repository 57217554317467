import { ReactElement, memo } from 'react';

import { Tooltip } from 'components/common';

import { UNAUTHORIZED_MESSAGE } from 'lib/constants/role-types';

type TooltipUnauthorizedMessage = {
  hasPermission: boolean;
  children: ReactElement<any>;
};

const TooltipUnauthorizedMessage = memo<TooltipUnauthorizedMessage>(
  ({ hasPermission, children }) => (
    // @ts-ignore
    <Tooltip text={UNAUTHORIZED_MESSAGE} size="xxxs" disabled={hasPermission}>
      {children}
    </Tooltip>
  ),
);

export default TooltipUnauthorizedMessage;
