import { useRecoilCallback, useRecoilState } from 'recoil';

import { HUB_URL } from 'hooks/signalR';

import { locColor } from 'utils/debugger';

import {
  debugSignalRHubInitSelector,
  debugSignalRHubInvokeSelector,
  debugSignalRHubReceiveSelector,
} from './logger.selectors';
import { LogSignalRMessageValue } from './types';

export function useLogSignalRInit(hub: HUB_URL) {
  const [{ color: colorInit, log: logInit }, setLogInit] = useRecoilState(
    debugSignalRHubInitSelector(hub),
  );

  function updateLogInit(log: boolean) {
    setLogInit((old) => ({
      ...old,
      log,
    }));
  }

  function updateColorInit(color: string) {
    setLogInit((old) => ({
      ...old,
      color,
    }));
  }

  return {
    colorInit,
    logInit,
    updateColorInit,
    updateLogInit,
  } as const;
}

export function useLogSignalRInvoke(hub: HUB_URL) {
  const [{ color: colorInvoke, log: logInvoke }, setLogInvoke] = useRecoilState(
    debugSignalRHubInvokeSelector(hub),
  );

  function updateLogInvoke(log: boolean) {
    setLogInvoke((old) => ({
      ...old,
      log,
    }));
  }

  function updateColorInvoke(color: string) {
    setLogInvoke((old) => ({
      ...old,
      color,
    }));
  }

  return {
    colorInvoke,
    logInvoke,
    updateColorInvoke,
    updateLogInvoke,
  } as const;
}

export function useLogSignalRReceive(hub: HUB_URL) {
  const [{ color: colorReceive, log: logReceive }, setLogReceive] = useRecoilState(
    debugSignalRHubReceiveSelector(hub),
  );

  function updateLogReceive(log: boolean) {
    setLogReceive((old) => ({
      ...old,
      log,
    }));
  }

  function updateColorReceive(color: string) {
    setLogReceive((old) => ({
      ...old,
      color,
    }));
  }

  return {
    colorReceive,
    logReceive,
    updateColorReceive,
    updateLogReceive,
  } as const;
}

/**
 * Returns a callback to read the state without subscribing to it.
 */
export function useLogSignalR(hub: HUB_URL) {
  const getLogSignalRHubInit = useRecoilCallback(
    ({ snapshot }) =>
      (hub: HUB_URL): LogSignalRMessageValue | undefined =>
        snapshot.getLoadable(debugSignalRHubInitSelector(hub))?.contents,
    [],
  );
  const getLogSignalRHubReceive = useRecoilCallback(
    ({ snapshot }) =>
      (hub: HUB_URL): LogSignalRMessageValue | undefined =>
        snapshot.getLoadable(debugSignalRHubReceiveSelector(hub))?.contents,
    [],
  );
  const getLogSignalRHubInvoke = useRecoilCallback(
    ({ snapshot }) =>
      (hub: HUB_URL): LogSignalRMessageValue | undefined =>
        snapshot.getLoadable(debugSignalRHubInvokeSelector(hub))?.contents,
    [],
  );

  function logSignalRInit(text: string, ...etc: unknown[]) {
    const debugInit = getLogSignalRHubInit(hub);
    if (!debugInit?.log) return;
    locColor(debugInit.color, text, ...etc);
  }

  function logSignalRInvoke(text: string, ...etc: unknown[]) {
    const debugInit = getLogSignalRHubInvoke(hub);
    if (!debugInit?.log) return;
    locColor(debugInit.color, text, ...etc);
  }

  function logSignalRReceive(text: string, ...etc: unknown[]) {
    const debugInit = getLogSignalRHubReceive(hub);
    if (!debugInit?.log) return;
    locColor(debugInit.color, text, ...etc);
  }

  return { logSignalRReceive, logSignalRInit, logSignalRInvoke };
}
