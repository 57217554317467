import { useRecoilValue, useSetRecoilState } from 'recoil';

import { gamepadAtom } from './gamepad.atom';

// helper hook to update the gamepad mode without subscribing to state change
export function useUpdateGamepad() {
  return useSetRecoilState(gamepadAtom);
}

/**
 * Hook for getting the gamepad state
 *
 * Note: currently not used which is good cause it gets updated on each button press and related information's should be selected by selectors
 */
export function useGamepad() {
  const gamepad = useRecoilValue(gamepadAtom);
  const updateGamepad = useUpdateGamepad();
  return { gamepad, updateGamepad };
}
