import { useLayoutEffect, useState } from 'react';

import { ImageModel } from 'types/ui/Image';

/**
 * This hook gives loading, loaded, error states for image
 * @param {String} src - Image source
 */

export const useImage = (src?: string): ImageModel => {
  const [image, setImage] = useState<HTMLImageElement | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState<boolean>(!src);
  const [isError, setIsError] = useState<boolean>(false);

  useLayoutEffect(() => {
    const img: HTMLImageElement = new Image();

    const handleError = () => {
      setIsError(true);
    };

    const handleLoad = () => {
      setImage(img);
      setIsLoaded(true);
      setIsError(false);
    };

    if (src) {
      setImage(undefined);
      setIsLoaded(false);
      setIsError(false);

      img.src = src;
      img.addEventListener('error', handleError);
      img.addEventListener('load', handleLoad);
    }

    return () => {
      img.removeEventListener('error', handleError);
      img.removeEventListener('load', handleLoad);
    };
  }, [src]);

  return {
    image,
    isLoaded,
    isError,
  };
};

export default useImage;
