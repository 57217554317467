import { useContext } from 'react';

import { RosBridgeContext } from 'components/provider';

/**
 * Access ros client from the provider - should
 */
export function useRosBridge() {
  const { ros } = useContext(RosBridgeContext);

  if (!ros) throw new Error('useRosBridge must be used within RosBridge');
  return { ros };
}
