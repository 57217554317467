import { lighten } from 'polished';
import styled from 'styled-components';

import { SBox, STypography } from 'components/ui';

import { hsLFromCssVar } from 'utils/helpers/hsl-from-css-var';

export const STileContainer = styled(SBox)`
  * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

export const STile = styled(SBox)`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.theme.borderRadius.lg};
  // background-color: ${(props) =>
    lighten(-0.05, hsLFromCssVar(props.theme.colors.background.main))};
  background-color: ${(props) => props.theme.colors.background.main};
  //box-shadow: 0 0 0 1px ${(props) => props.theme.colors.background.main};
`;

export const STileHeader = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  max-width: 280px;
  height: 26px;
  background-color: ${(props) => lighten(0.07, hsLFromCssVar(props.theme.colors.background.main))};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius.lg};
  border-bottom-left-radius: ${(props) => props.theme.borderRadius.lg};
  display: flex;
  justify-content: center;
  align-items: center;

  ${STypography} {
    user-select: none;
  }
`;

export const STileBody = styled.div<{ noPadding?: boolean }>`
  padding: ${(props) => (props.noPadding ? 0 : props.theme.spacing(7, 1, 1, 1))};
  overflow: auto;
  width: 100%;
  height: 100%;
`;
