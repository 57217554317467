import styled, { css } from 'styled-components';

import { SButton } from 'components/ui';

export const SDropdown = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen?: boolean }>`
  position: relative;
  display: inline-block;

  ${(props) => props.isOpen && css``};
`;

export const SDropdownListContainer = styled.div`
  position: absolute;
  z-index: 1;
  display: inline-block;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-bottom-left-radius: ${(props) => props.theme.borderRadius.md};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius.md};
  box-shadow: 0 0 10px -4px #ffffff70;
`;

export const SDropdownList = styled.ul`
  display: inline-block;
  margin: 0;
  padding: ${(props) => props.theme.spacing(1, 0)};
  //position: absolute;
  //min-width: 100%;
  //width: 100%;
  background-color: ${(props) => props.theme.colors.background.main};
`;

export const SDropdownItem = styled.li.withConfig({
  shouldForwardProp: (prop) => !['isClickable'].includes(prop),
})<{ isClickable?: boolean }>`
  cursor: default;
  display: flex;
  align-items: center;
  list-style: none;
  text-align: left;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  padding: ${(props) => props.theme.spacing(1, 2)};
  // color: ${(props) => props.theme.colors.text.main};

  ${(props) =>
    props.isClickable &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${props.theme.colors.background.dark};
      }
    `};
`;

export const SDropdownToggle = styled(SButton)`
  text-decoration: none;
`;

export const SDropdownDivider = styled.hr`
  border: none;
  margin: ${(props) => props.theme.spacing(1, 0)};
  background-color: ${(props) => props.theme.colors.border};
`;

export const SDropdownSection = styled.li`
  border-top: 1px solid ${(props) => props.theme.colors.border};
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  margin: ${(props) => props.theme.spacing(1, 0)};
  padding: ${(props) => props.theme.spacing(1, 0)};

  ${SDropdownItem} {
    padding: ${(props) => props.theme.spacing(2, 2, 2, 4)};
  }
`;
