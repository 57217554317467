import { memo } from 'react';

import { Collapsible, Loader, Modal } from 'components/common';
import { SBox, SButton, STypography } from 'components/ui';

import { NetworkError } from 'types/ui/NetworkError';

/**
 * ErrorBoundaryFallback modal
 * @param {NetworkError} error
 * @param {Function} resetErrorBoundary
 */

type ErrorBoundaryFallbackProps = {
  error: NetworkError;
  resetErrorBoundary?: () => void;
};

const ErrorBoundaryFallback = memo<ErrorBoundaryFallbackProps>(({ error, resetErrorBoundary }) => {
  const isUnauthorizedError = error?.status === 401;
  // const isNotFoundError = error?.status === 404;

  const refreshPage = () => {
    document.location.reload();
  };

  return (
    <Modal
      isOpen
      title="Something went wrong."
      footer={
        <Modal.Footer>
          {isUnauthorizedError ? (
            <STypography variant="subtitle1" align="right">
              Reload in 5 sec...
            </STypography>
          ) : (
            <>
              <SButton type="button" onClick={refreshPage}>
                Restart
              </SButton>
              {resetErrorBoundary && (
                <SButton type="button" data-dismiss="modal" onClick={resetErrorBoundary}>
                  Try Again
                </SButton>
              )}
            </>
          )}
        </Modal.Footer>
      }
    >
      {isUnauthorizedError && <Loader withBackdrop={false} linear />}
      <SBox py={4} px={8}>
        <Collapsible
          transitionTime={1}
          trigger={
            <STypography variant="subtitle1" w="100%" truncate>
              {error.title || 'Unknown error'}
            </STypography>
          }
        >
          <STypography
            as="div"
            wordBreak="break-word"
            // whiteSpace="pre-wrap"  TODO -> check
            dangerouslySetInnerHTML={{ __html: error?.message || 'No information available.' }}
          />
        </Collapsible>
      </SBox>
    </Modal>
  );
});

export default ErrorBoundaryFallback;
