import { get } from 'lodash-es';
import styled from 'styled-components';

import { STypography } from 'components/ui';

import { TooltipPlacements, position } from './Tooltip.helpers';

export const STooltip = styled(STypography).withConfig({
  shouldForwardProp: (prop) =>
    !['posRef', 'size', 'bgcolor', 'show', 'delay', 'placement'].includes(prop),
})<{
  posRef: {
    x: number | null;
    y: number | null;
  };
  size?: number;
  bgcolor?: string;
  show: number;
  delay?: number;
  placement: TooltipPlacements;
}>`
  position: fixed;
  top: ${(p) => p.posRef.y}px;
  left: ${(p) => p.posRef.x}px;
  font-size: ${(props) =>
    props.size ? get(props.theme.fontSizes, props.size) : props.theme.fontSizes.xxs};
  font-weight: 600;
  letter-spacing: 0.02em;
  background-color: ${(props) =>
    props.bgcolor ? get(props.theme.colors, props.bgcolor) : props.theme.colors.background.light};
  color: ${(props) => props.theme.colors.text.main};
  pointer-events: none;
  padding: 7px 10px;
  border-radius: 4px;
  z-index: 99999;
  display: inline-block;
  white-space: nowrap;
  opacity: ${(p) => p.show};
  transition-property: transform, opacity;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-delay: ${(p) => (p.show ? p.delay : 0.02)}s;
  transform-origin: ${(p) => position(p.placement).negate()};
  transform: scale(${(p) => (p.show ? 1 : 0.8)});
`;
