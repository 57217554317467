import AppRoutes from 'pages/Routes';
import { StrictMode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import ModalProvider from 'components/common/modal-provider';
import ErrorBoundaryFallback from 'components/complex/error-boundary-fallback/ErrorBoundaryFallback';
import RolesLayer from 'components/complex/role/RolesLayer';
import useSessionTimeout from 'components/complex/session-timeout/useSessionTimeout';
import GlobalStyles from 'components/ui/global/Global';
import ThemeProvider from 'components/ui/theme/ThemeProvider';
import { DarkModeThemeProvider } from 'components/ui/theme/dm-theme-provider';
import { Toaster } from 'components/ui/toaster';
import { ToastContainer } from 'components/ui/toastify/ToastContainer.styled';
import { TooltipProvider } from 'components/ui/tooltip';

import { QueryProvider } from 'hooks/query';

import { toastOptions } from 'lib/constants/toast-options';

import 'react-toastify/dist/ReactToastify.css';
import 'style/index.css';

const AppSecured = () => {
  const { isOutdated, modal } = useSessionTimeout();

  return (
    <RolesLayer>
      {!isOutdated && <AppRoutes />}
      {modal}
    </RolesLayer>
  );
};

function App() {
  return (
    <StrictMode>
      <RecoilRoot>
        <QueryProvider>
          <BrowserRouter>
            <ThemeProvider>
              <DarkModeThemeProvider defaultTheme="dark">
                <GlobalStyles />
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <ModalProvider>
                    <TooltipProvider>
                      <AppSecured />
                      <ToastContainer {...toastOptions} />
                      <Toaster />
                    </TooltipProvider>
                  </ModalProvider>
                </ErrorBoundary>
              </DarkModeThemeProvider>
            </ThemeProvider>
          </BrowserRouter>
        </QueryProvider>
      </RecoilRoot>
    </StrictMode>
  );
}

export default App;
