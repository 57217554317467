import { useRecoilValue } from 'recoil';

import { DeviceName } from 'types/models';

import { deviceNameSelector } from './myRobot.selectors';

/**
 * Todo: Test if we can use ID instead of name for the recoil-family distinguish
 */
export function useDeviceName(): { deviceName: DeviceName } {
  const deviceName = useRecoilValue(deviceNameSelector);

  return { deviceName };
}
