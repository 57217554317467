/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Any } from "../../google/protobuf/any";
import {
  BatteryChangePoseCommand_Feedback,
  BatteryChangePoseCommand_Request,
  ConstrainedManipulationCommand_Feedback,
  ConstrainedManipulationCommand_Request,
  FreezeCommand_Feedback,
  FreezeCommand_Request,
  JointCommand_Feedback,
  JointCommand_Request,
  RobotCommandFeedbackStatus_Status,
  robotCommandFeedbackStatus_StatusFromJSON,
  robotCommandFeedbackStatus_StatusToJSON,
  SafePowerOffCommand_Feedback,
  SafePowerOffCommand_Request,
  SelfRightCommand_Feedback,
  SelfRightCommand_Request,
  StopCommand_Feedback,
  StopCommand_Request,
} from "./basic_command";
import { PayloadEstimationCommand_Feedback, PayloadEstimationCommand_Request } from "./payload_estimation";

export const protobufPackage = "bosdyn.api";

/**
 * The robot command message to specify a basic command that requires full control of the entire
 * robot to be completed.
 */
export interface FullBodyCommand {
}

/** The full body request must be one of the basic command primitives. */
export interface FullBodyCommand_Request {
  /** Command to stop the robot. */
  stopRequest?:
    | StopCommand_Request
    | undefined;
  /** Command to freeze all joints of the robot. */
  freezeRequest?:
    | FreezeCommand_Request
    | undefined;
  /** Command to self-right the robot to a ready position. */
  selfrightRequest?:
    | SelfRightCommand_Request
    | undefined;
  /** Command to safely power off the robot. */
  safePowerOffRequest?:
    | SafePowerOffCommand_Request
    | undefined;
  /** Command to put the robot in a position to easily change the battery. */
  batteryChangePoseRequest?:
    | BatteryChangePoseCommand_Request
    | undefined;
  /** Command to perform payload mass property estimation */
  payloadEstimationRequest?:
    | PayloadEstimationCommand_Request
    | undefined;
  /** Command to perform full body constrained manipulation moves */
  constrainedManipulationRequest?:
    | ConstrainedManipulationCommand_Request
    | undefined;
  /** Activate joint level control */
  jointRequest?:
    | JointCommand_Request
    | undefined;
  /** Robot specific command parameters. */
  params: Any | undefined;
}

/**
 * The feedback for the fully body command that will provide information on the progress
 * of the robot command.
 */
export interface FullBodyCommand_Feedback {
  /** Feedback for the stop command request. */
  stopFeedback?:
    | StopCommand_Feedback
    | undefined;
  /** Feedback for the freeze command request. */
  freezeFeedback?:
    | FreezeCommand_Feedback
    | undefined;
  /** Feedback for the self-right command request. */
  selfrightFeedback?:
    | SelfRightCommand_Feedback
    | undefined;
  /** Feedback for the safe power off command request. */
  safePowerOffFeedback?:
    | SafePowerOffCommand_Feedback
    | undefined;
  /** Feedback for the battery change pose command request. */
  batteryChangePoseFeedback?:
    | BatteryChangePoseCommand_Feedback
    | undefined;
  /** Feedback for the payload estimation command request. */
  payloadEstimationFeedback?:
    | PayloadEstimationCommand_Feedback
    | undefined;
  /** Feedback for the constrained manipulation command request */
  constrainedManipulationFeedback?:
    | ConstrainedManipulationCommand_Feedback
    | undefined;
  /** Feedback for joint level control */
  jointFeedback?: JointCommand_Feedback | undefined;
  status: RobotCommandFeedbackStatus_Status;
}

function createBaseFullBodyCommand(): FullBodyCommand {
  return {};
}

export const FullBodyCommand = {
  encode(_: FullBodyCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FullBodyCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFullBodyCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FullBodyCommand {
    return {};
  },

  toJSON(_: FullBodyCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FullBodyCommand>, I>>(base?: I): FullBodyCommand {
    return FullBodyCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FullBodyCommand>, I>>(_: I): FullBodyCommand {
    const message = createBaseFullBodyCommand();
    return message;
  },
};

function createBaseFullBodyCommand_Request(): FullBodyCommand_Request {
  return {
    stopRequest: undefined,
    freezeRequest: undefined,
    selfrightRequest: undefined,
    safePowerOffRequest: undefined,
    batteryChangePoseRequest: undefined,
    payloadEstimationRequest: undefined,
    constrainedManipulationRequest: undefined,
    jointRequest: undefined,
    params: undefined,
  };
}

export const FullBodyCommand_Request = {
  encode(message: FullBodyCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.stopRequest !== undefined) {
      StopCommand_Request.encode(message.stopRequest, writer.uint32(10).fork()).ldelim();
    }
    if (message.freezeRequest !== undefined) {
      FreezeCommand_Request.encode(message.freezeRequest, writer.uint32(18).fork()).ldelim();
    }
    if (message.selfrightRequest !== undefined) {
      SelfRightCommand_Request.encode(message.selfrightRequest, writer.uint32(26).fork()).ldelim();
    }
    if (message.safePowerOffRequest !== undefined) {
      SafePowerOffCommand_Request.encode(message.safePowerOffRequest, writer.uint32(34).fork()).ldelim();
    }
    if (message.batteryChangePoseRequest !== undefined) {
      BatteryChangePoseCommand_Request.encode(message.batteryChangePoseRequest, writer.uint32(42).fork()).ldelim();
    }
    if (message.payloadEstimationRequest !== undefined) {
      PayloadEstimationCommand_Request.encode(message.payloadEstimationRequest, writer.uint32(50).fork()).ldelim();
    }
    if (message.constrainedManipulationRequest !== undefined) {
      ConstrainedManipulationCommand_Request.encode(message.constrainedManipulationRequest, writer.uint32(58).fork())
        .ldelim();
    }
    if (message.jointRequest !== undefined) {
      JointCommand_Request.encode(message.jointRequest, writer.uint32(66).fork()).ldelim();
    }
    if (message.params !== undefined) {
      Any.encode(message.params, writer.uint32(802).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FullBodyCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFullBodyCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.stopRequest = StopCommand_Request.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.freezeRequest = FreezeCommand_Request.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.selfrightRequest = SelfRightCommand_Request.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.safePowerOffRequest = SafePowerOffCommand_Request.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.batteryChangePoseRequest = BatteryChangePoseCommand_Request.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.payloadEstimationRequest = PayloadEstimationCommand_Request.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.constrainedManipulationRequest = ConstrainedManipulationCommand_Request.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.jointRequest = JointCommand_Request.decode(reader, reader.uint32());
          continue;
        case 100:
          if (tag !== 802) {
            break;
          }

          message.params = Any.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FullBodyCommand_Request {
    return {
      stopRequest: isSet(object.stopRequest) ? StopCommand_Request.fromJSON(object.stopRequest) : undefined,
      freezeRequest: isSet(object.freezeRequest) ? FreezeCommand_Request.fromJSON(object.freezeRequest) : undefined,
      selfrightRequest: isSet(object.selfrightRequest)
        ? SelfRightCommand_Request.fromJSON(object.selfrightRequest)
        : undefined,
      safePowerOffRequest: isSet(object.safePowerOffRequest)
        ? SafePowerOffCommand_Request.fromJSON(object.safePowerOffRequest)
        : undefined,
      batteryChangePoseRequest: isSet(object.batteryChangePoseRequest)
        ? BatteryChangePoseCommand_Request.fromJSON(object.batteryChangePoseRequest)
        : undefined,
      payloadEstimationRequest: isSet(object.payloadEstimationRequest)
        ? PayloadEstimationCommand_Request.fromJSON(object.payloadEstimationRequest)
        : undefined,
      constrainedManipulationRequest: isSet(object.constrainedManipulationRequest)
        ? ConstrainedManipulationCommand_Request.fromJSON(object.constrainedManipulationRequest)
        : undefined,
      jointRequest: isSet(object.jointRequest) ? JointCommand_Request.fromJSON(object.jointRequest) : undefined,
      params: isSet(object.params) ? Any.fromJSON(object.params) : undefined,
    };
  },

  toJSON(message: FullBodyCommand_Request): unknown {
    const obj: any = {};
    if (message.stopRequest !== undefined) {
      obj.stopRequest = StopCommand_Request.toJSON(message.stopRequest);
    }
    if (message.freezeRequest !== undefined) {
      obj.freezeRequest = FreezeCommand_Request.toJSON(message.freezeRequest);
    }
    if (message.selfrightRequest !== undefined) {
      obj.selfrightRequest = SelfRightCommand_Request.toJSON(message.selfrightRequest);
    }
    if (message.safePowerOffRequest !== undefined) {
      obj.safePowerOffRequest = SafePowerOffCommand_Request.toJSON(message.safePowerOffRequest);
    }
    if (message.batteryChangePoseRequest !== undefined) {
      obj.batteryChangePoseRequest = BatteryChangePoseCommand_Request.toJSON(message.batteryChangePoseRequest);
    }
    if (message.payloadEstimationRequest !== undefined) {
      obj.payloadEstimationRequest = PayloadEstimationCommand_Request.toJSON(message.payloadEstimationRequest);
    }
    if (message.constrainedManipulationRequest !== undefined) {
      obj.constrainedManipulationRequest = ConstrainedManipulationCommand_Request.toJSON(
        message.constrainedManipulationRequest,
      );
    }
    if (message.jointRequest !== undefined) {
      obj.jointRequest = JointCommand_Request.toJSON(message.jointRequest);
    }
    if (message.params !== undefined) {
      obj.params = Any.toJSON(message.params);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FullBodyCommand_Request>, I>>(base?: I): FullBodyCommand_Request {
    return FullBodyCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FullBodyCommand_Request>, I>>(object: I): FullBodyCommand_Request {
    const message = createBaseFullBodyCommand_Request();
    message.stopRequest = (object.stopRequest !== undefined && object.stopRequest !== null)
      ? StopCommand_Request.fromPartial(object.stopRequest)
      : undefined;
    message.freezeRequest = (object.freezeRequest !== undefined && object.freezeRequest !== null)
      ? FreezeCommand_Request.fromPartial(object.freezeRequest)
      : undefined;
    message.selfrightRequest = (object.selfrightRequest !== undefined && object.selfrightRequest !== null)
      ? SelfRightCommand_Request.fromPartial(object.selfrightRequest)
      : undefined;
    message.safePowerOffRequest = (object.safePowerOffRequest !== undefined && object.safePowerOffRequest !== null)
      ? SafePowerOffCommand_Request.fromPartial(object.safePowerOffRequest)
      : undefined;
    message.batteryChangePoseRequest =
      (object.batteryChangePoseRequest !== undefined && object.batteryChangePoseRequest !== null)
        ? BatteryChangePoseCommand_Request.fromPartial(object.batteryChangePoseRequest)
        : undefined;
    message.payloadEstimationRequest =
      (object.payloadEstimationRequest !== undefined && object.payloadEstimationRequest !== null)
        ? PayloadEstimationCommand_Request.fromPartial(object.payloadEstimationRequest)
        : undefined;
    message.constrainedManipulationRequest =
      (object.constrainedManipulationRequest !== undefined && object.constrainedManipulationRequest !== null)
        ? ConstrainedManipulationCommand_Request.fromPartial(object.constrainedManipulationRequest)
        : undefined;
    message.jointRequest = (object.jointRequest !== undefined && object.jointRequest !== null)
      ? JointCommand_Request.fromPartial(object.jointRequest)
      : undefined;
    message.params = (object.params !== undefined && object.params !== null)
      ? Any.fromPartial(object.params)
      : undefined;
    return message;
  },
};

function createBaseFullBodyCommand_Feedback(): FullBodyCommand_Feedback {
  return {
    stopFeedback: undefined,
    freezeFeedback: undefined,
    selfrightFeedback: undefined,
    safePowerOffFeedback: undefined,
    batteryChangePoseFeedback: undefined,
    payloadEstimationFeedback: undefined,
    constrainedManipulationFeedback: undefined,
    jointFeedback: undefined,
    status: 0,
  };
}

export const FullBodyCommand_Feedback = {
  encode(message: FullBodyCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.stopFeedback !== undefined) {
      StopCommand_Feedback.encode(message.stopFeedback, writer.uint32(10).fork()).ldelim();
    }
    if (message.freezeFeedback !== undefined) {
      FreezeCommand_Feedback.encode(message.freezeFeedback, writer.uint32(18).fork()).ldelim();
    }
    if (message.selfrightFeedback !== undefined) {
      SelfRightCommand_Feedback.encode(message.selfrightFeedback, writer.uint32(26).fork()).ldelim();
    }
    if (message.safePowerOffFeedback !== undefined) {
      SafePowerOffCommand_Feedback.encode(message.safePowerOffFeedback, writer.uint32(34).fork()).ldelim();
    }
    if (message.batteryChangePoseFeedback !== undefined) {
      BatteryChangePoseCommand_Feedback.encode(message.batteryChangePoseFeedback, writer.uint32(42).fork()).ldelim();
    }
    if (message.payloadEstimationFeedback !== undefined) {
      PayloadEstimationCommand_Feedback.encode(message.payloadEstimationFeedback, writer.uint32(50).fork()).ldelim();
    }
    if (message.constrainedManipulationFeedback !== undefined) {
      ConstrainedManipulationCommand_Feedback.encode(message.constrainedManipulationFeedback, writer.uint32(58).fork())
        .ldelim();
    }
    if (message.jointFeedback !== undefined) {
      JointCommand_Feedback.encode(message.jointFeedback, writer.uint32(66).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(800).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FullBodyCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFullBodyCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.stopFeedback = StopCommand_Feedback.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.freezeFeedback = FreezeCommand_Feedback.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.selfrightFeedback = SelfRightCommand_Feedback.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.safePowerOffFeedback = SafePowerOffCommand_Feedback.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.batteryChangePoseFeedback = BatteryChangePoseCommand_Feedback.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.payloadEstimationFeedback = PayloadEstimationCommand_Feedback.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.constrainedManipulationFeedback = ConstrainedManipulationCommand_Feedback.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.jointFeedback = JointCommand_Feedback.decode(reader, reader.uint32());
          continue;
        case 100:
          if (tag !== 800) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FullBodyCommand_Feedback {
    return {
      stopFeedback: isSet(object.stopFeedback) ? StopCommand_Feedback.fromJSON(object.stopFeedback) : undefined,
      freezeFeedback: isSet(object.freezeFeedback) ? FreezeCommand_Feedback.fromJSON(object.freezeFeedback) : undefined,
      selfrightFeedback: isSet(object.selfrightFeedback)
        ? SelfRightCommand_Feedback.fromJSON(object.selfrightFeedback)
        : undefined,
      safePowerOffFeedback: isSet(object.safePowerOffFeedback)
        ? SafePowerOffCommand_Feedback.fromJSON(object.safePowerOffFeedback)
        : undefined,
      batteryChangePoseFeedback: isSet(object.batteryChangePoseFeedback)
        ? BatteryChangePoseCommand_Feedback.fromJSON(object.batteryChangePoseFeedback)
        : undefined,
      payloadEstimationFeedback: isSet(object.payloadEstimationFeedback)
        ? PayloadEstimationCommand_Feedback.fromJSON(object.payloadEstimationFeedback)
        : undefined,
      constrainedManipulationFeedback: isSet(object.constrainedManipulationFeedback)
        ? ConstrainedManipulationCommand_Feedback.fromJSON(object.constrainedManipulationFeedback)
        : undefined,
      jointFeedback: isSet(object.jointFeedback) ? JointCommand_Feedback.fromJSON(object.jointFeedback) : undefined,
      status: isSet(object.status) ? robotCommandFeedbackStatus_StatusFromJSON(object.status) : 0,
    };
  },

  toJSON(message: FullBodyCommand_Feedback): unknown {
    const obj: any = {};
    if (message.stopFeedback !== undefined) {
      obj.stopFeedback = StopCommand_Feedback.toJSON(message.stopFeedback);
    }
    if (message.freezeFeedback !== undefined) {
      obj.freezeFeedback = FreezeCommand_Feedback.toJSON(message.freezeFeedback);
    }
    if (message.selfrightFeedback !== undefined) {
      obj.selfrightFeedback = SelfRightCommand_Feedback.toJSON(message.selfrightFeedback);
    }
    if (message.safePowerOffFeedback !== undefined) {
      obj.safePowerOffFeedback = SafePowerOffCommand_Feedback.toJSON(message.safePowerOffFeedback);
    }
    if (message.batteryChangePoseFeedback !== undefined) {
      obj.batteryChangePoseFeedback = BatteryChangePoseCommand_Feedback.toJSON(message.batteryChangePoseFeedback);
    }
    if (message.payloadEstimationFeedback !== undefined) {
      obj.payloadEstimationFeedback = PayloadEstimationCommand_Feedback.toJSON(message.payloadEstimationFeedback);
    }
    if (message.constrainedManipulationFeedback !== undefined) {
      obj.constrainedManipulationFeedback = ConstrainedManipulationCommand_Feedback.toJSON(
        message.constrainedManipulationFeedback,
      );
    }
    if (message.jointFeedback !== undefined) {
      obj.jointFeedback = JointCommand_Feedback.toJSON(message.jointFeedback);
    }
    if (message.status !== 0) {
      obj.status = robotCommandFeedbackStatus_StatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FullBodyCommand_Feedback>, I>>(base?: I): FullBodyCommand_Feedback {
    return FullBodyCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FullBodyCommand_Feedback>, I>>(object: I): FullBodyCommand_Feedback {
    const message = createBaseFullBodyCommand_Feedback();
    message.stopFeedback = (object.stopFeedback !== undefined && object.stopFeedback !== null)
      ? StopCommand_Feedback.fromPartial(object.stopFeedback)
      : undefined;
    message.freezeFeedback = (object.freezeFeedback !== undefined && object.freezeFeedback !== null)
      ? FreezeCommand_Feedback.fromPartial(object.freezeFeedback)
      : undefined;
    message.selfrightFeedback = (object.selfrightFeedback !== undefined && object.selfrightFeedback !== null)
      ? SelfRightCommand_Feedback.fromPartial(object.selfrightFeedback)
      : undefined;
    message.safePowerOffFeedback = (object.safePowerOffFeedback !== undefined && object.safePowerOffFeedback !== null)
      ? SafePowerOffCommand_Feedback.fromPartial(object.safePowerOffFeedback)
      : undefined;
    message.batteryChangePoseFeedback =
      (object.batteryChangePoseFeedback !== undefined && object.batteryChangePoseFeedback !== null)
        ? BatteryChangePoseCommand_Feedback.fromPartial(object.batteryChangePoseFeedback)
        : undefined;
    message.payloadEstimationFeedback =
      (object.payloadEstimationFeedback !== undefined && object.payloadEstimationFeedback !== null)
        ? PayloadEstimationCommand_Feedback.fromPartial(object.payloadEstimationFeedback)
        : undefined;
    message.constrainedManipulationFeedback =
      (object.constrainedManipulationFeedback !== undefined && object.constrainedManipulationFeedback !== null)
        ? ConstrainedManipulationCommand_Feedback.fromPartial(object.constrainedManipulationFeedback)
        : undefined;
    message.jointFeedback = (object.jointFeedback !== undefined && object.jointFeedback !== null)
      ? JointCommand_Feedback.fromPartial(object.jointFeedback)
      : undefined;
    message.status = object.status ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
