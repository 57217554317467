import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useDeviceName } from 'store/my-robot';

import { BatteryStateWithDuration, batteryStatusAtom } from './battery-status.atom';
import {
  batteryStatusChargingSelector,
  batteryStatusPercentageSelector,
  receivedNewBatteryStatusSelector,
} from './battery-status.selectors';

export function useBatteryStatus() {
  const percentage = useRecoilValue(batteryStatusPercentageSelector);
  const charging = useRecoilValue(batteryStatusChargingSelector);

  const receivedBatteryStatusViaRelayTopic = useRecoilValue(receivedNewBatteryStatusSelector);

  return { percentage, charging, receivedBatteryStatusViaRelayTopic };
}

export function useSetBatteryStatus() {
  const { deviceName } = useDeviceName();
  const setBatteryStatus = useSetRecoilState(batteryStatusAtom(deviceName));

  function updateBatteryStatus(payload: any) {
    setBatteryStatus(payload as BatteryStateWithDuration);
  }

  return { updateBatteryStatus };
}
