import axios, { AxiosRequestConfig } from 'axios';

import NetworkAxiosError from 'utils/helpers/network-axios-error';

import { getToken } from '../authProvider';
import { AzureADState } from '../constants/state';

const axiosInstance = axios.create({
  // baseURL: `${generateSubdomainBaseUrl(BASE_URL)}/api`,
  timeout: 50000,
  headers: {
    // Authorization: `Bearer ${getToken()}`,
    // 'Content-Type': 'application/json',
    UserName: AzureADState.accountInfo?.userName,
  },
});

// REQUEST
axiosInstance.interceptors.request.use(
  (request) => {
    // const token = localStorage.getItem('userToken');
    // if (token) {
    // eslint-disable-next-line no-param-reassign
    request.headers.Authorization = `Bearer ${getToken()}`;
    // }

    return request;
  },
  (error) => Promise.reject(error),
);

// RESPONSE
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!axios.isCancel(error) && error?.response) {
      const { statusText } = error.response;

      // Unauthorized call
      if (statusText?.toLowerCase().includes('unauthorized')) {
        setTimeout(() => {
          window.location.reload();
        }, 1000 * 5); // 5 sec to refresh the page
      }
    }

    return Promise.reject(new NetworkAxiosError(error));
  },
);

axiosInstance.interceptors.response.use((response) => {
  if (response.headers['content-type'] === 'text/html') {
    const url = response.config.url;
    const message = `404 - cannot find ${url} (api response content type is 'text/html')`;
    return Promise.reject({ message });
  }
  return response;
});

export function axiosRequest<T = unknown>(
  method: string, // this should be Method (from Axios)
  url: string,
  data: AxiosRequestConfig['data'] = {},
  options: AxiosRequestConfig = {},
) {
  const hasData = ['post', 'put', 'patch'].includes(method.toLowerCase());
  const settings = hasData ? options : data;
  const source = axios.CancelToken.source();
  const config: AxiosRequestConfig = Object.assign(
    {
      method,
      url,
      data: hasData ? data : undefined,
      cancelToken: source.token,
    },
    settings,
  );

  return axiosInstance.request<T>(config);
}
