import { useRelayTopicSetter } from 'store/my-robot/relay-topics/useRelayTopicSetter';

import { IothubEvent, useIothubHub } from 'hooks/signalR';

import { useRobotTopicRelayResend } from './useRobotTopicRelayResend';

/**
 * Top level hook for connecting to all relay topics
 *
 * Notes:
 * - its kind of bad that we cannot subscribe to a specific topic and always have to listen to all
 */
export function useRobotTopicsRelay() {
  // note - don't like this placed here nested
  useRobotTopicRelayResend();
  const { handleReceivedRelayTopicUpdate } = useRelayTopicSetter();

  return useIothubHub({
    listenMethods: {
      [IothubEvent.RECEIVE_TOPIC_RELAY]: handleReceivedRelayTopicUpdate,
    },
  });
}
