import Select from 'react-select';
import styled from 'styled-components';

const SSelect = styled(Select)`
  .react-select {
    font-family: ${(props) => props.theme.fontFamilies.roboto};

    &__control {
      //height: 24px;
      //min-height: 24px !important;
      min-height: 100%;
      cursor: pointer !important;
      border: none;
      background-color: transparent !important;
      padding: ${(props) => props.theme.spacing(0, 1)};
      font-size: ${(props) => props.theme.fontSizes.xxxs};
      border-radius: ${(props) => props.theme.borderRadius.md} !important;
      //width: 100px;
      min-width: 80px;
      margin: auto;
      //margin-bottom: $space-xxs;
      box-shadow: none !important;

      &--is-focused {
        border-color: ${(props) => props.theme.colors.primary.main} !important;
      }

      &--menu-is-open {
        .react-select__indicator svg {
          transform: rotate(-180deg);
        }
      }
    }

    &__value-container {
      width: 100%;
      padding-left: 0 !important;
    }

    &__input-container {
      width: 100%;
    }

    &__input {
      color: ${(props) => props.theme.colors.text.dark} !important;
    }

    &__single-value {
      width: 100%;
      color: ${(props) => props.theme.colors.text.main};
      font-family: ${(props) => props.theme.fontFamilies.roboto};
    }

    &__placeholder {
      margin-left: 0;
      color: ${(props) => props.theme.colors.text.main};
      font-family: ${(props) => props.theme.fontFamilies.roboto};
    }

    &__indicator-separator {
      display: none !important;
    }

    &__indicator {
      //padding-top: 0 !important;
      //padding-bottom: 0 !important;
      //padding-right: 0 !important;
      padding: 0 !important;

      & > svg {
        width: 16px;
        height: 16px;
        transition: all 0.25s ease-in-out;
        color: ${(props) => props.theme.colors.text.dark};
      }
    }

    &__menu {
      width: auto;
      max-width: 60vw;
      min-width: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: ${(props) => props.theme.spacing(1)};
      box-shadow: none !important;
      border: 1px solid ${(props) => props.theme.colors.primary.main};
      background-color: ${(props) => props.theme.colors.background.main};

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        background: transparent;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }

      ::-webkit-scrollbar-track {
        background: transparent;
      }

      &-notice--no-options {
        font-size: ${(props) => props.theme.fontSizes.xxxs};
        font-family: ${(props) => props.theme.fontFamilies.roboto};
      }

      &-list {
      }
    }

    &__option {
      cursor: pointer !important;
      display: inline-block;
      margin: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      color: ${(props) => props.theme.colors.text.main};
      padding: ${(props) => props.theme.spacing(1, 2)};
      font-size: ${(props) => props.theme.fontSizes.xxxs};
      font-family: ${(props) => props.theme.fontFamilies.roboto};

      &--is-focused {
        background-color: ${(props) => props.theme.colors.background.dark} !important;
      }

      &--is-selected {
        background-color: ${(props) => props.theme.colors.primary.main} !important;
      }

      .option-icon {
        border-radius: 50%;
        height: 20px;
        width: 20px;
        margin-right: 10px;
        object-fit: cover;
      }
    }
  }

  //&.field__is-searchable {
  //  &__indicator-separator {
  //    display: block !important;
  //  }
  //}
`;

export default SSelect;
