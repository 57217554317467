import { useQuery, useQueryClient } from '@tanstack/react-query';
import { spot_ncb_rms_actions_interfaces } from 'rclnodejs';

import {
  ListAvailableModelsResponse,
  ModelData,
} from 'lib/generated/proto/bosdyn/api/network_compute_bridge';

import { logBlue } from 'utils/debugger';

import { RobotRosTopic } from '../types';

const queryKey = ['relayTopics', RobotRosTopic.NCB_AVAILABLE_SERVICES];

type AvailableServiceNCB = ModelData & { serviceName: string };
type AvailableServicesNCB = AvailableServiceNCB[];

/**
 * Currently this only will not get updated frequents
 */
export function useAvailableServicesNCBSetter() {
  const queryClient = useQueryClient();

  function updateAvailableServicesNCB(data: unknown) {
    const parsedData = (
      data as spot_ncb_rms_actions_interfaces.msg.NetworkComputeServiceList
    ).services.map((e) => JSON.parse(e));
    logBlue('updateAvailableServicesNCB:parsedData', parsedData);

    const models = parsedData
      .map((service) => {
        // note: this will remove serviceName because it's not part of the model
        const data = ListAvailableModelsResponse.fromJSON(service);
        const serviceName = service.serviceName as unknown as string;
        return {
          serviceName,
          data,
        };
      })
      /**
       * We only the models with customParams
       */
      .reduce<AvailableServicesNCB>((acc, val) => {
        const data = val.data.models?.data;
        data?.map(
          (modelData) =>
            !!modelData.customParams && acc.push({ ...modelData, serviceName: val.serviceName }),
        );
        return acc;
      }, []);
    logBlue('updateAvailableServicesNCB:models', models);
    queryClient.setQueryData(queryKey, models);
  }

  return {
    updateAvailableServicesNCB,
  };
}

export function useAvailableServicesNCB() {
  const queryClient = useQuery<AvailableServicesNCB>({
    queryKey,
  });

  const availableServices = queryClient?.data ?? [];

  return { availableServices };
}
