export const LOCAL_STORAGE = {
  DEBUG: {
    /**
     * The first value we had - we use this to log all signalR messages
     */
    ALL: 'debug',
    /**
     * Showing all visual debug menus
     */
    SHOW_MENU: 'debug-show-menu',
    /**
     * Options for debugging the signalR receive relay
     */
    SIGNAL_R_RELAY: 'debug-signal-r-relay',
    /**
     * Options for debugging the signalR receive relay
     */
    ROS_BRIDGE: 'debug-ros-bridge',
    /**
     * Options for debugging the signalR receive relay
     */
    SIGNAL_R: 'debug-signal-r',
    /**
     * Options for debugging the signalR receive relay
     */
    SIGNAL_R_LOG_LEVEL: 'debug-signal-r-log-level',
  },
  SIDEBAR_CLOSED: 'sidebar-closed',
  DM_UI_THEME: 'dm-ui-theme',
};
