import { atom, atomFamily, selectorFamily } from 'recoil';

import { deviceNameSelector } from 'store/my-robot';

import { axiosRequest } from 'lib/api/axios';
import { getMyRobotNotificationsConfig, getMyRobotNotificationsCountConfig } from 'lib/api/urls';

import { Notification } from 'types/models/Notification';

export const newNotificationsIndicatorAtom = atom<boolean>({
  key: 'newNotificationsIndicatorAtom',
  default: false,
});

type NotificationOptions = {
  skip: number;
  take: number;
};

export const notificationsDataAtom = atomFamily<Notification[], NotificationOptions>({
  key: 'notificationsDataAtom',
  default: selectorFamily<Notification[], NotificationOptions>({
    key: 'notificationsDataAtomSelector',
    get:
      ({ skip, take }) =>
      async ({ get }) => {
        const deviceName = get(deviceNameSelector);

        const { method, url } = getMyRobotNotificationsConfig(deviceName, { skip, take });
        const { data: notifications } = await axiosRequest<Notification[]>(method, url);

        return notifications ?? [];
      },
  }),
});

export const notificationCountAtom = atomFamily<number, null>({
  key: 'notificationCountAtom',
  default: selectorFamily<number, null>({
    key: 'notificationCountAtomSelector',
    get:
      () =>
      async ({ get }) => {
        const deviceName = get(deviceNameSelector);

        const { method, url } = getMyRobotNotificationsCountConfig(deviceName);
        const { data: count } = await axiosRequest<number>(method, url);

        return count ?? 0;
      },
  }),
});
