import { RecoilValue, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil';

import { useModal } from 'components/common/modal-provider';
import ErrorBoundaryFallback from 'components/complex/error-boundary-fallback/ErrorBoundaryFallback';

import { useDidUpdate } from 'hooks';

type AsyncState<T> = {
  data?: T;
  isLoading?: boolean;
  error?: unknown;
  refresh?: () => void;
};

function useRecoilAsyncValue<T>(selector: RecoilValue<T>): AsyncState<T> {
  const { state, contents } = useRecoilValueLoadable(selector);
  const refresh = useRecoilRefresher_UNSTABLE(selector);

  const { showModal } = useModal();

  // Can be logic for revalidation with timeout

  useDidUpdate(() => {
    if (state === 'hasError') {
      showModal(() => <ErrorBoundaryFallback error={contents} resetErrorBoundary={refresh} />);
    }
  }, [state, contents]);

  switch (state) {
    case 'loading':
      return { isLoading: true };
    case 'hasValue':
      return { data: contents, refresh };
    case 'hasError': {
      return { error: contents, refresh };
    }
  }
}

export default useRecoilAsyncValue;
