import { atom } from 'recoil';

import { HUB_URLS } from 'hooks/signalR/const';

import { LOCAL_STORAGE } from 'lib/constants/local-storage';

import { DebugMessageColor, colorOption } from 'utils/debugger';

import { LogSignalRState } from './types';

const getDefaultLogSignalRState = (): LogSignalRState => {
  const stored = localStorage.getItem(LOCAL_STORAGE.DEBUG.SIGNAL_R);
  const state = stored ? JSON.parse(stored) : {};

  // add default values if local storage is empty
  HUB_URLS.forEach((hub) => {
    if (!state[hub]) {
      state[hub] = {
        invoke: {
          log: false,
          color: colorOption[DebugMessageColor.GREEN],
        },
        init: {
          log: false,
          color: colorOption[DebugMessageColor.GREEN_DARK],
        },
        receive: {
          log: false,
          color: colorOption[DebugMessageColor.BLUE],
        },
      };
    }
  });

  return state;
};

export const debugSignalRAtom = atom({
  key: 'debugSignalRAtom',
  default: getDefaultLogSignalRState(),
  effects: [
    ({ onSet }) => {
      onSet((state) => {
        localStorage.setItem(LOCAL_STORAGE.DEBUG.SIGNAL_R, JSON.stringify(state));
      });
    },
  ],
});
