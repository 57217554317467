import { useDeviceName } from 'store/my-robot';
import { RobotRosTopic } from 'store/my-robot/relay-topics';

import { TelemetryHubEvents, useTelemetryHub } from 'hooks/signalR';

const RESEND_RELAY_NAMES = [
  RobotRosTopic.ROBOT_BATTERY,
  RobotRosTopic.SPOT_FEEDBACK,
  RobotRosTopic.SPOT_MOBILITY_PARAMS,
  RobotRosTopic.SPOT_CLAIM,
  RobotRosTopic.MANIPULATION_STATE,
  RobotRosTopic.NCB_AVAILABLE_SERVICES,
  RobotRosTopic.RMS_AVAILABLE_SERVICES,
  RobotRosTopic.FIDUCIALS,
  RobotRosTopic.CURRENT_LOCATION,
];

/**
 * Todo: find out why we receive this stuff on other hub
 * Todo: merge with useInvokeRobotTopicRelayResend when sure that invoke works even connections is still startup/reconnecting
 */
export const useRobotTopicRelayResend = () => {
  const { deviceName } = useDeviceName();

  useTelemetryHub({
    invokeMethods: {
      [TelemetryHubEvents.SEND_RELAY_RESEND_REQUEST]: {
        args: {
          deviceName: deviceName,
          relayNames: RESEND_RELAY_NAMES,
        },
      },
    },
  });
};

export const useInvokeRobotTopicRelayResend = (relayNames: RobotRosTopic[] = []) => {
  const { deviceName } = useDeviceName();

  const { invoke } = useTelemetryHub({});

  function invokeResendClaimState() {
    const data = {
      deviceName,
      relayNames,
    };
    return invoke(TelemetryHubEvents.SEND_RELAY_RESEND_REQUEST, data);
  }

  return { invokeResendClaimState };
};
