export interface IRosTypeStdMsgsHeader {
  stamp: { sec: number; nanosec: number };
  frame_id: string;
}

export interface IRosTypeSensorMsgsBatteryState {
  header: IRosTypeStdMsgsHeader;
  voltage: number;
  temperature: number;
  current: number;
  charge: number;
  capacity: number;
  design_capacity: number;
  percentage: number;
  power_supply_status: IRosTypeSensorMsgsBatteryStatePowerSupplyStatus;
  power_supply_health: IRosTypeSensorMsgsBatteryStatePowerSupplyHealth;
  power_supply_technology: IRosTypeSensorMsgsBatteryStatePowerSupplyTechnology;
  present: boolean;
  cell_voltage: number[];
  cell_temperature: number[];
  location: string;
  serial_number: string;
}

export enum IRosTypeSensorMsgsBatteryStatePowerSupplyStatus {
  UNKNOWN = 0,
  CHARGING = 1,
  DISCHARGING = 2,
  NOT_CHARGING = 3,
  FULL = 4,
}

export enum IRosTypeSensorMsgsBatteryStatePowerSupplyHealth {
  UNKNOWN = 0,
  GOOD = 1,
  OVERHEAT = 2,
  DEAD = 3,
  OVERVOLTAGE = 4,
  UNSPEC_FAILURE = 5,
  COLD = 6,
  WATCHDOG_TIMER_EXPIRE = 7,
  SAFETY_TIMER_EXPIRE = 8,
}

export enum IRosTypeSensorMsgsBatteryStatePowerSupplyTechnology {
  UNKNOWN = 0,
  NIMH = 1,
  LION = 2,
  LIPO = 3,
  LIFE = 4,
  NICD = 5,
  LIMN = 6,
}
