import { SVGAttributes, memo } from 'react';

import iconsList from 'lib/constants/IconsList';

import { Spacings, ThemeColorKeys } from 'types/ui/Theme';

import { SIcon, SIconBadge, SIconWrapper } from './Icon.styled';

/**
 * Icon
 * @param {String} icon - Icon name
 */

export type IconProps = SVGAttributes<SVGElement> & {
  icon: string;
  // icon: keyof typeof iconsList; TODO -> ref this
  color?: `${ThemeColorKeys}`;
  badge?: string;
  badgeBackgroundColor?: `${ThemeColorKeys}`;
} & Partial<Spacings>;

const Icon = memo<IconProps>(({ icon, color, badge, badgeBackgroundColor, ...rest }) => {
  if (!badge) {
    return <SIcon as={iconsList?.[icon]} color={color} {...rest} />;
  }

  return (
    <SIconWrapper>
      <SIconBadge backgroundColor={badgeBackgroundColor}>
        <SIcon as={iconsList?.[badge]} />
      </SIconBadge>
      <SIcon as={iconsList?.[icon]} color={color} {...rest} />
    </SIconWrapper>
  );
});

export default Icon;
