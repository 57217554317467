// @ts-nocheck
import { RemoteFeedCallback } from '../helper';
import Janus from '../janus';
import {
  JanusEventType,
  JanusJSEP,
  JanusPluginHandle,
  JanusPluginOptions,
  JanusType,
  Publisher,
} from '../types';

// todo - write bedder explanation for this 2. plugin and link docs
// newRemoteFeed 	- A new feed has been published, create a new plugin handle and attach to it as a subscriber
export const subscribeRemoteFeed = (
  janus: JanusType,
  opaqueId: string,
  room: string,
  privateId: string | undefined,
  publisher: Publisher,
  callback: RemoteFeedCallback,
) => {
  // todo maybe it is audio_codec and video_codec now?
  let { id, display, audio, video } = publisher;
  let remoteFeed: JanusPluginHandle | null = null;

  const videoRoomPlugin: JanusPluginOptions = {
    plugin: 'janus.plugin.videoroom',
    opaqueId,
    success: function success(pluginHandle) {
      remoteFeed = pluginHandle;
      // todo - simulcastStarted it's not used anywhere in code or library
      remoteFeed.simulcastStarted = false;
      Janus.log(
        `remoteFeed:success: Plugin attached! (${remoteFeed.getPlugin()}, id=${remoteFeed.getId()})`,
      );
      Janus.log({ pluginHandle });

      const subscribe = {
        request: 'join',
        room: room,
        ptype: 'subscriber',
        feed: id,
        private_id: privateId,
        data: true,
      };

      if (
        Janus.webRTCAdapter.browserDetails.browser === 'safari' &&
        (video === 'vp9' || (video === 'vp8' && !Janus.safariVp8))
      ) {
        if (video) video = video.toUpperCase();
        subscribe.offer_video = false;
      }
      remoteFeed.videoCodec = video;
      remoteFeed.send({ message: subscribe });
    },
    error: function error(_error) {
      Janus.error('remoteFeed:error:  -- Error attaching plugin...', _error);
      callback(publisher, remoteFeed, JanusEventType.ERROR, _error);
    },
    // Handle msg, if needed, and check jsep
    onmessage: function onmessage(msg, jsep) {
      Janus.debug('remoteFeed:onmessage: ::: Got a message (subscriber) :::');
      Janus.debug({ msg, jsep });

      if (jsep) {
        Janus.debug('remoteFeed:SUBS: Handling SDP as well...');
        Janus.debug(jsep);
        // Answer and attach
        remoteFeed?.createAnswer({
          jsep,
          // Add data:true here if you want to subscribe to datachannels as well
          // (obviously only works if the publisher offered them in the first place)
          media: {
            audioSend: false,
            videoSend: false,
          },
          success: function success(ourJsep: JanusJSEP) {
            // Got our SDP! Send our ANSWER to the plugin
            Janus.debug('remoteFeed:Got SDP! (ourJsep)', ourJsep);
            const body = {
              request: 'start',
              room: room,
            };
            remoteFeed?.send({
              message: body,
              jsep: ourJsep,
            });
          },
          error: function error(_error2) {
            Janus.error('remoteFeed:WebRTC error:', _error2);
          },
        });
      }
    },
    webrtcState: function webrtcState(on) {
      Janus.log(
        `webrtcState: Janus says this WebRTC PeerConnection (feed #${remoteFeed?.rfindex}) is ${
          on ? 'up' : 'down'
        } now`,
      );
    },
    onlocalstream: function onlocalstream(stream: MediaStream) {
      // The subscriber stream is recvonly, we don't expect anything here
    },
    // this is working/logging on test-janus-app but our janus.js has not implemented this
    // onremotetrack: function (track, mid, on, metadata) {
    //   debugJanus('subscribeRemoteFeed:onremotetrack', { track, mid, on, metadata });
    //   Janus.debug(
    //     `Remote feed #${remoteFeed?.rfindex}, remote track (mid=${mid}) ${
    //       on ? 'added' : 'removed'
    //     }${metadata ? ' (' + metadata.reason + ') ' : ''}:`,
    //     track,
    //   );
    // },
    onremotestream: function onremotestream(stream: MediaStream) {
      callback(publisher, remoteFeed, JanusEventType.ON_REMOTE_STREAM, stream);
    },
    // remoteFeed
    oncleanup: function oncleanup() {
      callback(publisher, remoteFeed, JanusEventType.ON_CLEANUP);
    },
  };

  janus.attach?.(videoRoomPlugin);
  return remoteFeed;
};
