import { bosdyn_api_msgs } from 'rclnodejs';

import { NamedArmPositionsCommand_Positions } from 'lib/generated/proto/bosdyn/api/arm_command';

import {
  IRosTypeBosdynApiMsgsArmCommandRequestOneOfCommandConst,
  IRosTypeBosdynApiMsgsClawGripperCommandRequestConst,
  IRosTypeBosdynApiMsgsGripperCommandRequestOneOfCommandConst,
  IRosTypeBosdynApiMsgsRobotCommandOneOfCommandConst,
  IRosTypeBosdynApiMsgsSynchronizedCommandFeedbackConst,
  IRosTypeBosdynApiMsgsSynchronizedCommandRequestConst,
} from 'types/generated/ros-msgs';
import { DeepPartial } from 'types/util';

type RobotCommandRequest = DeepPartial<bosdyn_api_msgs.msg.RobotCommandRequest>;
type RobotCommand = DeepPartial<bosdyn_api_msgs.msg.RobotCommand>;
type SynchronizedCommandRequest = DeepPartial<bosdyn_api_msgs.msg.SynchronizedCommandRequest>;
type ArmCommandRequestOneOfCommand = DeepPartial<bosdyn_api_msgs.msg.ArmCommandRequestOneOfCommand>;
type ScalarTrajectory = DeepPartial<bosdyn_api_msgs.msg.ScalarTrajectory>;

export class RobotCommands {
  /**
   * currently nobody use a
   * @param synchronized_command
   */
  static _createRobotCommand(
    synchronized_command: SynchronizedCommandRequest,
  ): RobotCommandRequest {
    const command: RobotCommand = {
      command: {
        synchronized_command,
        which: IRosTypeBosdynApiMsgsRobotCommandOneOfCommandConst.COMMAND_SYNCHRONIZED_COMMAND_SET,
      },
    };

    return {
      command,
    };
  }

  static _createArmCommand(command: ArmCommandRequestOneOfCommand): RobotCommandRequest {
    const synchronized_command: SynchronizedCommandRequest = {
      arm_command: { command },
      has_field:
        IRosTypeBosdynApiMsgsSynchronizedCommandFeedbackConst.ARM_COMMAND_FEEDBACK_FIELD_SET,
    };
    return this._createRobotCommand(synchronized_command);
  }

  static createArmStow(stow: boolean): RobotCommandRequest {
    // the position of the arm we want to move to
    const value = stow
      ? NamedArmPositionsCommand_Positions.POSITIONS_STOW
      : NamedArmPositionsCommand_Positions.POSITIONS_READY;

    return this._createArmCommand({
      named_arm_position_command: {
        position: {
          value,
        },
      },
      which:
        IRosTypeBosdynApiMsgsArmCommandRequestOneOfCommandConst.COMMAND_NAMED_ARM_POSITION_COMMAND_SET,
    });
  }

  static createArmCarryCommand(): RobotCommandRequest {
    const value = NamedArmPositionsCommand_Positions.POSITIONS_CARRY;

    return this._createArmCommand({
      named_arm_position_command: {
        position: {
          value,
        },
      },
      which:
        IRosTypeBosdynApiMsgsArmCommandRequestOneOfCommandConst.COMMAND_NAMED_ARM_POSITION_COMMAND_SET,
    });
  }

  static createArmStopCommand(): RobotCommandRequest {
    return this._createArmCommand({
      arm_stop_command: {},
      which: IRosTypeBosdynApiMsgsArmCommandRequestOneOfCommandConst.COMMAND_ARM_STOP_COMMAND_SET,
    });
  }

  static createArmDragCommand(): RobotCommandRequest {
    return this._createArmCommand({
      arm_drag_command: {},
      which: IRosTypeBosdynApiMsgsArmCommandRequestOneOfCommandConst.COMMAND_ARM_DRAG_COMMAND_SET,
    });
  }

  static createOpenGripper(open: boolean, slow: boolean = false): RobotCommandRequest {
    // the position of the arm we want to move to
    const point = open ? -1.5708 : 0;
    const maximum_open_close_velocity = { data: slow ? 0.2 : 10 }; // 10 is default

    // no has_field cause both fields are required
    const trajectory: ScalarTrajectory = {
      points: [
        {
          point,
          has_field: 0,
        },
      ],
      interpolation: {
        value: 0,
      },
      has_field: 0,
    };

    const synchronized_command: SynchronizedCommandRequest = {
      gripper_command: {
        command: {
          claw_gripper_command: {
            trajectory,
            maximum_open_close_velocity,
            disable_force_on_contact: false,
            has_field:
              IRosTypeBosdynApiMsgsClawGripperCommandRequestConst.TRAJECTORY_FIELD_SET +
              IRosTypeBosdynApiMsgsClawGripperCommandRequestConst.MAXIMUM_OPEN_CLOSE_VELOCITY_FIELD_SET,
          },
          which:
            IRosTypeBosdynApiMsgsGripperCommandRequestOneOfCommandConst.COMMAND_CLAW_GRIPPER_COMMAND_SET,
        },
      },
      has_field: IRosTypeBosdynApiMsgsSynchronizedCommandRequestConst.GRIPPER_COMMAND_FIELD_SET,
    };

    return this._createRobotCommand(synchronized_command);
  }
}
