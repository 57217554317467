/**
 * Gamepad Buttons enumeration
 */
export enum Buttons {
  /**
   * Face 1 button (e.g. A/Bottom/Cross)
   */
  Face1 = 0,
  /**
   * Face 2 button (e.g. B/Right/Circle)
   */
  Face2 = 1,
  /**
   * Face 3 button (e.g. X/Left/Square)
   */
  Face3 = 2,
  /**
   * Face 4 button (e.g. Y/Top/Triangle)
   */
  Face4 = 3,
  /**
   * Left bumper button
   */
  LeftBumper = 4,
  /**
   * Right bumper button
   */
  RightBumper = 5,
  /**
   * Left trigger button
   */
  LeftTrigger = 6,
  /**
   * Right trigger button
   */
  RightTrigger = 7,
  /**
   * Select button
   */
  Select = 8,
  /**
   * Start button
   */
  Start = 9,
  /**
   * Left analog stick press (e.g. L3)
   */
  LeftStick = 10,
  /**
   * Right analog stick press (e.g. R3)
   */
  RightStick = 11,
  /**
   * D-pad up
   */
  DpadUp = 12,
  /**
   * D-pad down
   */
  DpadDown = 13,
  /**
   * D-pad left
   */
  DpadLeft = 14,
  /**
   * D-pad right
   */
  DpadRight = 15,
}

export enum DualSenseGamepadButton {
  X = Buttons.Face1,
  CIRCLE = Buttons.Face2,
  SQUARE = Buttons.Face3,
  TRIANGLE = Buttons.Face4,
  L1 = Buttons.LeftBumper,
  R1 = Buttons.RightBumper,
  L2 = Buttons.LeftTrigger,
  R2 = Buttons.RightTrigger,
  SHARE = Buttons.Select,
  OPTIONS = Buttons.Start,
  LS = Buttons.LeftStick,
  RS = Buttons.RightStick,
  D_PAD_UP = Buttons.DpadUp,
  D_PAD_DOWN = Buttons.DpadDown,
  D_PAD_LEFT = Buttons.DpadLeft,
  D_PAD_RIGHT = Buttons.DpadRight,
}

export enum DualSenseGamepadAxis {
  LS_HORIZONTAL = 0, // negative left/positive right
  LS_VERTICAL = 1, // negative up/positive down
  RS_HORIZONTAL = 2, // negative left/positive right
  RS_VERTICAL = 3, // negative up/positive down
}

export enum DualSenseGamepadStick {
  LS = 'LEFT_STICK',
  RS = 'RIGHT_STICK',
}

// todo - find a proper way to map the buttons
// why is everything Xbox related? can we not just use the Buttons?
export const XboxOneGamepadButton = {
  A: DualSenseGamepadButton.X,
  B: DualSenseGamepadButton.CIRCLE,
  X: DualSenseGamepadButton.SQUARE,
  Y: DualSenseGamepadButton.TRIANGLE,
  LB: DualSenseGamepadButton.L1,
  RB: DualSenseGamepadButton.R1,
  LT: DualSenseGamepadButton.L2,
  RT: DualSenseGamepadButton.R2,
  Back: DualSenseGamepadButton.SHARE,
  Start: DualSenseGamepadButton.OPTIONS,
  LSB: DualSenseGamepadButton.LS,
  RSB: DualSenseGamepadButton.RS,
  D_PAD_UP: DualSenseGamepadButton.D_PAD_UP,
  D_PAD_DOWN: DualSenseGamepadButton.D_PAD_DOWN,
  D_PAD_RIGHT: DualSenseGamepadButton.D_PAD_RIGHT,
  D_PAD_LEFT: DualSenseGamepadButton.D_PAD_LEFT,
} as const;

export type XboxOneGamepadButton = (typeof XboxOneGamepadButton)[keyof typeof XboxOneGamepadButton];

export enum XboxOneGamepadAxis {
  LSB_HORIZONTAL = DualSenseGamepadAxis.LS_HORIZONTAL,
  LSB_VERTICAL = DualSenseGamepadAxis.LS_VERTICAL,
  RSB_HORIZONTAL = DualSenseGamepadAxis.RS_HORIZONTAL,
  RSB_VERTICAL = DualSenseGamepadAxis.RS_VERTICAL,
}

export enum XboxOneGamepadStick {
  LSB = DualSenseGamepadStick.LS,
  RSB = DualSenseGamepadStick.RS,
}

export enum XboxOneGamepadStickAdditionalOption {
  LEFT_AND_RIGHT = 50,
  LSB_STOPPED = 51,
  RSB_STOPPED = 52,
}

export type GamepadStickValues = {
  x: number;
  y: number;
};

export type TGamepadButton = XboxOneGamepadButton | Buttons | DualSenseGamepadButton;

export const STICK_TRIGGER_SENSITIVITY = 0.25;
