import { spot_msgs, spot_ncb_rms_actions_interfaces } from 'rclnodejs';
import { useMemo } from 'react';

import { useDeviceName } from 'store/my-robot';

import { RosSignalR } from 'lib/roslibjs';

import { IRosTypeActionMsgsGoalStatusConst } from 'types/generated/ros-msgs';
import { DeviceName } from 'types/models';

import { useRosBridge } from './use-ros-bridge';

const ROS_ACTIONS = {
  OPEN_DOOR_FROM_IMAGE: (deviceName: DeviceName) => `/${deviceName}/open_door_from_image` as const,
  PICK_UP_OBJECT: (deviceName: DeviceName) => `/${deviceName}/pick_up_object` as const,
  REMOTE_MISSION_SERVICE: (deviceName: DeviceName) => `/${deviceName}/rms/call_service` as const,
} as const;

export type RosActionName = ReturnType<(typeof ROS_ACTIONS)[keyof typeof ROS_ACTIONS]>;

// Note: find a bedder naming as "caller"
class RosActionsCaller {
  private readonly ros: RosSignalR;
  private readonly deviceName: DeviceName;

  constructor(ros: RosSignalR, deviceName: DeviceName) {
    this.ros = ros;
    this.deviceName = deviceName;
  }

  parseResultStatus(status: number) {
    if (
      Object.values(IRosTypeActionMsgsGoalStatusConst).includes(
        status as IRosTypeActionMsgsGoalStatusConst,
      )
    ) {
      return status as IRosTypeActionMsgsGoalStatusConst;
    } else {
      return IRosTypeActionMsgsGoalStatusConst.STATUS_UNKNOWN;
    }
  }

  PickUpObject(
    goal: spot_msgs.action.PickUpObject_Goal,
    feedbackCallback: (f: spot_msgs.action.PickUpObject_Feedback) => void,
  ) {
    const name = ROS_ACTIONS.PICK_UP_OBJECT(this.deviceName);
    return this.ros.callAction<
      spot_msgs.action.PickUpObject_Goal,
      spot_msgs.action.PickUpObject_Feedback,
      spot_msgs.action.PickUpObject_GetResult_Response
    >(name, 'spot_msgs/action/PickUpObject', goal, feedbackCallback);
  }

  async OpenDoorFromImage(
    goal: spot_msgs.action.OpenDoorFromImage_Goal,
    feedbackCallback: (f: spot_msgs.action.OpenDoorFromImage_Feedback) => void,
  ) {
    const name = ROS_ACTIONS.OPEN_DOOR_FROM_IMAGE(this.deviceName);
    const response = await this.ros.callAction<
      spot_msgs.action.OpenDoorFromImage_Goal,
      spot_msgs.action.OpenDoorFromImage_Feedback,
      spot_msgs.action.OpenDoorFromImage_GetResult_Response
    >(name, 'spot_msgs/action/OpenDoorFromImage', goal, feedbackCallback);
    return { ...response, status: this.parseResultStatus(response.status) };
  }

  RemoteMissionService(
    goal: spot_ncb_rms_actions_interfaces.action.RemoteMissionService_Goal,
    feedbackCallback: (
      f: spot_ncb_rms_actions_interfaces.action.RemoteMissionService_Feedback,
    ) => void,
  ) {
    const name = ROS_ACTIONS.REMOTE_MISSION_SERVICE(this.deviceName);
    return this.ros.callAction<
      spot_ncb_rms_actions_interfaces.action.RemoteMissionService_Goal,
      spot_ncb_rms_actions_interfaces.action.RemoteMissionService_Feedback,
      spot_ncb_rms_actions_interfaces.action.RemoteMissionService_GetResult_Response
    >(name, 'spot_ncb_rms_actions_interfaces/action/RemoteMissionService', goal, feedbackCallback);
  }
}

/**
 * Todo: check if we merge this with "useRos"
 */
export function useRosAction() {
  const { ros } = useRosBridge();
  const { deviceName } = useDeviceName();

  const RosActions = useMemo(() => new RosActionsCaller(ros, deviceName), [deviceName]);

  return { RosActions };
}
