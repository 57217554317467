/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Any } from "../../google/protobuf/any";
import { Struct } from "../../google/protobuf/struct";
import { AlertData } from "./alerts";
import { RequestHeader, ResponseHeader } from "./header";
import { Image, ImageCaptureAndSource, ImageRequest, ImageResponse } from "./image";
import { CustomParamError, DictParam, DictParam_Spec } from "./service_customization";
import { WorldObject } from "./world_object";

export const protobufPackage = "bosdyn.api";

export enum NetworkComputeStatus {
  /** NETWORK_COMPUTE_STATUS_UNKNOWN - Status is not specified. */
  NETWORK_COMPUTE_STATUS_UNKNOWN = 0,
  /** NETWORK_COMPUTE_STATUS_SUCCESS - Succeeded. */
  NETWORK_COMPUTE_STATUS_SUCCESS = 1,
  /** NETWORK_COMPUTE_STATUS_EXTERNAL_SERVICE_NOT_FOUND - External service not found in the robot's directory. */
  NETWORK_COMPUTE_STATUS_EXTERNAL_SERVICE_NOT_FOUND = 2,
  /** NETWORK_COMPUTE_STATUS_EXTERNAL_SERVER_ERROR - The call to the external server did not succeed. */
  NETWORK_COMPUTE_STATUS_EXTERNAL_SERVER_ERROR = 3,
  /** NETWORK_COMPUTE_STATUS_ROTATION_ERROR - The robot failed to rotate the image as requested. */
  NETWORK_COMPUTE_STATUS_ROTATION_ERROR = 4,
  /**
   * NETWORK_COMPUTE_STATUS_CUSTOM_PARAMS_ERROR - One or more keys or values in the input custom_params are unsupported by the service.
   * See the custom_param_error for details.
   */
  NETWORK_COMPUTE_STATUS_CUSTOM_PARAMS_ERROR = 5,
  /**
   * NETWORK_COMPUTE_STATUS_ANALYSIS_FAILED - Transient error to indicate that the model failed to analyze the set of input images, but a
   * retry might work.
   */
  NETWORK_COMPUTE_STATUS_ANALYSIS_FAILED = 6,
  UNRECOGNIZED = -1,
}

export function networkComputeStatusFromJSON(object: any): NetworkComputeStatus {
  switch (object) {
    case 0:
    case "NETWORK_COMPUTE_STATUS_UNKNOWN":
      return NetworkComputeStatus.NETWORK_COMPUTE_STATUS_UNKNOWN;
    case 1:
    case "NETWORK_COMPUTE_STATUS_SUCCESS":
      return NetworkComputeStatus.NETWORK_COMPUTE_STATUS_SUCCESS;
    case 2:
    case "NETWORK_COMPUTE_STATUS_EXTERNAL_SERVICE_NOT_FOUND":
      return NetworkComputeStatus.NETWORK_COMPUTE_STATUS_EXTERNAL_SERVICE_NOT_FOUND;
    case 3:
    case "NETWORK_COMPUTE_STATUS_EXTERNAL_SERVER_ERROR":
      return NetworkComputeStatus.NETWORK_COMPUTE_STATUS_EXTERNAL_SERVER_ERROR;
    case 4:
    case "NETWORK_COMPUTE_STATUS_ROTATION_ERROR":
      return NetworkComputeStatus.NETWORK_COMPUTE_STATUS_ROTATION_ERROR;
    case 5:
    case "NETWORK_COMPUTE_STATUS_CUSTOM_PARAMS_ERROR":
      return NetworkComputeStatus.NETWORK_COMPUTE_STATUS_CUSTOM_PARAMS_ERROR;
    case 6:
    case "NETWORK_COMPUTE_STATUS_ANALYSIS_FAILED":
      return NetworkComputeStatus.NETWORK_COMPUTE_STATUS_ANALYSIS_FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NetworkComputeStatus.UNRECOGNIZED;
  }
}

export function networkComputeStatusToJSON(object: NetworkComputeStatus): string {
  switch (object) {
    case NetworkComputeStatus.NETWORK_COMPUTE_STATUS_UNKNOWN:
      return "NETWORK_COMPUTE_STATUS_UNKNOWN";
    case NetworkComputeStatus.NETWORK_COMPUTE_STATUS_SUCCESS:
      return "NETWORK_COMPUTE_STATUS_SUCCESS";
    case NetworkComputeStatus.NETWORK_COMPUTE_STATUS_EXTERNAL_SERVICE_NOT_FOUND:
      return "NETWORK_COMPUTE_STATUS_EXTERNAL_SERVICE_NOT_FOUND";
    case NetworkComputeStatus.NETWORK_COMPUTE_STATUS_EXTERNAL_SERVER_ERROR:
      return "NETWORK_COMPUTE_STATUS_EXTERNAL_SERVER_ERROR";
    case NetworkComputeStatus.NETWORK_COMPUTE_STATUS_ROTATION_ERROR:
      return "NETWORK_COMPUTE_STATUS_ROTATION_ERROR";
    case NetworkComputeStatus.NETWORK_COMPUTE_STATUS_CUSTOM_PARAMS_ERROR:
      return "NETWORK_COMPUTE_STATUS_CUSTOM_PARAMS_ERROR";
    case NetworkComputeStatus.NETWORK_COMPUTE_STATUS_ANALYSIS_FAILED:
      return "NETWORK_COMPUTE_STATUS_ANALYSIS_FAILED";
    case NetworkComputeStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ListAvailableModelsStatus {
  /** LIST_AVAILABLE_MODELS_STATUS_UNKNOWN - Status is not specified. */
  LIST_AVAILABLE_MODELS_STATUS_UNKNOWN = 0,
  /** LIST_AVAILABLE_MODELS_STATUS_SUCCESS - Succeeded. */
  LIST_AVAILABLE_MODELS_STATUS_SUCCESS = 1,
  /** LIST_AVAILABLE_MODELS_STATUS_EXTERNAL_SERVICE_NOT_FOUND - External service not found in the robot's directory. */
  LIST_AVAILABLE_MODELS_STATUS_EXTERNAL_SERVICE_NOT_FOUND = 2,
  /** LIST_AVAILABLE_MODELS_STATUS_EXTERNAL_SERVER_ERROR - The call to the external server did not succeed. */
  LIST_AVAILABLE_MODELS_STATUS_EXTERNAL_SERVER_ERROR = 3,
  UNRECOGNIZED = -1,
}

export function listAvailableModelsStatusFromJSON(object: any): ListAvailableModelsStatus {
  switch (object) {
    case 0:
    case "LIST_AVAILABLE_MODELS_STATUS_UNKNOWN":
      return ListAvailableModelsStatus.LIST_AVAILABLE_MODELS_STATUS_UNKNOWN;
    case 1:
    case "LIST_AVAILABLE_MODELS_STATUS_SUCCESS":
      return ListAvailableModelsStatus.LIST_AVAILABLE_MODELS_STATUS_SUCCESS;
    case 2:
    case "LIST_AVAILABLE_MODELS_STATUS_EXTERNAL_SERVICE_NOT_FOUND":
      return ListAvailableModelsStatus.LIST_AVAILABLE_MODELS_STATUS_EXTERNAL_SERVICE_NOT_FOUND;
    case 3:
    case "LIST_AVAILABLE_MODELS_STATUS_EXTERNAL_SERVER_ERROR":
      return ListAvailableModelsStatus.LIST_AVAILABLE_MODELS_STATUS_EXTERNAL_SERVER_ERROR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ListAvailableModelsStatus.UNRECOGNIZED;
  }
}

export function listAvailableModelsStatusToJSON(object: ListAvailableModelsStatus): string {
  switch (object) {
    case ListAvailableModelsStatus.LIST_AVAILABLE_MODELS_STATUS_UNKNOWN:
      return "LIST_AVAILABLE_MODELS_STATUS_UNKNOWN";
    case ListAvailableModelsStatus.LIST_AVAILABLE_MODELS_STATUS_SUCCESS:
      return "LIST_AVAILABLE_MODELS_STATUS_SUCCESS";
    case ListAvailableModelsStatus.LIST_AVAILABLE_MODELS_STATUS_EXTERNAL_SERVICE_NOT_FOUND:
      return "LIST_AVAILABLE_MODELS_STATUS_EXTERNAL_SERVICE_NOT_FOUND";
    case ListAvailableModelsStatus.LIST_AVAILABLE_MODELS_STATUS_EXTERNAL_SERVER_ERROR:
      return "LIST_AVAILABLE_MODELS_STATUS_EXTERNAL_SERVER_ERROR";
    case ListAvailableModelsStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ListAvailableModelsRequest {
  /** Common request header */
  header:
    | RequestHeader
    | undefined;
  /** Configuration about which server to use. */
  serverConfig: NetworkComputeServerConfiguration | undefined;
}

export interface ListAvailableModelsResponse {
  /** Common response header. */
  header:
    | ResponseHeader
    | undefined;
  /**
   * Provide list of available models.
   * DEPRECATED as of 3.3. Replaced by AvailableModels.
   *
   * @deprecated
   */
  availableModels: string[];
  /**
   * Optional information about available classes for each model
   * DEPRECATED as of 3.3. Replaced by AvailableModels.
   *
   * @deprecated
   */
  labels: ModelLabels[];
  /** Envelope message for repeated ModelData. */
  models:
    | AvailableModels
    | undefined;
  /** Command status */
  status: ListAvailableModelsStatus;
}

export interface AvailableModels {
  /** Contains model name, class labels, and per-model parameters. */
  data: ModelData[];
}

export interface ModelData {
  /** The model name used should match a name specified here. */
  modelName: string;
  /** List of class labels returned by this model (optional). */
  availableLabels: string[];
  /**
   * An ordered list of what types of outputs this NCB model generates.
   * This can be used by clients to determine which NCB images to display first
   * at action configuration time.
   *
   * It's ok for the NCB worker to return a different set of outputs (more / fewer)
   * than specified here.
   */
  outputImageSpec: OutputImageSpec[];
  /** Per-model parameters. */
  customParams: DictParam_Spec | undefined;
}

export interface ModelLabels {
  /** Model name. */
  modelName: string;
  /** List of class labels returned by this model. */
  availableLabels: string[];
}

export interface NetworkComputeRequest {
  /** Common request header. */
  header:
    | RequestHeader
    | undefined;
  /**
   * Input data.
   * DEPRECATED as of 3.3, use input_data_bridge instead.
   *
   * @deprecated
   */
  inputData?: NetworkComputeInputData | undefined;
  inputDataBridge?:
    | NetworkComputeInputDataBridge
    | undefined;
  /** Configuration about which server to use. */
  serverConfig: NetworkComputeServerConfiguration | undefined;
}

export interface ImageSourceAndService {
  /**
   * When only an image source is specified, network compute bridge will choose default values
   * for other request options.
   */
  imageSource?:
    | string
    | undefined;
  /** A full image request with the image source name as well as other options. */
  imageRequest?:
    | ImageRequest
    | undefined;
  /** Image service.  If blank, it is assumed to be the robot's default image service. */
  imageService: string;
}

/** @deprecated */
export interface OutputData {
  /** Optional metadata related to this image/region. */
  metadata:
    | { [key: string]: any }
    | undefined;
  /**
   * Optional detection information. May include bounding boxes, image coordinates,
   * 3D pose information, etc.
   */
  objectInImage: WorldObject[];
  /** Optional alert related to this image/region. */
  alertData:
    | AlertData
    | undefined;
  /** Optional data of a custom type. */
  otherData: Any | undefined;
}

export interface ComputeParameters {
  /**
   * Other data that isn't an image.  NetworkComputeBridge service will pass it through
   * to the remote server so you can do computation on arbitrary data.
   */
  otherData:
    | Any
    | undefined;
  /** Name of the model to be run on the input data. */
  modelName: string;
  /**
   * For some computer vision operations a number of reference images are required along with the
   * input image.  These images might have been taken months ago, not neccessarilly taken right
   * now.
   */
  referenceImages: ImageCaptureAndSource[];
  /** Input parameters unique to this worker that do not match any of the above fields. */
  customParams: DictParam | undefined;
}

/**
 * The network compute bridge will receive this input data, and convert it to a
 * [NetworkComputeInputDataWorker] before sending an RPC to the network compute bridge worker.
 */
export interface NetworkComputeInputDataBridge {
  /**
   * Image sources to collect a number of images from.  The ImageResponses
   * will populate the [images] field in [NetworkComputeInputDataWorker].
   */
  imageSourcesAndServices: ImageSourceAndService[];
  parameters: ComputeParameters | undefined;
}

/** The network compute bridge worker will receive this input data. */
export interface NetworkComputeInputDataWorker {
  /**
   * Live images (usually) filled out by NetworkComputeBridge right before NCB worker
   * is called.
   */
  images: ImageCaptureAndSource[];
  /** Input data. */
  parameters: ComputeParameters | undefined;
}

/** @deprecated */
export interface NetworkComputeInputData {
  /** Image source to collect an image from. */
  imageSourceAndService?:
    | ImageSourceAndService
    | undefined;
  /** Image to process, if you are not using an image source. */
  image?:
    | Image
    | undefined;
  /**
   * Other data that isn't an image.  NetworkComputeBridge service will pass it through
   * to the remote server so you can do computation on arbitrary data.
   */
  otherData:
    | Any
    | undefined;
  /** Name of the model to be run on the input data. */
  modelName: string;
  /**
   * Minimum confidence [0.0 - 1.0] an object must have to be returned. Detections below this
   * confidence threshold will be suppressed in the response.
   */
  minConfidence: number;
  /**
   * Options for rotating the image before processing. When unset, no rotation is applied.
   * Rotation is supported for data from image services that provide a FrameTreeSnapshot
   * defining the sensor's frame with respect to Spot's body and vision frames.
   * Field is ignored for non-image input.
   * DEPRECATED as of 3.3. Please rotate the image client-side before passing it to NCB worker.
   */
  rotateImage: NetworkComputeInputData_RotateImage;
}

export enum NetworkComputeInputData_RotateImage {
  /** ROTATE_IMAGE_UNKNOWN - Unspecified rotation method. Do not use. */
  ROTATE_IMAGE_UNKNOWN = 0,
  /** ROTATE_IMAGE_NO_ROTATION - No rotation applied. */
  ROTATE_IMAGE_NO_ROTATION = 3,
  /** ROTATE_IMAGE_ALIGN_HORIZONTAL - Rotate the images so the horizon is not rolled with respect to gravity. */
  ROTATE_IMAGE_ALIGN_HORIZONTAL = 1,
  /**
   * ROTATE_IMAGE_ALIGN_WITH_BODY - Rotate the images so that the horizon in the image is aligned with the inclination of
   * the body. For example, when applied to the left body camera this option rotates the image
   * so that the world does not appear upside down when the robot is standing upright, but if
   * the body is pitched up, the image will appear rotated.
   */
  ROTATE_IMAGE_ALIGN_WITH_BODY = 2,
  UNRECOGNIZED = -1,
}

export function networkComputeInputData_RotateImageFromJSON(object: any): NetworkComputeInputData_RotateImage {
  switch (object) {
    case 0:
    case "ROTATE_IMAGE_UNKNOWN":
      return NetworkComputeInputData_RotateImage.ROTATE_IMAGE_UNKNOWN;
    case 3:
    case "ROTATE_IMAGE_NO_ROTATION":
      return NetworkComputeInputData_RotateImage.ROTATE_IMAGE_NO_ROTATION;
    case 1:
    case "ROTATE_IMAGE_ALIGN_HORIZONTAL":
      return NetworkComputeInputData_RotateImage.ROTATE_IMAGE_ALIGN_HORIZONTAL;
    case 2:
    case "ROTATE_IMAGE_ALIGN_WITH_BODY":
      return NetworkComputeInputData_RotateImage.ROTATE_IMAGE_ALIGN_WITH_BODY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NetworkComputeInputData_RotateImage.UNRECOGNIZED;
  }
}

export function networkComputeInputData_RotateImageToJSON(object: NetworkComputeInputData_RotateImage): string {
  switch (object) {
    case NetworkComputeInputData_RotateImage.ROTATE_IMAGE_UNKNOWN:
      return "ROTATE_IMAGE_UNKNOWN";
    case NetworkComputeInputData_RotateImage.ROTATE_IMAGE_NO_ROTATION:
      return "ROTATE_IMAGE_NO_ROTATION";
    case NetworkComputeInputData_RotateImage.ROTATE_IMAGE_ALIGN_HORIZONTAL:
      return "ROTATE_IMAGE_ALIGN_HORIZONTAL";
    case NetworkComputeInputData_RotateImage.ROTATE_IMAGE_ALIGN_WITH_BODY:
      return "ROTATE_IMAGE_ALIGN_WITH_BODY";
    case NetworkComputeInputData_RotateImage.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface NetworkComputeServerConfiguration {
  /** Service name in the robot's Directory for the worker that will process the request. */
  serviceName: string;
}

export interface OutputImage {
  /** Annotated image showing process/end results. */
  imageResponse:
    | ImageResponse
    | undefined;
  /** Optional metadata related to this image. */
  metadata:
    | { [key: string]: any }
    | undefined;
  /**
   * Optional detection information. May include bounding boxes, image coordinates,
   * 3D pose information, etc.
   */
  objectInImage: WorldObject[];
  /** Optional alert related to this image. */
  alertData: AlertData | undefined;
}

/** Information about the output of an NCB worker. */
export interface OutputImageSpec {
  /** This string corresponds to the key in `output_images` */
  key: string;
  /** A human readable name for this output_image. */
  name: string;
}

export interface NetworkComputeResponse {
  /** Common response header. */
  header:
    | ResponseHeader
    | undefined;
  /**
   * Detection information. May include bounding boxes, image coordinates, 3D pose information,
   * etc.
   * DEPRECATED as of 4.0. Use object_in_image field in OutputImage instead.
   *
   * @deprecated
   */
  objectInImage: WorldObject[];
  /**
   * The image we computed the data on. If the input image itself was provided in the request,
   * this field is not populated.  This field is not set for non-image input.
   * DEPRECATED as of 3.3. Use image_responses instead.
   *
   * @deprecated
   */
  imageResponse:
    | ImageResponse
    | undefined;
  /** The image we computed the data on. This field is not set for non-image input. */
  imageResponses: ImageCaptureAndSource[];
  /**
   * If the image was rotated for processing, this field will contain the amount it was rotated by
   * (counter-clockwise, in radians).
   *
   * Note that the image returned is *not* rotated, regardless of if it was rotated
   * for processing.  This ensures that all other calibration and metadata remains valid.
   * DEPRECATED as of 3.3.  Please rotate the image client-side before passing it to NCB worker.
   *
   * @deprecated
   */
  imageRotationAngle: number;
  /** Non image-type data that can optionally be returned by a remote server. */
  otherData:
    | Any
    | undefined;
  /** Command status */
  status: NetworkComputeStatus;
  /** Filled out if status is NETWORK_COMPUTE_STATUS_CUSTOM_PARAMS_ERROR. */
  customParamError:
    | CustomParamError
    | undefined;
  /**
   * Optional field to indicate an alert detected by this model.
   *
   * Note that this alert will be reported for this entire response (including all output images).
   * If you have multiple output images and only want to alert on a specific image,
   * use the alert_data field in the associated OutputImage message.
   * DEPRECATED as of 3.3. Use alert_data in OutputImage instead.
   *
   * @deprecated
   */
  alertData:
    | AlertData
    | undefined;
  /**
   * Optional field to output images generated by this model.
   * Maps name to OutputImage.
   */
  outputImages: { [key: string]: OutputImage };
  /**
   * Information computed about the regions of interest by the worker.
   * DEPRECATED as of 3.3. Support for non-image products will be added in the future.
   *
   * @deprecated
   */
  roiOutputData: { [key: string]: OutputData };
}

export interface NetworkComputeResponse_OutputImagesEntry {
  key: string;
  value: OutputImage | undefined;
}

export interface NetworkComputeResponse_RoiOutputDataEntry {
  key: string;
  value: OutputData | undefined;
}

export interface WorkerComputeRequest {
  /** Common request header. */
  header: RequestHeader | undefined;
  inputData: NetworkComputeInputDataWorker | undefined;
}

export interface WorkerComputeResponse {
  /** Common response header. */
  header:
    | ResponseHeader
    | undefined;
  /** Non image-type data that can optionally be returned by a remote server. */
  otherData:
    | Any
    | undefined;
  /** Command status */
  status: NetworkComputeStatus;
  /** Filled out if status is NETWORK_COMPUTE_STATUS_CUSTOM_PARAMS_ERROR. */
  customParamError:
    | CustomParamError
    | undefined;
  /** Output images generated by this model. */
  outputImages: { [key: string]: OutputImage };
}

export interface WorkerComputeResponse_OutputImagesEntry {
  key: string;
  value: OutputImage | undefined;
}

function createBaseListAvailableModelsRequest(): ListAvailableModelsRequest {
  return { header: undefined, serverConfig: undefined };
}

export const ListAvailableModelsRequest = {
  encode(message: ListAvailableModelsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      RequestHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    if (message.serverConfig !== undefined) {
      NetworkComputeServerConfiguration.encode(message.serverConfig, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAvailableModelsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAvailableModelsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = RequestHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serverConfig = NetworkComputeServerConfiguration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAvailableModelsRequest {
    return {
      header: isSet(object.header) ? RequestHeader.fromJSON(object.header) : undefined,
      serverConfig: isSet(object.serverConfig)
        ? NetworkComputeServerConfiguration.fromJSON(object.serverConfig)
        : undefined,
    };
  },

  toJSON(message: ListAvailableModelsRequest): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = RequestHeader.toJSON(message.header);
    }
    if (message.serverConfig !== undefined) {
      obj.serverConfig = NetworkComputeServerConfiguration.toJSON(message.serverConfig);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAvailableModelsRequest>, I>>(base?: I): ListAvailableModelsRequest {
    return ListAvailableModelsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAvailableModelsRequest>, I>>(object: I): ListAvailableModelsRequest {
    const message = createBaseListAvailableModelsRequest();
    message.header = (object.header !== undefined && object.header !== null)
      ? RequestHeader.fromPartial(object.header)
      : undefined;
    message.serverConfig = (object.serverConfig !== undefined && object.serverConfig !== null)
      ? NetworkComputeServerConfiguration.fromPartial(object.serverConfig)
      : undefined;
    return message;
  },
};

function createBaseListAvailableModelsResponse(): ListAvailableModelsResponse {
  return { header: undefined, availableModels: [], labels: [], models: undefined, status: 0 };
}

export const ListAvailableModelsResponse = {
  encode(message: ListAvailableModelsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      ResponseHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.availableModels) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.labels) {
      ModelLabels.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.models !== undefined) {
      AvailableModels.encode(message.models, writer.uint32(26).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(40).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAvailableModelsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAvailableModelsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = ResponseHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.availableModels.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.labels.push(ModelLabels.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.models = AvailableModels.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAvailableModelsResponse {
    return {
      header: isSet(object.header) ? ResponseHeader.fromJSON(object.header) : undefined,
      availableModels: globalThis.Array.isArray(object?.availableModels)
        ? object.availableModels.map((e: any) => globalThis.String(e))
        : [],
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => ModelLabels.fromJSON(e)) : [],
      models: isSet(object.models) ? AvailableModels.fromJSON(object.models) : undefined,
      status: isSet(object.status) ? listAvailableModelsStatusFromJSON(object.status) : 0,
    };
  },

  toJSON(message: ListAvailableModelsResponse): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = ResponseHeader.toJSON(message.header);
    }
    if (message.availableModels?.length) {
      obj.availableModels = message.availableModels;
    }
    if (message.labels?.length) {
      obj.labels = message.labels.map((e) => ModelLabels.toJSON(e));
    }
    if (message.models !== undefined) {
      obj.models = AvailableModels.toJSON(message.models);
    }
    if (message.status !== 0) {
      obj.status = listAvailableModelsStatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAvailableModelsResponse>, I>>(base?: I): ListAvailableModelsResponse {
    return ListAvailableModelsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAvailableModelsResponse>, I>>(object: I): ListAvailableModelsResponse {
    const message = createBaseListAvailableModelsResponse();
    message.header = (object.header !== undefined && object.header !== null)
      ? ResponseHeader.fromPartial(object.header)
      : undefined;
    message.availableModels = object.availableModels?.map((e) => e) || [];
    message.labels = object.labels?.map((e) => ModelLabels.fromPartial(e)) || [];
    message.models = (object.models !== undefined && object.models !== null)
      ? AvailableModels.fromPartial(object.models)
      : undefined;
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseAvailableModels(): AvailableModels {
  return { data: [] };
}

export const AvailableModels = {
  encode(message: AvailableModels, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.data) {
      ModelData.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AvailableModels {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAvailableModels();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data.push(ModelData.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AvailableModels {
    return { data: globalThis.Array.isArray(object?.data) ? object.data.map((e: any) => ModelData.fromJSON(e)) : [] };
  },

  toJSON(message: AvailableModels): unknown {
    const obj: any = {};
    if (message.data?.length) {
      obj.data = message.data.map((e) => ModelData.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AvailableModels>, I>>(base?: I): AvailableModels {
    return AvailableModels.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AvailableModels>, I>>(object: I): AvailableModels {
    const message = createBaseAvailableModels();
    message.data = object.data?.map((e) => ModelData.fromPartial(e)) || [];
    return message;
  },
};

function createBaseModelData(): ModelData {
  return { modelName: "", availableLabels: [], outputImageSpec: [], customParams: undefined };
}

export const ModelData = {
  encode(message: ModelData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modelName !== "") {
      writer.uint32(10).string(message.modelName);
    }
    for (const v of message.availableLabels) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.outputImageSpec) {
      OutputImageSpec.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.customParams !== undefined) {
      DictParam_Spec.encode(message.customParams, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModelData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModelData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modelName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.availableLabels.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.outputImageSpec.push(OutputImageSpec.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.customParams = DictParam_Spec.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModelData {
    return {
      modelName: isSet(object.modelName) ? globalThis.String(object.modelName) : "",
      availableLabels: globalThis.Array.isArray(object?.availableLabels)
        ? object.availableLabels.map((e: any) => globalThis.String(e))
        : [],
      outputImageSpec: globalThis.Array.isArray(object?.outputImageSpec)
        ? object.outputImageSpec.map((e: any) => OutputImageSpec.fromJSON(e))
        : [],
      customParams: isSet(object.customParams) ? DictParam_Spec.fromJSON(object.customParams) : undefined,
    };
  },

  toJSON(message: ModelData): unknown {
    const obj: any = {};
    if (message.modelName !== "") {
      obj.modelName = message.modelName;
    }
    if (message.availableLabels?.length) {
      obj.availableLabels = message.availableLabels;
    }
    if (message.outputImageSpec?.length) {
      obj.outputImageSpec = message.outputImageSpec.map((e) => OutputImageSpec.toJSON(e));
    }
    if (message.customParams !== undefined) {
      obj.customParams = DictParam_Spec.toJSON(message.customParams);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ModelData>, I>>(base?: I): ModelData {
    return ModelData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ModelData>, I>>(object: I): ModelData {
    const message = createBaseModelData();
    message.modelName = object.modelName ?? "";
    message.availableLabels = object.availableLabels?.map((e) => e) || [];
    message.outputImageSpec = object.outputImageSpec?.map((e) => OutputImageSpec.fromPartial(e)) || [];
    message.customParams = (object.customParams !== undefined && object.customParams !== null)
      ? DictParam_Spec.fromPartial(object.customParams)
      : undefined;
    return message;
  },
};

function createBaseModelLabels(): ModelLabels {
  return { modelName: "", availableLabels: [] };
}

export const ModelLabels = {
  encode(message: ModelLabels, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modelName !== "") {
      writer.uint32(10).string(message.modelName);
    }
    for (const v of message.availableLabels) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModelLabels {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModelLabels();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modelName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.availableLabels.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModelLabels {
    return {
      modelName: isSet(object.modelName) ? globalThis.String(object.modelName) : "",
      availableLabels: globalThis.Array.isArray(object?.availableLabels)
        ? object.availableLabels.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ModelLabels): unknown {
    const obj: any = {};
    if (message.modelName !== "") {
      obj.modelName = message.modelName;
    }
    if (message.availableLabels?.length) {
      obj.availableLabels = message.availableLabels;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ModelLabels>, I>>(base?: I): ModelLabels {
    return ModelLabels.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ModelLabels>, I>>(object: I): ModelLabels {
    const message = createBaseModelLabels();
    message.modelName = object.modelName ?? "";
    message.availableLabels = object.availableLabels?.map((e) => e) || [];
    return message;
  },
};

function createBaseNetworkComputeRequest(): NetworkComputeRequest {
  return { header: undefined, inputData: undefined, inputDataBridge: undefined, serverConfig: undefined };
}

export const NetworkComputeRequest = {
  encode(message: NetworkComputeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      RequestHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    if (message.inputData !== undefined) {
      NetworkComputeInputData.encode(message.inputData, writer.uint32(18).fork()).ldelim();
    }
    if (message.inputDataBridge !== undefined) {
      NetworkComputeInputDataBridge.encode(message.inputDataBridge, writer.uint32(34).fork()).ldelim();
    }
    if (message.serverConfig !== undefined) {
      NetworkComputeServerConfiguration.encode(message.serverConfig, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NetworkComputeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNetworkComputeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = RequestHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.inputData = NetworkComputeInputData.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.inputDataBridge = NetworkComputeInputDataBridge.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.serverConfig = NetworkComputeServerConfiguration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NetworkComputeRequest {
    return {
      header: isSet(object.header) ? RequestHeader.fromJSON(object.header) : undefined,
      inputData: isSet(object.inputData) ? NetworkComputeInputData.fromJSON(object.inputData) : undefined,
      inputDataBridge: isSet(object.inputDataBridge)
        ? NetworkComputeInputDataBridge.fromJSON(object.inputDataBridge)
        : undefined,
      serverConfig: isSet(object.serverConfig)
        ? NetworkComputeServerConfiguration.fromJSON(object.serverConfig)
        : undefined,
    };
  },

  toJSON(message: NetworkComputeRequest): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = RequestHeader.toJSON(message.header);
    }
    if (message.inputData !== undefined) {
      obj.inputData = NetworkComputeInputData.toJSON(message.inputData);
    }
    if (message.inputDataBridge !== undefined) {
      obj.inputDataBridge = NetworkComputeInputDataBridge.toJSON(message.inputDataBridge);
    }
    if (message.serverConfig !== undefined) {
      obj.serverConfig = NetworkComputeServerConfiguration.toJSON(message.serverConfig);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NetworkComputeRequest>, I>>(base?: I): NetworkComputeRequest {
    return NetworkComputeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NetworkComputeRequest>, I>>(object: I): NetworkComputeRequest {
    const message = createBaseNetworkComputeRequest();
    message.header = (object.header !== undefined && object.header !== null)
      ? RequestHeader.fromPartial(object.header)
      : undefined;
    message.inputData = (object.inputData !== undefined && object.inputData !== null)
      ? NetworkComputeInputData.fromPartial(object.inputData)
      : undefined;
    message.inputDataBridge = (object.inputDataBridge !== undefined && object.inputDataBridge !== null)
      ? NetworkComputeInputDataBridge.fromPartial(object.inputDataBridge)
      : undefined;
    message.serverConfig = (object.serverConfig !== undefined && object.serverConfig !== null)
      ? NetworkComputeServerConfiguration.fromPartial(object.serverConfig)
      : undefined;
    return message;
  },
};

function createBaseImageSourceAndService(): ImageSourceAndService {
  return { imageSource: undefined, imageRequest: undefined, imageService: "" };
}

export const ImageSourceAndService = {
  encode(message: ImageSourceAndService, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.imageSource !== undefined) {
      writer.uint32(10).string(message.imageSource);
    }
    if (message.imageRequest !== undefined) {
      ImageRequest.encode(message.imageRequest, writer.uint32(26).fork()).ldelim();
    }
    if (message.imageService !== "") {
      writer.uint32(18).string(message.imageService);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageSourceAndService {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageSourceAndService();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.imageSource = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.imageRequest = ImageRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.imageService = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageSourceAndService {
    return {
      imageSource: isSet(object.imageSource) ? globalThis.String(object.imageSource) : undefined,
      imageRequest: isSet(object.imageRequest) ? ImageRequest.fromJSON(object.imageRequest) : undefined,
      imageService: isSet(object.imageService) ? globalThis.String(object.imageService) : "",
    };
  },

  toJSON(message: ImageSourceAndService): unknown {
    const obj: any = {};
    if (message.imageSource !== undefined) {
      obj.imageSource = message.imageSource;
    }
    if (message.imageRequest !== undefined) {
      obj.imageRequest = ImageRequest.toJSON(message.imageRequest);
    }
    if (message.imageService !== "") {
      obj.imageService = message.imageService;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageSourceAndService>, I>>(base?: I): ImageSourceAndService {
    return ImageSourceAndService.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImageSourceAndService>, I>>(object: I): ImageSourceAndService {
    const message = createBaseImageSourceAndService();
    message.imageSource = object.imageSource ?? undefined;
    message.imageRequest = (object.imageRequest !== undefined && object.imageRequest !== null)
      ? ImageRequest.fromPartial(object.imageRequest)
      : undefined;
    message.imageService = object.imageService ?? "";
    return message;
  },
};

function createBaseOutputData(): OutputData {
  return { metadata: undefined, objectInImage: [], alertData: undefined, otherData: undefined };
}

export const OutputData = {
  encode(message: OutputData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.metadata !== undefined) {
      Struct.encode(Struct.wrap(message.metadata), writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.objectInImage) {
      WorldObject.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.alertData !== undefined) {
      AlertData.encode(message.alertData, writer.uint32(34).fork()).ldelim();
    }
    if (message.otherData !== undefined) {
      Any.encode(message.otherData, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OutputData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOutputData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.metadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.objectInImage.push(WorldObject.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.alertData = AlertData.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.otherData = Any.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OutputData {
    return {
      metadata: isObject(object.metadata) ? object.metadata : undefined,
      objectInImage: globalThis.Array.isArray(object?.objectInImage)
        ? object.objectInImage.map((e: any) => WorldObject.fromJSON(e))
        : [],
      alertData: isSet(object.alertData) ? AlertData.fromJSON(object.alertData) : undefined,
      otherData: isSet(object.otherData) ? Any.fromJSON(object.otherData) : undefined,
    };
  },

  toJSON(message: OutputData): unknown {
    const obj: any = {};
    if (message.metadata !== undefined) {
      obj.metadata = message.metadata;
    }
    if (message.objectInImage?.length) {
      obj.objectInImage = message.objectInImage.map((e) => WorldObject.toJSON(e));
    }
    if (message.alertData !== undefined) {
      obj.alertData = AlertData.toJSON(message.alertData);
    }
    if (message.otherData !== undefined) {
      obj.otherData = Any.toJSON(message.otherData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OutputData>, I>>(base?: I): OutputData {
    return OutputData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OutputData>, I>>(object: I): OutputData {
    const message = createBaseOutputData();
    message.metadata = object.metadata ?? undefined;
    message.objectInImage = object.objectInImage?.map((e) => WorldObject.fromPartial(e)) || [];
    message.alertData = (object.alertData !== undefined && object.alertData !== null)
      ? AlertData.fromPartial(object.alertData)
      : undefined;
    message.otherData = (object.otherData !== undefined && object.otherData !== null)
      ? Any.fromPartial(object.otherData)
      : undefined;
    return message;
  },
};

function createBaseComputeParameters(): ComputeParameters {
  return { otherData: undefined, modelName: "", referenceImages: [], customParams: undefined };
}

export const ComputeParameters = {
  encode(message: ComputeParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.otherData !== undefined) {
      Any.encode(message.otherData, writer.uint32(26).fork()).ldelim();
    }
    if (message.modelName !== "") {
      writer.uint32(34).string(message.modelName);
    }
    for (const v of message.referenceImages) {
      ImageCaptureAndSource.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.customParams !== undefined) {
      DictParam.encode(message.customParams, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ComputeParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseComputeParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.otherData = Any.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.modelName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.referenceImages.push(ImageCaptureAndSource.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.customParams = DictParam.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ComputeParameters {
    return {
      otherData: isSet(object.otherData) ? Any.fromJSON(object.otherData) : undefined,
      modelName: isSet(object.modelName) ? globalThis.String(object.modelName) : "",
      referenceImages: globalThis.Array.isArray(object?.referenceImages)
        ? object.referenceImages.map((e: any) => ImageCaptureAndSource.fromJSON(e))
        : [],
      customParams: isSet(object.customParams) ? DictParam.fromJSON(object.customParams) : undefined,
    };
  },

  toJSON(message: ComputeParameters): unknown {
    const obj: any = {};
    if (message.otherData !== undefined) {
      obj.otherData = Any.toJSON(message.otherData);
    }
    if (message.modelName !== "") {
      obj.modelName = message.modelName;
    }
    if (message.referenceImages?.length) {
      obj.referenceImages = message.referenceImages.map((e) => ImageCaptureAndSource.toJSON(e));
    }
    if (message.customParams !== undefined) {
      obj.customParams = DictParam.toJSON(message.customParams);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ComputeParameters>, I>>(base?: I): ComputeParameters {
    return ComputeParameters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ComputeParameters>, I>>(object: I): ComputeParameters {
    const message = createBaseComputeParameters();
    message.otherData = (object.otherData !== undefined && object.otherData !== null)
      ? Any.fromPartial(object.otherData)
      : undefined;
    message.modelName = object.modelName ?? "";
    message.referenceImages = object.referenceImages?.map((e) => ImageCaptureAndSource.fromPartial(e)) || [];
    message.customParams = (object.customParams !== undefined && object.customParams !== null)
      ? DictParam.fromPartial(object.customParams)
      : undefined;
    return message;
  },
};

function createBaseNetworkComputeInputDataBridge(): NetworkComputeInputDataBridge {
  return { imageSourcesAndServices: [], parameters: undefined };
}

export const NetworkComputeInputDataBridge = {
  encode(message: NetworkComputeInputDataBridge, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.imageSourcesAndServices) {
      ImageSourceAndService.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.parameters !== undefined) {
      ComputeParameters.encode(message.parameters, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NetworkComputeInputDataBridge {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNetworkComputeInputDataBridge();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.imageSourcesAndServices.push(ImageSourceAndService.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parameters = ComputeParameters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NetworkComputeInputDataBridge {
    return {
      imageSourcesAndServices: globalThis.Array.isArray(object?.imageSourcesAndServices)
        ? object.imageSourcesAndServices.map((e: any) => ImageSourceAndService.fromJSON(e))
        : [],
      parameters: isSet(object.parameters) ? ComputeParameters.fromJSON(object.parameters) : undefined,
    };
  },

  toJSON(message: NetworkComputeInputDataBridge): unknown {
    const obj: any = {};
    if (message.imageSourcesAndServices?.length) {
      obj.imageSourcesAndServices = message.imageSourcesAndServices.map((e) => ImageSourceAndService.toJSON(e));
    }
    if (message.parameters !== undefined) {
      obj.parameters = ComputeParameters.toJSON(message.parameters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NetworkComputeInputDataBridge>, I>>(base?: I): NetworkComputeInputDataBridge {
    return NetworkComputeInputDataBridge.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NetworkComputeInputDataBridge>, I>>(
    object: I,
  ): NetworkComputeInputDataBridge {
    const message = createBaseNetworkComputeInputDataBridge();
    message.imageSourcesAndServices =
      object.imageSourcesAndServices?.map((e) => ImageSourceAndService.fromPartial(e)) || [];
    message.parameters = (object.parameters !== undefined && object.parameters !== null)
      ? ComputeParameters.fromPartial(object.parameters)
      : undefined;
    return message;
  },
};

function createBaseNetworkComputeInputDataWorker(): NetworkComputeInputDataWorker {
  return { images: [], parameters: undefined };
}

export const NetworkComputeInputDataWorker = {
  encode(message: NetworkComputeInputDataWorker, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.images) {
      ImageCaptureAndSource.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.parameters !== undefined) {
      ComputeParameters.encode(message.parameters, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NetworkComputeInputDataWorker {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNetworkComputeInputDataWorker();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.images.push(ImageCaptureAndSource.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parameters = ComputeParameters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NetworkComputeInputDataWorker {
    return {
      images: globalThis.Array.isArray(object?.images)
        ? object.images.map((e: any) => ImageCaptureAndSource.fromJSON(e))
        : [],
      parameters: isSet(object.parameters) ? ComputeParameters.fromJSON(object.parameters) : undefined,
    };
  },

  toJSON(message: NetworkComputeInputDataWorker): unknown {
    const obj: any = {};
    if (message.images?.length) {
      obj.images = message.images.map((e) => ImageCaptureAndSource.toJSON(e));
    }
    if (message.parameters !== undefined) {
      obj.parameters = ComputeParameters.toJSON(message.parameters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NetworkComputeInputDataWorker>, I>>(base?: I): NetworkComputeInputDataWorker {
    return NetworkComputeInputDataWorker.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NetworkComputeInputDataWorker>, I>>(
    object: I,
  ): NetworkComputeInputDataWorker {
    const message = createBaseNetworkComputeInputDataWorker();
    message.images = object.images?.map((e) => ImageCaptureAndSource.fromPartial(e)) || [];
    message.parameters = (object.parameters !== undefined && object.parameters !== null)
      ? ComputeParameters.fromPartial(object.parameters)
      : undefined;
    return message;
  },
};

function createBaseNetworkComputeInputData(): NetworkComputeInputData {
  return {
    imageSourceAndService: undefined,
    image: undefined,
    otherData: undefined,
    modelName: "",
    minConfidence: 0,
    rotateImage: 0,
  };
}

export const NetworkComputeInputData = {
  encode(message: NetworkComputeInputData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.imageSourceAndService !== undefined) {
      ImageSourceAndService.encode(message.imageSourceAndService, writer.uint32(58).fork()).ldelim();
    }
    if (message.image !== undefined) {
      Image.encode(message.image, writer.uint32(18).fork()).ldelim();
    }
    if (message.otherData !== undefined) {
      Any.encode(message.otherData, writer.uint32(26).fork()).ldelim();
    }
    if (message.modelName !== "") {
      writer.uint32(34).string(message.modelName);
    }
    if (message.minConfidence !== 0) {
      writer.uint32(45).float(message.minConfidence);
    }
    if (message.rotateImage !== 0) {
      writer.uint32(48).int32(message.rotateImage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NetworkComputeInputData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNetworkComputeInputData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 7:
          if (tag !== 58) {
            break;
          }

          message.imageSourceAndService = ImageSourceAndService.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.image = Image.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.otherData = Any.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.modelName = reader.string();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.minConfidence = reader.float();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.rotateImage = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NetworkComputeInputData {
    return {
      imageSourceAndService: isSet(object.imageSourceAndService)
        ? ImageSourceAndService.fromJSON(object.imageSourceAndService)
        : undefined,
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
      otherData: isSet(object.otherData) ? Any.fromJSON(object.otherData) : undefined,
      modelName: isSet(object.modelName) ? globalThis.String(object.modelName) : "",
      minConfidence: isSet(object.minConfidence) ? globalThis.Number(object.minConfidence) : 0,
      rotateImage: isSet(object.rotateImage) ? networkComputeInputData_RotateImageFromJSON(object.rotateImage) : 0,
    };
  },

  toJSON(message: NetworkComputeInputData): unknown {
    const obj: any = {};
    if (message.imageSourceAndService !== undefined) {
      obj.imageSourceAndService = ImageSourceAndService.toJSON(message.imageSourceAndService);
    }
    if (message.image !== undefined) {
      obj.image = Image.toJSON(message.image);
    }
    if (message.otherData !== undefined) {
      obj.otherData = Any.toJSON(message.otherData);
    }
    if (message.modelName !== "") {
      obj.modelName = message.modelName;
    }
    if (message.minConfidence !== 0) {
      obj.minConfidence = message.minConfidence;
    }
    if (message.rotateImage !== 0) {
      obj.rotateImage = networkComputeInputData_RotateImageToJSON(message.rotateImage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NetworkComputeInputData>, I>>(base?: I): NetworkComputeInputData {
    return NetworkComputeInputData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NetworkComputeInputData>, I>>(object: I): NetworkComputeInputData {
    const message = createBaseNetworkComputeInputData();
    message.imageSourceAndService =
      (object.imageSourceAndService !== undefined && object.imageSourceAndService !== null)
        ? ImageSourceAndService.fromPartial(object.imageSourceAndService)
        : undefined;
    message.image = (object.image !== undefined && object.image !== null) ? Image.fromPartial(object.image) : undefined;
    message.otherData = (object.otherData !== undefined && object.otherData !== null)
      ? Any.fromPartial(object.otherData)
      : undefined;
    message.modelName = object.modelName ?? "";
    message.minConfidence = object.minConfidence ?? 0;
    message.rotateImage = object.rotateImage ?? 0;
    return message;
  },
};

function createBaseNetworkComputeServerConfiguration(): NetworkComputeServerConfiguration {
  return { serviceName: "" };
}

export const NetworkComputeServerConfiguration = {
  encode(message: NetworkComputeServerConfiguration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serviceName !== "") {
      writer.uint32(26).string(message.serviceName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NetworkComputeServerConfiguration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNetworkComputeServerConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.serviceName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NetworkComputeServerConfiguration {
    return { serviceName: isSet(object.serviceName) ? globalThis.String(object.serviceName) : "" };
  },

  toJSON(message: NetworkComputeServerConfiguration): unknown {
    const obj: any = {};
    if (message.serviceName !== "") {
      obj.serviceName = message.serviceName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NetworkComputeServerConfiguration>, I>>(
    base?: I,
  ): NetworkComputeServerConfiguration {
    return NetworkComputeServerConfiguration.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NetworkComputeServerConfiguration>, I>>(
    object: I,
  ): NetworkComputeServerConfiguration {
    const message = createBaseNetworkComputeServerConfiguration();
    message.serviceName = object.serviceName ?? "";
    return message;
  },
};

function createBaseOutputImage(): OutputImage {
  return { imageResponse: undefined, metadata: undefined, objectInImage: [], alertData: undefined };
}

export const OutputImage = {
  encode(message: OutputImage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.imageResponse !== undefined) {
      ImageResponse.encode(message.imageResponse, writer.uint32(10).fork()).ldelim();
    }
    if (message.metadata !== undefined) {
      Struct.encode(Struct.wrap(message.metadata), writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.objectInImage) {
      WorldObject.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.alertData !== undefined) {
      AlertData.encode(message.alertData, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OutputImage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOutputImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.imageResponse = ImageResponse.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.metadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.objectInImage.push(WorldObject.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.alertData = AlertData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OutputImage {
    return {
      imageResponse: isSet(object.imageResponse) ? ImageResponse.fromJSON(object.imageResponse) : undefined,
      metadata: isObject(object.metadata) ? object.metadata : undefined,
      objectInImage: globalThis.Array.isArray(object?.objectInImage)
        ? object.objectInImage.map((e: any) => WorldObject.fromJSON(e))
        : [],
      alertData: isSet(object.alertData) ? AlertData.fromJSON(object.alertData) : undefined,
    };
  },

  toJSON(message: OutputImage): unknown {
    const obj: any = {};
    if (message.imageResponse !== undefined) {
      obj.imageResponse = ImageResponse.toJSON(message.imageResponse);
    }
    if (message.metadata !== undefined) {
      obj.metadata = message.metadata;
    }
    if (message.objectInImage?.length) {
      obj.objectInImage = message.objectInImage.map((e) => WorldObject.toJSON(e));
    }
    if (message.alertData !== undefined) {
      obj.alertData = AlertData.toJSON(message.alertData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OutputImage>, I>>(base?: I): OutputImage {
    return OutputImage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OutputImage>, I>>(object: I): OutputImage {
    const message = createBaseOutputImage();
    message.imageResponse = (object.imageResponse !== undefined && object.imageResponse !== null)
      ? ImageResponse.fromPartial(object.imageResponse)
      : undefined;
    message.metadata = object.metadata ?? undefined;
    message.objectInImage = object.objectInImage?.map((e) => WorldObject.fromPartial(e)) || [];
    message.alertData = (object.alertData !== undefined && object.alertData !== null)
      ? AlertData.fromPartial(object.alertData)
      : undefined;
    return message;
  },
};

function createBaseOutputImageSpec(): OutputImageSpec {
  return { key: "", name: "" };
}

export const OutputImageSpec = {
  encode(message: OutputImageSpec, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OutputImageSpec {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOutputImageSpec();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OutputImageSpec {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: OutputImageSpec): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OutputImageSpec>, I>>(base?: I): OutputImageSpec {
    return OutputImageSpec.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OutputImageSpec>, I>>(object: I): OutputImageSpec {
    const message = createBaseOutputImageSpec();
    message.key = object.key ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseNetworkComputeResponse(): NetworkComputeResponse {
  return {
    header: undefined,
    objectInImage: [],
    imageResponse: undefined,
    imageResponses: [],
    imageRotationAngle: 0,
    otherData: undefined,
    status: 0,
    customParamError: undefined,
    alertData: undefined,
    outputImages: {},
    roiOutputData: {},
  };
}

export const NetworkComputeResponse = {
  encode(message: NetworkComputeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      ResponseHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.objectInImage) {
      WorldObject.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.imageResponse !== undefined) {
      ImageResponse.encode(message.imageResponse, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.imageResponses) {
      ImageCaptureAndSource.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    if (message.imageRotationAngle !== 0) {
      writer.uint32(49).double(message.imageRotationAngle);
    }
    if (message.otherData !== undefined) {
      Any.encode(message.otherData, writer.uint32(34).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(40).int32(message.status);
    }
    if (message.customParamError !== undefined) {
      CustomParamError.encode(message.customParamError, writer.uint32(90).fork()).ldelim();
    }
    if (message.alertData !== undefined) {
      AlertData.encode(message.alertData, writer.uint32(58).fork()).ldelim();
    }
    Object.entries(message.outputImages).forEach(([key, value]) => {
      NetworkComputeResponse_OutputImagesEntry.encode({ key: key as any, value }, writer.uint32(66).fork()).ldelim();
    });
    Object.entries(message.roiOutputData).forEach(([key, value]) => {
      NetworkComputeResponse_RoiOutputDataEntry.encode({ key: key as any, value }, writer.uint32(82).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NetworkComputeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNetworkComputeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = ResponseHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.objectInImage.push(WorldObject.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.imageResponse = ImageResponse.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.imageResponses.push(ImageCaptureAndSource.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.imageRotationAngle = reader.double();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.otherData = Any.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.customParamError = CustomParamError.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.alertData = AlertData.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          const entry8 = NetworkComputeResponse_OutputImagesEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.outputImages[entry8.key] = entry8.value;
          }
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          const entry10 = NetworkComputeResponse_RoiOutputDataEntry.decode(reader, reader.uint32());
          if (entry10.value !== undefined) {
            message.roiOutputData[entry10.key] = entry10.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NetworkComputeResponse {
    return {
      header: isSet(object.header) ? ResponseHeader.fromJSON(object.header) : undefined,
      objectInImage: globalThis.Array.isArray(object?.objectInImage)
        ? object.objectInImage.map((e: any) => WorldObject.fromJSON(e))
        : [],
      imageResponse: isSet(object.imageResponse) ? ImageResponse.fromJSON(object.imageResponse) : undefined,
      imageResponses: globalThis.Array.isArray(object?.imageResponses)
        ? object.imageResponses.map((e: any) => ImageCaptureAndSource.fromJSON(e))
        : [],
      imageRotationAngle: isSet(object.imageRotationAngle) ? globalThis.Number(object.imageRotationAngle) : 0,
      otherData: isSet(object.otherData) ? Any.fromJSON(object.otherData) : undefined,
      status: isSet(object.status) ? networkComputeStatusFromJSON(object.status) : 0,
      customParamError: isSet(object.customParamError) ? CustomParamError.fromJSON(object.customParamError) : undefined,
      alertData: isSet(object.alertData) ? AlertData.fromJSON(object.alertData) : undefined,
      outputImages: isObject(object.outputImages)
        ? Object.entries(object.outputImages).reduce<{ [key: string]: OutputImage }>((acc, [key, value]) => {
          acc[key] = OutputImage.fromJSON(value);
          return acc;
        }, {})
        : {},
      roiOutputData: isObject(object.roiOutputData)
        ? Object.entries(object.roiOutputData).reduce<{ [key: string]: OutputData }>((acc, [key, value]) => {
          acc[key] = OutputData.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: NetworkComputeResponse): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = ResponseHeader.toJSON(message.header);
    }
    if (message.objectInImage?.length) {
      obj.objectInImage = message.objectInImage.map((e) => WorldObject.toJSON(e));
    }
    if (message.imageResponse !== undefined) {
      obj.imageResponse = ImageResponse.toJSON(message.imageResponse);
    }
    if (message.imageResponses?.length) {
      obj.imageResponses = message.imageResponses.map((e) => ImageCaptureAndSource.toJSON(e));
    }
    if (message.imageRotationAngle !== 0) {
      obj.imageRotationAngle = message.imageRotationAngle;
    }
    if (message.otherData !== undefined) {
      obj.otherData = Any.toJSON(message.otherData);
    }
    if (message.status !== 0) {
      obj.status = networkComputeStatusToJSON(message.status);
    }
    if (message.customParamError !== undefined) {
      obj.customParamError = CustomParamError.toJSON(message.customParamError);
    }
    if (message.alertData !== undefined) {
      obj.alertData = AlertData.toJSON(message.alertData);
    }
    if (message.outputImages) {
      const entries = Object.entries(message.outputImages);
      if (entries.length > 0) {
        obj.outputImages = {};
        entries.forEach(([k, v]) => {
          obj.outputImages[k] = OutputImage.toJSON(v);
        });
      }
    }
    if (message.roiOutputData) {
      const entries = Object.entries(message.roiOutputData);
      if (entries.length > 0) {
        obj.roiOutputData = {};
        entries.forEach(([k, v]) => {
          obj.roiOutputData[k] = OutputData.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NetworkComputeResponse>, I>>(base?: I): NetworkComputeResponse {
    return NetworkComputeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NetworkComputeResponse>, I>>(object: I): NetworkComputeResponse {
    const message = createBaseNetworkComputeResponse();
    message.header = (object.header !== undefined && object.header !== null)
      ? ResponseHeader.fromPartial(object.header)
      : undefined;
    message.objectInImage = object.objectInImage?.map((e) => WorldObject.fromPartial(e)) || [];
    message.imageResponse = (object.imageResponse !== undefined && object.imageResponse !== null)
      ? ImageResponse.fromPartial(object.imageResponse)
      : undefined;
    message.imageResponses = object.imageResponses?.map((e) => ImageCaptureAndSource.fromPartial(e)) || [];
    message.imageRotationAngle = object.imageRotationAngle ?? 0;
    message.otherData = (object.otherData !== undefined && object.otherData !== null)
      ? Any.fromPartial(object.otherData)
      : undefined;
    message.status = object.status ?? 0;
    message.customParamError = (object.customParamError !== undefined && object.customParamError !== null)
      ? CustomParamError.fromPartial(object.customParamError)
      : undefined;
    message.alertData = (object.alertData !== undefined && object.alertData !== null)
      ? AlertData.fromPartial(object.alertData)
      : undefined;
    message.outputImages = Object.entries(object.outputImages ?? {}).reduce<{ [key: string]: OutputImage }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = OutputImage.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.roiOutputData = Object.entries(object.roiOutputData ?? {}).reduce<{ [key: string]: OutputData }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = OutputData.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseNetworkComputeResponse_OutputImagesEntry(): NetworkComputeResponse_OutputImagesEntry {
  return { key: "", value: undefined };
}

export const NetworkComputeResponse_OutputImagesEntry = {
  encode(message: NetworkComputeResponse_OutputImagesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      OutputImage.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NetworkComputeResponse_OutputImagesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNetworkComputeResponse_OutputImagesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = OutputImage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NetworkComputeResponse_OutputImagesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? OutputImage.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: NetworkComputeResponse_OutputImagesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = OutputImage.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NetworkComputeResponse_OutputImagesEntry>, I>>(
    base?: I,
  ): NetworkComputeResponse_OutputImagesEntry {
    return NetworkComputeResponse_OutputImagesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NetworkComputeResponse_OutputImagesEntry>, I>>(
    object: I,
  ): NetworkComputeResponse_OutputImagesEntry {
    const message = createBaseNetworkComputeResponse_OutputImagesEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? OutputImage.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseNetworkComputeResponse_RoiOutputDataEntry(): NetworkComputeResponse_RoiOutputDataEntry {
  return { key: "", value: undefined };
}

export const NetworkComputeResponse_RoiOutputDataEntry = {
  encode(message: NetworkComputeResponse_RoiOutputDataEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      OutputData.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NetworkComputeResponse_RoiOutputDataEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNetworkComputeResponse_RoiOutputDataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = OutputData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NetworkComputeResponse_RoiOutputDataEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? OutputData.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: NetworkComputeResponse_RoiOutputDataEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = OutputData.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NetworkComputeResponse_RoiOutputDataEntry>, I>>(
    base?: I,
  ): NetworkComputeResponse_RoiOutputDataEntry {
    return NetworkComputeResponse_RoiOutputDataEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NetworkComputeResponse_RoiOutputDataEntry>, I>>(
    object: I,
  ): NetworkComputeResponse_RoiOutputDataEntry {
    const message = createBaseNetworkComputeResponse_RoiOutputDataEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? OutputData.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseWorkerComputeRequest(): WorkerComputeRequest {
  return { header: undefined, inputData: undefined };
}

export const WorkerComputeRequest = {
  encode(message: WorkerComputeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      RequestHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    if (message.inputData !== undefined) {
      NetworkComputeInputDataWorker.encode(message.inputData, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkerComputeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkerComputeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = RequestHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.inputData = NetworkComputeInputDataWorker.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkerComputeRequest {
    return {
      header: isSet(object.header) ? RequestHeader.fromJSON(object.header) : undefined,
      inputData: isSet(object.inputData) ? NetworkComputeInputDataWorker.fromJSON(object.inputData) : undefined,
    };
  },

  toJSON(message: WorkerComputeRequest): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = RequestHeader.toJSON(message.header);
    }
    if (message.inputData !== undefined) {
      obj.inputData = NetworkComputeInputDataWorker.toJSON(message.inputData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkerComputeRequest>, I>>(base?: I): WorkerComputeRequest {
    return WorkerComputeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkerComputeRequest>, I>>(object: I): WorkerComputeRequest {
    const message = createBaseWorkerComputeRequest();
    message.header = (object.header !== undefined && object.header !== null)
      ? RequestHeader.fromPartial(object.header)
      : undefined;
    message.inputData = (object.inputData !== undefined && object.inputData !== null)
      ? NetworkComputeInputDataWorker.fromPartial(object.inputData)
      : undefined;
    return message;
  },
};

function createBaseWorkerComputeResponse(): WorkerComputeResponse {
  return { header: undefined, otherData: undefined, status: 0, customParamError: undefined, outputImages: {} };
}

export const WorkerComputeResponse = {
  encode(message: WorkerComputeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      ResponseHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    if (message.otherData !== undefined) {
      Any.encode(message.otherData, writer.uint32(18).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    if (message.customParamError !== undefined) {
      CustomParamError.encode(message.customParamError, writer.uint32(34).fork()).ldelim();
    }
    Object.entries(message.outputImages).forEach(([key, value]) => {
      WorkerComputeResponse_OutputImagesEntry.encode({ key: key as any, value }, writer.uint32(58).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkerComputeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkerComputeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = ResponseHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.otherData = Any.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.customParamError = CustomParamError.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 = WorkerComputeResponse_OutputImagesEntry.decode(reader, reader.uint32());
          if (entry7.value !== undefined) {
            message.outputImages[entry7.key] = entry7.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkerComputeResponse {
    return {
      header: isSet(object.header) ? ResponseHeader.fromJSON(object.header) : undefined,
      otherData: isSet(object.otherData) ? Any.fromJSON(object.otherData) : undefined,
      status: isSet(object.status) ? networkComputeStatusFromJSON(object.status) : 0,
      customParamError: isSet(object.customParamError) ? CustomParamError.fromJSON(object.customParamError) : undefined,
      outputImages: isObject(object.outputImages)
        ? Object.entries(object.outputImages).reduce<{ [key: string]: OutputImage }>((acc, [key, value]) => {
          acc[key] = OutputImage.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: WorkerComputeResponse): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = ResponseHeader.toJSON(message.header);
    }
    if (message.otherData !== undefined) {
      obj.otherData = Any.toJSON(message.otherData);
    }
    if (message.status !== 0) {
      obj.status = networkComputeStatusToJSON(message.status);
    }
    if (message.customParamError !== undefined) {
      obj.customParamError = CustomParamError.toJSON(message.customParamError);
    }
    if (message.outputImages) {
      const entries = Object.entries(message.outputImages);
      if (entries.length > 0) {
        obj.outputImages = {};
        entries.forEach(([k, v]) => {
          obj.outputImages[k] = OutputImage.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkerComputeResponse>, I>>(base?: I): WorkerComputeResponse {
    return WorkerComputeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkerComputeResponse>, I>>(object: I): WorkerComputeResponse {
    const message = createBaseWorkerComputeResponse();
    message.header = (object.header !== undefined && object.header !== null)
      ? ResponseHeader.fromPartial(object.header)
      : undefined;
    message.otherData = (object.otherData !== undefined && object.otherData !== null)
      ? Any.fromPartial(object.otherData)
      : undefined;
    message.status = object.status ?? 0;
    message.customParamError = (object.customParamError !== undefined && object.customParamError !== null)
      ? CustomParamError.fromPartial(object.customParamError)
      : undefined;
    message.outputImages = Object.entries(object.outputImages ?? {}).reduce<{ [key: string]: OutputImage }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = OutputImage.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseWorkerComputeResponse_OutputImagesEntry(): WorkerComputeResponse_OutputImagesEntry {
  return { key: "", value: undefined };
}

export const WorkerComputeResponse_OutputImagesEntry = {
  encode(message: WorkerComputeResponse_OutputImagesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      OutputImage.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkerComputeResponse_OutputImagesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkerComputeResponse_OutputImagesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = OutputImage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkerComputeResponse_OutputImagesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? OutputImage.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: WorkerComputeResponse_OutputImagesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = OutputImage.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkerComputeResponse_OutputImagesEntry>, I>>(
    base?: I,
  ): WorkerComputeResponse_OutputImagesEntry {
    return WorkerComputeResponse_OutputImagesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkerComputeResponse_OutputImagesEntry>, I>>(
    object: I,
  ): WorkerComputeResponse_OutputImagesEntry {
    const message = createBaseWorkerComputeResponse_OutputImagesEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? OutputImage.fromPartial(object.value)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
