import pluralize from 'pluralize';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { Icon } from 'components/common/index';
import { STypography } from 'components/ui';

import { ThemeColorKeys } from 'types/ui/Theme';

import { SDropzoneContainer, SFilesList } from './Dropzone.styled';

/**
 * Dropzone component
 * @param {Object} config - configuration for files
 * @param {String} className - classname for container div
 */

type DropzoneConfigProps = {
  acceptedFiles: string[];
  maxFiles: number;
  maxSizeMB: number;
  singleFileMaxSizeMB: number;
};

export type DropzoneRef = {
  getFiles(): File[];
};

const defaultConfigs: DropzoneConfigProps = {
  acceptedFiles: ['image/jpg', 'image/jpeg', 'image/png'],
  maxFiles: 3,
  maxSizeMB: 50,
  singleFileMaxSizeMB: 25,
  // sizes: {
  //     maxWidth: 256,
  //     maxHeight: 256
  // }
};

const Dropzone = forwardRef<DropzoneRef | undefined, { config: DropzoneConfigProps }>(
  ({ config = defaultConfigs }, ref) => {
    const [filesState, setFilesState] = useState<
      {
        id: string;
        url: string;
        name: string;
        file: File;
      }[]
    >([]);
    const fileText = pluralize('file', config.maxFiles);

    const validateFiles = (fls: File[]) => {
      if (fls.length > config.maxFiles) {
        toast.error(`Total ${config.maxFiles} ${fileText} allowed.`);
      }

      const files = [...fls].slice(0, config.maxFiles);

      const validFiles = [];

      const maxAllowedSize = config.singleFileMaxSizeMB * 1024 * 1024;
      const maxAllowedTotalSize = config.maxSizeMB * 1024 * 1024;

      let totalSize = 0;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        let valid = true;
        let errorMessage = '';

        if (file) {
          // const fileName = file.name;
          const fileType = file.type;
          const fileSize = file.size;

          totalSize += fileSize;

          // Check type
          if (!config.acceptedFiles.includes(fileType)) {
            valid = false;
            errorMessage += `\n* Only ${config.acceptedFiles.join(', ')} ${pluralize(
              'file',
              config.acceptedFiles.length,
            )} ${pluralize('is', config.acceptedFiles.length)} allowed.`;
          }
          // Check file size
          if (fileSize > maxAllowedSize) {
            valid = false;
            errorMessage += `\n* Max allowed file size ${config.singleFileMaxSizeMB} MB.`;
          }
          // Check total size
          if (totalSize > maxAllowedTotalSize) {
            valid = false;
            errorMessage += `\n* Total file size limit exceeded (Max ${config.maxSizeMB} MB).`;
            toast.error(errorMessage);
            break;
          }
        } else {
          valid = false;
        }

        if (valid) {
          validFiles.push(file);
        } else {
          toast.error(errorMessage);
        }
      }

      return validFiles;
    };

    const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        // console.log('acceptedFiles', acceptedFiles);

        // do nothing if no files
        if (acceptedFiles.length === 0) {
          return;
        }

        // acceptedFiles.forEach(async file => {
        //     const reader = new FileReader();
        //
        //     reader.onabort = () => console.log('file reading was aborted');
        //     reader.onerror = () => console.log('file reading has failed');
        //     reader.onload = () => {
        //         // Do whatever you want with the file contents
        //         // const binaryStr = reader.result;
        //         // console.log(binaryStr);
        //     };
        //     reader.readAsArrayBuffer(file);
        // });

        // const validFiles = validateFiles([...filesState.map(f => f.file), ...acceptedFiles]);
        const validFiles = validateFiles(acceptedFiles);

        setFilesState(
          validFiles.map((f) => ({
            id: `_${Math.random().toString(36).substr(2, 9)}`,
            url: URL.createObjectURL(f),
            name: f.name,
            file: f,
          })),
        );
      },
      [filesState],
    );

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      // maxFiles: 1,
      // maxSize: config.maxSizeMB * 1024 * 1024
      // validator: nameLengthValidator
    });

    useImperativeHandle(ref, () => ({
      getFiles() {
        return filesState.map((f) => f.file);
      },
    }));

    return (
      <SDropzoneContainer>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input id="files-input" {...getInputProps()} />

          <div className="dropzone__text">
            <Icon icon="upload" width={44} height={44} mb={2} />
            <STypography as="span" variant="subtitle1">
              Drag and drop your {fileText} here
            </STypography>
            <STypography variant="subtitle2">
              Or <STypography color={ThemeColorKeys.PRIMARY_LIGHT}>browse</STypography> to choose{' '}
              {fileText}
            </STypography>

            <STypography as="p" variant="body2" align="center">
              Accepted file {pluralize('format', config.acceptedFiles.length)}:{' '}
              {config.acceptedFiles.map((fileFormat) => `.${fileFormat.split('/')[1]}`)}
              <br />
              (Maximum size {config.singleFileMaxSizeMB}MB)
            </STypography>
          </div>

          {filesState.length > 0 && (
            <SFilesList>
              {filesState.map((file) => (
                <li
                  key={file.id}
                  // isSelected={selectedFileId === f.id}
                  // deleteFunction={deleteLocalFile}
                >
                  <STypography as="span" variant="caption">
                    {file.name}
                  </STypography>
                  {/*<img src={file.url}/>*/}
                </li>
              ))}
            </SFilesList>
          )}
        </div>
      </SDropzoneContainer>
    );
  },
);

export default Dropzone;
