/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "bosdyn.api";

export enum TemperatureEnum {
  TEMPERATURE_UNKNOWN = 0,
  TEMPERATURE_KELVIN = 1,
  TEMPERATURE_CELSIUS = 2,
  TEMPERATURE_FAHRENHEIT = 3,
  UNRECOGNIZED = -1,
}

export function temperatureEnumFromJSON(object: any): TemperatureEnum {
  switch (object) {
    case 0:
    case "TEMPERATURE_UNKNOWN":
      return TemperatureEnum.TEMPERATURE_UNKNOWN;
    case 1:
    case "TEMPERATURE_KELVIN":
      return TemperatureEnum.TEMPERATURE_KELVIN;
    case 2:
    case "TEMPERATURE_CELSIUS":
      return TemperatureEnum.TEMPERATURE_CELSIUS;
    case 3:
    case "TEMPERATURE_FAHRENHEIT":
      return TemperatureEnum.TEMPERATURE_FAHRENHEIT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TemperatureEnum.UNRECOGNIZED;
  }
}

export function temperatureEnumToJSON(object: TemperatureEnum): string {
  switch (object) {
    case TemperatureEnum.TEMPERATURE_UNKNOWN:
      return "TEMPERATURE_UNKNOWN";
    case TemperatureEnum.TEMPERATURE_KELVIN:
      return "TEMPERATURE_KELVIN";
    case TemperatureEnum.TEMPERATURE_CELSIUS:
      return "TEMPERATURE_CELSIUS";
    case TemperatureEnum.TEMPERATURE_FAHRENHEIT:
      return "TEMPERATURE_FAHRENHEIT";
    case TemperatureEnum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PressureEnum {
  PRESSURE_UNKNOWN = 0,
  /** PRESSURE_PSI - Pound-force per square inch */
  PRESSURE_PSI = 1,
  /** PRESSURE_KPA - KiloPascal */
  PRESSURE_KPA = 2,
  /** PRESSURE_BAR - Bar */
  PRESSURE_BAR = 3,
  UNRECOGNIZED = -1,
}

export function pressureEnumFromJSON(object: any): PressureEnum {
  switch (object) {
    case 0:
    case "PRESSURE_UNKNOWN":
      return PressureEnum.PRESSURE_UNKNOWN;
    case 1:
    case "PRESSURE_PSI":
      return PressureEnum.PRESSURE_PSI;
    case 2:
    case "PRESSURE_KPA":
      return PressureEnum.PRESSURE_KPA;
    case 3:
    case "PRESSURE_BAR":
      return PressureEnum.PRESSURE_BAR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PressureEnum.UNRECOGNIZED;
  }
}

export function pressureEnumToJSON(object: PressureEnum): string {
  switch (object) {
    case PressureEnum.PRESSURE_UNKNOWN:
      return "PRESSURE_UNKNOWN";
    case PressureEnum.PRESSURE_PSI:
      return "PRESSURE_PSI";
    case PressureEnum.PRESSURE_KPA:
      return "PRESSURE_KPA";
    case PressureEnum.PRESSURE_BAR:
      return "PRESSURE_BAR";
    case PressureEnum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Units {
  /** Use this field if the desired unit is not a supported enum. */
  name?: string | undefined;
  temp?: TemperatureEnum | undefined;
  press?:
    | PressureEnum
    | undefined;
  /**
   * If a service wants an absolute temperature threshold between 0 C  and 100 C, that would show
   * up as 32 F to 212 F on any Fahrenheit loving clients IF is_relative is set to false.  Note
   * the change from 0 C to 32 F.
   *
   * If a service wants a relative temperate threshold (region A must be no more than X degrees
   * hotter than region B), between 0 and 100 C, that would show up as 0 F to 180 F on any
   * Fahrenheit loving clients IF is_relative is set to true.  Note that 0 C now maps to 0 F.
   *
   * NOTE: Only relevant for units with non equal zero points.
   */
  isRelative: boolean;
}

function createBaseUnits(): Units {
  return { name: undefined, temp: undefined, press: undefined, isRelative: false };
}

export const Units = {
  encode(message: Units, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.temp !== undefined) {
      writer.uint32(16).int32(message.temp);
    }
    if (message.press !== undefined) {
      writer.uint32(24).int32(message.press);
    }
    if (message.isRelative !== false) {
      writer.uint32(32).bool(message.isRelative);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Units {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnits();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.temp = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.press = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isRelative = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Units {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : undefined,
      temp: isSet(object.temp) ? temperatureEnumFromJSON(object.temp) : undefined,
      press: isSet(object.press) ? pressureEnumFromJSON(object.press) : undefined,
      isRelative: isSet(object.isRelative) ? globalThis.Boolean(object.isRelative) : false,
    };
  },

  toJSON(message: Units): unknown {
    const obj: any = {};
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.temp !== undefined) {
      obj.temp = temperatureEnumToJSON(message.temp);
    }
    if (message.press !== undefined) {
      obj.press = pressureEnumToJSON(message.press);
    }
    if (message.isRelative !== false) {
      obj.isRelative = message.isRelative;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Units>, I>>(base?: I): Units {
    return Units.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Units>, I>>(object: I): Units {
    const message = createBaseUnits();
    message.name = object.name ?? undefined;
    message.temp = object.temp ?? undefined;
    message.press = object.press ?? undefined;
    message.isRelative = object.isRelative ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
