import { Dispatch, SetStateAction } from 'react';

import { debugJanus } from 'utils/debugger';

import { subscribeRemoteFeed } from '../core';
import {
  JanusEventType,
  JanusPluginHandle,
  JanusType,
  PublisherId,
  VideoRoomPublishersData,
  VideoRoomUnpublishData,
} from '../types';
import { RemoteFeedCallback } from './createRemoteFeedCallback';

type PublishRoomCallbackProps = {
  janus: JanusType;
  opaqueId: string;
  room: string;
  pubPrivateId?: string;
  handleUnpublishedOrLeaving: (publisherId: PublisherId) => void;
  remoteFeed: JanusPluginHandle | null;
  setSfuTest: Dispatch<SetStateAction<JanusPluginHandle | null>>;
  remoteFeedCallback: RemoteFeedCallback;
};

export type CreatePublishRoomCallback = ReturnType<typeof createPublishRoomCallback>;

/**
 * Callbacks for the "publishToRoom" plugin
 */
export const createPublishRoomCallback =
  ({
    janus,
    opaqueId,
    room,
    pubPrivateId,
    handleUnpublishedOrLeaving,
    remoteFeed,
    setSfuTest,
    remoteFeedCallback,
  }: PublishRoomCallbackProps) =>
  (
    _sfutest: JanusPluginHandle | null,
    eventType: JanusEventType,
    data?: VideoRoomPublishersData,
  ) => {
    // todo - when is this called? why are we creating first a publish plugin and than a subscribe plugin?
    debugJanus(`publishRoomCallback -> ${eventType}`, { data });
    // todo - couldn't that be already set by the plugin handle success callback?
    setSfuTest(_sfutest);

    if (eventType === JanusEventType.JOINED) {
      if (!data?.publishers) return;

      const list = data.publishers;
      if (list.length === 0) return;

      for (const publisher of list) {
        subscribeRemoteFeed(janus, opaqueId, room, pubPrivateId, publisher, remoteFeedCallback);
      }
    } else if (eventType === JanusEventType.PUBLISHERS) {
      if (!data?.publishers) return;

      const list = data.publishers;
      if (list.length === 0) return;

      for (const publisher of list) {
        subscribeRemoteFeed(janus, opaqueId, room, pubPrivateId, publisher, remoteFeedCallback);
      }
    } else if (eventType === JanusEventType.LEAVING || eventType === JanusEventType.UNPUBLISHED) {
      // unique ID of the publisher who unpublished
      const { unpublished: publisherId } = data as unknown as VideoRoomUnpublishData;
      handleUnpublishedOrLeaving(String(publisherId));

      // sometimes it exist but not always
      if (remoteFeed) {
        // detaches from the plugin and destroys the handle, tearing down the related PeerConnection if it exists.
        // todo - params should be a "callback" related to what happen after the detach
        remoteFeed.detach({});
      }
    }
  };
