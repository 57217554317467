import { HUB_URL, InvokeMethod, ListenMethod } from './const';
import { useSignalRHub } from './context/useSignalRHub';

export enum TelemetryHubEvents {
  AUTH = 'Authenticate', // invoke
  LOG_INF = 'ReceiveMyRobotLogInformation', // listen
  GET_MY_ROBOT_CURRENT_LOCATION = 'GetMyRobotCurrentLocation', // invoke
  CURRENT_LOCATION = 'ReceiveMyRobotCurrentLocation', // listen
  RECEIVE_RELAY_RESEND_REQUEST = 'ReceiveRelayResendRequest',
  SEND_RELAY_RESEND_REQUEST = 'SendRelayResendRequest',
  RECEIVE_NOTIFICATION = 'ReceiveNotification',
  SEND_NOTIFICATION = 'SendNotification',
  RECEIVE_NETWORK_HEALTH = 'ReceiveNetworkHealth',
  SEND_NETWORK_HEALTH = 'SendNetworkHealth',
  // properly for the robot side - triggered after we invoke SEND_RELAY_RESEND_REQUEST
  // RECEIVE_RELAY_RESEND_REQUEST = 'ReceiveRelayResendRequest',
}

/**
 * Hook to use for the Telemetry hub
 */
export function useTelemetryHub({
  listenMethods,
  invokeMethods,
}: {
  invokeMethods?: Partial<Record<TelemetryHubEvents, InvokeMethod>>;
  listenMethods?: Partial<Record<TelemetryHubEvents, ListenMethod>>;
}) {
  return useSignalRHub(HUB_URL.TELEMETRY, invokeMethods, listenMethods);
}
