import { selector, selectorFamily } from 'recoil';

import { TGamepadButton } from 'types/models/gamepad/GamepadButtons';

import { gamepadAtom } from './gamepad.atom';

export const gamepadConnectedSelector = selector<boolean>({
  key: 'gamepadConnectedSelector',
  get: ({ get }) => get(gamepadAtom)?.connected ?? false,
});

export const gamepadAllButtonsSelector = selector({
  key: 'gamepadAllButtonsSelector',
  get: ({ get }) => get(gamepadAtom)?.buttons,
});

export const gamepadButtonSelector = selectorFamily({
  key: 'gamepadButtonSelector',
  get:
    (id: TGamepadButton) =>
    ({ get }) =>
      get(gamepadAllButtonsSelector)?.[id],
});

export const gamepadButtonPressedSelector = selectorFamily({
  key: 'gamepadButtonPressedSelector',
  get:
    (id: TGamepadButton) =>
    ({ get }) => {
      const button = get(gamepadButtonSelector(id));
      return button?.pressed ?? false;
    },
});

// note: currently unused
export const gamepadButtonValueSelector = selectorFamily({
  key: 'gamepadButtonPressedSelector',
  get:
    (id: TGamepadButton) =>
    ({ get }) =>
      get(gamepadButtonSelector(id))?.value ?? 0,
});
