import { useSetRecoilState } from 'recoil';

import { useLogSignalRRelay } from 'store/logger/useLogSignalRRelay';
import { useDeviceName } from 'store/my-robot';

import { useAvailableServicesNCBSetter, useAvailableServicesRMSSetter } from './available_services';
import { useCurrentLocationSetter } from './current-location';
import { useFiducialsSetter } from './fiducials';
import { RobotRelayJson, RobotRosTopic, useManipulatorStateSetter } from './index';
import { relayTopicsAtom } from './relay-topics.atoms';
import { useRobotCommandFeedbackSetter } from './robot-command-feedback';
import { useSetBatteryStatus } from './status-battery';

/**
 * A hook to set the relay topics for the robot when receiving them via signal R
 *
 * Updates different atoms based on the topic received - note that you don't have to update states on receiving
 */
export const useRelayTopicSetter = () => {
  const { deviceName } = useDeviceName();
  const setRelayTopics = useSetRecoilState(relayTopicsAtom(deviceName));
  const { updateBatteryStatus } = useSetBatteryStatus();
  const { updateManipulatorState } = useManipulatorStateSetter();
  const { handleRobotCommandFeedback, handleRobotCommandResult } = useRobotCommandFeedbackSetter();
  const { logSignalRRelay } = useLogSignalRRelay();
  const { updateAvailableServicesNCB } = useAvailableServicesNCBSetter();
  const { updateAvailableServicesRMS } = useAvailableServicesRMSSetter();
  const { updateCurrentLocation } = useCurrentLocationSetter();
  const { updateFiducials } = useFiducialsSetter();

  const handleReceivedRelayTopicUpdate = (data: RobotRelayJson) => {
    const { topic } = data;
    const payloadTemp = data.payload;
    // todo - the payload from Arm related stuff is double stringify
    const payload = payloadTemp.data
      ? { ...payloadTemp, data: JSON.parse(payloadTemp.data) }
      : payloadTemp;

    /**
     * Todo: Move logger to a global subscriber - not every subscriber to this method is handled here anymore
     */
    logSignalRRelay(topic, `RELAY ↓ ros-topic:${topic}`, payload);

    switch (topic) {
      case RobotRosTopic.ROBOT_BATTERY:
        updateBatteryStatus(payload);
        break;
      case RobotRosTopic.SPOT_CLAIM:
      case RobotRosTopic.SPOT_FEEDBACK:
      case RobotRosTopic.SPOT_MOBILITY_PARAMS:
        setRelayTopics((prevState) => ({
          ...prevState,
          [topic]: payload,
        }));
        break;
      case RobotRosTopic.MANIPULATION_STATE:
        updateManipulatorState(payload);
        break;
      case RobotRosTopic.JOINT_STATES:
        // we currently don't use them - todo we shouldn't receive them without subscribing
        break;
      case RobotRosTopic.ROBOT_COMMAND_FEEDBACK:
        handleRobotCommandFeedback(payload);
        break;
      case RobotRosTopic.ROBOT_COMMAND_RESULT:
        handleRobotCommandResult(payload);
        break;
      case RobotRosTopic.NCB_AVAILABLE_SERVICES:
        updateAvailableServicesNCB(payload);
        break;
      case RobotRosTopic.RMS_AVAILABLE_SERVICES:
        updateAvailableServicesRMS(payload);
        break;
      case RobotRosTopic.FIDUCIALS:
        updateFiducials(payload);
        break;
      case RobotRosTopic.CURRENT_LOCATION:
        updateCurrentLocation(payload);
        break;
      default:
        // some are maybe handle elsewhere
        if (!Object.values(RobotRosTopic).includes(topic)) {
          console.error('Received unknown Relay topic update:', topic, payload);
        }
        break;
    }
  };

  return {
    handleReceivedRelayTopicUpdate,
  };
};
