import { DefaultValue, selectorFamily } from 'recoil';

import { HUB_URL } from 'hooks/signalR';

import { debugSignalRAtom } from './logger.atom';
import { LogSignalRHub, LogSignalRMessageValue } from './types';

/**
 * Selectors for the logging information of a specific hub. Also sets the logging information.
 */
const debugSignalRHubSelector = selectorFamily<LogSignalRHub, HUB_URL>({
  key: 'debugSignalRHubSelector',
  get:
    (hub) =>
    ({ get }) =>
      get(debugSignalRAtom)[hub],
  set:
    (hub) =>
    ({ set, get }, newValue) => {
      const newState = {
        ...get(debugSignalRAtom),
        [hub]: newValue,
      };
      set(debugSignalRAtom, newState);
    },
});

export const debugSignalRHubInitSelector = selectorFamily<LogSignalRMessageValue, HUB_URL>({
  key: 'debugSignalRHubInitSelector',
  get:
    (hub) =>
    ({ get }) =>
      get(debugSignalRHubSelector(hub)).init,
  set:
    (hub) =>
    ({ set, get }, init) => {
      const state = get(debugSignalRHubSelector(hub));
      if (init instanceof DefaultValue) return set(debugSignalRHubSelector(hub), init);
      set(debugSignalRHubSelector(hub), {
        ...state,
        init,
      });
    },
});

export const debugSignalRHubReceiveSelector = selectorFamily<LogSignalRMessageValue, HUB_URL>({
  key: 'debugSignalRHubReceiveSelector',
  get:
    (hub) =>
    ({ get }) =>
      get(debugSignalRHubSelector(hub)).receive,
  set:
    (hub) =>
    ({ set, get }, receive) => {
      const state = get(debugSignalRHubSelector(hub));
      if (receive instanceof DefaultValue) return set(debugSignalRHubSelector(hub), receive);
      set(debugSignalRHubSelector(hub), {
        ...state,
        receive,
      });
    },
});

export const debugSignalRHubInvokeSelector = selectorFamily<LogSignalRMessageValue, HUB_URL>({
  key: 'debugSignalRHubInvokeSelector',
  get:
    (hub) =>
    ({ get }) =>
      get(debugSignalRHubSelector(hub)).invoke,
  set:
    (hub) =>
    ({ set, get }, invoke) => {
      const state = get(debugSignalRHubSelector(hub));
      if (invoke instanceof DefaultValue) return set(debugSignalRHubSelector(hub), invoke);
      set(debugSignalRHubSelector(hub), {
        ...state,
        invoke,
      });
    },
});
