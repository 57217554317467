import { logRed } from 'utils/debugger';

import { DirectMethodRequestDTO, DirectMethodResponseDTO } from 'types/models/DirectMethod';

import { axiosRequest } from '../api/axios';
import { getIoTHubGeneralConfig } from '../api/urls';

export enum IotHubModule {
  VIDEO_CAM_MODULE = 'VideoCamModule',
  SPOT_CONTROL_MODULE = 'SpotControlModule',
  ROBOT_MAIN_MODULE = 'RobotMainModule',
}

type SendDataToModuleOptions = {
  iotHubDeviceName: string;
  iotHubModule: IotHubModule;
  // actions maps to module methods
  actionName: DirectMethodRequestDTO['actionName'];
  payload: DirectMethodRequestDTO['payload'];
};

/**
 * This doesn't catch any error!
 *
 * @param iotHubDeviceName
 * @param iotHubModule
 * @param actionName
 * @param payload
 */
export function sendDataToModuleRaw({
  iotHubDeviceName,
  iotHubModule,
  actionName,
  payload,
}: SendDataToModuleOptions) {
  const { url, method } = getIoTHubGeneralConfig(iotHubDeviceName, iotHubModule);
  return axiosRequest<DirectMethodResponseDTO>(method, url, {
    actionName,
    payload,
  });
}

// TODO: new one - old  method useDirectMethod still in use - remember to check handling all cases the old already does
// todo - will probably be changed soon
export const sendDataToModule = async ({
  iotHubDeviceName,
  iotHubModule,
  actionName,
  payload,
}: SendDataToModuleOptions) => {
  const { data } = await sendDataToModuleRaw({
    iotHubDeviceName,
    iotHubModule,
    actionName,
    payload,
  });

  if (!data.isSuccess) {
    logRed('error:sendDataToModule:error', data);
    // @ts-ignore todo - remove - this is for debugging
    if (data.payload.ncbResponse) logRed(data.payload.ncbResponse);
    if (data.isModuleDisconnected) {
      throw new Error(
        `The module "${data.payload}" is disconnected: Please check the connection and try again`,
      );
    }
    // the error message is in the payload when i saw it last time
    if (typeof data.payload === 'string') {
      throw new Error(data.payload);
    }
    // old way - not sure if this is still needed
    const errorMessage = (data.payload as { error: string }).error;

    throw new Error(errorMessage);
  }
  return data;
};
