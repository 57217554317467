import styled from 'styled-components';

import { SBox } from 'components/ui/index';

const SButtons = styled(SBox)`
  display: flex;
  padding: ${(props) => props.theme.spacing(props.p !== undefined ? props.p : 2)};
  gap: ${(props) => props.theme.spacing(2)};
`;

export default SButtons;
