import { logBlue } from 'utils/debugger';
import { RobotStream } from 'utils/janus-react-components/helper';

import { DeviceName } from 'types/models';

import { IotHubModule, sendDataToModule } from '../util';
import {
  RequestStreamChangeAction,
  RequestStreamChangeChannel,
  RequestStreamChangePayload,
} from './types';

/**
 * Direct Methods for VideoCamModule
 * @link https://dev.azure.com/RoboverseReply/Robotics%20Platform/_wiki/wikis/Robotics-Platform.wiki/401/VideoCamModule
 */
export enum VideoCamModuleAction {
  LIST_METHODS = 'listMethods', // DEBUG
  GET_STATE = 'getcurrentstreamingstate', // DEBUG
  REQUEST_STREAM_CHANGE = 'requestStreamChange',
}

type DefaultProps = {
  iotHubDeviceName: string;
};

type Props = DefaultProps & {
  payload: RequestStreamChangePayload;
};

export const sendVideoCamModuleRequestStreamChange = async ({
  iotHubDeviceName,
  payload,
}: Props) => {
  return sendDataToModule({
    iotHubDeviceName,
    iotHubModule: IotHubModule.VIDEO_CAM_MODULE,
    actionName: VideoCamModuleAction.REQUEST_STREAM_CHANGE,
    payload,
  });
};

type ChangeVideoCamModuleVideoSourceProps = DefaultProps & {
  channelName: RobotStream;
  streamSource: string;
  oldStreamSource?: string;
  frameSize?: [number, number];
};

export const changeVideoCamModuleVideoSource = ({
  iotHubDeviceName,
  channelName,
  streamSource,
  oldStreamSource,
  frameSize,
}: ChangeVideoCamModuleVideoSourceProps) => {
  logBlue('changeVideoCamModuleVideoSource', channelName, streamSource, oldStreamSource);
  const requestedStreamChanges: RequestStreamChangeChannel[] = [
    {
      channelName,
      streamSource,
      action: RequestStreamChangeAction.REQUEST_STREAM,
      requested_frame_size_height_width: frameSize ?? [720, 1280],
    },
  ];

  if (oldStreamSource) {
    requestedStreamChanges.unshift({
      channelName,
      streamSource: null,
      action: RequestStreamChangeAction.REQUEST_STREAM,
      requested_frame_size_height_width: null,
    });
  }

  return sendVideoCamModuleRequestStreamChange({
    iotHubDeviceName,
    payload: {
      requestedStreamChanges,
      streamingHalted: null,
      implicitCloseOthers: null,
    },
  });
};

type CloseVideoCamModuleChannelProps = {
  channelName: RobotStream;
  deviceName: DeviceName;
};

export const closeVideoCamModuleStream = ({
  deviceName,
  channelName,
}: CloseVideoCamModuleChannelProps) => {
  return sendVideoCamModuleRequestStreamChange({
    iotHubDeviceName: deviceName,
    payload: {
      requestedStreamChanges: [
        {
          channelName,
          streamSource: null,
          action: RequestStreamChangeAction.CLOSE_CHANNEL,
        },
      ],
      streamingHalted: null,
      implicitCloseOthers: null,
    },
  });
};

export const closeAllVideoCamModuleChannels = ({ iotHubDeviceName }: DefaultProps) =>
  sendVideoCamModuleRequestStreamChange({
    iotHubDeviceName,
    payload: {
      streamingHalted: true,
      implicitCloseOthers: true,
    },
  });
