// @ts-nocheck
import { FC, HTMLProps, ReactNode, RefCallback, memo, useMemo } from 'react';
import { ReactSliderProps } from 'react-slider';

import { STypography } from 'components/ui';

import {
  SSlider,
  SSliderContainer,
  SSliderMainContainer,
  SThumb,
  STrack,
  STrackContainer,
} from './Slider.styled';

type TrackProps = HTMLProps<HTMLDivElement> & { ref: RefCallback<HTMLDivElement> };
type State = { index: number; value: any };

const Track = (props: TrackProps, state: State) => (
  // @ts-ignore
  <STrack
    {...props}
    index={state.index}
    count={Array.isArray(state.value) ? state.value.length : 1}
  />
);

// const Thumb = (props, state) => <SThumb {...props}>{state.valueNow}</SThumb>;
const Thumb = (props: TrackProps, state: State) => (
  // @ts-ignore
  <SThumb {...props}>{/*<span>{state.valueNow}</span>*/}</SThumb>
);

/**
 * Slider component
 * @param {String} label - slider top label
 * @param {String} minText - min side text
 * @param {String} maxText - max side text
 * @param {String} orientation - vertical / horizontal
 * @param {Function} valueFormatter - function to format value in label
 */

type Props = ReactSliderProps & {
  label?: string | ReactNode;
  minText?: string | ReactNode;
  maxText?: string | ReactNode;
  invert?: boolean;
  orientation?: 'vertical' | 'horizontal';
  disabled?: boolean;
  value?: any;
  valueFormatter?: any;
};

const Slider: FC<Props> = memo(
  ({
    label,
    minText,
    maxText,
    invert,
    orientation,
    disabled,
    value,
    valueFormatter,
    key,
    ...props
  }) => {
    const minT = invert ? maxText : minText;
    const maxT = invert ? minText : maxText;

    const formattedValue = useMemo(
      () => (valueFormatter ? valueFormatter(value) : value),
      [value, valueFormatter],
    );

    return (
      <SSliderMainContainer isVertical={orientation === 'vertical'} disabled={disabled}>
        {typeof label === 'string' ? (
          <STypography variant="caption" truncate>
            {label && `${label} (${formattedValue})`}
          </STypography>
        ) : (
          <STypography variant="caption" truncate>
            {label !== undefined && label}
          </STypography>
        )}
        <SSliderContainer>
          {minT && <STypography variant="caption">{minT}</STypography>}
          <STrackContainer>
            <SSlider
              key={key}
              value={value}
              orientation={orientation}
              invert={invert}
              renderTrack={Track}
              renderThumb={Thumb}
              disabled={disabled}
              {...props}
            />
          </STrackContainer>
          {maxT && <STypography variant="caption">{maxT}</STypography>}
        </SSliderContainer>
      </SSliderMainContainer>
    );
  },
);

export default Slider;
