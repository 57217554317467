import { atom, selectorFamily, useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';

import { LOCAL_STORAGE } from 'lib/constants/local-storage';

import { DebugMessageColor, colorOption, locColor } from 'utils/debugger';

/**
 * Keys for managing areas for logging
 */
export enum RosBridgeLogging {
  SERVICE_REQUEST = 'ros:service:request',
  SERVICE_RESPONSE = 'ros:service:response',
  ACTION_GOAL = 'ros:action:goal',
  ACTION_FEEDBACK = 'ros:action:feedback',
  ACTION_RESULT = 'ros:action:result',
}

export const ROS_BRIDGE_LOGGING = Object.values(RosBridgeLogging);

type DebugRosBridgeStateEntry = {
  log: boolean;
  color: string;
};

type DebugRosBridgeState = Record<RosBridgeLogging, DebugRosBridgeStateEntry>;

const getLogRosBridgeDefaultState = (): DebugRosBridgeState => {
  const stored = localStorage.getItem(LOCAL_STORAGE.DEBUG.ROS_BRIDGE);
  const state = stored ? JSON.parse(stored) : {};

  ROS_BRIDGE_LOGGING.forEach((key) => {
    if (!state[key]) {
      state[key] = {
        log: false,
        color: colorOption[DebugMessageColor.BLUE],
      };
    }
  });

  return state;
};

const logRosBridgeAtom = atom({
  key: 'logRosBridgeAtom',
  default: getLogRosBridgeDefaultState(),
  effects: [
    ({ onSet }) => {
      onSet((state) => {
        localStorage.setItem(LOCAL_STORAGE.DEBUG.ROS_BRIDGE, JSON.stringify(state));
      });
    },
  ],
});

const logRosBridgeSelector = selectorFamily<DebugRosBridgeStateEntry, RosBridgeLogging>({
  key: 'logRosBridgeSelector',
  get:
    (topic) =>
    ({ get }) =>
      get(logRosBridgeAtom)[topic],
});

export function useLogRosBridgeSetter(key: RosBridgeLogging) {
  const { color, log } = useRecoilValue(logRosBridgeSelector(key));
  const setDebugState = useSetRecoilState(logRosBridgeAtom);

  const updateLog = (log: boolean) =>
    setDebugState((old) => {
      const newItem = {
        ...old[key],
        log,
      };
      return {
        ...old,
        [key]: newItem,
      };
    });

  const updateColor = (color: string) => {
    setDebugState((old) => {
      const newItem = {
        ...old[key],
        color,
      };
      return {
        ...old,
        [key]: newItem,
      };
    });
  };

  return { color, log, updateLog, updateColor } as const;
}

/**
 * Returns a callback to read the state without subscribing to it.
 */
function useDebugRosBridgeCallback() {
  const getDebugRosBridge = useRecoilCallback(
    ({ snapshot }) =>
      (key: RosBridgeLogging): DebugRosBridgeStateEntry | undefined =>
        snapshot.getLoadable(logRosBridgeSelector(key))?.contents,
    [],
  );

  return { getDebugRosBridge };
}

/**
 * Returns a callback to read the state without subscribing to it.
 */
export function useLogRosBridge() {
  const { getDebugRosBridge } = useDebugRosBridgeCallback();

  function logRosBridge(topic: RosBridgeLogging, text: string, ...rest: unknown[]) {
    const debug = getDebugRosBridge(topic);
    if (debug?.log) {
      locColor(debug.color, text, ...rest);
    }
  }

  return { logRosBridge };
}

export type LogRosBridge = ReturnType<typeof useLogRosBridge>['logRosBridge'];
