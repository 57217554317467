import { AuthenticationContext, UserInfo } from 'react-adal';

type AzureADState = {
  authContext?: AuthenticationContext;
  accountInfo?: UserInfo;
  companyDomain: string;
};

export const AzureADState: AzureADState = {
  companyDomain: '',
};
