import styled from 'styled-components';

import { SButton } from 'components/ui';

export const SCarouselContainer = styled.div`
  .rec .rec-slider-container {
    margin: 0;
  }

  .rec .rec-item-wrapper {
    height: 100%;
    overflow: visible;
  }
`;

export const SCarouselButton = styled(SButton)`
  background-color: transparent;
  border: 2px solid white;

  &:disabled {
    opacity: 0;
  }
`;

export const SCarouselPaginationButton = styled(SButton).withConfig({
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<{ isActive?: boolean }>`
  width: 100%;
  border: none;
  padding: 4px;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.primary.main : props.theme.colors.background.light};
  margin: ${(props) => props.theme.spacing(0, 1)};

  &:disabled {
    opacity: 0.5;
  }
`;
