import { useEffect, useRef, useState } from 'react';

import BatteryStatus from 'components/complex/battery-status/BatteryStatus';

const BatteryAnimation = () => {
  const [lvl, setLvl] = useState(0);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setLvl((prevLvl) => (prevLvl === 100 ? 0 : prevLvl + 1));
    }, 50);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return <BatteryStatus status="CHARGING" percentage={lvl} />;
};

export default BatteryAnimation;
