// @ts-nocheck
import { get } from 'lodash-es';
import styled, { ThemedStyledProps, css } from 'styled-components';

import { STypography } from 'components/ui';

export const SKeyLetter = styled(STypography)`
  padding: 0;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  bottom: 4%;
  right: 8%;
`;

const activeStyles = (props: ThemedStyledProps<any, any>) => css`
  box-shadow: 0 0 4px -3px black;
  transform: translateY(1px);

  & > * {
    filter: drop-shadow(0 0 1px ${props.theme.colors.primary.main})
      drop-shadow(0 0 4px ${props.theme.colors.primary.main});

    //text-shadow: 1px 1px ${props.theme.colors.primary.main};
  }

  svg {
    filter: drop-shadow(0 0 1px ${props.theme.colors.primary.main})
      drop-shadow(0 0 3px ${props.theme.colors.primary.main})
      drop-shadow(0 0 6px ${props.theme.colors.primary.main})
      drop-shadow(0 0 8px ${props.theme.colors.primary.main});
  }
`;

export const SKey = styled.button.withConfig({
  shouldForwardProp: (prop) =>
    !['btnSize', 'fontSize', 'round', 'withIcon', 'isActive'].includes(prop),
})`
  position: relative;
  border: none;
  color: ${(props) => props.theme.colors.text.main};
  border-radius: ${(props) => props.theme.borderRadius.md};
  background-color: ${(props) => props.theme.colors.background.main};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  cursor: pointer;
  user-select: none;
  transition: box-shadow 0.2s, transform 0s;
  box-shadow: 0 0 8px -2px black; //${(props) => props.theme.colors.neutral};
  background-color: ${(props) =>
    props.bgcolor ? get(props.theme.colors, props.bgcolor) : props.theme.colors.background.light};
  max-width: 60px;
  max-height: 60px;

  //padding: ${(props) => (props.round ? props.theme.spacing(2) : props.theme.spacing(1.5, 2))};
  border-radius: ${(props) => (props.round ? '100%' : props.theme.borderRadius.md)};

  & > * {
    pointer-events: none;
  }

  ${(props) =>
    props.btnSize &&
    css`
      width: ${`${props.btnSize}px`};
      height: ${`${props.btnSize}px`};
      font-size: ${`calc(${props.fontSize}px)!important`};
      line-height: ${`calc(${props.fontSize}px * 1.2)!important`};

      & > span {
        font-size: inherit;
        line-height: inherit;
        white-space: pre-line;
      }

      svg {
        width: 80%;
        height: 80%;
      }

      ${SKeyLetter} {
        font-size: ${`calc(${props.fontSize}px / 5)`};
      }
    `}

  ${(props) =>
    props.disabled
      ? css`
          opacity: 0.8;
          user-select: none;
          pointer-events: none;
          background-color: ${props.theme.colors.neutral};

          & > span {
            color: ${props.theme.colors.text.dark};
          }

          svg {
            color: ${props.theme.colors.text.dark};
          }
        `
      : css`
          &:active {
            ${activeStyles(props)}
          }
        `}

    ${(props) => props.isActive && activeStyles(props)}
`;
