import { HubConnection } from '@microsoft/signalr';
import { useContext, useEffect } from 'react';

import { HUB_URL, InvokeMethod, ListenMethod, MethodName } from '../const';
import { HubContext } from './SignalRHubProvider';
import { useHubConnection } from './useHubConnection';

export type UseHubConnection = ReturnType<typeof useHubConnection>;

/**
 * Methods list to invoke (Key as 'method name' value as arguments and callback function).
 */
type InvokeMethods = Partial<Record<MethodName, InvokeMethod>>;
/**
 * Methods list to subscribe (Key as 'method name' value as callback function).
 */
type ListenMethods = Partial<Record<MethodName, ListenMethod>>;

/**
 * Hook to use for the Iothub hub
 */
export function useSignalRHub(
  url: HUB_URL,
  /**
   * Refactor this - its a unhandy way for invoking methods on startup -
   * it should be have a bedder name at least to describe what this does cause general invokes are different
   */
  invokeMethods?: InvokeMethods,
  listenMethods?: ListenMethods,
) {
  const context = useContext(HubContext);

  // this helps with not having undefined/null types later https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/
  if (!context) {
    throw new Error('Error: HubContext has to be used inside a <HubProvider>');
  }

  const hubContext = context[url];
  if (!hubContext) {
    throw new Error('Error: HubContext not initialised - Provider should protect user for access');
  }

  // we override the connection type because all invokes/subscribes throw if no connection not exist.
  const connection = hubContext.connection as HubConnection;
  // if (!connection) {
  //   throw new Error('Error: connection not initialised - Provider should protect user for access');
  // }

  // wrappers up the old way we managed the subscribe and invoke on init
  useEffect(() => {
    if (invokeMethods) {
      const methods = Object.entries(invokeMethods) as [MethodName, InvokeMethod][];
      hubContext.invokeMany(methods);
    }

    if (listenMethods) {
      const methods = Object.entries(listenMethods) as [MethodName, ListenMethod][];
      hubContext.subscribeMany(methods);
      return () => hubContext.unsubscribeMany(methods);
    }
  }, []);

  return { ...hubContext, connection };
}
