import styled from 'styled-components';

export const SPage = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  padding: ${(props) => props.theme.spacing(3, 5)};
  display: flex;
  flex-direction: column;
`;
