import { FC, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import { theme } from './Theme';

const AppTheme: FC<{ children: ReactNode }> = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default AppTheme;
