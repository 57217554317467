import { toast } from 'react-toastify';

import { useDeviceName } from 'store/my-robot';
import { useRobotArmLoading } from 'store/robot-control';

import { logGreen } from 'utils/debugger';

import { DirectMethodResponseDTO } from 'types/models/DirectMethod';

import { IotHubModule, sendDataToModule } from '../util';
import { RobotCommands } from './robotCommand';
import { SpotControlModuleAction } from './types';

/**
 * the user should use the loading state at least to maybe not trigger the event again?
 */
export function useSendRobotCommand() {
  const { deviceName } = useDeviceName();
  const { loading, setLoading } = useRobotArmLoading();

  async function sendRobotCommand(payload: unknown) {
    logGreen('temp:sendRobotCommand', payload);
    setLoading(true);

    /**
     * Return value:{
     *     "status": 200,
     *     "payload": {
     *         "Response": {
     *             "GoalId": 14,
     *             "FeedbackTopic": "robot_command_feedback_stripped",
     *             "ResultTopic": "robot_command_result_stripped"
     *         }
     *     }
     * }
     *
     */
    const response = await sendDataToModule({
      iotHubDeviceName: deviceName,
      iotHubModule: IotHubModule.SPOT_CONTROL_MODULE,
      actionName: SpotControlModuleAction.ROBOT_COMMAND,
      payload,
    })
      .catch((error) => {
        toast(`Failed to send robot command: ${error}`, { type: 'error' });
        console.error('sendRobotCommand', { error });
      })
      .finally(() => setLoading(false));

    if (!response) return; // only when error - already handled in the catch

    logGreen('temp:sendRobotCommand', response);
    /**
     * Todo: Each command will overwrite the old one currently
     *  - properly it would be bedder to save this per command but for now the buttons will be disabled until command
     *  is finished
     */
    // @ts-ignore - todo check if this really exist when this will be needed
    // const id = response.payload.GoalId as number;
    // setCurrentCommandId(id);

    return response;
  }

  /**
   *
   * @param stow - true if we want to stow the arm, false if the arm should become ready
   */
  function sendArmStowCommand(stow: boolean): Promise<DirectMethodResponseDTO | void> {
    const payload = RobotCommands.createArmStow(stow);
    return sendRobotCommand(payload);
  }

  function sendGripperCommand(
    open: boolean,
    slow: boolean = false,
  ): Promise<DirectMethodResponseDTO | void> {
    const payload = RobotCommands.createOpenGripper(open, slow);
    return sendRobotCommand(payload);
  }

  function sendArmStopCommand(): Promise<DirectMethodResponseDTO | void> {
    const payload = RobotCommands.createArmStopCommand();
    return sendRobotCommand(payload);
  }

  function sendDragCommand(): Promise<DirectMethodResponseDTO | void> {
    const payload = RobotCommands.createArmDragCommand();
    return sendRobotCommand(payload);
  }

  // loading until parseNamedArmPositionCommandFeedback is 1 STATUS_COMPLETE
  function sendCarryCommand(): Promise<DirectMethodResponseDTO | void> {
    const payload = RobotCommands.createArmCarryCommand();
    return sendRobotCommand(payload);
  }

  return {
    loading,
    sendArmStowCommand,
    sendGripperCommand,
    sendArmStopCommand,
    sendDragCommand,
    sendCarryCommand,
  };
}
