import { PropsWithChildren } from 'react';

import { LOCAL_STORAGE } from 'lib/constants/local-storage';

import { useDebug } from 'utils/debugger';

/**
 * This component will only render its children if the debug mode is enabled.
 * @param children
 * @constructor
 */
export function DebugOnlyWrapper({ children }: PropsWithChildren) {
  const [debug] = useDebug(LOCAL_STORAGE.DEBUG.SHOW_MENU);
  if (!debug) return null;
  return children;
}
