import { useLayoutEffect, useRef } from 'react';

/**
 * Hook is for using EventListener. (Automatically removes Eventlistener on unmount)
 * @param {String} eventName - Event name
 * @param {Function} handler - Function to handle event
 * @param {Ref, Element} element - Element/Ref to assign event (document by default)
 * @param {Object} options - Event Options
 */

// boolean | AddEventListenerOptions | undefined
type Options = {
  capture?: boolean;
  passive?: boolean;
  once?: boolean;
};

function useEventListener<KD extends keyof DocumentEventMap>(
  eventName: KD,
  handler: (evt: DocumentEventMap[KD]) => void,
  element?: Document | null | undefined,
  options?: Options,
): void;

function useEventListener<KH extends keyof HTMLElementEventMap>(
  eventName: KH,
  handler: (evt: HTMLElementEventMap[KH]) => void,
  element?: HTMLElement | null | undefined,
  options?: Options,
): void;

function useEventListener<KW extends keyof WindowEventMap>(
  eventName: KW,
  handler: (evt: WindowEventMap[KW]) => void,
  element?: Window | null | undefined,
  options?: Options,
): void;

function useEventListener(
  eventName: string,
  handler: (evt: Event) => void,
  element?: Document | HTMLElement | Window | null | undefined,
  options?: Options,
): void;

function useEventListener<
  KD extends keyof DocumentEventMap,
  KH extends keyof HTMLElementEventMap,
  KW extends keyof WindowEventMap,
>(
  eventName: KD | KH | KW | string,
  handler: (
    evt: DocumentEventMap[KD] | HTMLElementEventMap[KH] | WindowEventMap[KW] | Event,
  ) => void,
  element: Document | HTMLElement | Window | null = window,
  options?: Options,
): void {
  const savedHandler = useRef<typeof handler | null>(null);
  const { capture, passive, once } = options || {};

  useLayoutEffect(() => {
    if (handler) {
      savedHandler.current = handler;
    }
  }, [handler]);

  useLayoutEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) {
      return;
    }

    const eventListener = (event: Event) => savedHandler?.current?.(event);

    const opts = {
      capture,
      passive,
      once,
    };

    element.addEventListener(eventName, eventListener, opts);

    return () => {
      element.removeEventListener(eventName, eventListener, opts);
    };
  }, [eventName, element, capture, passive, once]);
}

export default useEventListener;
