export enum NotificationSeverity {
  Info = 0,
  Warning = 1,
  Error = 2,
}

export type Notification = {
  deviceName: string;
  timeStampUTC: string;
  severityLevel: NotificationSeverity;
  message: string;
};
