export enum PlayBackSoundOptionId {
  CAR_HORN = 'car-horn',
}

export type PlaybackSound = {
  id: PlayBackSoundOptionId;
  filename: string;
  name: string;
  wavPath: string;
};

export const PLAYBACK_SOUND_OPTIONS: Record<PlayBackSoundOptionId, PlaybackSound> = {
  [PlayBackSoundOptionId.CAR_HORN]: {
    id: PlayBackSoundOptionId.CAR_HORN,
    filename: 'car_horn.wav',
    name: 'Car Horn',
    wavPath: '/catkinWorkspace/src/spotcam_ptz/src/spotcam_sounds/car_horn.wav',
  },
};

export const PLAYBACK_SOUND_OPTIONS_LIST = Object.values(PLAYBACK_SOUND_OPTIONS);
