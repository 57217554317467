import { PropsWithChildren, createContext, useEffect, useMemo } from 'react';

import { useLogRosBridge } from 'store/logger';

import { RosbridgeHubEvents, useRosbridgeHub } from 'hooks/signalR/useRosbridgeHub';

import { RosSignalR } from 'lib/roslibjs';

/**
 * Generate a instance of the extended version of the ros client
 *
 * @return
 */
function useInitRosBridgeProvider() {
  const hub = useRosbridgeHub({});
  const { logRosBridge } = useLogRosBridge();

  const ros: RosSignalR = useMemo(
    () => new RosSignalR(hub.RosbridgeHubClient.sendToRosbridge, logRosBridge),
    [],
  );

  useEffect(() => {
    hub.subscribe(RosbridgeHubEvents.ReceiveRosBridgeMessage, (msg) =>
      ros.handleReceiveMessage(msg),
    );

    return () => hub.unsubscribe(RosbridgeHubEvents.ReceiveRosBridgeMessage);
  }, []);

  return {
    ros,
  };
}

export const RosBridgeContext = createContext<{ ros: RosSignalR | null }>({ ros: null });

export function RosBridgeProvider({ children }: PropsWithChildren) {
  const { ros } = useInitRosBridgeProvider();
  return <RosBridgeContext.Provider value={{ ros }}>{children}</RosBridgeContext.Provider>;
}
