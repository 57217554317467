import { ChangeEvent, memo } from 'react';

import { ThemeColorKeys } from 'types/ui/Theme';

import { SInputContainer, SInputDescription, SInputError, SLabel } from './Input.styled';

type InputProps = {
  id?: string;
  type: string;
  name?: string;
  label?: string;
  defaultValue?: string;
  description?: string;
  value?: string | number;
  placeholder?: string;
  errorMessage?: string | boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
};

const isEmpty = (value?: string | number | null | false) => {
  return value === null || value === undefined || value === false;
};

const Input = memo<InputProps>(
  ({
    id,
    type,
    name,
    label,
    defaultValue,
    value,
    placeholder,
    errorMessage,
    onChange,
    required,
    disabled,
    description,
  }) => (
    <SInputContainer isError={!!errorMessage}>
      {label && <SLabel htmlFor={id}>{label}</SLabel>}
      <input
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
        disabled={disabled}
        {...(!isEmpty(defaultValue) && { defaultValue })}
        {...(!isEmpty(value) && { value })}
      />
      <div className="flex w-full justify-between">
        <SInputDescription color={ThemeColorKeys.TEXT_DARK} variant="caption">
          {description}
        </SInputDescription>
        {errorMessage && (
          <SInputError color={ThemeColorKeys.RED} variant="caption" align="right">
            {errorMessage}
          </SInputError>
        )}
      </div>
    </SInputContainer>
  ),
);

export default Input;
