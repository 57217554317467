import styled, { css } from 'styled-components';

import { generateButtonStyles, generateSpacings } from 'components/ui/index';

import { Spacings, ThemeColorKeys } from 'types/ui/Theme';

type ButtonProps = {
  variant?: 'contained' | 'outline' | 'text';
  pos?: 'static' | 'relative' | 'fixed' | 'absolute' | 'sticky';
  bgcolor?: `${ThemeColorKeys}`;
  loading?: boolean;
  round?: boolean;
  w?: string;
} & Partial<Spacings>;

const SButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['pos', 'loading', 'round'].includes(prop),
})<ButtonProps>`
  cursor: pointer;
  transition: all 0.3s;
  color: #fff;
  border: 1px solid transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  white-space: nowrap;
  user-select: none;
  position: ${(props) => props.pos && props.pos};
  font-size: ${(props) => props.theme.fontSizes.xxs};
  padding: ${(props) => (props.round ? props.theme.spacing(2) : props.theme.spacing(1, 2))};
  border-radius: ${(props) => (props.round ? '100%' : props.theme.borderRadius.md)};
  width: ${(props) => props.w && props.w};
  ${(props) => generateSpacings(props)};
  ${(props) => generateButtonStyles(props)};

  &:disabled {
    opacity: 0.75;
    pointer-events: none;
  }

  ${(props) =>
    props.loading &&
    css`
      position: relative;
      pointer-events: none;
      color: transparent;

      &::after {
        content: '';
        position: absolute;
        box-sizing: border-box;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 3px solid ${props.theme.colors.primary.main};
        border-top: 3px solid ${props.theme.colors.background.main};
        background-color: ${props.theme.colors.background.main};
        box-shadow: 0 0 0 4px ${props.theme.colors.background.main};
        animation: spin 0.7s linear infinite;

        @keyframes spin {
          0% {
            transform: translate(-50%, -50%) rotate(0deg);
          }
          100% {
            transform: translate(-50%, -50%) rotate(360deg);
          }
        }
      }
    `};
`;

export default SButton;
