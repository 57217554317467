import { BugIcon } from 'lucide-react';
import React, { useId } from 'react';

import { STypography } from 'components/ui';
import { Button } from 'components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { Text } from 'components/ui/typography';

import {
  ROS_BRIDGE_LOGGING,
  RosBridgeLogging,
  useLogRosBridgeSetter,
  useLogSignalRInit,
  useLogSignalRInvoke,
  useLogSignalRReceive,
} from 'store/logger';
import { useLogSignalRRelaySetter } from 'store/logger/useLogSignalRRelay';
import { ROBOT_ROS_TOPICS, RobotRosTopic } from 'store/my-robot/relay-topics';

import { HUB_URL } from 'hooks/signalR';

import { appVersion } from 'lib/constants/env-variables';

import { SNavigationDivider, SNavigationItem, SNavigationLink } from '../styled';

type SwitchLoggingProps = {
  label: string;
  log: boolean;
  color: string;
  updateLog: (log: boolean) => void;
  updateColor: (color: string) => void;
};

function SwitchLogging({ log, color, label, updateLog, updateColor }: SwitchLoggingProps) {
  const id = useId();

  function handleUpdateColor(event: React.ChangeEvent<HTMLInputElement>) {
    const color = event.target.value;
    updateColor(color);
  }

  return (
    <div className="grid grid-cols-3 items-center gap-4">
      <Label htmlFor={id} className="col-span-2">
        {label}
      </Label>
      <div className="flex items-center space-x-2">
        <Switch id={id} checked={log} onCheckedChange={updateLog} />
        <input
          className="h-7 w-6 block cursor-pointer p-0 bg-transparent"
          type="color"
          id="head"
          name="head"
          value={color}
          onChange={handleUpdateColor}
        />
      </div>
    </div>
  );
}

function SwitchLoggingTopic({ topic, label }: { topic: RobotRosTopic; label?: string }) {
  const { log, color, updateLog, updateColor } = useLogSignalRRelaySetter(topic);

  return (
    <SwitchLogging
      label={label ?? topic}
      log={log}
      updateLog={updateLog}
      color={color}
      updateColor={updateColor}
    />
  );
}

function SwitchLoggingRos({ topic, label }: { topic: RosBridgeLogging; label?: string }) {
  const { log, color, updateLog, updateColor } = useLogRosBridgeSetter(topic);

  return (
    <SwitchLogging
      label={label ?? topic}
      log={log}
      updateLog={updateLog}
      color={color}
      updateColor={updateColor}
    />
  );
}

function SwitchLoggingHubInit({
  hub,
  label,
  description,
}: {
  hub: HUB_URL;
  label: string;
  description: string;
}) {
  const { colorInit, logInit, updateColorInit, updateLogInit } = useLogSignalRInit(hub);

  return (
    <div>
      <SwitchLogging
        label={label}
        log={logInit}
        updateLog={updateLogInit}
        color={colorInit}
        updateColor={updateColorInit}
      />
      <Text variant="description">{description}</Text>
    </div>
  );
}

function SwitchLoggingHubInvoke({
  hub,
  label,
  description,
}: {
  hub: HUB_URL;
  label: string;
  description: string;
}) {
  const { colorInvoke, logInvoke, updateColorInvoke, updateLogInvoke } = useLogSignalRInvoke(hub);

  return (
    <div>
      <SwitchLogging
        label={label}
        log={logInvoke}
        updateLog={updateLogInvoke}
        color={colorInvoke}
        updateColor={updateColorInvoke}
      />
      <Text variant="description">{description}</Text>
    </div>
  );
}

function SwitchLoggingHubReceive({
  hub,
  label,
  description,
}: {
  hub: HUB_URL;
  label: string;
  description: string;
}) {
  const { colorReceive, logReceive, updateColorReceive, updateLogReceive } =
    useLogSignalRReceive(hub);

  return (
    <div>
      <SwitchLogging
        label={label}
        log={logReceive}
        updateLog={updateLogReceive}
        color={colorReceive}
        updateColor={updateColorReceive}
      />
      <Text variant="description">{description}</Text>
    </div>
  );
}

function HubSwitches({ hub }: { hub: HUB_URL }) {
  return (
    <div className="gap-4 grid grid-cols-3 ">
      <SwitchLoggingHubInit
        hub={hub}
        label="Init"
        description="Connection/initialization related logs"
      />
      <SwitchLoggingHubInvoke
        hub={hub}
        label="Invoke"
        description="⬆️ Outgoing messages (invokations)"
      />
      <SwitchLoggingHubReceive
        hub={hub}
        label="Receive"
        description="📩 Incoming messages (subscribtions)"
      />
    </div>
  );
}

function ManageConsoleLogs() {
  return (
    <Tabs defaultValue={HUB_URL.IOTHUB} className="space-y-3">
      <TabsList>
        <TabsTrigger value={HUB_URL.TELEOPERATION}>Teleoperation</TabsTrigger>
        <TabsTrigger value={HUB_URL.TELEMETRY}>Telemetry</TabsTrigger>
        <TabsTrigger value={HUB_URL.SPOTCONTROL}>Spotcontrol</TabsTrigger>
        <TabsTrigger value={HUB_URL.IOTHUB}>Iothub</TabsTrigger>
        <TabsTrigger value={HUB_URL.ROSBRIDGE}>RosBridge</TabsTrigger>
      </TabsList>
      <TabsContent value={HUB_URL.TELEOPERATION}>
        <Card>
          <CardHeader>
            <CardTitle>Teleoperation</CardTitle>
            <CardDescription>
              Toggle witch logs to show in the console and change color
            </CardDescription>
          </CardHeader>
          <CardContent>
            <HubSwitches hub={HUB_URL.TELEOPERATION} />
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value={HUB_URL.TELEMETRY}>
        <Card>
          <CardHeader>
            <CardTitle>Telemetry</CardTitle>
            <CardDescription>
              Toggle witch logs to show in the console and change color
            </CardDescription>
          </CardHeader>
          <CardContent>
            <HubSwitches hub={HUB_URL.TELEMETRY} />
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value={HUB_URL.SPOTCONTROL}>
        <Card>
          <CardHeader>
            <CardTitle>Spotcontrol</CardTitle>
            <CardDescription>
              Toggle witch logs to show in the console and change color
            </CardDescription>
          </CardHeader>
          <CardContent>
            <HubSwitches hub={HUB_URL.SPOTCONTROL} />
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value={HUB_URL.IOTHUB} className="space-y-3">
        <Card>
          <CardHeader>
            <CardTitle>Iothub</CardTitle>
            <CardDescription>
              Toggle witch logs to show in the console and change color
            </CardDescription>
          </CardHeader>
          <CardContent>
            <HubSwitches hub={HUB_URL.IOTHUB} />
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Relay Topics</CardTitle>
            <CardDescription>
              You can choose from with ros-topic you want to see logs. Switch off "Receive" above.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid gap-2 grid-cols-2">
              {ROBOT_ROS_TOPICS.map((topic) => (
                <SwitchLoggingTopic topic={topic} key={topic} />
              ))}
            </div>
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value={HUB_URL.ROSBRIDGE} className="space-y-3">
        <Card>
          <CardHeader>
            <CardTitle>Rosbridge</CardTitle>
            <CardDescription>
              Toggle witch logs to show in the console and change color
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid gap-2 grid-cols-2">
              {ROS_BRIDGE_LOGGING.map((topic) => (
                <SwitchLoggingRos topic={topic} key={topic} />
              ))}
            </div>
          </CardContent>
        </Card>
      </TabsContent>
    </Tabs>
  );
}

function DebugStates() {
  return <div>{/*<OperatorDebugInformation />*/}</div>;
}

function DebugMenuDialogContent() {
  return (
    <DialogContent className="sm:max-w-[900px]">
      <DialogHeader>
        <DialogTitle>Debug Menu</DialogTitle>
      </DialogHeader>
      <Tabs defaultValue="signalR" className="space-y-3">
        <TabsList>
          <TabsTrigger value="signalR">Signal R</TabsTrigger>
          <TabsTrigger value="ros">Ros</TabsTrigger>
          <TabsTrigger value="states">States</TabsTrigger>
        </TabsList>
        <TabsContent value="signalR">
          <ManageConsoleLogs />
        </TabsContent>
        <TabsContent value="states">
          <DebugStates />
        </TabsContent>
      </Tabs>
      <DialogFooter>
        <div className="flex justify-between flex-1">
          <Text variant="description">App version: {appVersion}</Text>
          <DialogClose asChild>
            <Button type="submit">Close</Button>
          </DialogClose>
        </div>
      </DialogFooter>
    </DialogContent>
  );
}

/**
 * ned a manual set local storage entry
 * @constructor
 */
function DebugMenu() {
  return (
    <Dialog>
      <SNavigationItem>
        <SNavigationDivider />
      </SNavigationItem>
      <DialogTrigger asChild>
        <SNavigationItem>
          <SNavigationLink as="button" className="">
            <BugIcon className="" />
            <STypography variant="subtitle2">Debug Info</STypography>
          </SNavigationLink>
        </SNavigationItem>
      </DialogTrigger>
      <DebugMenuDialogContent />
    </Dialog>
  );
}

export default DebugMenu;
