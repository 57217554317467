'use client';

import { useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { Suspense, useState } from 'react';

import { Page } from 'components/common';
import { Button } from 'components/ui/button';
import { Label } from 'components/ui/label';
import { ScrollArea } from 'components/ui/scroll-area';
import { Skeleton } from 'components/ui/skeleton';
import { Text } from 'components/ui/typography';
import Heading from 'components/ui/typography/Heading';

import { useDeviceName } from 'store/my-robot';

import { DEVICE_NAME_SERVICES, ROS_API, useRosApi, useRosService } from 'hooks/ros';

import { Combobox } from './combobox';

function useRosBridgeHandler() {
  const { RosServices } = useRosService();

  async function sendUndock() {
    return await RosServices.sendUndock();
  }

  async function sendDock() {
    return await RosServices.sendDock().then((res: any) => console.log({ res }));
  }

  return {
    sendUndock,
    sendDock,
  };
}

function GetServiceType({ service }: { service: string }) {
  const { RosApi } = useRosApi();
  const { data } = useSuspenseQuery({
    queryKey: [ROS_API.SERVICE_TYPE, service],
    queryFn: () => RosApi.getServiceType(service),
  });
  const type = data.type || 'Select a service';

  return (
    <div>
      <Text variant="description">{type}</Text>
      <div className="flex gap-2 overflow-auto">
        <div className="flex-1 space-y-1">
          <Label>Service request details</Label>
          <Suspense>
            <GetServiceRequestDetails type={type} />
          </Suspense>
        </div>
        <div className="flex-1 space-y-1">
          <Label>Service response details</Label>
          <Suspense>
            <GetServiceResponseDetails type={type} />
          </Suspense>
        </div>
      </div>
    </div>
  );
}

function GetServiceRequestDetails({ type }: { type: string }) {
  const { RosApi } = useRosApi();
  const { data } = useSuspenseQuery({
    queryKey: [ROS_API.SERVICE_REQUEST_DETAILS, type],
    queryFn: () => RosApi.getServiceRequestDetails(type),
  });
  const { typedefs } = data;

  return (
    <ScrollArea>
      <pre className="rounded-md bg-slate-950 p-4">
        <code className="text-white">{JSON.stringify(typedefs, null, 2)}</code>
      </pre>
    </ScrollArea>
  );
}

function GetServiceResponseDetails({ type }: { type: string }) {
  const { RosApi } = useRosApi();
  const { data } = useSuspenseQuery({
    queryKey: [ROS_API.SERVICE_RESPONSE_DETAILS, type],
    queryFn: () => RosApi.getServiceResponseDetails(type),
  });
  const { typedefs } = data;

  return (
    <pre className="rounded-md bg-slate-950 p-4">
      <code className="text-white">{JSON.stringify(typedefs, null, 2)}</code>
    </pre>
  );
}

function GetServices() {
  const { RosApi } = useRosApi();
  const [selectedService, setSelectedService] = useState<string>('');
  const { data } = useSuspenseQuery({
    queryKey: [ROS_API.SERVICES],
    queryFn: () => RosApi.getServices(),
  });
  const items = data.services.map((s) => ({ value: s, label: s }));

  return (
    <>
      <Combobox
        value={selectedService}
        setValue={setSelectedService}
        items={items}
        nameOfItem="service"
      />
      <div>
        <Label>Type of selected service:</Label>
        {selectedService ? (
          <Suspense fallback={<Skeleton className="h-5 w-[250px]" />}>
            <GetServiceType service={selectedService} />
          </Suspense>
        ) : (
          <Text variant="description">Select a service</Text>
        )}
      </div>
    </>
  );
}

function GetNodeDetails({ node }: { node: string }) {
  const { RosApi } = useRosApi();
  const { data } = useSuspenseQuery({
    queryKey: [ROS_API.NODE_DETAILS, node],
    queryFn: () => RosApi.getNodeDetails(node),
  });
  const { services, publishing, subscribing } = data;

  if (!node.length) return <Text variant="description">Select a node</Text>;

  return (
    <div className="flex fap-4 flex-wrap">
      <div className="border p-2 rounded">
        <Label>Services:</Label>
        <div className="flex flex-col">
          {services.map((s) => (
            <Text key={s} variant="description">
              {s}
            </Text>
          ))}
        </div>
      </div>
      <div className="border p-2 rounded">
        <Label>Subscribed topics:</Label>
        <div className="flex flex-col">
          {subscribing.map((s) => (
            <Text key={s} variant="description">
              {s}
            </Text>
          ))}
        </div>
      </div>
      <div className="border p-2 rounded">
        <Label>Publishing topics</Label>
        <div className="flex flex-col">
          {publishing.map((s) => (
            <Text key={s} variant="description">
              {s}
            </Text>
          ))}
        </div>
      </div>
    </div>
  );
}

function GetNodes() {
  const { RosApi } = useRosApi();
  const [selectedNode, setSelectedNode] = useState<string>('');
  const { data } = useSuspenseQuery({
    queryKey: [ROS_API.NODES],
    queryFn: () => RosApi.getNodes(),
  });
  const items = data.nodes.map((s) => ({ value: s, label: s }));

  return (
    <>
      <Combobox value={selectedNode} setValue={setSelectedNode} items={items} nameOfItem="node" />
      <div>
        <Label>Type of selected service:</Label>
        {selectedNode.length ? (
          <Suspense fallback={<Skeleton className="h-5 w-[250px]" />}>
            <GetNodeDetails node={selectedNode} />
          </Suspense>
        ) : (
          <Text variant="description">Select a node</Text>
        )}
      </div>
    </>
  );
}

function GetActions() {
  const { RosApi } = useRosApi();
  const [selectedAction, setSelectedAction] = useState<string>('');

  const { data } = useSuspenseQuery({
    queryKey: [ROS_API.ACTIONS],
    queryFn: () => RosApi.getActionServers(),
  });

  const items = data.action_servers.map((s) => ({ value: s, label: s }));

  return (
    <>
      <Combobox
        value={selectedAction}
        setValue={setSelectedAction}
        items={items}
        nameOfItem="action"
      />
    </>
  );
}

function GetTopics() {
  const [selectedTopic, setSelectedTopic] = useState<string>('');
  const { RosApi } = useRosApi();
  const { data } = useSuspenseQuery({
    queryKey: [ROS_API.TOPICS],
    queryFn: () => RosApi.getTopics(),
  });

  const { topics, types } = data;
  const items = topics.map((s) => ({ value: s, label: s }));

  const selectedTopicType = types[topics.indexOf(selectedTopic)];
  return (
    <>
      <Combobox
        value={selectedTopic}
        setValue={setSelectedTopic}
        items={items}
        nameOfItem="topic"
      />
      <div className="flex space-x-4">
        <div>
          <Label>Type of selected topic</Label>:
          <Text variant="description">{selectedTopicType}</Text>
        </div>
        <div>
          <Label>Type of selected topic (loaded):</Label>
          {selectedTopic.length ? (
            <Suspense fallback={<Skeleton className="h-5 w-[250px]" />}>
              <GetTopicType topic={selectedTopic} />
            </Suspense>
          ) : (
            <Text variant="description">Select a topic</Text>
          )}
        </div>
      </div>
    </>
  );
}

function GetTopicsAndRawTypes() {
  const [selectedTopic, setSelectedTopic] = useState<string>('');
  const { RosApi } = useRosApi();
  const { data } = useSuspenseQuery({
    queryKey: [ROS_API.TOPICS_AND_RAW_TYPES],
    queryFn: () => RosApi.getTopicsAndRawTypes(),
  });

  const { topics, types, typedefs_full_text } = data;
  const items = topics.map((s) => ({ value: s, label: s }));

  const selectedTopicType = types[topics.indexOf(selectedTopic)];
  const selectedTypeDef = typedefs_full_text[topics.indexOf(selectedTopic)];

  return (
    <>
      <Combobox
        value={selectedTopic}
        setValue={setSelectedTopic}
        items={items}
        nameOfItem="topic"
      />
      <div className="flex space-x-4">
        <div>
          <Label>Type of selected topic</Label>:
          <Text variant="description">{selectedTopicType}</Text>
        </div>
        <div>
          <Label>Type definition of selected topic</Label>:
          <Text variant="description">{selectedTypeDef}</Text>
        </div>
      </div>
    </>
  );
}

function GetTopicType({ topic }: { topic: string }) {
  const { RosApi } = useRosApi();
  const { data } = useSuspenseQuery({
    queryKey: [ROS_API.TOPIC_TYPE, topic],
    queryFn: () => RosApi.getTopicType(topic),
  });
  const type = data.type || 'Select a topic';

  return <Text variant="description">{type}</Text>;
}

const TestRoslibjs = () => {
  const { RosApi } = useRosApi();
  const { deviceName } = useDeviceName();
  const { sendUndock, sendDock } = useRosBridgeHandler();
  const queryClient = useQueryClient();

  const [loadTopics, setLoadTopics] = useState(false);

  const topicsQuery = useQuery({
    queryKey: ['ros', 'topics'],
    queryFn: RosApi.getTopics,
    enabled: loadTopics,
  });
  const { data: topics } = topicsQuery;

  const nodesQuery = useQuery({
    queryKey: ['ros', 'nodes'],
    enabled: false,
  });
  const { data: nodes } = nodesQuery;

  console.log('nodes', { nodesQuery, nodes });
  console.log('topics', { topicsQuery, topics });

  async function handleGetNodes() {
    const res = await RosApi.getNodes();
    console.log({ res });
    queryClient.setQueryData(['ros', 'nodes'], res.nodes);
  }

  return (
    <Page title="Test roslibjs and rosapi">
      <div className="p-4 space-y-4">
        <Heading>testing with setting enabled state</Heading>
        <Button onClick={() => setLoadTopics(true)} variant="outline">
          rosapi/Topics
        </Button>
        <Heading>testing with updating query directly</Heading>
        <Button onClick={handleGetNodes} variant="outline">
          /rosapi/nodes (handleGetNodes)
        </Button>
        <br />
        <div className="flex flex-col space-y-2">
          <Text variant="code">{DEVICE_NAME_SERVICES.UNDOCK(deviceName)}</Text>
          <div>
            <Button onClick={sendUndock} variant="outline">
              undock
            </Button>
          </div>
          <Text variant="code">{DEVICE_NAME_SERVICES.DOCK(deviceName)}</Text>
          <div>
            <Button onClick={sendDock} variant="outline">
              dock
            </Button>
          </div>
        </div>
        {/*<div className="flex flex-col space-y-0">*/}
        {/*  <Button onClick={handleAction} variant="outline">*/}
        {/*    Handle Action*/}
        {/*  </Button>*/}
        {/*</div>*/}
        <div className="space-y-2">
          <Heading variant="sectionHeading">Nodes</Heading>
          <Suspense fallback="loading nodes">
            <GetNodes />
          </Suspense>
        </div>
        <div className="space-y-2">
          <Heading variant="sectionHeading">Services</Heading>
          <Suspense fallback="loading services">
            <GetServices />
          </Suspense>
        </div>
        <div className="space-y-2">
          <Heading variant="sectionHeading">Actions</Heading>
          <Suspense fallback="loading actions">
            <GetActions />
          </Suspense>
        </div>
        <div className="space-y-2">
          <Heading variant="sectionHeading">Topics</Heading>
          <Suspense fallback="loading topics">
            <GetTopics />
          </Suspense>
        </div>
        <div className="space-y-2">
          <Heading variant="sectionHeading">Topics and raw types</Heading>
          <Suspense fallback="loading topics and raw types">
            <GetTopicsAndRawTypes />
          </Suspense>
        </div>
      </div>
    </Page>
  );
};

export default TestRoslibjs;
