import { memo, useMemo } from 'react';

import { SBox, STypography } from 'components/ui';

import { SBatteryIcon, SChargeIcon, SIconContainer } from './BatteryStatus.styled';

/**
 * Hook to display battery info
 * @param {String} status - CHARGING | DISCHARGING | FULL
 * @param {Number} percentage - [0,100] float
 * @param {Number} remainingRuntime - int, in seconds, 0 if charging or unknown
 */

type BatteryStatusProps = {
  status?: 'CHARGING' | string; // TODO -> add other options
  percentage: number;
};

const BatteryStatus = memo<BatteryStatusProps>(({ status, percentage }) => {
  const batteryColor = useMemo(() => {
    switch (true) {
      case percentage >= 70 && percentage <= 100: {
        return 'green';
      }
      case percentage >= 30 && percentage < 70: {
        return 'yellow';
      }
      case percentage >= 0 && percentage < 30: {
        return 'red';
      }
      default: {
        return 'neutral';
      }
    }
  }, [percentage]);

  const isBatteryAvailable = useMemo(() => {
    return percentage !== undefined && percentage >= 0;
  }, [percentage]);

  return (
    <SBox display="inline-flex" align="center">
      <SIconContainer level={percentage} color={batteryColor}>
        <SBatteryIcon icon="battery_0" color={batteryColor} />
        {status === 'CHARGING' && <SChargeIcon icon="bolt" />}
      </SIconContainer>
      <STypography variant="subtitle2" color={batteryColor}>
        <b>{isBatteryAvailable ? `${Math.round(percentage)}%` : '~'}</b>
      </STypography>
    </SBox>
  );
});

export default BatteryStatus;
