import { ChevronRightIcon, EnvelopeOpenIcon, ReloadIcon } from '@radix-ui/react-icons';
import { useState } from 'react';
import styled from 'styled-components';
import { cn } from 'utils';

import { Icon, Page } from 'components/common';
import Switch from 'components/common/switch/Switch';
import BatteryStatus from 'components/complex/battery-status/BatteryStatus';
import Navbar from 'components/layouts/nav-bar';
import { SBox, STypography } from 'components/ui';
import { Button } from 'components/ui/button';
import SButton from 'components/ui/components/button/Button';
import { Label } from 'components/ui/label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { Separator } from 'components/ui/separator';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'components/ui/sheet';
import { theme } from 'components/ui/theme/Theme';
import { Heading, Text } from 'components/ui/typography';

import iconsList from 'lib/constants/IconsList';

import { ThemeColorKeys } from 'types/ui/Theme';

import BatteryAnimation from './battery-animation';

function TypographyExamples() {
  return (
    <div className="space-y-0 flex flex-col">
      <STypography variant="h1">Typography (styled components)</STypography>
      <STypography variant="h1">Heading 1</STypography>
      <STypography variant="h2">Heading 2</STypography>
      <STypography variant="h3">Heading 3</STypography>
      <STypography variant="h4">Heading 4</STypography>
      <STypography variant="h5" as="h5">
        Heading 5
      </STypography>
      <STypography variant="h6">Heading 6</STypography>
      <STypography variant="subtitle1">Subtitle 1</STypography>
      <STypography variant="subtitle2">Subtitle 2</STypography>
      <STypography variant="body1">Body 1</STypography>
      <STypography variant="body2">Body 2</STypography>
      <STypography variant="caption">Caption</STypography>
    </div>
  );
}

function ButtonExamples() {
  return (
    <div className="flex flex-col gap-4">
      <STypography variant="h1">shadcn/ui Buttons</STypography>
      <div className="flex flex-col gap-4 max-w-60">
        <Button>Primary</Button>
        <Button variant="secondary">Secondary</Button>
        <Button variant="destructive">Destructive</Button>
        <Button variant="outline">Outline</Button>
        <Button variant="ghost">Ghost</Button>
        <Button variant="link">Link</Button>
        <div className="space-x-2">
          <Button size="icon">
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button variant="secondary" size="icon">
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button variant="destructive" size="icon">
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button variant="outline" size="icon">
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button variant="ghost" size="icon">
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
        </div>
        <div className="space-x-2">
          <Button size="iconSm">
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button variant="secondary" size="iconSm">
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button variant="destructive" size="iconSm">
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button variant="outline" size="iconSm">
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button variant="ghost" size="iconSm">
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
        </div>
        <Button>
          <EnvelopeOpenIcon className="mr-2 h-4 w-4" /> Login with Email
        </Button>
        <Button disabled>
          <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
          Manuel loading icon
        </Button>
        <Button loading>prop loading</Button>
        <Button size="round">size:round</Button>
      </div>
    </div>
  );
}

function StyledButtonExamples() {
  return (
    <div className="flex flex-col gap-4">
      <STypography variant="h1">Styled Components Buttons</STypography>
      <div className="flex flex-col gap-4 max-w-60">
        <SButton>Contained</SButton>
        <SButton variant="outline">Outline</SButton>
        <SButton variant="text">Text</SButton>
        <SButton loading>Loading</SButton>
        <SButton round>Round</SButton>
        <SButton bgcolor={ThemeColorKeys.NEUTRAL}>Bg:Neutral</SButton>
        <SButton bgcolor={ThemeColorKeys.RED}>Bg:Red</SButton>
        <SButton bgcolor={ThemeColorKeys.GREEN}>Bg:Green</SButton>
        <SButton bgcolor={ThemeColorKeys.WHITE}>Bg:White</SButton>
        <SButton bgcolor={ThemeColorKeys.YELLOW}>Bg:Yellow</SButton>
        <SButton bgcolor={ThemeColorKeys.BORDER}>Bg:Border</SButton>
      </div>
    </div>
  );
}

function ColorBox({ className }: { className: string }) {
  return (
    <div className="flex flex-col justify-center gap-1">
      <div className={cn('h-20 w-20 p-2 ring-1 ring-black ring-offset-2', `${className}`)} />
      <span className="text-muted-foreground text-xs">{className.replace('bg-', '')}</span>
    </div>
  );
}

function ColorShadncnUIExamples() {
  return (
    <div className="space-y-4 pb-20">
      <STypography variant="h3">shadcn/ui colors</STypography>
      <STypography variant="subtitle1" as="h5">
        Default background color of {'<body />'} ...etc
      </STypography>
      <div className="flex gap-4">
        <ColorBox className="bg-background" />
        <ColorBox className="bg-foreground" />
      </div>
      <STypography variant="subtitle1" as="h5">
        Muted backgrounds such as {'<TabsList />, <Skeleton /> and <Switch />'}
      </STypography>
      <div className="flex gap-4">
        <ColorBox className="bg-muted" />
        <ColorBox className="bg-muted-foreground" />
      </div>
      <STypography variant="subtitle1" as="h5">
        Background color for {'<Card />'}
      </STypography>
      <div className="flex gap-4">
        <ColorBox className="bg-card" />
        <ColorBox className="bg-card-foreground" />
      </div>
      <STypography variant="subtitle1" as="h5">
        Background color for popovers such as {'<DropdownMenu />, <HoverCard />, <Popover />'}
      </STypography>
      <div className="flex gap-4">
        <ColorBox className="bg-popover" />
        <ColorBox className="bg-popover-foreground" />
      </div>
      <STypography variant="subtitle1" as="h5">
        Default border color
      </STypography>
      <div className="flex gap-4">
        <ColorBox className="bg-border" />
      </div>
      <STypography variant="subtitle1" as="h5">
        Border color for inputs such as {'<Input />, <Select />, <Textarea />'}
      </STypography>
      <div className="flex gap-4">
        <ColorBox className="bg-input" />
      </div>
      <STypography variant="subtitle1" as="h5">
        Primary colors for {'<Button />'}
      </STypography>
      <div className="flex gap-4">
        <ColorBox className="bg-primary" />
        <ColorBox className="bg-primary-foreground" />
      </div>
      <STypography variant="subtitle1" as="h5">
        Secondary colors for {'<Button />'}
      </STypography>
      <div className="flex gap-4">
        <ColorBox className="bg-secondary" />
        <ColorBox className="bg-secondary-foreground" />
      </div>
      <STypography variant="subtitle1" as="h5">
        Used for accents such as hover effects on {'<DropdownMenuItem>, <SelectItem>'} ...etc
      </STypography>
      <div className="flex gap-4">
        <ColorBox className="bg-accent" />
        <ColorBox className="bg-accent-foreground" />
      </div>
      <STypography variant="subtitle1" as="h5">
        Used for destructive actions such as {'<Button variant="destructive">'}
      </STypography>
      <div className="flex gap-4">
        <ColorBox className="bg-destructive" />
        <ColorBox className="bg-destructive-foreground" />
      </div>
      <div className="flex gap-4">
        <ColorBox className="bg-warning" />
        <ColorBox className="bg-warning-foreground" />
      </div>
      <STypography variant="subtitle1" as="h5">
        Used for focus ring
      </STypography>
      <div className="flex gap-4">
        <ColorBox className="bg-ring" />
      </div>
    </div>
  );
}

function ColorTailwindExamples() {
  return (
    <div className="space-y-4 pb-20">
      <STypography variant="h2" as="h2">
        Tailwind gray(s) colors - just for example
      </STypography>
      <div className="flex gap-4">
        <ColorBox className="bg-gray-50" />
        <ColorBox className="bg-gray-100" />
        <ColorBox className="bg-gray-200" />
        <ColorBox className="bg-gray-300" />
        <ColorBox className="bg-gray-300" />
        <ColorBox className="bg-gray-400" />
        <ColorBox className="bg-gray-500" />
        <ColorBox className="bg-gray-600" />
        <ColorBox className="bg-gray-700" />
        <ColorBox className="bg-gray-800" />
        <ColorBox className="bg-gray-900" />
      </div>
    </div>
  );
}

export const SBoxColor = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  height: 100%;
  width: 100%;
`;

function StyledBoxColor({ color, label }: { color: string; label: string }) {
  return (
    <div className="flex flex-col justify-center gap-1">
      <div className="h-24 w-24 ring-1 ring-black ring-offset-2">
        <SBoxColor color={color}></SBoxColor>
      </div>
      <span className="text-muted-foreground text-xs">{label}</span>
    </div>
  );
}

function StyledColors() {
  return (
    <div className="space-y-4 pb-20">
      <STypography variant="h2" as="h5">
        Styled components theme colors
      </STypography>
      <STypography variant="h4" as="h4">
        Primary
      </STypography>
      <div className="flex gap-4">
        <StyledBoxColor
          color={theme.colors.primary.main}
          label={`main (${theme.colors.primary.main})`}
        />
        <StyledBoxColor
          color={theme.colors.primary.dark}
          label={`dark (${theme.colors.primary.dark})`}
        />
        <StyledBoxColor
          color={theme.colors.primary.light}
          label={`light (${theme.colors.primary.light})`}
        />
      </div>
      <STypography variant="h4" as="h4">
        Background
      </STypography>
      <div className="flex gap-4">
        <StyledBoxColor
          color={theme.colors.background.main}
          label={`main (${theme.colors.primary.main})`}
        />
        <StyledBoxColor
          color={theme.colors.background.dark}
          label={`dark (${theme.colors.primary.dark})`}
        />
        <StyledBoxColor
          color={theme.colors.background.light}
          label={`light (${theme.colors.primary.light})`}
        />
      </div>
      <STypography variant="h4" as="h4">
        Text
      </STypography>
      <div className="flex gap-4">
        <StyledBoxColor
          color={theme.colors.text.main}
          label={`main (${theme.colors.primary.main})`}
        />
        <StyledBoxColor
          color={theme.colors.text.dark}
          label={`dark (${theme.colors.primary.dark})`}
        />
        <StyledBoxColor
          color={theme.colors.text.light}
          label={`light (${theme.colors.primary.light})`}
        />
      </div>
      <STypography variant="h4" as="h4">
        General
      </STypography>
      <div className="flex gap-4">
        <StyledBoxColor color={theme.colors.white} label={`white (${theme.colors.white})`} />
        <StyledBoxColor color={theme.colors.neutral} label={`neutral (${theme.colors.neutral})`} />
        <StyledBoxColor color={theme.colors.border} label={`border (${theme.colors.border})`} />
      </div>
      <div className="flex gap-4">
        <StyledBoxColor color={theme.colors.red} label={`neutral (${theme.colors.neutral})`} />
        <StyledBoxColor color={theme.colors.yellow} label={`yellow (${theme.colors.yellow})`} />
        <StyledBoxColor color={theme.colors.green} label={`green (${theme.colors.green})`} />
      </div>
    </div>
  );
}

export function SheetDemo() {
  return (
    <div className="space-y-4">
      <STypography variant="h2" as="h2">
        Sheet Demo - click the button to open the sheet
      </STypography>
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline">Open</Button>
        </SheetTrigger>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Edit profile</SheetTitle>
            <SheetDescription>
              Make changes to your profile here. Click save when you're done.
            </SheetDescription>
          </SheetHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <label htmlFor="name" className="text-right">
                Name
              </label>
              <input id="name" className="col-span-3" />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <label htmlFor="username" className="text-right">
                Username
              </label>
              <input id="username" className="col-span-3" />
            </div>
          </div>
          <SheetFooter>
            <SheetClose asChild>
              <Button type="submit">Save changes</Button>
            </SheetClose>
          </SheetFooter>
        </SheetContent>
      </Sheet>
    </div>
  );
}

export function SelectDemo() {
  return (
    <div className="space-y-4">
      <STypography variant="h2" as="h2">
        Select Demo - click the button to open the select
      </STypography>
      <Select>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Select a fruit" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Fruits</SelectLabel>
            <SelectItem value="apple">Apple</SelectItem>
            <SelectItem value="banana">Banana</SelectItem>
            <SelectItem value="blueberry">Blueberry</SelectItem>
            <SelectItem value="grapes">Grapes</SelectItem>
            <SelectItem value="pineapple">Pineapple</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}

export function SwitchDemo() {
  const [checked, setChecked] = useState(false);
  return (
    <div className="space-y-4">
      <STypography variant="h2" as="h2">
        Switch Demo
      </STypography>
      <Switch label="Label for switch" checked={checked} onChange={() => setChecked(!checked)} />
      <Switch label="Disabled" checked={checked} onChange={() => setChecked(!checked)} />
    </div>
  );
}

function BatteryStatusExamples() {
  return (
    <SBox p={5}>
      <BatteryAnimation />
      <BatteryStatus status="FULL" percentage={11} />
      <div
        style={{
          display: 'inline-block',
          marginRight: '20px',
        }}
      >
        <BatteryStatus status="CHARGING" percentage={-1} />
      </div>
      {[...Array(101)].map((_, i) => (
        <div
          key={i}
          style={{
            display: 'inline-block',
            marginRight: '20px',
          }}
        >
          <BatteryStatus status="CHARGING" percentage={i} />
        </div>
      ))}
      <div
        style={{
          display: 'inline-block',
          marginRight: '20px',
        }}
      >
        <BatteryStatus status="DISCHARGING" percentage={-1} />
      </div>
      {[...Array(101)].map((_, i) => (
        <div
          key={i}
          style={{
            display: 'inline-block',
            marginRight: '20px',
          }}
        >
          <BatteryStatus status="DISCHARGING" percentage={i} />
        </div>
      ))}
    </SBox>
  );
}

function IconsOldExample() {
  return (
    <SBox p={5}>
      <STypography variant="h3" bottomSpace>
        Icons
      </STypography>
      {Object.keys(iconsList).map((icon, i) => (
        <SBox key={i} display="flex" align="center">
          <Icon key={icon} icon={icon} mr={2} />
          <STypography variant="caption">{icon}</STypography>
        </SBox>
      ))}
    </SBox>
  );
}

function TextExample() {
  return (
    <div className="space-y-2">
      <Heading>Text</Heading>
      unfinished
      <Separator />
      <Text variant="body">Cupcake ipsum dolor I love sesame snaps chocolate. (body)</Text>
      <div>
        <Label>Cupcake ipsum dolor I love sesame snaps chocolate. (Label)</Label>
      </div>
      <div>
        <Text variant="link">Cupcake ipsum dolor I love sesame snaps chocolate. (link)</Text>
      </div>
      <Text variant="description">
        Cupcake ipsum dolor I love sesame snaps chocolate. (description)
      </Text>
      <Text variant="sub">Cupcake ipsum dolor I love sesame snaps chocolate. (sub)</Text>
      <Text variant="error">Cupcake ipsum dolor I love sesame snaps chocolate. (error)</Text>
      <Text variant="code">@test/more-test</Text>
    </div>
  );
}

function HeadingExample() {
  return (
    <div className="space-y-2">
      <Heading>Headings (shadcn)</Heading>
      unfinished
      <Separator />
      <Heading variant="pageHeading">
        Cupcake ipsum dolor I love sesame snaps chocolate. (pageHeading)
      </Heading>
      <Heading variant="sectionHeading">
        Cupcake ipsum dolor I love sesame snaps chocolate. (sectionHeading)
      </Heading>
      <Heading variant="cardHeading">
        Cupcake ipsum dolor I love sesame snaps chocolate. (cardHeading)
      </Heading>
      <Heading variant="menu">Cupcake ipsum dolor I love sesame snaps chocolate. (menu)</Heading>
      <Heading variant="menuHeading">
        Cupcake ipsum dolor I love sesame snaps chocolate. (menuHeading)
      </Heading>
    </div>
  );
}

const TestPage = () => {
  return (
    <>
      <Navbar />
      <Page pageHeader={<Page.Header title="UI Components Examples" />}>
        <div className="space-y-4 container">
          <div className="grid grid-cols-3 gap-4">
            <div className="space-y-4">
              <HeadingExample />
              <TextExample />
            </div>
            <TypographyExamples />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <ButtonExamples />
            <StyledButtonExamples />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <ColorShadncnUIExamples />
            <StyledColors />
          </div>
          <ColorTailwindExamples />
          <SheetDemo />
          <SelectDemo />
          <SwitchDemo />
          <BatteryStatusExamples />
          <IconsOldExample />
        </div>
      </Page>
    </>
  );
};

export default TestPage;
