import { ChangeEvent, FC, HTMLAttributes } from 'react';

import { CheckBox, CheckBoxLabel, CheckBoxMark, CheckBoxWrapper } from './Switch.styled';

/**
 * Badge component
 * @param {String} label
 * @param {Boolean} checked
 * @param {Function} onChange
 * @param {Boolean} disabled
 */

type SwitchProps = {
  label?: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
} & HTMLAttributes<HTMLInputElement>;

const Switch: FC<SwitchProps> = ({ label, checked, onChange, disabled, ...rest }) => (
  <CheckBoxWrapper>
    <CheckBox
      type="checkbox"
      hidden
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      {...rest}
    />
    <CheckBoxMark />
    <CheckBoxLabel variant="caption">{label}</CheckBoxLabel>
  </CheckBoxWrapper>
);

export default Switch;
