export enum RobotStream {
  PRIMARY_STREAM = 'PrimaryStream',
  TOP_DOWN_VIEW = 'TopDownView',
  OPEN_DOOR_VIEWER = 'openDoorViewer',
  SPOT_SERVICE_NCB = 'ServiceNCB',
}

export const getJanusStreamName = (myRobotIotHubDeviceName: string, streamSuffix: RobotStream) => {
  return `robot@${myRobotIotHubDeviceName}@${streamSuffix}`;
};
