import Icon, { IconProps } from 'components/common/icon/Icon';

import { NotificationSeverity } from 'types/models/Notification';
import { ThemeColorKeys } from 'types/ui/Theme';

type Props = Omit<IconProps, 'icon'> & {
  severity: NotificationSeverity;
};

export const NotificationSeverityIcon = ({ severity, ...iconProps }: Props) => {
  const NotificationIcon = ({ color }: { color?: ThemeColorKeys }) => (
    <Icon icon="warning" color={color} {...iconProps} />
  );

  switch (severity) {
    case NotificationSeverity.Warning:
      return <NotificationIcon color={ThemeColorKeys.YELLOW} />;
    case NotificationSeverity.Error:
      return <NotificationIcon color={ThemeColorKeys.RED} />;
    default:
      return <NotificationIcon />;
  }
};
