import { RefObject, useEffect, useRef, useState } from 'react';

const useHover = <T extends HTMLElement>(): [
  ref: RefObject<T | null>,
  value: MouseEvent | undefined,
] => {
  const [value, setValue] = useState<MouseEvent | undefined>(undefined);
  const ref = useRef<T>(null);
  const handleMouseOver = (e: MouseEvent) => setValue(e);
  const handleMouseOut = () => setValue(undefined);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [ref.current]);
  return [ref, value];
};

export default useHover;
