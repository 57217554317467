import { atom, selector } from 'recoil';

import { axiosRequest } from 'lib/api/axios';
import { getUserRolesConfig } from 'lib/api/urls';
import RoleTypes from 'lib/constants/role-types';

export const userRolesAtom = atom<RoleTypes[]>({
  key: 'userRolesAtom',
  default: selector<RoleTypes[]>({
    key: 'userRolesAtomSelector',
    get: async () => {
      const { method, url } = getUserRolesConfig();
      const { data } = await axiosRequest<RoleTypes[]>(method, url);
      return data;
    },
  }),
});
