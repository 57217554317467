import { useLogSignalR } from 'store/logger';

import { logRed } from 'utils/debugger';

import { HUB_URL, InvokeMethod, ListenMethod, MethodName } from '../const';
import { useSignalRConnection } from './useSignalRConnection';

/**
 * Create and initialize a connection to a signalR hub
 *
 * Should only be used in the context of a provider by design
 */
export function useHubConnection(url: HUB_URL) {
  const { connection, isConnected, status } = useSignalRConnection(url);

  const { logSignalRReceive, logSignalRInvoke, logSignalRInit } = useLogSignalR(url);

  /**
   * Todo - remove callback
   * Todo - type a general response of invoking if there is any?
   * Note: we could not add many listeners to the same connection currently because unsubscribe will remove all
   */
  async function invoke<T = any>(
    methodName: MethodName,
    args?: unknown,
    callback?: (data: unknown) => void,
  ): Promise<T> {
    logSignalRInvoke(`signalR:${url} ⬆️⬆️⬆️ invoke: ${methodName}`, args);
    if (!connection) {
      const message = `signalR:${url} 🚫🚫🚫connection not initialized: ${methodName}`;
      logRed(message);
      throw new Error(message);
    }

    const data = await connection.invoke<T>(methodName, args); // use invoke instead?

    if (callback) callback(data);

    return data;
  }

  // for the old way of handling invoke
  async function invokeMany(methods: [MethodName, InvokeMethod][]) {
    for (const [methodName, { args, callback }] of methods) {
      await invoke(methodName, args, callback);
    }
  }

  function subscribe(methodName: MethodName, callback: (data: unknown | any) => void) {
    logSignalRInit(`signalR:${url} 📩📩📩 subscribed: ${methodName}`);
    if (!connection) {
      const message = `signalR:${url} 🚫🚫🚫connection not initialized: ${methodName}`;
      logRed(message);
      throw new Error(message);
    }

    connection.on(methodName, (data: any) => {
      if (data?.payload) data.payload = JSON.parse(data.payload);

      logSignalRReceive(`signalR:${url} 📩📩📩 received: ${methodName}:`, data);

      callback(data);
    });
  }

  // for the old way of handling subscribe
  function subscribeMany(methods: [MethodName, ListenMethod][]) {
    methods.forEach(([methodName, callback]) => {
      subscribe(methodName, callback);
    });
  }

  const unsubscribe = (methodName: MethodName) => {
    logSignalRInit(`signalR:${url} 👋👋👋 unsubscribed: ${methodName}`);
    if (!connection) {
      const message = `signalR:${url} 🚫🚫🚫connection not initialized: ${methodName}`;
      logRed(message);
      throw new Error(message);
    }

    connection.off(methodName);
  };

  const unsubscribeMany = (methods: [MethodName, ListenMethod][]) => {
    methods.forEach(([methodName]) => unsubscribe(methodName));
  };

  return {
    connection, // todo - shouldn't be return
    isConnected,
    status,
    invoke,
    invokeMany,
    subscribe,
    subscribeMany,
    unsubscribe,
    unsubscribeMany,
  };
}
