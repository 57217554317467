import { atomFamily } from 'recoil';

import { RobotCommandFeedback } from 'lib/generated/proto/bosdyn/api/robot_command';

import { DeviceName } from 'types/models';

/**
 * Information we receiving while robot command is placed or finished
 *
 * Note: currently not all information we receive are stored -> extend parser
 *
 * Note: feedback is related to an id - we overwrite it
 * - this is maybe not the best approach
 * */
export const robotCommandFeedbackAtom = atomFamily<RobotCommandFeedback, DeviceName>({
  key: 'robotCommandFeedbackAtom',
  default: RobotCommandFeedback.create(),
});
