import { useQuery, useQueryClient } from '@tanstack/react-query';
import { spot_ncb_rms_actions_interfaces } from 'rclnodejs';

import { GetRemoteMissionServiceInfoResponse } from 'lib/generated/proto/bosdyn/api/mission/remote';
import { ModelData } from 'lib/generated/proto/bosdyn/api/network_compute_bridge';

import { logBlue } from 'utils/debugger';

import { RobotRosTopic } from '../types';

const queryKey = ['relayTopics', RobotRosTopic.RMS_AVAILABLE_SERVICES];

type AvailableServiceRMS = ModelData & { serviceName: string };
type AvailableServicesRMS = AvailableServiceRMS[];

/**
 * Currently this only will not get updated frequents
 */
export function useAvailableServicesRMSSetter() {
  const queryClient = useQueryClient();

  function updateAvailableServicesRMS(data: unknown) {
    const parsedData = (
      data as spot_ncb_rms_actions_interfaces.msg.RemoteMissionServiceList
    ).services.map((e) => JSON.parse(e));
    logBlue('updateAvailableServicesRMS:parsedData', parsedData);

    const models = parsedData.map((service) => {
      // note: this will remove serviceName because it's not part of the model
      const data = GetRemoteMissionServiceInfoResponse.fromJSON(service);
      const serviceName = service.serviceName as unknown as string;
      return {
        serviceName,
        data,
      };
    });

    logBlue('updateAvailableServicesRMS:models', models);
    queryClient.setQueryData(queryKey, models);
  }

  return {
    updateAvailableServicesRMS,
  };
}

export function useAvailableServicesRMS() {
  const queryClient = useQuery<AvailableServicesRMS>({
    queryKey,
  });

  const availableServices = queryClient?.data ?? [{ serviceName: 'test', customParams: undefined }];

  return { availableServices };
}
