import styled from 'styled-components';

export const SLoaderBackdrop = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: progress;
`;

export const SLoaderCircle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['fixed'].includes(prop),
})<{ fixed?: boolean }>`
  position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  border-radius: 100%;
  padding: 6px;
  box-shadow: 0 0 10px -4px #00000069;
  background-color: ${(props) => props.theme.colors.background.main};
  cursor: progress;
`;

export const SLoaderIcon = styled.div`
  border: 4px solid ${(props) => props.theme.colors.primary.main};
  border-top: 4px solid ${(props) => props.theme.colors.background.main};
  border-radius: 50%;
  width: 100%;
  height: 100%;
  -webkit-animation: spin 0.7s linear infinite;
  animation: spin 0.7s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const SLoaderLinear = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.primary.dark};
`;

export const SLoaderLinearInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: ${(props) => props.theme.colors.primary.light};
    animation: linear-first 1.5s infinite ease-out;
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    background-color: ${(props) => props.theme.colors.primary.light};
    animation: linear-second 1.5s infinite ease-in;
  }

  @keyframes linear-first {
    0% {
      left: -100%;
      width: 100%;
    }
    100% {
      left: 100%;
      width: 10%;
    }
  }

  @keyframes linear-second {
    0% {
      left: -150%;
      width: 100%;
    }
    100% {
      left: 100%;
      width: 10%;
    }
  }
`;
