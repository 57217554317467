import { useRecoilState } from 'recoil';

import { useDeviceName } from 'store/my-robot';

import { DEFAULT_ROTATION_AXIS_HEIGHT } from 'lib/constants/variables';

import { PlayBackSoundOptionId } from 'types/models/playback-sound';

import { RobotRotationAxes, RobotSpeed, robotControlAtom } from './robot-control.atom';

/**
 * Todo - Refactor hook - either own hooks/atoms or with selectors for each part
 */
export const useRobotControlState = () => {
  const { deviceName } = useDeviceName();
  const [robotControlState, setRobotControlState] = useRecoilState(robotControlAtom(deviceName));

  const setRobotSpeed = (speed: RobotSpeed) => setRobotControlState((prev) => ({ ...prev, speed }));

  const setRobotRotationAxes = (rotationAxes: RobotRotationAxes) =>
    setRobotControlState((prev) => ({ ...prev, rotationAxes }));

  const resetRobotRotationAxes = () =>
    setRobotRotationAxes({ roll: 0, pitch: 0, yaw: 0, height: DEFAULT_ROTATION_AXIS_HEIGHT });

  const toggleRotationAxesLock = () =>
    setRobotControlState((prev) => ({ ...prev, isRotationAxesLocked: !prev.isRotationAxesLocked }));

  const setIsPayloadSettingsOpened = (isPayloadSettingsOpened: boolean) =>
    setRobotControlState((prev) => ({ ...prev, isPayloadSettingsOpened }));

  const setSelectedPlaybackSound = (playbackSound: PlayBackSoundOptionId) =>
    setRobotControlState((prev) => ({ ...prev, playbackSound }));

  const setLoadedPlaybackSound = (loadedPlaybackSound: PlayBackSoundOptionId) =>
    setRobotControlState((prev) => ({ ...prev, loadedPlaybackSound }));

  return [
    robotControlState,
    {
      setRobotSpeed,
      setRobotControlState,
      setRobotRotationAxes,
      resetRobotRotationAxes,
      toggleRotationAxesLock,
      setIsPayloadSettingsOpened,
      setSelectedPlaybackSound,
      setLoadedPlaybackSound,
    },
  ] as const;
};
