import { ToastContainer as ReactToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const ToastContainer = styled(ReactToastContainer)`
  &&&.Toastify__toast-container {
    width: auto;
    min-width: 320px;
  }
  .Toastify__toast {
    min-height: 50px;
  }
  .Toastify__toast-body {
    & > div:last-child {
      font-size: 0.937rem;
      //line-height: 1.375rem;
      //white-space: break-spaces;
    }
  }
  .Toastify__progress-bar {
    height: 3px;
  }
`;
