// @ts-nocheck
import {
  MISSION_SCHEDULE_MASTER_KEY,
  MISSION_SCHEDULE_URL,
  REACT_APP_CIRCLE_VIDEO_TOUR_CODE,
} from 'lib/constants/env-variables';

import { AddMissionPlanDTO, CircleTourMissionRequestDTO } from 'types/models/Mission';
import {
  AddMissionScheduleRequestDTO,
  UpdateMissionScheduleRequestDTO,
} from 'types/models/MissionSchedule';
import { OpenTeleoperationSessionRequestDTO } from 'types/models/Session';

// User

export const getUserRolesConfig = () => ({
  method: 'get',
  url: 'user/getroles',
});

// All robots

export const MyRobotUrls = {
  PrimaryInformation: {
    method: 'get',
    url: 'myRobot/PrimaryInformation',
  },
  GetMyRobotPayloads: {
    method: 'get',
    url: (robotId) => `myRobot/Payloads/${robotId}`,
  },
  GetMyRobotPrimaryInformation: {
    method: 'get',
    url: (robotId) => `myRobot/PrimaryInformation/${robotId}`,
  },
};

// Single robot

export const addRobotConfig = (myRobotDTO) => ({
  method: 'post',
  url: 'myRobot/AddMyRobot',
  body: myRobotDTO,
});

export const deleteRobotConfig = (robotId) => ({
  method: 'delete',
  url: `myRobot/Delete/${robotId}`,
});

export const getRobotDataConfig = (robotId) => ({
  method: 'get',
  url: `myRobot/Get/${robotId}`,
});

// Robot Payloads

export const PayloadsUrls = {
  GetGeneralPayloadsConfig: {
    method: 'get',
    url: `payloads`,
  },
  AddPayloadToRobot: {
    method: 'post',
    url: (payloadId, myRobotId) => `payloads/${payloadId}/Robot/${myRobotId}`,
  },
  RemovePayloadFromRobot: {
    method: 'delete',
    url: (myRobotToPayloadId) => `payloads/RobotsPayload/${myRobotToPayloadId}`,
  },
};

export const getRobotPayloadsConfig = (robotId) => ({
  method: 'get',
  url: `myRobot/Payloads/${robotId}`,
});

export const getMyRobotToPayloadsForImagesConfig = (robotId) => ({
  method: 'get',
  url: `myRobot/GetMyRobotToPayloadsForImages/${robotId}`,
});

/**
 * TODO: Concept is WIP
 *
 * Goal: Having a bedder understand of with endpoints are related to which group.
 */
export const ServiceUrls = {
  robotTemplate: {
    GetSettingsOfRobotTemplateByMyRobotId: {
      method: 'get',
      url: (robotId) => `robotTemplate/GetSettingsOfRobotTemplateByMyRobotId/${robotId}`,
    },
  },
};

// Templates

export const getAllRobotTemplatesConfig = () => ({
  method: 'get',
  url: 'robotTemplate/GetAllRobotTemplates',
});

export const getRobotTemplateConfig = (robotTemplateId) => ({
  method: 'get',
  url: `robotTemplate/GetRobotTemplate/${robotTemplateId}`,
});

export const getMyRobotInfoConfig = (iotHubDeviceName: string) => ({
  method: 'get',
  url: `myRobot/GetMyRobotInfo/${iotHubDeviceName}`,
});

export const getGeInstallationInstructionConfig = (iotHubDeviceName: string) => ({
  method: 'get',
  key: 'installationInstruction',
  url: `myRobot/GeInstallationInstruction/${iotHubDeviceName}`,
});

// Autowalks

export const getAutowalksConfig = () => ({
  method: 'get',
  url: 'autowalks',
});

export const getAutowalkResultsConfig = (autowalkId) => ({
  method: 'get',
  url: `autowalks/${autowalkId}`,
});

export const uploadNewAutowalkConfig = (newAutowalkId, newAutowalkZipFormData) => ({
  method: 'post',
  url: `/Autowalks/UploadIngest/${newAutowalkId}`,
  body: newAutowalkZipFormData,
});

// Scheduled missions
const scheduledMissionsUrl = `${MISSION_SCHEDULE_URL}/api/Core?code=${MISSION_SCHEDULE_MASTER_KEY}`;

export const getMissionsScheduledConfig = () => ({
  method: 'get',
  url: scheduledMissionsUrl,
});

export const createScheduledMissionConfig = (scheduledMission: AddMissionScheduleRequestDTO) => ({
  method: 'post',
  url: scheduledMissionsUrl,
  body: scheduledMission,
});

export const editScheduledMissionConfig = (scheduledMission: UpdateMissionScheduleRequestDTO) => ({
  method: 'put',
  url: scheduledMissionsUrl,
  body: scheduledMission,
});

export const deleteScheduledMissionConfig = (scheduledMissionId) => ({
  method: 'delete',
  url: scheduledMissionsUrl,
  params: {
    id: scheduledMissionId,
  },
});

//

export const getFullNetworkAnalysisConfig = (fullStateBlobKey) => ({
  method: 'get',
  url: `Overview/GetFullNetworkAnalysis/${fullStateBlobKey}`,
});

export const updateNetworkStatusConfig = (iotHubDeviceName: string) => ({
  method: 'post',
  url: `Overview/RunNetworkAnalysis/${iotHubDeviceName}`,
});

export const getNetworkHealthConfig = (iotHubDeviceName: string) => ({
  method: 'get',
  url: `Overview/GetNetworkHealth/${iotHubDeviceName}`,
});

export const getFileDataConfig = (iotHubDeviceName: string) => ({
  method: 'get',
  url: `Context/GetUploadFileInfo/${iotHubDeviceName}`,
});

export const startUploadDataConfig = (iotHubDeviceName: string) => ({
  method: 'post',
  url: `/Context/StartUpload/${iotHubDeviceName}`,
});

export const ManualControlUrls = {
  GetCurrentMainOperator: {
    method: 'get',
    url: (deviceName) => `ManualControl/GetCurrentMainOperator/${deviceName}`,
  },
  SetCurrentActivity: {
    method: 'post',
    url: (deviceName) => `ManualControl/SetCurrentActivity/${deviceName}`,
  },
  RemoveCurrentActivity: {
    method: 'delete',
    url: (deviceName) => `ManualControl/RemoveCurrentActivity/${deviceName}`,
  },
  PingActivity: {
    method: 'post',
    url: (deviceName) => `ManualControl/PingActivity/${deviceName}`,
  },
  // depreocated
  GetAvailableCameraSources: {
    method: 'get',
    url: (deviceName) => `ManualControl/GetAvailableCameraSources/${deviceName}`,
  },
  AvailableStreamSources: {
    method: 'get',
    url: (deviceName) => `ManualControl/AvailableStreamSources/${deviceName}`,
  },
  GetPtzCameraData: {
    method: 'get',
    url: (deviceName) => `ManualControl/GetPtzCameraData/${deviceName}`,
  },
  GetSpotControlData: {
    method: 'get',
    url: (deviceName) => `ManualControl/GetSpotControlData/${deviceName}`,
  },
  RequestControlPermission: {
    method: 'post',
    url: (deviceName) => `ManualControl/RequestControlPermission/${deviceName}`,
  },
  ResponseControlPermissionRequest: {
    method: 'post',
    url: (deviceName: string, userName: string, allowControl: boolean) =>
      `ManualControl/ResponseControlPermissionRequest/${deviceName}/${userName}/${allowControl}`,
  },
};

export const recordStartVideoStreamConfig = (serverURL, iotHubDeviceName: string) => ({
  method: 'post',
  url: `${serverURL}/Recording/Start/${iotHubDeviceName}`,
});

export const recordStopVideoStreamConfig = (serverURL, iotHubDeviceName: string) => ({
  method: 'post',
  url: `${serverURL}/Recording/Stop/${iotHubDeviceName}`,
});

// Session

export const getSessionConfig = (iotHubDeviceName: string) => ({
  method: 'get',
  url: `context/${iotHubDeviceName}`,
});

export const openSessionConfig = (data: OpenTeleoperationSessionRequestDTO) => ({
  method: 'post',
  url: `sessions/teleoperation/open`,
  body: data,
});

export const closeSessionConfig = (iotHubDeviceName: string, teleoperationSessionId: number) => ({
  method: 'post',
  url: `sessions/teleoperation/${teleoperationSessionId}/Close/${iotHubDeviceName}`,
});

// Radiation

export const getRadiationMapsConfig = () => ({
  method: 'get',
  url: 'Maps3D/GetRadiationMaps',
});

export const getSessionsForMapConfig = (mapId) => ({
  method: 'get',
  url: `Maps3D/GetSessionsForMap/${mapId}`,
});

export const getRadiationLocationsConfig = (sessionId) => ({
  method: 'get',
  url: `Maps3D/GetRadiationLocations/${sessionId}`,
});

// Mission plan

export const getMissionPlansConfig = () => ({
  method: 'get',
  url: 'Missions/Plan',
});

export const getMissionPlanConfig = (id: number) => ({
  method: 'get',
  url: `Missions/Plan/${id}`,
});

export const createMissionPlanConfig = (missionPlanDTO: AddMissionPlanDTO) => ({
  method: 'post',
  url: 'Missions/Plan',
  body: missionPlanDTO,
});

export const deleteMissionPlanConfig = (missionPlanId: number) => ({
  method: 'delete',
  url: `Missions/Plan/${missionPlanId}`,
});

export const executeMissionPlanConfig = (iotHubDeviceName: string, missionPlanId: number) => ({
  method: 'post',
  url: `Missions/Plan/${missionPlanId}/Execute/${iotHubDeviceName}`,
});

export const passCarToCircleTourMissionConfig = (data: CircleTourMissionRequestDTO) => ({
  method: 'post',
  url: `https://roboticsplatform-function-tuv-circletourmissionmanager-dev.azurewebsites.net/api/CircleVideoTour?code=${REACT_APP_CIRCLE_VIDEO_TOUR_CODE}`,
  body: data,
});

// Robot actions

// API to get robot action templates list
export const getRobotActionTemplatesConfig = (iotHubDeviceName?: string) => ({
  method: 'get',
  url: 'RobotActionTemplates',
  ...(iotHubDeviceName && {
    params: {
      IotHubDeviceName: iotHubDeviceName,
    },
  }),
});

// API to get robot action template: response -> RobotActionTemplateDTO
export const getRobotActionTemplateConfig = (actionIndicatorId) => ({
  method: 'get',
  url: `RobotActionTemplates/GetByActionIndicatorId/${actionIndicatorId}`,
});

// IoTHub General API
export const getIoTHubGeneralConfig = (iotHubDeviceName: string, iotHubModule: string) => ({
  method: 'post',
  url: `Teleoperation/${iotHubDeviceName}/IoTHub/${iotHubModule}/DirectMethod`,
});

///

export const getMapAsBase64ByMapNameConfig = (mapName: string) => ({
  method: 'get',
  url: `maps/GetMapAsBitsByName/${mapName}`,
});

// Notifications
type NotificationOptions = {
  skip: number;
  take: number;
};

export const getMyRobotNotificationsConfig = (
  iotHubDeviceName: string,
  options?: NotificationOptions,
) => ({
  method: 'get',
  url: `Overview/GetNotifications/${iotHubDeviceName}?${new URLSearchParams(options).toString()}`,
});

export const getMyRobotNotificationsCountConfig = (iotHubDeviceName: string) => ({
  method: 'get',
  url: `Overview/GetNotificationsCount/${iotHubDeviceName}`,
});
