import { rosapi_msgs } from 'rclnodejs';
import { useMemo } from 'react';

import { useRosBridge } from 'hooks/ros';

import { RosSignalR } from 'lib/roslibjs';

// todo - are these all services?
export const ROS_API = {
  NODES: '/rosapi/nodes',
  NODE_DETAILS: '/rosapi/node_details',
  SERVICES: '/rosapi/services',
  SERVICE_TYPE: '/rosapi/service_type',
  SERVICE_REQUEST_DETAILS: '/rosapi/service_request_details',
  SERVICE_RESPONSE_DETAILS: '/rosapi/service_response_details',
  ACTIONS: '/rosapi/action_servers',
  TOPICS: '/rosapi/topics',
  TOPIC_TYPE: '/rosapi/topic_type',
  TOPICS_AND_RAW_TYPES: '/rosapi/topics_and_raw_types',
} as const;

/**
 * Note: When adding roslibjs support the package is not completely updated to ros2 yet.
 * Maybe choose
 *
 * Check the source to see what methods exist https://github.com/RobotWebTools/roslibjs/blob/develop/src/core/Ros.js
 */
export class RosApiHandler {
  private readonly ros: RosSignalR;

  constructor(ros: RosSignalR) {
    this.ros = ros;
  }

  /**
   * @override {ROSLIB.Ros.getTopics}
   */
  async getTopics() {
    const name = ROS_API.TOPICS;

    return this.ros.callRosService<rosapi_msgs.srv.Topics_Request, rosapi_msgs.srv.Topics_Response>(
      name,
      {},
    );
  }

  /**
   * @override {ROSLIB.Ros.getTopicType}
   */
  async getTopicType(topic: string) {
    const name = ROS_API.TOPIC_TYPE;

    return this.ros.callRosService<
      rosapi_msgs.srv.TopicType_Request,
      rosapi_msgs.srv.TopicType_Response
    >(name, { topic });
  }

  /**
   * @override {ROSLIB.Ros.getNodes}
   */
  getNodes() {
    const name = ROS_API.NODES;

    return this.ros.callRosService<rosapi_msgs.srv.Nodes_Request, rosapi_msgs.srv.Nodes_Response>(
      name,
      {},
    );
  }

  /**
   * @override {ROSLIB.Ros.getNodeDetails}
   */
  getNodeDetails(node: string) {
    const name = ROS_API.NODE_DETAILS;

    return this.ros.callRosService<
      rosapi_msgs.srv.NodeDetails_Request,
      rosapi_msgs.srv.NodeDetails_Response
    >(name, { node });
  }

  /**
   * @override {ROSLIB.Ros.getServices}
   */
  getServices() {
    const name = ROS_API.SERVICES;

    return this.ros.callRosService<
      rosapi_msgs.srv.Services_Request,
      rosapi_msgs.srv.Services_Response
    >(name, {});
  }

  /**
   * @override {ROSLIB.Ros.getServiceType}
   */
  getServiceType(service: string) {
    const name = ROS_API.SERVICE_TYPE;

    return this.ros.callRosService<
      rosapi_msgs.srv.ServiceType_Request,
      rosapi_msgs.srv.ServiceType_Response
    >(name, { service });
  }

  /**
   * @override {ROSLIB.Ros.getTopicsAndRawTypes}
   */
  getTopicsAndRawTypes() {
    const name = ROS_API.TOPICS_AND_RAW_TYPES;

    return this.ros.callRosService<
      rosapi_msgs.srv.TopicsAndRawTypes_Request,
      rosapi_msgs.srv.TopicsAndRawTypes_Response
    >(name, {});
  }

  /**
   * @override {ROSLIB.Ros.getServiceRequestDetails}
   */
  getServiceRequestDetails(type: string) {
    const name = ROS_API.SERVICE_REQUEST_DETAILS;

    return this.ros.callRosService<
      rosapi_msgs.srv.ServiceRequestDetails_Request,
      rosapi_msgs.srv.ServiceRequestDetails_Response
    >(name, { type });
  }

  /**
   * @override {ROSLIB.Ros.getServiceResponseDetails}
   */
  getServiceResponseDetails(type: string) {
    const name = ROS_API.SERVICE_RESPONSE_DETAILS;

    return this.ros.callRosService<
      rosapi_msgs.srv.ServiceResponseDetails_Request,
      rosapi_msgs.srv.ServiceResponseDetails_Response
    >(name, { type });
  }

  /**
   * @override {ROSLIB.Ros.getActionServers}
   */
  getActionServers() {
    const name = ROS_API.ACTIONS;

    return this.ros.callRosService<
      rosapi_msgs.srv.GetActionServers_Request,
      rosapi_msgs.srv.GetActionServers_Response
    >(name, {});
  }
}

export function useRosApi() {
  const { ros } = useRosBridge();

  const RosApi = useMemo(() => new RosApiHandler(ros), []);

  return { RosApi };
}
