import { ChevronsLeft } from 'lucide-react';
import { memo, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as RoboticsPlatformReplySmallIcon } from 'assets/icons/roboticsplatform-reply-small.svg';
import { ReactComponent as RoboticsPlatformReplyIcon } from 'assets/icons/roboticsplatform-reply.svg';

import { Icon } from 'components/common';
import { useRole } from 'components/complex/role';
import { STypography } from 'components/ui';

import { LOCAL_STORAGE } from 'lib/constants/local-storage';

import TooltipUnauthorizedMessage from '../tooltip-unauthorized-message/TooltipUnauthorizedMessage';
import { navigationRootList } from './navigation-list';
import SidebarRobot from './sidebar-robot/SidebarRobot';
import {
  SLogoContainer,
  SNavigationDivider,
  SNavigationItem,
  SNavigationLink,
  SNavigationList,
  SSidebar,
  SToggleItem,
} from './styled';

export function ReplyLogo() {
  return (
    <NavLink to="/">
      <SLogoContainer>
        <RoboticsPlatformReplySmallIcon className="logo-small" />
        <RoboticsPlatformReplyIcon className="logo-big" />
      </SLogoContainer>
    </NavLink>
  );
}

const Sidebar = memo<{ isRobotPage?: boolean }>(({ isRobotPage }) => {
  const { checkRole } = useRole();

  const [isClosed, setIsClosed] = useState(
    localStorage.getItem(LOCAL_STORAGE.SIDEBAR_CLOSED) === 'true',
  );

  const toggle = () => {
    setIsClosed((prevState) => {
      const nextState = !prevState;
      localStorage.setItem(LOCAL_STORAGE.SIDEBAR_CLOSED, nextState.toString());
      return !prevState;
    });
  };

  return (
    <SSidebar isClosed={isClosed} className="border-r border-border">
      <ReplyLogo />
      <SNavigationList>
        {navigationRootList.map(({ path, title, icon, role, disabled }) => {
          const hasPermission = !role || checkRole(role);
          return (
            <TooltipUnauthorizedMessage key={path} hasPermission={hasPermission}>
              <SNavigationItem>
                <SNavigationLink to={`/${path}` ?? ''} disabled={disabled || !hasPermission}>
                  <Icon icon={icon} />
                  <STypography variant="subtitle2">{title}</STypography>
                </SNavigationLink>
              </SNavigationItem>
            </TooltipUnauthorizedMessage>
          );
        })}
        <SNavigationItem>
          <SNavigationDivider />
        </SNavigationItem>
        {isRobotPage && <SidebarRobot />}
      </SNavigationList>
      <SToggleItem>
        <SNavigationLink as="button" onClick={toggle} className="flex justify-end space-x-2">
          {!isClosed && <STypography variant="subtitle2">Hide</STypography>}
          <ChevronsLeft className="" />
        </SNavigationLink>
      </SToggleItem>
    </SSidebar>
  );
});

export default Sidebar;
