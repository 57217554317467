import { useRecoilValue } from 'recoil';

import { robotCommandFeedbackStatus_StatusToJSON } from 'lib/generated/proto/bosdyn/api/basic_command';
import {
  ClawGripperCommand_Feedback_Status,
  clawGripperCommand_Feedback_StatusToJSON,
} from 'lib/generated/proto/bosdyn/api/gripper_command';
import { useSendRobotCommand } from 'lib/robot-modules/spot-control-module';

import {
  clawGripperCommandFeedbackStatusSelector,
  gripperCommandFeedbackStatusSelector,
} from '../robot-command-feedback/selectors';
import { gripperOpeningPercentageSelector } from './selectors';

export function useOpenCloseGripper() {
  // const { deviceName } = useDeviceName();
  // const setCurrentCommandId = useSetRecoilState(currentCommandIdAtom(deviceName));

  const openingPercentage = useRecoilValue(gripperOpeningPercentageSelector);
  const { gripperCommandStatus } = useRecoilValue(gripperCommandFeedbackStatusSelector);

  const _gripperCommandStatusString = robotCommandFeedbackStatus_StatusToJSON(gripperCommandStatus);
  const { clawGripperCommandFeedbackStatus } = useRecoilValue(
    clawGripperCommandFeedbackStatusSelector,
  );
  const _clawGripperCommandFeedbackStatus = clawGripperCommand_Feedback_StatusToJSON(
    clawGripperCommandFeedbackStatus,
  );

  // for sending the command only
  const { sendGripperCommand, sendArmStopCommand } = useSendRobotCommand();

  async function handleOpenGripper(slow = false) {
    return sendGripperCommand(true, slow);
  }

  function handleCloseGripper(slow = false) {
    return sendGripperCommand(false, slow);
  }

  const processingGripper =
    clawGripperCommandFeedbackStatus === ClawGripperCommand_Feedback_Status.STATUS_IN_PROGRESS;
  const applyingForce =
    clawGripperCommandFeedbackStatus === ClawGripperCommand_Feedback_Status.STATUS_APPLYING_FORCE;

  return {
    openingPercentage,
    handleOpenGripper,
    handleCloseGripper,
    sendArmStopCommand,
    gripperCommandStatus,
    _gripperCommandStatusString,
    clawGripperCommandFeedbackStatus,
    processingGripper,
    applyingForce,
    _clawGripperCommandFeedbackStatus,
  };
}
