import { ReactNode, memo } from 'react';

import { SBox } from 'components/ui';
import { Heading } from 'components/ui/typography';

/**
 * Page Sub Header component
 * @param {String, Node} title - Sub Header Title
 * @param {Node} leftSection - Left Section for header Any node
 * @param {Node} rightSection - Right Section for header Any node
 * @param {Node} children - Any node
 */

type PageHeaderProps = {
  title: string | ReactNode;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  children?: ReactNode;
};

const PageHeader = memo<PageHeaderProps>(({ title, leftSection, rightSection, children }) => (
  <>
    <SBox display="flex" align="center" py={2}>
      {leftSection && leftSection}
      {typeof title === 'string' ? <Heading>{title}</Heading> : title}
      {rightSection && rightSection}
    </SBox>
    {children && children}
  </>
));

export default PageHeader;
