// @ts-nocheck
import { debugJanus } from 'utils/debugger';

import { CreatePublishRoomCallback } from '../helper';
import Janus from '../janus';
import { JanusEventType, JanusPluginHandle, JanusPluginOptions } from '../types';

/**
 * This create a plugin to attach to a session
 * docs:
 * - jsep - JavaScript Session Establishment Protocol: https://rtcweb-wg.github.io/jsep/
 */
export const createPublishRoomPlugin = (
  opaqueId: string,
  room: string,
  username: string | undefined,
  isPublisher: boolean = false,
  callback: CreatePublishRoomCallback,
) => {
  let sfutest: JanusPluginHandle | null = null;
  // todo - currently for local stream only witch is not in used - will be in feature maybe
  let mystream: MediaStream | null = null;

  // Todo: not sure but a lot of this function are not used
  const videoRoomPlugin: JanusPluginOptions = {
    plugin: 'janus.plugin.videoroom',
    opaqueId,
    success: function success(pluginHandle: JanusPluginHandle) {
      sfutest = pluginHandle;
      Janus.log(
        'success: Plugin attached! (' + sfutest.getPlugin() + ', id=' + sfutest.getId() + ')',
      );
      // if room is available
      if (isPublisher) {
        const create = {
          request: 'create',
          notify_joining: true,
          room,
          pin,
        };
        // send message to create new room
        sfutest.send({
          message: create,
          success: function success(data) {
            // check if room create is okay
            if (data.videoroom && data.videoroom === 'created') {
              // now register ourselves
              // Todo - find documentation for stuff like this and type/name it properly
              const register = {
                request: 'join',
                room: data.room,
                ptype: 'publisher',
                display: username,
              };
              sfutest?.send({ message: register });

              // todo - 'created' is not handled on our callback
              callback(sfutest, 'created', data);
            }
          },
          error: function error(_error) {
            console.log(`Error creating room ${_error}`);
          },
        });
      } else {
        const register = {
          request: 'join',
          room: room,
          ptype: 'publisher',
          display: username || '',
        };
        sfutest.send({ message: register });
      }
    },
    error: function error(_error2: any) {
      Janus.log('plugin:error:  -- Error attaching plugin...', _error2);
      callback(sfutest, JanusEventType.ERROR, _error2);
    },
    consentDialog: function consentDialog(on) {
      Janus.debug(`plugin:consentDialog:Consent dialog should be ${on ? 'on' : 'off'} now`);
    },
    // todo - pretty sure we don't see this
    mediaState: function mediaState(medium, receiving, mid) {
      Janus.log(
        `plugin:mediaState ${
          receiving ? 'started' : 'stopped'
        } receiving our ${medium} (mid=${mid})`,
      );
    },
    webrtcState: function webrtcState(on) {
      Janus.log(
        `plugin:webrtcState:Janus says our WebRTC PeerConnection is ${on ? 'up' : 'down'} now`,
      );
    },
    onmessage: function onmessage(msg, jsep) {
      Janus.log('plugin:onmessage', msg);

      const event = msg.videoroom;
      if (event) {
        if (event === JanusEventType.JOINED) {
          callback(sfutest, JanusEventType.JOINED, msg);
        } else if (event === 'destroyed') {
          // todo destroyed is not existing - check if event can really happen
          Janus.warn('The room has been destroyed!');
          callback(sfutest, 'destroyed', event);
        } else if (event === 'event') {
          if (msg.error) {
            callback(sfutest, JanusEventType.ERROR, msg);
          } else if (msg.publishers) {
            callback(sfutest, JanusEventType.PUBLISHERS, msg);
          } else if (msg.leaving) {
            callback(sfutest, JanusEventType.LEAVING, msg);
          } else if (msg.unpublished) {
            callback(sfutest, JanusEventType.UNPUBLISHED, msg);
          }
        }
      }

      if (jsep) {
        Janus.debug('Handling SDP as well...');
        Janus.debug(jsep);
        sfutest?.handleRemoteJsep({ jsep });
        // Check if any of the media we wanted to publish has
        // been rejected (e.g., wrong or unsupported codec)
        const audio = msg.audio_codec;
        if (
          mystream &&
          mystream.getAudioTracks() &&
          mystream.getAudioTracks().length > 0 &&
          !audio
        ) {
          // Audio has been rejected
          Janus.log("Our audio stream has been rejected, viewers won't hear us");
        }
        const video = msg.video_codec;
        if (
          mystream &&
          mystream.getVideoTracks() &&
          mystream.getVideoTracks().length > 0 &&
          !video
        ) {
          Janus.log("Our video stream has been rejected, viewers won't see us");
        }
      }
    },
    // todo - will be onlocaltrack in 1.0
    onlocalstream: function onlocalstream(stream: MediaStream) {
      // todo - why do we have this? I think this is related if client/user send a stream
      Janus.debug('plugin:onlocalstream', stream);
      mystream = stream;
      callback(sfutest, 'onlocalstream', stream);
    },
    // a remote MediaStream is available and ready to be displayed
    onremotestream: function onremotestream(stream: MediaStream) {
      debugJanus('plugin:onremotestream', stream);
      // The publisher stream is sendonly, we don't expect anything here
    },
    oncleanup: function oncleanup() {
      Janus.log('plugin:oncleanup: ::: Got a cleanup notification: we are unpublished now :::');
      callback(sfutest, JanusEventType.ON_CLEANUP);
    },
  };

  return videoRoomPlugin;
};
