import { DefaultValue, atom, atomFamily, selector, selectorFamily } from 'recoil';

import { DEFAULT_ROTATION_AXIS_HEIGHT } from 'lib/constants/variables';

import { PlayBackSoundOptionId } from 'types/models/playback-sound';
import { CameraSource, DefaultCameraSource } from 'types/ui/Camera';

import { RobotStatus } from '../../types/models';
import { robotStatusSelector } from '../my-robot/myRobot.atoms';

export type RobotRotationAxes = {
  roll: number;
  pitch: number;
  yaw: number;
  height: number;
};

export type RobotSpeed = number;

export type RobotControlState = {
  speed: RobotSpeed;
  rotationAxes: RobotRotationAxes;
  isRotationAxesLocked: boolean;
  isPayloadSettingsOpened: boolean;
  playbackSound: PlayBackSoundOptionId;
  // todo - now default state?
  loadedPlaybackSound?: PlayBackSoundOptionId;
};

// todo - having a atomFamily make no sense when we don't use this for myRobotInfoAtom as well
// todo:jl - find out why we have a atomFamily based on a selectorFamily
export const robotControlAtom = atomFamily<RobotControlState, string>({
  key: 'robotControlFamilyAtom',
  default: selectorFamily<RobotControlState, string>({
    key: 'robotControlFamilySelector',
    get: () => () => {
      return {
        speed: 0.59,
        rotationAxes: {
          roll: 0,
          pitch: 0,
          yaw: 0,
          height: DEFAULT_ROTATION_AXIS_HEIGHT,
        },
        isRotationAxesLocked: false,
        // todo - why is this in this atom? Why not a own one? is it related?
        isPayloadSettingsOpened: false,
        playbackSound: PlayBackSoundOptionId.CAR_HORN,
      };
    },
  }),
});

export const selectedCameraSourceAtom = atomFamily<CameraSource, string>({
  key: 'selectedCameraSourceAtom',
  default: () => {
    return {
      value: DefaultCameraSource.OFF,
      label: DefaultCameraSource.OFF,
    };
  },
});

export const robotEmergencyStopSelector = selector<boolean>({
  key: 'robotEmergencyStopSelector',
  get: ({ get }) => {
    const robotStatus = get(robotStatusSelector);
    return robotStatus?.isEmergencyStop ?? false;
  },
  set: ({ get, set }, newValue) => {
    const state = get(robotStatusSelector);
    if (!state) return; // todo - remove after rebuilding to query
    // todo - not sure if this is the right way to handle this
    if (newValue instanceof DefaultValue) return set(robotStatusSelector, newValue);
    const newState: RobotStatus = {
      ...state,
      isEmergencyStop: newValue,
    };
    set(robotStatusSelector, newState);
  },
});

export const robotEmergencyStopAtom = atom<boolean>({
  key: 'robotEmergencyStopAtom',
  default: robotEmergencyStopSelector,
});
