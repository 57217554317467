import { FC, PropsWithChildren } from 'react';

import { Modal } from 'components/common';
import { SBox, SButton, STypography } from 'components/ui';

import { ThemeColorKeys } from 'types/ui/Theme';

type ConfirmModalProps = PropsWithChildren & {
  isOpen: boolean;
  close?: () => void;
  title?: string;
  onConfirm?: () => void;
  confirmText?: string;
  onReject?: () => void;
  rejectText?: string;
  isConfirmLoading?: boolean;
};

const ConfirmModal: FC<ConfirmModalProps> = ({
  isOpen,
  close,
  title,
  onConfirm,
  confirmText,
  onReject,
  rejectText,
  isConfirmLoading,
  children,
}) => {
  const confirm = () => {
    onConfirm && onConfirm();
    close?.();
  };

  const reject = () => {
    onReject && onReject();
    close?.();
  };

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      title={title || 'Confirm'}
      footer={
        <Modal.Footer>
          {onConfirm && (
            <SButton
              type="button"
              bgcolor={ThemeColorKeys.GREEN}
              onClick={confirm}
              loading={isConfirmLoading}
            >
              {confirmText || 'Ok'}
            </SButton>
          )}
          {onReject && (
            <SButton type="button" bgcolor={ThemeColorKeys.RED} onClick={reject}>
              {rejectText || 'Cancel'}
            </SButton>
          )}
        </Modal.Footer>
      }
    >
      <SBox display="flex" direction="column" py={4} px={8}>
        {typeof children === 'string' ? (
          <STypography variant="body2">{children}</STypography>
        ) : (
          children
        )}
      </SBox>
    </Modal>
  );
};

export default ConfirmModal;
