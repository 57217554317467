import { useRecoilValue } from 'recoil';

import { gamepadConnectedSelector } from './selectors';

/**
 * Tells you if a gamepad is connected - useful for not subscribed the gamepad state itself with gets updated high frequently
 */
export function useGamepadConnected() {
  const gamepadConnected = useRecoilValue(gamepadConnectedSelector);
  return { gamepadConnected };
}
