import { parseRadiationMapUrl } from 'utils/helpers';

export const ROBOTICSPLATFORM_CORE_APP_SETTINGS_CS =
  process.env.REACT_APP_ROBOTICSPLATFORMAPPSETTINGSCS;

/**
 * this is set only in local development with local running server - not on production or localhost:3000
 */
export const SIGNAL_R_URL = process.env.REACT_APP_SIGNAL_R_URL ?? '';

export const REACT_APP_MENU_HIDE_RADIATION_MAP = process.env.REACT_APP_MENUHIDERADIATIONMAP;
export const REACT_APP_MENU_HIDE_3DMAPS = process.env.REACT_APP_MENUHIDE3DMAP;
export const REACT_APP_MENU_HIDE_NAVIGATION_MAPS = process.env.REACT_APP_MENUHIDENAVIGATIONMAPS;
export const REACT_APP_MENU_HIDE_AUTOWALK = process.env.REACT_APP_MENUHIDEAUTOWALK;
export const REACT_APP_MENU_HIDE_MISSION = process.env.REACT_APP_MENUHIDEMISSION;
export const REACT_APP_CIRCLE_VIDEO_TOUR_CODE = process.env.REACT_APP_CIRCLE_VIDEO_TOUR_CODE;
export const REACT_APP_MENU_HIDE_SCHEDULE_MISSIONS =
  process.env.REACT_APP_MENU_HIDE_SCHEDULE_MISSIONS;
export const MISSION_SCHEDULE_URL = process.env.REACT_APP_MISSION_SCHEDULE_URL;
export const MISSION_SCHEDULE_MASTER_KEY = process.env.REACT_APP_MISSION_SCHEDULE_MASTER_KEY;

// Radiation maps
export const RADIATION_MAPS_LOADER_URL = parseRadiationMapUrl(
  process.env.REACT_APP_RADIATION_MAPS_LOADER_URL,
);
export const RADIATION_MAPS_DATA_URL = parseRadiationMapUrl(
  process.env.REACT_APP_RADIATION_MAPS_DATA_URL,
);
export const RADIATION_MAPS_FRAMEWORK_URL = parseRadiationMapUrl(
  process.env.REACT_APP_RADIATION_MAPS_FRAMEWORK_URL,
);
export const RADIATION_MAPS_CODE_URL = parseRadiationMapUrl(
  process.env.REACT_APP_RADIATION_MAPS_CODE_URL,
);

export const appVersion = process.env.REACT_APP_VERSION ?? -1;

console.log(process.env);
