export enum RequestStreamChangeAction {
  REQUEST_STREAM = 'REQUEST_STREAM',
  CLOSE_CHANNEL = 'CLOSE_CHANNEL',
}

export type RequestStreamChangeChannel = {
  channelName: string;
  streamSource: string | null;
  action: RequestStreamChangeAction;
  requested_frame_size_height_width?: [number, number] | null;
};

export type RequestStreamChangesHalted = boolean | null;

export type RequestStreamChangeImplicitCloseOthers = boolean | null;

export type RequestStreamChangePayload = {
  requestedStreamChanges?: RequestStreamChangeChannel[] | null;
  streamingHalted?: RequestStreamChangesHalted;
  implicitCloseOthers?: RequestStreamChangeImplicitCloseOthers;
};
