import { useSuspenseQuery } from '@tanstack/react-query';

import { useDeviceName } from 'store/my-robot';

import { ManualControlManager } from 'lib/api/manuel-control';
import { ManualControlUrls } from 'lib/api/urls';

import { CameraSource, DefaultCameraSource, StreamSource, StreamSourceType } from 'types/ui/Camera';

export const DEFAULT_CAMERA_SOURCE: CameraSource = {
  value: DefaultCameraSource.OFF,
  label: 'Off',
};

/**
 * Old hook for old api - used for parsing where this is still used
 */
export function useCameraSources() {
  const { streamSources } = useStreamSources();
  const cameraSources: CameraSource[] = [DEFAULT_CAMERA_SOURCE].concat(
    streamSources.map((source) => ({
      value: source.name,
      label: source.name + ` (${source.type})`,
    })),
  );
  return { cameraSources };
}

/**
 * Todo: Extend data with labels for show them in the UI
 */
export function useStreamSources() {
  const { deviceName } = useDeviceName();
  const { url } = ManualControlUrls.AvailableStreamSources;
  const queryKey = [url(deviceName)];
  const queryResult = useSuspenseQuery({
    queryKey,
    queryFn: () => ManualControlManager.AvailableStreamSources(deviceName),
  });

  const streamSources: StreamSource[] = queryResult.data ?? [];

  const spotStreamSources = streamSources
    .filter((s) => s.type === StreamSourceType.SpotImageSource)
    .filter((s) => !!s.spotServiceName)
    .sort((a, b) => a.name.localeCompare(b.name));

  return {
    streamSources,
    spotStreamSources,
  };
}
