import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import useMyRobotActions from 'store/my-robot/myRobot.actions';
import { myRobotAtom, myRobotPrimaryInformationAtom } from 'store/my-robot/myRobot.atoms';

const useGetMyRobot = (robotId: number) => {
  const myRobotActions = useMyRobotActions();

  // this is the only area were we want to allow accessing this
  const { isFetched: myRobotIsFetched, error: myRobotError } = useRecoilValue(myRobotAtom);
  const { isFetched: myRobotPrimaryInformationIsFetched, error: myRobotPrimaryInformationError } =
    useRecoilValue(myRobotPrimaryInformationAtom);

  useEffect(() => {
    // Load robot data
    myRobotActions.getById(robotId);
    myRobotActions.getPrimaryInformationById(robotId);
  }, [robotId]);

  const error = myRobotError || myRobotPrimaryInformationError;
  return {
    isFetched: myRobotIsFetched && myRobotPrimaryInformationIsFetched,
    error,
  };
};

export default useGetMyRobot;
