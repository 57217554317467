import { useEffect } from 'react';

import { useGamepadConnected, useUpdateGamepad } from 'store/gamepad';

import { gamepadToast } from './util/handlers';

/**
 * Hook that listens for gamepad connection and disconnection events and update global gamepad state
 */
export const useGamepadListenerConnection = () => {
  const updateGamepad = useUpdateGamepad();
  const { gamepadConnected } = useGamepadConnected();

  useEffect(() => {
    const onGamepadConnected = (event: GamepadEvent) => {
      updateGamepad(event.gamepad);
      gamepadToast('success', 'You successfully connected your gamepad!');
    };

    const onGamepadDisconnected = () => {
      updateGamepad(undefined);
      gamepadToast('error', 'You gamepad has been disconnected.');
    };

    window.addEventListener('gamepadconnected', onGamepadConnected);
    window.addEventListener('gamepaddisconnected', onGamepadDisconnected);

    return () => {
      window.removeEventListener('gamepadconnected', onGamepadConnected);
      window.removeEventListener('gamepaddisconnected', onGamepadDisconnected);
    };
  }, []);

  return gamepadConnected;
};
