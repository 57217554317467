import {
  janus_streaming_interfaces,
  localization_handler_interfaces,
  spot_localization_handler_interfaces,
  spot_ncb_rms_actions_interfaces,
  std_srvs,
} from 'rclnodejs';
import { useMemo } from 'react';

import { useDeviceName } from 'store/my-robot';

import { RosSignalR } from 'lib/roslibjs';

import { DeviceName } from 'types/models';

import { ROS_API } from './use-ros-api';
import { useRosBridge } from './use-ros-bridge';

//
export const DEVICE_NAME_SERVICES = {
  UNDOCK: (deviceName: DeviceName) => `/${deviceName}/undock` as const,
  DOCK: (deviceName: DeviceName) => `/${deviceName}/dock` as const,
  NCB_CALL_SERVICE: (deviceName: DeviceName) => `/${deviceName}/ncb/call_service` as const,
  LOCALIZE_TO_MAP: (deviceName: DeviceName) => `/${deviceName}/localize_to_map` as const,
  START_MAP_RECORDING: (deviceName: DeviceName) => `/${deviceName}/start_map_recording` as const,
  STOP_MAP_RECORDING: (deviceName: DeviceName) => `/${deviceName}/stop_map_recording` as const,
  CANCEL_MAP_RECORDING: (deviceName: DeviceName) => `/${deviceName}/cancel_map_recording` as const,
  REQUEST_STREAM_CHANGE: (deviceName: DeviceName) =>
    `/${deviceName}/handle_request_stream_change` as const,
} as const;

export type DeviceNameServices = ReturnType<
  (typeof DEVICE_NAME_SERVICES)[keyof typeof DEVICE_NAME_SERVICES]
>;

// todo - no need for this
export const ROS_SERVICES = {
  ROS_API,
} as const;

// helper for getting double nested objects values types
type DistributiveValues<T extends Record<string, any>> = T extends T ? T[keyof T] : never;
type InnerValues<T extends Record<keyof T, object>, K extends keyof T> = DistributiveValues<T[K]>;

export type RosServiceName = InnerValues<typeof ROS_SERVICES, keyof typeof ROS_SERVICES>; // is number|string

class RosServiceHandler {
  private readonly ros: RosSignalR;
  private readonly deviceName: DeviceName;

  constructor(ros: RosSignalR, deviceName: DeviceName) {
    this.ros = ros;
    this.deviceName = deviceName;
  }

  async sendDock() {
    const name = DEVICE_NAME_SERVICES.DOCK(this.deviceName);
    return this.ros.callRosService(name).then((res: any) => console.log({ res }));
  }

  async sendUndock() {
    const name = DEVICE_NAME_SERVICES.UNDOCK(this.deviceName);
    return this.ros.callRosService(name);
  }

  async LocalizeToMap(map_id: number) {
    const name = DEVICE_NAME_SERVICES.LOCALIZE_TO_MAP(this.deviceName);

    return this.ros.callRosService<
      spot_localization_handler_interfaces.srv.LocalizeToMap_Request,
      spot_localization_handler_interfaces.srv.LocalizeToMap_Response
    >(name, { map_id });
  }

  async StartMapRecording(map_name: string) {
    const name = DEVICE_NAME_SERVICES.START_MAP_RECORDING(this.deviceName);
    return this.ros.callRosService<
      localization_handler_interfaces.srv.MapRecord_Request,
      localization_handler_interfaces.srv.MapRecord_Response
    >(name, { map_name });
  }

  async StopMapRecording() {
    const name = DEVICE_NAME_SERVICES.STOP_MAP_RECORDING(this.deviceName);
    return this.ros.callRosService<std_srvs.srv.Trigger_Request, std_srvs.srv.Trigger_Response>(
      name,
      {},
    );
  }

  async CancelMapRecording() {
    const name = DEVICE_NAME_SERVICES.CANCEL_MAP_RECORDING(this.deviceName);
    return this.ros.callRosService<std_srvs.srv.Trigger_Request, std_srvs.srv.Trigger_Response>(
      name,
      {},
    );
  }

  async NCBCallService(request: string) {
    const name = DEVICE_NAME_SERVICES.NCB_CALL_SERVICE(this.deviceName);
    return this.ros.callRosService<
      spot_ncb_rms_actions_interfaces.srv.NetworkCompute_Request,
      spot_ncb_rms_actions_interfaces.srv.NetworkCompute_Response
    >(name, { request });
  }

  async requestStreamChange(json_request: string) {
    const name = DEVICE_NAME_SERVICES.REQUEST_STREAM_CHANGE(this.deviceName);

    return this.ros.callRosService<
      janus_streaming_interfaces.srv.StreamChange_Request,
      janus_streaming_interfaces.srv.StreamChange_Response
    >(name, { json_request });
  }
}

export function useRosService() {
  const { ros } = useRosBridge();
  const { deviceName } = useDeviceName();

  const RosServices = useMemo(() => new RosServiceHandler(ros, deviceName), [deviceName]);

  return { RosServices };
}
