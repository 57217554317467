import { memo } from 'react';

import { SDropdownDivider } from './Dropdown.styled';

/**
 * Dropdown divider component
 * @param {Number, String} height - Divider height
 * @param {Number, String} width - Divider width
 */

type DropdownDividerProps = {
  height: number | string;
  width: number | string;
};

const DropdownDivider = memo<DropdownDividerProps>(({ height = 1, width = '100%' }) => (
  <SDropdownDivider
    style={{
      height,
      width,
    }}
  />
));

export default DropdownDivider;
