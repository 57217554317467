import { atomFamily, selectorFamily, useRecoilValue } from 'recoil';

import { axiosRequest } from 'lib/api/axios';
import { getRobotTemplateConfig } from 'lib/api/urls';

import { ROBOT_MODEL } from 'types/models';
import {
  SingleRobotTemplateDetailed,
  SingleRobotTemplateDetailedDTO,
} from 'types/models/RobotTemplate';

const ROBOT_MODEL_IMAGE_MAP = {
  [ROBOT_MODEL.SPOT]: '/assets/robots/spot-600x341.png',
  [ROBOT_MODEL.MAGNI]: '/assets/robots/magni-800x653.png',
  [ROBOT_MODEL.TURTLEBOT]: '/assets/robots/turtlebot3-burger-389x441.png',
} as const;

export function robotImageByModelName(modelName: ROBOT_MODEL): string {
  const isValidModelName = Object.values(ROBOT_MODEL).includes(modelName);
  if (!isValidModelName) {
    console.error('Invalid robot model name', modelName);
    throw new Error(`Invalid robot model name: ${modelName}`);
  }

  return ROBOT_MODEL_IMAGE_MAP[modelName];
}

function extendRobotTemplate(robot: SingleRobotTemplateDetailedDTO): SingleRobotTemplateDetailed {
  const modelName = robot.modelName as ROBOT_MODEL;
  const imageUrl = robotImageByModelName(modelName);

  return {
    ...robot,
    imageUrl,
    modelName,
  };
}

const getRobotTemplate = async (robotTemplateId: number): Promise<SingleRobotTemplateDetailed> => {
  const { method, url } = getRobotTemplateConfig(robotTemplateId);
  const { data } = await axiosRequest<SingleRobotTemplateDetailedDTO>(method, url);
  return extendRobotTemplate(data);
};

export const robotTemplateAtom = atomFamily<SingleRobotTemplateDetailed, number>({
  key: 'robotTemplateAtom',
  default: selectorFamily({
    key: 'robotTemplateAtomSelector',
    get: (robotTemplateId) => () => getRobotTemplate(robotTemplateId),
  }),
});

export function useRobotTemplate(robotTemplateId: number): SingleRobotTemplateDetailed {
  return useRecoilValue(robotTemplateAtom(robotTemplateId));
}
