import { useSetRobotStatus } from 'store/my-robot';

import { IothubEvent, useIothubHub } from 'hooks/signalR';

import { RobotStatus } from 'types/models/Robot';

/**
 * Used for updating the battery status of the robot
 * todo - this should be in the side menu but this is maybe a problem with the current problem of reloading the menu
 */
export function useReceiveRobotMainModuleReportedData() {
  const { updateStatus } = useSetRobotStatus();

  // Silently update robot status data with received with signalR
  const onReceiveRobotStatusData = (robotStatusData: RobotStatus) => {
    updateStatus(robotStatusData);
  };

  // Connect iot hub when robot data fetched
  return useIothubHub({
    listenMethods: {
      [IothubEvent.RECEIVE_ROBOT_MAIN_MODULE_REPORTED_DATA]: onReceiveRobotStatusData,
    },
  });
}
