import { useRecoilCallback, useRecoilValue } from 'recoil';

import { TGamepadButton, XboxOneGamepadButton } from 'types/models/gamepad';

import { gamepadButtonPressedSelector } from './selectors';

/**
 * Return a callback that gives the current button pressed state without subscribing to the state
 */
export function useGamepadButtonPressedCallback() {
  const getButtonPressed = useRecoilCallback(
    ({ snapshot }) =>
      (buttonKey: TGamepadButton) =>
        snapshot.getLoadable(gamepadButtonPressedSelector(buttonKey)).contents,
    [],
  );

  function getIsLTButtonPressed() {
    return getButtonPressed(XboxOneGamepadButton.LT);
  }

  // check if this is performant enough
  function waitForButtonUnpressed(buttonKey: TGamepadButton) {
    return new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (!getButtonPressed(buttonKey)) {
          clearInterval(interval);
          resolve();
        }
      }, 10);
    });
  }

  return { getButtonPressed, getIsLTButtonPressed, waitForButtonUnpressed };
}

/**
 * Returns the buttons state
 */
export function useGamepadButtonPressed(buttonKey: TGamepadButton) {
  return useRecoilValue(gamepadButtonPressedSelector(buttonKey));
}
