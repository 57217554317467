/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { JointCommand_UpdateRequest } from "./basic_command";
import { FullBodyCommand_Feedback, FullBodyCommand_Request } from "./full_body_command";
import { RequestHeader, ResponseHeader } from "./header";
import { Lease, LeaseUseResult } from "./lease";
import { BehaviorFault, SystemFault } from "./robot_state";
import { SynchronizedCommand_Feedback, SynchronizedCommand_Request } from "./synchronized_command";

export const protobufPackage = "bosdyn.api";

/**
 * A command for a robot to execute.
 * The server decides if a set of commands is valid for a given robot and configuration.
 */
export interface RobotCommand {
  /** Commands which require control of entire robot. */
  fullBodyCommand?:
    | FullBodyCommand_Request
    | undefined;
  /** A synchronized command, for partial or full control of robot. */
  synchronizedCommand?: SynchronizedCommand_Request | undefined;
}

/**
 * Command specific feedback. Distance to goal, estimated time remaining, probability of
 * success, etc. Note that the feedback should directly mirror the command request.
 */
export interface RobotCommandFeedback {
  /** Commands which require control of entire robot. */
  fullBodyFeedback?:
    | FullBodyCommand_Feedback
    | undefined;
  /** A synchronized command, for partial or full control of robot. */
  synchronizedFeedback?: SynchronizedCommand_Feedback | undefined;
}

/**
 * A RobotCommand request message includes the lease and command as well as a clock
 * identifier to ensure timesync when issuing commands with a fixed length.
 */
export interface RobotCommandRequest {
  /** Common request header. */
  header:
    | RequestHeader
    | undefined;
  /** The Lease to show ownership of the robot. */
  lease:
    | Lease
    | undefined;
  /** A command for a robot to execute. A command can be comprised of several subcommands. */
  command:
    | RobotCommand
    | undefined;
  /** Identifier provided by the time sync service to verify time sync between robot and client. */
  clockIdentifier: string;
}

/**
 * The RobotCommand response message contains a robot command id that can be used to poll the
 * robot command service for feedback on the state of the command.
 */
export interface RobotCommandResponse {
  /** Common response header. */
  header:
    | ResponseHeader
    | undefined;
  /** Details about how the lease was used. */
  leaseUseResult:
    | LeaseUseResult
    | undefined;
  /** Return status for a request. */
  status: RobotCommandResponse_Status;
  /** Human-readable error description.  Not for programmatic analysis. */
  message: string;
  /** Unique identifier for the command, If empty, command was not accepted. */
  robotCommandId: number;
}

export enum RobotCommandResponse_Status {
  /** STATUS_UNKNOWN - An unknown / unexpected error occurred. */
  STATUS_UNKNOWN = 0,
  /** STATUS_OK - Request was accepted. */
  STATUS_OK = 1,
  /** STATUS_INVALID_REQUEST - [Programming Error] */
  STATUS_INVALID_REQUEST = 2,
  /** STATUS_UNSUPPORTED - The robot does not understand this command. */
  STATUS_UNSUPPORTED = 3,
  /** STATUS_NO_TIMESYNC - [Timesync Error] */
  STATUS_NO_TIMESYNC = 4,
  /** STATUS_EXPIRED - The command was received after its end_time had already passed. */
  STATUS_EXPIRED = 5,
  /** STATUS_TOO_DISTANT - The command end time was too far in the future. */
  STATUS_TOO_DISTANT = 6,
  /** STATUS_NOT_POWERED_ON - [Hardware Error] */
  STATUS_NOT_POWERED_ON = 7,
  /** STATUS_BEHAVIOR_FAULT - [Robot State Error] */
  STATUS_BEHAVIOR_FAULT = 9,
  /** STATUS_DOCKED - The robot cannot be docked for certain commands. */
  STATUS_DOCKED = 10,
  /** STATUS_UNKNOWN_FRAME - // [Frame Error] */
  STATUS_UNKNOWN_FRAME = 8,
  UNRECOGNIZED = -1,
}

export function robotCommandResponse_StatusFromJSON(object: any): RobotCommandResponse_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return RobotCommandResponse_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_OK":
      return RobotCommandResponse_Status.STATUS_OK;
    case 2:
    case "STATUS_INVALID_REQUEST":
      return RobotCommandResponse_Status.STATUS_INVALID_REQUEST;
    case 3:
    case "STATUS_UNSUPPORTED":
      return RobotCommandResponse_Status.STATUS_UNSUPPORTED;
    case 4:
    case "STATUS_NO_TIMESYNC":
      return RobotCommandResponse_Status.STATUS_NO_TIMESYNC;
    case 5:
    case "STATUS_EXPIRED":
      return RobotCommandResponse_Status.STATUS_EXPIRED;
    case 6:
    case "STATUS_TOO_DISTANT":
      return RobotCommandResponse_Status.STATUS_TOO_DISTANT;
    case 7:
    case "STATUS_NOT_POWERED_ON":
      return RobotCommandResponse_Status.STATUS_NOT_POWERED_ON;
    case 9:
    case "STATUS_BEHAVIOR_FAULT":
      return RobotCommandResponse_Status.STATUS_BEHAVIOR_FAULT;
    case 10:
    case "STATUS_DOCKED":
      return RobotCommandResponse_Status.STATUS_DOCKED;
    case 8:
    case "STATUS_UNKNOWN_FRAME":
      return RobotCommandResponse_Status.STATUS_UNKNOWN_FRAME;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RobotCommandResponse_Status.UNRECOGNIZED;
  }
}

export function robotCommandResponse_StatusToJSON(object: RobotCommandResponse_Status): string {
  switch (object) {
    case RobotCommandResponse_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case RobotCommandResponse_Status.STATUS_OK:
      return "STATUS_OK";
    case RobotCommandResponse_Status.STATUS_INVALID_REQUEST:
      return "STATUS_INVALID_REQUEST";
    case RobotCommandResponse_Status.STATUS_UNSUPPORTED:
      return "STATUS_UNSUPPORTED";
    case RobotCommandResponse_Status.STATUS_NO_TIMESYNC:
      return "STATUS_NO_TIMESYNC";
    case RobotCommandResponse_Status.STATUS_EXPIRED:
      return "STATUS_EXPIRED";
    case RobotCommandResponse_Status.STATUS_TOO_DISTANT:
      return "STATUS_TOO_DISTANT";
    case RobotCommandResponse_Status.STATUS_NOT_POWERED_ON:
      return "STATUS_NOT_POWERED_ON";
    case RobotCommandResponse_Status.STATUS_BEHAVIOR_FAULT:
      return "STATUS_BEHAVIOR_FAULT";
    case RobotCommandResponse_Status.STATUS_DOCKED:
      return "STATUS_DOCKED";
    case RobotCommandResponse_Status.STATUS_UNKNOWN_FRAME:
      return "STATUS_UNKNOWN_FRAME";
    case RobotCommandResponse_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * The RobotCommandFeedback request message, which can get the feedback for a specific
 * robot command id number.
 */
export interface RobotCommandFeedbackRequest {
  /** Common request header. */
  header:
    | RequestHeader
    | undefined;
  /** Unique identifier for the command, provided by StartRequest. */
  robotCommandId: number;
}

/** The RobotCommandFeedback response message, which contains the progress of the robot command. */
export interface RobotCommandFeedbackResponse {
  /** Common response header. */
  header:
    | ResponseHeader
    | undefined;
  /** Details about how the lease was used. */
  leaseUseResult:
    | LeaseUseResult
    | undefined;
  /** Command specific feedback. */
  feedback: RobotCommandFeedback | undefined;
}

/** A ClearBehaviorFault request message has the associated behavior fault id to be cleared. */
export interface ClearBehaviorFaultRequest {
  /** Common request header. */
  header:
    | RequestHeader
    | undefined;
  /** The Lease to show ownership of the robot. */
  lease:
    | Lease
    | undefined;
  /** Unique identifier for the error */
  behaviorFaultId: number;
}

/**
 * A ClearBehaviorFault response message has status indicating whether the service cleared
 * the fault or not.
 */
export interface ClearBehaviorFaultResponse {
  /** Common response header. */
  header:
    | ResponseHeader
    | undefined;
  /** Details about how the lease was used. */
  leaseUseResult:
    | LeaseUseResult
    | undefined;
  /** Return status for a request. */
  status: ClearBehaviorFaultResponse_Status;
  /** Echo back the behavior fault if it was active at the time of request. */
  behaviorFault:
    | BehaviorFault
    | undefined;
  /** Blocking hardware faults for an unclearable behavior fault. */
  blockingSystemFaults: SystemFault[];
}

export enum ClearBehaviorFaultResponse_Status {
  /** STATUS_UNKNOWN - An unknown / unexpected error occurred. */
  STATUS_UNKNOWN = 0,
  /** STATUS_CLEARED - The BehaviorFault has been cleared. */
  STATUS_CLEARED = 1,
  /** STATUS_NOT_CLEARED - The BehaviorFault could not be cleared. */
  STATUS_NOT_CLEARED = 2,
  UNRECOGNIZED = -1,
}

export function clearBehaviorFaultResponse_StatusFromJSON(object: any): ClearBehaviorFaultResponse_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return ClearBehaviorFaultResponse_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_CLEARED":
      return ClearBehaviorFaultResponse_Status.STATUS_CLEARED;
    case 2:
    case "STATUS_NOT_CLEARED":
      return ClearBehaviorFaultResponse_Status.STATUS_NOT_CLEARED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ClearBehaviorFaultResponse_Status.UNRECOGNIZED;
  }
}

export function clearBehaviorFaultResponse_StatusToJSON(object: ClearBehaviorFaultResponse_Status): string {
  switch (object) {
    case ClearBehaviorFaultResponse_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case ClearBehaviorFaultResponse_Status.STATUS_CLEARED:
      return "STATUS_CLEARED";
    case ClearBehaviorFaultResponse_Status.STATUS_NOT_CLEARED:
      return "STATUS_NOT_CLEARED";
    case ClearBehaviorFaultResponse_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface JointControlStreamRequest {
  /** Common request header. */
  header:
    | RequestHeader
    | undefined;
  /** Joint command details */
  jointCommand: JointCommand_UpdateRequest | undefined;
}

export interface JointControlStreamResponse {
  /** Common response header. */
  header:
    | ResponseHeader
    | undefined;
  /** Return status for the stream. */
  status: JointControlStreamResponse_Status;
  /** Human-readable error description.  Not for programmatic analysis. */
  message: string;
}

export enum JointControlStreamResponse_Status {
  /** STATUS_UNKNOWN - An unknown / unexpected error occurred. */
  STATUS_UNKNOWN = 0,
  /** STATUS_OK - Steam was accepted and ended normally */
  STATUS_OK = 1,
  /** STATUS_INVALID_REQUEST - [Programming Error] */
  STATUS_INVALID_REQUEST = 2,
  /** STATUS_INACTIVE - The robot is not in joint control mode. */
  STATUS_INACTIVE = 3,
  /** STATUS_EXPIRED - [Timesync Error] */
  STATUS_EXPIRED = 4,
  /** STATUS_TOO_DISTANT - The command end time was too far in the future. */
  STATUS_TOO_DISTANT = 5,
  UNRECOGNIZED = -1,
}

export function jointControlStreamResponse_StatusFromJSON(object: any): JointControlStreamResponse_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return JointControlStreamResponse_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_OK":
      return JointControlStreamResponse_Status.STATUS_OK;
    case 2:
    case "STATUS_INVALID_REQUEST":
      return JointControlStreamResponse_Status.STATUS_INVALID_REQUEST;
    case 3:
    case "STATUS_INACTIVE":
      return JointControlStreamResponse_Status.STATUS_INACTIVE;
    case 4:
    case "STATUS_EXPIRED":
      return JointControlStreamResponse_Status.STATUS_EXPIRED;
    case 5:
    case "STATUS_TOO_DISTANT":
      return JointControlStreamResponse_Status.STATUS_TOO_DISTANT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return JointControlStreamResponse_Status.UNRECOGNIZED;
  }
}

export function jointControlStreamResponse_StatusToJSON(object: JointControlStreamResponse_Status): string {
  switch (object) {
    case JointControlStreamResponse_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case JointControlStreamResponse_Status.STATUS_OK:
      return "STATUS_OK";
    case JointControlStreamResponse_Status.STATUS_INVALID_REQUEST:
      return "STATUS_INVALID_REQUEST";
    case JointControlStreamResponse_Status.STATUS_INACTIVE:
      return "STATUS_INACTIVE";
    case JointControlStreamResponse_Status.STATUS_EXPIRED:
      return "STATUS_EXPIRED";
    case JointControlStreamResponse_Status.STATUS_TOO_DISTANT:
      return "STATUS_TOO_DISTANT";
    case JointControlStreamResponse_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseRobotCommand(): RobotCommand {
  return { fullBodyCommand: undefined, synchronizedCommand: undefined };
}

export const RobotCommand = {
  encode(message: RobotCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fullBodyCommand !== undefined) {
      FullBodyCommand_Request.encode(message.fullBodyCommand, writer.uint32(10).fork()).ldelim();
    }
    if (message.synchronizedCommand !== undefined) {
      SynchronizedCommand_Request.encode(message.synchronizedCommand, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RobotCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fullBodyCommand = FullBodyCommand_Request.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.synchronizedCommand = SynchronizedCommand_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RobotCommand {
    return {
      fullBodyCommand: isSet(object.fullBodyCommand)
        ? FullBodyCommand_Request.fromJSON(object.fullBodyCommand)
        : undefined,
      synchronizedCommand: isSet(object.synchronizedCommand)
        ? SynchronizedCommand_Request.fromJSON(object.synchronizedCommand)
        : undefined,
    };
  },

  toJSON(message: RobotCommand): unknown {
    const obj: any = {};
    if (message.fullBodyCommand !== undefined) {
      obj.fullBodyCommand = FullBodyCommand_Request.toJSON(message.fullBodyCommand);
    }
    if (message.synchronizedCommand !== undefined) {
      obj.synchronizedCommand = SynchronizedCommand_Request.toJSON(message.synchronizedCommand);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotCommand>, I>>(base?: I): RobotCommand {
    return RobotCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotCommand>, I>>(object: I): RobotCommand {
    const message = createBaseRobotCommand();
    message.fullBodyCommand = (object.fullBodyCommand !== undefined && object.fullBodyCommand !== null)
      ? FullBodyCommand_Request.fromPartial(object.fullBodyCommand)
      : undefined;
    message.synchronizedCommand = (object.synchronizedCommand !== undefined && object.synchronizedCommand !== null)
      ? SynchronizedCommand_Request.fromPartial(object.synchronizedCommand)
      : undefined;
    return message;
  },
};

function createBaseRobotCommandFeedback(): RobotCommandFeedback {
  return { fullBodyFeedback: undefined, synchronizedFeedback: undefined };
}

export const RobotCommandFeedback = {
  encode(message: RobotCommandFeedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fullBodyFeedback !== undefined) {
      FullBodyCommand_Feedback.encode(message.fullBodyFeedback, writer.uint32(18).fork()).ldelim();
    }
    if (message.synchronizedFeedback !== undefined) {
      SynchronizedCommand_Feedback.encode(message.synchronizedFeedback, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RobotCommandFeedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotCommandFeedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fullBodyFeedback = FullBodyCommand_Feedback.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.synchronizedFeedback = SynchronizedCommand_Feedback.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RobotCommandFeedback {
    return {
      fullBodyFeedback: isSet(object.fullBodyFeedback)
        ? FullBodyCommand_Feedback.fromJSON(object.fullBodyFeedback)
        : undefined,
      synchronizedFeedback: isSet(object.synchronizedFeedback)
        ? SynchronizedCommand_Feedback.fromJSON(object.synchronizedFeedback)
        : undefined,
    };
  },

  toJSON(message: RobotCommandFeedback): unknown {
    const obj: any = {};
    if (message.fullBodyFeedback !== undefined) {
      obj.fullBodyFeedback = FullBodyCommand_Feedback.toJSON(message.fullBodyFeedback);
    }
    if (message.synchronizedFeedback !== undefined) {
      obj.synchronizedFeedback = SynchronizedCommand_Feedback.toJSON(message.synchronizedFeedback);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotCommandFeedback>, I>>(base?: I): RobotCommandFeedback {
    return RobotCommandFeedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotCommandFeedback>, I>>(object: I): RobotCommandFeedback {
    const message = createBaseRobotCommandFeedback();
    message.fullBodyFeedback = (object.fullBodyFeedback !== undefined && object.fullBodyFeedback !== null)
      ? FullBodyCommand_Feedback.fromPartial(object.fullBodyFeedback)
      : undefined;
    message.synchronizedFeedback = (object.synchronizedFeedback !== undefined && object.synchronizedFeedback !== null)
      ? SynchronizedCommand_Feedback.fromPartial(object.synchronizedFeedback)
      : undefined;
    return message;
  },
};

function createBaseRobotCommandRequest(): RobotCommandRequest {
  return { header: undefined, lease: undefined, command: undefined, clockIdentifier: "" };
}

export const RobotCommandRequest = {
  encode(message: RobotCommandRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      RequestHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    if (message.lease !== undefined) {
      Lease.encode(message.lease, writer.uint32(18).fork()).ldelim();
    }
    if (message.command !== undefined) {
      RobotCommand.encode(message.command, writer.uint32(26).fork()).ldelim();
    }
    if (message.clockIdentifier !== "") {
      writer.uint32(34).string(message.clockIdentifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RobotCommandRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotCommandRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = RequestHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lease = Lease.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.command = RobotCommand.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clockIdentifier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RobotCommandRequest {
    return {
      header: isSet(object.header) ? RequestHeader.fromJSON(object.header) : undefined,
      lease: isSet(object.lease) ? Lease.fromJSON(object.lease) : undefined,
      command: isSet(object.command) ? RobotCommand.fromJSON(object.command) : undefined,
      clockIdentifier: isSet(object.clockIdentifier) ? globalThis.String(object.clockIdentifier) : "",
    };
  },

  toJSON(message: RobotCommandRequest): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = RequestHeader.toJSON(message.header);
    }
    if (message.lease !== undefined) {
      obj.lease = Lease.toJSON(message.lease);
    }
    if (message.command !== undefined) {
      obj.command = RobotCommand.toJSON(message.command);
    }
    if (message.clockIdentifier !== "") {
      obj.clockIdentifier = message.clockIdentifier;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotCommandRequest>, I>>(base?: I): RobotCommandRequest {
    return RobotCommandRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotCommandRequest>, I>>(object: I): RobotCommandRequest {
    const message = createBaseRobotCommandRequest();
    message.header = (object.header !== undefined && object.header !== null)
      ? RequestHeader.fromPartial(object.header)
      : undefined;
    message.lease = (object.lease !== undefined && object.lease !== null) ? Lease.fromPartial(object.lease) : undefined;
    message.command = (object.command !== undefined && object.command !== null)
      ? RobotCommand.fromPartial(object.command)
      : undefined;
    message.clockIdentifier = object.clockIdentifier ?? "";
    return message;
  },
};

function createBaseRobotCommandResponse(): RobotCommandResponse {
  return { header: undefined, leaseUseResult: undefined, status: 0, message: "", robotCommandId: 0 };
}

export const RobotCommandResponse = {
  encode(message: RobotCommandResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      ResponseHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    if (message.leaseUseResult !== undefined) {
      LeaseUseResult.encode(message.leaseUseResult, writer.uint32(18).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    if (message.message !== "") {
      writer.uint32(34).string(message.message);
    }
    if (message.robotCommandId !== 0) {
      writer.uint32(40).uint32(message.robotCommandId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RobotCommandResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotCommandResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = ResponseHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.leaseUseResult = LeaseUseResult.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.message = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.robotCommandId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RobotCommandResponse {
    return {
      header: isSet(object.header) ? ResponseHeader.fromJSON(object.header) : undefined,
      leaseUseResult: isSet(object.leaseUseResult) ? LeaseUseResult.fromJSON(object.leaseUseResult) : undefined,
      status: isSet(object.status) ? robotCommandResponse_StatusFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      robotCommandId: isSet(object.robotCommandId) ? globalThis.Number(object.robotCommandId) : 0,
    };
  },

  toJSON(message: RobotCommandResponse): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = ResponseHeader.toJSON(message.header);
    }
    if (message.leaseUseResult !== undefined) {
      obj.leaseUseResult = LeaseUseResult.toJSON(message.leaseUseResult);
    }
    if (message.status !== 0) {
      obj.status = robotCommandResponse_StatusToJSON(message.status);
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.robotCommandId !== 0) {
      obj.robotCommandId = Math.round(message.robotCommandId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotCommandResponse>, I>>(base?: I): RobotCommandResponse {
    return RobotCommandResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotCommandResponse>, I>>(object: I): RobotCommandResponse {
    const message = createBaseRobotCommandResponse();
    message.header = (object.header !== undefined && object.header !== null)
      ? ResponseHeader.fromPartial(object.header)
      : undefined;
    message.leaseUseResult = (object.leaseUseResult !== undefined && object.leaseUseResult !== null)
      ? LeaseUseResult.fromPartial(object.leaseUseResult)
      : undefined;
    message.status = object.status ?? 0;
    message.message = object.message ?? "";
    message.robotCommandId = object.robotCommandId ?? 0;
    return message;
  },
};

function createBaseRobotCommandFeedbackRequest(): RobotCommandFeedbackRequest {
  return { header: undefined, robotCommandId: 0 };
}

export const RobotCommandFeedbackRequest = {
  encode(message: RobotCommandFeedbackRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      RequestHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    if (message.robotCommandId !== 0) {
      writer.uint32(16).uint32(message.robotCommandId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RobotCommandFeedbackRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotCommandFeedbackRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = RequestHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.robotCommandId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RobotCommandFeedbackRequest {
    return {
      header: isSet(object.header) ? RequestHeader.fromJSON(object.header) : undefined,
      robotCommandId: isSet(object.robotCommandId) ? globalThis.Number(object.robotCommandId) : 0,
    };
  },

  toJSON(message: RobotCommandFeedbackRequest): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = RequestHeader.toJSON(message.header);
    }
    if (message.robotCommandId !== 0) {
      obj.robotCommandId = Math.round(message.robotCommandId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotCommandFeedbackRequest>, I>>(base?: I): RobotCommandFeedbackRequest {
    return RobotCommandFeedbackRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotCommandFeedbackRequest>, I>>(object: I): RobotCommandFeedbackRequest {
    const message = createBaseRobotCommandFeedbackRequest();
    message.header = (object.header !== undefined && object.header !== null)
      ? RequestHeader.fromPartial(object.header)
      : undefined;
    message.robotCommandId = object.robotCommandId ?? 0;
    return message;
  },
};

function createBaseRobotCommandFeedbackResponse(): RobotCommandFeedbackResponse {
  return { header: undefined, leaseUseResult: undefined, feedback: undefined };
}

export const RobotCommandFeedbackResponse = {
  encode(message: RobotCommandFeedbackResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      ResponseHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    if (message.leaseUseResult !== undefined) {
      LeaseUseResult.encode(message.leaseUseResult, writer.uint32(42).fork()).ldelim();
    }
    if (message.feedback !== undefined) {
      RobotCommandFeedback.encode(message.feedback, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RobotCommandFeedbackResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotCommandFeedbackResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = ResponseHeader.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.leaseUseResult = LeaseUseResult.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.feedback = RobotCommandFeedback.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RobotCommandFeedbackResponse {
    return {
      header: isSet(object.header) ? ResponseHeader.fromJSON(object.header) : undefined,
      leaseUseResult: isSet(object.leaseUseResult) ? LeaseUseResult.fromJSON(object.leaseUseResult) : undefined,
      feedback: isSet(object.feedback) ? RobotCommandFeedback.fromJSON(object.feedback) : undefined,
    };
  },

  toJSON(message: RobotCommandFeedbackResponse): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = ResponseHeader.toJSON(message.header);
    }
    if (message.leaseUseResult !== undefined) {
      obj.leaseUseResult = LeaseUseResult.toJSON(message.leaseUseResult);
    }
    if (message.feedback !== undefined) {
      obj.feedback = RobotCommandFeedback.toJSON(message.feedback);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotCommandFeedbackResponse>, I>>(base?: I): RobotCommandFeedbackResponse {
    return RobotCommandFeedbackResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotCommandFeedbackResponse>, I>>(object: I): RobotCommandFeedbackResponse {
    const message = createBaseRobotCommandFeedbackResponse();
    message.header = (object.header !== undefined && object.header !== null)
      ? ResponseHeader.fromPartial(object.header)
      : undefined;
    message.leaseUseResult = (object.leaseUseResult !== undefined && object.leaseUseResult !== null)
      ? LeaseUseResult.fromPartial(object.leaseUseResult)
      : undefined;
    message.feedback = (object.feedback !== undefined && object.feedback !== null)
      ? RobotCommandFeedback.fromPartial(object.feedback)
      : undefined;
    return message;
  },
};

function createBaseClearBehaviorFaultRequest(): ClearBehaviorFaultRequest {
  return { header: undefined, lease: undefined, behaviorFaultId: 0 };
}

export const ClearBehaviorFaultRequest = {
  encode(message: ClearBehaviorFaultRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      RequestHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    if (message.lease !== undefined) {
      Lease.encode(message.lease, writer.uint32(18).fork()).ldelim();
    }
    if (message.behaviorFaultId !== 0) {
      writer.uint32(24).uint32(message.behaviorFaultId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClearBehaviorFaultRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClearBehaviorFaultRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = RequestHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lease = Lease.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.behaviorFaultId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClearBehaviorFaultRequest {
    return {
      header: isSet(object.header) ? RequestHeader.fromJSON(object.header) : undefined,
      lease: isSet(object.lease) ? Lease.fromJSON(object.lease) : undefined,
      behaviorFaultId: isSet(object.behaviorFaultId) ? globalThis.Number(object.behaviorFaultId) : 0,
    };
  },

  toJSON(message: ClearBehaviorFaultRequest): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = RequestHeader.toJSON(message.header);
    }
    if (message.lease !== undefined) {
      obj.lease = Lease.toJSON(message.lease);
    }
    if (message.behaviorFaultId !== 0) {
      obj.behaviorFaultId = Math.round(message.behaviorFaultId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClearBehaviorFaultRequest>, I>>(base?: I): ClearBehaviorFaultRequest {
    return ClearBehaviorFaultRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClearBehaviorFaultRequest>, I>>(object: I): ClearBehaviorFaultRequest {
    const message = createBaseClearBehaviorFaultRequest();
    message.header = (object.header !== undefined && object.header !== null)
      ? RequestHeader.fromPartial(object.header)
      : undefined;
    message.lease = (object.lease !== undefined && object.lease !== null) ? Lease.fromPartial(object.lease) : undefined;
    message.behaviorFaultId = object.behaviorFaultId ?? 0;
    return message;
  },
};

function createBaseClearBehaviorFaultResponse(): ClearBehaviorFaultResponse {
  return {
    header: undefined,
    leaseUseResult: undefined,
    status: 0,
    behaviorFault: undefined,
    blockingSystemFaults: [],
  };
}

export const ClearBehaviorFaultResponse = {
  encode(message: ClearBehaviorFaultResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      ResponseHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    if (message.leaseUseResult !== undefined) {
      LeaseUseResult.encode(message.leaseUseResult, writer.uint32(18).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    if (message.behaviorFault !== undefined) {
      BehaviorFault.encode(message.behaviorFault, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.blockingSystemFaults) {
      SystemFault.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClearBehaviorFaultResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClearBehaviorFaultResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = ResponseHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.leaseUseResult = LeaseUseResult.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.behaviorFault = BehaviorFault.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.blockingSystemFaults.push(SystemFault.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClearBehaviorFaultResponse {
    return {
      header: isSet(object.header) ? ResponseHeader.fromJSON(object.header) : undefined,
      leaseUseResult: isSet(object.leaseUseResult) ? LeaseUseResult.fromJSON(object.leaseUseResult) : undefined,
      status: isSet(object.status) ? clearBehaviorFaultResponse_StatusFromJSON(object.status) : 0,
      behaviorFault: isSet(object.behaviorFault) ? BehaviorFault.fromJSON(object.behaviorFault) : undefined,
      blockingSystemFaults: globalThis.Array.isArray(object?.blockingSystemFaults)
        ? object.blockingSystemFaults.map((e: any) => SystemFault.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ClearBehaviorFaultResponse): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = ResponseHeader.toJSON(message.header);
    }
    if (message.leaseUseResult !== undefined) {
      obj.leaseUseResult = LeaseUseResult.toJSON(message.leaseUseResult);
    }
    if (message.status !== 0) {
      obj.status = clearBehaviorFaultResponse_StatusToJSON(message.status);
    }
    if (message.behaviorFault !== undefined) {
      obj.behaviorFault = BehaviorFault.toJSON(message.behaviorFault);
    }
    if (message.blockingSystemFaults?.length) {
      obj.blockingSystemFaults = message.blockingSystemFaults.map((e) => SystemFault.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClearBehaviorFaultResponse>, I>>(base?: I): ClearBehaviorFaultResponse {
    return ClearBehaviorFaultResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClearBehaviorFaultResponse>, I>>(object: I): ClearBehaviorFaultResponse {
    const message = createBaseClearBehaviorFaultResponse();
    message.header = (object.header !== undefined && object.header !== null)
      ? ResponseHeader.fromPartial(object.header)
      : undefined;
    message.leaseUseResult = (object.leaseUseResult !== undefined && object.leaseUseResult !== null)
      ? LeaseUseResult.fromPartial(object.leaseUseResult)
      : undefined;
    message.status = object.status ?? 0;
    message.behaviorFault = (object.behaviorFault !== undefined && object.behaviorFault !== null)
      ? BehaviorFault.fromPartial(object.behaviorFault)
      : undefined;
    message.blockingSystemFaults = object.blockingSystemFaults?.map((e) => SystemFault.fromPartial(e)) || [];
    return message;
  },
};

function createBaseJointControlStreamRequest(): JointControlStreamRequest {
  return { header: undefined, jointCommand: undefined };
}

export const JointControlStreamRequest = {
  encode(message: JointControlStreamRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      RequestHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    if (message.jointCommand !== undefined) {
      JointCommand_UpdateRequest.encode(message.jointCommand, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JointControlStreamRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJointControlStreamRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = RequestHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.jointCommand = JointCommand_UpdateRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JointControlStreamRequest {
    return {
      header: isSet(object.header) ? RequestHeader.fromJSON(object.header) : undefined,
      jointCommand: isSet(object.jointCommand) ? JointCommand_UpdateRequest.fromJSON(object.jointCommand) : undefined,
    };
  },

  toJSON(message: JointControlStreamRequest): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = RequestHeader.toJSON(message.header);
    }
    if (message.jointCommand !== undefined) {
      obj.jointCommand = JointCommand_UpdateRequest.toJSON(message.jointCommand);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JointControlStreamRequest>, I>>(base?: I): JointControlStreamRequest {
    return JointControlStreamRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JointControlStreamRequest>, I>>(object: I): JointControlStreamRequest {
    const message = createBaseJointControlStreamRequest();
    message.header = (object.header !== undefined && object.header !== null)
      ? RequestHeader.fromPartial(object.header)
      : undefined;
    message.jointCommand = (object.jointCommand !== undefined && object.jointCommand !== null)
      ? JointCommand_UpdateRequest.fromPartial(object.jointCommand)
      : undefined;
    return message;
  },
};

function createBaseJointControlStreamResponse(): JointControlStreamResponse {
  return { header: undefined, status: 0, message: "" };
}

export const JointControlStreamResponse = {
  encode(message: JointControlStreamResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      ResponseHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    if (message.message !== "") {
      writer.uint32(26).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JointControlStreamResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJointControlStreamResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = ResponseHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JointControlStreamResponse {
    return {
      header: isSet(object.header) ? ResponseHeader.fromJSON(object.header) : undefined,
      status: isSet(object.status) ? jointControlStreamResponse_StatusFromJSON(object.status) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : "",
    };
  },

  toJSON(message: JointControlStreamResponse): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = ResponseHeader.toJSON(message.header);
    }
    if (message.status !== 0) {
      obj.status = jointControlStreamResponse_StatusToJSON(message.status);
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JointControlStreamResponse>, I>>(base?: I): JointControlStreamResponse {
    return JointControlStreamResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JointControlStreamResponse>, I>>(object: I): JointControlStreamResponse {
    const message = createBaseJointControlStreamResponse();
    message.header = (object.header !== undefined && object.header !== null)
      ? ResponseHeader.fromPartial(object.header)
      : undefined;
    message.status = object.status ?? 0;
    message.message = object.message ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
