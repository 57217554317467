import ReactSlider from 'react-slider';
import styled, { css } from 'styled-components';

import { STypography } from 'components/ui';

export const SThumb = styled.div`
  height: 16px;
  width: 16px;
  line-height: 16px;
  background-color: ${(props) => props.theme.colors.primary.light};
  border-radius: 50%;
  cursor: grab;
  outline: none !important;
`;

type TrackProps = {
  count: number;
  index: number;
};

export const STrack = styled.div<TrackProps>`
  background-color: ${(props) => {
    if (props.count > 1) {
      return props.count - props.index === props.index
        ? props.theme.colors.primary.dark
        : props.theme.colors.background.light;
    } else {
      return props.index === 0
        ? props.theme.colors.primary.dark
        : props.theme.colors.background.light;
    }
  }};
  border-radius: 999px;
`;

export const SSlider = styled(ReactSlider)`
  width: 100%;
  height: 8px;

  ${STrack} {
    top: 0;
    bottom: 0;
  }

  ${SThumb} {
    transform: translateY(-25%);
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;

      ${STrack} {
        background-color: ${props.theme.colors.neutral};
      }

      ${SThumb} {
        background-color: ${props.theme.colors.text.dark};
        cursor: not-allowed;
      }
    `}
`;

export const STrackContainer = styled.div`
  padding: ${(props) => props.theme.spacing(0, 2)};
  height: 100%;
  width: 100%;
`;

export const SSliderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
`;

type SlideMainContainerProps = {
  isVertical?: boolean;
  disabled?: boolean;
};

export const SSliderMainContainer = styled.div<SlideMainContainerProps>`
  display: flex;
  align-items: center;
  margin: auto;
  flex-direction: ${(props) => (props.isVertical ? 'row' : 'column')};
  user-select: none;
  width: 100%;
  height: auto;

  & > ${STypography} {
    // padding: ${(props) => props.theme.spacing(0, 0, 1, 0)};
  }

  ${SSliderContainer} {
    flex-direction: ${(props) => (props.isVertical ? 'column' : 'row')};

    width: 100%;
    height: 8px;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.8;

      ${STypography} {
        color: ${props.theme.colors.text.dark};
      }
    `}

  ${(props) =>
    props.isVertical &&
    css`
      width: auto;
      height: 100%;

      & > ${STypography} {
        padding: ${props.theme.spacing(0, 2, 0, 0)};
      }

      ${SSliderContainer} {
        width: 8px;
        height: 100%;

        ${STrackContainer} {
          padding: ${props.theme.spacing(1, 0)};
        }

        ${SSlider} {
          width: 8px;
          height: 100%;

          ${STrack} {
            left: 0;
            right: 0;
          }

          ${SThumb} {
            transform: translateX(-25%);
          }
        }
      }
    `}
`;
