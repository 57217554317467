import { CSSProp } from 'styled-components';

type Color = {
  light: string;
  main: string;
  dark: string;
};

export enum ThemeColorKeys {
  PRIMARY_LIGHT = 'primary.light',
  PRIMARY_MAIN = 'primary.main',
  PRIMARY_DARK = 'primary.dark',
  BACKGROUND_LIGHT = 'background.light',
  BACKGROUND_MAIN = 'background.main',
  BACKGROUND_DARK = 'background.dark',
  TEXT_LIGHT = 'text.light',
  TEXT_MAIN = 'text.main',
  TEXT_DARK = 'text.dark',
  WHITE = 'white',
  NEUTRAL = 'neutral',
  BORDER = 'border',
  RED = 'red',
  YELLOW = 'yellow',
  GREEN = 'green',
}

export type Colors = {
  primary: Color;
  background: Color;
  text: Color;
  [ThemeColorKeys.WHITE]: string;
  [ThemeColorKeys.NEUTRAL]: string;
  [ThemeColorKeys.BORDER]: string;
  [ThemeColorKeys.RED]: string;
  [ThemeColorKeys.YELLOW]: string;
  [ThemeColorKeys.GREEN]: string;
};

type FontSizes = {
  xxxs: string;
  xxs: string;
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
  xxxl: string;
};

type FontFamilies = {
  roboto: string;
};

export type Spacings = {
  m: number | string;
  my: number | string;
  mx: number | string;
  mt: number | string;
  mb: number | string;
  mr: number | string;
  ml: number | string;
  p: number | string;
  py: number | string;
  px: number | string;
  pt: number | string;
  pb: number | string;
  pr: number | string;
  pl: number | string;
};

type BorderRadius = {
  sm: string;
  md: string;
  lg: string;
};

type MediaQuery = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
};

export type Theme = {
  colors: Colors;
  background: {
    gradientLightCenter: CSSProp;
  };
  fontSizes: FontSizes;
  fontFamilies: FontFamilies;
  spacing: (...args: number[]) => string;
  borderRadius: BorderRadius;
  awesomegrid: {
    columns: MediaQuery;
    gutterWidth: MediaQuery;
    paddingWidth: MediaQuery;
  };
};
