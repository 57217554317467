import { ActionsMap } from 'rclnodejs';
import ROSLIB, { Service } from 'roslib';

import { LogRosBridge, RosBridgeLogging } from 'store/logger';

import { DeviceNameServices, RosActionName, RosServiceName } from 'hooks/ros';
import { useRosbridgeHub } from 'hooks/signalR/useRosbridgeHub';

import { logRed } from 'utils/debugger';

import Action from './Action';

type TSendToRosBridge = ReturnType<typeof useRosbridgeHub>['RosbridgeHubClient']['sendToRosbridge'];

/**
 * Note: When adding roslibjs support the package is not completely updated to ros2 yet.
 * Maybe choose
 *
 * Check the source to see what methods exist https://github.com/RobotWebTools/roslibjs/blob/develop/src/core/Ros.js
 */
export class RosSignalR extends ROSLIB.Ros {
  _sendToRosbridge: TSendToRosBridge;
  /**
   * This overwrites the parent idCounter cause that is starting with 0 and we have to make sure that this is unique
   */
  idCounter: number;
  private readonly logRosBridge: LogRosBridge;

  constructor(sendToRosbridge: TSendToRosBridge, logRosBridge: LogRosBridge) {
    /**
     * Adding the required url would trigger the connect function in the parent class - we don't want this
     */
    super({});

    this._sendToRosbridge = sendToRosbridge;
    this.logRosBridge = logRosBridge;

    this.idCounter = Math.floor(Math.random() * 1000 * 1000);
  }

  /**
   * This overwrite the real function witch get called to send everything over sockets.
   * We do this via signalR by design.
   * @param message
   */
  callOnConnection(message: Object) {
    return this._sendToRosbridge(message);
  }

  /**
   * Move this completely to ros class
   * @param name
   * @param request
   */
  callRosService<TServiceRequest, TServiceResponse>(
    name: RosServiceName | DeviceNameServices,
    request?: TServiceRequest,
  ) {
    const serviceClient = new Service<TServiceRequest, TServiceResponse>({
      ros: this,
      name,
      /**
       * Types currently will be replaced on the server
       */
      serviceType: name,
    });

    return this.callServicePromise(serviceClient, request);
  }

  callServicePromise<TServiceRequest, TServiceResponse>(
    serviceClient: ROSLIB.Service<TServiceRequest, TServiceResponse>,
    request: TServiceRequest,
  ): Promise<TServiceResponse> {
    return new Promise((resolve, reject) => {
      this.logRosBridge(
        RosBridgeLogging.SERVICE_REQUEST,
        '🌉 service:request',
        serviceClient.name,
        request,
      );

      serviceClient.callService(
        request,
        (result) => {
          this.logRosBridge(
            RosBridgeLogging.SERVICE_RESPONSE,
            '🌉 service:response',
            serviceClient.name,
            result,
          );

          return resolve(result);
        },
        (message) => reject(message),
      );
    });
  }

  callAction<TActionGoal, TActionFeedback, TActionResult>(
    name: RosActionName,
    actionType: keyof ActionsMap,
    goal: TActionGoal,
    feedbackCallback?: (feedback: TActionFeedback) => void,
  ) {
    const actionClient = new Action({
      ros: this,
      name,
      actionType,
    });

    return new Promise<TActionResult>((resolve, reject) => {
      this.logRosBridge(RosBridgeLogging.ACTION_GOAL, '🎯 action:goal', name, goal);

      actionClient.sendGoal<TActionGoal, TActionFeedback, TActionResult>(
        goal,
        (feedback) => {
          this.logRosBridge(RosBridgeLogging.ACTION_FEEDBACK, '🎯 action:feedback', name, feedback);

          feedbackCallback && feedbackCallback(feedback);
        },
        (result) => {
          this.logRosBridge(RosBridgeLogging.ACTION_RESULT, '🎯 action:result', name, result);

          return resolve(result);
        },
        (message) => {
          logRed('Error for action on ', name, message);
          return reject(message);
        },
      );
    });
  }

  /**
   * Copy & pasted from https://github.com/RobotWebTools/roslibjs/blob/develop/src/core/SocketAdapter.js#L33
   * Note: these operation are more than we we want to use currently.
   * These also include publish/status for Ros-Topics and they are also have these witch are
   * used by the robot (like 'call_service')
   */
  handleReceiveMessage(message: any) {
    // console.debug('debug:handleReceiveMessage', message);
    if (message.op === 'publish') {
      this.emit(message.topic, message.msg);
    } else if (message.op === 'service_response') {
      this.emit(message.id, message);
    } else if (message.op === 'call_service') {
      this.emit(message.service, message);
    } else if (message.op === 'send_action_goal') {
      this.emit(message.action, message);
    } else if (message.op === 'cancel_action_goal') {
      this.emit(message.id, message);
    } else if (message.op === 'action_feedback') {
      this.emit(message.id, message);
    } else if (message.op === 'action_result') {
      this.emit(message.id, message);
    } else if (message.op === 'status') {
      if (message.id) {
        this.emit('status:' + message.id, message);
      } else {
        this.emit('status', message);
      }
    }
  }
}
