import { ROOT_ROUTES } from 'pages/routes-names';
import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import EmptyContent from 'components/common/empty-content/EmptyContent';
import { SBox, SButton } from 'components/ui';

import RoleTypes, { UNAUTHORIZED_MESSAGE } from 'lib/constants/role-types';

import { useRole } from './index';

type RoleWrapperProps = {
  role: RoleTypes;
} & PropsWithChildren;

const RoleWrapper: FC<RoleWrapperProps> = ({ role, children = null }) => {
  const { checkRole } = useRole();
  const isAllowed = checkRole(role);

  if (isAllowed) return children;

  return (
    <SBox
      display="flex"
      align="center"
      justify="center"
      w="100%"
      h="100%"
      style={{
        textAlign: 'center',
      }}
    >
      <EmptyContent
        title="Access Denied."
        // description="You dont have role to view this page!"
        description={UNAUTHORIZED_MESSAGE}
      >
        {role !== RoleTypes.BASIC && (
          <Link to={`/${ROOT_ROUTES.HOME.path}`}>
            <SButton mt={4} type="button" autoFocus>
              Home Page
            </SButton>
          </Link>
        )}
      </EmptyContent>
    </SBox>
  );
};

function withRole(role: RoleTypes) {
  return (Component: FC) => (props: Record<string, unknown>) =>
    (
      <RoleWrapper role={role}>
        <Component {...props} />
      </RoleWrapper>
    );
}

export default withRole;
