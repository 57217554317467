import { DefaultValue, atom, selector } from 'recoil';

import { axiosRequest } from 'lib/api/axios';
import { getRobotActionTemplatesConfig } from 'lib/api/urls';

import {
  MyRobotDTO,
  MyRobotsPrimaryInformationResponseDTO,
  RobotInfo,
  RobotStatus,
} from 'types/models/Robot';
import { RobotActionTemplateDTO } from 'types/models/RobotAction';

import { deviceNameSelector } from './myRobot.selectors';

/**
 * TBD: I don't like the fact that some basic information are potential null/undefined (not already fetched) with cause a lot of null checks
 *  and some more complex return handlers. SignalR or Janus require some basic information's to work properly.
 *  Every component which use them is inside a nested route and the data is always there.
 *  Therefor the architecture of the app provides a guaranty that data is always existing when accessing it
 *
 * Todo: add type after merge
 * TODO: refactor to recoil async loading when refresh is stable
 */
export const myRobotAtom = atom<{
  data?: MyRobotDTO;
  isFetched: boolean;
  error?: any;
}>({
  key: 'myRobot',
  default: {
    data: undefined,
    error: undefined,
    isFetched: false,
  },
});

export const myRobotPrimaryInformationAtom = atom<{
  data?: MyRobotsPrimaryInformationResponseDTO;
  isFetched: boolean;
  error?: any;
}>({
  key: 'myRobotPrimaryInformation',
  default: {
    data: undefined,
    isFetched: false,
    error: undefined,
  },
});

/**
 * Received via myRobotPrimaryInformationAtom or signal R
 */
export const robotStatusSelector = selector<RobotStatus | undefined>({
  key: 'robotStatusSelector',
  get: ({ get }) => {
    const myRobotPrimaryInformation = get(myRobotPrimaryInformationAtom);
    return myRobotPrimaryInformation.data?.robotInfo.robotStatus;
  },
  set: ({ set, get }, newValue) => {
    const state = get(myRobotPrimaryInformationAtom);
    // todo - not sure if this is the right way to handle this
    if (newValue instanceof DefaultValue) return set(myRobotPrimaryInformationAtom, newValue);
    set(myRobotPrimaryInformationAtom, {
      ...state,
      data: {
        ...(state.data as MyRobotsPrimaryInformationResponseDTO),
        robotInfo: {
          ...(state.data?.robotInfo as RobotInfo),
          robotStatus: newValue as RobotStatus,
        },
      },
    });
  },
});

export const myRobotActionTemplatesAtom = atom<RobotActionTemplateDTO[]>({
  key: 'myRobotActionTemplatesAtom',
  default: selector({
    key: 'myRobotActionTemplatesAtomSelector',
    get: async ({ get }) => {
      const deviceName = get(deviceNameSelector);
      const { method, url, params } = getRobotActionTemplatesConfig(deviceName);
      const { data } = await axiosRequest<RobotActionTemplateDTO[]>(method, url, { params });
      return data;
    },
  }),
});
