import { FC, ReactNode } from 'react';

import { SBox, STypography } from 'components/ui';

/**
 * Empty Content component
 * @param {String} title - Title text for empty section
 * @param {String} description - Description text for empty section
 * @param {Node} children - Any node
 */

type EmptyContent = {
  title?: string;
  description?: string;
  children?: ReactNode;
};

const EmptyContent: FC<EmptyContent> = ({ title, description, children }) => (
  <SBox w="100%" display="flex" direction="column" align="column" justify="column" py={4} px={10}>
    {title && (
      <STypography as="h4" variant="h6" bottomSpace>
        {title}
      </STypography>
    )}
    {description && (
      <STypography variant="subtitle2" whiteSpace="pre-line" bottomSpace>
        {description}
      </STypography>
    )}
    {children && children}
  </SBox>
);

export default EmptyContent;
