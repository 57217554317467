import styled from 'styled-components';

export const SCollapsibleContainer = styled.div`
  .Collapsible {
    display: block;
    box-sizing: border-box;
    width: 100% !important;
    border-radius: ${(props) => props.theme.borderRadius.md};
    box-shadow: 0 0 0 2px ${(props) => props.theme.colors.primary.main} inset;
    background-color: ${(props) => props.theme.colors.background.main};

    &__trigger {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      cursor: pointer;
      width: 100%;
      padding: ${(props) => props.theme.spacing(1.2, 3)};
      transition: all 0.3s;
      box-shadow: 0 0 0 2px ${(props) => props.theme.colors.primary.main} inset;
      border-radius: ${(props) => props.theme.borderRadius.md};

      svg {
        transition: all 0.3s;
      }

      &.is-open {
        svg {
          transform: rotate(90deg);
        }
      }
    }

    &__contentOuter {
      //position: absolute;
    }

    &__contentInner {
      padding: ${(props) => props.theme.spacing(3, 6, 4, 6)};
      max-height: 50vh;
      overflow: auto;
    }
  }
`;
