import { useRecoilValue } from 'recoil';

import { TGamepadButton, XboxOneGamepadButton } from 'types/models/gamepad/GamepadButtons';

import { gamepadButtonPressedSelector, gamepadButtonValueSelector } from './selectors';

/**
 * This helper hook returns the current pressed state of the gamepad button
 *
 * @param buttonKey
 */
export function useGamepadXboxButtonPressed(buttonKey: XboxOneGamepadButton): boolean {
  const pressed = useRecoilValue(gamepadButtonPressedSelector(buttonKey));
  console.log(`button:${buttonKey}:pressed`, pressed);

  return pressed;
}

/**
 * This helper hook returns the current value of the gamepad button
 *
 * @param buttonKey
 */
export function useGamepadButtonValue(buttonKey: TGamepadButton): number {
  const value = useRecoilValue(gamepadButtonValueSelector(buttonKey));
  console.log(`button:${buttonKey}:pressed`, value);

  return value;
}
