import { type JSX } from 'react';
import { QrReader } from 'react-qr-reader';

type UseQRReaderResult = {
  qrReaderCameraWindow: JSX.Element | null;
};

const useQRReader = (
  disabled: boolean,
  onQRDetect: (result?: string) => void,
): UseQRReaderResult => {
  const onResult = (result: any, err: any) => {
    if (!!result) {
      onQRDetect(result?.text);
    }

    if (!!err) {
      // console.info(err);
    }
  };

  const qrReaderCameraWindow = disabled ? null : (
    <QrReader
      onResult={onResult}
      constraints={{
        facingMode: 'environment',
      }}
      // @ts-ignore
      style={{ width: '100%' }}
    />
  );

  return {
    qrReaderCameraWindow,
  };
};

export default useQRReader;
