import { HTMLAttributes } from 'react';

import { STypography } from 'components/ui';

import { SCheckbox, SCheckboxIcon, SHiddenCheckbox, SLabel } from './styled';

type Props = HTMLAttributes<HTMLDivElement> & {
  checked: boolean;
  label: string;
};

const Checkbox = ({ checked, label, ...rest }: Props) => (
  <SLabel>
    <SHiddenCheckbox checked={checked} {...rest} />
    <SCheckbox checked={checked}>
      <SCheckboxIcon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </SCheckboxIcon>
    </SCheckbox>
    <STypography variant="caption">{label}</STypography>
  </SLabel>
);

export default Checkbox;
