import { get } from 'lodash-es';
import styled from 'styled-components';

import { generateSpacings } from 'components/ui';
import { theme } from 'components/ui/theme/Theme';

import { Spacings, ThemeColorKeys } from 'types/ui/Theme';

type SIconProps = {
  color?: `${ThemeColorKeys}`;
} & Partial<Spacings>;

export const SIcon = styled.svg.withConfig({
  shouldForwardProp: (prop) => !['transform'].includes(prop),
})<SIconProps>`
  color: ${(props) =>
    props.color ? get(props.theme.colors, props.color) : props.theme.colors.text.main};
  transform: ${(props) => props.transform && props.transform};
  ${(props) => generateSpacings(props)}
`;

export const SIconWrapper = styled.div`
  position: relative;
`;

type SIconBadgeProps = {
  backgroundColor?: string;
};

export const SIconBadge = styled.div<SIconBadgeProps>`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 60%;
  height: 60%;
  border-radius: 50%;
  background-color: ${(props) => props?.backgroundColor ?? theme.colors[ThemeColorKeys.YELLOW]};
  padding: 1px;

  & > svg {
    width: 100%;
    height: 100%;
    position: relative;
    top: -1px;
  }
`;
