import { uniq } from 'lodash-es';

import { arrayContainsAll } from 'utils/helpers/array';

import {
  GamepadAxisPreProcessed,
  GamepadCombosPreProcessed,
  GamepadKeysPreProcessed,
  GamepadMapper,
  GamepadMapperActions,
  GamepadMapperPreprocessed,
} from 'types/models/gamepad/GamepadMapper';

import { getComboKey } from '../handlers';
import { DEFAULT_MAPPER } from './defaultMappers';

export const mapActions = (preprocessed: GamepadMapperPreprocessed): GamepadMapperActions => {
  const keyActions = preprocessed.keys.reduce<GamepadMapperActions>((acc, { key, action }) => {
    acc[key] = action;
    return acc;
  }, {});
  const comboActions = preprocessed.combos.reduce<GamepadMapperActions>((acc, { keys, action }) => {
    const key = getComboKey(keys);
    acc[key] = action;
    return acc;
  }, {});
  const axesActions = preprocessed.axes.reduce<GamepadMapperActions>((acc, { axis, action }) => {
    acc[axis] = action;
    return acc;
  }, {});
  return { ...keyActions, ...comboActions, ...axesActions };
};

export const mapFromPreprocessedToMapper = (
  preprocessed: GamepadMapperPreprocessed,
): GamepadMapper => {
  const allKeys = [...DEFAULT_MAPPER.keys, ...preprocessed.keys];
  const allCombos = [...DEFAULT_MAPPER.combos, ...preprocessed.combos];
  allCombos.sort((keys1, keys2) => keys2.keys.length - keys1.keys.length);
  const allAxes = [...DEFAULT_MAPPER.axes, ...preprocessed.axes];
  const allUsedKeys = uniq([
    ...allKeys.map(({ key }) => key),
    ...allCombos.map(({ keys }) => keys).flat(),
    ...allAxes.map(({ axis }) => axis),
  ]);
  const allUsedCombos = allCombos.map(({ keys }) => keys);
  const preprocessedWithDefault = {
    keys: allKeys,
    combos: allCombos,
    axes: allAxes,
  };

  return {
    keys: allUsedKeys,
    combos: allUsedCombos,
    actions: mapActions(preprocessedWithDefault),
  };
};

export const modifyPreprocessedMapperByKey = (
  mapper: GamepadMapperPreprocessed,
  modifications: GamepadKeysPreProcessed,
) => {
  const newMapper = { ...mapper };

  modifications.forEach((modification) => {
    const index = newMapper.keys.findIndex(({ key }) => key === modification.key);
    if (index === -1) {
      newMapper.keys.push(modification);
    } else {
      newMapper.keys[index] = modification;
    }
  });

  return newMapper;
};

export const modifyPreprocessedMapperByAxis = (
  mapper: GamepadMapperPreprocessed,
  modifications: GamepadAxisPreProcessed,
) => {
  const newMapper = { ...mapper };

  modifications.forEach((modification) => {
    const index = newMapper.axes.findIndex(({ axis }) => axis === modification.axis);
    if (index === -1) {
      newMapper.axes.push(modification);
    } else {
      newMapper.axes[index] = modification;
    }
  });

  return newMapper;
};

export const modifyPreprocessedMapperByCombo = (
  mapper: GamepadMapperPreprocessed,
  modifications: GamepadCombosPreProcessed,
) => {
  const newMapper = { ...mapper };

  modifications.forEach((modification) => {
    const index = newMapper.combos.findIndex(({ keys }) =>
      arrayContainsAll(keys, modification.keys),
    );
    if (index === -1) {
      newMapper.combos.push(modification);
    } else {
      newMapper.combos[index] = modification;
    }
  });

  return newMapper;
};
