import { MoonIcon, SunIcon } from '@radix-ui/react-icons';

import { Button } from 'components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';

import { ReplyLogo } from '../complex/sidebar/Sidebar';
import { useTheme } from '../ui/theme/dm-theme-provider';
import { SHeader } from './styled';

export function ModeToggle() {
  const { setTheme } = useTheme();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="w-9 px-0">
          <SunIcon className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
          <MoonIcon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => setTheme('light')}>Light</DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme('dark')}>Dark</DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme('system')}>System</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function Logo() {
  return (
    <div className="-mt-1">
      <ReplyLogo />
    </div>
  );
}

export function Navbar() {
  return (
    <SHeader className="relative z-50 flex h-14 items-center justify-between bg-secondary px-4">
      <nav className="flex h-full">
        <Logo />
      </nav>
      <nav className="flex h-full items-center space-x-3">
        <ModeToggle />
      </nav>
    </SHeader>
  );
}

export default Navbar;
