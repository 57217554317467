import { atom, selectorFamily, useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';

import { LOCAL_STORAGE } from 'lib/constants/local-storage';

import { DebugMessageColor, colorOption, locColor } from 'utils/debugger';

import { ROBOT_ROS_TOPICS, RobotRosTopic } from '../my-robot/relay-topics';

type DebugSignalRRelayStateEntry = {
  log: boolean;
  color: string;
};

type DebugSignalRRelayState = Record<RobotRosTopic, DebugSignalRRelayStateEntry>;

const getLogSignalRRelayRosTopicsDefaultState = (): DebugSignalRRelayState => {
  const stored = localStorage.getItem(LOCAL_STORAGE.DEBUG.SIGNAL_R_RELAY);
  const state = stored ? JSON.parse(stored) : {};

  ROBOT_ROS_TOPICS.forEach((topic) => {
    if (!state[topic]) {
      state[topic] = {
        log: false,
        color: colorOption[DebugMessageColor.BLUE],
      };
    }
  });

  return state;
};

const logSignalRRelayRosTopicsAtom = atom({
  key: 'logSignalRRelayRosTopicsAtom',
  default: getLogSignalRRelayRosTopicsDefaultState(),
  effects: [
    ({ onSet }) => {
      onSet((state) => {
        localStorage.setItem(LOCAL_STORAGE.DEBUG.SIGNAL_R_RELAY, JSON.stringify(state));
      });
    },
  ],
});

const logSignalRRelayRosTopicsSelector = selectorFamily<DebugSignalRRelayStateEntry, RobotRosTopic>(
  {
    key: 'logSignalRRelayRosTopicsSelector',
    get:
      (topic) =>
      ({ get }) =>
        get(logSignalRRelayRosTopicsAtom)[topic],
  },
);

export function useLogSignalRRelaySetter(key: RobotRosTopic) {
  const { color, log } = useRecoilValue(logSignalRRelayRosTopicsSelector(key));
  const setDebugState = useSetRecoilState(logSignalRRelayRosTopicsAtom);

  const updateLog = (log: boolean) =>
    setDebugState((old) => {
      const newItem = {
        ...old[key],
        log,
      };
      return {
        ...old,
        [key]: newItem,
      };
    });

  const updateColor = (color: string) => {
    setDebugState((old) => {
      const newItem = {
        ...old[key],
        color,
      };
      return {
        ...old,
        [key]: newItem,
      };
    });
  };

  return { color, log, updateLog, updateColor } as const;
}

/**
 * Returns a callback to read the state without subscribing to it.
 */
function useDebugSignalRRelayCallback() {
  const getDebugSignalRRelay = useRecoilCallback(
    ({ snapshot }) =>
      (key: RobotRosTopic): DebugSignalRRelayStateEntry | undefined =>
        snapshot.getLoadable(logSignalRRelayRosTopicsSelector(key))?.contents,
    [],
  );

  return { getDebugSignalRRelay };
}

/**
 * Returns a callback to read the state without subscribing to it.
 */
export function useLogSignalRRelay() {
  const { getDebugSignalRRelay } = useDebugSignalRRelayCallback();

  function logSignalRRelay(topic: RobotRosTopic, text: string, ...rest: unknown[]) {
    const debug = getDebugSignalRRelay(topic);
    if (debug?.log) {
      locColor(debug.color, text, ...rest);
    }
  }

  return { logSignalRRelay };
}
