import { PowerState_MotorPowerState } from 'lib/generated/proto';

export type SpotFeedbackPayload = {
  standing: boolean;
  sitting: boolean;
  moving: boolean;
  docked: boolean;
  dock_id: number; // 0 means not docked
  serial_number: string;
  species: string;
  version: string;
  nickname: string;
  computer_serial_number: string;
};

export type SpotClaimPayload = {
  claimed: boolean;
  power_state: PowerState_MotorPowerState;
};

// https://dev.azure.com/RoboverseReply/Robotics%20Platform/_wiki/wikis/Robotics-Platform.wiki/417/Relayed-Robot-ROS-Topics#
export enum RobotRosTopic {
  ROBOT_BATTERY = 'status/battery', // removed
  SPOT_FEEDBACK = 'status/feedback',
  SPOT_MOBILITY_PARAMS = 'status/mobility_params',
  SPOT_CLAIM = 'status/claim_state',
  /**
   * state of the robot's joints  https://dev.bostondynamics.com/protos/bosdyn/api/proto_reference#jointstate
   */
  JOINT_STATES = 'joint_states',
  /**
   * General Arm state
   * https://dev.bostondynamics.com/protos/bosdyn/api/proto_reference#manipulatorstate
   */
  MANIPULATION_STATE = 'manipulation_state', // todo - rename to SPOT_?
  // a direct method call defines these also - but these are ros_spot messages
  ROBOT_COMMAND_FEEDBACK = 'robot_command_feedback_stripped',
  ROBOT_COMMAND_RESULT = 'robot_command_result_stripped',
  DOOR_COMMAND_FEEDBACK = 'door_command_feedback_stripped',
  DOOR_COMMAND_RESULT = 'door_command_result_stripped',
  PICK_UP_OBJECT_FEEDBACK = 'pick_up_object_feedback',
  PICK_UP_OBJECT_RESULT = 'pick_up_object_result',
  OPEN_DOOR_FROM_IMAGE_FEEDBACK = 'open_door_from_image_feedback',
  OPEN_DOOR_FROM_IMAGE_RESULT = 'open_door_from_image_result',
  // https://dev.azure.com/RoboverseReply/Robotics%20Platform/_wiki/wikis/Robotics-Platform.wiki/540/Spot-NCB-RMS-actions-message-formats
  // TODO - not used now, just for not showing errors
  NCB_AVAILABLE_SERVICES = 'ncb/available_services',
  RMS_AVAILABLE_SERVICES = 'rms/available_services',
  FIDUCIALS = 'fiducials',
  CURRENT_LOCATION = 'current_location',
}

export const ROBOT_ROS_TOPICS = Object.values(RobotRosTopic);

export type RobotRelayJson = {
  deviceName: string;
  topic: RobotRosTopic;
  payload: string | any; // parsed on receiving for logging messages
  id: string;
};
