import { PropsWithChildren, memo } from 'react';

import { SDropdownSection } from './Dropdown.styled';

/**
 * Dropdown list section component
 * @param {Array.<DropdownSection, DropdownItem, DropdownDivider>} children - List of allowed items
 */

const DropdownSection = memo<PropsWithChildren>(({ children }) => (
  <SDropdownSection>
    <ul>{children}</ul>
  </SDropdownSection>
));

export default DropdownSection;
