import { useState } from 'react';
import { toast } from 'react-toastify';

import { useDeviceName } from 'store/my-robot';

import { gamepadToast } from 'hooks/complex/gamepad/util/handlers';

import {
  changeVideoCamModuleVideoSource,
  closeAllVideoCamModuleChannels,
} from 'lib/robot-modules/videoCamModule';

import { logRed } from 'utils/debugger';
import { RobotStream } from 'utils/janus-react-components/helper';

import { CameraSource, DefaultCameraSource } from 'types/ui/Camera';

import { useCameraSources } from '../../lib/api/manuel-control';
import { useSelectedCameraSource } from './setters';

let cameraSourceToastId: number | string | null = null;

const clearCameraSourceToast = () => {
  if (cameraSourceToastId) {
    toast.dismiss(cameraSourceToastId);
    cameraSourceToastId = null;
  }
};

const updateCameraSourceToastSuccess = (source: string) => {
  if (cameraSourceToastId) {
    toast.update(cameraSourceToastId, {
      render: `Camera source changed to ${source}`,
      type: 'success',
      isLoading: false,
      autoClose: 500,
    });
  }
};

const triggerCameraSourceToast = async (
  cameraSource: CameraSource,
  action: (source: CameraSource) => Promise<void>,
) => {
  clearCameraSourceToast();
  cameraSourceToastId = gamepadToast('info', `Changing Camera source to ${cameraSource.label}`, {
    isLoading: true,
    autoClose: 500,
  });
  await action(cameraSource);
  updateCameraSourceToastSuccess(cameraSource.label);
};

export const useRobotControlCameraSource = () => {
  const [cameraSourceLoading, setCameraSourceLoading] = useState(false);
  const { deviceName } = useDeviceName();
  const { cameraSources } = useCameraSources();
  const [currentCameraSource, setRobotCameraSource] = useSelectedCameraSource();

  const updateCameraSource = async (newSource: CameraSource) => {
    // todo - off should be able to resend again
    if (
      currentCameraSource.value === newSource.value &&
      newSource.value !== DefaultCameraSource.OFF
    ) {
      return;
    }
    try {
      setCameraSourceLoading(true);
      if (newSource.value === DefaultCameraSource.OFF) {
        await closeAllVideoCamModuleChannels({ iotHubDeviceName: deviceName });
      } else {
        await changeVideoCamModuleVideoSource({
          iotHubDeviceName: deviceName,
          channelName: RobotStream.PRIMARY_STREAM,
          streamSource: newSource.value,
          oldStreamSource: currentCameraSource.value,
        });
      }
      setRobotCameraSource(newSource);
    } catch (e) {
      logRed('useRobotControlCameraSource:updateCameraSource:error', e);
      toast.error(`Failed to update camera source: ${e.message}`);
    } finally {
      setCameraSourceLoading(false);
    }
  };

  const findCurrentSourceIndex = () => {
    const index = cameraSources.findIndex((s) => s.value === currentCameraSource.value);
    return { currentSourceIndex: index, lastItemIndex: cameraSources.length - 1 };
  };

  const selectNextCameraSource = async () => {
    const { currentSourceIndex, lastItemIndex } = findCurrentSourceIndex();
    let source;
    if (currentSourceIndex === lastItemIndex) {
      source = cameraSources[0];
    } else {
      source = cameraSources[currentSourceIndex + 1];
    }
    await triggerCameraSourceToast(source, updateCameraSource);
  };

  const selectPreviousCameraSource = async () => {
    const { currentSourceIndex, lastItemIndex } = findCurrentSourceIndex();
    let source;
    if (currentSourceIndex === 0) {
      source = cameraSources[lastItemIndex];
    } else {
      source = cameraSources[currentSourceIndex - 1];
    }
    await triggerCameraSourceToast(source, updateCameraSource);
  };

  const selectSourceByValue = async (value: string) => {
    const source = cameraSources?.find((source) => source.value === value);
    if (source) {
      await triggerCameraSourceToast(source, updateCameraSource);
    } else {
      toast.error(`This spot doesn't have ${value} camera source.`);
    }
  };

  const selectSpotCamStream = () => selectSourceByValue(DefaultCameraSource.SpotCamStream);
  const selectGripperCamStream = () => selectSourceByValue(DefaultCameraSource.GripperCam);

  return {
    cameraSourceLoading,
    currentCameraSource,
    cameraSources,
    updateCameraSource,
    selectNextCameraSource,
    selectPreviousCameraSource,
    selectSourceByValue,
    selectSpotCamStream,
    selectGripperCamStream,
  };
};
