import { selector } from 'recoil';

import { deviceNameSelector } from 'store/my-robot';

import { NamedArmPositionsCommand_Feedback_Status } from 'lib/generated/proto/bosdyn/api/arm_command';
import {
  ArmDragCommand_Feedback_Status,
  RobotCommandFeedbackStatus_Status,
} from 'lib/generated/proto/bosdyn/api/basic_command';
import { ClawGripperCommand_Feedback_Status } from 'lib/generated/proto/bosdyn/api/gripper_command';

import { robotCommandFeedbackAtom } from './atoms';

const robotCommandFeedbackSelector = selector({
  key: 'robotCommandFeedbackSelector',
  get: ({ get }) => {
    const deviceName = get(deviceNameSelector);
    const { synchronizedFeedback, fullBodyFeedback } = get(robotCommandFeedbackAtom(deviceName));
    return { synchronizedFeedback, fullBodyFeedback };
  },
});

const robotCommandArmCommandFeedbackSelector = selector({
  key: 'robotCommandArmCommandFeedbackSelector',
  get: ({ get }) => {
    const { synchronizedFeedback } = get(robotCommandFeedbackSelector);
    const armCommandFeedback = synchronizedFeedback?.armCommandFeedback;
    const gripperCommandFeedback = synchronizedFeedback?.gripperCommandFeedback;
    return { armCommandFeedback, gripperCommandFeedback };
  },
});

export const armCommandFeedbackStatusSelector = selector({
  key: 'armCommandFeedbackStatusSelector',
  get: ({ get }) => {
    const { armCommandFeedback } = get(robotCommandArmCommandFeedbackSelector);

    const armCommandStatus =
      armCommandFeedback?.status || RobotCommandFeedbackStatus_Status.STATUS_UNKNOWN;

    return { armCommandStatus };
  },
});

/**
 * https://dev.bostondynamics.com/protos/bosdyn/api/proto_reference#namedarmpositionscommand-feedback-status
 */
export const namedArmPositionCommandFeedbackStatusSelector = selector({
  key: 'namedArmPositionCommandFeedbackStatusSelector',
  get: ({ get }) => {
    const { armCommandFeedback } = get(robotCommandArmCommandFeedbackSelector);

    const namedArmPositionCommandFeedbackStatus =
      armCommandFeedback?.namedArmPositionFeedback?.status ||
      NamedArmPositionsCommand_Feedback_Status.STATUS_UNKNOWN;

    return { namedArmPositionCommandFeedbackStatus };
  },
});

export const gripperCommandFeedbackStatusSelector = selector({
  key: 'gripperCommandFeedbackStatusSelector',
  get: ({ get }) => {
    const { gripperCommandFeedback } = get(robotCommandArmCommandFeedbackSelector);

    const gripperCommandStatus =
      gripperCommandFeedback?.status || RobotCommandFeedbackStatus_Status.STATUS_UNKNOWN;

    return { gripperCommandStatus };
  },
});

export const clawGripperCommandFeedbackStatusSelector = selector({
  key: 'clawGripperCommandFeedbackStatusSelector',
  get: ({ get }) => {
    const { gripperCommandFeedback } = get(robotCommandArmCommandFeedbackSelector);

    const clawGripperCommandFeedbackStatus =
      gripperCommandFeedback?.clawGripperFeedback?.status ||
      ClawGripperCommand_Feedback_Status.STATUS_UNKNOWN;

    return { clawGripperCommandFeedbackStatus };
  },
});

export const armDragFeedbackStatusSelector = selector({
  key: 'armDragFeedbackStatusSelector',
  get: ({ get }) => {
    const { armCommandFeedback } = get(robotCommandArmCommandFeedbackSelector);

    const armDragFeedbackStatus =
      armCommandFeedback?.armDragFeedback?.status || ArmDragCommand_Feedback_Status.STATUS_UNKNOWN;

    return { armDragFeedbackStatus };
  },
});
