import { CSSProperties, PropsWithChildren, memo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { SDropdownItem } from './Dropdown.styled';

import DropdownContext from './DropdownContext';

/**
 * Dropdown item component (use to / onClick)
 * @param {String} label - Item label
 * @param {String} to - Item url
 * @param {Function} onClick - Item onClick function
 * @param {String} style - Item style object
 * @param {Element} children - Item inner content
 */

type DropdownItemProps = {
  label?: string;
  to?: string;
  onClick?: () => void;
  style?: CSSProperties;
};

const DropdownItem = memo<DropdownItemProps & PropsWithChildren>(
  ({ label = '', to, onClick, style, children }) => {
    const navigate = useNavigate();
    const handleToggle = useContext(DropdownContext);

    const handleClick = () => {
      if (to) {
        // history.push(to);
        navigate(to);
      } else if (onClick) {
        onClick();
      }
      handleToggle();
    };

    return (
      <SDropdownItem style={style} isClickable={!!(to || onClick)} onClick={handleClick}>
        {children || label}
      </SDropdownItem>
    );
  },
);

export default DropdownItem;
