import { HttpStatusCode } from 'axios';
import { isArray } from 'lodash-es';

export default class NetworkAxiosError {
  title: string;
  message: string;
  status: HttpStatusCode;

  constructor(err: any) {
    this.title = 'Unknown error';
    this.message = 'Something went wrong...\nPlease, try again later!';
    this.status = 404;
    console.error('Error: ', err);

    if (err.response) {
      const { status, statusText, data } = err.response;
      if (isArray(data)) {
        const { errorMessage, errorStatusCode } = data?.[0];
        this.title = `${errorMessage}. ${errorStatusCode ? `(Code ${errorStatusCode})` : ''}`;
        this.message = `Details: ${errorMessage}`;
        this.status = errorStatusCode;
      } else {
        this.title = `${statusText}. ${status ? `(Code ${status})` : ''}`;
        this.message = `Details: ${data}`;
        this.status = status;
      }
    } else {
      const { description, message, name, status } = err.toJSON();
      this.title = `${name}. ${status ? `(Status: ${status})` : ''}`;
      this.message = `Details: ${message}`;
      this.status = status;

      if (description) {
        this.message += `\n${description}`;
      }
    }
  }
}
