import { atomFamily } from 'recoil';

import { ManipulatorState } from 'lib/generated/proto/bosdyn/api/robot_state';

import { DeviceName } from 'types/models';

/**
 * Additional state published if an arm is attached to the robot.
 *
 * Reflects the state coming from the robot.
 * Note: currently not all information we receive are stored related to missing parser
 * We use the concept of Proto but what we receive are ros-messages
 * */
export const manipulatorStateAtom = atomFamily<ManipulatorState, DeviceName>({
  key: 'manipulatorStateAtom',
  default: ManipulatorState.create(),
});
