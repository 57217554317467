import { spot_monitor_interfaces } from 'rclnodejs';
import { atomFamily } from 'recoil';

import { DeviceName } from 'types/models';

export type BatteryStateWithDuration = spot_monitor_interfaces.msg.BatteryStateWithDuration;

export const batteryStatusAtom = atomFamily<BatteryStateWithDuration, DeviceName>({
  key: 'batteryStatusAtom',
  default: undefined,
});
