import { type JSX, ReactElement, RefObject, useEffect, useRef } from 'react';

import { useEventListener } from 'hooks/index';

import { getPixelRatio } from 'utils/helpers';

type UseCanvasProps = {
  fixedWidth?: number;
  fixedHeight?: number;
};

type UseCanvasResult = {
  canvasRef: RefObject<HTMLCanvasElement | null> | null;
  CanvasElement: JSX.Element | null;
};

const useCanvas = ({ fixedWidth, fixedHeight }: UseCanvasProps = {}): UseCanvasResult => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const createHiDPICanvas = (canvas: HTMLCanvasElement, w: number, h: number, ratio?: number) => {
    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
    if (ctx) {
      if (!ratio) {
        ratio = getPixelRatio(ctx);
      }
      canvas.width = w * ratio;
      canvas.height = h * ratio;
      canvas.style.width = w + 'px';
      canvas.style.height = h + 'px';
      ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
    }
  };

  const prepareCanvas = () => {
    const canvas = canvasRef.current;

    if (canvas) {
      if (fixedWidth !== undefined && fixedHeight !== undefined) {
        createHiDPICanvas(canvas, fixedWidth, fixedHeight);
      } else {
        const parentElement = canvasRef.current.parentElement;

        if (parentElement) {
          const { width, height } = parentElement.getBoundingClientRect();
          createHiDPICanvas(canvas, width, height);
        }
      }
    }
  };

  useEffect(() => {
    prepareCanvas();
  }, [canvasRef.current, fixedWidth, fixedHeight]);

  useEventListener('resize', prepareCanvas);

  const CanvasElement: ReactElement<any> = <canvas ref={canvasRef} />;

  return {
    canvasRef,
    CanvasElement,
  };
};

export default useCanvas;
