import { useRecoilValue, useSetRecoilState } from 'recoil';

import { gamepadInstructionOpenedAtom } from './gamepad.atom';

export function useGamepadInstructionOpenToggle() {
  const setInstructionsOpened = useSetRecoilState(gamepadInstructionOpenedAtom);

  const toggleGamepadInstructionsOpen = () => {
    setInstructionsOpened((prev) => !prev);
  };

  return { toggleGamepadInstructionsOpen };
}

export function useGamepadInstructionOpen() {
  return useRecoilValue(gamepadInstructionOpenedAtom);
}
