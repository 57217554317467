import { atom, selector } from 'recoil';

import { deviceNameSelector } from 'store/my-robot';

import { axiosRequest } from 'lib/api/axios';
import { ManualControlUrls } from 'lib/api/urls';

import { SpotControlData } from 'types/models/Spot';

import { MainOperator, handleGetCurrentMainOperator, parseMainOperatorData } from './handler';

/**
 * Information about the main operator - can be overwritten by SignalR and has a fr
 */
export const mainOperatorAtom = atom<MainOperator>({
  key: 'mainOperatorAtom',
  default: selector<MainOperator>({
    key: 'mainOperatorQuery',
    get: async ({ get }) => {
      const deviceName = get(deviceNameSelector);
      const data = await handleGetCurrentMainOperator(deviceName);
      return parseMainOperatorData(data);
    },
  }),
});

/**
 * Will be manuel be overwritten
 */
export const spotControlDataAtom = atom({
  key: 'spotControlDataAtom',
  default: selector<SpotControlData>({
    key: 'spotControlDataAtomSelector',
    get: async ({ get }) => {
      const deviceName = get(deviceNameSelector);
      const { method, url } = ManualControlUrls.GetSpotControlData;
      const { data } = await axiosRequest<SpotControlData>(method, url(deviceName));
      return data;
    },
  }),
});
