// @ts-nocheck
import { get } from 'lodash-es';
import { darken } from 'polished';
import { css } from 'styled-components';

import { hsLFromCssVar } from 'utils/helpers/hsl-from-css-var';

import { ThemeColorKeys } from 'types/ui/Theme';

export const generateSpacings = ({ theme, ...rest }) => {
  const { m, my, mx, mt, mb, mr, ml, p, py, px, pt, pb, pr, pl } = rest;

  const check = (property, value) =>
    value !== undefined &&
    css`
      ${property}: ${value === 'auto' ? value : `${value * 4}px`};
    `;

  const spacings = (type, a, y, x, t, b, r, l) => css`
    ${check(type, a)};

    ${check(`${type}-top`, y)}
    ${check(`${type}-bottom`, y)}

      ${check(`${type}-right`, x)}
      ${check(`${type}-left`, x)}

      ${check(`${type}-top`, t)};
    ${check(`${type}-bottom`, b)};
    ${check(`${type}-right`, r)};
    ${check(`${type}-left`, l)};
  `;

  return css`
    ${spacings('margin', m, my, mx, mt, mb, mr, ml)}
    ${spacings('padding', p, py, px, pt, pb, pr, pl)}
  `;
};

export const generateTypographyStyles = ({ theme, ...rest }) => {
  const { variant = 'body2' } = rest;

  const variants = {
    h1: css`
      font-size: ${theme.fontSizes.xxxl};
      font-weight: 900;
      line-height: 1.167;
      letter-spacing: -0.01562em;
    `,
    h2: css`
      font-size: ${theme.fontSizes.xxl};
      font-weight: 400;
      line-height: 1.167;
      letter-spacing: 0;
    `,
    h3: css`
      font-size: ${theme.fontSizes.xl};
      font-weight: 400;
      line-height: 1.167;
      letter-spacing: 0;
    `,
    h4: css`
      font-size: ${theme.fontSizes.lg};
      font-weight: 400;
      line-height: 1.235;
      letter-spacing: 0.00735em;
    `,
    h5: css`
      font-size: ${theme.fontSizes.md};
      font-weight: 500;
      line-height: 1.334;
      letter-spacing: 0;
    `,
    h6: css`
      font-size: ${theme.fontSizes.sm};
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 0.0075em;
    `,
    subtitle1: css`
      font-size: ${theme.fontSizes.xs};
      font-weight: 400;
      line-height: 1.75;
      letter-spacing: 0.00938em;
    `,
    subtitle2: css`
      font-size: ${theme.fontSizes.xxs};
      font-weight: 500;
      line-height: 1.57;
      letter-spacing: 0.00714em;
    `,
    body1: css`
      font-size: ${theme.fontSizes.xs};
    `,
    body2: css`
      font-size: ${theme.fontSizes.xxs};
    `,
    caption: css`
      font-size: ${theme.fontSizes.xxxs};
      font-weight: 400;
      line-height: 1.66;
      letter-spacing: 0.03333em;
      display: block;
    `,
  };

  return css`
    ${variants[variant]}
  `;
};

export const generateButtonStyles = ({ theme, ...rest }) => {
  const { variant = 'contained', bgcolor = ThemeColorKeys.BACKGROUND_LIGHT } = rest;

  const variants = {
    contained: css`
      background-color: ${get(theme.colors, bgcolor)};

      &:not(:disabled) {
        &:hover,
        &:active {
          background-color: ${darken(0.07, hsLFromCssVar(get(theme.colors, bgcolor)))};
        }
      }
    `,

    outline: css`
      background-color: transparent;
      border: 1px solid ${get(theme.colors, bgcolor)};

      &:not(:disabled) {
        &:hover,
        &:active {
          background-color: ${darken(0.07, hsLFromCssVar(get(theme.colors, bgcolor)))};
        }
      }
    `,

    text: css`
      background-color: transparent;

      &:not(:disabled) {
        &:hover,
        &:active {
          text-decoration: underline;
        }
      }
    `,
  };

  return css`
    ${variants[variant]}
  `;
};
