/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Duration } from "../../google/protobuf/duration";
import { Timestamp } from "../../google/protobuf/timestamp";
import { BoolValue, DoubleValue, FloatValue } from "../../google/protobuf/wrappers";
import { SE2Velocity, Vec2, Vec3, Wrench } from "./geometry";
import { SE2Trajectory } from "./trajectory";

export const protobufPackage = "bosdyn.api";

export interface RobotCommandFeedbackStatus {
}

export enum RobotCommandFeedbackStatus_Status {
  /** STATUS_UNKNOWN - / Behavior execution is in an unknown / unexpected state. */
  STATUS_UNKNOWN = 0,
  /** STATUS_PROCESSING - / The robot is actively working on the command */
  STATUS_PROCESSING = 1,
  /** STATUS_COMMAND_OVERRIDDEN - / The command was replaced by a new command */
  STATUS_COMMAND_OVERRIDDEN = 2,
  /** STATUS_COMMAND_TIMED_OUT - / The command expired */
  STATUS_COMMAND_TIMED_OUT = 3,
  /** STATUS_ROBOT_FROZEN - / The robot is in an unsafe state, and will only respond to known safe commands. */
  STATUS_ROBOT_FROZEN = 4,
  /**
   * STATUS_INCOMPATIBLE_HARDWARE - / The request cannot be executed because the required hardware is missing.
   * / For example, an armless robot receiving a synchronized command with an arm_command
   * / request will return this value in the arm_command_feedback status.
   */
  STATUS_INCOMPATIBLE_HARDWARE = 5,
  UNRECOGNIZED = -1,
}

export function robotCommandFeedbackStatus_StatusFromJSON(object: any): RobotCommandFeedbackStatus_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return RobotCommandFeedbackStatus_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_PROCESSING":
      return RobotCommandFeedbackStatus_Status.STATUS_PROCESSING;
    case 2:
    case "STATUS_COMMAND_OVERRIDDEN":
      return RobotCommandFeedbackStatus_Status.STATUS_COMMAND_OVERRIDDEN;
    case 3:
    case "STATUS_COMMAND_TIMED_OUT":
      return RobotCommandFeedbackStatus_Status.STATUS_COMMAND_TIMED_OUT;
    case 4:
    case "STATUS_ROBOT_FROZEN":
      return RobotCommandFeedbackStatus_Status.STATUS_ROBOT_FROZEN;
    case 5:
    case "STATUS_INCOMPATIBLE_HARDWARE":
      return RobotCommandFeedbackStatus_Status.STATUS_INCOMPATIBLE_HARDWARE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RobotCommandFeedbackStatus_Status.UNRECOGNIZED;
  }
}

export function robotCommandFeedbackStatus_StatusToJSON(object: RobotCommandFeedbackStatus_Status): string {
  switch (object) {
    case RobotCommandFeedbackStatus_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case RobotCommandFeedbackStatus_Status.STATUS_PROCESSING:
      return "STATUS_PROCESSING";
    case RobotCommandFeedbackStatus_Status.STATUS_COMMAND_OVERRIDDEN:
      return "STATUS_COMMAND_OVERRIDDEN";
    case RobotCommandFeedbackStatus_Status.STATUS_COMMAND_TIMED_OUT:
      return "STATUS_COMMAND_TIMED_OUT";
    case RobotCommandFeedbackStatus_Status.STATUS_ROBOT_FROZEN:
      return "STATUS_ROBOT_FROZEN";
    case RobotCommandFeedbackStatus_Status.STATUS_INCOMPATIBLE_HARDWARE:
      return "STATUS_INCOMPATIBLE_HARDWARE";
    case RobotCommandFeedbackStatus_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Get the robot into a convenient pose for changing the battery */
export interface BatteryChangePoseCommand {
}

export interface BatteryChangePoseCommand_Request {
  directionHint: BatteryChangePoseCommand_Request_DirectionHint;
}

export enum BatteryChangePoseCommand_Request_DirectionHint {
  /** HINT_UNKNOWN - Unknown direction, just hold still */
  HINT_UNKNOWN = 0,
  /** HINT_RIGHT - Roll over right (right feet end up under the robot) */
  HINT_RIGHT = 1,
  /** HINT_LEFT - Roll over left (left feet end up under the robot) */
  HINT_LEFT = 2,
  UNRECOGNIZED = -1,
}

export function batteryChangePoseCommand_Request_DirectionHintFromJSON(
  object: any,
): BatteryChangePoseCommand_Request_DirectionHint {
  switch (object) {
    case 0:
    case "HINT_UNKNOWN":
      return BatteryChangePoseCommand_Request_DirectionHint.HINT_UNKNOWN;
    case 1:
    case "HINT_RIGHT":
      return BatteryChangePoseCommand_Request_DirectionHint.HINT_RIGHT;
    case 2:
    case "HINT_LEFT":
      return BatteryChangePoseCommand_Request_DirectionHint.HINT_LEFT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BatteryChangePoseCommand_Request_DirectionHint.UNRECOGNIZED;
  }
}

export function batteryChangePoseCommand_Request_DirectionHintToJSON(
  object: BatteryChangePoseCommand_Request_DirectionHint,
): string {
  switch (object) {
    case BatteryChangePoseCommand_Request_DirectionHint.HINT_UNKNOWN:
      return "HINT_UNKNOWN";
    case BatteryChangePoseCommand_Request_DirectionHint.HINT_RIGHT:
      return "HINT_RIGHT";
    case BatteryChangePoseCommand_Request_DirectionHint.HINT_LEFT:
      return "HINT_LEFT";
    case BatteryChangePoseCommand_Request_DirectionHint.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface BatteryChangePoseCommand_Feedback {
  status: BatteryChangePoseCommand_Feedback_Status;
}

export enum BatteryChangePoseCommand_Feedback_Status {
  STATUS_UNKNOWN = 0,
  /** STATUS_COMPLETED - Robot is finished rolling */
  STATUS_COMPLETED = 1,
  /** STATUS_IN_PROGRESS - Robot still in process of rolling over */
  STATUS_IN_PROGRESS = 2,
  /** STATUS_FAILED - Robot has failed to roll onto its side */
  STATUS_FAILED = 3,
  UNRECOGNIZED = -1,
}

export function batteryChangePoseCommand_Feedback_StatusFromJSON(
  object: any,
): BatteryChangePoseCommand_Feedback_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return BatteryChangePoseCommand_Feedback_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_COMPLETED":
      return BatteryChangePoseCommand_Feedback_Status.STATUS_COMPLETED;
    case 2:
    case "STATUS_IN_PROGRESS":
      return BatteryChangePoseCommand_Feedback_Status.STATUS_IN_PROGRESS;
    case 3:
    case "STATUS_FAILED":
      return BatteryChangePoseCommand_Feedback_Status.STATUS_FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BatteryChangePoseCommand_Feedback_Status.UNRECOGNIZED;
  }
}

export function batteryChangePoseCommand_Feedback_StatusToJSON(
  object: BatteryChangePoseCommand_Feedback_Status,
): string {
  switch (object) {
    case BatteryChangePoseCommand_Feedback_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case BatteryChangePoseCommand_Feedback_Status.STATUS_COMPLETED:
      return "STATUS_COMPLETED";
    case BatteryChangePoseCommand_Feedback_Status.STATUS_IN_PROGRESS:
      return "STATUS_IN_PROGRESS";
    case BatteryChangePoseCommand_Feedback_Status.STATUS_FAILED:
      return "STATUS_FAILED";
    case BatteryChangePoseCommand_Feedback_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Move the robot into a "ready" position from which it can sit or stand up. */
export interface SelfRightCommand {
}

/** SelfRight command request takes no additional arguments. */
export interface SelfRightCommand_Request {
}

export interface SelfRightCommand_Feedback {
  status: SelfRightCommand_Feedback_Status;
}

export enum SelfRightCommand_Feedback_Status {
  STATUS_UNKNOWN = 0,
  /** STATUS_COMPLETED - Self-right has completed */
  STATUS_COMPLETED = 1,
  /** STATUS_IN_PROGRESS - Robot is in progress of attempting to self-right */
  STATUS_IN_PROGRESS = 2,
  UNRECOGNIZED = -1,
}

export function selfRightCommand_Feedback_StatusFromJSON(object: any): SelfRightCommand_Feedback_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return SelfRightCommand_Feedback_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_COMPLETED":
      return SelfRightCommand_Feedback_Status.STATUS_COMPLETED;
    case 2:
    case "STATUS_IN_PROGRESS":
      return SelfRightCommand_Feedback_Status.STATUS_IN_PROGRESS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SelfRightCommand_Feedback_Status.UNRECOGNIZED;
  }
}

export function selfRightCommand_Feedback_StatusToJSON(object: SelfRightCommand_Feedback_Status): string {
  switch (object) {
    case SelfRightCommand_Feedback_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case SelfRightCommand_Feedback_Status.STATUS_COMPLETED:
      return "STATUS_COMPLETED";
    case SelfRightCommand_Feedback_Status.STATUS_IN_PROGRESS:
      return "STATUS_IN_PROGRESS";
    case SelfRightCommand_Feedback_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Stop the robot in place with minimal motion. */
export interface StopCommand {
}

/** Stop command request takes no additional arguments. */
export interface StopCommand_Request {
}

/** Stop command provides no feedback. */
export interface StopCommand_Feedback {
}

/** Freeze all joints at their current positions (no balancing control). */
export interface FreezeCommand {
}

/** Freeze command request takes no additional arguments. */
export interface FreezeCommand_Request {
}

/** Freeze command provides no feedback. */
export interface FreezeCommand_Feedback {
}

/**
 * Get robot into a position where it is safe to power down, then power down. If the robot has
 * fallen, it will power down directly. If the robot is standing, it will first sit then power down.
 * With appropriate request parameters and under limited scenarios, the robot may take additional
 * steps to move to a safe position. The robot will not power down until it is in a sitting state.
 */
export interface SafePowerOffCommand {
}

export interface SafePowerOffCommand_Request {
  unsafeAction: SafePowerOffCommand_Request_UnsafeAction;
}

/**
 * Robot action in response to a command received while in an unsafe position. If not
 * specified, UNSAFE_MOVE_TO_SAFE_POSITION will be used
 */
export enum SafePowerOffCommand_Request_UnsafeAction {
  UNSAFE_UNKNOWN = 0,
  /**
   * UNSAFE_MOVE_TO_SAFE_POSITION - Robot may attempt to move to a safe position (i.e. descends stairs) before sitting
   * and powering off.
   */
  UNSAFE_MOVE_TO_SAFE_POSITION = 1,
  /**
   * UNSAFE_FORCE_COMMAND - Force sit and power off regardless of positioning. Robot will not take additional
   * steps
   */
  UNSAFE_FORCE_COMMAND = 2,
  UNRECOGNIZED = -1,
}

export function safePowerOffCommand_Request_UnsafeActionFromJSON(
  object: any,
): SafePowerOffCommand_Request_UnsafeAction {
  switch (object) {
    case 0:
    case "UNSAFE_UNKNOWN":
      return SafePowerOffCommand_Request_UnsafeAction.UNSAFE_UNKNOWN;
    case 1:
    case "UNSAFE_MOVE_TO_SAFE_POSITION":
      return SafePowerOffCommand_Request_UnsafeAction.UNSAFE_MOVE_TO_SAFE_POSITION;
    case 2:
    case "UNSAFE_FORCE_COMMAND":
      return SafePowerOffCommand_Request_UnsafeAction.UNSAFE_FORCE_COMMAND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SafePowerOffCommand_Request_UnsafeAction.UNRECOGNIZED;
  }
}

export function safePowerOffCommand_Request_UnsafeActionToJSON(
  object: SafePowerOffCommand_Request_UnsafeAction,
): string {
  switch (object) {
    case SafePowerOffCommand_Request_UnsafeAction.UNSAFE_UNKNOWN:
      return "UNSAFE_UNKNOWN";
    case SafePowerOffCommand_Request_UnsafeAction.UNSAFE_MOVE_TO_SAFE_POSITION:
      return "UNSAFE_MOVE_TO_SAFE_POSITION";
    case SafePowerOffCommand_Request_UnsafeAction.UNSAFE_FORCE_COMMAND:
      return "UNSAFE_FORCE_COMMAND";
    case SafePowerOffCommand_Request_UnsafeAction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * The SafePowerOff will provide feedback on whether or not it has succeeded in powering off
 * the robot yet.
 */
export interface SafePowerOffCommand_Feedback {
  /** Current status of the command. */
  status: SafePowerOffCommand_Feedback_Status;
}

export enum SafePowerOffCommand_Feedback_Status {
  /** STATUS_UNKNOWN - STATUS_UNKNOWN should never be used. If used, an internal error has happened. */
  STATUS_UNKNOWN = 0,
  /** STATUS_POWERED_OFF - Robot has powered off. */
  STATUS_POWERED_OFF = 1,
  /** STATUS_IN_PROGRESS - Robot is trying to safely power off. */
  STATUS_IN_PROGRESS = 2,
  UNRECOGNIZED = -1,
}

export function safePowerOffCommand_Feedback_StatusFromJSON(object: any): SafePowerOffCommand_Feedback_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return SafePowerOffCommand_Feedback_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_POWERED_OFF":
      return SafePowerOffCommand_Feedback_Status.STATUS_POWERED_OFF;
    case 2:
    case "STATUS_IN_PROGRESS":
      return SafePowerOffCommand_Feedback_Status.STATUS_IN_PROGRESS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SafePowerOffCommand_Feedback_Status.UNRECOGNIZED;
  }
}

export function safePowerOffCommand_Feedback_StatusToJSON(object: SafePowerOffCommand_Feedback_Status): string {
  switch (object) {
    case SafePowerOffCommand_Feedback_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case SafePowerOffCommand_Feedback_Status.STATUS_POWERED_OFF:
      return "STATUS_POWERED_OFF";
    case SafePowerOffCommand_Feedback_Status.STATUS_IN_PROGRESS:
      return "STATUS_IN_PROGRESS";
    case SafePowerOffCommand_Feedback_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Move along a trajectory in 2D space. */
export interface SE2TrajectoryCommand {
}

export interface SE2TrajectoryCommand_Request {
  /**
   * The timestamp (in robot time) by which a command must finish executing.
   * This is a required field and used to prevent runaway commands.
   */
  endTime:
    | Date
    | undefined;
  /**
   * The name of the frame that trajectory is relative to. The trajectory
   * must be expressed in a gravity aligned frame, so either "vision",
   * "odom", or "body". Any other provided se2_frame_name will be rejected
   * and the trajectory command will not be exectuted.
   */
  se2FrameName: string;
  /**
   * The trajectory that the robot should follow, expressed in the frame
   * identified by se2_frame_name.
   */
  trajectory: SE2Trajectory | undefined;
}

/**
 * The SE2TrajectoryCommand will provide feedback on whether or not the robot has reached
 * the final point of the trajectory.
 */
export interface SE2TrajectoryCommand_Feedback {
  /** Current status of the command. */
  status: SE2TrajectoryCommand_Feedback_Status;
  /** Current status of how the body is moving */
  bodyMovementStatus: SE2TrajectoryCommand_Feedback_BodyMovementStatus;
  /** Flag indicating if the final requested position was achievable. */
  finalGoalStatus: SE2TrajectoryCommand_Feedback_FinalGoalStatus;
}

export enum SE2TrajectoryCommand_Feedback_Status {
  /** STATUS_UNKNOWN - STATUS_UNKNOWN should never be used. If used, an internal error has happened. */
  STATUS_UNKNOWN = 0,
  /**
   * STATUS_STOPPED - The robot has stopped. Either the robot has reached the end of the trajectory or it
   * believes that it cannot reach the desired position. Robot may start to move again if
   * a blocked path clears.
   */
  STATUS_STOPPED = 1,
  /**
   * STATUS_STOPPING - The robot is nearing the end of the requested trajectory and is doing final
   * positioning.
   */
  STATUS_STOPPING = 3,
  /** STATUS_IN_PROGRESS - The robot is actively following the requested trajectory. */
  STATUS_IN_PROGRESS = 2,
  /**
   * STATUS_AT_GOAL - The following enum values were possibly confusing in situations where the robot
   * cannot achieve the requested trajectory and are now deprecated.
   */
  STATUS_AT_GOAL = 1,
  STATUS_NEAR_GOAL = 3,
  STATUS_GOING_TO_GOAL = 2,
  UNRECOGNIZED = -1,
}

export function sE2TrajectoryCommand_Feedback_StatusFromJSON(object: any): SE2TrajectoryCommand_Feedback_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return SE2TrajectoryCommand_Feedback_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_STOPPED":
      return SE2TrajectoryCommand_Feedback_Status.STATUS_STOPPED;
    case 3:
    case "STATUS_STOPPING":
      return SE2TrajectoryCommand_Feedback_Status.STATUS_STOPPING;
    case 2:
    case "STATUS_IN_PROGRESS":
      return SE2TrajectoryCommand_Feedback_Status.STATUS_IN_PROGRESS;
    case 1:
    case "STATUS_AT_GOAL":
      return SE2TrajectoryCommand_Feedback_Status.STATUS_AT_GOAL;
    case 3:
    case "STATUS_NEAR_GOAL":
      return SE2TrajectoryCommand_Feedback_Status.STATUS_NEAR_GOAL;
    case 2:
    case "STATUS_GOING_TO_GOAL":
      return SE2TrajectoryCommand_Feedback_Status.STATUS_GOING_TO_GOAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SE2TrajectoryCommand_Feedback_Status.UNRECOGNIZED;
  }
}

export function sE2TrajectoryCommand_Feedback_StatusToJSON(object: SE2TrajectoryCommand_Feedback_Status): string {
  switch (object) {
    case SE2TrajectoryCommand_Feedback_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case SE2TrajectoryCommand_Feedback_Status.STATUS_STOPPED:
      return "STATUS_STOPPED";
    case SE2TrajectoryCommand_Feedback_Status.STATUS_STOPPING:
      return "STATUS_STOPPING";
    case SE2TrajectoryCommand_Feedback_Status.STATUS_IN_PROGRESS:
      return "STATUS_IN_PROGRESS";
    case SE2TrajectoryCommand_Feedback_Status.STATUS_AT_GOAL:
      return "STATUS_AT_GOAL";
    case SE2TrajectoryCommand_Feedback_Status.STATUS_NEAR_GOAL:
      return "STATUS_NEAR_GOAL";
    case SE2TrajectoryCommand_Feedback_Status.STATUS_GOING_TO_GOAL:
      return "STATUS_GOING_TO_GOAL";
    case SE2TrajectoryCommand_Feedback_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SE2TrajectoryCommand_Feedback_BodyMovementStatus {
  /** BODY_STATUS_UNKNOWN - STATUS_UNKNOWN should never be used. If used, an internal error has happened. */
  BODY_STATUS_UNKNOWN = 0,
  /** BODY_STATUS_MOVING - The robot body is not settled at the goal. */
  BODY_STATUS_MOVING = 1,
  /** BODY_STATUS_SETTLED - The robot is at the goal and the body has stopped moving. */
  BODY_STATUS_SETTLED = 2,
  UNRECOGNIZED = -1,
}

export function sE2TrajectoryCommand_Feedback_BodyMovementStatusFromJSON(
  object: any,
): SE2TrajectoryCommand_Feedback_BodyMovementStatus {
  switch (object) {
    case 0:
    case "BODY_STATUS_UNKNOWN":
      return SE2TrajectoryCommand_Feedback_BodyMovementStatus.BODY_STATUS_UNKNOWN;
    case 1:
    case "BODY_STATUS_MOVING":
      return SE2TrajectoryCommand_Feedback_BodyMovementStatus.BODY_STATUS_MOVING;
    case 2:
    case "BODY_STATUS_SETTLED":
      return SE2TrajectoryCommand_Feedback_BodyMovementStatus.BODY_STATUS_SETTLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SE2TrajectoryCommand_Feedback_BodyMovementStatus.UNRECOGNIZED;
  }
}

export function sE2TrajectoryCommand_Feedback_BodyMovementStatusToJSON(
  object: SE2TrajectoryCommand_Feedback_BodyMovementStatus,
): string {
  switch (object) {
    case SE2TrajectoryCommand_Feedback_BodyMovementStatus.BODY_STATUS_UNKNOWN:
      return "BODY_STATUS_UNKNOWN";
    case SE2TrajectoryCommand_Feedback_BodyMovementStatus.BODY_STATUS_MOVING:
      return "BODY_STATUS_MOVING";
    case SE2TrajectoryCommand_Feedback_BodyMovementStatus.BODY_STATUS_SETTLED:
      return "BODY_STATUS_SETTLED";
    case SE2TrajectoryCommand_Feedback_BodyMovementStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SE2TrajectoryCommand_Feedback_FinalGoalStatus {
  /**
   * FINAL_GOAL_STATUS_UNKNOWN - FINAL_GOAL_STATUS_UNKNOWN should never be used. If used, an internal error has
   * happened.
   */
  FINAL_GOAL_STATUS_UNKNOWN = 0,
  /** FINAL_GOAL_STATUS_IN_PROGRESS - Robot is not stopped or stopping. */
  FINAL_GOAL_STATUS_IN_PROGRESS = 1,
  /** FINAL_GOAL_STATUS_ACHIEVABLE - Final position was achievable. */
  FINAL_GOAL_STATUS_ACHIEVABLE = 2,
  /** FINAL_GOAL_STATUS_BLOCKED - Final position was not achievable. */
  FINAL_GOAL_STATUS_BLOCKED = 3,
  UNRECOGNIZED = -1,
}

export function sE2TrajectoryCommand_Feedback_FinalGoalStatusFromJSON(
  object: any,
): SE2TrajectoryCommand_Feedback_FinalGoalStatus {
  switch (object) {
    case 0:
    case "FINAL_GOAL_STATUS_UNKNOWN":
      return SE2TrajectoryCommand_Feedback_FinalGoalStatus.FINAL_GOAL_STATUS_UNKNOWN;
    case 1:
    case "FINAL_GOAL_STATUS_IN_PROGRESS":
      return SE2TrajectoryCommand_Feedback_FinalGoalStatus.FINAL_GOAL_STATUS_IN_PROGRESS;
    case 2:
    case "FINAL_GOAL_STATUS_ACHIEVABLE":
      return SE2TrajectoryCommand_Feedback_FinalGoalStatus.FINAL_GOAL_STATUS_ACHIEVABLE;
    case 3:
    case "FINAL_GOAL_STATUS_BLOCKED":
      return SE2TrajectoryCommand_Feedback_FinalGoalStatus.FINAL_GOAL_STATUS_BLOCKED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SE2TrajectoryCommand_Feedback_FinalGoalStatus.UNRECOGNIZED;
  }
}

export function sE2TrajectoryCommand_Feedback_FinalGoalStatusToJSON(
  object: SE2TrajectoryCommand_Feedback_FinalGoalStatus,
): string {
  switch (object) {
    case SE2TrajectoryCommand_Feedback_FinalGoalStatus.FINAL_GOAL_STATUS_UNKNOWN:
      return "FINAL_GOAL_STATUS_UNKNOWN";
    case SE2TrajectoryCommand_Feedback_FinalGoalStatus.FINAL_GOAL_STATUS_IN_PROGRESS:
      return "FINAL_GOAL_STATUS_IN_PROGRESS";
    case SE2TrajectoryCommand_Feedback_FinalGoalStatus.FINAL_GOAL_STATUS_ACHIEVABLE:
      return "FINAL_GOAL_STATUS_ACHIEVABLE";
    case SE2TrajectoryCommand_Feedback_FinalGoalStatus.FINAL_GOAL_STATUS_BLOCKED:
      return "FINAL_GOAL_STATUS_BLOCKED";
    case SE2TrajectoryCommand_Feedback_FinalGoalStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Move the robot at a specific SE2 velocity for a fixed amount of time. */
export interface SE2VelocityCommand {
}

export interface SE2VelocityCommand_Request {
  /**
   * The timestamp (in robot time) by which a command must finish executing. This is a
   * required field and used to prevent runaway commands.
   */
  endTime:
    | Date
    | undefined;
  /**
   * The name of the frame that velocity and slew_rate_limit are relative to.
   * The trajectory must be expressed in a gravity aligned frame, so either
   * "vision", "odom", or "flat_body". Any other provided
   * se2_frame_name will be rejected and the velocity command will not be executed.
   */
  se2FrameName: string;
  /** Desired planar velocity of the robot body relative to se2_frame_name. */
  velocity:
    | SE2Velocity
    | undefined;
  /**
   * If set, limits how quickly velocity can change relative to se2_frame_name.
   * Otherwise, robot may decide to limit velocities using default settings.
   * These values should be non-negative.
   */
  slewRateLimit: SE2Velocity | undefined;
}

/** Planar velocity commands provide no feedback. */
export interface SE2VelocityCommand_Feedback {
}

/** Sit the robot down in its current position. */
export interface SitCommand {
}

/** Sit command request takes no additional arguments. */
export interface SitCommand_Request {
}

export interface SitCommand_Feedback {
  /** Current status of the command. */
  status: SitCommand_Feedback_Status;
}

export enum SitCommand_Feedback_Status {
  /** STATUS_UNKNOWN - STATUS_UNKNOWN should never be used. If used, an internal error has happened. */
  STATUS_UNKNOWN = 0,
  /** STATUS_IS_SITTING - Robot is currently sitting. */
  STATUS_IS_SITTING = 1,
  /** STATUS_IN_PROGRESS - Robot is trying to sit. */
  STATUS_IN_PROGRESS = 2,
  UNRECOGNIZED = -1,
}

export function sitCommand_Feedback_StatusFromJSON(object: any): SitCommand_Feedback_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return SitCommand_Feedback_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_IS_SITTING":
      return SitCommand_Feedback_Status.STATUS_IS_SITTING;
    case 2:
    case "STATUS_IN_PROGRESS":
      return SitCommand_Feedback_Status.STATUS_IN_PROGRESS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SitCommand_Feedback_Status.UNRECOGNIZED;
  }
}

export function sitCommand_Feedback_StatusToJSON(object: SitCommand_Feedback_Status): string {
  switch (object) {
    case SitCommand_Feedback_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case SitCommand_Feedback_Status.STATUS_IS_SITTING:
      return "STATUS_IS_SITTING";
    case SitCommand_Feedback_Status.STATUS_IN_PROGRESS:
      return "STATUS_IN_PROGRESS";
    case SitCommand_Feedback_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** The stand the robot up in its current position. */
export interface StandCommand {
}

/** Stand command request takes no additional arguments. */
export interface StandCommand_Request {
}

/**
 * The StandCommand will provide two feedback fields: status, and standing_state. Status
 * reflects if the robot has four legs on the ground and is near a final pose. StandingState
 * reflects if the robot has converged to a final pose and does not expect future movement.
 */
export interface StandCommand_Feedback {
  /** Current status of the command. */
  status: StandCommand_Feedback_Status;
  /** What type of standing the robot is doing currently. */
  standingState: StandCommand_Feedback_StandingState;
}

export enum StandCommand_Feedback_Status {
  /** STATUS_UNKNOWN - STATUS_UNKNOWN should never be used. If used, an internal error has happened. */
  STATUS_UNKNOWN = 0,
  /** STATUS_IS_STANDING - Robot has finished standing up and has completed desired body trajectory. */
  STATUS_IS_STANDING = 1,
  /** STATUS_IN_PROGRESS - Robot is trying to come to a steady stand. */
  STATUS_IN_PROGRESS = 2,
  UNRECOGNIZED = -1,
}

export function standCommand_Feedback_StatusFromJSON(object: any): StandCommand_Feedback_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return StandCommand_Feedback_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_IS_STANDING":
      return StandCommand_Feedback_Status.STATUS_IS_STANDING;
    case 2:
    case "STATUS_IN_PROGRESS":
      return StandCommand_Feedback_Status.STATUS_IN_PROGRESS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return StandCommand_Feedback_Status.UNRECOGNIZED;
  }
}

export function standCommand_Feedback_StatusToJSON(object: StandCommand_Feedback_Status): string {
  switch (object) {
    case StandCommand_Feedback_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case StandCommand_Feedback_Status.STATUS_IS_STANDING:
      return "STATUS_IS_STANDING";
    case StandCommand_Feedback_Status.STATUS_IN_PROGRESS:
      return "STATUS_IN_PROGRESS";
    case StandCommand_Feedback_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum StandCommand_Feedback_StandingState {
  /** STANDING_UNKNOWN - STANDING_UNKNOWN should never be used. If used, an internal error has happened. */
  STANDING_UNKNOWN = 0,
  /**
   * STANDING_CONTROLLED - Robot is standing up and actively controlling its body so it may occasionally make
   * small body adjustments.
   */
  STANDING_CONTROLLED = 1,
  /**
   * STANDING_FROZEN - Robot is standing still with its body frozen in place so it should not move unless
   * commanded to. Motion sensitive tasks like laser scanning should be performed in this
   * state.
   */
  STANDING_FROZEN = 2,
  UNRECOGNIZED = -1,
}

export function standCommand_Feedback_StandingStateFromJSON(object: any): StandCommand_Feedback_StandingState {
  switch (object) {
    case 0:
    case "STANDING_UNKNOWN":
      return StandCommand_Feedback_StandingState.STANDING_UNKNOWN;
    case 1:
    case "STANDING_CONTROLLED":
      return StandCommand_Feedback_StandingState.STANDING_CONTROLLED;
    case 2:
    case "STANDING_FROZEN":
      return StandCommand_Feedback_StandingState.STANDING_FROZEN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return StandCommand_Feedback_StandingState.UNRECOGNIZED;
  }
}

export function standCommand_Feedback_StandingStateToJSON(object: StandCommand_Feedback_StandingState): string {
  switch (object) {
    case StandCommand_Feedback_StandingState.STANDING_UNKNOWN:
      return "STANDING_UNKNOWN";
    case StandCommand_Feedback_StandingState.STANDING_CONTROLLED:
      return "STANDING_CONTROLLED";
    case StandCommand_Feedback_StandingState.STANDING_FROZEN:
      return "STANDING_FROZEN";
    case StandCommand_Feedback_StandingState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Precise foot placement
 * This can be used to reposition the robots feet in place.
 */
export interface StanceCommand {
}

export interface StanceCommand_Request {
  /**
   * / The timestamp (in robot time) by which a command must finish executing.
   * / This is a required field and used to prevent runaway commands.
   */
  endTime: Date | undefined;
  stance: Stance | undefined;
}

export interface StanceCommand_Feedback {
  status: StanceCommand_Feedback_Status;
}

export enum StanceCommand_Feedback_Status {
  STATUS_UNKNOWN = 0,
  /** STATUS_STANCED - Robot has finished moving feet and they are at the specified position */
  STATUS_STANCED = 1,
  /** STATUS_GOING_TO_STANCE - Robot is in the process of moving feet to specified position */
  STATUS_GOING_TO_STANCE = 2,
  /**
   * STATUS_TOO_FAR_AWAY - Robot is not moving, the specified stance was too far away.
   * Hint: Try using SE2TrajectoryCommand to safely put the robot at the
   *       correct location first.
   */
  STATUS_TOO_FAR_AWAY = 3,
  UNRECOGNIZED = -1,
}

export function stanceCommand_Feedback_StatusFromJSON(object: any): StanceCommand_Feedback_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return StanceCommand_Feedback_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_STANCED":
      return StanceCommand_Feedback_Status.STATUS_STANCED;
    case 2:
    case "STATUS_GOING_TO_STANCE":
      return StanceCommand_Feedback_Status.STATUS_GOING_TO_STANCE;
    case 3:
    case "STATUS_TOO_FAR_AWAY":
      return StanceCommand_Feedback_Status.STATUS_TOO_FAR_AWAY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return StanceCommand_Feedback_Status.UNRECOGNIZED;
  }
}

export function stanceCommand_Feedback_StatusToJSON(object: StanceCommand_Feedback_Status): string {
  switch (object) {
    case StanceCommand_Feedback_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case StanceCommand_Feedback_Status.STATUS_STANCED:
      return "STATUS_STANCED";
    case StanceCommand_Feedback_Status.STATUS_GOING_TO_STANCE:
      return "STATUS_GOING_TO_STANCE";
    case StanceCommand_Feedback_Status.STATUS_TOO_FAR_AWAY:
      return "STATUS_TOO_FAR_AWAY";
    case StanceCommand_Feedback_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Stance {
  /** The frame name which the desired foot_positions are described in. */
  se2FrameName: string;
  /**
   * Map of foot name to its x,y location in specified frame.
   * Required positions for spot: "fl", "fr", "hl", "hr".
   */
  footPositions: { [key: string]: Vec2 };
  /**
   * Required foot positional accuracy in meters
   * Advised = 0.05 ( 5cm)
   * Minimum = 0.02 ( 2cm)
   * Maximum = 0.10 (10cm)
   */
  accuracy: number;
}

export interface Stance_FootPositionsEntry {
  key: string;
  value: Vec2 | undefined;
}

/**
 * The base will move in response to the hand's location, allow the arm to reach beyond
 * its current workspace.  If the hand is moved forward, the body will begin walking
 * forward to keep the base at the desired offset from the hand.
 */
export interface FollowArmCommand {
}

export interface FollowArmCommand_Request {
  /**
   * Optional body offset from the hand.
   * For example, to have the body 0.75 meters behind the hand, use (0.75, 0, 0)
   */
  bodyOffsetFromHand:
    | Vec3
    | undefined;
  /**
   * DEPRECATED as of 3.1.
   * To reproduce the robot's behavior of disable_walking == true,
   * issue a StandCommand setting the enable_body_yaw_assist_for_manipulation and
   * enable_hip_height_assist_for_manipulation MobilityParams to true.  Any combination
   * of the enable_*_for_manipulation are accepted in stand giving finer control of
   * the robot's behavior.
   *
   * @deprecated
   */
  disableWalking: boolean;
}

/** FollowArmCommand commands provide no feedback. */
export interface FollowArmCommand_Feedback {
}

export interface ArmDragCommand {
}

export interface ArmDragCommand_Request {
}

export interface ArmDragCommand_Feedback {
  status: ArmDragCommand_Feedback_Status;
}

export enum ArmDragCommand_Feedback_Status {
  /** STATUS_UNKNOWN - STATUS_UNKNOWN should never be used. If used, an internal error has happened. */
  STATUS_UNKNOWN = 0,
  /** STATUS_DRAGGING - Robot is dragging. */
  STATUS_DRAGGING = 1,
  /**
   * STATUS_GRASP_FAILED - Robot is not dragging because grasp failed.
   * This could be due to a lost grasp during a drag, or because the gripper isn't in a
   * good position at the time of request. You'll have to reposition or regrasp and then
   * send a new drag request to overcome this type of error. Note: When requesting drag,
   * make sure the gripper is positioned in front of the robot (not to the side of or
   * above the robot body).
   */
  STATUS_GRASP_FAILED = 2,
  /**
   * STATUS_OTHER_FAILURE - Robot is not dragging for another reason.
   * This might be because the gripper isn't holding an item.
   * You can continue dragging once you resolve this type of error (i.e. by sending an
   * ApiGraspOverride request). Note: When requesting drag, be sure to that the robot
   * knows it's holding something (or use APIGraspOverride to OVERRIDE_HOLDING).
   */
  STATUS_OTHER_FAILURE = 3,
  UNRECOGNIZED = -1,
}

export function armDragCommand_Feedback_StatusFromJSON(object: any): ArmDragCommand_Feedback_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return ArmDragCommand_Feedback_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_DRAGGING":
      return ArmDragCommand_Feedback_Status.STATUS_DRAGGING;
    case 2:
    case "STATUS_GRASP_FAILED":
      return ArmDragCommand_Feedback_Status.STATUS_GRASP_FAILED;
    case 3:
    case "STATUS_OTHER_FAILURE":
      return ArmDragCommand_Feedback_Status.STATUS_OTHER_FAILURE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ArmDragCommand_Feedback_Status.UNRECOGNIZED;
  }
}

export function armDragCommand_Feedback_StatusToJSON(object: ArmDragCommand_Feedback_Status): string {
  switch (object) {
    case ArmDragCommand_Feedback_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case ArmDragCommand_Feedback_Status.STATUS_DRAGGING:
      return "STATUS_DRAGGING";
    case ArmDragCommand_Feedback_Status.STATUS_GRASP_FAILED:
      return "STATUS_GRASP_FAILED";
    case ArmDragCommand_Feedback_Status.STATUS_OTHER_FAILURE:
      return "STATUS_OTHER_FAILURE";
    case ArmDragCommand_Feedback_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ConstrainedManipulationCommand {
}

export interface ConstrainedManipulationCommand_Request {
  /** Frame that the initial wrench will be expressed in */
  frameName: string;
  /**
   * Direction of the initial wrench to be applied
   * Depending on the task, either the force vector or the
   * torque vector are required to be specified. The required
   * vector should not have a magnitude of zero and will be
   * normalized to 1. For tasks that require the force vector,
   * the torque vector can still be specified as a non-zero vector
   * if it is a good guess of the axis of rotation of the task.
   * (for e.g. TASK_TYPE_SE3_ROTATIONAL_TORQUE task types.)
   * Note that if both vectors are non-zero, they have to be perpendicular.
   * Once the constrained manipulation system estimates the
   * constraint, the init_wrench_direction and frame_name
   * will no longer be used.
   */
  initWrenchDirectionInFrameName:
    | Wrench
    | undefined;
  /** Recommended values are in the range of [-4, 4] m/s */
  tangentialSpeed?:
    | number
    | undefined;
  /** Recommended values are in the range of [-4, 4] rad/s */
  rotationalSpeed?:
    | number
    | undefined;
  /**
   * The limit on the force that is applied on any translation direction
   * Value must be positive
   * If unspecified, a default value of 40 N will be used.
   */
  forceLimit:
    | number
    | undefined;
  /**
   * The limit on the torque that is applied on any rotational direction
   * Value must be positive
   * If unspecified, a default value of 4 Nm will be used.
   */
  torqueLimit: number | undefined;
  taskType: ConstrainedManipulationCommand_Request_TaskType;
  /**
   * The timestamp (in robot time) by which a command must finish executing.
   * This is a required field and used to prevent runaway commands.
   */
  endTime:
    | Date
    | undefined;
  /**
   * Whether to enable the robot to take steps during constrained manip to keep the hand in
   * the workspace.
   */
  enableRobotLocomotion: boolean | undefined;
  controlMode: ConstrainedManipulationCommand_Request_ControlMode;
  /**
   * Desired linear position to travel for task type
   * TASK_TYPE_R3_LINEAR_FORCE
   */
  targetLinearPosition?:
    | number
    | undefined;
  /**
   * Desired rotation in task space for all tasks other than
   * TASK_TYPE_R3_LINEAR_FORCE
   * This angle is about the estimated axis of rotation.
   */
  targetAngle?:
    | number
    | undefined;
  /**
   * Acceleration limit for the planned trajectory in the free task DOF.
   * Note that the units of this variable will be m/(s^2) or rad/(s^2) depending
   * on the choice of target_linear_position vs. target_angle above.
   */
  accelLimit:
    | number
    | undefined;
  /**
   * Constrained manipulation estimates the task frame given the observed initial motion.
   * Setting this to false saves and uses the estimation state from the previous
   * constrained manipulation move. This is true by default.
   */
  resetEstimator: boolean | undefined;
}

/**
 * Geometrical category of a task. See the constrained_manipulation_helper function
 * for examples of each of these categories. For e.g. SE3_CIRCLE_FORCE_TORQUE corresponds
 * to lever type objects.
 */
export enum ConstrainedManipulationCommand_Request_TaskType {
  TASK_TYPE_UNKNOWN = 0,
  /**
   * TASK_TYPE_SE3_CIRCLE_FORCE_TORQUE - This task type corresponds to circular tasks where
   * both the end-effector position and orientation rotate about a circle to manipulate.
   * The constrained manipulation logic will generate forces and torques in this case.
   * Example tasks are: A lever or a ball valve with a solid grasp
   * This task type will require an initial force vector specified
   * in init_wrench_direction_in_frame_name. A torque vector can be specified
   * as well if a good initial guess of the axis of rotation of the task is available.
   */
  TASK_TYPE_SE3_CIRCLE_FORCE_TORQUE = 1,
  /**
   * TASK_TYPE_R3_CIRCLE_EXTRADOF_FORCE - This task type corresponds to circular tasks that have an extra degree of freedom.
   * In these tasks the end-effector position rotates about a circle
   * but the orientation does not need to follow a circle (can remain fixed).
   * The constrained manipulation logic will generate translational forces in this case.
   * Example tasks are: A crank that has a loose handle and moves in a circle
   * and the end-effector is free to rotate about the handle in one direction.
   * This task type will require an initial force vector specified
   * in init_wrench_direction_in_frame_name.
   */
  TASK_TYPE_R3_CIRCLE_EXTRADOF_FORCE = 2,
  /**
   * TASK_TYPE_SE3_ROTATIONAL_TORQUE - This task type corresponds to purely rotational tasks.
   * In these tasks the orientation of the end-effector follows a circle,
   * and the position remains fixed. The robot will apply a torque at the
   * end-effector in these tasks.
   * Example tasks are: rotating a knob or valve that does not have a lever arm.
   * This task type will require an initial torque vector specified
   * in init_wrench_direction_in_frame_name.
   */
  TASK_TYPE_SE3_ROTATIONAL_TORQUE = 3,
  /**
   * TASK_TYPE_R3_CIRCLE_FORCE - This task type corresponds to circular tasks where
   * the end-effector position and orientation rotate about a circle
   * but the orientation does always strictly follow the circle due to slips.
   * The constrained manipulation logic will generate translational forces in this case.
   * Example tasks are: manipulating a cabinet where the grasp on handle is not very rigid
   * or can often slip.
   * This task type will require an initial force vector specified
   * in init_wrench_direction_in_frame_name.
   */
  TASK_TYPE_R3_CIRCLE_FORCE = 4,
  /**
   * TASK_TYPE_R3_LINEAR_FORCE - This task type corresponds to linear tasks where
   * the end-effector position moves in a line
   * but the orientation does not need to change.
   * The constrained manipulation logic will generate a force in this case.
   * Example tasks are: A crank that has a loose handle, or manipulating
   * a cabinet where the grasp of the handle is loose and the end-effector is free
   * to rotate about the handle in one direction.
   * This task type will require an initial force vector specified
   * in init_wrench_direction_in_frame_name.
   */
  TASK_TYPE_R3_LINEAR_FORCE = 5,
  /**
   * TASK_TYPE_HOLD_POSE - This option simply holds the hand in place with stiff impedance control.
   * You can use this mode at the beginning of a constrained manipulation task or to
   * hold position while transitioning between two different constrained manipulation
   * tasks. The target pose to hold will be the measured hand pose upon transitioning to
   * constrained manipulation or upon switching to this task type. This mode should only
   * be used during constrained manipulation tasks, since it uses impedance control to
   * hold the hand in place. This is not intended to stop the arm during position control
   * moves.
   */
  TASK_TYPE_HOLD_POSE = 6,
  UNRECOGNIZED = -1,
}

export function constrainedManipulationCommand_Request_TaskTypeFromJSON(
  object: any,
): ConstrainedManipulationCommand_Request_TaskType {
  switch (object) {
    case 0:
    case "TASK_TYPE_UNKNOWN":
      return ConstrainedManipulationCommand_Request_TaskType.TASK_TYPE_UNKNOWN;
    case 1:
    case "TASK_TYPE_SE3_CIRCLE_FORCE_TORQUE":
      return ConstrainedManipulationCommand_Request_TaskType.TASK_TYPE_SE3_CIRCLE_FORCE_TORQUE;
    case 2:
    case "TASK_TYPE_R3_CIRCLE_EXTRADOF_FORCE":
      return ConstrainedManipulationCommand_Request_TaskType.TASK_TYPE_R3_CIRCLE_EXTRADOF_FORCE;
    case 3:
    case "TASK_TYPE_SE3_ROTATIONAL_TORQUE":
      return ConstrainedManipulationCommand_Request_TaskType.TASK_TYPE_SE3_ROTATIONAL_TORQUE;
    case 4:
    case "TASK_TYPE_R3_CIRCLE_FORCE":
      return ConstrainedManipulationCommand_Request_TaskType.TASK_TYPE_R3_CIRCLE_FORCE;
    case 5:
    case "TASK_TYPE_R3_LINEAR_FORCE":
      return ConstrainedManipulationCommand_Request_TaskType.TASK_TYPE_R3_LINEAR_FORCE;
    case 6:
    case "TASK_TYPE_HOLD_POSE":
      return ConstrainedManipulationCommand_Request_TaskType.TASK_TYPE_HOLD_POSE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConstrainedManipulationCommand_Request_TaskType.UNRECOGNIZED;
  }
}

export function constrainedManipulationCommand_Request_TaskTypeToJSON(
  object: ConstrainedManipulationCommand_Request_TaskType,
): string {
  switch (object) {
    case ConstrainedManipulationCommand_Request_TaskType.TASK_TYPE_UNKNOWN:
      return "TASK_TYPE_UNKNOWN";
    case ConstrainedManipulationCommand_Request_TaskType.TASK_TYPE_SE3_CIRCLE_FORCE_TORQUE:
      return "TASK_TYPE_SE3_CIRCLE_FORCE_TORQUE";
    case ConstrainedManipulationCommand_Request_TaskType.TASK_TYPE_R3_CIRCLE_EXTRADOF_FORCE:
      return "TASK_TYPE_R3_CIRCLE_EXTRADOF_FORCE";
    case ConstrainedManipulationCommand_Request_TaskType.TASK_TYPE_SE3_ROTATIONAL_TORQUE:
      return "TASK_TYPE_SE3_ROTATIONAL_TORQUE";
    case ConstrainedManipulationCommand_Request_TaskType.TASK_TYPE_R3_CIRCLE_FORCE:
      return "TASK_TYPE_R3_CIRCLE_FORCE";
    case ConstrainedManipulationCommand_Request_TaskType.TASK_TYPE_R3_LINEAR_FORCE:
      return "TASK_TYPE_R3_LINEAR_FORCE";
    case ConstrainedManipulationCommand_Request_TaskType.TASK_TYPE_HOLD_POSE:
      return "TASK_TYPE_HOLD_POSE";
    case ConstrainedManipulationCommand_Request_TaskType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ConstrainedManipulationCommand_Request_ControlMode {
  CONTROL_MODE_UNKNOWN = 0,
  /**
   * CONTROL_MODE_POSITION - Position control mode, either a linear or angular position is specified
   * and constrained manipulation moves to that position with a trapezoidal
   * trajectory that has the max velocity specified in task_speed
   */
  CONTROL_MODE_POSITION = 1,
  /**
   * CONTROL_MODE_VELOCITY - Velocity control mode where constrained manipulation applies forces to
   * maintain the velocity specified in task_speed
   */
  CONTROL_MODE_VELOCITY = 2,
  UNRECOGNIZED = -1,
}

export function constrainedManipulationCommand_Request_ControlModeFromJSON(
  object: any,
): ConstrainedManipulationCommand_Request_ControlMode {
  switch (object) {
    case 0:
    case "CONTROL_MODE_UNKNOWN":
      return ConstrainedManipulationCommand_Request_ControlMode.CONTROL_MODE_UNKNOWN;
    case 1:
    case "CONTROL_MODE_POSITION":
      return ConstrainedManipulationCommand_Request_ControlMode.CONTROL_MODE_POSITION;
    case 2:
    case "CONTROL_MODE_VELOCITY":
      return ConstrainedManipulationCommand_Request_ControlMode.CONTROL_MODE_VELOCITY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConstrainedManipulationCommand_Request_ControlMode.UNRECOGNIZED;
  }
}

export function constrainedManipulationCommand_Request_ControlModeToJSON(
  object: ConstrainedManipulationCommand_Request_ControlMode,
): string {
  switch (object) {
    case ConstrainedManipulationCommand_Request_ControlMode.CONTROL_MODE_UNKNOWN:
      return "CONTROL_MODE_UNKNOWN";
    case ConstrainedManipulationCommand_Request_ControlMode.CONTROL_MODE_POSITION:
      return "CONTROL_MODE_POSITION";
    case ConstrainedManipulationCommand_Request_ControlMode.CONTROL_MODE_VELOCITY:
      return "CONTROL_MODE_VELOCITY";
    case ConstrainedManipulationCommand_Request_ControlMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ConstrainedManipulationCommand_Feedback {
  status: ConstrainedManipulationCommand_Feedback_Status;
  /** Desired wrench in odom world frame, applied at hand frame origin */
  desiredWrenchOdomFrame:
    | Wrench
    | undefined;
  /**
   * A boolean signal indicating constrained manipulation has seen
   * enough motion to estimate the constraint and that the wrench
   * is being applied along the estimated directions.
   */
  estimationActivated: boolean | undefined;
}

export enum ConstrainedManipulationCommand_Feedback_Status {
  /** STATUS_UNKNOWN - STATUS_UNKNOWN should never be used. If used, an internal error has happened. */
  STATUS_UNKNOWN = 0,
  /** STATUS_RUNNING - Constrained manipulation is working as expected */
  STATUS_RUNNING = 1,
  /**
   * STATUS_ARM_IS_STUCK - Arm is stuck, either force is being applied in a direction
   * where the affordance can't move or not enough force is applied
   */
  STATUS_ARM_IS_STUCK = 2,
  /**
   * STATUS_GRASP_IS_LOST - The grasp was lost. In this situation, constrained manipulation
   * will stop applying force, and will hold the last position.
   */
  STATUS_GRASP_IS_LOST = 3,
  UNRECOGNIZED = -1,
}

export function constrainedManipulationCommand_Feedback_StatusFromJSON(
  object: any,
): ConstrainedManipulationCommand_Feedback_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return ConstrainedManipulationCommand_Feedback_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_RUNNING":
      return ConstrainedManipulationCommand_Feedback_Status.STATUS_RUNNING;
    case 2:
    case "STATUS_ARM_IS_STUCK":
      return ConstrainedManipulationCommand_Feedback_Status.STATUS_ARM_IS_STUCK;
    case 3:
    case "STATUS_GRASP_IS_LOST":
      return ConstrainedManipulationCommand_Feedback_Status.STATUS_GRASP_IS_LOST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConstrainedManipulationCommand_Feedback_Status.UNRECOGNIZED;
  }
}

export function constrainedManipulationCommand_Feedback_StatusToJSON(
  object: ConstrainedManipulationCommand_Feedback_Status,
): string {
  switch (object) {
    case ConstrainedManipulationCommand_Feedback_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case ConstrainedManipulationCommand_Feedback_Status.STATUS_RUNNING:
      return "STATUS_RUNNING";
    case ConstrainedManipulationCommand_Feedback_Status.STATUS_ARM_IS_STUCK:
      return "STATUS_ARM_IS_STUCK";
    case ConstrainedManipulationCommand_Feedback_Status.STATUS_GRASP_IS_LOST:
      return "STATUS_GRASP_IS_LOST";
    case ConstrainedManipulationCommand_Feedback_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface JointCommand {
}

/** Empty message, no paramaters required to activate. */
export interface JointCommand_Request {
}

export interface JointCommand_Feedback {
  status: JointCommand_Feedback_Status;
  /** Number of UpdateRequest messages received through the stream */
  numMessagesReceived: number;
}

export enum JointCommand_Feedback_Status {
  STATUS_UNKNOWN = 0,
  /** STATUS_ACTIVE - Command is still active and processing incoming joint requests */
  STATUS_ACTIVE = 1,
  /** STATUS_ERROR - An error has occurred and joint requests are no longer being processed */
  STATUS_ERROR = 2,
  UNRECOGNIZED = -1,
}

export function jointCommand_Feedback_StatusFromJSON(object: any): JointCommand_Feedback_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return JointCommand_Feedback_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_ACTIVE":
      return JointCommand_Feedback_Status.STATUS_ACTIVE;
    case 2:
    case "STATUS_ERROR":
      return JointCommand_Feedback_Status.STATUS_ERROR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return JointCommand_Feedback_Status.UNRECOGNIZED;
  }
}

export function jointCommand_Feedback_StatusToJSON(object: JointCommand_Feedback_Status): string {
  switch (object) {
    case JointCommand_Feedback_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case JointCommand_Feedback_Status.STATUS_ACTIVE:
      return "STATUS_ACTIVE";
    case JointCommand_Feedback_Status.STATUS_ERROR:
      return "STATUS_ERROR";
    case JointCommand_Feedback_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface JointCommand_UpdateRequest {
  /**
   * The timestamp (in robot time) when the command will stop executing. This is a
   * required field and used to prevent runaway commands.
   */
  endTime:
    | Date
    | undefined;
  /**
   * (Optional) joint trajectory reference time. See extrapolation_time for detailed
   * explanation. If unspecified, this will default to the time the command is received.
   * If the time is in the future, no extrapolation will be performed until that time
   * (extrapolation never goes backwards in time)
   */
  referenceTime:
    | Date
    | undefined;
  /**
   * (Optional) joint trajectory extrapolation time. If specified, the robot will extrapolate
   * desired position based on desired velocity, starting at reference_time for at most
   * extrapolation_duration (or until end_time, whichever is sooner)
   */
  extrapolationDuration:
    | Duration
    | undefined;
  /** Commanded joint details */
  position: number[];
  velocity: number[];
  load: number[];
  /**
   * Gains are required to be specified on the first message. After that can be optionally
   * updated by filling out the gains message again. Partial updates of gains are not
   * supported, a full gain set must be specified each time.
   */
  gains:
    | JointCommand_UpdateRequest_Gains
    | undefined;
  /**
   * user_command_key is optional, but it can be used for tracking when commands take effect
   * on the robot and calculating latencies. Avoid using 0
   */
  userCommandKey: number;
  /**
   * (Optional) Joint velocity safety limit. Possibly useful during initial development or
   * gain tuning. If the magnitude of any joint velocity passes the threshold the robot will
   * trigger a behavior fault and go into a safety state. Client must power down the robot or
   * clear the behavior fault via the Robot Command Service. Values less than or equal to 0
   * will be considered invalid and must be sent in every UpdateRequest for use.
   */
  velocitySafetyLimit: number | undefined;
}

export interface JointCommand_UpdateRequest_Gains {
  /** position error proportional coefficient */
  kQP: number[];
  /** velocity error proportional coefficient */
  kQdP: number[];
}

function createBaseRobotCommandFeedbackStatus(): RobotCommandFeedbackStatus {
  return {};
}

export const RobotCommandFeedbackStatus = {
  encode(_: RobotCommandFeedbackStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RobotCommandFeedbackStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotCommandFeedbackStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RobotCommandFeedbackStatus {
    return {};
  },

  toJSON(_: RobotCommandFeedbackStatus): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RobotCommandFeedbackStatus>, I>>(base?: I): RobotCommandFeedbackStatus {
    return RobotCommandFeedbackStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RobotCommandFeedbackStatus>, I>>(_: I): RobotCommandFeedbackStatus {
    const message = createBaseRobotCommandFeedbackStatus();
    return message;
  },
};

function createBaseBatteryChangePoseCommand(): BatteryChangePoseCommand {
  return {};
}

export const BatteryChangePoseCommand = {
  encode(_: BatteryChangePoseCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BatteryChangePoseCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatteryChangePoseCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): BatteryChangePoseCommand {
    return {};
  },

  toJSON(_: BatteryChangePoseCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<BatteryChangePoseCommand>, I>>(base?: I): BatteryChangePoseCommand {
    return BatteryChangePoseCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BatteryChangePoseCommand>, I>>(_: I): BatteryChangePoseCommand {
    const message = createBaseBatteryChangePoseCommand();
    return message;
  },
};

function createBaseBatteryChangePoseCommand_Request(): BatteryChangePoseCommand_Request {
  return { directionHint: 0 };
}

export const BatteryChangePoseCommand_Request = {
  encode(message: BatteryChangePoseCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.directionHint !== 0) {
      writer.uint32(8).int32(message.directionHint);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BatteryChangePoseCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatteryChangePoseCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.directionHint = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatteryChangePoseCommand_Request {
    return {
      directionHint: isSet(object.directionHint)
        ? batteryChangePoseCommand_Request_DirectionHintFromJSON(object.directionHint)
        : 0,
    };
  },

  toJSON(message: BatteryChangePoseCommand_Request): unknown {
    const obj: any = {};
    if (message.directionHint !== 0) {
      obj.directionHint = batteryChangePoseCommand_Request_DirectionHintToJSON(message.directionHint);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BatteryChangePoseCommand_Request>, I>>(
    base?: I,
  ): BatteryChangePoseCommand_Request {
    return BatteryChangePoseCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BatteryChangePoseCommand_Request>, I>>(
    object: I,
  ): BatteryChangePoseCommand_Request {
    const message = createBaseBatteryChangePoseCommand_Request();
    message.directionHint = object.directionHint ?? 0;
    return message;
  },
};

function createBaseBatteryChangePoseCommand_Feedback(): BatteryChangePoseCommand_Feedback {
  return { status: 0 };
}

export const BatteryChangePoseCommand_Feedback = {
  encode(message: BatteryChangePoseCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BatteryChangePoseCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatteryChangePoseCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatteryChangePoseCommand_Feedback {
    return { status: isSet(object.status) ? batteryChangePoseCommand_Feedback_StatusFromJSON(object.status) : 0 };
  },

  toJSON(message: BatteryChangePoseCommand_Feedback): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = batteryChangePoseCommand_Feedback_StatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BatteryChangePoseCommand_Feedback>, I>>(
    base?: I,
  ): BatteryChangePoseCommand_Feedback {
    return BatteryChangePoseCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BatteryChangePoseCommand_Feedback>, I>>(
    object: I,
  ): BatteryChangePoseCommand_Feedback {
    const message = createBaseBatteryChangePoseCommand_Feedback();
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseSelfRightCommand(): SelfRightCommand {
  return {};
}

export const SelfRightCommand = {
  encode(_: SelfRightCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelfRightCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelfRightCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SelfRightCommand {
    return {};
  },

  toJSON(_: SelfRightCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SelfRightCommand>, I>>(base?: I): SelfRightCommand {
    return SelfRightCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SelfRightCommand>, I>>(_: I): SelfRightCommand {
    const message = createBaseSelfRightCommand();
    return message;
  },
};

function createBaseSelfRightCommand_Request(): SelfRightCommand_Request {
  return {};
}

export const SelfRightCommand_Request = {
  encode(_: SelfRightCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelfRightCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelfRightCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SelfRightCommand_Request {
    return {};
  },

  toJSON(_: SelfRightCommand_Request): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SelfRightCommand_Request>, I>>(base?: I): SelfRightCommand_Request {
    return SelfRightCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SelfRightCommand_Request>, I>>(_: I): SelfRightCommand_Request {
    const message = createBaseSelfRightCommand_Request();
    return message;
  },
};

function createBaseSelfRightCommand_Feedback(): SelfRightCommand_Feedback {
  return { status: 0 };
}

export const SelfRightCommand_Feedback = {
  encode(message: SelfRightCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelfRightCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelfRightCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SelfRightCommand_Feedback {
    return { status: isSet(object.status) ? selfRightCommand_Feedback_StatusFromJSON(object.status) : 0 };
  },

  toJSON(message: SelfRightCommand_Feedback): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = selfRightCommand_Feedback_StatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SelfRightCommand_Feedback>, I>>(base?: I): SelfRightCommand_Feedback {
    return SelfRightCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SelfRightCommand_Feedback>, I>>(object: I): SelfRightCommand_Feedback {
    const message = createBaseSelfRightCommand_Feedback();
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseStopCommand(): StopCommand {
  return {};
}

export const StopCommand = {
  encode(_: StopCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StopCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStopCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StopCommand {
    return {};
  },

  toJSON(_: StopCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StopCommand>, I>>(base?: I): StopCommand {
    return StopCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StopCommand>, I>>(_: I): StopCommand {
    const message = createBaseStopCommand();
    return message;
  },
};

function createBaseStopCommand_Request(): StopCommand_Request {
  return {};
}

export const StopCommand_Request = {
  encode(_: StopCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StopCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStopCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StopCommand_Request {
    return {};
  },

  toJSON(_: StopCommand_Request): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StopCommand_Request>, I>>(base?: I): StopCommand_Request {
    return StopCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StopCommand_Request>, I>>(_: I): StopCommand_Request {
    const message = createBaseStopCommand_Request();
    return message;
  },
};

function createBaseStopCommand_Feedback(): StopCommand_Feedback {
  return {};
}

export const StopCommand_Feedback = {
  encode(_: StopCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StopCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStopCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StopCommand_Feedback {
    return {};
  },

  toJSON(_: StopCommand_Feedback): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StopCommand_Feedback>, I>>(base?: I): StopCommand_Feedback {
    return StopCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StopCommand_Feedback>, I>>(_: I): StopCommand_Feedback {
    const message = createBaseStopCommand_Feedback();
    return message;
  },
};

function createBaseFreezeCommand(): FreezeCommand {
  return {};
}

export const FreezeCommand = {
  encode(_: FreezeCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FreezeCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFreezeCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FreezeCommand {
    return {};
  },

  toJSON(_: FreezeCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FreezeCommand>, I>>(base?: I): FreezeCommand {
    return FreezeCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FreezeCommand>, I>>(_: I): FreezeCommand {
    const message = createBaseFreezeCommand();
    return message;
  },
};

function createBaseFreezeCommand_Request(): FreezeCommand_Request {
  return {};
}

export const FreezeCommand_Request = {
  encode(_: FreezeCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FreezeCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFreezeCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FreezeCommand_Request {
    return {};
  },

  toJSON(_: FreezeCommand_Request): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FreezeCommand_Request>, I>>(base?: I): FreezeCommand_Request {
    return FreezeCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FreezeCommand_Request>, I>>(_: I): FreezeCommand_Request {
    const message = createBaseFreezeCommand_Request();
    return message;
  },
};

function createBaseFreezeCommand_Feedback(): FreezeCommand_Feedback {
  return {};
}

export const FreezeCommand_Feedback = {
  encode(_: FreezeCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FreezeCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFreezeCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FreezeCommand_Feedback {
    return {};
  },

  toJSON(_: FreezeCommand_Feedback): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FreezeCommand_Feedback>, I>>(base?: I): FreezeCommand_Feedback {
    return FreezeCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FreezeCommand_Feedback>, I>>(_: I): FreezeCommand_Feedback {
    const message = createBaseFreezeCommand_Feedback();
    return message;
  },
};

function createBaseSafePowerOffCommand(): SafePowerOffCommand {
  return {};
}

export const SafePowerOffCommand = {
  encode(_: SafePowerOffCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SafePowerOffCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSafePowerOffCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SafePowerOffCommand {
    return {};
  },

  toJSON(_: SafePowerOffCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SafePowerOffCommand>, I>>(base?: I): SafePowerOffCommand {
    return SafePowerOffCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SafePowerOffCommand>, I>>(_: I): SafePowerOffCommand {
    const message = createBaseSafePowerOffCommand();
    return message;
  },
};

function createBaseSafePowerOffCommand_Request(): SafePowerOffCommand_Request {
  return { unsafeAction: 0 };
}

export const SafePowerOffCommand_Request = {
  encode(message: SafePowerOffCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.unsafeAction !== 0) {
      writer.uint32(8).int32(message.unsafeAction);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SafePowerOffCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSafePowerOffCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.unsafeAction = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SafePowerOffCommand_Request {
    return {
      unsafeAction: isSet(object.unsafeAction)
        ? safePowerOffCommand_Request_UnsafeActionFromJSON(object.unsafeAction)
        : 0,
    };
  },

  toJSON(message: SafePowerOffCommand_Request): unknown {
    const obj: any = {};
    if (message.unsafeAction !== 0) {
      obj.unsafeAction = safePowerOffCommand_Request_UnsafeActionToJSON(message.unsafeAction);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SafePowerOffCommand_Request>, I>>(base?: I): SafePowerOffCommand_Request {
    return SafePowerOffCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SafePowerOffCommand_Request>, I>>(object: I): SafePowerOffCommand_Request {
    const message = createBaseSafePowerOffCommand_Request();
    message.unsafeAction = object.unsafeAction ?? 0;
    return message;
  },
};

function createBaseSafePowerOffCommand_Feedback(): SafePowerOffCommand_Feedback {
  return { status: 0 };
}

export const SafePowerOffCommand_Feedback = {
  encode(message: SafePowerOffCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SafePowerOffCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSafePowerOffCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SafePowerOffCommand_Feedback {
    return { status: isSet(object.status) ? safePowerOffCommand_Feedback_StatusFromJSON(object.status) : 0 };
  },

  toJSON(message: SafePowerOffCommand_Feedback): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = safePowerOffCommand_Feedback_StatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SafePowerOffCommand_Feedback>, I>>(base?: I): SafePowerOffCommand_Feedback {
    return SafePowerOffCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SafePowerOffCommand_Feedback>, I>>(object: I): SafePowerOffCommand_Feedback {
    const message = createBaseSafePowerOffCommand_Feedback();
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseSE2TrajectoryCommand(): SE2TrajectoryCommand {
  return {};
}

export const SE2TrajectoryCommand = {
  encode(_: SE2TrajectoryCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SE2TrajectoryCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSE2TrajectoryCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SE2TrajectoryCommand {
    return {};
  },

  toJSON(_: SE2TrajectoryCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SE2TrajectoryCommand>, I>>(base?: I): SE2TrajectoryCommand {
    return SE2TrajectoryCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SE2TrajectoryCommand>, I>>(_: I): SE2TrajectoryCommand {
    const message = createBaseSE2TrajectoryCommand();
    return message;
  },
};

function createBaseSE2TrajectoryCommand_Request(): SE2TrajectoryCommand_Request {
  return { endTime: undefined, se2FrameName: "", trajectory: undefined };
}

export const SE2TrajectoryCommand_Request = {
  encode(message: SE2TrajectoryCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(10).fork()).ldelim();
    }
    if (message.se2FrameName !== "") {
      writer.uint32(26).string(message.se2FrameName);
    }
    if (message.trajectory !== undefined) {
      SE2Trajectory.encode(message.trajectory, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SE2TrajectoryCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSE2TrajectoryCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.se2FrameName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.trajectory = SE2Trajectory.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SE2TrajectoryCommand_Request {
    return {
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      se2FrameName: isSet(object.se2FrameName) ? globalThis.String(object.se2FrameName) : "",
      trajectory: isSet(object.trajectory) ? SE2Trajectory.fromJSON(object.trajectory) : undefined,
    };
  },

  toJSON(message: SE2TrajectoryCommand_Request): unknown {
    const obj: any = {};
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    if (message.se2FrameName !== "") {
      obj.se2FrameName = message.se2FrameName;
    }
    if (message.trajectory !== undefined) {
      obj.trajectory = SE2Trajectory.toJSON(message.trajectory);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SE2TrajectoryCommand_Request>, I>>(base?: I): SE2TrajectoryCommand_Request {
    return SE2TrajectoryCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SE2TrajectoryCommand_Request>, I>>(object: I): SE2TrajectoryCommand_Request {
    const message = createBaseSE2TrajectoryCommand_Request();
    message.endTime = object.endTime ?? undefined;
    message.se2FrameName = object.se2FrameName ?? "";
    message.trajectory = (object.trajectory !== undefined && object.trajectory !== null)
      ? SE2Trajectory.fromPartial(object.trajectory)
      : undefined;
    return message;
  },
};

function createBaseSE2TrajectoryCommand_Feedback(): SE2TrajectoryCommand_Feedback {
  return { status: 0, bodyMovementStatus: 0, finalGoalStatus: 0 };
}

export const SE2TrajectoryCommand_Feedback = {
  encode(message: SE2TrajectoryCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.bodyMovementStatus !== 0) {
      writer.uint32(16).int32(message.bodyMovementStatus);
    }
    if (message.finalGoalStatus !== 0) {
      writer.uint32(40).int32(message.finalGoalStatus);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SE2TrajectoryCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSE2TrajectoryCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.bodyMovementStatus = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.finalGoalStatus = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SE2TrajectoryCommand_Feedback {
    return {
      status: isSet(object.status) ? sE2TrajectoryCommand_Feedback_StatusFromJSON(object.status) : 0,
      bodyMovementStatus: isSet(object.bodyMovementStatus)
        ? sE2TrajectoryCommand_Feedback_BodyMovementStatusFromJSON(object.bodyMovementStatus)
        : 0,
      finalGoalStatus: isSet(object.finalGoalStatus)
        ? sE2TrajectoryCommand_Feedback_FinalGoalStatusFromJSON(object.finalGoalStatus)
        : 0,
    };
  },

  toJSON(message: SE2TrajectoryCommand_Feedback): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = sE2TrajectoryCommand_Feedback_StatusToJSON(message.status);
    }
    if (message.bodyMovementStatus !== 0) {
      obj.bodyMovementStatus = sE2TrajectoryCommand_Feedback_BodyMovementStatusToJSON(message.bodyMovementStatus);
    }
    if (message.finalGoalStatus !== 0) {
      obj.finalGoalStatus = sE2TrajectoryCommand_Feedback_FinalGoalStatusToJSON(message.finalGoalStatus);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SE2TrajectoryCommand_Feedback>, I>>(base?: I): SE2TrajectoryCommand_Feedback {
    return SE2TrajectoryCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SE2TrajectoryCommand_Feedback>, I>>(
    object: I,
  ): SE2TrajectoryCommand_Feedback {
    const message = createBaseSE2TrajectoryCommand_Feedback();
    message.status = object.status ?? 0;
    message.bodyMovementStatus = object.bodyMovementStatus ?? 0;
    message.finalGoalStatus = object.finalGoalStatus ?? 0;
    return message;
  },
};

function createBaseSE2VelocityCommand(): SE2VelocityCommand {
  return {};
}

export const SE2VelocityCommand = {
  encode(_: SE2VelocityCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SE2VelocityCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSE2VelocityCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SE2VelocityCommand {
    return {};
  },

  toJSON(_: SE2VelocityCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SE2VelocityCommand>, I>>(base?: I): SE2VelocityCommand {
    return SE2VelocityCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SE2VelocityCommand>, I>>(_: I): SE2VelocityCommand {
    const message = createBaseSE2VelocityCommand();
    return message;
  },
};

function createBaseSE2VelocityCommand_Request(): SE2VelocityCommand_Request {
  return { endTime: undefined, se2FrameName: "", velocity: undefined, slewRateLimit: undefined };
}

export const SE2VelocityCommand_Request = {
  encode(message: SE2VelocityCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(10).fork()).ldelim();
    }
    if (message.se2FrameName !== "") {
      writer.uint32(42).string(message.se2FrameName);
    }
    if (message.velocity !== undefined) {
      SE2Velocity.encode(message.velocity, writer.uint32(18).fork()).ldelim();
    }
    if (message.slewRateLimit !== undefined) {
      SE2Velocity.encode(message.slewRateLimit, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SE2VelocityCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSE2VelocityCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.se2FrameName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.velocity = SE2Velocity.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.slewRateLimit = SE2Velocity.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SE2VelocityCommand_Request {
    return {
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      se2FrameName: isSet(object.se2FrameName) ? globalThis.String(object.se2FrameName) : "",
      velocity: isSet(object.velocity) ? SE2Velocity.fromJSON(object.velocity) : undefined,
      slewRateLimit: isSet(object.slewRateLimit) ? SE2Velocity.fromJSON(object.slewRateLimit) : undefined,
    };
  },

  toJSON(message: SE2VelocityCommand_Request): unknown {
    const obj: any = {};
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    if (message.se2FrameName !== "") {
      obj.se2FrameName = message.se2FrameName;
    }
    if (message.velocity !== undefined) {
      obj.velocity = SE2Velocity.toJSON(message.velocity);
    }
    if (message.slewRateLimit !== undefined) {
      obj.slewRateLimit = SE2Velocity.toJSON(message.slewRateLimit);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SE2VelocityCommand_Request>, I>>(base?: I): SE2VelocityCommand_Request {
    return SE2VelocityCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SE2VelocityCommand_Request>, I>>(object: I): SE2VelocityCommand_Request {
    const message = createBaseSE2VelocityCommand_Request();
    message.endTime = object.endTime ?? undefined;
    message.se2FrameName = object.se2FrameName ?? "";
    message.velocity = (object.velocity !== undefined && object.velocity !== null)
      ? SE2Velocity.fromPartial(object.velocity)
      : undefined;
    message.slewRateLimit = (object.slewRateLimit !== undefined && object.slewRateLimit !== null)
      ? SE2Velocity.fromPartial(object.slewRateLimit)
      : undefined;
    return message;
  },
};

function createBaseSE2VelocityCommand_Feedback(): SE2VelocityCommand_Feedback {
  return {};
}

export const SE2VelocityCommand_Feedback = {
  encode(_: SE2VelocityCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SE2VelocityCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSE2VelocityCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SE2VelocityCommand_Feedback {
    return {};
  },

  toJSON(_: SE2VelocityCommand_Feedback): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SE2VelocityCommand_Feedback>, I>>(base?: I): SE2VelocityCommand_Feedback {
    return SE2VelocityCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SE2VelocityCommand_Feedback>, I>>(_: I): SE2VelocityCommand_Feedback {
    const message = createBaseSE2VelocityCommand_Feedback();
    return message;
  },
};

function createBaseSitCommand(): SitCommand {
  return {};
}

export const SitCommand = {
  encode(_: SitCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SitCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSitCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SitCommand {
    return {};
  },

  toJSON(_: SitCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SitCommand>, I>>(base?: I): SitCommand {
    return SitCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SitCommand>, I>>(_: I): SitCommand {
    const message = createBaseSitCommand();
    return message;
  },
};

function createBaseSitCommand_Request(): SitCommand_Request {
  return {};
}

export const SitCommand_Request = {
  encode(_: SitCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SitCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSitCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SitCommand_Request {
    return {};
  },

  toJSON(_: SitCommand_Request): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SitCommand_Request>, I>>(base?: I): SitCommand_Request {
    return SitCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SitCommand_Request>, I>>(_: I): SitCommand_Request {
    const message = createBaseSitCommand_Request();
    return message;
  },
};

function createBaseSitCommand_Feedback(): SitCommand_Feedback {
  return { status: 0 };
}

export const SitCommand_Feedback = {
  encode(message: SitCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SitCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSitCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SitCommand_Feedback {
    return { status: isSet(object.status) ? sitCommand_Feedback_StatusFromJSON(object.status) : 0 };
  },

  toJSON(message: SitCommand_Feedback): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = sitCommand_Feedback_StatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SitCommand_Feedback>, I>>(base?: I): SitCommand_Feedback {
    return SitCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SitCommand_Feedback>, I>>(object: I): SitCommand_Feedback {
    const message = createBaseSitCommand_Feedback();
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseStandCommand(): StandCommand {
  return {};
}

export const StandCommand = {
  encode(_: StandCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StandCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStandCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StandCommand {
    return {};
  },

  toJSON(_: StandCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StandCommand>, I>>(base?: I): StandCommand {
    return StandCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StandCommand>, I>>(_: I): StandCommand {
    const message = createBaseStandCommand();
    return message;
  },
};

function createBaseStandCommand_Request(): StandCommand_Request {
  return {};
}

export const StandCommand_Request = {
  encode(_: StandCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StandCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStandCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StandCommand_Request {
    return {};
  },

  toJSON(_: StandCommand_Request): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StandCommand_Request>, I>>(base?: I): StandCommand_Request {
    return StandCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StandCommand_Request>, I>>(_: I): StandCommand_Request {
    const message = createBaseStandCommand_Request();
    return message;
  },
};

function createBaseStandCommand_Feedback(): StandCommand_Feedback {
  return { status: 0, standingState: 0 };
}

export const StandCommand_Feedback = {
  encode(message: StandCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.standingState !== 0) {
      writer.uint32(16).int32(message.standingState);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StandCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStandCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.standingState = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StandCommand_Feedback {
    return {
      status: isSet(object.status) ? standCommand_Feedback_StatusFromJSON(object.status) : 0,
      standingState: isSet(object.standingState)
        ? standCommand_Feedback_StandingStateFromJSON(object.standingState)
        : 0,
    };
  },

  toJSON(message: StandCommand_Feedback): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = standCommand_Feedback_StatusToJSON(message.status);
    }
    if (message.standingState !== 0) {
      obj.standingState = standCommand_Feedback_StandingStateToJSON(message.standingState);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StandCommand_Feedback>, I>>(base?: I): StandCommand_Feedback {
    return StandCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StandCommand_Feedback>, I>>(object: I): StandCommand_Feedback {
    const message = createBaseStandCommand_Feedback();
    message.status = object.status ?? 0;
    message.standingState = object.standingState ?? 0;
    return message;
  },
};

function createBaseStanceCommand(): StanceCommand {
  return {};
}

export const StanceCommand = {
  encode(_: StanceCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StanceCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStanceCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StanceCommand {
    return {};
  },

  toJSON(_: StanceCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StanceCommand>, I>>(base?: I): StanceCommand {
    return StanceCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StanceCommand>, I>>(_: I): StanceCommand {
    const message = createBaseStanceCommand();
    return message;
  },
};

function createBaseStanceCommand_Request(): StanceCommand_Request {
  return { endTime: undefined, stance: undefined };
}

export const StanceCommand_Request = {
  encode(message: StanceCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(10).fork()).ldelim();
    }
    if (message.stance !== undefined) {
      Stance.encode(message.stance, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StanceCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStanceCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.stance = Stance.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StanceCommand_Request {
    return {
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      stance: isSet(object.stance) ? Stance.fromJSON(object.stance) : undefined,
    };
  },

  toJSON(message: StanceCommand_Request): unknown {
    const obj: any = {};
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    if (message.stance !== undefined) {
      obj.stance = Stance.toJSON(message.stance);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StanceCommand_Request>, I>>(base?: I): StanceCommand_Request {
    return StanceCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StanceCommand_Request>, I>>(object: I): StanceCommand_Request {
    const message = createBaseStanceCommand_Request();
    message.endTime = object.endTime ?? undefined;
    message.stance = (object.stance !== undefined && object.stance !== null)
      ? Stance.fromPartial(object.stance)
      : undefined;
    return message;
  },
};

function createBaseStanceCommand_Feedback(): StanceCommand_Feedback {
  return { status: 0 };
}

export const StanceCommand_Feedback = {
  encode(message: StanceCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StanceCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStanceCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StanceCommand_Feedback {
    return { status: isSet(object.status) ? stanceCommand_Feedback_StatusFromJSON(object.status) : 0 };
  },

  toJSON(message: StanceCommand_Feedback): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = stanceCommand_Feedback_StatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StanceCommand_Feedback>, I>>(base?: I): StanceCommand_Feedback {
    return StanceCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StanceCommand_Feedback>, I>>(object: I): StanceCommand_Feedback {
    const message = createBaseStanceCommand_Feedback();
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseStance(): Stance {
  return { se2FrameName: "", footPositions: {}, accuracy: 0 };
}

export const Stance = {
  encode(message: Stance, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.se2FrameName !== "") {
      writer.uint32(26).string(message.se2FrameName);
    }
    Object.entries(message.footPositions).forEach(([key, value]) => {
      Stance_FootPositionsEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    if (message.accuracy !== 0) {
      writer.uint32(37).float(message.accuracy);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Stance {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.se2FrameName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = Stance_FootPositionsEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.footPositions[entry2.key] = entry2.value;
          }
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.accuracy = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Stance {
    return {
      se2FrameName: isSet(object.se2FrameName) ? globalThis.String(object.se2FrameName) : "",
      footPositions: isObject(object.footPositions)
        ? Object.entries(object.footPositions).reduce<{ [key: string]: Vec2 }>((acc, [key, value]) => {
          acc[key] = Vec2.fromJSON(value);
          return acc;
        }, {})
        : {},
      accuracy: isSet(object.accuracy) ? globalThis.Number(object.accuracy) : 0,
    };
  },

  toJSON(message: Stance): unknown {
    const obj: any = {};
    if (message.se2FrameName !== "") {
      obj.se2FrameName = message.se2FrameName;
    }
    if (message.footPositions) {
      const entries = Object.entries(message.footPositions);
      if (entries.length > 0) {
        obj.footPositions = {};
        entries.forEach(([k, v]) => {
          obj.footPositions[k] = Vec2.toJSON(v);
        });
      }
    }
    if (message.accuracy !== 0) {
      obj.accuracy = message.accuracy;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Stance>, I>>(base?: I): Stance {
    return Stance.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Stance>, I>>(object: I): Stance {
    const message = createBaseStance();
    message.se2FrameName = object.se2FrameName ?? "";
    message.footPositions = Object.entries(object.footPositions ?? {}).reduce<{ [key: string]: Vec2 }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Vec2.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.accuracy = object.accuracy ?? 0;
    return message;
  },
};

function createBaseStance_FootPositionsEntry(): Stance_FootPositionsEntry {
  return { key: "", value: undefined };
}

export const Stance_FootPositionsEntry = {
  encode(message: Stance_FootPositionsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Vec2.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Stance_FootPositionsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStance_FootPositionsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Vec2.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Stance_FootPositionsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? Vec2.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: Stance_FootPositionsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Vec2.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Stance_FootPositionsEntry>, I>>(base?: I): Stance_FootPositionsEntry {
    return Stance_FootPositionsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Stance_FootPositionsEntry>, I>>(object: I): Stance_FootPositionsEntry {
    const message = createBaseStance_FootPositionsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null) ? Vec2.fromPartial(object.value) : undefined;
    return message;
  },
};

function createBaseFollowArmCommand(): FollowArmCommand {
  return {};
}

export const FollowArmCommand = {
  encode(_: FollowArmCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FollowArmCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFollowArmCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FollowArmCommand {
    return {};
  },

  toJSON(_: FollowArmCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FollowArmCommand>, I>>(base?: I): FollowArmCommand {
    return FollowArmCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FollowArmCommand>, I>>(_: I): FollowArmCommand {
    const message = createBaseFollowArmCommand();
    return message;
  },
};

function createBaseFollowArmCommand_Request(): FollowArmCommand_Request {
  return { bodyOffsetFromHand: undefined, disableWalking: false };
}

export const FollowArmCommand_Request = {
  encode(message: FollowArmCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bodyOffsetFromHand !== undefined) {
      Vec3.encode(message.bodyOffsetFromHand, writer.uint32(10).fork()).ldelim();
    }
    if (message.disableWalking !== false) {
      writer.uint32(16).bool(message.disableWalking);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FollowArmCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFollowArmCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bodyOffsetFromHand = Vec3.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.disableWalking = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FollowArmCommand_Request {
    return {
      bodyOffsetFromHand: isSet(object.bodyOffsetFromHand) ? Vec3.fromJSON(object.bodyOffsetFromHand) : undefined,
      disableWalking: isSet(object.disableWalking) ? globalThis.Boolean(object.disableWalking) : false,
    };
  },

  toJSON(message: FollowArmCommand_Request): unknown {
    const obj: any = {};
    if (message.bodyOffsetFromHand !== undefined) {
      obj.bodyOffsetFromHand = Vec3.toJSON(message.bodyOffsetFromHand);
    }
    if (message.disableWalking !== false) {
      obj.disableWalking = message.disableWalking;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FollowArmCommand_Request>, I>>(base?: I): FollowArmCommand_Request {
    return FollowArmCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FollowArmCommand_Request>, I>>(object: I): FollowArmCommand_Request {
    const message = createBaseFollowArmCommand_Request();
    message.bodyOffsetFromHand = (object.bodyOffsetFromHand !== undefined && object.bodyOffsetFromHand !== null)
      ? Vec3.fromPartial(object.bodyOffsetFromHand)
      : undefined;
    message.disableWalking = object.disableWalking ?? false;
    return message;
  },
};

function createBaseFollowArmCommand_Feedback(): FollowArmCommand_Feedback {
  return {};
}

export const FollowArmCommand_Feedback = {
  encode(_: FollowArmCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FollowArmCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFollowArmCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FollowArmCommand_Feedback {
    return {};
  },

  toJSON(_: FollowArmCommand_Feedback): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FollowArmCommand_Feedback>, I>>(base?: I): FollowArmCommand_Feedback {
    return FollowArmCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FollowArmCommand_Feedback>, I>>(_: I): FollowArmCommand_Feedback {
    const message = createBaseFollowArmCommand_Feedback();
    return message;
  },
};

function createBaseArmDragCommand(): ArmDragCommand {
  return {};
}

export const ArmDragCommand = {
  encode(_: ArmDragCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmDragCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmDragCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ArmDragCommand {
    return {};
  },

  toJSON(_: ArmDragCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmDragCommand>, I>>(base?: I): ArmDragCommand {
    return ArmDragCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmDragCommand>, I>>(_: I): ArmDragCommand {
    const message = createBaseArmDragCommand();
    return message;
  },
};

function createBaseArmDragCommand_Request(): ArmDragCommand_Request {
  return {};
}

export const ArmDragCommand_Request = {
  encode(_: ArmDragCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmDragCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmDragCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ArmDragCommand_Request {
    return {};
  },

  toJSON(_: ArmDragCommand_Request): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmDragCommand_Request>, I>>(base?: I): ArmDragCommand_Request {
    return ArmDragCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmDragCommand_Request>, I>>(_: I): ArmDragCommand_Request {
    const message = createBaseArmDragCommand_Request();
    return message;
  },
};

function createBaseArmDragCommand_Feedback(): ArmDragCommand_Feedback {
  return { status: 0 };
}

export const ArmDragCommand_Feedback = {
  encode(message: ArmDragCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmDragCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmDragCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmDragCommand_Feedback {
    return { status: isSet(object.status) ? armDragCommand_Feedback_StatusFromJSON(object.status) : 0 };
  },

  toJSON(message: ArmDragCommand_Feedback): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = armDragCommand_Feedback_StatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmDragCommand_Feedback>, I>>(base?: I): ArmDragCommand_Feedback {
    return ArmDragCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmDragCommand_Feedback>, I>>(object: I): ArmDragCommand_Feedback {
    const message = createBaseArmDragCommand_Feedback();
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseConstrainedManipulationCommand(): ConstrainedManipulationCommand {
  return {};
}

export const ConstrainedManipulationCommand = {
  encode(_: ConstrainedManipulationCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConstrainedManipulationCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConstrainedManipulationCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ConstrainedManipulationCommand {
    return {};
  },

  toJSON(_: ConstrainedManipulationCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ConstrainedManipulationCommand>, I>>(base?: I): ConstrainedManipulationCommand {
    return ConstrainedManipulationCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConstrainedManipulationCommand>, I>>(_: I): ConstrainedManipulationCommand {
    const message = createBaseConstrainedManipulationCommand();
    return message;
  },
};

function createBaseConstrainedManipulationCommand_Request(): ConstrainedManipulationCommand_Request {
  return {
    frameName: "",
    initWrenchDirectionInFrameName: undefined,
    tangentialSpeed: undefined,
    rotationalSpeed: undefined,
    forceLimit: undefined,
    torqueLimit: undefined,
    taskType: 0,
    endTime: undefined,
    enableRobotLocomotion: undefined,
    controlMode: 0,
    targetLinearPosition: undefined,
    targetAngle: undefined,
    accelLimit: undefined,
    resetEstimator: undefined,
  };
}

export const ConstrainedManipulationCommand_Request = {
  encode(message: ConstrainedManipulationCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.frameName !== "") {
      writer.uint32(10).string(message.frameName);
    }
    if (message.initWrenchDirectionInFrameName !== undefined) {
      Wrench.encode(message.initWrenchDirectionInFrameName, writer.uint32(18).fork()).ldelim();
    }
    if (message.tangentialSpeed !== undefined) {
      writer.uint32(25).double(message.tangentialSpeed);
    }
    if (message.rotationalSpeed !== undefined) {
      writer.uint32(33).double(message.rotationalSpeed);
    }
    if (message.forceLimit !== undefined) {
      DoubleValue.encode({ value: message.forceLimit! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.torqueLimit !== undefined) {
      DoubleValue.encode({ value: message.torqueLimit! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.taskType !== 0) {
      writer.uint32(56).int32(message.taskType);
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(66).fork()).ldelim();
    }
    if (message.enableRobotLocomotion !== undefined) {
      BoolValue.encode({ value: message.enableRobotLocomotion! }, writer.uint32(74).fork()).ldelim();
    }
    if (message.controlMode !== 0) {
      writer.uint32(80).int32(message.controlMode);
    }
    if (message.targetLinearPosition !== undefined) {
      writer.uint32(89).double(message.targetLinearPosition);
    }
    if (message.targetAngle !== undefined) {
      writer.uint32(97).double(message.targetAngle);
    }
    if (message.accelLimit !== undefined) {
      DoubleValue.encode({ value: message.accelLimit! }, writer.uint32(106).fork()).ldelim();
    }
    if (message.resetEstimator !== undefined) {
      BoolValue.encode({ value: message.resetEstimator! }, writer.uint32(114).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConstrainedManipulationCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConstrainedManipulationCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.frameName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.initWrenchDirectionInFrameName = Wrench.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.tangentialSpeed = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.rotationalSpeed = reader.double();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.forceLimit = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.torqueLimit = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.taskType = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.enableRobotLocomotion = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.controlMode = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 89) {
            break;
          }

          message.targetLinearPosition = reader.double();
          continue;
        case 12:
          if (tag !== 97) {
            break;
          }

          message.targetAngle = reader.double();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.accelLimit = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.resetEstimator = BoolValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConstrainedManipulationCommand_Request {
    return {
      frameName: isSet(object.frameName) ? globalThis.String(object.frameName) : "",
      initWrenchDirectionInFrameName: isSet(object.initWrenchDirectionInFrameName)
        ? Wrench.fromJSON(object.initWrenchDirectionInFrameName)
        : undefined,
      tangentialSpeed: isSet(object.tangentialSpeed) ? globalThis.Number(object.tangentialSpeed) : undefined,
      rotationalSpeed: isSet(object.rotationalSpeed) ? globalThis.Number(object.rotationalSpeed) : undefined,
      forceLimit: isSet(object.forceLimit) ? Number(object.forceLimit) : undefined,
      torqueLimit: isSet(object.torqueLimit) ? Number(object.torqueLimit) : undefined,
      taskType: isSet(object.taskType) ? constrainedManipulationCommand_Request_TaskTypeFromJSON(object.taskType) : 0,
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      enableRobotLocomotion: isSet(object.enableRobotLocomotion) ? Boolean(object.enableRobotLocomotion) : undefined,
      controlMode: isSet(object.controlMode)
        ? constrainedManipulationCommand_Request_ControlModeFromJSON(object.controlMode)
        : 0,
      targetLinearPosition: isSet(object.targetLinearPosition)
        ? globalThis.Number(object.targetLinearPosition)
        : undefined,
      targetAngle: isSet(object.targetAngle) ? globalThis.Number(object.targetAngle) : undefined,
      accelLimit: isSet(object.accelLimit) ? Number(object.accelLimit) : undefined,
      resetEstimator: isSet(object.resetEstimator) ? Boolean(object.resetEstimator) : undefined,
    };
  },

  toJSON(message: ConstrainedManipulationCommand_Request): unknown {
    const obj: any = {};
    if (message.frameName !== "") {
      obj.frameName = message.frameName;
    }
    if (message.initWrenchDirectionInFrameName !== undefined) {
      obj.initWrenchDirectionInFrameName = Wrench.toJSON(message.initWrenchDirectionInFrameName);
    }
    if (message.tangentialSpeed !== undefined) {
      obj.tangentialSpeed = message.tangentialSpeed;
    }
    if (message.rotationalSpeed !== undefined) {
      obj.rotationalSpeed = message.rotationalSpeed;
    }
    if (message.forceLimit !== undefined) {
      obj.forceLimit = message.forceLimit;
    }
    if (message.torqueLimit !== undefined) {
      obj.torqueLimit = message.torqueLimit;
    }
    if (message.taskType !== 0) {
      obj.taskType = constrainedManipulationCommand_Request_TaskTypeToJSON(message.taskType);
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    if (message.enableRobotLocomotion !== undefined) {
      obj.enableRobotLocomotion = message.enableRobotLocomotion;
    }
    if (message.controlMode !== 0) {
      obj.controlMode = constrainedManipulationCommand_Request_ControlModeToJSON(message.controlMode);
    }
    if (message.targetLinearPosition !== undefined) {
      obj.targetLinearPosition = message.targetLinearPosition;
    }
    if (message.targetAngle !== undefined) {
      obj.targetAngle = message.targetAngle;
    }
    if (message.accelLimit !== undefined) {
      obj.accelLimit = message.accelLimit;
    }
    if (message.resetEstimator !== undefined) {
      obj.resetEstimator = message.resetEstimator;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConstrainedManipulationCommand_Request>, I>>(
    base?: I,
  ): ConstrainedManipulationCommand_Request {
    return ConstrainedManipulationCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConstrainedManipulationCommand_Request>, I>>(
    object: I,
  ): ConstrainedManipulationCommand_Request {
    const message = createBaseConstrainedManipulationCommand_Request();
    message.frameName = object.frameName ?? "";
    message.initWrenchDirectionInFrameName =
      (object.initWrenchDirectionInFrameName !== undefined && object.initWrenchDirectionInFrameName !== null)
        ? Wrench.fromPartial(object.initWrenchDirectionInFrameName)
        : undefined;
    message.tangentialSpeed = object.tangentialSpeed ?? undefined;
    message.rotationalSpeed = object.rotationalSpeed ?? undefined;
    message.forceLimit = object.forceLimit ?? undefined;
    message.torqueLimit = object.torqueLimit ?? undefined;
    message.taskType = object.taskType ?? 0;
    message.endTime = object.endTime ?? undefined;
    message.enableRobotLocomotion = object.enableRobotLocomotion ?? undefined;
    message.controlMode = object.controlMode ?? 0;
    message.targetLinearPosition = object.targetLinearPosition ?? undefined;
    message.targetAngle = object.targetAngle ?? undefined;
    message.accelLimit = object.accelLimit ?? undefined;
    message.resetEstimator = object.resetEstimator ?? undefined;
    return message;
  },
};

function createBaseConstrainedManipulationCommand_Feedback(): ConstrainedManipulationCommand_Feedback {
  return { status: 0, desiredWrenchOdomFrame: undefined, estimationActivated: undefined };
}

export const ConstrainedManipulationCommand_Feedback = {
  encode(message: ConstrainedManipulationCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.desiredWrenchOdomFrame !== undefined) {
      Wrench.encode(message.desiredWrenchOdomFrame, writer.uint32(18).fork()).ldelim();
    }
    if (message.estimationActivated !== undefined) {
      BoolValue.encode({ value: message.estimationActivated! }, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConstrainedManipulationCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConstrainedManipulationCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.desiredWrenchOdomFrame = Wrench.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.estimationActivated = BoolValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConstrainedManipulationCommand_Feedback {
    return {
      status: isSet(object.status) ? constrainedManipulationCommand_Feedback_StatusFromJSON(object.status) : 0,
      desiredWrenchOdomFrame: isSet(object.desiredWrenchOdomFrame)
        ? Wrench.fromJSON(object.desiredWrenchOdomFrame)
        : undefined,
      estimationActivated: isSet(object.estimationActivated) ? Boolean(object.estimationActivated) : undefined,
    };
  },

  toJSON(message: ConstrainedManipulationCommand_Feedback): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = constrainedManipulationCommand_Feedback_StatusToJSON(message.status);
    }
    if (message.desiredWrenchOdomFrame !== undefined) {
      obj.desiredWrenchOdomFrame = Wrench.toJSON(message.desiredWrenchOdomFrame);
    }
    if (message.estimationActivated !== undefined) {
      obj.estimationActivated = message.estimationActivated;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConstrainedManipulationCommand_Feedback>, I>>(
    base?: I,
  ): ConstrainedManipulationCommand_Feedback {
    return ConstrainedManipulationCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConstrainedManipulationCommand_Feedback>, I>>(
    object: I,
  ): ConstrainedManipulationCommand_Feedback {
    const message = createBaseConstrainedManipulationCommand_Feedback();
    message.status = object.status ?? 0;
    message.desiredWrenchOdomFrame =
      (object.desiredWrenchOdomFrame !== undefined && object.desiredWrenchOdomFrame !== null)
        ? Wrench.fromPartial(object.desiredWrenchOdomFrame)
        : undefined;
    message.estimationActivated = object.estimationActivated ?? undefined;
    return message;
  },
};

function createBaseJointCommand(): JointCommand {
  return {};
}

export const JointCommand = {
  encode(_: JointCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JointCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJointCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): JointCommand {
    return {};
  },

  toJSON(_: JointCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<JointCommand>, I>>(base?: I): JointCommand {
    return JointCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JointCommand>, I>>(_: I): JointCommand {
    const message = createBaseJointCommand();
    return message;
  },
};

function createBaseJointCommand_Request(): JointCommand_Request {
  return {};
}

export const JointCommand_Request = {
  encode(_: JointCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JointCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJointCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): JointCommand_Request {
    return {};
  },

  toJSON(_: JointCommand_Request): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<JointCommand_Request>, I>>(base?: I): JointCommand_Request {
    return JointCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JointCommand_Request>, I>>(_: I): JointCommand_Request {
    const message = createBaseJointCommand_Request();
    return message;
  },
};

function createBaseJointCommand_Feedback(): JointCommand_Feedback {
  return { status: 0, numMessagesReceived: 0 };
}

export const JointCommand_Feedback = {
  encode(message: JointCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.numMessagesReceived !== 0) {
      writer.uint32(16).uint64(message.numMessagesReceived);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JointCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJointCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.numMessagesReceived = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JointCommand_Feedback {
    return {
      status: isSet(object.status) ? jointCommand_Feedback_StatusFromJSON(object.status) : 0,
      numMessagesReceived: isSet(object.numMessagesReceived) ? globalThis.Number(object.numMessagesReceived) : 0,
    };
  },

  toJSON(message: JointCommand_Feedback): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = jointCommand_Feedback_StatusToJSON(message.status);
    }
    if (message.numMessagesReceived !== 0) {
      obj.numMessagesReceived = Math.round(message.numMessagesReceived);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JointCommand_Feedback>, I>>(base?: I): JointCommand_Feedback {
    return JointCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JointCommand_Feedback>, I>>(object: I): JointCommand_Feedback {
    const message = createBaseJointCommand_Feedback();
    message.status = object.status ?? 0;
    message.numMessagesReceived = object.numMessagesReceived ?? 0;
    return message;
  },
};

function createBaseJointCommand_UpdateRequest(): JointCommand_UpdateRequest {
  return {
    endTime: undefined,
    referenceTime: undefined,
    extrapolationDuration: undefined,
    position: [],
    velocity: [],
    load: [],
    gains: undefined,
    userCommandKey: 0,
    velocitySafetyLimit: undefined,
  };
}

export const JointCommand_UpdateRequest = {
  encode(message: JointCommand_UpdateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(10).fork()).ldelim();
    }
    if (message.referenceTime !== undefined) {
      Timestamp.encode(toTimestamp(message.referenceTime), writer.uint32(58).fork()).ldelim();
    }
    if (message.extrapolationDuration !== undefined) {
      Duration.encode(message.extrapolationDuration, writer.uint32(66).fork()).ldelim();
    }
    writer.uint32(18).fork();
    for (const v of message.position) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(26).fork();
    for (const v of message.velocity) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(34).fork();
    for (const v of message.load) {
      writer.float(v);
    }
    writer.ldelim();
    if (message.gains !== undefined) {
      JointCommand_UpdateRequest_Gains.encode(message.gains, writer.uint32(42).fork()).ldelim();
    }
    if (message.userCommandKey !== 0) {
      writer.uint32(48).uint32(message.userCommandKey);
    }
    if (message.velocitySafetyLimit !== undefined) {
      FloatValue.encode({ value: message.velocitySafetyLimit! }, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JointCommand_UpdateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJointCommand_UpdateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.referenceTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.extrapolationDuration = Duration.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag === 21) {
            message.position.push(reader.float());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.position.push(reader.float());
            }

            continue;
          }

          break;
        case 3:
          if (tag === 29) {
            message.velocity.push(reader.float());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.velocity.push(reader.float());
            }

            continue;
          }

          break;
        case 4:
          if (tag === 37) {
            message.load.push(reader.float());

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.load.push(reader.float());
            }

            continue;
          }

          break;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.gains = JointCommand_UpdateRequest_Gains.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.userCommandKey = reader.uint32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.velocitySafetyLimit = FloatValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JointCommand_UpdateRequest {
    return {
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      referenceTime: isSet(object.referenceTime) ? fromJsonTimestamp(object.referenceTime) : undefined,
      extrapolationDuration: isSet(object.extrapolationDuration)
        ? Duration.fromJSON(object.extrapolationDuration)
        : undefined,
      position: globalThis.Array.isArray(object?.position) ? object.position.map((e: any) => globalThis.Number(e)) : [],
      velocity: globalThis.Array.isArray(object?.velocity) ? object.velocity.map((e: any) => globalThis.Number(e)) : [],
      load: globalThis.Array.isArray(object?.load) ? object.load.map((e: any) => globalThis.Number(e)) : [],
      gains: isSet(object.gains) ? JointCommand_UpdateRequest_Gains.fromJSON(object.gains) : undefined,
      userCommandKey: isSet(object.userCommandKey) ? globalThis.Number(object.userCommandKey) : 0,
      velocitySafetyLimit: isSet(object.velocitySafetyLimit) ? Number(object.velocitySafetyLimit) : undefined,
    };
  },

  toJSON(message: JointCommand_UpdateRequest): unknown {
    const obj: any = {};
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    if (message.referenceTime !== undefined) {
      obj.referenceTime = message.referenceTime.toISOString();
    }
    if (message.extrapolationDuration !== undefined) {
      obj.extrapolationDuration = Duration.toJSON(message.extrapolationDuration);
    }
    if (message.position?.length) {
      obj.position = message.position;
    }
    if (message.velocity?.length) {
      obj.velocity = message.velocity;
    }
    if (message.load?.length) {
      obj.load = message.load;
    }
    if (message.gains !== undefined) {
      obj.gains = JointCommand_UpdateRequest_Gains.toJSON(message.gains);
    }
    if (message.userCommandKey !== 0) {
      obj.userCommandKey = Math.round(message.userCommandKey);
    }
    if (message.velocitySafetyLimit !== undefined) {
      obj.velocitySafetyLimit = message.velocitySafetyLimit;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JointCommand_UpdateRequest>, I>>(base?: I): JointCommand_UpdateRequest {
    return JointCommand_UpdateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JointCommand_UpdateRequest>, I>>(object: I): JointCommand_UpdateRequest {
    const message = createBaseJointCommand_UpdateRequest();
    message.endTime = object.endTime ?? undefined;
    message.referenceTime = object.referenceTime ?? undefined;
    message.extrapolationDuration =
      (object.extrapolationDuration !== undefined && object.extrapolationDuration !== null)
        ? Duration.fromPartial(object.extrapolationDuration)
        : undefined;
    message.position = object.position?.map((e) => e) || [];
    message.velocity = object.velocity?.map((e) => e) || [];
    message.load = object.load?.map((e) => e) || [];
    message.gains = (object.gains !== undefined && object.gains !== null)
      ? JointCommand_UpdateRequest_Gains.fromPartial(object.gains)
      : undefined;
    message.userCommandKey = object.userCommandKey ?? 0;
    message.velocitySafetyLimit = object.velocitySafetyLimit ?? undefined;
    return message;
  },
};

function createBaseJointCommand_UpdateRequest_Gains(): JointCommand_UpdateRequest_Gains {
  return { kQP: [], kQdP: [] };
}

export const JointCommand_UpdateRequest_Gains = {
  encode(message: JointCommand_UpdateRequest_Gains, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.kQP) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.kQdP) {
      writer.float(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JointCommand_UpdateRequest_Gains {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJointCommand_UpdateRequest_Gains();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 13) {
            message.kQP.push(reader.float());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.kQP.push(reader.float());
            }

            continue;
          }

          break;
        case 2:
          if (tag === 21) {
            message.kQdP.push(reader.float());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.kQdP.push(reader.float());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JointCommand_UpdateRequest_Gains {
    return {
      kQP: globalThis.Array.isArray(object?.kQP) ? object.kQP.map((e: any) => globalThis.Number(e)) : [],
      kQdP: globalThis.Array.isArray(object?.kQdP) ? object.kQdP.map((e: any) => globalThis.Number(e)) : [],
    };
  },

  toJSON(message: JointCommand_UpdateRequest_Gains): unknown {
    const obj: any = {};
    if (message.kQP?.length) {
      obj.kQP = message.kQP;
    }
    if (message.kQdP?.length) {
      obj.kQdP = message.kQdP;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JointCommand_UpdateRequest_Gains>, I>>(
    base?: I,
  ): JointCommand_UpdateRequest_Gains {
    return JointCommand_UpdateRequest_Gains.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JointCommand_UpdateRequest_Gains>, I>>(
    object: I,
  ): JointCommand_UpdateRequest_Gains {
    const message = createBaseJointCommand_UpdateRequest_Gains();
    message.kQP = object.kQP?.map((e) => e) || [];
    message.kQdP = object.kQdP?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
