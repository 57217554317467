import { useSetRecoilState } from 'recoil';

import { useDeviceName } from 'store/my-robot';

import { ManipulatorState } from 'lib/generated/proto';

import { IRosTypeBosdynApiMsgsManipulatorState } from 'types/generated/ros-msgs';

import { manipulatorStateAtom } from './atoms';

/**
 * Helper for parsing the ros version to proto version
 * @param payload
 */
function parseManipulatorState(payload: IRosTypeBosdynApiMsgsManipulatorState) {
  const gripperOpenPercentage = payload.gripper_open_percentage;
  const isGripperHoldingItem = payload.is_gripper_holding_item;
  const stowState = payload.stow_state?.value;
  const carryState = payload.carry_state?.value;
  // const estimatedEndEffectorForceInHand = payload.estimated_end_effector_force_in_hand;

  return ManipulatorState.create({
    gripperOpenPercentage,
    isGripperHoldingItem,
    stowState,
    carryState,
  });
}

export function useManipulatorStateSetter() {
  const { deviceName } = useDeviceName();
  const setManipulatorState = useSetRecoilState(manipulatorStateAtom(deviceName));

  /**
   * @param payload - from signalR message
   */
  function updateManipulatorState(payload: unknown) {
    const newState = parseManipulatorState(payload as IRosTypeBosdynApiMsgsManipulatorState);
    setManipulatorState(newState);
  }

  return { updateManipulatorState };
}
