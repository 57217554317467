import { HubConnectionState } from '@microsoft/signalr';
import { stringify } from 'qs';
import { toast } from 'react-toastify';

import { useLogSignalR } from 'store/logger';
import { useDeviceName } from 'store/my-robot';

import { SIGNAL_R_URL } from 'lib/constants/env-variables';

import { logRed } from 'utils/debugger';

import { HUB_URL } from '../const';
import { Options } from './types';
import { useSignalRHubBasic } from './useSignalRHubBasic';

type QueryParams = Record<string, unknown>;

export const createUrl = (url: string, params?: QueryParams) => {
  if (!params) {
    return `${SIGNAL_R_URL}/${url}`;
  }

  return `${SIGNAL_R_URL}/${url}?${stringify(params)}`;
};

/**
 * Create and initialize a connection to a signalR hub
 */
export function useSignalRConnection(url: HUB_URL) {
  const { deviceName } = useDeviceName();
  const { logSignalRInit } = useLogSignalR(url);

  const hubOptions: Options = {
    onConnected: () => logSignalRInit(`signalR: 🏁🏁🏁 connection started: "${url}"`),
    onDisconnected: () => logSignalRInit(`signalR: 🛑🛑🛑 connection disconnected: "${url}"`),
    onError: (error) => {
      toast.error(
        error?.toString() || 'Something went wrong with signalR...\nPlease, try again later!',
      );
      logRed(`signalR: 🚫🚫🚫 connection error: "${url}"`, error);
    },
  };

  const connection = useSignalRHubBasic(createUrl(url, { deviceId: deviceName }), hubOptions);

  const isConnected = connection?.state === HubConnectionState.Connected;
  const status = connection?.state;

  return {
    connection,
    isConnected,
    status,
  };
}
