import styled from 'styled-components';

export const SDropzoneContainer = styled.div`
  .dropzone {
    border: 2px dashed ${(props) => props.theme.colors.border};
    border-radius: ${(props) => props.theme.borderRadius.md};
    background-color: ${(props) => props.theme.colors.background.main};

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: ${(props) => props.theme.spacing(5)};
    }
  }

  .dropzone__files {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
    margin: 0 -10px;

    .file {
      width: 20%;
      padding: 5px;

      img {
        width: 100%;
        height: 80px;
        object-fit: contain;
      }
    }
  }
`;

export const SFilesList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing(5)};

  li {
    position: relative;
    padding: ${(props) => props.theme.spacing(1, 1, 1, 3)};

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: ${(props) => props.theme.colors.primary.main};
    }
  }
`;
