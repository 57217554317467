// @ts-nocheck
import { FC, HTMLProps, ReactNode, memo, useMemo } from 'react';

import { ThemeColorKeys } from 'types/ui/Theme';

import { SKey, SKeyLetter } from './styled';

/**
 * Key Button component
 * @param {String} keyName - Small letter indicator for button
 * @param {Boolean} disabled - Status of button
 * @param {String} bgcolor - Background color for button
 * @param {Number} btnSize - Size in pixels
 * @param {Node} children - Any
 */

export type KeyButtonProps = HTMLProps<HTMLButtonElement> & {
  keyName?: string;
  isActive?: boolean;
  disabled?: boolean;
  bgcolor?: string;
  btnSize?: number;
  children?: ReactNode;
  round?: boolean;
  m?: string;
};

const MIN_FONT_SIZE = 16;

const KeyButton: FC<KeyButtonProps> = memo(
  ({ keyName, isActive, disabled, bgcolor, btnSize, children, ...props }) => {
    const fontSize = useMemo(
      () => Math.min(btnSize / Math.max(children?.props?.children?.length / 2.5, 4), MIN_FONT_SIZE),
      [btnSize, children?.props?.children?.length],
    );

    return (
      <SKey
        withIcon={!!children}
        bgcolor={bgcolor}
        btnSize={btnSize}
        fontSize={fontSize}
        disabled={disabled}
        isActive={isActive}
        data-key-name={keyName}
        {...props}
      >
        {children && children}
        {keyName && <SKeyLetter color={ThemeColorKeys.PRIMARY_LIGHT}>{keyName}</SKeyLetter>}
      </SKey>
    );
  },
);

export default KeyButton;
