export enum TooltipPlacements {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
}

type Point = {
  x: number | null;
  y: number | null;
};

const point = (): Point & {
  reset: (p: Point) => void;
  restrictRect: (rect: { l: number; t: number; r: number; b: number }) => void;
} => ({
  x: null,
  y: null,
  reset(p: Point) {
    this.x = p.x;
    this.y = p.y;
  },
  restrictRect(rect) {
    if (this.x && this.x < rect.l) {
      this.x = rect.l;
    } else if (this.x && this.x > rect.r) this.x = rect.r;
    if (this.y && this.y < rect.t) {
      this.y = rect.t;
    } else if (this.y && this.y > rect.b) this.y = rect.b;
  },
});

export const position = (p: TooltipPlacements) => ({
  current: p,
  // eslint-disable-next-line consistent-return
  negate() {
    if (this.current === TooltipPlacements.LEFT) return TooltipPlacements.RIGHT;
    if (this.current === TooltipPlacements.RIGHT) return TooltipPlacements.LEFT;
    if (this.current === TooltipPlacements.TOP) return TooltipPlacements.BOTTOM;
    if (this.current === TooltipPlacements.BOTTOM) return TooltipPlacements.TOP;
  },
  isHorizontal() {
    return this.current === TooltipPlacements.LEFT || this.current === TooltipPlacements.RIGHT;
  },
  isVertical() {
    return this.current === TooltipPlacements.TOP || this.current === TooltipPlacements.BOTTOM;
  },
});

export const getPoint = (
  el: HTMLElement,
  tt: HTMLSpanElement,
  placement: TooltipPlacements,
  space: number,
) => {
  let recurCount = 0;
  const pt = point();
  const bdys = {
    l: space,
    t: space,
    r: document.body.clientWidth - (tt.clientWidth + space),
    b: window.innerHeight - (tt.clientHeight + space),
  };
  const elRect = el.getBoundingClientRect();

  return (function recursive(placementInner) {
    // eslint-disable-next-line no-plusplus
    recurCount++;
    const pos = position(placementInner);
    switch (pos.current) {
      case 'left':
        pt.x = elRect.left - (tt.offsetWidth + space);
        pt.y = elRect.top + (el.offsetHeight - tt.offsetHeight) / 2;
        break;
      case 'right':
        pt.x = elRect.right + space;
        pt.y = elRect.top + (el.offsetHeight - tt.offsetHeight) / 2;
        break;
      case 'top':
        pt.x = elRect.left + (el.offsetWidth - tt.offsetWidth) / 2;
        pt.y = elRect.top - (tt.offsetHeight + space);
        break;
      default:
        pt.x = elRect.left + (el.offsetWidth - tt.offsetWidth) / 2;
        pt.y = elRect.bottom + space;
    }

    if (recurCount < 3) {
      if (
        (pos.isHorizontal() && (pt.x < bdys.l || pt.x > bdys.r)) ||
        (pos.isVertical() && (pt.y < bdys.t || pt.y > bdys.b))
      ) {
        pt.reset(recursive(pos.negate() as TooltipPlacements));
      }
    }

    // restrict to rect boundary
    pt.restrictRect(bdys);

    return pt;
  })(placement);
};
