import { useSetRecoilState } from 'recoil';

import { logYellow } from 'utils/debugger';

import { RobotStatus } from 'types/models';

import { robotStatusSelector } from './myRobot.atoms';

export function useSetRobotStatus() {
  const setRobotStatus = useSetRecoilState(robotStatusSelector);

  const updateStatus = (robotStatusData: RobotStatus) => {
    logYellow('RobotMainModule:updateStatus', robotStatusData);
    if (robotStatusData) {
      setRobotStatus(robotStatusData);
    }
  };

  return { updateStatus };
}
