import styled from 'styled-components';

import { SBox } from 'components/ui';

export const SImg = styled(SBox)`
  width: ${(props) => props.w && props.w};
  height: ${(props) => props.h && props.h};
  max-height: ${(props) => props.maxH && props.maxH};
  min-height: ${(props) => props.minH && props.minH};
  max-width: ${(props) => props.maxW && props.maxW};
  min-width: ${(props) => props.minW && props.minW};
`;
