import { PropsWithChildren, ReactNode, memo } from 'react';

import { STypography } from 'components/ui';

import { STile, STileBody, STileContainer, STileHeader } from './Tile.styled';

/**
 * Tile component
 * @param {Number, String} w - Tile width
 * @param {Number, String} h - Tile height
 * @param {String} title
 * @param {Node} header - Header component
 * @param {Node} children - Any
 */

type TileProps = {
  w: string;
  h: string;
  flex?: string;
  title?: string;
  header?: ReactNode;
  disabled?: boolean;
  noPadding?: boolean;
} & PropsWithChildren;

const Tile = memo<TileProps>(({ w, h, flex, title, header, disabled, noPadding, children }) => (
  <STileContainer w={w} h={h} flex={flex} disabled={disabled}>
    <STile className="border">
      {(title || header) && (
        <STileHeader>
          {title && (
            <STypography variant="caption" fontWeight={700} truncate>
              {title}
            </STypography>
          )}
          {header && header}
        </STileHeader>
      )}
      <STileBody noPadding={noPadding}>{children && children}</STileBody>
    </STile>
  </STileContainer>
));

export default Tile;
