import { Page } from 'components/common';
import Navbar from 'components/layouts/nav-bar';

import { useRobotTopicsRelay } from 'hooks/complex/signal-r/useRobotTopisRelay';
import { SignalRHubProvider, useIothubHub } from 'hooks/signalR';

function RobotTopicTest() {
  const con = useRobotTopicsRelay();

  const isConnected = con?.isConnected;

  return (
    <div className="space-y-4">
      <h1>Robot topics relay</h1>
      <div>isConnected {isConnected ? 'yes' : 'on'}</div>
    </div>
  );
}

// @ts-ignore-next-line
function IothubTest() {
  // const { onRelayTopicReceived } = useMyRobotRelayTopic();
  const bla = useIothubHub({
    listenMethods: {
      // [IothubEvent.RECEIVE_TOPIC_RELAY]: onRelayTopicReceived,
    },
  });
  const isConnected = bla?.isConnected;

  return (
    <div className="space-y-4">
      <h1>IOT HUB</h1>
      <div>isConnected {isConnected ? 'yes' : 'on'}</div>
    </div>
  );
}

function SignalRTest() {
  return (
    <SignalRHubProvider>
      <Navbar />
      <Page pageHeader={<Page.Header title="UI Components Examples" />}>
        <div className="space-y-4 container">
          <div className="space-y-4">
            <h1>Signal R test</h1>
            <RobotTopicTest />
            <RobotTopicTest />
            <RobotTopicTest />
          </div>
        </div>
      </Page>
    </SignalRHubProvider>
  );
}

export default SignalRTest;
