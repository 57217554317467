import { robotImageByModelName } from 'store/robot-toolkit/robot-template';

import {
  MyRobotsPrimaryInformationResponse,
  MyRobotsPrimaryInformationResponseDTO,
  ROBOT_MODEL,
} from 'types/models';

import { axiosRequest } from '../axios';
import { MyRobotUrls } from '../urls';

export function extendRobotPrimaryInformation(
  robot: MyRobotsPrimaryInformationResponse,
): MyRobotsPrimaryInformationResponseDTO {
  const modelName = robot.modelName as ROBOT_MODEL;
  const imageUrl = robotImageByModelName(modelName);

  return {
    ...robot,
    imageUrl,
    modelName,
  };
}

export class MyRobotManager {
  static async getAllRobotPrimaryInformation(): Promise<MyRobotsPrimaryInformationResponseDTO[]> {
    const { method, url } = MyRobotUrls.PrimaryInformation;
    const { data } = await axiosRequest<MyRobotsPrimaryInformationResponse[]>(method, url);
    return data.map(extendRobotPrimaryInformation);
  }

  static async GetMyRobotPrimaryInformation(
    robotId: number,
  ): Promise<MyRobotsPrimaryInformationResponseDTO> {
    const { method, url } = MyRobotUrls.GetMyRobotPrimaryInformation;
    const { data } = await axiosRequest<MyRobotsPrimaryInformationResponse>(method, url(robotId));
    return extendRobotPrimaryInformation(data);
  }
}
