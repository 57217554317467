// @ts-nocheck
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    html, body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overscroll-behavior-y: none;
        overscroll-behavior-x: none;
        height: 100vh;
        font-size: 1rem;
        background-color: ${(props) => props.theme.colors.background.dark};
        // TODO -> test for touch devices
        //touch-action: none; // pan-x pan-y;
        //-webkit-text-size-adjust: none;

        //&.ReactModal__Body--open, &.hide-scroll {
        //    overflow: hidden!important;
        //    padding-right: 15px;
        //}
    }

    #root {
        height: 100%;
    }

    main {
        height: 100%;
    }

    div, header, footer {
        box-sizing: border-box;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    button {
        &:disabled {
            cursor: not-allowed;
        }

        &:active, &:focus {
            outline: none !important;
        }
    }

    // Modal fix
    .ReactModal__Overlay {
        z-index: 1;
    }

    // Snackbar fix
    div[class^='Snackbar_snackbar-wrapper'] {
        z-index: 2;
    }

    // Hides error messages overlay
    #webpack-dev-server-client-overlay {
        display: none;
    }

`;

export default GlobalStyles;
