import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useRobotId } from 'store/my-robot/useRobotId';

import { PayloadItem } from 'types/models/Payload';

import { axiosRequest } from '../axios';
import { MyRobotUrls } from '../urls';

async function getRobotPayloads(myRobotId: number) {
  const { method, url } = MyRobotUrls.GetMyRobotPayloads;
  const { data } = await axiosRequest<PayloadItem[]>(method, url(myRobotId));
  return data;
}

export function useMyRobotPayloads() {
  const queryClient = useQueryClient();

  const { robotId } = useRobotId();
  const queryKey = [MyRobotUrls.GetMyRobotPayloads.url(robotId)];
  const { data: robotPayloads, isLoading } = useQuery({
    queryKey,
    queryFn: () => getRobotPayloads(robotId),
    refetchInterval: 2 * 1000,
    initialData: [],
  });

  function refreshRobotPayloads() {
    return queryClient.invalidateQueries({ queryKey });
  }

  return { robotPayloads, refreshRobotPayloads, isLoading };
}
