import { Dispatch, SetStateAction } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { axiosRequest } from 'lib/api/axios';
import { getRobotDataConfig } from 'lib/api/urls';

import { MyRobotManager } from '../../lib/api/myRobot';
import { myRobotAtom, myRobotPrimaryInformationAtom } from './myRobot.atoms';

type GetRequestSetState<T> = Dispatch<SetStateAction<T>>;

type GetRequestWithStatusUpdate<T> = {
  setState: GetRequestSetState<T>;
  url: string;
  silent?: boolean;
  params?: Record<string, unknown>;
  dataParser?: any;
};

// old way - nearly not used anymore
export const getRequestWithStateUpdate = <T = unknown>({
  setState,
  url,
  silent = false,
  params,
  dataParser,
}: GetRequestWithStatusUpdate<T>) => {
  setState((prevState) => ({
    ...prevState,
    ...(!silent && {
      data: undefined,
      error: undefined,
      isFetched: false,
    }),
  }));

  axiosRequest('get', url, { params })
    .then(({ data }) => {
      const finalData = dataParser ? dataParser(data) : data;

      setState((prevState) => ({
        ...prevState,
        data: finalData,
        isFetched: true,
        error: undefined,
      }));
    })
    .catch((err) => {
      setState((prevState) => ({
        ...prevState,
        data: undefined,
        isFetched: true,
        error: err,
      }));
    });
};

/**
 * Note: these are high level update functions - properly removed soon
 */
const useMyRobotActions = () => {
  const setMyRobot = useSetRecoilState(myRobotAtom);
  const setMyRobotPrimaryInformation = useSetRecoilState(myRobotPrimaryInformationAtom);

  async function getById(robotId: number) {
    const { url } = getRobotDataConfig(robotId);

    return getRequestWithStateUpdate({
      setState: setMyRobot,
      url,
    });
  }

  async function getPrimaryInformationById(robotId: number) {
    const data = await MyRobotManager.GetMyRobotPrimaryInformation(robotId);
    console.log('getPrimaryInformationById', data.robotInfo.robotStatus);

    setMyRobotPrimaryInformation((prevState) => ({
      ...prevState,
      data,
      isFetched: true,
      error: undefined,
    }));
  }

  return {
    getById,
    getPrimaryInformationById,
  };
};

export function useMyRobotPrimaryInformation() {
  const { data: myRobotPrimaryInformation, isFetched } = useRecoilValue(
    myRobotPrimaryInformationAtom,
  );
  return { myRobotPrimaryInformation, isFetched };
}

export default useMyRobotActions;
