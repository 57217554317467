import React, {
  CSSProperties,
  PropsWithChildren,
  ReactElement,
  memo,
  useCallback,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';

import { useDidUpdate, useEventListener } from 'hooks';

import {
  SDropdown,
  SDropdownList,
  SDropdownListContainer,
  SDropdownToggle,
} from './Dropdown.styled';

import DropdownContext from './DropdownContext';
import DropdownDivider from './DropdownDivider';
import DropdownItem from './DropdownItem';
import DropdownSection from './DropdownSection';

/**
 * Dropdown component
 * @param {String, Element} label - Dropdown toggle button
 * @param {String} className - Dropdown className
 * @param {Array.<DropdownSection, DropdownItem, DropdownDivider>} children - List of allowed items
 */

type DropdownProps = {
  label: string | ReactElement<any>;
  loading?: boolean;
  disabled?: boolean;
};

const Dropdown = Object.assign(
  memo<DropdownProps & PropsWithChildren>(({ label, loading, disabled, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [listContainerStyles, setListContainerStyles] = useState({});

    const dropdownRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLUListElement>(null);

    const handleToggle = (): void => {
      setIsOpen((prevState) => !prevState);
    };

    const calculateDropdownBoundaries = useCallback(() => {
      const buffer = 10;

      const dropdown = dropdownRef.current;
      const list = listRef.current;

      if (dropdown && list) {
        const dropdownRect: DOMRect = dropdown.getBoundingClientRect();
        const listRect: DOMRect = list.getBoundingClientRect();

        const maxHeight = window.innerHeight - buffer * 2;
        const maxWidth = window.innerWidth - buffer * 2;

        const inlineStyles: CSSProperties = {
          left: dropdownRect.left,
          maxHeight: `${maxHeight}px`,
          maxWidth: `${maxWidth}px`,
        };

        const availableBottomPlace = window.innerHeight - dropdownRect.bottom - buffer;
        const availableTopPlace = dropdownRect.top - buffer;

        if (listRect.height < availableBottomPlace) {
          // If dropdown fits render in bottom
          inlineStyles.top = dropdownRect.bottom + document.documentElement.scrollTop;
        } else {
          // If not then render in top
          if (listRect.height <= availableTopPlace) {
            // Render in top if fits
            inlineStyles.top =
              dropdownRect.top + document.documentElement.scrollTop - listRect.height;
          } else {
            // If not render till the bottom of viewport
            inlineStyles.top = document.documentElement.scrollTop + buffer;
          }
        }

        setListContainerStyles(inlineStyles);
      }
    }, []);

    const adjustDropdownPositionAndSize = (): void => {
      if (isOpen) {
        calculateDropdownBoundaries();
      }
    };

    const handleOutsideClick = (e: MouseEvent): void => {
      if (isOpen && !listRef?.current?.contains(e?.target as Node)) {
        handleToggle();
      }
    };

    useEventListener('mousedown', handleOutsideClick);
    useEventListener('resize', adjustDropdownPositionAndSize, window);
    // useEventListener('scroll', adjustDropdownPositionAndSize);

    useDidUpdate(() => {
      if (isOpen) {
        document.body.classList.add('hide-scroll');
      } else {
        document.body.classList.remove('hide-scroll');
      }

      adjustDropdownPositionAndSize();
    }, [isOpen]);

    return (
      <DropdownContext.Provider value={handleToggle}>
        <SDropdown isOpen={isOpen} ref={dropdownRef}>
          {typeof label === 'string' ? (
            <SDropdownToggle
              type="button"
              aria-label={label}
              onClick={handleToggle}
              loading={loading}
              disabled={disabled}
            >
              {label}
            </SDropdownToggle>
          ) : (
            React.cloneElement(label, {
              className: `${label.props?.className ?? ''} ${isOpen ? 'isOpen' : 'isClose'}`,
              onClick: handleToggle,
              loading,
              disabled,
            })
          )}

          {isOpen &&
            ReactDOM.createPortal(
              <SDropdownListContainer style={listContainerStyles}>
                <SDropdownList ref={listRef}>{children}</SDropdownList>
              </SDropdownListContainer>,
              document.body,
            )}
        </SDropdown>
      </DropdownContext.Provider>
    );
  }),
  {
    Section: DropdownSection,
    Item: DropdownItem,
    Divider: DropdownDivider,
  },
);

export default Dropdown;
