import cameraSources from './camera-sources.json';
import spotImageSources from './image-source-by-camera.json';

const spotAllowedCameraSources = cameraSources.spot.allowed;

export type SpotCameraValue = keyof typeof spotAllowedCameraSources;

type SpotCameraName = (typeof spotAllowedCameraSources)[SpotCameraValue];
export type SpotCameraSources = { name: SpotCameraName; value: SpotCameraValue }[];

export const StreamSourceNames = {
  HAND_COLOR_IMAGE: 'hand_color_image',
};
/**
 * A wrapper around dummy sources for streams.
 * Todo: Rebuild this when it comes to mapping label to value for stream sources
 */
export class CameraSources {
  static getSpotSources(): SpotCameraSources {
    return Object.entries(spotAllowedCameraSources).map(([key, value]) => ({
      name: value,
      value: key as unknown as SpotCameraValue,
    }));
  }

  static getSpotSourcesRaw() {
    return spotAllowedCameraSources;
  }

  static getSpotImageSourcesRaw() {
    return spotImageSources;
  }
}
