import styled from 'styled-components';

import { generateSpacings } from 'components/ui/utils';

import { Spacings } from 'types/ui/Theme';

type Props = Partial<Spacings> & {
  height?: string;
  bgColor?: string;
};

const SDivider = styled.hr<Props>`
  height: ${(props) => props.height || '1px'};
  width: 100%;
  border: none;
  background-color: ${(props) => props.bgColor ?? props.theme.colors.neutral};
  ${(props) => generateSpacings(props)}
`;

export default SDivider;
