import styled, { css } from 'styled-components';

import { SBox, STypography } from 'components/ui';

export const SInputContainer = styled(SBox).withConfig({
  shouldForwardProp: (prop) => !['isError'].includes(prop),
})<{ isError?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${(props) => !props.mb && props.theme.spacing(2)};

  input,
  select {
    box-sizing: border-box;
    width: 100%;
    height: 32px;
    outline: none !important;
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: ${(props) => props.theme.borderRadius.md};
    background-color: ${(props) => props.theme.colors.background.light};
    color: ${(props) => props.theme.colors.text.main};
    padding: ${(props) => props.theme.spacing(1, 2)};
    font-size: ${(props) => props.theme.fontSizes.xxs};

    &:disabled {
      background-color: ${(props) => props.theme.colors.background.main};
    }
  }

  ${(props) =>
    props.isError &&
    css`
      input,
      select {
        border: 1px solid ${props.theme.colors.red};
      }
    `};
`;

export const SLabel = styled.label`
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.text.main};
  font-size: ${(props) => props.theme.fontSizes.xxs};
  font-family: ${(props) => props.theme.fontFamilies.roboto};
  margin-bottom: ${(props) => props.theme.spacing(1)};
`;

export const SInputError = styled(STypography)`
  display: inline-block;
  height: 20px;
  margin-top: ${(props) => props.theme.spacing(1)};
`;

export const SInputDescription = styled(STypography)`
  height: 20px;
  margin-top: ${(props) => props.theme.spacing(1)};
`;
