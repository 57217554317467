import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { ManipulatorState_StowState } from 'lib/generated/proto';
import {
  NamedArmPositionsCommand_Feedback_Status,
  namedArmPositionsCommand_Feedback_StatusToJSON,
} from 'lib/generated/proto/bosdyn/api/arm_command';
import { robotCommandFeedbackStatus_StatusToJSON } from 'lib/generated/proto/bosdyn/api/basic_command';
import { useSendRobotCommand } from 'lib/robot-modules/spot-control-module';

import {
  armCommandFeedbackStatusSelector,
  namedArmPositionCommandFeedbackStatusSelector,
} from '../robot-command-feedback/selectors';
import { manipulatorStateStowedSelector } from './selectors';

/**
 * Weather the arm is stowed or not on robot side
 * @return
 */
export function useArmStowed() {
  const { stowState } = useRecoilValue(manipulatorStateStowedSelector);
  const { armCommandStatus } = useRecoilValue(armCommandFeedbackStatusSelector);
  const { namedArmPositionCommandFeedbackStatus } = useRecoilValue(
    namedArmPositionCommandFeedbackStatusSelector,
  );

  const _armCommandStatusString = robotCommandFeedbackStatus_StatusToJSON(armCommandStatus);
  const _namedArmPositionCommandFeedbackStatusString =
    namedArmPositionsCommand_Feedback_StatusToJSON(namedArmPositionCommandFeedbackStatus);

  // const { deviceName } = useDeviceName();
  // const setCurrentCommandId = useSetRecoilState(currentCommandIdAtom(deviceName));

  const stowed = stowState === ManipulatorState_StowState.STOWSTATE_STOWED;
  const deployed = stowState === ManipulatorState_StowState.STOWSTATE_DEPLOYED;
  const unknown = stowState === ManipulatorState_StowState.STOWSTATE_UNKNOWN;

  const { sendArmStowCommand } = useSendRobotCommand();

  function handleStowArm() {
    return sendArmStowCommand(true);
  }

  function handleDeployArm() {
    return sendArmStowCommand(false);
  }

  async function handleToggleStowArm() {
    if (unknown) {
      return toast('Error: Current arm stow state unknown', {
        type: 'error',
      });
    }
    if (stowed) {
      await handleDeployArm();
    } else if (deployed) {
      await handleStowArm();
    }
  }

  const processingStow =
    namedArmPositionCommandFeedbackStatus ===
    NamedArmPositionsCommand_Feedback_Status.STATUS_IN_PROGRESS;

  return {
    stowed,
    deployed,
    unknown,
    stowState,
    handleToggleStowArm,
    processingStow,
    handleDeployArm,
    handleStowArm,
    armCommandStatus,
    _armCommandStatusString,
    namedArmPositionCommandFeedbackStatus,
    _namedArmPositionCommandFeedbackStatusString,
  };
}
