/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { BoolValue, DoubleValue, Int64Value } from "../../google/protobuf/wrappers";
import { AreaI } from "./image_geometry";
import { Units } from "./units";

export const protobufPackage = "bosdyn.api";

export interface CustomParam {
  dictValue?: DictParam | undefined;
  listValue?: ListParam | undefined;
  intValue?: Int64Param | undefined;
  doubleValue?: DoubleParam | undefined;
  stringValue?: StringParam | undefined;
  roiValue?: RegionOfInterestParam | undefined;
  boolValue?: BoolParam | undefined;
  oneOfValue?: OneOfParam | undefined;
}

export interface CustomParam_Spec {
  /**
   * This parameter is actually a set of sub-parameters.
   * Useful for setting up a paramter hierarchy, e.g.
   *           param A
   *          /       \
   *         /         \
   *     param A.B   param A.C
   */
  dictSpec?:
    | DictParam_Spec
    | undefined;
  /** This parameter is a list of things. */
  listSpec?: ListParam_Spec | undefined;
  intSpec?: Int64Param_Spec | undefined;
  doubleSpec?: DoubleParam_Spec | undefined;
  stringSpec?: StringParam_Spec | undefined;
  roiSpec?: RegionOfInterestParam_Spec | undefined;
  boolSpec?: BoolParam_Spec | undefined;
  oneOfSpec?: OneOfParam_Spec | undefined;
}

export interface UserInterfaceInfo {
  /**
   * This string is an optional display name for the UI.  If left unset, UI will use the
   * DictParam key to label the corresponding UI element.  DictParam key is meant to be
   * machine readable, and shouldn't change accross releases.  display_name is meant to be
   * human readable, and can change from release to release if needed.
   */
  displayName: string;
  /**
   * An optional description that provides additional information about the parameter. Use in
   * combination with display_name.
   */
  description: string;
  /**
   * This is an optional sorting hint.  UI elements will likely be shown in a list, and
   * Dictionary children will be sorted by [display_order, display_name, dictionary key],
   * in ascending numerical order and alphabetical string order.
   */
  displayOrder: number;
}

/**
 * Collection of both specifications and values.
 * Meant to be used as a snapshot of specifications offered by a service, and the values
 * chosen by a user.
 */
export interface CustomParamCollection {
  specs: DictParam_Spec | undefined;
  values: DictParam | undefined;
}

/** A dictionary of parameters. */
export interface DictParam {
  values: { [key: string]: CustomParam };
}

export interface DictParam_ChildSpec {
  spec: CustomParam_Spec | undefined;
  uiInfo: UserInterfaceInfo | undefined;
}

export interface DictParam_Spec {
  /** Each element can have its own type.  Dictionaries can even contain dictionaries! */
  specs: { [key: string]: DictParam_ChildSpec };
  /**
   * Whether the dict should initially appear hidden/collapsed. For example an "Advanced"
   * section that users infrequently access.
   *
   * The client may ignore this value if there is sufficient screen space.
   */
  isHiddenByDefault: boolean;
}

export interface DictParam_Spec_SpecsEntry {
  key: string;
  value: DictParam_ChildSpec | undefined;
}

export interface DictParam_ValuesEntry {
  key: string;
  value: CustomParam | undefined;
}

/**
 * A selected param from one of several options.  Can be useful to specify parameters
 * that have correlations.
 *
 * Example 1 - A camera that advertises an "exposure" parameter:
 *      OneOf Option 1: Auto exposure [no additional parameters]
 *      OneOf Option 2: Manual exposure [additonal exposure double parameter from 0 - 100 ms]
 *
 * Example 2 - A NCB worker that will alert if temperature outside a specified bound:
 *      OneOf Option 1: No alert [no additional parameters]
 *      OneOf Option 2: Alert if above max [addional max_temp parameter]
 *      OneOf Option 3: Alert if below min [additional min_temp parameter]
 *      OneOf Option 4: Alert if above max or below min [additional max_temp and min_temp
 *      parameters]
 *
 * In the above examples, the service advertises a OneOf spec, the UI lets user PICK which child
 * of the OneOf they want, and then the UI lets the user specify any child specific parameters.
 */
export interface OneOfParam {
  key: string;
  /**
   * The values of the chosen spec will is guaranteed to be at value[key].
   * The values of the other specs might at value[that_specs_key], but no guarantees.
   */
  values: { [key: string]: DictParam };
}

export interface OneOfParam_ChildSpec {
  spec: DictParam_Spec | undefined;
  uiInfo: UserInterfaceInfo | undefined;
}

export interface OneOfParam_Spec {
  /**
   * What options are available.  Only one will be specified by the user.
   *
   * If an option has no additional parameters, its spec should be an empty DictParam.Spec.
   */
  specs: { [key: string]: OneOfParam_ChildSpec };
  /** Which option to start selected.  If left blank, UI will pick one for you. */
  defaultKey: string;
}

export interface OneOfParam_Spec_SpecsEntry {
  key: string;
  value: OneOfParam_ChildSpec | undefined;
}

export interface OneOfParam_ValuesEntry {
  key: string;
  value: DictParam | undefined;
}

/** A list of elements of given types. */
export interface ListParam {
  values: CustomParam[];
}

export interface ListParam_Spec {
  /**
   * Each element in the list must follow the specification of the matching type.
   * For example, if element_specs.int_spec is filled out, all values should follow
   * that specification. If element_specs.string_spec is filled out, all values
   * should follow that specification.
   */
  elementSpec:
    | CustomParam_Spec
    | undefined;
  /**
   * Minimum and maximum number of values the client may send (inclusive).
   * If min_number_of_values is 0, the parameter is optional.
   *
   * 0 <= min_number_of_values <= max_number_of_values
   */
  minNumberOfValues: number | undefined;
  maxNumberOfValues: number | undefined;
}

/**
 * A 64-bit integer parameter.
 * Wraps specification-related messages, and contains fields for the value sent by a client.
 */
export interface Int64Param {
  /** Value should be provided in the same units as defined by the spec. */
  value: number;
}

export interface Int64Param_Spec {
  /**
   * Default value. If unspecified, UIs can pick their own default OR force user to make a
   * selection.
   */
  defaultValue:
    | number
    | undefined;
  /** Units of value, default_value, min_value, min_value. */
  units:
    | Units
    | undefined;
  /**
   * A value sent by the client must be within this minimum and maximum (inclusive).
   * If unset, only limited by system representation.
   * ERROR: It is an error to specify a min_value larger than the max_value.
   */
  minValue: number | undefined;
  maxValue: number | undefined;
}

/**
 * A 64-bit floating point parameter.
 * Wraps specification-related messages, and contains fields for the value sent by a client.
 */
export interface DoubleParam {
  /** Value should be provided in the same units as defined by the spec. */
  value: number;
}

export interface DoubleParam_Spec {
  /**
   * Default value. If unspecified, UIs can pick their own default OR force user to make a
   * selection.
   */
  defaultValue: number | undefined;
  units:
    | Units
    | undefined;
  /**
   * A value sent by the client must be within this minimum and maximum (inclusive).
   * If unset, only limited by system representation.
   * ERROR: It is an error to specify a min_value larger than the max_value.
   */
  minValue: number | undefined;
  maxValue: number | undefined;
}

/**
 * A text parameter.
 * Wraps specification-related messages, and contains fields for the value sent by a client.
 */
export interface StringParam {
  /** The value sent by a client. */
  value: string;
}

export interface StringParam_Spec {
  /** A value sent by the client must be equal to one of these. */
  options: string[];
  /**
   * Whether or not this parameter accepts a freeform string.
   * If set to true, clients can pick one of the given options OR type their own value.
   * If set to false, clients have to pick one of the given options.
   * If no options are specified, clients should type their own value (ignoring this bool).
   */
  editable: boolean;
  /**
   * Default value. If empty, UIs can pick their own default OR force user to make a
   * selection.
   */
  defaultValue: string;
}

/** A boolean parameter. */
export interface BoolParam {
  /** The value sent by a client. */
  value: boolean;
}

export interface BoolParam_Spec {
  /**
   * Default value. If unset, UIs can pick their own default OR force user to make a
   * selection.
   */
  defaultValue: boolean | undefined;
}

/** Region of Interest parameter, region is associated with a specific image. */
export interface RegionOfInterestParam {
  area:
    | AreaI
    | undefined;
  /** The name of the image service and source the UI had the user specify the ROI on. */
  serviceAndSource:
    | RegionOfInterestParam_ServiceAndSource
    | undefined;
  /**
   * Width of the image (in pixels) when the ROI was specified.
   * This should be used to ensure that the ROI is applied to an image with the same
   * size / aspect ratio.
   */
  imageCols: number;
  /**
   * Height of the image (in pixels).
   * This should be used to ensure that the ROI is applied to an image with the same
   * size / aspect ratio.
   */
  imageRows: number;
}

export interface RegionOfInterestParam_ServiceAndSource {
  service: string;
  source: string;
}

export interface RegionOfInterestParam_Spec {
  /**
   * Sometimes, which image the ROI will reference is obvious:
   *   - Image services
   *   - Network compute bridge workers that accept a single image
   *
   * Othertimes, it might not be clear which image an ROI is supposed to reference.  In those
   * cases, the Spec for the ROI can advertise which image it wants.
   */
  serviceAndSource:
    | RegionOfInterestParam_ServiceAndSource
    | undefined;
  /**
   * Default value. If unset, UIs can pick their own default OR force user to make a
   * selection.
   */
  defaultArea:
    | AreaI
    | undefined;
  /**
   * Area may eventually contain many shape primatives.  In that case, services can constrain
   * which primatives they accept.  These will be opt in, so that adding new primative types
   * won't be automatically advertised by older services.
   */
  allowsRectangle: boolean;
}

/** Errors specific to the use of custom parameters. */
export interface CustomParamError {
  status: CustomParamError_Status;
  /** List of error messages from this parameter and its children */
  errorMessages: string[];
}

export enum CustomParamError_Status {
  /** STATUS_UNKNOWN - Invalid, do not use. */
  STATUS_UNKNOWN = 0,
  /** STATUS_OK - No problems! */
  STATUS_OK = 1,
  /** STATUS_INVALID_COMBINATION - The combination of parameters was invalid. */
  STATUS_INVALID_COMBINATION = 2,
  /** STATUS_UNSUPPORTED_PARAMETER - One or more of the children parameters is unsupported by the service. */
  STATUS_UNSUPPORTED_PARAMETER = 3,
  /** STATUS_INVALID_VALUE - One or more of the parameters had an invalid value. */
  STATUS_INVALID_VALUE = 4,
  /** STATUS_INVALID_TYPE - One or more of the parameters had an invalid type (e.g. a string when an int was needed). */
  STATUS_INVALID_TYPE = 5,
  UNRECOGNIZED = -1,
}

export function customParamError_StatusFromJSON(object: any): CustomParamError_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return CustomParamError_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_OK":
      return CustomParamError_Status.STATUS_OK;
    case 2:
    case "STATUS_INVALID_COMBINATION":
      return CustomParamError_Status.STATUS_INVALID_COMBINATION;
    case 3:
    case "STATUS_UNSUPPORTED_PARAMETER":
      return CustomParamError_Status.STATUS_UNSUPPORTED_PARAMETER;
    case 4:
    case "STATUS_INVALID_VALUE":
      return CustomParamError_Status.STATUS_INVALID_VALUE;
    case 5:
    case "STATUS_INVALID_TYPE":
      return CustomParamError_Status.STATUS_INVALID_TYPE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CustomParamError_Status.UNRECOGNIZED;
  }
}

export function customParamError_StatusToJSON(object: CustomParamError_Status): string {
  switch (object) {
    case CustomParamError_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case CustomParamError_Status.STATUS_OK:
      return "STATUS_OK";
    case CustomParamError_Status.STATUS_INVALID_COMBINATION:
      return "STATUS_INVALID_COMBINATION";
    case CustomParamError_Status.STATUS_UNSUPPORTED_PARAMETER:
      return "STATUS_UNSUPPORTED_PARAMETER";
    case CustomParamError_Status.STATUS_INVALID_VALUE:
      return "STATUS_INVALID_VALUE";
    case CustomParamError_Status.STATUS_INVALID_TYPE:
      return "STATUS_INVALID_TYPE";
    case CustomParamError_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseCustomParam(): CustomParam {
  return {
    dictValue: undefined,
    listValue: undefined,
    intValue: undefined,
    doubleValue: undefined,
    stringValue: undefined,
    roiValue: undefined,
    boolValue: undefined,
    oneOfValue: undefined,
  };
}

export const CustomParam = {
  encode(message: CustomParam, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dictValue !== undefined) {
      DictParam.encode(message.dictValue, writer.uint32(10).fork()).ldelim();
    }
    if (message.listValue !== undefined) {
      ListParam.encode(message.listValue, writer.uint32(18).fork()).ldelim();
    }
    if (message.intValue !== undefined) {
      Int64Param.encode(message.intValue, writer.uint32(26).fork()).ldelim();
    }
    if (message.doubleValue !== undefined) {
      DoubleParam.encode(message.doubleValue, writer.uint32(34).fork()).ldelim();
    }
    if (message.stringValue !== undefined) {
      StringParam.encode(message.stringValue, writer.uint32(42).fork()).ldelim();
    }
    if (message.roiValue !== undefined) {
      RegionOfInterestParam.encode(message.roiValue, writer.uint32(50).fork()).ldelim();
    }
    if (message.boolValue !== undefined) {
      BoolParam.encode(message.boolValue, writer.uint32(58).fork()).ldelim();
    }
    if (message.oneOfValue !== undefined) {
      OneOfParam.encode(message.oneOfValue, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CustomParam {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomParam();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dictValue = DictParam.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.listValue = ListParam.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.intValue = Int64Param.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.doubleValue = DoubleParam.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.stringValue = StringParam.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.roiValue = RegionOfInterestParam.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.boolValue = BoolParam.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.oneOfValue = OneOfParam.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomParam {
    return {
      dictValue: isSet(object.dictValue) ? DictParam.fromJSON(object.dictValue) : undefined,
      listValue: isSet(object.listValue) ? ListParam.fromJSON(object.listValue) : undefined,
      intValue: isSet(object.intValue) ? Int64Param.fromJSON(object.intValue) : undefined,
      doubleValue: isSet(object.doubleValue) ? DoubleParam.fromJSON(object.doubleValue) : undefined,
      stringValue: isSet(object.stringValue) ? StringParam.fromJSON(object.stringValue) : undefined,
      roiValue: isSet(object.roiValue) ? RegionOfInterestParam.fromJSON(object.roiValue) : undefined,
      boolValue: isSet(object.boolValue) ? BoolParam.fromJSON(object.boolValue) : undefined,
      oneOfValue: isSet(object.oneOfValue) ? OneOfParam.fromJSON(object.oneOfValue) : undefined,
    };
  },

  toJSON(message: CustomParam): unknown {
    const obj: any = {};
    if (message.dictValue !== undefined) {
      obj.dictValue = DictParam.toJSON(message.dictValue);
    }
    if (message.listValue !== undefined) {
      obj.listValue = ListParam.toJSON(message.listValue);
    }
    if (message.intValue !== undefined) {
      obj.intValue = Int64Param.toJSON(message.intValue);
    }
    if (message.doubleValue !== undefined) {
      obj.doubleValue = DoubleParam.toJSON(message.doubleValue);
    }
    if (message.stringValue !== undefined) {
      obj.stringValue = StringParam.toJSON(message.stringValue);
    }
    if (message.roiValue !== undefined) {
      obj.roiValue = RegionOfInterestParam.toJSON(message.roiValue);
    }
    if (message.boolValue !== undefined) {
      obj.boolValue = BoolParam.toJSON(message.boolValue);
    }
    if (message.oneOfValue !== undefined) {
      obj.oneOfValue = OneOfParam.toJSON(message.oneOfValue);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomParam>, I>>(base?: I): CustomParam {
    return CustomParam.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CustomParam>, I>>(object: I): CustomParam {
    const message = createBaseCustomParam();
    message.dictValue = (object.dictValue !== undefined && object.dictValue !== null)
      ? DictParam.fromPartial(object.dictValue)
      : undefined;
    message.listValue = (object.listValue !== undefined && object.listValue !== null)
      ? ListParam.fromPartial(object.listValue)
      : undefined;
    message.intValue = (object.intValue !== undefined && object.intValue !== null)
      ? Int64Param.fromPartial(object.intValue)
      : undefined;
    message.doubleValue = (object.doubleValue !== undefined && object.doubleValue !== null)
      ? DoubleParam.fromPartial(object.doubleValue)
      : undefined;
    message.stringValue = (object.stringValue !== undefined && object.stringValue !== null)
      ? StringParam.fromPartial(object.stringValue)
      : undefined;
    message.roiValue = (object.roiValue !== undefined && object.roiValue !== null)
      ? RegionOfInterestParam.fromPartial(object.roiValue)
      : undefined;
    message.boolValue = (object.boolValue !== undefined && object.boolValue !== null)
      ? BoolParam.fromPartial(object.boolValue)
      : undefined;
    message.oneOfValue = (object.oneOfValue !== undefined && object.oneOfValue !== null)
      ? OneOfParam.fromPartial(object.oneOfValue)
      : undefined;
    return message;
  },
};

function createBaseCustomParam_Spec(): CustomParam_Spec {
  return {
    dictSpec: undefined,
    listSpec: undefined,
    intSpec: undefined,
    doubleSpec: undefined,
    stringSpec: undefined,
    roiSpec: undefined,
    boolSpec: undefined,
    oneOfSpec: undefined,
  };
}

export const CustomParam_Spec = {
  encode(message: CustomParam_Spec, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dictSpec !== undefined) {
      DictParam_Spec.encode(message.dictSpec, writer.uint32(10).fork()).ldelim();
    }
    if (message.listSpec !== undefined) {
      ListParam_Spec.encode(message.listSpec, writer.uint32(18).fork()).ldelim();
    }
    if (message.intSpec !== undefined) {
      Int64Param_Spec.encode(message.intSpec, writer.uint32(26).fork()).ldelim();
    }
    if (message.doubleSpec !== undefined) {
      DoubleParam_Spec.encode(message.doubleSpec, writer.uint32(34).fork()).ldelim();
    }
    if (message.stringSpec !== undefined) {
      StringParam_Spec.encode(message.stringSpec, writer.uint32(42).fork()).ldelim();
    }
    if (message.roiSpec !== undefined) {
      RegionOfInterestParam_Spec.encode(message.roiSpec, writer.uint32(50).fork()).ldelim();
    }
    if (message.boolSpec !== undefined) {
      BoolParam_Spec.encode(message.boolSpec, writer.uint32(58).fork()).ldelim();
    }
    if (message.oneOfSpec !== undefined) {
      OneOfParam_Spec.encode(message.oneOfSpec, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CustomParam_Spec {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomParam_Spec();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dictSpec = DictParam_Spec.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.listSpec = ListParam_Spec.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.intSpec = Int64Param_Spec.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.doubleSpec = DoubleParam_Spec.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.stringSpec = StringParam_Spec.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.roiSpec = RegionOfInterestParam_Spec.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.boolSpec = BoolParam_Spec.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.oneOfSpec = OneOfParam_Spec.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomParam_Spec {
    return {
      dictSpec: isSet(object.dictSpec) ? DictParam_Spec.fromJSON(object.dictSpec) : undefined,
      listSpec: isSet(object.listSpec) ? ListParam_Spec.fromJSON(object.listSpec) : undefined,
      intSpec: isSet(object.intSpec) ? Int64Param_Spec.fromJSON(object.intSpec) : undefined,
      doubleSpec: isSet(object.doubleSpec) ? DoubleParam_Spec.fromJSON(object.doubleSpec) : undefined,
      stringSpec: isSet(object.stringSpec) ? StringParam_Spec.fromJSON(object.stringSpec) : undefined,
      roiSpec: isSet(object.roiSpec) ? RegionOfInterestParam_Spec.fromJSON(object.roiSpec) : undefined,
      boolSpec: isSet(object.boolSpec) ? BoolParam_Spec.fromJSON(object.boolSpec) : undefined,
      oneOfSpec: isSet(object.oneOfSpec) ? OneOfParam_Spec.fromJSON(object.oneOfSpec) : undefined,
    };
  },

  toJSON(message: CustomParam_Spec): unknown {
    const obj: any = {};
    if (message.dictSpec !== undefined) {
      obj.dictSpec = DictParam_Spec.toJSON(message.dictSpec);
    }
    if (message.listSpec !== undefined) {
      obj.listSpec = ListParam_Spec.toJSON(message.listSpec);
    }
    if (message.intSpec !== undefined) {
      obj.intSpec = Int64Param_Spec.toJSON(message.intSpec);
    }
    if (message.doubleSpec !== undefined) {
      obj.doubleSpec = DoubleParam_Spec.toJSON(message.doubleSpec);
    }
    if (message.stringSpec !== undefined) {
      obj.stringSpec = StringParam_Spec.toJSON(message.stringSpec);
    }
    if (message.roiSpec !== undefined) {
      obj.roiSpec = RegionOfInterestParam_Spec.toJSON(message.roiSpec);
    }
    if (message.boolSpec !== undefined) {
      obj.boolSpec = BoolParam_Spec.toJSON(message.boolSpec);
    }
    if (message.oneOfSpec !== undefined) {
      obj.oneOfSpec = OneOfParam_Spec.toJSON(message.oneOfSpec);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomParam_Spec>, I>>(base?: I): CustomParam_Spec {
    return CustomParam_Spec.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CustomParam_Spec>, I>>(object: I): CustomParam_Spec {
    const message = createBaseCustomParam_Spec();
    message.dictSpec = (object.dictSpec !== undefined && object.dictSpec !== null)
      ? DictParam_Spec.fromPartial(object.dictSpec)
      : undefined;
    message.listSpec = (object.listSpec !== undefined && object.listSpec !== null)
      ? ListParam_Spec.fromPartial(object.listSpec)
      : undefined;
    message.intSpec = (object.intSpec !== undefined && object.intSpec !== null)
      ? Int64Param_Spec.fromPartial(object.intSpec)
      : undefined;
    message.doubleSpec = (object.doubleSpec !== undefined && object.doubleSpec !== null)
      ? DoubleParam_Spec.fromPartial(object.doubleSpec)
      : undefined;
    message.stringSpec = (object.stringSpec !== undefined && object.stringSpec !== null)
      ? StringParam_Spec.fromPartial(object.stringSpec)
      : undefined;
    message.roiSpec = (object.roiSpec !== undefined && object.roiSpec !== null)
      ? RegionOfInterestParam_Spec.fromPartial(object.roiSpec)
      : undefined;
    message.boolSpec = (object.boolSpec !== undefined && object.boolSpec !== null)
      ? BoolParam_Spec.fromPartial(object.boolSpec)
      : undefined;
    message.oneOfSpec = (object.oneOfSpec !== undefined && object.oneOfSpec !== null)
      ? OneOfParam_Spec.fromPartial(object.oneOfSpec)
      : undefined;
    return message;
  },
};

function createBaseUserInterfaceInfo(): UserInterfaceInfo {
  return { displayName: "", description: "", displayOrder: 0 };
}

export const UserInterfaceInfo = {
  encode(message: UserInterfaceInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.displayName !== "") {
      writer.uint32(10).string(message.displayName);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.displayOrder !== 0) {
      writer.uint32(24).int64(message.displayOrder);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserInterfaceInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserInterfaceInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.displayOrder = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserInterfaceInfo {
    return {
      displayName: isSet(object.displayName) ? globalThis.String(object.displayName) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      displayOrder: isSet(object.displayOrder) ? globalThis.Number(object.displayOrder) : 0,
    };
  },

  toJSON(message: UserInterfaceInfo): unknown {
    const obj: any = {};
    if (message.displayName !== "") {
      obj.displayName = message.displayName;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.displayOrder !== 0) {
      obj.displayOrder = Math.round(message.displayOrder);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserInterfaceInfo>, I>>(base?: I): UserInterfaceInfo {
    return UserInterfaceInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserInterfaceInfo>, I>>(object: I): UserInterfaceInfo {
    const message = createBaseUserInterfaceInfo();
    message.displayName = object.displayName ?? "";
    message.description = object.description ?? "";
    message.displayOrder = object.displayOrder ?? 0;
    return message;
  },
};

function createBaseCustomParamCollection(): CustomParamCollection {
  return { specs: undefined, values: undefined };
}

export const CustomParamCollection = {
  encode(message: CustomParamCollection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.specs !== undefined) {
      DictParam_Spec.encode(message.specs, writer.uint32(10).fork()).ldelim();
    }
    if (message.values !== undefined) {
      DictParam.encode(message.values, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CustomParamCollection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomParamCollection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.specs = DictParam_Spec.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.values = DictParam.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomParamCollection {
    return {
      specs: isSet(object.specs) ? DictParam_Spec.fromJSON(object.specs) : undefined,
      values: isSet(object.values) ? DictParam.fromJSON(object.values) : undefined,
    };
  },

  toJSON(message: CustomParamCollection): unknown {
    const obj: any = {};
    if (message.specs !== undefined) {
      obj.specs = DictParam_Spec.toJSON(message.specs);
    }
    if (message.values !== undefined) {
      obj.values = DictParam.toJSON(message.values);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomParamCollection>, I>>(base?: I): CustomParamCollection {
    return CustomParamCollection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CustomParamCollection>, I>>(object: I): CustomParamCollection {
    const message = createBaseCustomParamCollection();
    message.specs = (object.specs !== undefined && object.specs !== null)
      ? DictParam_Spec.fromPartial(object.specs)
      : undefined;
    message.values = (object.values !== undefined && object.values !== null)
      ? DictParam.fromPartial(object.values)
      : undefined;
    return message;
  },
};

function createBaseDictParam(): DictParam {
  return { values: {} };
}

export const DictParam = {
  encode(message: DictParam, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.values).forEach(([key, value]) => {
      DictParam_ValuesEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DictParam {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDictParam();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = DictParam_ValuesEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.values[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DictParam {
    return {
      values: isObject(object.values)
        ? Object.entries(object.values).reduce<{ [key: string]: CustomParam }>((acc, [key, value]) => {
          acc[key] = CustomParam.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: DictParam): unknown {
    const obj: any = {};
    if (message.values) {
      const entries = Object.entries(message.values);
      if (entries.length > 0) {
        obj.values = {};
        entries.forEach(([k, v]) => {
          obj.values[k] = CustomParam.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DictParam>, I>>(base?: I): DictParam {
    return DictParam.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DictParam>, I>>(object: I): DictParam {
    const message = createBaseDictParam();
    message.values = Object.entries(object.values ?? {}).reduce<{ [key: string]: CustomParam }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = CustomParam.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseDictParam_ChildSpec(): DictParam_ChildSpec {
  return { spec: undefined, uiInfo: undefined };
}

export const DictParam_ChildSpec = {
  encode(message: DictParam_ChildSpec, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spec !== undefined) {
      CustomParam_Spec.encode(message.spec, writer.uint32(10).fork()).ldelim();
    }
    if (message.uiInfo !== undefined) {
      UserInterfaceInfo.encode(message.uiInfo, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DictParam_ChildSpec {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDictParam_ChildSpec();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spec = CustomParam_Spec.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.uiInfo = UserInterfaceInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DictParam_ChildSpec {
    return {
      spec: isSet(object.spec) ? CustomParam_Spec.fromJSON(object.spec) : undefined,
      uiInfo: isSet(object.uiInfo) ? UserInterfaceInfo.fromJSON(object.uiInfo) : undefined,
    };
  },

  toJSON(message: DictParam_ChildSpec): unknown {
    const obj: any = {};
    if (message.spec !== undefined) {
      obj.spec = CustomParam_Spec.toJSON(message.spec);
    }
    if (message.uiInfo !== undefined) {
      obj.uiInfo = UserInterfaceInfo.toJSON(message.uiInfo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DictParam_ChildSpec>, I>>(base?: I): DictParam_ChildSpec {
    return DictParam_ChildSpec.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DictParam_ChildSpec>, I>>(object: I): DictParam_ChildSpec {
    const message = createBaseDictParam_ChildSpec();
    message.spec = (object.spec !== undefined && object.spec !== null)
      ? CustomParam_Spec.fromPartial(object.spec)
      : undefined;
    message.uiInfo = (object.uiInfo !== undefined && object.uiInfo !== null)
      ? UserInterfaceInfo.fromPartial(object.uiInfo)
      : undefined;
    return message;
  },
};

function createBaseDictParam_Spec(): DictParam_Spec {
  return { specs: {}, isHiddenByDefault: false };
}

export const DictParam_Spec = {
  encode(message: DictParam_Spec, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.specs).forEach(([key, value]) => {
      DictParam_Spec_SpecsEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    if (message.isHiddenByDefault !== false) {
      writer.uint32(24).bool(message.isHiddenByDefault);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DictParam_Spec {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDictParam_Spec();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = DictParam_Spec_SpecsEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.specs[entry2.key] = entry2.value;
          }
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isHiddenByDefault = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DictParam_Spec {
    return {
      specs: isObject(object.specs)
        ? Object.entries(object.specs).reduce<{ [key: string]: DictParam_ChildSpec }>((acc, [key, value]) => {
          acc[key] = DictParam_ChildSpec.fromJSON(value);
          return acc;
        }, {})
        : {},
      isHiddenByDefault: isSet(object.isHiddenByDefault) ? globalThis.Boolean(object.isHiddenByDefault) : false,
    };
  },

  toJSON(message: DictParam_Spec): unknown {
    const obj: any = {};
    if (message.specs) {
      const entries = Object.entries(message.specs);
      if (entries.length > 0) {
        obj.specs = {};
        entries.forEach(([k, v]) => {
          obj.specs[k] = DictParam_ChildSpec.toJSON(v);
        });
      }
    }
    if (message.isHiddenByDefault !== false) {
      obj.isHiddenByDefault = message.isHiddenByDefault;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DictParam_Spec>, I>>(base?: I): DictParam_Spec {
    return DictParam_Spec.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DictParam_Spec>, I>>(object: I): DictParam_Spec {
    const message = createBaseDictParam_Spec();
    message.specs = Object.entries(object.specs ?? {}).reduce<{ [key: string]: DictParam_ChildSpec }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = DictParam_ChildSpec.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.isHiddenByDefault = object.isHiddenByDefault ?? false;
    return message;
  },
};

function createBaseDictParam_Spec_SpecsEntry(): DictParam_Spec_SpecsEntry {
  return { key: "", value: undefined };
}

export const DictParam_Spec_SpecsEntry = {
  encode(message: DictParam_Spec_SpecsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DictParam_ChildSpec.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DictParam_Spec_SpecsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDictParam_Spec_SpecsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = DictParam_ChildSpec.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DictParam_Spec_SpecsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? DictParam_ChildSpec.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DictParam_Spec_SpecsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = DictParam_ChildSpec.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DictParam_Spec_SpecsEntry>, I>>(base?: I): DictParam_Spec_SpecsEntry {
    return DictParam_Spec_SpecsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DictParam_Spec_SpecsEntry>, I>>(object: I): DictParam_Spec_SpecsEntry {
    const message = createBaseDictParam_Spec_SpecsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DictParam_ChildSpec.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseDictParam_ValuesEntry(): DictParam_ValuesEntry {
  return { key: "", value: undefined };
}

export const DictParam_ValuesEntry = {
  encode(message: DictParam_ValuesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      CustomParam.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DictParam_ValuesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDictParam_ValuesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = CustomParam.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DictParam_ValuesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? CustomParam.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DictParam_ValuesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = CustomParam.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DictParam_ValuesEntry>, I>>(base?: I): DictParam_ValuesEntry {
    return DictParam_ValuesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DictParam_ValuesEntry>, I>>(object: I): DictParam_ValuesEntry {
    const message = createBaseDictParam_ValuesEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? CustomParam.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseOneOfParam(): OneOfParam {
  return { key: "", values: {} };
}

export const OneOfParam = {
  encode(message: OneOfParam, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    Object.entries(message.values).forEach(([key, value]) => {
      OneOfParam_ValuesEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OneOfParam {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOneOfParam();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = OneOfParam_ValuesEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.values[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OneOfParam {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      values: isObject(object.values)
        ? Object.entries(object.values).reduce<{ [key: string]: DictParam }>((acc, [key, value]) => {
          acc[key] = DictParam.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: OneOfParam): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.values) {
      const entries = Object.entries(message.values);
      if (entries.length > 0) {
        obj.values = {};
        entries.forEach(([k, v]) => {
          obj.values[k] = DictParam.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OneOfParam>, I>>(base?: I): OneOfParam {
    return OneOfParam.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OneOfParam>, I>>(object: I): OneOfParam {
    const message = createBaseOneOfParam();
    message.key = object.key ?? "";
    message.values = Object.entries(object.values ?? {}).reduce<{ [key: string]: DictParam }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = DictParam.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseOneOfParam_ChildSpec(): OneOfParam_ChildSpec {
  return { spec: undefined, uiInfo: undefined };
}

export const OneOfParam_ChildSpec = {
  encode(message: OneOfParam_ChildSpec, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spec !== undefined) {
      DictParam_Spec.encode(message.spec, writer.uint32(10).fork()).ldelim();
    }
    if (message.uiInfo !== undefined) {
      UserInterfaceInfo.encode(message.uiInfo, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OneOfParam_ChildSpec {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOneOfParam_ChildSpec();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spec = DictParam_Spec.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.uiInfo = UserInterfaceInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OneOfParam_ChildSpec {
    return {
      spec: isSet(object.spec) ? DictParam_Spec.fromJSON(object.spec) : undefined,
      uiInfo: isSet(object.uiInfo) ? UserInterfaceInfo.fromJSON(object.uiInfo) : undefined,
    };
  },

  toJSON(message: OneOfParam_ChildSpec): unknown {
    const obj: any = {};
    if (message.spec !== undefined) {
      obj.spec = DictParam_Spec.toJSON(message.spec);
    }
    if (message.uiInfo !== undefined) {
      obj.uiInfo = UserInterfaceInfo.toJSON(message.uiInfo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OneOfParam_ChildSpec>, I>>(base?: I): OneOfParam_ChildSpec {
    return OneOfParam_ChildSpec.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OneOfParam_ChildSpec>, I>>(object: I): OneOfParam_ChildSpec {
    const message = createBaseOneOfParam_ChildSpec();
    message.spec = (object.spec !== undefined && object.spec !== null)
      ? DictParam_Spec.fromPartial(object.spec)
      : undefined;
    message.uiInfo = (object.uiInfo !== undefined && object.uiInfo !== null)
      ? UserInterfaceInfo.fromPartial(object.uiInfo)
      : undefined;
    return message;
  },
};

function createBaseOneOfParam_Spec(): OneOfParam_Spec {
  return { specs: {}, defaultKey: "" };
}

export const OneOfParam_Spec = {
  encode(message: OneOfParam_Spec, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.specs).forEach(([key, value]) => {
      OneOfParam_Spec_SpecsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    if (message.defaultKey !== "") {
      writer.uint32(18).string(message.defaultKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OneOfParam_Spec {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOneOfParam_Spec();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = OneOfParam_Spec_SpecsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.specs[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.defaultKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OneOfParam_Spec {
    return {
      specs: isObject(object.specs)
        ? Object.entries(object.specs).reduce<{ [key: string]: OneOfParam_ChildSpec }>((acc, [key, value]) => {
          acc[key] = OneOfParam_ChildSpec.fromJSON(value);
          return acc;
        }, {})
        : {},
      defaultKey: isSet(object.defaultKey) ? globalThis.String(object.defaultKey) : "",
    };
  },

  toJSON(message: OneOfParam_Spec): unknown {
    const obj: any = {};
    if (message.specs) {
      const entries = Object.entries(message.specs);
      if (entries.length > 0) {
        obj.specs = {};
        entries.forEach(([k, v]) => {
          obj.specs[k] = OneOfParam_ChildSpec.toJSON(v);
        });
      }
    }
    if (message.defaultKey !== "") {
      obj.defaultKey = message.defaultKey;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OneOfParam_Spec>, I>>(base?: I): OneOfParam_Spec {
    return OneOfParam_Spec.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OneOfParam_Spec>, I>>(object: I): OneOfParam_Spec {
    const message = createBaseOneOfParam_Spec();
    message.specs = Object.entries(object.specs ?? {}).reduce<{ [key: string]: OneOfParam_ChildSpec }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = OneOfParam_ChildSpec.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.defaultKey = object.defaultKey ?? "";
    return message;
  },
};

function createBaseOneOfParam_Spec_SpecsEntry(): OneOfParam_Spec_SpecsEntry {
  return { key: "", value: undefined };
}

export const OneOfParam_Spec_SpecsEntry = {
  encode(message: OneOfParam_Spec_SpecsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      OneOfParam_ChildSpec.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OneOfParam_Spec_SpecsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOneOfParam_Spec_SpecsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = OneOfParam_ChildSpec.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OneOfParam_Spec_SpecsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? OneOfParam_ChildSpec.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: OneOfParam_Spec_SpecsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = OneOfParam_ChildSpec.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OneOfParam_Spec_SpecsEntry>, I>>(base?: I): OneOfParam_Spec_SpecsEntry {
    return OneOfParam_Spec_SpecsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OneOfParam_Spec_SpecsEntry>, I>>(object: I): OneOfParam_Spec_SpecsEntry {
    const message = createBaseOneOfParam_Spec_SpecsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? OneOfParam_ChildSpec.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseOneOfParam_ValuesEntry(): OneOfParam_ValuesEntry {
  return { key: "", value: undefined };
}

export const OneOfParam_ValuesEntry = {
  encode(message: OneOfParam_ValuesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DictParam.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OneOfParam_ValuesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOneOfParam_ValuesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = DictParam.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OneOfParam_ValuesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? DictParam.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: OneOfParam_ValuesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = DictParam.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OneOfParam_ValuesEntry>, I>>(base?: I): OneOfParam_ValuesEntry {
    return OneOfParam_ValuesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OneOfParam_ValuesEntry>, I>>(object: I): OneOfParam_ValuesEntry {
    const message = createBaseOneOfParam_ValuesEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DictParam.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseListParam(): ListParam {
  return { values: [] };
}

export const ListParam = {
  encode(message: ListParam, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.values) {
      CustomParam.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListParam {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListParam();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.values.push(CustomParam.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListParam {
    return {
      values: globalThis.Array.isArray(object?.values) ? object.values.map((e: any) => CustomParam.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListParam): unknown {
    const obj: any = {};
    if (message.values?.length) {
      obj.values = message.values.map((e) => CustomParam.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListParam>, I>>(base?: I): ListParam {
    return ListParam.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListParam>, I>>(object: I): ListParam {
    const message = createBaseListParam();
    message.values = object.values?.map((e) => CustomParam.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListParam_Spec(): ListParam_Spec {
  return { elementSpec: undefined, minNumberOfValues: undefined, maxNumberOfValues: undefined };
}

export const ListParam_Spec = {
  encode(message: ListParam_Spec, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.elementSpec !== undefined) {
      CustomParam_Spec.encode(message.elementSpec, writer.uint32(10).fork()).ldelim();
    }
    if (message.minNumberOfValues !== undefined) {
      Int64Value.encode({ value: message.minNumberOfValues! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.maxNumberOfValues !== undefined) {
      Int64Value.encode({ value: message.maxNumberOfValues! }, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListParam_Spec {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListParam_Spec();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.elementSpec = CustomParam_Spec.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.minNumberOfValues = Int64Value.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.maxNumberOfValues = Int64Value.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListParam_Spec {
    return {
      elementSpec: isSet(object.elementSpec) ? CustomParam_Spec.fromJSON(object.elementSpec) : undefined,
      minNumberOfValues: isSet(object.minNumberOfValues) ? Number(object.minNumberOfValues) : undefined,
      maxNumberOfValues: isSet(object.maxNumberOfValues) ? Number(object.maxNumberOfValues) : undefined,
    };
  },

  toJSON(message: ListParam_Spec): unknown {
    const obj: any = {};
    if (message.elementSpec !== undefined) {
      obj.elementSpec = CustomParam_Spec.toJSON(message.elementSpec);
    }
    if (message.minNumberOfValues !== undefined) {
      obj.minNumberOfValues = message.minNumberOfValues;
    }
    if (message.maxNumberOfValues !== undefined) {
      obj.maxNumberOfValues = message.maxNumberOfValues;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListParam_Spec>, I>>(base?: I): ListParam_Spec {
    return ListParam_Spec.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListParam_Spec>, I>>(object: I): ListParam_Spec {
    const message = createBaseListParam_Spec();
    message.elementSpec = (object.elementSpec !== undefined && object.elementSpec !== null)
      ? CustomParam_Spec.fromPartial(object.elementSpec)
      : undefined;
    message.minNumberOfValues = object.minNumberOfValues ?? undefined;
    message.maxNumberOfValues = object.maxNumberOfValues ?? undefined;
    return message;
  },
};

function createBaseInt64Param(): Int64Param {
  return { value: 0 };
}

export const Int64Param = {
  encode(message: Int64Param, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(8).int64(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Int64Param {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInt64Param();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.value = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Int64Param {
    return { value: isSet(object.value) ? globalThis.Number(object.value) : 0 };
  },

  toJSON(message: Int64Param): unknown {
    const obj: any = {};
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Int64Param>, I>>(base?: I): Int64Param {
    return Int64Param.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Int64Param>, I>>(object: I): Int64Param {
    const message = createBaseInt64Param();
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseInt64Param_Spec(): Int64Param_Spec {
  return { defaultValue: undefined, units: undefined, minValue: undefined, maxValue: undefined };
}

export const Int64Param_Spec = {
  encode(message: Int64Param_Spec, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.defaultValue !== undefined) {
      Int64Value.encode({ value: message.defaultValue! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.units !== undefined) {
      Units.encode(message.units, writer.uint32(26).fork()).ldelim();
    }
    if (message.minValue !== undefined) {
      Int64Value.encode({ value: message.minValue! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.maxValue !== undefined) {
      Int64Value.encode({ value: message.maxValue! }, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Int64Param_Spec {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInt64Param_Spec();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.defaultValue = Int64Value.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.units = Units.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.minValue = Int64Value.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.maxValue = Int64Value.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Int64Param_Spec {
    return {
      defaultValue: isSet(object.defaultValue) ? Number(object.defaultValue) : undefined,
      units: isSet(object.units) ? Units.fromJSON(object.units) : undefined,
      minValue: isSet(object.minValue) ? Number(object.minValue) : undefined,
      maxValue: isSet(object.maxValue) ? Number(object.maxValue) : undefined,
    };
  },

  toJSON(message: Int64Param_Spec): unknown {
    const obj: any = {};
    if (message.defaultValue !== undefined) {
      obj.defaultValue = message.defaultValue;
    }
    if (message.units !== undefined) {
      obj.units = Units.toJSON(message.units);
    }
    if (message.minValue !== undefined) {
      obj.minValue = message.minValue;
    }
    if (message.maxValue !== undefined) {
      obj.maxValue = message.maxValue;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Int64Param_Spec>, I>>(base?: I): Int64Param_Spec {
    return Int64Param_Spec.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Int64Param_Spec>, I>>(object: I): Int64Param_Spec {
    const message = createBaseInt64Param_Spec();
    message.defaultValue = object.defaultValue ?? undefined;
    message.units = (object.units !== undefined && object.units !== null) ? Units.fromPartial(object.units) : undefined;
    message.minValue = object.minValue ?? undefined;
    message.maxValue = object.maxValue ?? undefined;
    return message;
  },
};

function createBaseDoubleParam(): DoubleParam {
  return { value: 0 };
}

export const DoubleParam = {
  encode(message: DoubleParam, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(9).double(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DoubleParam {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDoubleParam();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.value = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DoubleParam {
    return { value: isSet(object.value) ? globalThis.Number(object.value) : 0 };
  },

  toJSON(message: DoubleParam): unknown {
    const obj: any = {};
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DoubleParam>, I>>(base?: I): DoubleParam {
    return DoubleParam.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DoubleParam>, I>>(object: I): DoubleParam {
    const message = createBaseDoubleParam();
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseDoubleParam_Spec(): DoubleParam_Spec {
  return { defaultValue: undefined, units: undefined, minValue: undefined, maxValue: undefined };
}

export const DoubleParam_Spec = {
  encode(message: DoubleParam_Spec, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.defaultValue !== undefined) {
      DoubleValue.encode({ value: message.defaultValue! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.units !== undefined) {
      Units.encode(message.units, writer.uint32(26).fork()).ldelim();
    }
    if (message.minValue !== undefined) {
      DoubleValue.encode({ value: message.minValue! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.maxValue !== undefined) {
      DoubleValue.encode({ value: message.maxValue! }, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DoubleParam_Spec {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDoubleParam_Spec();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.defaultValue = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.units = Units.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.minValue = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.maxValue = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DoubleParam_Spec {
    return {
      defaultValue: isSet(object.defaultValue) ? Number(object.defaultValue) : undefined,
      units: isSet(object.units) ? Units.fromJSON(object.units) : undefined,
      minValue: isSet(object.minValue) ? Number(object.minValue) : undefined,
      maxValue: isSet(object.maxValue) ? Number(object.maxValue) : undefined,
    };
  },

  toJSON(message: DoubleParam_Spec): unknown {
    const obj: any = {};
    if (message.defaultValue !== undefined) {
      obj.defaultValue = message.defaultValue;
    }
    if (message.units !== undefined) {
      obj.units = Units.toJSON(message.units);
    }
    if (message.minValue !== undefined) {
      obj.minValue = message.minValue;
    }
    if (message.maxValue !== undefined) {
      obj.maxValue = message.maxValue;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DoubleParam_Spec>, I>>(base?: I): DoubleParam_Spec {
    return DoubleParam_Spec.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DoubleParam_Spec>, I>>(object: I): DoubleParam_Spec {
    const message = createBaseDoubleParam_Spec();
    message.defaultValue = object.defaultValue ?? undefined;
    message.units = (object.units !== undefined && object.units !== null) ? Units.fromPartial(object.units) : undefined;
    message.minValue = object.minValue ?? undefined;
    message.maxValue = object.maxValue ?? undefined;
    return message;
  },
};

function createBaseStringParam(): StringParam {
  return { value: "" };
}

export const StringParam = {
  encode(message: StringParam, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== "") {
      writer.uint32(10).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StringParam {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStringParam();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StringParam {
    return { value: isSet(object.value) ? globalThis.String(object.value) : "" };
  },

  toJSON(message: StringParam): unknown {
    const obj: any = {};
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StringParam>, I>>(base?: I): StringParam {
    return StringParam.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StringParam>, I>>(object: I): StringParam {
    const message = createBaseStringParam();
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseStringParam_Spec(): StringParam_Spec {
  return { options: [], editable: false, defaultValue: "" };
}

export const StringParam_Spec = {
  encode(message: StringParam_Spec, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.options) {
      writer.uint32(10).string(v!);
    }
    if (message.editable !== false) {
      writer.uint32(16).bool(message.editable);
    }
    if (message.defaultValue !== "") {
      writer.uint32(26).string(message.defaultValue);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StringParam_Spec {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStringParam_Spec();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.options.push(reader.string());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.editable = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.defaultValue = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StringParam_Spec {
    return {
      options: globalThis.Array.isArray(object?.options) ? object.options.map((e: any) => globalThis.String(e)) : [],
      editable: isSet(object.editable) ? globalThis.Boolean(object.editable) : false,
      defaultValue: isSet(object.defaultValue) ? globalThis.String(object.defaultValue) : "",
    };
  },

  toJSON(message: StringParam_Spec): unknown {
    const obj: any = {};
    if (message.options?.length) {
      obj.options = message.options;
    }
    if (message.editable !== false) {
      obj.editable = message.editable;
    }
    if (message.defaultValue !== "") {
      obj.defaultValue = message.defaultValue;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StringParam_Spec>, I>>(base?: I): StringParam_Spec {
    return StringParam_Spec.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StringParam_Spec>, I>>(object: I): StringParam_Spec {
    const message = createBaseStringParam_Spec();
    message.options = object.options?.map((e) => e) || [];
    message.editable = object.editable ?? false;
    message.defaultValue = object.defaultValue ?? "";
    return message;
  },
};

function createBaseBoolParam(): BoolParam {
  return { value: false };
}

export const BoolParam = {
  encode(message: BoolParam, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== false) {
      writer.uint32(8).bool(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BoolParam {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBoolParam();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BoolParam {
    return { value: isSet(object.value) ? globalThis.Boolean(object.value) : false };
  },

  toJSON(message: BoolParam): unknown {
    const obj: any = {};
    if (message.value !== false) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BoolParam>, I>>(base?: I): BoolParam {
    return BoolParam.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BoolParam>, I>>(object: I): BoolParam {
    const message = createBaseBoolParam();
    message.value = object.value ?? false;
    return message;
  },
};

function createBaseBoolParam_Spec(): BoolParam_Spec {
  return { defaultValue: undefined };
}

export const BoolParam_Spec = {
  encode(message: BoolParam_Spec, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.defaultValue !== undefined) {
      BoolValue.encode({ value: message.defaultValue! }, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BoolParam_Spec {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBoolParam_Spec();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.defaultValue = BoolValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BoolParam_Spec {
    return { defaultValue: isSet(object.defaultValue) ? Boolean(object.defaultValue) : undefined };
  },

  toJSON(message: BoolParam_Spec): unknown {
    const obj: any = {};
    if (message.defaultValue !== undefined) {
      obj.defaultValue = message.defaultValue;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BoolParam_Spec>, I>>(base?: I): BoolParam_Spec {
    return BoolParam_Spec.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BoolParam_Spec>, I>>(object: I): BoolParam_Spec {
    const message = createBaseBoolParam_Spec();
    message.defaultValue = object.defaultValue ?? undefined;
    return message;
  },
};

function createBaseRegionOfInterestParam(): RegionOfInterestParam {
  return { area: undefined, serviceAndSource: undefined, imageCols: 0, imageRows: 0 };
}

export const RegionOfInterestParam = {
  encode(message: RegionOfInterestParam, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.area !== undefined) {
      AreaI.encode(message.area, writer.uint32(10).fork()).ldelim();
    }
    if (message.serviceAndSource !== undefined) {
      RegionOfInterestParam_ServiceAndSource.encode(message.serviceAndSource, writer.uint32(18).fork()).ldelim();
    }
    if (message.imageCols !== 0) {
      writer.uint32(24).int32(message.imageCols);
    }
    if (message.imageRows !== 0) {
      writer.uint32(32).int32(message.imageRows);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestParam {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestParam();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.area = AreaI.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serviceAndSource = RegionOfInterestParam_ServiceAndSource.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.imageCols = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.imageRows = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestParam {
    return {
      area: isSet(object.area) ? AreaI.fromJSON(object.area) : undefined,
      serviceAndSource: isSet(object.serviceAndSource)
        ? RegionOfInterestParam_ServiceAndSource.fromJSON(object.serviceAndSource)
        : undefined,
      imageCols: isSet(object.imageCols) ? globalThis.Number(object.imageCols) : 0,
      imageRows: isSet(object.imageRows) ? globalThis.Number(object.imageRows) : 0,
    };
  },

  toJSON(message: RegionOfInterestParam): unknown {
    const obj: any = {};
    if (message.area !== undefined) {
      obj.area = AreaI.toJSON(message.area);
    }
    if (message.serviceAndSource !== undefined) {
      obj.serviceAndSource = RegionOfInterestParam_ServiceAndSource.toJSON(message.serviceAndSource);
    }
    if (message.imageCols !== 0) {
      obj.imageCols = Math.round(message.imageCols);
    }
    if (message.imageRows !== 0) {
      obj.imageRows = Math.round(message.imageRows);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestParam>, I>>(base?: I): RegionOfInterestParam {
    return RegionOfInterestParam.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegionOfInterestParam>, I>>(object: I): RegionOfInterestParam {
    const message = createBaseRegionOfInterestParam();
    message.area = (object.area !== undefined && object.area !== null) ? AreaI.fromPartial(object.area) : undefined;
    message.serviceAndSource = (object.serviceAndSource !== undefined && object.serviceAndSource !== null)
      ? RegionOfInterestParam_ServiceAndSource.fromPartial(object.serviceAndSource)
      : undefined;
    message.imageCols = object.imageCols ?? 0;
    message.imageRows = object.imageRows ?? 0;
    return message;
  },
};

function createBaseRegionOfInterestParam_ServiceAndSource(): RegionOfInterestParam_ServiceAndSource {
  return { service: "", source: "" };
}

export const RegionOfInterestParam_ServiceAndSource = {
  encode(message: RegionOfInterestParam_ServiceAndSource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.service !== "") {
      writer.uint32(10).string(message.service);
    }
    if (message.source !== "") {
      writer.uint32(18).string(message.source);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestParam_ServiceAndSource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestParam_ServiceAndSource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.service = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.source = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestParam_ServiceAndSource {
    return {
      service: isSet(object.service) ? globalThis.String(object.service) : "",
      source: isSet(object.source) ? globalThis.String(object.source) : "",
    };
  },

  toJSON(message: RegionOfInterestParam_ServiceAndSource): unknown {
    const obj: any = {};
    if (message.service !== "") {
      obj.service = message.service;
    }
    if (message.source !== "") {
      obj.source = message.source;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestParam_ServiceAndSource>, I>>(
    base?: I,
  ): RegionOfInterestParam_ServiceAndSource {
    return RegionOfInterestParam_ServiceAndSource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegionOfInterestParam_ServiceAndSource>, I>>(
    object: I,
  ): RegionOfInterestParam_ServiceAndSource {
    const message = createBaseRegionOfInterestParam_ServiceAndSource();
    message.service = object.service ?? "";
    message.source = object.source ?? "";
    return message;
  },
};

function createBaseRegionOfInterestParam_Spec(): RegionOfInterestParam_Spec {
  return { serviceAndSource: undefined, defaultArea: undefined, allowsRectangle: false };
}

export const RegionOfInterestParam_Spec = {
  encode(message: RegionOfInterestParam_Spec, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serviceAndSource !== undefined) {
      RegionOfInterestParam_ServiceAndSource.encode(message.serviceAndSource, writer.uint32(10).fork()).ldelim();
    }
    if (message.defaultArea !== undefined) {
      AreaI.encode(message.defaultArea, writer.uint32(18).fork()).ldelim();
    }
    if (message.allowsRectangle !== false) {
      writer.uint32(24).bool(message.allowsRectangle);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestParam_Spec {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestParam_Spec();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serviceAndSource = RegionOfInterestParam_ServiceAndSource.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.defaultArea = AreaI.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.allowsRectangle = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestParam_Spec {
    return {
      serviceAndSource: isSet(object.serviceAndSource)
        ? RegionOfInterestParam_ServiceAndSource.fromJSON(object.serviceAndSource)
        : undefined,
      defaultArea: isSet(object.defaultArea) ? AreaI.fromJSON(object.defaultArea) : undefined,
      allowsRectangle: isSet(object.allowsRectangle) ? globalThis.Boolean(object.allowsRectangle) : false,
    };
  },

  toJSON(message: RegionOfInterestParam_Spec): unknown {
    const obj: any = {};
    if (message.serviceAndSource !== undefined) {
      obj.serviceAndSource = RegionOfInterestParam_ServiceAndSource.toJSON(message.serviceAndSource);
    }
    if (message.defaultArea !== undefined) {
      obj.defaultArea = AreaI.toJSON(message.defaultArea);
    }
    if (message.allowsRectangle !== false) {
      obj.allowsRectangle = message.allowsRectangle;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestParam_Spec>, I>>(base?: I): RegionOfInterestParam_Spec {
    return RegionOfInterestParam_Spec.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegionOfInterestParam_Spec>, I>>(object: I): RegionOfInterestParam_Spec {
    const message = createBaseRegionOfInterestParam_Spec();
    message.serviceAndSource = (object.serviceAndSource !== undefined && object.serviceAndSource !== null)
      ? RegionOfInterestParam_ServiceAndSource.fromPartial(object.serviceAndSource)
      : undefined;
    message.defaultArea = (object.defaultArea !== undefined && object.defaultArea !== null)
      ? AreaI.fromPartial(object.defaultArea)
      : undefined;
    message.allowsRectangle = object.allowsRectangle ?? false;
    return message;
  },
};

function createBaseCustomParamError(): CustomParamError {
  return { status: 0, errorMessages: [] };
}

export const CustomParamError = {
  encode(message: CustomParamError, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(32).int32(message.status);
    }
    for (const v of message.errorMessages) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CustomParamError {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomParamError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 32) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.errorMessages.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomParamError {
    return {
      status: isSet(object.status) ? customParamError_StatusFromJSON(object.status) : 0,
      errorMessages: globalThis.Array.isArray(object?.errorMessages)
        ? object.errorMessages.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: CustomParamError): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = customParamError_StatusToJSON(message.status);
    }
    if (message.errorMessages?.length) {
      obj.errorMessages = message.errorMessages;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomParamError>, I>>(base?: I): CustomParamError {
    return CustomParamError.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CustomParamError>, I>>(object: I): CustomParamError {
    const message = createBaseCustomParamError();
    message.status = object.status ?? 0;
    message.errorMessages = object.errorMessages?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
