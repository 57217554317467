import { memo } from 'react';

import {
  SLoaderBackdrop,
  SLoaderCircle,
  SLoaderIcon,
  SLoaderLinear,
  SLoaderLinearInner,
} from './Loader.styled';

type LoaderProps = {
  fixed?: boolean;
  linear?: boolean;
  withBackdrop?: boolean;
};

const Loader = memo<LoaderProps>(({ fixed = false, linear = false, withBackdrop = true }) => (
  <>
    {withBackdrop && <SLoaderBackdrop />}

    {linear ? (
      <SLoaderLinear>
        <SLoaderLinearInner />
      </SLoaderLinear>
    ) : (
      <SLoaderCircle fixed={fixed}>
        <SLoaderIcon />
      </SLoaderCircle>
    )}
  </>
));

export default Loader;
