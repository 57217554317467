import { css } from 'styled-components';

import { Theme } from 'types/ui/Theme';

export const theme: Theme = {
  colors: {
    primary: {
      light: '#47b2e9',
      main: 'hsl(var(--primary))', // '#31a0da'
      dark: '#2177a2', // todo remove after replacing checkbox and slider
    },
    background: {
      dark: 'hsl(var(--background))', // #0e1011
      main: 'hsl(var(--card))', // #26282c
      light: 'hsl(var(--border))', // #4a4d51
    },
    text: {
      light: '',
      main: 'hsl(var(--foreground))',
      dark: '#b3b3b3', // maybe muted-foreground
    },
    white: 'hsl(var(--foreground))',
    neutral: '#7c7c7c', // todo: muted?
    border: 'hsl(var(--border))', // #3f3f3f
    // use destructive later when green is adjusted
    red: '#cc3c3c', // red: 'hsl(var(--destructive))', // #cc3c3c
    yellow: '#d7a72f',
    green: '#18a737',
  },
  background: {
    gradientLightCenter: css`
      background: rgb(43, 43, 43);
      background: -moz-radial-gradient(circle, rgba(43, 43, 43, 1) 0%, rgba(17, 17, 17, 1) 100%);
      background: -webkit-radial-gradient(circle, rgba(43, 43, 43, 1) 0%, rgba(17, 17, 17, 1) 100%);
      background: radial-gradient(circle, rgba(43, 43, 43, 1) 0%, rgba(17, 17, 17, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2b2b2b",endColorstr="#111111",GradientType=1);
    `,
  },
  fontSizes: {
    xxxs: '0.75rem',
    xxs: '0.875rem',
    xs: '1rem',
    sm: '1.125rem',
    md: '1.25rem',
    lg: '1.5rem',
    xl: '1.625rem',
    xxl: '1.875rem',
    xxxl: '2.25rem',
  },
  fontFamilies: {
    roboto: '"Roboto",sans-serif',
  },
  spacing: (...args) =>
    args
      .slice(0, 4)
      .map((a) => `${a * 4}px`)
      .join(' '),
  borderRadius: {
    sm: '2px',
    md: '3px',
    lg: '6px',
  },
  awesomegrid: {
    // mediaQuery: 'only screen',
    columns: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
    gutterWidth: {
      xs: 0.75,
      sm: 0.75,
      md: 0.75,
      lg: 0.75,
      xl: 0.75,
    },
    paddingWidth: {
      xs: 0.75,
      sm: 0.75,
      md: 0.75,
      lg: 0.75,
      xl: 0.75,
    },
    // container: {
    //     xs: 'full', // 'full' = max-width: 100%
    //     sm: 'full', // 'full' = max-width: 100%
    //     md: 'full', // 'full' = max-width: 100%
    //     lg: 90, // max-width: 1440px
    //     xl: 90, // max-width: 1440px
    // },
    // breakpoints: {
    //     xs: 1,
    //     sm: 48, // 768px
    //     md: 64, // 1024px
    //     lg: 90, // 1440px
    //     xl: 120 // 1920px
    // }
  },
};
