import { Buffer } from 'buffer';

export const encodeToBase64 = (data: string) => Buffer.from(data).toString('base64');

export const decodeFromBase64 = (data: string) => Buffer.from(data, 'base64').toString();

export const tryParseDateToLocaleString = (date: string) => {
  if (!Number.isNaN(Date.parse(date))) {
    return new Date(date).toLocaleString();
  }
  return date;
};

export const isFirefox = () => {
  //wole list of detectors
  //https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser

  // Firefox 1.0+
  // @ts-ignore
  return typeof InstallTrigger !== 'undefined';
};

export const isPositiveInteger = (num: number) => {
  return Number.isInteger(num) && num > 0;
};

export const isStringPositiveInteger = (str: string) => {
  const num = Number(str);
  return isPositiveInteger(num);
};

export const roundNumber = (number: number) => Math.round(number * 100) / 100;

// Download content as file
export function downloadFile(dataUrl: string, filename: string) {
  const link = document.createElement('a');
  link.href = dataUrl;
  link.download = filename;
  link.click();
}

export function downloadContentAsFile(content: BlobPart, fileName: string, fileType: string) {
  const tempLinkElement = document.createElement('a');
  const file = new Blob([content], { type: fileType });
  tempLinkElement.href = URL.createObjectURL(file);
  tempLinkElement.download = fileName;
  document.body.appendChild(tempLinkElement); // Required for this to work in FireFox
  tempLinkElement.click();
  document.body.removeChild(tempLinkElement);
}

export const getPixelRatio = (ctx: CanvasRenderingContext2D | null) => {
  const dpr = window.devicePixelRatio || 1,
    bsr =
      // @ts-ignore
      ctx?.webkitBackingStorePixelRatio ||
      // @ts-ignore
      ctx?.mozBackingStorePixelRatio ||
      // @ts-ignore
      ctx?.msBackingStorePixelRatio ||
      // @ts-ignore
      ctx?.oBackingStorePixelRatio ||
      // @ts-ignore
      ctx?.backingStorePixelRatio ||
      1;

  return dpr / bsr;
};

export function isTouchDevice() {
  return (
    // @ts-ignore
    'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator?.msMaxTouchPoints > 0
  );
}

export function parseRadiationMapUrl(url?: string) {
  return url?.replaceAll('^&', '&') ?? '';
}

export const getCompanyDomainFromEmail = (email: string) => {
  const domain = email?.split('@').pop() ?? '';
  return domain.substring(0, domain.lastIndexOf('.'));
};

export function boolEmoji(value: boolean): string {
  return value ? '✅' : '❌';
}

export function wait(s: number) {
  return new Promise((resolve) => setTimeout(resolve, s * 1000));
}
