import { useSyncExternalStore } from 'react';

type DebugState = 'true' | 'false';

const getSnapshot = (key: string) => {
  const debug = window.localStorage.getItem(key) as DebugState | null;
  return debug === 'true';
};

/**
 * Todo - test whats happen if use this 100 times in the app.
 */
const subscribe = (listener: () => void) => {
  window.addEventListener('storage', listener);
  return () => void window.removeEventListener('storage', listener);
};

export function useDebug(key: string) {
  const debug = useSyncExternalStore(subscribe, () => getSnapshot(key));

  const setDebug = (debug: boolean) => {
    const newValue = String(debug);
    window.localStorage.setItem(key, String(newValue));
    window.dispatchEvent(new StorageEvent('storage', { key, newValue }));
  };

  return [debug, setDebug] as const;
}
