// @ts-nocheck
import { getAuthContext } from 'lib/authProvider';
import { AzureADState } from 'lib/constants/state';
import React from 'react';
import { runWithAdal } from 'react-adal';
import { createRoot } from 'react-dom/client';

import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { getCompanyDomainFromEmail } from './utils/helpers';

const ALLOW_ANONYMOUS_USERS = false; // Indicates whether the app allows anonymous users

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

getAuthContext()
  .then((authContext) => {
    // TODO -> replace this with react way
    AzureADState.authContext = authContext;
    AzureADState.accountInfo = AzureADState.authContext?.getCachedUser();
    AzureADState.companyDomain = getCompanyDomainFromEmail(
      AzureADState.accountInfo?.userName ?? '',
    );
    runWithAdal(authContext, () => root.render(<App />), ALLOW_ANONYMOUS_USERS);
    registerServiceWorker();
  })
  .catch((err) => {
    console.log(err);
  });
