import { PropsWithChildren, createContext } from 'react';

import { HUB_URL } from '../const';
import { useHubConnection } from './useHubConnection';
import { UseHubConnection } from './useSignalRHub';

type HubProviderState = Record<HUB_URL, UseHubConnection | undefined>;

const defaultValues: HubProviderState = {
  [HUB_URL.IOTHUB]: undefined,
  [HUB_URL.TELEMETRY]: undefined,
  [HUB_URL.TELEOPERATION]: undefined,
  [HUB_URL.SPOTCONTROL]: undefined,
  [HUB_URL.ROSBRIDGE]: undefined,
};

export const HubContext = createContext<HubProviderState>(defaultValues);
type HubContext = typeof HubContext;

/**
 * Provider for all hubs - This way all connection are always on - that's properly not optimal
 * @param children
 * @constructor
 */
export function SignalRHubProvider({ children }: PropsWithChildren) {
  const value: HubProviderState = {
    [HUB_URL.IOTHUB]: useHubConnection(HUB_URL.IOTHUB),
    [HUB_URL.TELEMETRY]: useHubConnection(HUB_URL.TELEMETRY),
    [HUB_URL.TELEOPERATION]: useHubConnection(HUB_URL.TELEOPERATION),
    [HUB_URL.SPOTCONTROL]: useHubConnection(HUB_URL.SPOTCONTROL),
    [HUB_URL.ROSBRIDGE]: useHubConnection(HUB_URL.ROSBRIDGE),
  };

  return <HubContext.Provider value={value}>{children}</HubContext.Provider>;
}
