import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { useEffect, useRef, useState } from 'react';

import { getToken } from 'lib/authProvider';
import {
  SIGNALR_KEEP_ALIVE_INTERVAL_MS,
  SIGNALR_SERVER_TIMEOUT_MS,
} from 'lib/constants/signalR-config';

import { SIGNAL_R_LOG_LEVEL } from 'utils/debugger';

import { Options } from './types';

const DEFAULTS: Options = {
  enabled: true,
  automaticReconnect: true,
  logging: SIGNAL_R_LOG_LEVEL,
  httpTransportTypeOrOptions: {
    accessTokenFactory: getToken,
  },
};

export let defaultOptions: Options = DEFAULTS;

// this is the way the library would set defaults overwrite
// export const setDefaults = (options: Options) => {
//   defaultOptions = {
//     ...DEFAULTS,
//     ...options,
//   };
// };

/**
 * Inspired by This is very good: https://github.com/Darhagonable/use-signalr-hub/blob/main/src/useSignalRHub.ts
 *
 * @param hubUrl
 * @param options
 */
export function useSignalRHubBasic(hubUrl: string, options?: Options) {
  const [signalRHub, setSignalRHub] = useState<HubConnection | null>(null);
  const optionsRef = useRef<Options>({ ...defaultOptions, ...options });

  useEffect(() => {
    optionsRef.current = { ...defaultOptions, ...options };
  }, [options]);

  useEffect(() => {
    if (!optionsRef.current.enabled) return;

    let isCanceled = false;

    const hubConnectionSetup = new HubConnectionBuilder();

    if (optionsRef.current.httpTransportTypeOrOptions)
      // @ts-expect-error: We don't need to adhere to the overloads. https://github.com/microsoft/TypeScript/issues/14107
      hubConnectionSetup.withUrl(hubUrl, optionsRef.current.httpTransportTypeOrOptions);
    else hubConnectionSetup.withUrl(hubUrl);

    if (optionsRef.current.automaticReconnect) {
      if (optionsRef.current.automaticReconnect === true)
        hubConnectionSetup.withAutomaticReconnect();
      // @ts-expect-error: We don't need to adhere to the overloads. https://github.com/microsoft/TypeScript/issues/14107
      else hubConnectionSetup.withAutomaticReconnect(optionsRef.current.automaticReconnect);
    }

    if (optionsRef.current.logging) hubConnectionSetup.configureLogging(optionsRef.current.logging);

    if (optionsRef.current.hubProtocol)
      hubConnectionSetup.withHubProtocol(optionsRef.current.hubProtocol);

    const hubConnection = hubConnectionSetup.build();
    hubConnection.serverTimeoutInMilliseconds = SIGNALR_SERVER_TIMEOUT_MS;
    hubConnection.keepAliveIntervalInMilliseconds = SIGNALR_KEEP_ALIVE_INTERVAL_MS;

    hubConnection
      .start()
      .then(() => {
        if (isCanceled) return hubConnection.stop();

        if (optionsRef.current.onConnected) optionsRef.current.onConnected(hubConnection);

        if (optionsRef.current.onDisconnected)
          hubConnection.onclose(optionsRef.current.onDisconnected);

        if (optionsRef.current.onReconnecting)
          hubConnection.onreconnecting(optionsRef.current.onReconnecting);

        if (optionsRef.current.onReconnected)
          hubConnection.onreconnected(optionsRef.current.onReconnected);

        setSignalRHub(hubConnection);
      })
      .catch((error) => {
        if (isCanceled) return;

        if (optionsRef.current.onError) optionsRef.current.onError(error);
      });

    return () => {
      isCanceled = true;

      if (hubConnection.state === HubConnectionState.Connected) hubConnection.stop();

      setSignalRHub(null);
    };
  }, [hubUrl, optionsRef.current.enabled]);

  return signalRHub;
}
