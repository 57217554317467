import { get } from 'lodash-es';
import styled, { css } from 'styled-components';

import { generateSpacings } from 'components/ui';

import { Spacings } from 'types/ui/Theme';

export type BoxProps = {
  pos?: string;
  display?: string;
  direction?: string;
  align?: string;
  justify?: string;
  wrap?: string;
  flex?: string | number;
  bgcolor?: string;
  w?: string;
  h?: string;
  maxH?: string;
  minH?: string;
  maxW?: string;
  minW?: string;
  overflow?: string;
  gap?: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  disabled?: boolean;
} & Partial<Spacings>;

const SBox = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'pos',
      'display',
      'direction',
      'align',
      'justify',
      'wrap',
      'w',
      'h',
      'maxH',
      'minH',
      'maxW',
      'minW',
      'overflow',
      'gap',
      'm',
      'my',
      'mx',
      'mt',
      'mb',
      'mr',
      'ml',
      'p',
      'py',
      'px',
      'pt',
      'pb',
      'pr',
      'pl',
    ].includes(prop),
})<BoxProps>`
  position: ${(props) => props.pos && props.pos};
  display: ${(props) => props.display && props.display};
  flex-direction: ${(props) => props.direction};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  flex-wrap: ${(props) => props.wrap && props.wrap};
  flex: ${(props) => props.flex && props.flex};
  background-color: ${(props) => props.bgcolor && get(props.theme.colors, props.bgcolor)};
  width: ${(props) => props.w && props.w};
  height: ${(props) => props.h && props.h};
  max-height: ${(props) => props.maxH && props.maxH};
  min-height: ${(props) => props.minH && props.minH};
  max-width: ${(props) => props.maxW && props.maxW};
  min-width: ${(props) => props.minW && props.minW};
  overflow: ${(props) => props.overflow && props.overflow};
  gap: ${(props) => props.gap && props.gap};
  top: ${(props) => props.top && props.top};
  bottom: ${(props) => props.bottom && props.bottom};
  left: ${(props) => props.left && props.left};
  right: ${(props) => props.right && props.right};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
      cursor: not-allowed;
      user-select: none;
    `}

  ${(props) => generateSpacings(props)}
`;

export default SBox;
