/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Duration } from "../../google/protobuf/duration";
import { Timestamp } from "../../google/protobuf/timestamp";
import { FrameTreeSnapshot, Vec2 } from "./geometry";
import { RequestHeader, ResponseHeader } from "./header";
import { CustomParamError, DictParam, DictParam_Spec } from "./service_customization";

export const protobufPackage = "bosdyn.api";

/** Rectangular color/greyscale/depth images. */
export interface Image {
  /** Number of columns in the image (in pixels). */
  cols: number;
  /** Number of rows in the image (in pixels). */
  rows: number;
  /** Raw image data. */
  data: Uint8Array;
  /** How the image is encoded. */
  format: Image_Format;
  /**
   * Pixel format of the image; this will be set even when the Format implies
   * the pixel format.
   */
  pixelFormat: Image_PixelFormat;
}

export enum Image_Format {
  /** FORMAT_UNKNOWN - Unknown image format. */
  FORMAT_UNKNOWN = 0,
  /**
   * FORMAT_JPEG - Color/greyscale formats.
   * JPEG format.
   */
  FORMAT_JPEG = 1,
  /** FORMAT_RAW - Uncompressed.  Requires pixel_format. */
  FORMAT_RAW = 2,
  /** FORMAT_RLE - 1 byte run-length before each pixel value. */
  FORMAT_RLE = 3,
  UNRECOGNIZED = -1,
}

export function image_FormatFromJSON(object: any): Image_Format {
  switch (object) {
    case 0:
    case "FORMAT_UNKNOWN":
      return Image_Format.FORMAT_UNKNOWN;
    case 1:
    case "FORMAT_JPEG":
      return Image_Format.FORMAT_JPEG;
    case 2:
    case "FORMAT_RAW":
      return Image_Format.FORMAT_RAW;
    case 3:
    case "FORMAT_RLE":
      return Image_Format.FORMAT_RLE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Image_Format.UNRECOGNIZED;
  }
}

export function image_FormatToJSON(object: Image_Format): string {
  switch (object) {
    case Image_Format.FORMAT_UNKNOWN:
      return "FORMAT_UNKNOWN";
    case Image_Format.FORMAT_JPEG:
      return "FORMAT_JPEG";
    case Image_Format.FORMAT_RAW:
      return "FORMAT_RAW";
    case Image_Format.FORMAT_RLE:
      return "FORMAT_RLE";
    case Image_Format.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Image_PixelFormat {
  /** PIXEL_FORMAT_UNKNOWN - Unspecified value -- should not be used. */
  PIXEL_FORMAT_UNKNOWN = 0,
  /** PIXEL_FORMAT_GREYSCALE_U8 - One byte per pixel. */
  PIXEL_FORMAT_GREYSCALE_U8 = 1,
  /** PIXEL_FORMAT_RGB_U8 - Three bytes per pixel. */
  PIXEL_FORMAT_RGB_U8 = 3,
  /** PIXEL_FORMAT_RGBA_U8 - Four bytes per pixel. */
  PIXEL_FORMAT_RGBA_U8 = 4,
  /** PIXEL_FORMAT_DEPTH_U16 - Little-endian uint16 z-distance from camera (mm). */
  PIXEL_FORMAT_DEPTH_U16 = 5,
  /** PIXEL_FORMAT_GREYSCALE_U16 - Big-endian uint16 */
  PIXEL_FORMAT_GREYSCALE_U16 = 6,
  UNRECOGNIZED = -1,
}

export function image_PixelFormatFromJSON(object: any): Image_PixelFormat {
  switch (object) {
    case 0:
    case "PIXEL_FORMAT_UNKNOWN":
      return Image_PixelFormat.PIXEL_FORMAT_UNKNOWN;
    case 1:
    case "PIXEL_FORMAT_GREYSCALE_U8":
      return Image_PixelFormat.PIXEL_FORMAT_GREYSCALE_U8;
    case 3:
    case "PIXEL_FORMAT_RGB_U8":
      return Image_PixelFormat.PIXEL_FORMAT_RGB_U8;
    case 4:
    case "PIXEL_FORMAT_RGBA_U8":
      return Image_PixelFormat.PIXEL_FORMAT_RGBA_U8;
    case 5:
    case "PIXEL_FORMAT_DEPTH_U16":
      return Image_PixelFormat.PIXEL_FORMAT_DEPTH_U16;
    case 6:
    case "PIXEL_FORMAT_GREYSCALE_U16":
      return Image_PixelFormat.PIXEL_FORMAT_GREYSCALE_U16;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Image_PixelFormat.UNRECOGNIZED;
  }
}

export function image_PixelFormatToJSON(object: Image_PixelFormat): string {
  switch (object) {
    case Image_PixelFormat.PIXEL_FORMAT_UNKNOWN:
      return "PIXEL_FORMAT_UNKNOWN";
    case Image_PixelFormat.PIXEL_FORMAT_GREYSCALE_U8:
      return "PIXEL_FORMAT_GREYSCALE_U8";
    case Image_PixelFormat.PIXEL_FORMAT_RGB_U8:
      return "PIXEL_FORMAT_RGB_U8";
    case Image_PixelFormat.PIXEL_FORMAT_RGBA_U8:
      return "PIXEL_FORMAT_RGBA_U8";
    case Image_PixelFormat.PIXEL_FORMAT_DEPTH_U16:
      return "PIXEL_FORMAT_DEPTH_U16";
    case Image_PixelFormat.PIXEL_FORMAT_GREYSCALE_U16:
      return "PIXEL_FORMAT_GREYSCALE_U16";
    case Image_PixelFormat.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Sensor parameters associated with an image capture. */
export interface CaptureParameters {
  /** The duration of exposure in microseconds. */
  exposureDuration:
    | Duration
    | undefined;
  /** Sensor gain in dB. */
  gain: number;
  /** Any other custom parameters used in the image capture. */
  customParams: DictParam | undefined;
}

/** Rectangular color/greyscale images. */
export interface ImageCapture {
  /** The time at which the image data was acquired in the robot's time basis. */
  acquisitionTime:
    | Date
    | undefined;
  /**
   * A tree-based collection of transformations, which will include the transformations to each image's
   * sensor in addition to transformations to the common frames ("vision", "body", "odom").
   * All transforms within the snapshot are at the acquistion time of the image.
   */
  transformsSnapshot:
    | FrameTreeSnapshot
    | undefined;
  /** The frame name for the image's sensor source. This will be included in the transform snapshot. */
  frameNameImageSensor: string;
  /** Image data. */
  image:
    | Image
    | undefined;
  /** Sensor parameters associated with this image capture. */
  captureParams: CaptureParameters | undefined;
}

/** Proto for a description of an image source on the robot. */
export interface ImageSource {
  /** The name of this image source used to get images. */
  name: string;
  /** Number of columns in the image (in pixels). */
  cols: number;
  /** Number of rows in the image (in pixels). */
  rows: number;
  /**
   * For depth images, the pixel value that represents a depth of one meter.
   * Depth in meters can be computed by dividing the raw pixel value by this scale factor.
   */
  depthScale: number;
  /** Rectilinear camera model. */
  pinhole?:
    | ImageSource_PinholeModel
    | undefined;
  /** The kind of images returned by this image source. */
  imageType: ImageSource_ImageType;
  /** The pixel formats a specific image source supports. */
  pixelFormats: Image_PixelFormat[];
  /** The image formats a specific image source supports. */
  imageFormats: Image_Format[];
  /** ImageRequest parameters unique to this source that do not match any of the above fields. */
  customParams: DictParam_Spec | undefined;
}

export enum ImageSource_ImageType {
  /** IMAGE_TYPE_UNKNOWN - Unspecified image type. */
  IMAGE_TYPE_UNKNOWN = 0,
  /** IMAGE_TYPE_VISUAL - Color or greyscale intensity image. */
  IMAGE_TYPE_VISUAL = 1,
  /** IMAGE_TYPE_DEPTH - Pixel values represent distances to objects/surfaces. */
  IMAGE_TYPE_DEPTH = 2,
  UNRECOGNIZED = -1,
}

export function imageSource_ImageTypeFromJSON(object: any): ImageSource_ImageType {
  switch (object) {
    case 0:
    case "IMAGE_TYPE_UNKNOWN":
      return ImageSource_ImageType.IMAGE_TYPE_UNKNOWN;
    case 1:
    case "IMAGE_TYPE_VISUAL":
      return ImageSource_ImageType.IMAGE_TYPE_VISUAL;
    case 2:
    case "IMAGE_TYPE_DEPTH":
      return ImageSource_ImageType.IMAGE_TYPE_DEPTH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ImageSource_ImageType.UNRECOGNIZED;
  }
}

export function imageSource_ImageTypeToJSON(object: ImageSource_ImageType): string {
  switch (object) {
    case ImageSource_ImageType.IMAGE_TYPE_UNKNOWN:
      return "IMAGE_TYPE_UNKNOWN";
    case ImageSource_ImageType.IMAGE_TYPE_VISUAL:
      return "IMAGE_TYPE_VISUAL";
    case ImageSource_ImageType.IMAGE_TYPE_DEPTH:
      return "IMAGE_TYPE_DEPTH";
    case ImageSource_ImageType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * The camera can be modeled as a pinhole camera described with a matrix.
 * Camera Matrix can be constructed by the camera intrinsics:
 * [[focal_length.x,         skew.x, principal_point.x],
 * [[        skew.y, focal_length.y, principal_point.y],
 * [[             0,              0,                 1]]
 */
export interface ImageSource_PinholeModel {
  /** The camera intrinsics are necessary for descrbing the pinhole camera matrix. */
  intrinsics: ImageSource_PinholeModel_CameraIntrinsics | undefined;
}

/** Intrinsic parameters are in pixel space. */
export interface ImageSource_PinholeModel_CameraIntrinsics {
  /** The focal length of the camera. */
  focalLength:
    | Vec2
    | undefined;
  /** The optical center in sensor coordinates. */
  principalPoint:
    | Vec2
    | undefined;
  /** The skew for the intrinsic matrix. */
  skew: Vec2 | undefined;
}

/** The ListImageSources request message for the robot image service. */
export interface ListImageSourcesRequest {
  /** Common request header. */
  header: RequestHeader | undefined;
}

/** The ListImageSources response message which contains all known image sources for the robot. */
export interface ListImageSourcesResponse {
  /** Common response Header. */
  header:
    | ResponseHeader
    | undefined;
  /**
   * The set of ImageSources available from this service.
   * May be empty if the service serves no cameras (e.g., if no cameras were found on startup).
   */
  imageSources: ImageSource[];
}

/** The image request specifying the image source and data format desired. */
export interface ImageRequest {
  /** The string name of the image source to get image data from. */
  imageSourceName: string;
  /**
   * Image quality: a number from 0 (worst) to 100 (highest).
   * Note that jpeg quality 100 is still lossy.
   */
  qualityPercent: number;
  /**
   * Specify the desired image encoding (e.g. JPEG, RAW). If no format is specified (e.g. FORMAT_UNKNOWN), the image
   * service will choose the best format for the data.
   */
  imageFormat: Image_Format;
  /**
   * Optional specification of the desired image dimensions.
   * If the original image is 1920x1080, a resize_ratio of (2/3) will return an image with size 1280x720
   * The range is clipped to [0.01, 1] while maintaining the original aspect ratio.
   * For backwards compatibliity, a value of 0 means no resizing.
   * Note: this field is not supported by the robot body cameras image service (`image`).
   */
  resizeRatio: number;
  /**
   * Specify the desired pixel format (e.g. GREYSCALE_U8, RGB_U8). If no format is specified
   * (e.g. PIXEL_FORMAT_UNKNOWN), the image service will choose the best format for the data.
   */
  pixelFormat: Image_PixelFormat;
  /**
   * Parameters unique to the servicer that do not match any of the above fields.
   * Whether or not these are valid may depend on the values of the above fields.
   */
  customParams: DictParam | undefined;
}

/** The GetImage request message which can send multiple different image source requests at once. */
export interface GetImageRequest {
  /** Common request header. */
  header:
    | RequestHeader
    | undefined;
  /** The different image requests for this rpc call. */
  imageRequests: ImageRequest[];
}

/** The image response for each request, that includes image data and image source information. */
export interface ImageResponse {
  /**
   * The image capture contains the image data and information about the state of the camera and robot
   * at the time the image was collected.
   */
  shot:
    | ImageCapture
    | undefined;
  /** The source describes general information about the camera source the image data was collected from. */
  source:
    | ImageSource
    | undefined;
  /** Return status of the request. */
  status: ImageResponse_Status;
  /** Filled out if status is STATUS_CUSTOM_PARAMS_ERROR. */
  customParamError: CustomParamError | undefined;
}

export enum ImageResponse_Status {
  /**
   * STATUS_UNKNOWN - UNKNOWN should never be used.
   * An internal ImageService issue has happened if UNKNOWN is set.
   * None of the other fields are filled out.
   */
  STATUS_UNKNOWN = 0,
  /** STATUS_OK - Call succeeded at filling out all the fields. */
  STATUS_OK = 1,
  /** STATUS_UNKNOWN_CAMERA - Image source name in request is unknown.  Other fields are not filled out. */
  STATUS_UNKNOWN_CAMERA = 2,
  /** STATUS_SOURCE_DATA_ERROR - Failed to fill out ImageSource.  All the other fields are not filled out. */
  STATUS_SOURCE_DATA_ERROR = 3,
  /** STATUS_IMAGE_DATA_ERROR - There was a problem with the image data.  Only the ImageSource is filled out. */
  STATUS_IMAGE_DATA_ERROR = 4,
  /**
   * STATUS_UNSUPPORTED_IMAGE_FORMAT_REQUESTED - The requested image format is unsupported for the image-source named. The image data will
   * not be filled out. Note, if an image request has "FORMAT_UNKNOWN", the service should choose the
   * best format to provide the data in.
   */
  STATUS_UNSUPPORTED_IMAGE_FORMAT_REQUESTED = 5,
  /**
   * STATUS_UNSUPPORTED_PIXEL_FORMAT_REQUESTED - The requested pixel format is unsupported for the image-source named. The image data will
   * not be filled out. Note, if an image request has "PIXEL_FORMAT_UNKNOWN", the service
   * should choose the best format to provide the data in.
   */
  STATUS_UNSUPPORTED_PIXEL_FORMAT_REQUESTED = 6,
  /** STATUS_UNSUPPORTED_RESIZE_RATIO_REQUESTED - The requested ratio is out of bounds [0,1] or unsupported by the image service */
  STATUS_UNSUPPORTED_RESIZE_RATIO_REQUESTED = 7,
  /**
   * STATUS_CUSTOM_PARAMS_ERROR - One or more keys or values in custom_params are unsupported by the image service.
   * See the custom_param_error for details.
   */
  STATUS_CUSTOM_PARAMS_ERROR = 8,
  UNRECOGNIZED = -1,
}

export function imageResponse_StatusFromJSON(object: any): ImageResponse_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return ImageResponse_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_OK":
      return ImageResponse_Status.STATUS_OK;
    case 2:
    case "STATUS_UNKNOWN_CAMERA":
      return ImageResponse_Status.STATUS_UNKNOWN_CAMERA;
    case 3:
    case "STATUS_SOURCE_DATA_ERROR":
      return ImageResponse_Status.STATUS_SOURCE_DATA_ERROR;
    case 4:
    case "STATUS_IMAGE_DATA_ERROR":
      return ImageResponse_Status.STATUS_IMAGE_DATA_ERROR;
    case 5:
    case "STATUS_UNSUPPORTED_IMAGE_FORMAT_REQUESTED":
      return ImageResponse_Status.STATUS_UNSUPPORTED_IMAGE_FORMAT_REQUESTED;
    case 6:
    case "STATUS_UNSUPPORTED_PIXEL_FORMAT_REQUESTED":
      return ImageResponse_Status.STATUS_UNSUPPORTED_PIXEL_FORMAT_REQUESTED;
    case 7:
    case "STATUS_UNSUPPORTED_RESIZE_RATIO_REQUESTED":
      return ImageResponse_Status.STATUS_UNSUPPORTED_RESIZE_RATIO_REQUESTED;
    case 8:
    case "STATUS_CUSTOM_PARAMS_ERROR":
      return ImageResponse_Status.STATUS_CUSTOM_PARAMS_ERROR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ImageResponse_Status.UNRECOGNIZED;
  }
}

export function imageResponse_StatusToJSON(object: ImageResponse_Status): string {
  switch (object) {
    case ImageResponse_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case ImageResponse_Status.STATUS_OK:
      return "STATUS_OK";
    case ImageResponse_Status.STATUS_UNKNOWN_CAMERA:
      return "STATUS_UNKNOWN_CAMERA";
    case ImageResponse_Status.STATUS_SOURCE_DATA_ERROR:
      return "STATUS_SOURCE_DATA_ERROR";
    case ImageResponse_Status.STATUS_IMAGE_DATA_ERROR:
      return "STATUS_IMAGE_DATA_ERROR";
    case ImageResponse_Status.STATUS_UNSUPPORTED_IMAGE_FORMAT_REQUESTED:
      return "STATUS_UNSUPPORTED_IMAGE_FORMAT_REQUESTED";
    case ImageResponse_Status.STATUS_UNSUPPORTED_PIXEL_FORMAT_REQUESTED:
      return "STATUS_UNSUPPORTED_PIXEL_FORMAT_REQUESTED";
    case ImageResponse_Status.STATUS_UNSUPPORTED_RESIZE_RATIO_REQUESTED:
      return "STATUS_UNSUPPORTED_RESIZE_RATIO_REQUESTED";
    case ImageResponse_Status.STATUS_CUSTOM_PARAMS_ERROR:
      return "STATUS_CUSTOM_PARAMS_ERROR";
    case ImageResponse_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * This message is a subset of the ImageResponse message with only the information needed
 * to pass captured images to other services.
 */
export interface ImageCaptureAndSource {
  /**
   * The image capture contains the image data and information about the state of the camera and
   * robot at the time the image was collected.
   */
  shot:
    | ImageCapture
    | undefined;
  /**
   * The source describes general information about the camera source the image data was collected
   * from.
   */
  source:
    | ImageSource
    | undefined;
  /** Image service.  If blank, it is assumed to be the robot's default image service. */
  imageService: string;
}

/** The GetImage response message which includes image data for all requested sources. */
export interface GetImageResponse {
  /** Common response header. */
  header:
    | ResponseHeader
    | undefined;
  /** The ordering of these image responses is defined by the order of the ImageRequests. */
  imageResponses: ImageResponse[];
}

function createBaseImage(): Image {
  return { cols: 0, rows: 0, data: new Uint8Array(0), format: 0, pixelFormat: 0 };
}

export const Image = {
  encode(message: Image, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cols !== 0) {
      writer.uint32(16).int32(message.cols);
    }
    if (message.rows !== 0) {
      writer.uint32(24).int32(message.rows);
    }
    if (message.data.length !== 0) {
      writer.uint32(34).bytes(message.data);
    }
    if (message.format !== 0) {
      writer.uint32(40).int32(message.format);
    }
    if (message.pixelFormat !== 0) {
      writer.uint32(48).int32(message.pixelFormat);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Image {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.cols = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.rows = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.data = reader.bytes();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.format = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.pixelFormat = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Image {
    return {
      cols: isSet(object.cols) ? globalThis.Number(object.cols) : 0,
      rows: isSet(object.rows) ? globalThis.Number(object.rows) : 0,
      data: isSet(object.data) ? bytesFromBase64(object.data) : new Uint8Array(0),
      format: isSet(object.format) ? image_FormatFromJSON(object.format) : 0,
      pixelFormat: isSet(object.pixelFormat) ? image_PixelFormatFromJSON(object.pixelFormat) : 0,
    };
  },

  toJSON(message: Image): unknown {
    const obj: any = {};
    if (message.cols !== 0) {
      obj.cols = Math.round(message.cols);
    }
    if (message.rows !== 0) {
      obj.rows = Math.round(message.rows);
    }
    if (message.data.length !== 0) {
      obj.data = base64FromBytes(message.data);
    }
    if (message.format !== 0) {
      obj.format = image_FormatToJSON(message.format);
    }
    if (message.pixelFormat !== 0) {
      obj.pixelFormat = image_PixelFormatToJSON(message.pixelFormat);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Image>, I>>(base?: I): Image {
    return Image.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Image>, I>>(object: I): Image {
    const message = createBaseImage();
    message.cols = object.cols ?? 0;
    message.rows = object.rows ?? 0;
    message.data = object.data ?? new Uint8Array(0);
    message.format = object.format ?? 0;
    message.pixelFormat = object.pixelFormat ?? 0;
    return message;
  },
};

function createBaseCaptureParameters(): CaptureParameters {
  return { exposureDuration: undefined, gain: 0, customParams: undefined };
}

export const CaptureParameters = {
  encode(message: CaptureParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.exposureDuration !== undefined) {
      Duration.encode(message.exposureDuration, writer.uint32(10).fork()).ldelim();
    }
    if (message.gain !== 0) {
      writer.uint32(17).double(message.gain);
    }
    if (message.customParams !== undefined) {
      DictParam.encode(message.customParams, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CaptureParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCaptureParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.exposureDuration = Duration.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.gain = reader.double();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.customParams = DictParam.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CaptureParameters {
    return {
      exposureDuration: isSet(object.exposureDuration) ? Duration.fromJSON(object.exposureDuration) : undefined,
      gain: isSet(object.gain) ? globalThis.Number(object.gain) : 0,
      customParams: isSet(object.customParams) ? DictParam.fromJSON(object.customParams) : undefined,
    };
  },

  toJSON(message: CaptureParameters): unknown {
    const obj: any = {};
    if (message.exposureDuration !== undefined) {
      obj.exposureDuration = Duration.toJSON(message.exposureDuration);
    }
    if (message.gain !== 0) {
      obj.gain = message.gain;
    }
    if (message.customParams !== undefined) {
      obj.customParams = DictParam.toJSON(message.customParams);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CaptureParameters>, I>>(base?: I): CaptureParameters {
    return CaptureParameters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CaptureParameters>, I>>(object: I): CaptureParameters {
    const message = createBaseCaptureParameters();
    message.exposureDuration = (object.exposureDuration !== undefined && object.exposureDuration !== null)
      ? Duration.fromPartial(object.exposureDuration)
      : undefined;
    message.gain = object.gain ?? 0;
    message.customParams = (object.customParams !== undefined && object.customParams !== null)
      ? DictParam.fromPartial(object.customParams)
      : undefined;
    return message;
  },
};

function createBaseImageCapture(): ImageCapture {
  return {
    acquisitionTime: undefined,
    transformsSnapshot: undefined,
    frameNameImageSensor: "",
    image: undefined,
    captureParams: undefined,
  };
}

export const ImageCapture = {
  encode(message: ImageCapture, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.acquisitionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.acquisitionTime), writer.uint32(242).fork()).ldelim();
    }
    if (message.transformsSnapshot !== undefined) {
      FrameTreeSnapshot.encode(message.transformsSnapshot, writer.uint32(250).fork()).ldelim();
    }
    if (message.frameNameImageSensor !== "") {
      writer.uint32(42).string(message.frameNameImageSensor);
    }
    if (message.image !== undefined) {
      Image.encode(message.image, writer.uint32(26).fork()).ldelim();
    }
    if (message.captureParams !== undefined) {
      CaptureParameters.encode(message.captureParams, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageCapture {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageCapture();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 30:
          if (tag !== 242) {
            break;
          }

          message.acquisitionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.transformsSnapshot = FrameTreeSnapshot.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.frameNameImageSensor = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.image = Image.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.captureParams = CaptureParameters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageCapture {
    return {
      acquisitionTime: isSet(object.acquisitionTime) ? fromJsonTimestamp(object.acquisitionTime) : undefined,
      transformsSnapshot: isSet(object.transformsSnapshot)
        ? FrameTreeSnapshot.fromJSON(object.transformsSnapshot)
        : undefined,
      frameNameImageSensor: isSet(object.frameNameImageSensor) ? globalThis.String(object.frameNameImageSensor) : "",
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
      captureParams: isSet(object.captureParams) ? CaptureParameters.fromJSON(object.captureParams) : undefined,
    };
  },

  toJSON(message: ImageCapture): unknown {
    const obj: any = {};
    if (message.acquisitionTime !== undefined) {
      obj.acquisitionTime = message.acquisitionTime.toISOString();
    }
    if (message.transformsSnapshot !== undefined) {
      obj.transformsSnapshot = FrameTreeSnapshot.toJSON(message.transformsSnapshot);
    }
    if (message.frameNameImageSensor !== "") {
      obj.frameNameImageSensor = message.frameNameImageSensor;
    }
    if (message.image !== undefined) {
      obj.image = Image.toJSON(message.image);
    }
    if (message.captureParams !== undefined) {
      obj.captureParams = CaptureParameters.toJSON(message.captureParams);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageCapture>, I>>(base?: I): ImageCapture {
    return ImageCapture.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImageCapture>, I>>(object: I): ImageCapture {
    const message = createBaseImageCapture();
    message.acquisitionTime = object.acquisitionTime ?? undefined;
    message.transformsSnapshot = (object.transformsSnapshot !== undefined && object.transformsSnapshot !== null)
      ? FrameTreeSnapshot.fromPartial(object.transformsSnapshot)
      : undefined;
    message.frameNameImageSensor = object.frameNameImageSensor ?? "";
    message.image = (object.image !== undefined && object.image !== null) ? Image.fromPartial(object.image) : undefined;
    message.captureParams = (object.captureParams !== undefined && object.captureParams !== null)
      ? CaptureParameters.fromPartial(object.captureParams)
      : undefined;
    return message;
  },
};

function createBaseImageSource(): ImageSource {
  return {
    name: "",
    cols: 0,
    rows: 0,
    depthScale: 0,
    pinhole: undefined,
    imageType: 0,
    pixelFormats: [],
    imageFormats: [],
    customParams: undefined,
  };
}

export const ImageSource = {
  encode(message: ImageSource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.cols !== 0) {
      writer.uint32(32).int32(message.cols);
    }
    if (message.rows !== 0) {
      writer.uint32(40).int32(message.rows);
    }
    if (message.depthScale !== 0) {
      writer.uint32(49).double(message.depthScale);
    }
    if (message.pinhole !== undefined) {
      ImageSource_PinholeModel.encode(message.pinhole, writer.uint32(66).fork()).ldelim();
    }
    if (message.imageType !== 0) {
      writer.uint32(72).int32(message.imageType);
    }
    writer.uint32(82).fork();
    for (const v of message.pixelFormats) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(90).fork();
    for (const v of message.imageFormats) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.customParams !== undefined) {
      DictParam_Spec.encode(message.customParams, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageSource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageSource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.cols = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.rows = reader.int32();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.depthScale = reader.double();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.pinhole = ImageSource_PinholeModel.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.imageType = reader.int32() as any;
          continue;
        case 10:
          if (tag === 80) {
            message.pixelFormats.push(reader.int32() as any);

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.pixelFormats.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 11:
          if (tag === 88) {
            message.imageFormats.push(reader.int32() as any);

            continue;
          }

          if (tag === 90) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.imageFormats.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.customParams = DictParam_Spec.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageSource {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      cols: isSet(object.cols) ? globalThis.Number(object.cols) : 0,
      rows: isSet(object.rows) ? globalThis.Number(object.rows) : 0,
      depthScale: isSet(object.depthScale) ? globalThis.Number(object.depthScale) : 0,
      pinhole: isSet(object.pinhole) ? ImageSource_PinholeModel.fromJSON(object.pinhole) : undefined,
      imageType: isSet(object.imageType) ? imageSource_ImageTypeFromJSON(object.imageType) : 0,
      pixelFormats: globalThis.Array.isArray(object?.pixelFormats)
        ? object.pixelFormats.map((e: any) => image_PixelFormatFromJSON(e))
        : [],
      imageFormats: globalThis.Array.isArray(object?.imageFormats)
        ? object.imageFormats.map((e: any) => image_FormatFromJSON(e))
        : [],
      customParams: isSet(object.customParams) ? DictParam_Spec.fromJSON(object.customParams) : undefined,
    };
  },

  toJSON(message: ImageSource): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.cols !== 0) {
      obj.cols = Math.round(message.cols);
    }
    if (message.rows !== 0) {
      obj.rows = Math.round(message.rows);
    }
    if (message.depthScale !== 0) {
      obj.depthScale = message.depthScale;
    }
    if (message.pinhole !== undefined) {
      obj.pinhole = ImageSource_PinholeModel.toJSON(message.pinhole);
    }
    if (message.imageType !== 0) {
      obj.imageType = imageSource_ImageTypeToJSON(message.imageType);
    }
    if (message.pixelFormats?.length) {
      obj.pixelFormats = message.pixelFormats.map((e) => image_PixelFormatToJSON(e));
    }
    if (message.imageFormats?.length) {
      obj.imageFormats = message.imageFormats.map((e) => image_FormatToJSON(e));
    }
    if (message.customParams !== undefined) {
      obj.customParams = DictParam_Spec.toJSON(message.customParams);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageSource>, I>>(base?: I): ImageSource {
    return ImageSource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImageSource>, I>>(object: I): ImageSource {
    const message = createBaseImageSource();
    message.name = object.name ?? "";
    message.cols = object.cols ?? 0;
    message.rows = object.rows ?? 0;
    message.depthScale = object.depthScale ?? 0;
    message.pinhole = (object.pinhole !== undefined && object.pinhole !== null)
      ? ImageSource_PinholeModel.fromPartial(object.pinhole)
      : undefined;
    message.imageType = object.imageType ?? 0;
    message.pixelFormats = object.pixelFormats?.map((e) => e) || [];
    message.imageFormats = object.imageFormats?.map((e) => e) || [];
    message.customParams = (object.customParams !== undefined && object.customParams !== null)
      ? DictParam_Spec.fromPartial(object.customParams)
      : undefined;
    return message;
  },
};

function createBaseImageSource_PinholeModel(): ImageSource_PinholeModel {
  return { intrinsics: undefined };
}

export const ImageSource_PinholeModel = {
  encode(message: ImageSource_PinholeModel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.intrinsics !== undefined) {
      ImageSource_PinholeModel_CameraIntrinsics.encode(message.intrinsics, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageSource_PinholeModel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageSource_PinholeModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.intrinsics = ImageSource_PinholeModel_CameraIntrinsics.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageSource_PinholeModel {
    return {
      intrinsics: isSet(object.intrinsics)
        ? ImageSource_PinholeModel_CameraIntrinsics.fromJSON(object.intrinsics)
        : undefined,
    };
  },

  toJSON(message: ImageSource_PinholeModel): unknown {
    const obj: any = {};
    if (message.intrinsics !== undefined) {
      obj.intrinsics = ImageSource_PinholeModel_CameraIntrinsics.toJSON(message.intrinsics);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageSource_PinholeModel>, I>>(base?: I): ImageSource_PinholeModel {
    return ImageSource_PinholeModel.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImageSource_PinholeModel>, I>>(object: I): ImageSource_PinholeModel {
    const message = createBaseImageSource_PinholeModel();
    message.intrinsics = (object.intrinsics !== undefined && object.intrinsics !== null)
      ? ImageSource_PinholeModel_CameraIntrinsics.fromPartial(object.intrinsics)
      : undefined;
    return message;
  },
};

function createBaseImageSource_PinholeModel_CameraIntrinsics(): ImageSource_PinholeModel_CameraIntrinsics {
  return { focalLength: undefined, principalPoint: undefined, skew: undefined };
}

export const ImageSource_PinholeModel_CameraIntrinsics = {
  encode(message: ImageSource_PinholeModel_CameraIntrinsics, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.focalLength !== undefined) {
      Vec2.encode(message.focalLength, writer.uint32(10).fork()).ldelim();
    }
    if (message.principalPoint !== undefined) {
      Vec2.encode(message.principalPoint, writer.uint32(18).fork()).ldelim();
    }
    if (message.skew !== undefined) {
      Vec2.encode(message.skew, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageSource_PinholeModel_CameraIntrinsics {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageSource_PinholeModel_CameraIntrinsics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.focalLength = Vec2.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.principalPoint = Vec2.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.skew = Vec2.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageSource_PinholeModel_CameraIntrinsics {
    return {
      focalLength: isSet(object.focalLength) ? Vec2.fromJSON(object.focalLength) : undefined,
      principalPoint: isSet(object.principalPoint) ? Vec2.fromJSON(object.principalPoint) : undefined,
      skew: isSet(object.skew) ? Vec2.fromJSON(object.skew) : undefined,
    };
  },

  toJSON(message: ImageSource_PinholeModel_CameraIntrinsics): unknown {
    const obj: any = {};
    if (message.focalLength !== undefined) {
      obj.focalLength = Vec2.toJSON(message.focalLength);
    }
    if (message.principalPoint !== undefined) {
      obj.principalPoint = Vec2.toJSON(message.principalPoint);
    }
    if (message.skew !== undefined) {
      obj.skew = Vec2.toJSON(message.skew);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageSource_PinholeModel_CameraIntrinsics>, I>>(
    base?: I,
  ): ImageSource_PinholeModel_CameraIntrinsics {
    return ImageSource_PinholeModel_CameraIntrinsics.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImageSource_PinholeModel_CameraIntrinsics>, I>>(
    object: I,
  ): ImageSource_PinholeModel_CameraIntrinsics {
    const message = createBaseImageSource_PinholeModel_CameraIntrinsics();
    message.focalLength = (object.focalLength !== undefined && object.focalLength !== null)
      ? Vec2.fromPartial(object.focalLength)
      : undefined;
    message.principalPoint = (object.principalPoint !== undefined && object.principalPoint !== null)
      ? Vec2.fromPartial(object.principalPoint)
      : undefined;
    message.skew = (object.skew !== undefined && object.skew !== null) ? Vec2.fromPartial(object.skew) : undefined;
    return message;
  },
};

function createBaseListImageSourcesRequest(): ListImageSourcesRequest {
  return { header: undefined };
}

export const ListImageSourcesRequest = {
  encode(message: ListImageSourcesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      RequestHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListImageSourcesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListImageSourcesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = RequestHeader.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListImageSourcesRequest {
    return { header: isSet(object.header) ? RequestHeader.fromJSON(object.header) : undefined };
  },

  toJSON(message: ListImageSourcesRequest): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = RequestHeader.toJSON(message.header);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListImageSourcesRequest>, I>>(base?: I): ListImageSourcesRequest {
    return ListImageSourcesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListImageSourcesRequest>, I>>(object: I): ListImageSourcesRequest {
    const message = createBaseListImageSourcesRequest();
    message.header = (object.header !== undefined && object.header !== null)
      ? RequestHeader.fromPartial(object.header)
      : undefined;
    return message;
  },
};

function createBaseListImageSourcesResponse(): ListImageSourcesResponse {
  return { header: undefined, imageSources: [] };
}

export const ListImageSourcesResponse = {
  encode(message: ListImageSourcesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      ResponseHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.imageSources) {
      ImageSource.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListImageSourcesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListImageSourcesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = ResponseHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.imageSources.push(ImageSource.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListImageSourcesResponse {
    return {
      header: isSet(object.header) ? ResponseHeader.fromJSON(object.header) : undefined,
      imageSources: globalThis.Array.isArray(object?.imageSources)
        ? object.imageSources.map((e: any) => ImageSource.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListImageSourcesResponse): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = ResponseHeader.toJSON(message.header);
    }
    if (message.imageSources?.length) {
      obj.imageSources = message.imageSources.map((e) => ImageSource.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListImageSourcesResponse>, I>>(base?: I): ListImageSourcesResponse {
    return ListImageSourcesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListImageSourcesResponse>, I>>(object: I): ListImageSourcesResponse {
    const message = createBaseListImageSourcesResponse();
    message.header = (object.header !== undefined && object.header !== null)
      ? ResponseHeader.fromPartial(object.header)
      : undefined;
    message.imageSources = object.imageSources?.map((e) => ImageSource.fromPartial(e)) || [];
    return message;
  },
};

function createBaseImageRequest(): ImageRequest {
  return {
    imageSourceName: "",
    qualityPercent: 0,
    imageFormat: 0,
    resizeRatio: 0,
    pixelFormat: 0,
    customParams: undefined,
  };
}

export const ImageRequest = {
  encode(message: ImageRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.imageSourceName !== "") {
      writer.uint32(10).string(message.imageSourceName);
    }
    if (message.qualityPercent !== 0) {
      writer.uint32(17).double(message.qualityPercent);
    }
    if (message.imageFormat !== 0) {
      writer.uint32(24).int32(message.imageFormat);
    }
    if (message.resizeRatio !== 0) {
      writer.uint32(33).double(message.resizeRatio);
    }
    if (message.pixelFormat !== 0) {
      writer.uint32(40).int32(message.pixelFormat);
    }
    if (message.customParams !== undefined) {
      DictParam.encode(message.customParams, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.imageSourceName = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.qualityPercent = reader.double();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.imageFormat = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.resizeRatio = reader.double();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.pixelFormat = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.customParams = DictParam.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageRequest {
    return {
      imageSourceName: isSet(object.imageSourceName) ? globalThis.String(object.imageSourceName) : "",
      qualityPercent: isSet(object.qualityPercent) ? globalThis.Number(object.qualityPercent) : 0,
      imageFormat: isSet(object.imageFormat) ? image_FormatFromJSON(object.imageFormat) : 0,
      resizeRatio: isSet(object.resizeRatio) ? globalThis.Number(object.resizeRatio) : 0,
      pixelFormat: isSet(object.pixelFormat) ? image_PixelFormatFromJSON(object.pixelFormat) : 0,
      customParams: isSet(object.customParams) ? DictParam.fromJSON(object.customParams) : undefined,
    };
  },

  toJSON(message: ImageRequest): unknown {
    const obj: any = {};
    if (message.imageSourceName !== "") {
      obj.imageSourceName = message.imageSourceName;
    }
    if (message.qualityPercent !== 0) {
      obj.qualityPercent = message.qualityPercent;
    }
    if (message.imageFormat !== 0) {
      obj.imageFormat = image_FormatToJSON(message.imageFormat);
    }
    if (message.resizeRatio !== 0) {
      obj.resizeRatio = message.resizeRatio;
    }
    if (message.pixelFormat !== 0) {
      obj.pixelFormat = image_PixelFormatToJSON(message.pixelFormat);
    }
    if (message.customParams !== undefined) {
      obj.customParams = DictParam.toJSON(message.customParams);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageRequest>, I>>(base?: I): ImageRequest {
    return ImageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImageRequest>, I>>(object: I): ImageRequest {
    const message = createBaseImageRequest();
    message.imageSourceName = object.imageSourceName ?? "";
    message.qualityPercent = object.qualityPercent ?? 0;
    message.imageFormat = object.imageFormat ?? 0;
    message.resizeRatio = object.resizeRatio ?? 0;
    message.pixelFormat = object.pixelFormat ?? 0;
    message.customParams = (object.customParams !== undefined && object.customParams !== null)
      ? DictParam.fromPartial(object.customParams)
      : undefined;
    return message;
  },
};

function createBaseGetImageRequest(): GetImageRequest {
  return { header: undefined, imageRequests: [] };
}

export const GetImageRequest = {
  encode(message: GetImageRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      RequestHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.imageRequests) {
      ImageRequest.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetImageRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetImageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = RequestHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.imageRequests.push(ImageRequest.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetImageRequest {
    return {
      header: isSet(object.header) ? RequestHeader.fromJSON(object.header) : undefined,
      imageRequests: globalThis.Array.isArray(object?.imageRequests)
        ? object.imageRequests.map((e: any) => ImageRequest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetImageRequest): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = RequestHeader.toJSON(message.header);
    }
    if (message.imageRequests?.length) {
      obj.imageRequests = message.imageRequests.map((e) => ImageRequest.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetImageRequest>, I>>(base?: I): GetImageRequest {
    return GetImageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetImageRequest>, I>>(object: I): GetImageRequest {
    const message = createBaseGetImageRequest();
    message.header = (object.header !== undefined && object.header !== null)
      ? RequestHeader.fromPartial(object.header)
      : undefined;
    message.imageRequests = object.imageRequests?.map((e) => ImageRequest.fromPartial(e)) || [];
    return message;
  },
};

function createBaseImageResponse(): ImageResponse {
  return { shot: undefined, source: undefined, status: 0, customParamError: undefined };
}

export const ImageResponse = {
  encode(message: ImageResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.shot !== undefined) {
      ImageCapture.encode(message.shot, writer.uint32(10).fork()).ldelim();
    }
    if (message.source !== undefined) {
      ImageSource.encode(message.source, writer.uint32(18).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(32).int32(message.status);
    }
    if (message.customParamError !== undefined) {
      CustomParamError.encode(message.customParamError, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.shot = ImageCapture.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.source = ImageSource.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.customParamError = CustomParamError.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageResponse {
    return {
      shot: isSet(object.shot) ? ImageCapture.fromJSON(object.shot) : undefined,
      source: isSet(object.source) ? ImageSource.fromJSON(object.source) : undefined,
      status: isSet(object.status) ? imageResponse_StatusFromJSON(object.status) : 0,
      customParamError: isSet(object.customParamError) ? CustomParamError.fromJSON(object.customParamError) : undefined,
    };
  },

  toJSON(message: ImageResponse): unknown {
    const obj: any = {};
    if (message.shot !== undefined) {
      obj.shot = ImageCapture.toJSON(message.shot);
    }
    if (message.source !== undefined) {
      obj.source = ImageSource.toJSON(message.source);
    }
    if (message.status !== 0) {
      obj.status = imageResponse_StatusToJSON(message.status);
    }
    if (message.customParamError !== undefined) {
      obj.customParamError = CustomParamError.toJSON(message.customParamError);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageResponse>, I>>(base?: I): ImageResponse {
    return ImageResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImageResponse>, I>>(object: I): ImageResponse {
    const message = createBaseImageResponse();
    message.shot = (object.shot !== undefined && object.shot !== null)
      ? ImageCapture.fromPartial(object.shot)
      : undefined;
    message.source = (object.source !== undefined && object.source !== null)
      ? ImageSource.fromPartial(object.source)
      : undefined;
    message.status = object.status ?? 0;
    message.customParamError = (object.customParamError !== undefined && object.customParamError !== null)
      ? CustomParamError.fromPartial(object.customParamError)
      : undefined;
    return message;
  },
};

function createBaseImageCaptureAndSource(): ImageCaptureAndSource {
  return { shot: undefined, source: undefined, imageService: "" };
}

export const ImageCaptureAndSource = {
  encode(message: ImageCaptureAndSource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.shot !== undefined) {
      ImageCapture.encode(message.shot, writer.uint32(10).fork()).ldelim();
    }
    if (message.source !== undefined) {
      ImageSource.encode(message.source, writer.uint32(18).fork()).ldelim();
    }
    if (message.imageService !== "") {
      writer.uint32(26).string(message.imageService);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageCaptureAndSource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageCaptureAndSource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.shot = ImageCapture.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.source = ImageSource.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.imageService = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageCaptureAndSource {
    return {
      shot: isSet(object.shot) ? ImageCapture.fromJSON(object.shot) : undefined,
      source: isSet(object.source) ? ImageSource.fromJSON(object.source) : undefined,
      imageService: isSet(object.imageService) ? globalThis.String(object.imageService) : "",
    };
  },

  toJSON(message: ImageCaptureAndSource): unknown {
    const obj: any = {};
    if (message.shot !== undefined) {
      obj.shot = ImageCapture.toJSON(message.shot);
    }
    if (message.source !== undefined) {
      obj.source = ImageSource.toJSON(message.source);
    }
    if (message.imageService !== "") {
      obj.imageService = message.imageService;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageCaptureAndSource>, I>>(base?: I): ImageCaptureAndSource {
    return ImageCaptureAndSource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImageCaptureAndSource>, I>>(object: I): ImageCaptureAndSource {
    const message = createBaseImageCaptureAndSource();
    message.shot = (object.shot !== undefined && object.shot !== null)
      ? ImageCapture.fromPartial(object.shot)
      : undefined;
    message.source = (object.source !== undefined && object.source !== null)
      ? ImageSource.fromPartial(object.source)
      : undefined;
    message.imageService = object.imageService ?? "";
    return message;
  },
};

function createBaseGetImageResponse(): GetImageResponse {
  return { header: undefined, imageResponses: [] };
}

export const GetImageResponse = {
  encode(message: GetImageResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      ResponseHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.imageResponses) {
      ImageResponse.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetImageResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetImageResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = ResponseHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.imageResponses.push(ImageResponse.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetImageResponse {
    return {
      header: isSet(object.header) ? ResponseHeader.fromJSON(object.header) : undefined,
      imageResponses: globalThis.Array.isArray(object?.imageResponses)
        ? object.imageResponses.map((e: any) => ImageResponse.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetImageResponse): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = ResponseHeader.toJSON(message.header);
    }
    if (message.imageResponses?.length) {
      obj.imageResponses = message.imageResponses.map((e) => ImageResponse.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetImageResponse>, I>>(base?: I): GetImageResponse {
    return GetImageResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetImageResponse>, I>>(object: I): GetImageResponse {
    const message = createBaseGetImageResponse();
    message.header = (object.header !== undefined && object.header !== null)
      ? ResponseHeader.fromPartial(object.header)
      : undefined;
    message.imageResponses = object.imageResponses?.map((e) => ImageResponse.fromPartial(e)) || [];
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
