import styled, { css } from 'styled-components';

import { SButton } from 'components/ui';

export const SModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing(2, 4)};
`;

export const SModalCloseButton = styled(SButton)`
  margin-left: auto;
  background-color: transparent;

  & > svg {
    color: ${(props) => props.theme.colors.text.dark};
  }

  &:hover,
  &:active {
    background-color: transparent;

    svg {
      color: ${(props) => props.theme.colors.text.main};
    }
  }
`;

export const SModalBody = styled.div<{ fullscreen?: boolean }>`
  overflow: auto;
  ${(props) => props.theme.background.gradientLightCenter};

  ${(props) =>
    props.fullscreen
      ? css`
          flex: 1;
        `
      : css`
          max-height: 70vh;
          padding: ${props.theme.spacing(3, 0)};
        `};
`;

export const SModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${(props) => props.theme.spacing(4)};

  & > * {
    margin: ${(props) => props.theme.spacing(0, 1)};
  }
`;
