import { ROOT_ROUTES } from 'pages/routes-names';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import { EmptyContent } from 'components/common';
import { STypography } from 'components/ui';

import { SErrorContainer } from './RobotErrorScreen.styled';

/**
 * RobotErrorScreen component
 * @param {String} error - Error message
 */

const RobotErrorScreen = memo<{ error: string }>(({ error }) => (
  <SErrorContainer>
    <EmptyContent title="Something went wrong here." description={error}>
      <STypography variant="body1">- or -</STypography>
      <STypography variant="subtitle1">
        {'Back to your '}
        <Link to={`/${ROOT_ROUTES.HOME.path}`}>
          <u>Home Page</u>
        </Link>
      </STypography>
    </EmptyContent>
  </SErrorContainer>
));

export default RobotErrorScreen;
