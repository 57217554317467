import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { NotificationSeverityIcon } from 'components/common/notification-severity-icon/NotificationSeverityIcon';
import { SBox } from 'components/ui';

const BIG_SIZE = '240px';
const SMALL_SIZE = '60px';
const SIDE_PADDING = 1.5;

type SSidebarStyledProps = {
  isClosed: boolean;
};

export const SSidebar = styled(SBox).withConfig({
  shouldForwardProp: (prop) => !['isClosed'].includes(prop),
})`
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: width 125ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: ${BIG_SIZE};
  flex-shrink: 0;
  background-color: ${(props) => props.theme.colors.background.main};

  ${({ isClosed }: SSidebarStyledProps) =>
    isClosed &&
    css`
      width: ${SMALL_SIZE};
      transition: all 125ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

      ${SLogoContainer} {
        .logo-big {
          opacity: 0;
          width: 0;
          transition-delay: 0s;
        }

        .logo-small {
          opacity: 1;
          width: 100%;
          transition-delay: 0.3s;
        }
      }

      ${SNavigationLink} {
        & > span {
          opacity: 0;
          transition: all 0.2s;
        }
      }

      ${SCurrentRobotName} {
        padding: ${(props) => props.theme.spacing(SIDE_PADDING + 2.5, 0)};

        & > svg,
        & > span {
          transition-delay: 0s, 0.2s, 0.2s, 0.2s;
          transition: opacity 0.2s, width 0s, padding 0s, margin 0s;
          opacity: 0;
          width: 0;
          padding: 0;
          margin: 0;
        }

        & > div:last-child {
          margin-right: auto;
        }
      }

      ${SToggleItem} {
        & svg {
          transform: scale(-1);
        }
      }
    `}
`;

export const SLogoContainer = styled.div`
  width: 100%;
  height: 50px;
  padding: ${(props) => props.theme.spacing(4, SIDE_PADDING + 3, 0, SIDE_PADDING + 3)};
  margin-bottom: ${(props) => props.theme.spacing(4.5)};

  & > svg {
    height: 100%;
    transition: opacity 0.2s, width 0s;
    display: inline;
  }

  .logo-big {
    opacity: 1;
    transition-delay: 0.3s;
  }

  .logo-small {
    width: 0;
    opacity: 0;
  }
`;

export const SCurrentRobotName = styled(SBox)`
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing(SIDE_PADDING + 2.5, SIDE_PADDING + 3)};
  color: ${(props) => props.theme.colors.text.main};
  background-color: ${(props) => props.theme.colors.background.dark};

  & > svg,
  & > span {
    transition-delay: 0.25s, 0s, 0s, 0s;
    transition: opacity 0.5s, width 0s, padding 0s, margin 0s;
  }

  & > svg {
    color: inherit;
    font-size: 1rem;
    flex-shrink: 0;
  }

  & > span {
    color: inherit;
    font-size: 0.812rem;
    font-weight: 600;
    margin-bottom: 0;
    margin-left: ${(props) => props.theme.spacing(5)};
  }

  & > div:last-child {
    margin-left: auto;
  }
`;

export const SNavigationList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-y: auto;
`;

type SNavigationItemProps = {
  noPadding?: boolean;
};

export const SNavigationItem = styled.li<SNavigationItemProps>`
  flex: 1 1 auto;
  margin: ${(props) => props.theme.spacing(SIDE_PADDING / 2, 0)};
  padding: ${(props) => (props.noPadding ? 0 : props.theme.spacing(0, SIDE_PADDING))};
`;

export const SNavigationDivider = styled.hr`
  border: ${(props) => `1px solid ${props.theme.colors.border}`};
`;

type SNavigationLinkProps = {
  disabled?: boolean;
};

export const SNavigationLink = styled(NavLink)`
  display: flex;
  align-items: center;
  transition: all 0.15s;
  white-space: nowrap;
  border: none;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: ${(props) => props.theme.spacing(1.9, 3)};
  border-radius: ${(props) => props.theme.borderRadius.lg};
  color: ${(props) => props.theme.colors.text.main};
  background-color: ${(props) => props.theme.colors.background.main};

  ${({ disabled }: SNavigationLinkProps) =>
    disabled &&
    css`
      pointer-events: none !important;
      color: ${(props) => props.theme.colors.neutral};
    `}

  &.active {
    background-color: ${(props) => props.theme.colors.primary.main};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.light};
  }

  & > svg {
    color: inherit;
    font-size: 1rem;
    flex-shrink: 0;
  }

  & > span {
    color: inherit;
    transition: all 0.5s;
    font-size: 0.812rem;
    font-weight: 600;
    margin-bottom: 0;
    margin-left: ${(props) => props.theme.spacing(5)};
    white-space: nowrap;
  }
`;

export const SNavigationLinkWarningIcon = styled(NotificationSeverityIcon)`
  margin-left: auto;
  color: ${(props) => props.theme.colors.red} !important;
`;

export const SToggleItem = styled.div`
  margin-top: auto;
  padding: ${(props) => props.theme.spacing(SIDE_PADDING)};
  border-top: ${(props) => `1px solid ${props.theme.colors.border}`};
`;
