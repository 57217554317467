import { AxiosRequestConfig } from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { axiosRequest } from 'lib/api/axios';

import { logRed } from 'utils/debugger';

type RequestBody = Record<string, unknown>;

type AxiosObjectArgs = {
  method: string;
  url: string;
  body?: RequestBody;
  options?: AxiosRequestConfig;
  withErrorSnack?: boolean;
  errorMessage?: string;
  successMessage?: string;
};

type AxiosSeqArgs = [
  method: AxiosObjectArgs['method'],
  url: AxiosObjectArgs['url'],
  body: AxiosObjectArgs['body'],
  options: AxiosObjectArgs['options'],
  withErrorSnack: AxiosObjectArgs['withErrorSnack'],
  errorMessage: AxiosObjectArgs['errorMessage'],
  successMessage: AxiosObjectArgs['successMessage'],
];

type RequestArg = AxiosSeqArgs | [AxiosObjectArgs];

export type RequestFn = (...arg: RequestArg) => Promise<any>;

function useAxios<T = any>(): [RequestFn, { data: any; error: any; isLoading: boolean }] {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [data, setData] = useState<T | undefined>(undefined);

  const request: RequestFn = async (...arg: RequestArg) => {
    const [
      arg1,
      urlArg,
      bodyArg,
      optionsArg,
      withErrorSnackArg,
      errorMessageArg,
      successMessageArg,
    ] = arg;
    const isArgObject = typeof arg1 === 'object' && arg1 !== null;
    const method = isArgObject ? arg1.method : arg1;
    const url = isArgObject ? arg1.url : urlArg ?? '';
    const body = isArgObject ? arg1.body : bodyArg;
    const options = isArgObject ? arg1.options : optionsArg;
    const withErrorSnack = isArgObject ? arg1.withErrorSnack : withErrorSnackArg;
    const errorMessage = isArgObject ? arg1.errorMessage : errorMessageArg;
    const successMessage = isArgObject ? arg1.successMessage : successMessageArg;

    setLoading(true);
    try {
      const res = await axiosRequest<T>(method, url, body, options);
      setData(res.data);
      setError(undefined);
      setLoading(false);

      if (successMessage) {
        toast.success(successMessage, {
          toastId: successMessage,
        });
      }

      return res.data;
    } catch (err) {
      setError(err);
      setData(undefined);
      setLoading(false);

      logRed('useAxios:error', { err, arg, data });
      if (withErrorSnack || errorMessage) {
        toast.error(errorMessage || err.message, {
          toastId: errorMessage || err.message,
        });
      }

      throw err;
    }
  };

  return [
    request,
    {
      data,
      error,
      isLoading,
    },
  ];
}

export default useAxios;
