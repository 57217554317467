import { useEffect, useRef, useState } from 'react';

import { Page } from 'components/common';
import Navbar from 'components/layouts/nav-bar';
import { STypography } from 'components/ui';

import { useGamepadConnected, useUpdateGamepad } from 'store/gamepad';
import { useGamepadButtonValue, useGamepadXboxButtonPressed } from 'store/gamepad/gamepad.actions';

import useAnimationFrame from 'hooks/common/use-animation-frame';
import useInterval from 'hooks/common/useInterval';
import { useGamepadListenerConnection } from 'hooks/complex/gamepad/useGamepadListenerConnection';
import { handleAxesInputs, handleControllerInputs } from 'hooks/complex/gamepad/util/handlers';
import { MOVEMENT_MAPPER } from 'hooks/complex/gamepad/util/mappers/defaultMappers';
import { getGamepadFromNavigator } from 'hooks/complex/gamepad/util/native';

import { logRed } from 'utils/debugger';
import { boolEmoji } from 'utils/helpers';

import {
  XboxOneGamepadButton,
  XboxOneGamepadStick,
  XboxOneGamepadStickAdditionalOption,
} from 'types/models/gamepad';

type LastPressedButtonRef = {
  button: string | null;
  numberOfIntervals: number;
};

type LastMovedAxesRef = {
  axis: XboxOneGamepadStick | XboxOneGamepadStickAdditionalOption | null;
};

function GamepadConnected() {
  const { gamepadConnected } = useGamepadConnected();

  useEffect(() => {
    console.log('gamepadConnected state changed', gamepadConnected);
  }, [gamepadConnected]);

  const gamepadConnectedText = boolEmoji(gamepadConnected);
  return (
    <div className="space-y-2">
      <STypography variant="h5">Gamepad connected?</STypography>
      <STypography variant="caption">
        Related to the state of the gamepad we use in our app
      </STypography>
      <div className="border rounded p-4">
        <div>Gamepad connected: {gamepadConnectedText}</div>
      </div>
    </div>
  );
}

function GamepadsConnected() {
  const [gamepads, setGamepads] = useState<(Gamepad | null)[]>([]);

  useInterval(() => {
    const gamepads = navigator.getGamepads();
    setGamepads(gamepads);
  }, 100);

  return (
    <div className="space-y-2">
      <STypography variant="h5">All 4 gamepad states from browser</STypography>
      <STypography variant="caption">
        Browser can max connect to 4 gamepads. This can be a hugh range on devices. Our current
        implementation use the first objet in this list of 4. This get updated every 0.1s
      </STypography>
      {gamepads.map((gamepad, index) => (
        <div className="border rounded p-4" key={index}>
          {index + 1}:{' '}
          {gamepad?.id && (
            <div>
              <div>id: {gamepad.id}</div>
              <div>connected: {boolEmoji(gamepad.connected)}</div>
              <div>timestamp: {gamepad.timestamp}</div>
              <div>mapping: {gamepad.mapping}</div>
              <div>axes: {gamepad.axes.join(', ')}</div>
              <div>buttons: {gamepad.buttons.map((button) => button.pressed).join(', ')}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

function TestGamepad() {
  useGamepadListenerConnection();
  const { gamepadConnected } = useGamepadConnected();

  const updateGamepad = useUpdateGamepad();

  const lastPressedButton = useRef<LastPressedButtonRef>({
    button: null,
    numberOfIntervals: 0,
  });

  const lastMovedAxes = useRef<LastMovedAxesRef>({
    axis: null,
  });

  function handleButtonInputs() {
    const gamepadNative = getGamepadFromNavigator();
    console.log('handleButtonInputs', !!gamepadNative);

    if (!gamepadNative) {
      return;
    }

    const { buttons, axes } = gamepadNative;

    // this executes the actions for the buttons and axes
    lastPressedButton.current = handleControllerInputs(
      buttons,
      MOVEMENT_MAPPER,
      lastPressedButton.current,
    );
    lastMovedAxes.current = handleAxesInputs(MOVEMENT_MAPPER, axes, lastMovedAxes.current);

    // the gamepad object only gets a new referenced if the state changes (found out observing)
    updateGamepad(gamepadNative);
  }

  // useEffect(() => {
  //   if (!gamepadConnected) return;
  //   const onInterval = () => handleButtonInputs();
  //   const timer = setInterval(onInterval, 100);
  //   return () => clearInterval(timer);
  // }, [gamepadConnected]);

  useAnimationFrame((e) => {
    if (e.delta > 0.1) {
      logRed(`last check for gamepad input was before: ${e}`);
    }
    if (!gamepadConnected) return;
    handleButtonInputs();
  });

  return <div>Test version of our listener implementation - should not update anymore </div>;
}

function ButtonListener({ buttonKey, label }: { buttonKey: XboxOneGamepadButton; label: string }) {
  const pressed = useGamepadXboxButtonPressed(buttonKey);
  const value = useGamepadButtonValue(buttonKey);

  const text = boolEmoji(pressed);

  return (
    <div className="border rounded p-4">
      <div>
        {label}: {buttonKey}
      </div>
      <div>Pressed: {text}</div>
      <div>Value: {value}</div>
    </div>
  );
}

function ButtonsListener() {
  return (
    <div className="space-y-2">
      <STypography variant="h5">Button atoms update</STypography>
      <STypography variant="caption">
        We want to know if recoil is a good choice for this
      </STypography>
      <div className="grid gap-2 grid-cols-4">
        {Object.entries(XboxOneGamepadButton).map(([label, keyId]) => (
          <ButtonListener label={label} buttonKey={keyId} key={keyId} />
        ))}
      </div>
    </div>
  );
}

const TestGamepadPage = () => {
  return (
    <>
      <Navbar />
      <Page pageHeader={<Page.Header title="Test Gamepad" />}>
        <main className="space-y-4 container">
          <ButtonsListener />
          <GamepadConnected />
          <GamepadsConnected />
          <TestGamepad />
        </main>
      </Page>
    </>
  );
};

export default TestGamepadPage;
