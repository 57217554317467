import { Check, ChevronsUpDown } from 'lucide-react';
import { useDeferredValue, useMemo, useState } from 'react';
import { cn } from 'utils';

import { Button } from 'components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';

type Item = {
  value: string;
  label: string;
};
type ComboboxDemoProps = {
  items: Item[];
  nameOfItem: string;
  /**
   *
   */
  value: string;
  setValue: (value: string) => void;
};

export function Combobox({ items, nameOfItem, value, setValue }: ComboboxDemoProps) {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const deferredSearchValue = useDeferredValue(searchValue);
  // const isStale = searchValue !== deferredSearchValue;

  const filteredItems = useMemo(() => {
    const lowerCaseValue = deferredSearchValue.toLowerCase();
    return items.filter((item) => item.value.toLowerCase().includes(lowerCaseValue));
  }, [deferredSearchValue]);

  const buttonText = useMemo(() => {
    return value ? items.find((item) => item.value === value)?.label : `Select a ${nameOfItem}`;
  }, [value]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[500px] justify-between"
        >
          {buttonText}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[--radix-popover-trigger-width] p-0">
        <Command
          // filter={(value, search) => {
          //   if (value.includes(search.toLowerCase())) {
          //     return 1;
          //   }
          //   return 0;
          // }}
          shouldFilter={false}
        >
          <CommandInput
            onValueChange={(v) => setSearchValue(v)}
            value={searchValue}
            placeholder={`Search ${nameOfItem}...`}
          />
          <CommandEmpty>No {nameOfItem} found.</CommandEmpty>
          <CommandList>
            <CommandGroup>
              {filteredItems.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={(currentValue: string) => {
                    setValue(currentValue === value ? '' : currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      value === item.value ? 'opacity-100' : 'opacity-0',
                    )}
                  />
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
