import { Slot } from '@radix-ui/react-slot';
import { ReactElement, ReactNode, forwardRef, useRef, useState } from 'react';

import { Portal } from 'components/common/index';

import { STooltip } from './Tooltip.styled';

import { TooltipPlacements, getPoint } from './Tooltip.helpers';

type TooltipProps = {
  text: string | ReactNode;
  placement?: TooltipPlacements;
  space?: number;
  disabled?: boolean;
  delay?: number;
  bgcolor?: string;
  size?: number;
  children: ReactElement<any>;
};

// MutableRefObject<HTMLSpanElement>
const Tooltip = forwardRef<HTMLElement, TooltipProps>(
  (
    {
      text,
      placement = TooltipPlacements.BOTTOM,
      space = 5,
      disabled,
      delay,
      bgcolor,
      size,
      children,
    },
    ref,
  ) => {
    const [show, setShow] = useState<0 | 1>(0);
    const posRef = useRef<{
      x: number | null;
      y: number | null;
    }>({
      x: 0,
      y: 0,
    });
    const tooltipRef = useRef<HTMLSpanElement | null>(null);
    const containerRef = useRef<HTMLElement | null>(null);

    const handleMouseOver = () => {
      if (!show) {
        // To follow cursor
        // x = event.clientX;
        // y = event.clientY;

        if (tooltipRef.current && typeof ref !== 'function') {
          if (ref?.current || containerRef?.current) {
            posRef.current = getPoint(
              (ref?.current || containerRef?.current) as HTMLElement, // e.target -> use it for exact item || containerRef.current -> use it for main element
              tooltipRef.current,
              placement,
              space,
            );

            setShow(1);
          }
        }
      }
    };

    const handleMouseOut = () => {
      setShow(0);
    };

    return (
      <>
        {disabled ? (
          children
        ) : (
          <Slot
            ref={ref || containerRef}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onFocus={handleMouseOver}
            onBlur={handleMouseOut}
          >
            {children}
          </Slot>
        )}
        {!disabled && (
          <Portal>
            <STooltip
              ref={tooltipRef}
              posRef={posRef.current}
              show={show}
              delay={delay}
              bgcolor={bgcolor}
              size={size}
              placement={placement}
            >
              {text}
            </STooltip>
          </Portal>
        )}
      </>
    );
  },
);

export default Tooltip;
