import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';

import { useDeviceName } from 'store/my-robot';

import {
  ArmCommand_Feedback,
  NamedArmPositionsCommand_Feedback,
  NamedArmPositionsCommand_Feedback_Status,
  namedArmPositionsCommand_Feedback_StatusToJSON,
} from 'lib/generated/proto/bosdyn/api/arm_command';
import {
  ArmDragCommand_Feedback,
  ArmDragCommand_Feedback_Status,
  RobotCommandFeedbackStatus_Status,
  armDragCommand_Feedback_StatusToJSON,
  robotCommandFeedbackStatus_StatusToJSON,
} from 'lib/generated/proto/bosdyn/api/basic_command';
import {
  ClawGripperCommand_Feedback,
  ClawGripperCommand_Feedback_Status,
  GripperCommand_Feedback,
  clawGripperCommand_Feedback_StatusToJSON,
} from 'lib/generated/proto/bosdyn/api/gripper_command';
import { RobotCommandFeedback } from 'lib/generated/proto/bosdyn/api/robot_command';
import { SynchronizedCommand_Feedback } from 'lib/generated/proto/bosdyn/api/synchronized_command';

import { logGreen } from 'utils/debugger';

import {
  IRosTypeBosdynApiMsgsArmCommandFeedback,
  IRosTypeBosdynApiMsgsArmDragCommandFeedback,
  IRosTypeBosdynApiMsgsGripperCommandFeedback,
  IRosTypeBosdynApiMsgsNamedArmPositionsCommandFeedback,
  IRosTypeBosdynApiMsgsRobotCommandFeedback,
  IRosTypeBosdynApiMsgsRobotCommandFeedbackStatusStatus,
  IRosTypeBosdynApiMsgsSynchronizedCommandFeedback,
  IRosTypeSpotMsgsRobotCommandActionFeedback,
  IRosTypeSpotMsgsRobotCommandActionResult,
} from 'types/generated/ros-msgs';

import { robotCommandFeedbackAtom } from './atoms';

/**
 * Not very useful currently - for debugging and errors properly
 *
 * Docs: https://dev.bostondynamics.com/protos/bosdyn/api/proto_reference#robotcommandfeedbackstatus-status
 *
 * STATUS_UNKNOWN	0	Behavior execution is in an unknown / unexpected state.
 * STATUS_PROCESSING	1	The robot is actively working on the command
 * STATUS_COMMAND_OVERRIDDEN	2	The command was replaced by a new command
 * STATUS_COMMAND_TIMED_OUT	3	The command expired
 * STATUS_ROBOT_FROZEN	4	The robot is in an unsafe state, and will only respond to known safe commands.
 * STATUS_INCOMPATIBLE_HARDWARE	5	The request cannot be executed because the required hardware is missing.
 *  For example, an armless robot receiving a synchronized command with an arm_command
 *  request will return this value in the arm_command_feedback status.
 */
function parseRobotCommandFeedbackStatus(
  status: IRosTypeBosdynApiMsgsRobotCommandFeedbackStatusStatus,
) {
  return status.value as RobotCommandFeedbackStatus_Status;
}

function parseGripperCommandFeedback(
  gripper_command_feedback: IRosTypeBosdynApiMsgsGripperCommandFeedback,
) {
  if (!gripper_command_feedback) return;
  // general feedback for the command
  const gripperStatus = parseRobotCommandFeedbackStatus(gripper_command_feedback.status);
  logGreen(
    'gripper_command_feedback:gripperStatus',
    gripperStatus,
    robotCommandFeedbackStatus_StatusToJSON(gripperStatus),
  );

  // Feedback for the claw gripper command.
  const { claw_gripper_feedback } = gripper_command_feedback.command;

  /**
   * docs: https://dev.bostondynamics.com/protos/bosdyn/api/proto_reference#clawgrippercommand-feedback-status
   * STATUS_UNKNOWN	0	STATUS_UNKNOWN should never be used. If used, an internal error has happened.
   * STATUS_IN_PROGRESS	1	The gripper is opening or closing.
   * STATUS_AT_GOAL	2	The gripper is at the final point of the trajectory.
   * STATUS_APPLYING_FORCE	3	During a close, detected contact and transitioned to force control.
   */
  const clawStatus = claw_gripper_feedback?.status.value as ClawGripperCommand_Feedback_Status;
  logGreen(
    'gripper_command_feedback:clawStatus',
    clawGripperCommand_Feedback_StatusToJSON(clawStatus),
  );

  const clawGripperFeedback = ClawGripperCommand_Feedback.create({
    status: clawStatus,
  });

  return GripperCommand_Feedback.create({
    status: gripperStatus,
    clawGripperFeedback,
  });
}

/**
 * Docs: https://dev.bostondynamics.com/protos/bosdyn/api/proto_reference#namedarmpositionscommand-feedback-status
 *
 * STATUS_UNKNOWN	0	STATUS_UNKNOWN should never be used. If used, an internal error has happened.
 * STATUS_COMPLETE	1	The arm is at the desired configuration.
 * STATUS_IN_PROGRESS	2	Robot is re-configuring arm to get to desired configuration.
 * STATUS_STALLED_HOLDING_ITEM	3	Some requests may not execute if the gripper is holding an item declared not
 *  stowable, e.g. POSITIONS_STOW with carry_state == CARRY_STATE_CARRIABLE. In these
 *  situations, Spot will instead run an ArmStopCommand request while the blocking
 *  condition remains true. Clearing the condition will cause the request to proceed and
 *  the arm will start moving.
 */
function parseNamedArmPositionCommandFeedback(
  named_arm_position_feedback: IRosTypeBosdynApiMsgsNamedArmPositionsCommandFeedback,
) {
  if (!named_arm_position_feedback) return;
  const namedArmPositionStatus = named_arm_position_feedback.status
    .value as NamedArmPositionsCommand_Feedback_Status;

  logGreen(
    'parseNamedArmPositionCommandFeedback',
    namedArmPositionStatus,
    namedArmPositionsCommand_Feedback_StatusToJSON(namedArmPositionStatus),
  );

  return NamedArmPositionsCommand_Feedback.create({
    status: namedArmPositionStatus,
  });
}

function parseArmDragFeedback(arm_drag_feedback: IRosTypeBosdynApiMsgsArmDragCommandFeedback) {
  if (!arm_drag_feedback) return;

  const armDragFeedback = arm_drag_feedback.status.value as ArmDragCommand_Feedback_Status;

  logGreen(
    'parseArmDragFeedback',
    armDragFeedback,
    armDragCommand_Feedback_StatusToJSON(armDragFeedback),
  );

  return ArmDragCommand_Feedback.create({
    status: armDragFeedback,
  });
}

function parseArmCommandFeedback(arm_command_feedback: IRosTypeBosdynApiMsgsArmCommandFeedback) {
  if (!arm_command_feedback) return;

  const { named_arm_position_feedback, arm_drag_feedback } = arm_command_feedback.feedback;
  const namedArmPositionFeedback = parseNamedArmPositionCommandFeedback(
    named_arm_position_feedback,
  );

  const armDragFeedback = parseArmDragFeedback(arm_drag_feedback);
  // note - its always 0 but currently also nobody is using it
  const armCommandStatus = parseRobotCommandFeedbackStatus(arm_command_feedback.status);

  return ArmCommand_Feedback.create({
    namedArmPositionFeedback,
    armDragFeedback,
    status: armCommandStatus,
  });
}

/**
 * Both, arm and gripper feedback provide a status field
 *
 * {
 *     "data": {
 *         "feedback": {
 *             "feedback": {
 *                 "command": {
 *                     "synchronized_feedback": {
 *                         "arm_command_feedback": {
 *                             "feedback": {
 *                                 "named_arm_position_feedback": {
 *                                     "status": {
 *                                         "value": 2
 *                                     }
 *                                 }
 *                             },
 *                             "status": {
 *                                 "value": 1
 *                             }
 *                         }
 *                     }
 *                 }
 *             }
 *         },
 *         "id": 4
 *     }
 * }
 */
function parseSynchronizedFeedback(
  synchronized_feedback: IRosTypeBosdynApiMsgsSynchronizedCommandFeedback,
) {
  const { gripper_command_feedback, arm_command_feedback } = synchronized_feedback;
  const gripperCommandFeedback = parseGripperCommandFeedback(gripper_command_feedback);
  const armCommandFeedback = parseArmCommandFeedback(arm_command_feedback);

  return SynchronizedCommand_Feedback.create({
    gripperCommandFeedback,
    armCommandFeedback,
  });
}

/**
 * {
 *     "result": {
 *         "result": {
 *             "command": {
 *                 "synchronized_feedback": {
 *                     "gripper_command_feedback": {
 *                         "command": {
 *                             "claw_gripper_feedback": {
 *                                 "status": {
 *                                     "value": 2
 *                                 }
 *                             }
 *                         },
 *                         "status": {
 *                             "value": 1
 *                         }
 *                     }
 *                 }
 *             }
 *         },
 *         "success": true,
 *         "message": "Successfully completed command"
 *     },
 *     "id": 4
 * }
 * @param result
 */

/**
 * The will parse the response from feedback/response (witch are the same)
 *
 * @param feedback
 */
function parseRobotCommandFeedback(feedback: IRosTypeBosdynApiMsgsRobotCommandFeedback) {
  // logGreen('parseRobotCommandFeedback:feedback', feedback);
  const feedBack = feedback.command.synchronized_feedback;
  const synchronizedFeedback = parseSynchronizedFeedback(feedBack);

  return RobotCommandFeedback.create({ synchronizedFeedback });
}

/**
 * In general we will be informed if a command will be overwritten
 */
export function useRobotCommandFeedbackSetter() {
  const { deviceName } = useDeviceName();
  const setRobotCommandFeedback = useSetRecoilState(robotCommandFeedbackAtom(deviceName));

  /**
   * Todo: ID not handled, we assume that only one command can currently proceed but that's not true.
   *  We want to ensure that the ui is blocked until command finished
   */
  function handleRobotCommandFeedback(payload: any) {
    // note sure where the payload type comes from
    const { feedback } = payload.data.feedback as IRosTypeSpotMsgsRobotCommandActionFeedback;
    const newState = parseRobotCommandFeedback(feedback);
    setRobotCommandFeedback(newState);
  }

  // command with [id] is finished
  function handleRobotCommandResult(payload: any) {
    // const { id } = payload.data;
    const { success, message, result } = payload.data
      .result as IRosTypeSpotMsgsRobotCommandActionResult;
    if (!success) {
      return toast(message, { type: 'error' });
    }
    const newState = parseRobotCommandFeedback(result);
    setRobotCommandFeedback(newState);
  }

  return { handleRobotCommandFeedback, handleRobotCommandResult };
}
