import { ImgHTMLAttributes, memo } from 'react';

import { Loader } from 'components/common/index';
import { STypography } from 'components/ui';
import { BoxProps } from 'components/ui/components/box/Box';

import { useImage } from 'hooks';

import { SImg } from './Image.styled';

type ImageProps = BoxProps & ImgHTMLAttributes<HTMLImageElement>;

const Image = memo<ImageProps>(({ src, alt = '', ...props }) => {
  const { isLoaded: isLoadedImage, isError: isErrorImage } = useImage(src);

  return isErrorImage ? (
    <STypography variant="body2">Failed to load Image</STypography>
  ) : !isLoadedImage ? (
    <Loader linear />
  ) : (
    <SImg as="img" src={src} alt={alt} {...props} />
  );
});

export default Image;
