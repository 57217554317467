import { atom, selector } from 'recoil';

import { MyRobotManager } from 'lib/api/myRobot';

import { MyRobotsPrimaryInformationResponseDTO } from 'types/models/Robot';

/**
 * Note: for replacing this we have to check the useQuery docs for locally updating the cache
 */
export const allRobotsPrimaryInformationAtom = atom<MyRobotsPrimaryInformationResponseDTO[]>({
  key: 'allRobotsPrimaryInformationAtom',
  default: selector<MyRobotsPrimaryInformationResponseDTO[]>({
    key: 'allRobotsPrimaryInformationAtomSelector',
    get: MyRobotManager.getAllRobotPrimaryInformation,
  }),
});
