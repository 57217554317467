import { DEBUG } from './consts';

export enum DebugMessageColor {
  BLUE = 'blue',
  GREEN = 'green',
  GREEN_DARK = 'green2',
  RED = 'red',
  YELLOW = 'yellow',
}

export const colorOption = {
  [DebugMessageColor.BLUE]: '#47b2e9',
  [DebugMessageColor.GREEN]: '#4cd964',
  [DebugMessageColor.RED]: '#e74c3c',
  [DebugMessageColor.GREEN_DARK]: '#3e750d',
  [DebugMessageColor.YELLOW]: '#f1c40f',
};

export function locColor(color: string, text: string, ...etc: unknown[]) {
  console.log(`%c ${text}`, `background: black; color: ${color};`, ...etc);
}

export function debugMessage(
  type: DebugMessageColor = DebugMessageColor.BLUE,
  text: string,
  ...etc: unknown[]
) {
  locColor(colorOption[type], text, ...etc);
}

export function logBlue(text: string, ...etc: unknown[]) {
  debugMessage(DebugMessageColor.BLUE, text, ...etc);
}

export function logGreen(text: string, ...etc: unknown[]) {
  debugMessage(DebugMessageColor.GREEN, text, ...etc);
}

export function logRed(text: string, ...etc: unknown[]) {
  debugMessage(DebugMessageColor.RED, text, ...etc);
}

export function logYellow(text: string, ...etc: unknown[]) {
  debugMessage(DebugMessageColor.YELLOW, text, ...etc);
}

export function debugJanus(text: string, ...etc: unknown[]) {
  if (!DEBUG) return;
  logYellow(text, ...etc);
}
