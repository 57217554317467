import { ComponentType, Suspense, SuspenseProps } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Loader } from 'components/common/index';
import ErrorBoundaryFallback from 'components/complex/error-boundary-fallback/ErrorBoundaryFallback';

export function withAsyncState<P extends object>(
  WrappedComponent: ComponentType<P>,
  fallback: SuspenseProps['fallback'] = null,
) {
  function ComponentWithSuspense(props: P) {
    return (
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <Suspense fallback={fallback || <Loader fixed />}>
          <WrappedComponent {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  }

  return ComponentWithSuspense;
}
