import { StreamSource } from 'types/ui/Camera';

import { axiosRequest } from '../axios';
import { ManualControlUrls } from '../urls';

export class ManualControlManager {
  static async AvailableStreamSources(deviceName: string) {
    const { method, url } = ManualControlUrls.AvailableStreamSources;
    const response = await axiosRequest<StreamSource[]>(method, url(deviceName));

    if (response.status === 200) {
      return response.data;
    }

    if (response.status === 204) {
      return [];
    }
    // todo - handle error
    return [];
  }
}
