import { selector } from 'recoil';

import {
  BatteryStatus,
  ConnectionState,
  DeviceName,
  MyRobotConfigurationStatusEnum,
  NetworkStatus,
  OperatingStatus,
  ROBOT_MODEL,
} from 'types/models/Robot';
import { RobotActionTemplateDTO } from 'types/models/RobotAction';

import {
  myRobotActionTemplatesAtom,
  myRobotAtom,
  myRobotPrimaryInformationAtom,
  robotStatusSelector,
} from './myRobot.atoms';

function throwProtectAccessError() {
  throw new Error(
    "using hook 'useMyRobot' or related atoms outside of 'LayoutRobotManagement' is not possible",
  );
}

// Robot data
export const myRobotIdSelector = selector<number>({
  key: 'myRobotIdSelector',
  get: ({ get }) => {
    const myRobotData = get(myRobotAtom);
    const myRobotId = myRobotData.data?.myRobotId;

    if (!myRobotId) throwProtectAccessError();

    return myRobotId as number;
  },
});

/**
 * This get loaded in the LayoutRobotManagement.tsx witch is so high up that every selector won't get rendered before this was loaded
 * Cause rhe recoil root is event higher some default states are properly already set.
 * The big question is will the app still works if the change the robot from the manuel control page
 * and will the app in general work properly if we change deviceName.
 *
 */
// This get loaded in the LayoutRobotManagement.tsx witch is so high up that every selector won't get rendered before this was loaded
export const deviceNameSelector = selector<DeviceName>({
  key: 'deviceNameSelector',
  get: ({ get }) => {
    const myRobotData = get(myRobotAtom);
    const deviceName = myRobotData.data?.iotHubDeviceName;

    if (!deviceName) throwProtectAccessError();

    return deviceName as DeviceName;
  },
});

export const myRobotConfigurationStatusSelector = selector<MyRobotConfigurationStatusEnum>({
  key: 'myRobotConfigurationStatusSelector',
  get: ({ get }) => {
    const myRobotData = get(myRobotAtom);
    const configurationStatus = myRobotData.data?.configurationStatus;

    if (!configurationStatus) throwProtectAccessError();

    return configurationStatus as MyRobotConfigurationStatusEnum;
  },
});

export const isSpotSelector = selector({
  key: 'isSpotSelector',
  get: ({ get }) => {
    const myRobotPrimaryInformation = get(myRobotPrimaryInformationAtom);
    return myRobotPrimaryInformation.data?.modelName === ROBOT_MODEL.SPOT;
  },
});

export const isMagniSelector = selector({
  key: 'isMagniSelector',
  get: ({ get }) => {
    const myRobotPrimaryInformation = get(myRobotPrimaryInformationAtom);
    return myRobotPrimaryInformation.data?.modelName === ROBOT_MODEL.MAGNI;
  },
});

export const myRobotBatteryStatusSelector = selector<BatteryStatus | Record<string, never>>({
  key: 'myRobotBatteryStatusSelector',
  get: ({ get }) => {
    const robotStatus = get(robotStatusSelector);

    return robotStatus?.batteryStatus || {};
  },
});

export const myRobotNetworkStatusSelector = selector<NetworkStatus | Record<string, never>>({
  key: 'myRobotNetworkStatusSelector',
  get: ({ get }) => {
    const robotStatus = get(robotStatusSelector);
    return robotStatus?.networkStatus || {};
  },
});

export const myRobotConnectionStateSelector = selector<ConnectionState['state'] | undefined>({
  key: 'myRobotConnectionStateSelector',
  get: ({ get }) => {
    const myRobotPrimaryInformation = get(myRobotPrimaryInformationAtom);
    return myRobotPrimaryInformation.data?.robotInfo?.connectionState?.state?.toLowerCase();
  },
});

export const myRobotOperatingStatusSelector = selector<OperatingStatus | undefined>({
  key: 'myRobotOperatingStatusSelector',
  get: ({ get }) => {
    const robotStatus = get(robotStatusSelector);
    return robotStatus?.operatingStatus;
  },
});

export const isRecordingMapSelector = selector<boolean | undefined>({
  key: 'isRecordingMapSelector',
  get: ({ get }) => {
    const robotStatus = get(robotStatusSelector);
    return robotStatus?.isMapRecording;
  },
});

export const myRobotActionTemplatesWithoutParamsSelector = selector<RobotActionTemplateDTO[]>({
  key: 'myRobotActionTemplatesWithoutParamsSelector',
  get: async ({ get }) => {
    const myRobotActionTemplates = get(myRobotActionTemplatesAtom);
    return myRobotActionTemplates.filter(
      (action) => action.requiredParameterTemplates?.length === 0,
    );
  },
});
