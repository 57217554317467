import { lighten } from 'polished';
import styled from 'styled-components';

import { STypography } from 'components/ui';

import { hsLFromCssVar } from 'utils/helpers/hsl-from-css-var';

// Modify this only
const gap = 3;
const wrapperBaseSize = 22;
// Modify this only

const wrapperWidth = wrapperBaseSize * 1.5 + gap * 2;
const wrapperHeight = wrapperBaseSize;

const btnSize = wrapperBaseSize - gap * 2;

export const CheckBoxWrapper = styled.label`
  display: inline-flex;
  align-items: center;
`;

export const CheckBoxMark = styled.span`
  cursor: pointer;
  position: relative;
  display: inline-block;
  box-sizing: content-box;
  width: ${wrapperWidth}px;
  height: ${wrapperHeight}px;
  border-radius: 15px;
  background-color: ${(props) => lighten(0.05, hsLFromCssVar(props.theme.colors.background.light))};
  border: 2px solid ${(props) => props.theme.colors.border};

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: calc(100% - ${btnSize + gap}px);
    transform: translateY(-50%);
    border-radius: 50%;
    width: ${btnSize}px;
    height: ${btnSize}px;
    background-color: white;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: right 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
`;

export const CheckBoxLabel = styled(STypography)`
  cursor: pointer;
  user-select: none;
  font-weight: 700;
  padding-left: ${(props) => props.theme.spacing(2)};
`;

export const CheckBox = styled.input`
  &:checked + ${CheckBoxMark} {
    background-color: ${(props) => props.theme.colors.green};

    &::after {
      right: ${gap}px;
    }
  }

  &:disabled {
    ${`& + ${CheckBoxMark}`} {
      cursor: not-allowed;

      &::after {
        background-color: ${(props) => props.theme.colors.text.dark};
      }

      ${`& + ${CheckBoxLabel}`} {
        cursor: not-allowed;
        color: ${(props) => props.theme.colors.text.dark};
      }
    }
  }
`;
