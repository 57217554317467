import { get } from 'lodash-es';
import styled, { css } from 'styled-components';

// @ts-ignore
import { generateTypographyStyles } from 'components/ui/index';

type TypographyProps = {
  variant?:
    | 'h1'
    | 'h2' // unused currently
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption';
  w?: string;
  color?: string;
  bottomSpace?: boolean;
  truncate?: boolean;
  fontSize?: number | string;
  fontWeight?: number | string;
  wrap?: 'pre' | 'nowrap' | 'pre-wrap' | 'pre-line' | 'initial' | 'inherit';
  align?: 'left' | 'center' | 'right' | 'justify' | 'initial' | 'inherit';
  wordBreak?: 'normal' | 'break-all' | 'keep-all' | 'break-word' | 'initial' | 'inherit';
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line' | 'initial' | 'inherit';
  textWrap?: 'wrap' | 'pre' | 'nowrap' | 'pre-wrap' | 'pre-line' | 'initial' | 'inherit';
};

const STypography = styled.span.withConfig({
  shouldForwardProp: (prop) =>
    !['bottomSpace', 'fontSize', 'fontWeight', 'wordBreak', 'whiteSpace', 'truncate'].includes(
      prop,
    ),
})<TypographyProps>`
  color: ${(props) =>
    props.color ? get(props.theme.colors, props.color) : props.theme.colors.text.main};
  font-family: ${(props) => props.theme.fontFamilies.roboto};
  margin: ${({ bottomSpace }) => (bottomSpace ? '0 0 0.35em 0' : '0')};
  white-space: ${(props) => props.wrap && props.wrap};
  text-align: ${(props) => props.align && props.align};
  width: ${(props) => props.w && props.w};
  word-break: ${(props) => props.wordBreak && props.wordBreak};
  white-space: ${(props) => props.whiteSpace && props.whiteSpace};
  text-wrap: ${(props) => props.textWrap ?? 'wrap'};

  ${(props) =>
    props.truncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};

  ${(props) => generateTypographyStyles(props)};

  font-size: ${(props) => props.fontSize && `${props.fontSize}px`};
  font-weight: ${(props) => props.fontWeight && props.fontWeight};
`;

export default STypography;
