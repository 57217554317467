import { FC, ReactNode } from 'react';
import ReactModal from 'react-modal';

import { Icon } from 'components/common/index';
import { STypography } from 'components/ui';
import { theme } from 'components/ui/theme/Theme';

import { SModalBody, SModalCloseButton, SModalHeader } from './Modal.styled';

import ModalFooter from './ModalFooter';

type ModalProps = {
  isOpen: boolean;
  close?: () => void;
  title: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  fullscreen?: boolean;
};

interface ModalComponent extends FC<ModalProps> {
  Footer: typeof ModalFooter;
}

ReactModal.setAppElement('#root');

const Modal: ModalComponent = ({ isOpen, close, title, header, footer, children, fullscreen }) => (
  <ReactModal
    isOpen={isOpen}
    style={{
      overlay: {
        backgroundColor: '#000000b3',
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        inset: '50% auto auto 50%',
        minWidth: '500px',
        maxWidth: '90vw',
        maxHeight: '90vh',
        padding: 0,
        transform: 'translate(-50%, -50%)',
        backgroundColor: theme.colors.background.dark,
        border: 'none',
        boxShadow:
          'rgb(255 255 255 / 7%) 0px 0px 0 1px, 0 0 70px -30px #ffffff24, 0 0 130px #ffffff24',
        ...(fullscreen && {
          width: '100vw',
          height: '100vh',
          maxWidth: '100vw',
          maxHeight: '100vh',
        }),
      },
    }}
  >
    <SModalHeader>
      <STypography variant="h6">{title}</STypography>
      {close && (
        <SModalCloseButton
          type="button"
          aria-label="Close"
          data-bs-dismiss="modal"
          onClick={close}
          round
        >
          <Icon icon="close" />
        </SModalCloseButton>
      )}
      {header && header}
    </SModalHeader>
    <SModalBody fullscreen>{children}</SModalBody>
    {footer && footer}
  </ReactModal>
);

Modal.Footer = ModalFooter;

export default Modal;
