import { ROBOT_MANAGEMENT_ROUTES } from 'pages/routes-names';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

import { DebugOnlyWrapper, Icon } from 'components/common';
import BatteryStatus from 'components/complex/battery-status/BatteryStatus';
import { useRole } from 'components/complex/role';
import TooltipUnauthorizedMessage from 'components/complex/tooltip-unauthorized-message/TooltipUnauthorizedMessage';
import { STypography } from 'components/ui';

import { useDeviceName } from 'store/my-robot';
import { spotFaultsSelector } from 'store/my-robot/manual-control/manualControl.selectors';
import { myRobotBatteryStatusSelector } from 'store/my-robot/myRobot.selectors';
import { useBatteryStatus } from 'store/my-robot/relay-topics/status-battery';

import { useNewNotificationIndicator } from 'hooks/useNewNotificationIndicator';

import { NotificationSeverity } from 'types/models/Notification';

import { navigationRobotList } from '../navigation-list';
import {
  SCurrentRobotName,
  SNavigationItem,
  SNavigationLink,
  SNavigationLinkWarningIcon,
} from '../styled';
import DebugMenu from './DebugMenu';

const SidebarRobot: FC = () => {
  const { checkRole } = useRole();
  const { newNotificationIndicator } = useNewNotificationIndicator();

  const { deviceName } = useDeviceName();
  const {
    status: batteryStatusOld,
    chargePercentage: batteryPercentageOld,
    // remainingRuntimeL: batteryRemainingRuntime
  } = useRecoilValue(myRobotBatteryStatusSelector);
  // todo - after migration to new battery status disable showing anything without a message
  const batteryStatus = useBatteryStatus();
  const { receivedBatteryStatusViaRelayTopic } = batteryStatus;
  const spotFaults = useRecoilValue(spotFaultsSelector);
  const activeFaultsIndicator = spotFaults?.length > 0;

  // the default is negative to show that the battery status is not yet received
  const batteryPercentage = receivedBatteryStatusViaRelayTopic
    ? batteryStatus.percentage
    : batteryPercentageOld;
  const status = receivedBatteryStatusViaRelayTopic
    ? batteryStatus.charging
      ? 'CHARGING'
      : 'DISCHARGING'
    : batteryStatusOld;

  return (
    <>
      <SNavigationItem noPadding>
        <SCurrentRobotName>
          <Icon icon="robot_head" />
          <STypography truncate>{deviceName}</STypography>
          <BatteryStatus status={status} percentage={batteryPercentage} />
        </SCurrentRobotName>
      </SNavigationItem>

      {navigationRobotList.map(({ path, title, icon, role, disabled }) => {
        const hasPermission = !role || (typeof checkRole === 'function' && checkRole(role));
        const isOverview = path === ROBOT_MANAGEMENT_ROUTES.OVERVIEW.path;
        return (
          <TooltipUnauthorizedMessage key={path} hasPermission={hasPermission}>
            <SNavigationItem>
              <SNavigationLink to={path ?? ''} disabled={disabled || !hasPermission}>
                <Icon
                  icon={icon}
                  badge={isOverview && activeFaultsIndicator ? 'warning' : undefined}
                />
                <STypography variant="subtitle2">{title}</STypography>
                {isOverview && newNotificationIndicator && (
                  <SNavigationLinkWarningIcon severity={NotificationSeverity.Error} />
                )}
              </SNavigationLink>
            </SNavigationItem>
          </TooltipUnauthorizedMessage>
        );
      })}

      <DebugOnlyWrapper>
        <DebugMenu />
      </DebugOnlyWrapper>
    </>
  );
};

export default SidebarRobot;
