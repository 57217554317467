/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "bosdyn.api";

/** Represents a rectangle, with integer indices. */
export interface RectangleI {
  /** Distance from the left */
  x: number;
  /** Distance from the top */
  y: number;
  /** Width of the rectangle in pixels */
  cols: number;
  /** Height of the rectangle in pixels */
  rows: number;
}

/** Represents an area in the XY plane, with integer indices */
export interface AreaI {
  rectangle?: RectangleI | undefined;
}

function createBaseRectangleI(): RectangleI {
  return { x: 0, y: 0, cols: 0, rows: 0 };
}

export const RectangleI = {
  encode(message: RectangleI, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.x !== 0) {
      writer.uint32(40).int32(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(48).int32(message.y);
    }
    if (message.cols !== 0) {
      writer.uint32(56).int32(message.cols);
    }
    if (message.rows !== 0) {
      writer.uint32(64).int32(message.rows);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RectangleI {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRectangleI();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          if (tag !== 40) {
            break;
          }

          message.x = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.y = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.cols = reader.int32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.rows = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RectangleI {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
      cols: isSet(object.cols) ? globalThis.Number(object.cols) : 0,
      rows: isSet(object.rows) ? globalThis.Number(object.rows) : 0,
    };
  },

  toJSON(message: RectangleI): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = Math.round(message.x);
    }
    if (message.y !== 0) {
      obj.y = Math.round(message.y);
    }
    if (message.cols !== 0) {
      obj.cols = Math.round(message.cols);
    }
    if (message.rows !== 0) {
      obj.rows = Math.round(message.rows);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RectangleI>, I>>(base?: I): RectangleI {
    return RectangleI.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RectangleI>, I>>(object: I): RectangleI {
    const message = createBaseRectangleI();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    message.cols = object.cols ?? 0;
    message.rows = object.rows ?? 0;
    return message;
  },
};

function createBaseAreaI(): AreaI {
  return { rectangle: undefined };
}

export const AreaI = {
  encode(message: AreaI, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rectangle !== undefined) {
      RectangleI.encode(message.rectangle, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AreaI {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAreaI();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rectangle = RectangleI.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AreaI {
    return { rectangle: isSet(object.rectangle) ? RectangleI.fromJSON(object.rectangle) : undefined };
  },

  toJSON(message: AreaI): unknown {
    const obj: any = {};
    if (message.rectangle !== undefined) {
      obj.rectangle = RectangleI.toJSON(message.rectangle);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AreaI>, I>>(base?: I): AreaI {
    return AreaI.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AreaI>, I>>(object: I): AreaI {
    const message = createBaseAreaI();
    message.rectangle = (object.rectangle !== undefined && object.rectangle !== null)
      ? RectangleI.fromPartial(object.rectangle)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
