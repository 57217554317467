import { FC, SVGProps } from 'react';

import { ReactComponent as Map3DIcon } from 'assets/icons/3d-map.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as Battery0Icon } from 'assets/icons/battery-0.svg';
import { ReactComponent as Battery20Icon } from 'assets/icons/battery-20.svg';
import { ReactComponent as Battery40Icon } from 'assets/icons/battery-40.svg';
import { ReactComponent as Battery60Icon } from 'assets/icons/battery-60.svg';
import { ReactComponent as Battery80Icon } from 'assets/icons/battery-80.svg';
import { ReactComponent as Battery100Icon } from 'assets/icons/battery-100.svg';
import { ReactComponent as BoltIcon } from 'assets/icons/bolt.svg';
import { ReactComponent as BulbIcon } from 'assets/icons/bulb.svg';
import { ReactComponent as CameraFrontIcon } from 'assets/icons/camera-front.svg';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as CarIcon } from 'assets/icons/car.svg';
import { ReactComponent as ChipIcon } from 'assets/icons/chip.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as DatabaseIcon } from 'assets/icons/database.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as DoneIcon } from 'assets/icons/done.svg';
import { ReactComponent as DotsVerticalIcon } from 'assets/icons/dots-vert.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as ExportIcon } from 'assets/icons/export.svg';
import { ReactComponent as FullScreenExitIcon } from 'assets/icons/fullscreen-exit.svg';
import { ReactComponent as FullScreenIcon } from 'assets/icons/fullscreen.svg';
import { ReactComponent as GearIcon } from 'assets/icons/gear-solid.svg';
import { ReactComponent as HistoryMissionIcon } from 'assets/icons/history-mission.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as InfraredIcon } from 'assets/icons/infrared.svg';
import { ReactComponent as LiveIcon } from 'assets/icons/live.svg';
import { ReactComponent as ManualControllerIcon } from 'assets/icons/manual-controller.svg';
import { ReactComponent as MapIcon } from 'assets/icons/map.svg';
import { ReactComponent as MissionTemplateIcon } from 'assets/icons/mission-template.svg';
import { ReactComponent as MissionIcon } from 'assets/icons/mission.svg';
import { ReactComponent as NoMapIcon } from 'assets/icons/no-map.svg';
import { ReactComponent as OverviewIcon } from 'assets/icons/overview.svg';
import { ReactComponent as PayloadIcon } from 'assets/icons/payload.svg';
import { ReactComponent as PendingActionsIcon } from 'assets/icons/pending-actions.svg';
import { ReactComponent as PlayArrowIcon } from 'assets/icons/play-arrow.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as RadiationIcon } from 'assets/icons/radiation.svg';
import { ReactComponent as RecenterIcon } from 'assets/icons/recenter.svg';
import { ReactComponent as RecordingIcon } from 'assets/icons/recording.svg';
import { ReactComponent as RemoveDoneIcon } from 'assets/icons/remove-done.svg';
import { ReactComponent as RobotArmIcon } from 'assets/icons/robot-arm.svg';
import { ReactComponent as RobotHeadIcon } from 'assets/icons/robot-head.svg';
import { ReactComponent as RotateLeftIcon } from 'assets/icons/rotate-left.svg';
import { ReactComponent as RouteIcon } from 'assets/icons/route.svg';
import { ReactComponent as ScanIcon } from 'assets/icons/scan.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { ReactComponent as SignalIcon } from 'assets/icons/signal.svg';
import { ReactComponent as SpeedGaugeIcon } from 'assets/icons/speed-gauge.svg';
import { ReactComponent as SyncIcon } from 'assets/icons/sync.svg';
import { ReactComponent as UnlinkIcon } from 'assets/icons/unlink.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as VolumeHighIcon } from 'assets/icons/volume-high-solid.svg';
import { ReactComponent as WalkIcon } from 'assets/icons/walk.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

const iconsList: Record<string, FC<SVGProps<SVGSVGElement>>> = {
  overview: OverviewIcon,
  map: MapIcon,
  mission: MissionIcon,
  mission_template: MissionTemplateIcon,
  history_mission: HistoryMissionIcon,
  live: LiveIcon,
  manual_controller: ManualControllerIcon,
  payload: PayloadIcon,
  arrow_left: ArrowLeftIcon,
  plus: PlusIcon,
  sync: SyncIcon,
  signal: SignalIcon,
  battery_0: Battery0Icon,
  battery_20: Battery20Icon,
  battery_40: Battery40Icon,
  battery_60: Battery60Icon,
  battery_80: Battery80Icon,
  battery_100: Battery100Icon,
  bolt: BoltIcon,
  unlink: UnlinkIcon,
  robot_arm: RobotArmIcon,
  home: HomeIcon,
  no_map: NoMapIcon,
  rotate_left: RotateLeftIcon,
  camera: CameraIcon,
  camera_front: CameraFrontIcon,
  scan: ScanIcon,
  robot_head: RobotHeadIcon,
  bulb: BulbIcon,
  fullscreen: FullScreenIcon,
  fullscreen_exit: FullScreenExitIcon,
  close: CloseIcon,
  done: DoneIcon,
  remove_done: RemoveDoneIcon,
  share: ShareIcon,
  export: ExportIcon,
  dots_vertical: DotsVerticalIcon,
  walk: WalkIcon,
  map_3d: Map3DIcon,
  recording: RecordingIcon,
  recenter: RecenterIcon,
  speed_gauge: SpeedGaugeIcon,
  route: RouteIcon,
  upload: UploadIcon,
  play_arrow: PlayArrowIcon,
  chip: ChipIcon,
  delete: DeleteIcon,
  radiation: RadiationIcon,
  infrared: InfraredIcon,
  edit: EditIcon,
  car: CarIcon,
  pending_actions: PendingActionsIcon,
  warning: WarningIcon,
  database: DatabaseIcon,
  gear: GearIcon,
  volume_high: VolumeHighIcon,
};

export default iconsList;
