/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Duration } from "../../google/protobuf/duration";
import { Timestamp } from "../../google/protobuf/timestamp";
import { BoolValue, DoubleValue } from "../../google/protobuf/wrappers";
import {
  ArmDragCommand_Feedback,
  ArmDragCommand_Request,
  RobotCommandFeedbackStatus_Status,
  robotCommandFeedbackStatus_StatusFromJSON,
  robotCommandFeedbackStatus_StatusToJSON,
} from "./basic_command";
import { CylindricalCoordinate, FrameTreeSnapshot, SE3Pose, Vec3, Vector, Wrench } from "./geometry";
import { SE3Trajectory, Vec3Trajectory, WrenchTrajectory } from "./trajectory";

export const protobufPackage = "bosdyn.api";

/**
 * The synchronized command message for commanding the arm to move.
 * A synchronized commands is one of the possible robot command messages for controlling the robot.
 */
export interface ArmCommand {
}

/** The arm request must be one of the basic command primitives. */
export interface ArmCommand_Request {
  /** Control the end-effector in Cartesian space. */
  armCartesianCommand?:
    | ArmCartesianCommand_Request
    | undefined;
  /** Control joint angles of the arm. */
  armJointMoveCommand?:
    | ArmJointMoveCommand_Request
    | undefined;
  /** Move the arm to some predefined configurations. */
  namedArmPositionCommand?:
    | NamedArmPositionsCommand_Request
    | undefined;
  /** Velocity control of the end-effector. */
  armVelocityCommand?:
    | ArmVelocityCommand_Request
    | undefined;
  /** Point the gripper at a point in the world. */
  armGazeCommand?:
    | GazeCommand_Request
    | undefined;
  /** Stop the arm in place with minimal motion. */
  armStopCommand?:
    | ArmStopCommand_Request
    | undefined;
  /** Use the arm to drag something held in the gripper. */
  armDragCommand?:
    | ArmDragCommand_Request
    | undefined;
  /** Impedance control of arm (beta) */
  armImpedanceCommand?:
    | ArmImpedanceCommand_Request
    | undefined;
  /** Any arm parameters to send, common across all arm commands */
  params: ArmParams | undefined;
}

/**
 * The feedback for the arm command that will provide information on the progress
 * of the command.
 */
export interface ArmCommand_Feedback {
  /** Feedback for the end-effector Cartesian command. */
  armCartesianFeedback?:
    | ArmCartesianCommand_Feedback
    | undefined;
  /** Feedback for the joint move command. */
  armJointMoveFeedback?:
    | ArmJointMoveCommand_Feedback
    | undefined;
  /** Feedback for the named position move command. */
  namedArmPositionFeedback?: NamedArmPositionsCommand_Feedback | undefined;
  armVelocityFeedback?:
    | ArmVelocityCommand_Feedback
    | undefined;
  /** Feedback for the gaze command. */
  armGazeFeedback?: GazeCommand_Feedback | undefined;
  armStopFeedback?:
    | ArmStopCommand_Feedback
    | undefined;
  /** Feedback for the drag command. */
  armDragFeedback?:
    | ArmDragCommand_Feedback
    | undefined;
  /** Feedback for impedance command. */
  armImpedanceFeedback?: ArmImpedanceCommand_Feedback | undefined;
  status: RobotCommandFeedbackStatus_Status;
}

/** Parameters common across arm commands. */
export interface ArmParams {
  /**
   * / Whether or not to disable the body force limiter running on the robot. By default, this is
   * / on, and the chance that the body falls over because the arm makes contact in the world is
   * / low. If this is purposely disabled (by setting disable_body_force_limiter to True), the arm
   * / may be able to accelerate faster, and apply more force to the world and to objects than
   * / usual, but there is also added risk of the robot falling over.
   */
  disableBodyForceLimiter: boolean | undefined;
}

/**
 * When controlling the arm with a joystick, because of latency it can often be better to send
 * velocity commands rather than position commands.  Both linear and angular velocity can be
 * specified.  The linear velocity can be specified in a cylindrical frame around the shoulder or
 * with a specified frame.
 */
export interface ArmVelocityCommand {
}

export interface ArmVelocityCommand_CylindricalVelocity {
  /**
   * / The linear velocities for the end-effector are specified in unitless cylindrical
   * / coordinates. The origin of the cylindrical coordinate system is the base of the arm
   * / (shoulder).  The Z-axis is aligned with gravity, and the X-axis is the unit vector from
   * / the shoulder to the hand-point. This construction allows for 'Z'-axis velocities to
   * / raise/lower the hand, 'R'-axis velocities will cause the hand to move towards/away from
   * / the shoulder, and 'theta'-axis velocities will cause the hand to travel
   * / clockwise/counter-clockwise around the shoulder. Lastly, the command is unitless, with
   * / values for each axis specified in the range [-1, 1].  A value of 0 denotes no velocity
   * / and values of +/- 1 denote maximum velocity (see max_linear_velocity).
   */
  linearVelocity:
    | CylindricalCoordinate
    | undefined;
  /**
   * / The maximum velocity in meters / second for the hand.
   * / If unset and default value of 0 received, will set max_linear_velocity to 0.5 m/s.
   */
  maxLinearVelocity: number | undefined;
}

export interface ArmVelocityCommand_CartesianVelocity {
  /** The frame to express our velocities in */
  frameName: string;
  /** The x-y-z velocity of the hand (m/s) with respect to the frame */
  velocityInFrameName: Vec3 | undefined;
}

export interface ArmVelocityCommand_Request {
  cylindricalVelocity?: ArmVelocityCommand_CylindricalVelocity | undefined;
  cartesianVelocity?:
    | ArmVelocityCommand_CartesianVelocity
    | undefined;
  /**
   * The angular velocity of the hand frame measured with respect to the odom frame, expressed
   * in the hand frame. A 'X' rate will cause the hand to rotate about its x-axis, e.g. the
   * final wrist twist joint will rotate. And similarly, 'Y' and 'Z' rates will cause the hand
   * to rotate about its y and z axis respectively. \
   * The units should be rad/sec.
   */
  angularVelocityOfHandRtOdomInHand:
    | Vec3
    | undefined;
  /** Optional maximum acceleration magnitude of the end-effector. (m/s^2) */
  maximumAcceleration:
    | number
    | undefined;
  /**
   * The timestamp (in robot time) by which a command must finish executing.
   * This is a required field and used to prevent runaway commands.
   */
  endTime: Date | undefined;
}

/** ArmVelocityCommand provides no feedback */
export interface ArmVelocityCommand_Feedback {
}

/** Command the arm move to a predefined configuration. */
export interface NamedArmPositionsCommand {
}

export enum NamedArmPositionsCommand_Positions {
  /** POSITIONS_UNKNOWN - Invalid request; do not use. */
  POSITIONS_UNKNOWN = 0,
  /**
   * POSITIONS_CARRY - The carry position is a damped, force limited position close to stow, with the hand
   * slightly in front of the robot.
   */
  POSITIONS_CARRY = 1,
  /**
   * POSITIONS_READY - Move arm to ready position. The ready position is defined with the hand directly in
   * front of and slightly above the body, with the hand facing forward in the robot body +X
   * direction.
   */
  POSITIONS_READY = 2,
  /**
   * POSITIONS_STOW - Stow the arm, safely. If the robot is holding something, it will freeze the arm instead
   * of stowing.  Overriding the carry_state to CARRY_STATE_CARRIABLE_AND_STOWABLE, will allow
   * the robot to stow the arm while grasping an item.
   */
  POSITIONS_STOW = 3,
  UNRECOGNIZED = -1,
}

export function namedArmPositionsCommand_PositionsFromJSON(object: any): NamedArmPositionsCommand_Positions {
  switch (object) {
    case 0:
    case "POSITIONS_UNKNOWN":
      return NamedArmPositionsCommand_Positions.POSITIONS_UNKNOWN;
    case 1:
    case "POSITIONS_CARRY":
      return NamedArmPositionsCommand_Positions.POSITIONS_CARRY;
    case 2:
    case "POSITIONS_READY":
      return NamedArmPositionsCommand_Positions.POSITIONS_READY;
    case 3:
    case "POSITIONS_STOW":
      return NamedArmPositionsCommand_Positions.POSITIONS_STOW;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NamedArmPositionsCommand_Positions.UNRECOGNIZED;
  }
}

export function namedArmPositionsCommand_PositionsToJSON(object: NamedArmPositionsCommand_Positions): string {
  switch (object) {
    case NamedArmPositionsCommand_Positions.POSITIONS_UNKNOWN:
      return "POSITIONS_UNKNOWN";
    case NamedArmPositionsCommand_Positions.POSITIONS_CARRY:
      return "POSITIONS_CARRY";
    case NamedArmPositionsCommand_Positions.POSITIONS_READY:
      return "POSITIONS_READY";
    case NamedArmPositionsCommand_Positions.POSITIONS_STOW:
      return "POSITIONS_STOW";
    case NamedArmPositionsCommand_Positions.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface NamedArmPositionsCommand_Request {
  position: NamedArmPositionsCommand_Positions;
}

export interface NamedArmPositionsCommand_Feedback {
  /** Current status of the request. */
  status: NamedArmPositionsCommand_Feedback_Status;
}

export enum NamedArmPositionsCommand_Feedback_Status {
  /** STATUS_UNKNOWN - STATUS_UNKNOWN should never be used. If used, an internal error has happened. */
  STATUS_UNKNOWN = 0,
  /** STATUS_COMPLETE - The arm is at the desired configuration. */
  STATUS_COMPLETE = 1,
  /** STATUS_IN_PROGRESS - Robot is re-configuring arm to get to desired configuration. */
  STATUS_IN_PROGRESS = 2,
  /**
   * STATUS_STALLED_HOLDING_ITEM - Some requests may not execute if the gripper is holding an item declared not
   * stowable, e.g. POSITIONS_STOW with carry_state == CARRY_STATE_CARRIABLE. In these
   * situations, Spot will instead run an ArmStopCommand request while the blocking
   * condition remains true.  Clearing the condition will cause the request to proceed and
   * the arm will start moving.
   */
  STATUS_STALLED_HOLDING_ITEM = 3,
  UNRECOGNIZED = -1,
}

export function namedArmPositionsCommand_Feedback_StatusFromJSON(
  object: any,
): NamedArmPositionsCommand_Feedback_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return NamedArmPositionsCommand_Feedback_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_COMPLETE":
      return NamedArmPositionsCommand_Feedback_Status.STATUS_COMPLETE;
    case 2:
    case "STATUS_IN_PROGRESS":
      return NamedArmPositionsCommand_Feedback_Status.STATUS_IN_PROGRESS;
    case 3:
    case "STATUS_STALLED_HOLDING_ITEM":
      return NamedArmPositionsCommand_Feedback_Status.STATUS_STALLED_HOLDING_ITEM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NamedArmPositionsCommand_Feedback_Status.UNRECOGNIZED;
  }
}

export function namedArmPositionsCommand_Feedback_StatusToJSON(
  object: NamedArmPositionsCommand_Feedback_Status,
): string {
  switch (object) {
    case NamedArmPositionsCommand_Feedback_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case NamedArmPositionsCommand_Feedback_Status.STATUS_COMPLETE:
      return "STATUS_COMPLETE";
    case NamedArmPositionsCommand_Feedback_Status.STATUS_IN_PROGRESS:
      return "STATUS_IN_PROGRESS";
    case NamedArmPositionsCommand_Feedback_Status.STATUS_STALLED_HOLDING_ITEM:
      return "STATUS_STALLED_HOLDING_ITEM";
    case NamedArmPositionsCommand_Feedback_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Command the end effector of the arm.  Each axis in the task frame is allowed to be set to
 * position mode (default) or Force mode.  If the axis is set to position, the desired value is read
 * from the pose_trajectory_in_task. If the axis is set to force, the desired value is read from
 * the wrench_trajectory. This supports hybrid control of the arm where users can specify, for
 * example, Z to be in force control with X and Y in position control.
 */
export interface ArmCartesianCommand {
}

export interface ArmCartesianCommand_Request {
  /**
   * The root frame is used to set the optional task frame that all trajectories are
   * specified with respect to.  If the optional task frame is left un-specified it defaults
   * to the identity transform and the root frame becomes the task frame.
   */
  rootFrameName: string;
  /**
   * The tool pose relative to the parent link (wrist).
   * Defaults to
   *    [0.19557 0 0]
   *    [1 0 0 0]
   * a frame with it's origin slightly in front of the gripper's palm plate aligned with
   * wrist's orientation.
   */
  wristTformTool:
    | SE3Pose
    | undefined;
  /**
   * The fields below are specified in this optional task frame.  If unset it defaults
   * to the identity transform and all quantities are therefore expressed in the
   * root_frame_name.
   */
  rootTformTask:
    | SE3Pose
    | undefined;
  /**
   * A 3D pose trajectory for the tool expressed in the task frame, e.g. task_T_tool.
   * This pose trajectory is optional if requesting a pure wrench at the end-effector,
   * otherwise required for position or mixed force/position end-effector requests.
   */
  poseTrajectoryInTask:
    | SE3Trajectory
    | undefined;
  /**
   * Optional Maximum acceleration magnitude of the end-effector.
   * Valid ranges (0, 20]
   */
  maximumAcceleration:
    | number
    | undefined;
  /** Optional Maximum linear velocity magnitude of the end-effector. (m/s) */
  maxLinearVelocity:
    | number
    | undefined;
  /** Optional Maximum angular velocity magnitude of the end-effector. (rad/s) */
  maxAngularVelocity:
    | number
    | undefined;
  /**
   * Maximum allowable tracking error of the tool frame from the desired trajectory
   * before the arm will stop moving and cancel the rest of the trajectory. When this limit is
   * exceeded, the hand will stay at the pose it was at when it exceeded the tracking error,
   * and any other part of the trajectory specified in the rest of this message will be
   * ignored. max position tracking error in meters
   */
  maxPosTrackingError:
    | number
    | undefined;
  /** max orientation tracking error in radians */
  maxRotTrackingError: number | undefined;
  forceRemainNearCurrentJointConfiguration?: boolean | undefined;
  preferredJointConfiguration?: ArmJointPosition | undefined;
  xAxis: ArmCartesianCommand_Request_AxisMode;
  yAxis: ArmCartesianCommand_Request_AxisMode;
  zAxis: ArmCartesianCommand_Request_AxisMode;
  rxAxis: ArmCartesianCommand_Request_AxisMode;
  ryAxis: ArmCartesianCommand_Request_AxisMode;
  rzAxis: ArmCartesianCommand_Request_AxisMode;
  /**
   * A force/torque trajectory for the tool expressed in the task frame.
   * This trajectory is optional if requesting a pure pose at the end-effector,
   * otherwise required for force or mixed force/position end-effector requests.
   */
  wrenchTrajectoryInTask:
    | WrenchTrajectory
    | undefined;
  /**
   * Disables protection that prevents the arm from moving unexpectedly fast. If you are
   * commanding an especially aggressive arm trajectory, you may need to disable this
   * protection.
   * WARNING: setting `disable_velocity_limiting` to true may result in fast arm motions!
   */
  disableVelocityLimiting: boolean | undefined;
}

/**
 * If an axis is set to position mode (default), read desired from SE3Trajectory trajectory
 * command.  If mode is set to Force, read desired from WrenchTrajectory wrench_trajectory
 * command.  This supports hybrid control of the arm where users can specify, for example, Z
 * to be in force control with X and Y in position control.  The elements are expressed in
 * the same task_frame as the trajectories.
 */
export enum ArmCartesianCommand_Request_AxisMode {
  AXIS_MODE_POSITION = 0,
  AXIS_MODE_FORCE = 1,
  UNRECOGNIZED = -1,
}

export function armCartesianCommand_Request_AxisModeFromJSON(object: any): ArmCartesianCommand_Request_AxisMode {
  switch (object) {
    case 0:
    case "AXIS_MODE_POSITION":
      return ArmCartesianCommand_Request_AxisMode.AXIS_MODE_POSITION;
    case 1:
    case "AXIS_MODE_FORCE":
      return ArmCartesianCommand_Request_AxisMode.AXIS_MODE_FORCE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ArmCartesianCommand_Request_AxisMode.UNRECOGNIZED;
  }
}

export function armCartesianCommand_Request_AxisModeToJSON(object: ArmCartesianCommand_Request_AxisMode): string {
  switch (object) {
    case ArmCartesianCommand_Request_AxisMode.AXIS_MODE_POSITION:
      return "AXIS_MODE_POSITION";
    case ArmCartesianCommand_Request_AxisMode.AXIS_MODE_FORCE:
      return "AXIS_MODE_FORCE";
    case ArmCartesianCommand_Request_AxisMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ArmCartesianCommand_Feedback {
  /** Current status of the pose trajectory. */
  status: ArmCartesianCommand_Feedback_Status;
  /** Current linear tracking error of the tool frame [meters]. */
  measuredPosTrackingError: number;
  /** Current rotational tracking error of the tool frame [radians]. */
  measuredRotTrackingError: number;
  /** Linear distance from the tool to the tool trajectory's end point [meters]. */
  measuredPosDistanceToGoal: number;
  /** Rotational distance from the tool to the trajectory's end point [radians]. */
  measuredRotDistanceToGoal: number;
}

export enum ArmCartesianCommand_Feedback_Status {
  /** STATUS_UNKNOWN - STATUS_UNKNOWN should never be used. If used, an internal error has happened. */
  STATUS_UNKNOWN = 0,
  /** STATUS_TRAJECTORY_COMPLETE - Tool frame has reached the end of the trajectory within tracking error bounds. */
  STATUS_TRAJECTORY_COMPLETE = 1,
  /** STATUS_IN_PROGRESS - Robot is attempting to reach the target. */
  STATUS_IN_PROGRESS = 2,
  /** STATUS_TRAJECTORY_CANCELLED - Tool frame exceeded maximum allowable tracking error from the desired trajectory. */
  STATUS_TRAJECTORY_CANCELLED = 3,
  /**
   * STATUS_TRAJECTORY_STALLED - The arm has stopped making progress to the goal.  Note, this does not cancel the
   * trajectory. For example, if the requested goal is too far away, walking the base
   * robot closer to the goal will cause the arm to continue along the trajectory once the
   * goal point is inside the workspace.
   */
  STATUS_TRAJECTORY_STALLED = 4,
  UNRECOGNIZED = -1,
}

export function armCartesianCommand_Feedback_StatusFromJSON(object: any): ArmCartesianCommand_Feedback_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return ArmCartesianCommand_Feedback_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_TRAJECTORY_COMPLETE":
      return ArmCartesianCommand_Feedback_Status.STATUS_TRAJECTORY_COMPLETE;
    case 2:
    case "STATUS_IN_PROGRESS":
      return ArmCartesianCommand_Feedback_Status.STATUS_IN_PROGRESS;
    case 3:
    case "STATUS_TRAJECTORY_CANCELLED":
      return ArmCartesianCommand_Feedback_Status.STATUS_TRAJECTORY_CANCELLED;
    case 4:
    case "STATUS_TRAJECTORY_STALLED":
      return ArmCartesianCommand_Feedback_Status.STATUS_TRAJECTORY_STALLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ArmCartesianCommand_Feedback_Status.UNRECOGNIZED;
  }
}

export function armCartesianCommand_Feedback_StatusToJSON(object: ArmCartesianCommand_Feedback_Status): string {
  switch (object) {
    case ArmCartesianCommand_Feedback_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case ArmCartesianCommand_Feedback_Status.STATUS_TRAJECTORY_COMPLETE:
      return "STATUS_TRAJECTORY_COMPLETE";
    case ArmCartesianCommand_Feedback_Status.STATUS_IN_PROGRESS:
      return "STATUS_IN_PROGRESS";
    case ArmCartesianCommand_Feedback_Status.STATUS_TRAJECTORY_CANCELLED:
      return "STATUS_TRAJECTORY_CANCELLED";
    case ArmCartesianCommand_Feedback_Status.STATUS_TRAJECTORY_STALLED:
      return "STATUS_TRAJECTORY_STALLED";
    case ArmCartesianCommand_Feedback_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Specify a set of joint angles to move the arm. */
export interface ArmJointMoveCommand {
}

export interface ArmJointMoveCommand_Request {
  /**
   * Note: Sending a single point empty trajectory will cause the arm to freeze in place. This
   * is an easy way to lock the arm in its current configuration.
   */
  trajectory: ArmJointTrajectory | undefined;
}

export interface ArmJointMoveCommand_Feedback {
  /** Current status of the request. */
  status: ArmJointMoveCommand_Feedback_Status;
  /** Current status of the trajectory planner. */
  plannerStatus: ArmJointMoveCommand_Feedback_PlannerStatus;
  /**
   * Based on the user trajectory, the planned knot points that obey acceleration and
   * velocity constraints. If these knot points don't match the requested knot points,
   * consider increasing velocity/acceleration limits, and/or staying further away from
   * joint position limits. In situations where we've modified you last point, we append
   * a minimum time trajectory (that obeys the velocity and acceleration limits) from the
   * planner's final point to the requested final point. This means that the length of
   * planned_points may be one point larger than the requested. The planner works on a
   * moving window of up to 10 points from the input trajectory, so the length of planned
   * points will be at most 10, and its contents will change over time for long trajectories.
   */
  plannedPoints: ArmJointTrajectoryPoint[];
  /**
   * Returns amount of time remaining until the joints are at the goal position.  For
   * multiple point trajectories, this is the time remaining to the final point.
   */
  timeToGoal: Duration | undefined;
}

export enum ArmJointMoveCommand_Feedback_Status {
  /** STATUS_UNKNOWN - STATUS_UNKNOWN should never be used. If used, an internal error has happened */
  STATUS_UNKNOWN = 0,
  /** STATUS_COMPLETE - The arm is at the desired configuration. */
  STATUS_COMPLETE = 1,
  /** STATUS_IN_PROGRESS - Robot is re-configuring arm to get to desired configuration. */
  STATUS_IN_PROGRESS = 2,
  /**
   * STATUS_STALLED - The arm has stopped making progress towards the goal. This could be because it is
   * avoiding a collision or joint limit.
   */
  STATUS_STALLED = 3,
  UNRECOGNIZED = -1,
}

export function armJointMoveCommand_Feedback_StatusFromJSON(object: any): ArmJointMoveCommand_Feedback_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return ArmJointMoveCommand_Feedback_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_COMPLETE":
      return ArmJointMoveCommand_Feedback_Status.STATUS_COMPLETE;
    case 2:
    case "STATUS_IN_PROGRESS":
      return ArmJointMoveCommand_Feedback_Status.STATUS_IN_PROGRESS;
    case 3:
    case "STATUS_STALLED":
      return ArmJointMoveCommand_Feedback_Status.STATUS_STALLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ArmJointMoveCommand_Feedback_Status.UNRECOGNIZED;
  }
}

export function armJointMoveCommand_Feedback_StatusToJSON(object: ArmJointMoveCommand_Feedback_Status): string {
  switch (object) {
    case ArmJointMoveCommand_Feedback_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case ArmJointMoveCommand_Feedback_Status.STATUS_COMPLETE:
      return "STATUS_COMPLETE";
    case ArmJointMoveCommand_Feedback_Status.STATUS_IN_PROGRESS:
      return "STATUS_IN_PROGRESS";
    case ArmJointMoveCommand_Feedback_Status.STATUS_STALLED:
      return "STATUS_STALLED";
    case ArmJointMoveCommand_Feedback_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ArmJointMoveCommand_Feedback_PlannerStatus {
  /** PLANNER_STATUS_UNKNOWN - PLANNER_STATUS_UNKNOWN should never be used. If used, an internal error has happened. */
  PLANNER_STATUS_UNKNOWN = 0,
  /** PLANNER_STATUS_SUCCESS - A solution passing through the desired points and obeying the constraints was found. */
  PLANNER_STATUS_SUCCESS = 1,
  /**
   * PLANNER_STATUS_MODIFIED - The planner had to modify the desired points in order to obey the constraints.  For
   * example, if you specify a 1 point trajectory, and tell it to get there in a really
   * short amount of time, but haven't set a high allowable max velocity / acceleration,
   * the planner will do its best to get as close as possible to the final point, but
   * won't reach it. In situations where we've modified you last point, we append a
   * minimum time trajectory (that obeys the velocity and acceleration limits) from the
   * planner's final point to the requested final point.
   */
  PLANNER_STATUS_MODIFIED = 2,
  /**
   * PLANNER_STATUS_FAILED - Failed to compute a valid trajectory, will go to first point instead. It is possible
   * that our optimizer till fail to solve the problem instead of returning a sub-optimal
   * solution.  This is un-likely to occur.
   */
  PLANNER_STATUS_FAILED = 3,
  UNRECOGNIZED = -1,
}

export function armJointMoveCommand_Feedback_PlannerStatusFromJSON(
  object: any,
): ArmJointMoveCommand_Feedback_PlannerStatus {
  switch (object) {
    case 0:
    case "PLANNER_STATUS_UNKNOWN":
      return ArmJointMoveCommand_Feedback_PlannerStatus.PLANNER_STATUS_UNKNOWN;
    case 1:
    case "PLANNER_STATUS_SUCCESS":
      return ArmJointMoveCommand_Feedback_PlannerStatus.PLANNER_STATUS_SUCCESS;
    case 2:
    case "PLANNER_STATUS_MODIFIED":
      return ArmJointMoveCommand_Feedback_PlannerStatus.PLANNER_STATUS_MODIFIED;
    case 3:
    case "PLANNER_STATUS_FAILED":
      return ArmJointMoveCommand_Feedback_PlannerStatus.PLANNER_STATUS_FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ArmJointMoveCommand_Feedback_PlannerStatus.UNRECOGNIZED;
  }
}

export function armJointMoveCommand_Feedback_PlannerStatusToJSON(
  object: ArmJointMoveCommand_Feedback_PlannerStatus,
): string {
  switch (object) {
    case ArmJointMoveCommand_Feedback_PlannerStatus.PLANNER_STATUS_UNKNOWN:
      return "PLANNER_STATUS_UNKNOWN";
    case ArmJointMoveCommand_Feedback_PlannerStatus.PLANNER_STATUS_SUCCESS:
      return "PLANNER_STATUS_SUCCESS";
    case ArmJointMoveCommand_Feedback_PlannerStatus.PLANNER_STATUS_MODIFIED:
      return "PLANNER_STATUS_MODIFIED";
    case ArmJointMoveCommand_Feedback_PlannerStatus.PLANNER_STATUS_FAILED:
      return "PLANNER_STATUS_FAILED";
    case ArmJointMoveCommand_Feedback_PlannerStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Position of our 6 arm joints in radians. If a joint angle is not specified,
 * we will use the joint position at time the message is received on robot.
 */
export interface ArmJointPosition {
  sh0: number | undefined;
  sh1: number | undefined;
  el0: number | undefined;
  el1: number | undefined;
  wr0: number | undefined;
  wr1: number | undefined;
}

/**
 * Velocity of our 6 arm joints in radians / second. If a velocity
 * for a joint is specified, velocities for all joints we are
 * trying to move must be specified.
 */
export interface ArmJointVelocity {
  sh0: number | undefined;
  sh1: number | undefined;
  el0: number | undefined;
  el1: number | undefined;
  wr0: number | undefined;
  wr1: number | undefined;
}

/** A set of joint angles and velocities that can be used as a point within a joint trajectory. */
export interface ArmJointTrajectoryPoint {
  /** Desired joint angles in radians */
  position:
    | ArmJointPosition
    | undefined;
  /** Optional desired joint velocities in radians / sec */
  velocity:
    | ArmJointVelocity
    | undefined;
  /** The time since reference at which we wish to achieve this position / velocity */
  timeSinceReference: Duration | undefined;
}

/**
 * This allows a user to move the arm's joints directly. Each of the arm's joints will never move
 * faster than maximum_velocity and never accelerate faster than maximum_acceleration. The user can
 * specify a trajectory of joint positions and optional velocities for the arm to follow. The
 * trajectory will be acted upon as follows. If a single trajectory point with no time is provided,
 * the arm will take the joint currently furthest away from the goal pose and plan a minimum time
 * trajectory such that the joint accelerates at maximum_acceleration, coasts at maximum_velocity,
 * and decelerates at maximum_acceleration. The other joints will accelerate at
 * maximum_acceleration, but then coast at a slower speed such that all joints arrive at the goal
 * pose simultaneously with zero velocity. If the user provides trajectory times, the robot will fit
 * a piece-wise cubic trajectory (continuous position and velocity) to the user's requested
 * positions and (optional) velocities. If the requested trajectory is not achievable because it
 * will violate position limits or the maximum_velocity or maximum_acceleration, the robot will pick
 * a trajectory that is as close as possible to the user requested without violating velocity or
 * acceleration limits.
 *
 * If the robot is not hitting the desired trajectory, try increasing the time between knot points,
 * increasing the max velocity and acceleration, or only specifying joint position goals without a
 * velocity
 */
export interface ArmJointTrajectory {
  /** The points in our trajectory. (positions, (optional) velocity, (optional) time) */
  points: ArmJointTrajectoryPoint[];
  /**
   * All trajectory points specify times relative to this reference time. The reference
   * time should be in robot clock. If this field is not included, this time will be
   * the receive time of the command.
   */
  referenceTime:
    | Date
    | undefined;
  /**
   * The maximum velocity in rad/s that any joint is allowed to achieve.
   * If this field is not set, a default value will be used.
   */
  maximumVelocity:
    | number
    | undefined;
  /**
   * The maximum acceleration in rad/s^2 that any joint is allowed to
   * achieve. If this field is not set, a default value will be used.
   */
  maximumAcceleration: number | undefined;
}

/** / Move the hand in such a way to point it at a position in the world. */
export interface GazeCommand {
}

export interface GazeCommand_Request {
  /** Point(s) to look at expressed in frame1. */
  targetTrajectoryInFrame1: Vec3Trajectory | undefined;
  frame1Name: string;
  /**
   * Optional, desired pose of the tool expressed in frame2.  Will be constrained to 'look at'
   * the target regardless of the requested orientation.
   */
  toolTrajectoryInFrame2: SE3Trajectory | undefined;
  frame2Name: string;
  /**
   * The transformation of the tool pose relative to the parent link (wrist).
   * If the field is left unset, the transform will default to:
   *      The position is wrist_tform_hand.position() [20 cm translation in wrist x].
   *      The rotation is wrist_tform_hand_camera.rotation() [-9 degree pitch about wrist y].
   */
  wristTformTool:
    | SE3Pose
    | undefined;
  /**
   * Optional velocity to move the target along the shortest path from the gaze's starting
   * position to the first point in the target trajectory.
   */
  targetTrajectoryInitialVelocity:
    | number
    | undefined;
  /**
   * Optional Maximum acceleration magnitude of the end-effector.
   * Valid ranges (0, 20]
   */
  maximumAcceleration:
    | number
    | undefined;
  /** Optional Maximum linear velocity magnitude of the end-effector. (m/s) */
  maxLinearVelocity:
    | number
    | undefined;
  /** Optional Maximum angular velocity magnitude of the end-effector. (rad/s) */
  maxAngularVelocity: number | undefined;
}

export interface GazeCommand_Feedback {
  /** Current status of the command. */
  status: GazeCommand_Feedback_Status;
  /**
   * If we are gazing at the target
   * Rotation from the current gaze point to the trajectory's end [radians]
   */
  gazingAtTarget: boolean;
  gazeToTargetRotationMeasured: number;
  /**
   * If the hand's position is at the goal.
   * Distance from the hand's current position to the trajectory's end [meters]
   */
  handPositionAtGoal: boolean;
  handDistanceToGoalMeasured: number;
  /**
   * If the hand's roll is at the goal.
   * Rotation from the current hand position to the desired roll at the trajectory's end
   * [radians]
   */
  handRollAtGoal: boolean;
  handRollToTargetRollMeasured: number;
}

export enum GazeCommand_Feedback_Status {
  /** STATUS_UNKNOWN - STATUS_UNKNOWN should never be used. If used, an internal error has happened. */
  STATUS_UNKNOWN = 0,
  /** STATUS_TRAJECTORY_COMPLETE - Robot is gazing at the target at the end of the trajectory. */
  STATUS_TRAJECTORY_COMPLETE = 1,
  /** STATUS_IN_PROGRESS - Robot is re-configuring arm to gaze at the target. */
  STATUS_IN_PROGRESS = 2,
  /**
   * STATUS_TOOL_TRAJECTORY_STALLED - The arm has stopped making progress to the goal pose for the tool.
   * Note, this does not cancel the trajectory. For example, if the requested goal is too
   * far away, walking the base robot closer to the goal will cause the arm to continue
   * along the trajectory once it can continue.
   */
  STATUS_TOOL_TRAJECTORY_STALLED = 3,
  UNRECOGNIZED = -1,
}

export function gazeCommand_Feedback_StatusFromJSON(object: any): GazeCommand_Feedback_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return GazeCommand_Feedback_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_TRAJECTORY_COMPLETE":
      return GazeCommand_Feedback_Status.STATUS_TRAJECTORY_COMPLETE;
    case 2:
    case "STATUS_IN_PROGRESS":
      return GazeCommand_Feedback_Status.STATUS_IN_PROGRESS;
    case 3:
    case "STATUS_TOOL_TRAJECTORY_STALLED":
      return GazeCommand_Feedback_Status.STATUS_TOOL_TRAJECTORY_STALLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return GazeCommand_Feedback_Status.UNRECOGNIZED;
  }
}

export function gazeCommand_Feedback_StatusToJSON(object: GazeCommand_Feedback_Status): string {
  switch (object) {
    case GazeCommand_Feedback_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case GazeCommand_Feedback_Status.STATUS_TRAJECTORY_COMPLETE:
      return "STATUS_TRAJECTORY_COMPLETE";
    case GazeCommand_Feedback_Status.STATUS_IN_PROGRESS:
      return "STATUS_IN_PROGRESS";
    case GazeCommand_Feedback_Status.STATUS_TOOL_TRAJECTORY_STALLED:
      return "STATUS_TOOL_TRAJECTORY_STALLED";
    case GazeCommand_Feedback_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Stop the arm applying minimal forces to the world. For example, if the arm is in the  middle of
 * opening a heavy door and a stop command is sent, the arm will comply and let the door close.
 */
export interface ArmStopCommand {
}

/** Stop command takes no arguments. */
export interface ArmStopCommand_Request {
}

/** Stop command provides no feedback */
export interface ArmStopCommand_Feedback {
}

/**
 * Specify impedance about the end-effector. Users can set up frames along with stiffness and
 * damping parameters to control how the end-effector will respond to external contact as it moves
 * along a specified trajectory
 */
export interface ArmImpedanceCommand {
}

export interface ArmImpedanceCommand_Request {
  /**
   * Name of the frame relative to which the task frame is defined for this command.
   * Common frames for this include "odom", "vision", "body", and "flat_body".
   */
  rootFrameName: string;
  /**
   * This transform specifies the pose of the task frame relative to the root frame.
   * If unset, it defaults to identity, and the task frame coincides with the root frame.
   * The `desired_tool` frame will be specified relative to the task frame. For peg in
   * hole tasks for example, the task frame could be a frame attached to the top of the
   * hole with z-axis aligned with the hole axis, and the `desired_tool` frame could
   * move in z to direct the peg deeper into the hole.
   */
  rootTformTask:
    | SE3Pose
    | undefined;
  /**
   * The tool pose relative to the parent link (link_wr1). This can also be thought of as the
   * "remote center" frame. For peg in hole tasks for example, one might put the tool frame
   * at the tip of the peg, or slightly below the tip floating in space, at the point on which
   * we want our virtual springs to pull.
   * Defaults to
   *    [0.19557 0 0]
   *    [1 0 0 0]
   * which is a frame aligned with the wrist frame, with its origin slightly in front of
   * the gripper's palm plate.
   */
  wristTformTool:
    | SE3Pose
    | undefined;
  /**
   * Trajectory of where we want the tool to be relative to the task frame. Note that this
   * `desired_tool` frame is not the same as the tool frame attached to the wrist link. If our
   * tool deviates from this `desired_tool` pose, it will be subject to a wrench determined by
   * our stiffness and damping matrices.
   */
  taskTformDesiredTool:
    | SE3Trajectory
    | undefined;
  /**
   * Feed forward wrench to apply at the tool, expressed with respect to the `desired_tool`
   * frame
   */
  feedForwardWrenchAtToolInDesiredTool:
    | Wrench
    | undefined;
  /**
   * Stiffness matrix in the `desired_tool` frame. The matrix is parameterized by a vector of
   * 6 doubles, representing the diagonal of this 6x6 matrix: [x,y,z,tx,ty,tz] (N/m, N/m, N/m,
   * Nm/rad, Nm/rad, Nm/rad). All other entries will be set to 0. All stiffness values along
   * the diagonal should be non-negative.
   */
  diagonalStiffnessMatrix:
    | Vector
    | undefined;
  /**
   * Damping matrix in the `desired_tool` frame. The matrix is parameterized by a vector of 6
   * doubles, representing the diagonal of this 6x6 matrix: [x,y,z,tx,ty,tz] (Ns/m, Ns/m,
   * Ns/m, Nms/rad, Nms/rad, Nms/rad) All other entries will be set to 0. All damping values
   * along the diagonal should be non-negative.
   */
  diagonalDampingMatrix:
    | Vector
    | undefined;
  /**
   * Maximum force magnitude in Newtons we're allowed to exert. If the tool deviates such that
   * the magnitude of the requested force would exceed this threshold, saturate the requested
   * force. If this value is not set, a default of 60N will be used.
   */
  maxForceMag:
    | number
    | undefined;
  /**
   * Maximum torque magnitude in Newton meters we're allowed to exert. If the tool deviates
   * such that the magnitude of the requested torque would exceed this threshold, saturate the
   * requested torque. If this value is not set, a default of 15Nm will be used.
   */
  maxTorqueMag:
    | number
    | undefined;
  /**
   * Set to True to disable cancelling the trajectory for unsafe behaviors. NOTE: If
   * disable_safety_check is set to True, the robot may damage itself or the environment.
   * Extreme caution should be used when setting disable_safety_check to True.
   */
  disableSafetyCheck: boolean | undefined;
}

export interface ArmImpedanceCommand_Feedback {
  /** Current status of the pose trajectory. */
  status: ArmImpedanceCommand_Feedback_Status;
  /**
   * A tree-based collection of transformations relevant to the current impedance operation.
   * In addition to the common frames ("vision", "body", "odom"), this snapshot contains the
   * following:
   * "task": The task frame that the impedance action is specified in.
   * "desired_tool": The pose of the desired_tool frame at the current time.
   * "tool": The current measured pose of the tool.
   * "desired_tool_at_end": The desired tool pose at the end of the requested trajectory.
   * "measured_tool_at_start": The measured pose of the tool when this command was first sent.
   *
   * While these poses can be used in any way the user sees fit, here are some useful ideas:
   * desired_tool_tform_tool: The current measured tool pose relative to the `desired_tool`
   *                          frame [meters, quaternion]. This is our "tracking error".
   *                          Multiplying this error by `diagonal_stiffness_matrix` should
   *                          yield `commanded_wrench_from_stiffness_at_tool_in_desired_tool`.
   * desired_tool_at_end_tform_tool: The current measured tool pose relative to the
   *                                 `desired_tool` frame at the end of the user trajectory
   *                                 [meters, quaternion]. This is our "distance to goal",
   *                                 and can be used for checking when an impedance move is
   *                                 "complete".
   * measured_tool_at_start_tform_tool_in_task: The current measured tool pose relative to
   *                                            the measured tool frame at the start,
   *                                            expressed in the task frame
   *                                            [meters, quaternion]. This can be used to
   *                                            see how far the tool has moved since the
   *                                            beginning of the command.
   */
  transformsSnapshot:
    | FrameTreeSnapshot
    | undefined;
  /**
   * The component of our commanded wrench at the tool expressed with respect to the
   * `desired_tool` frame from our stiffness matrix [Newtons / Nm]
   */
  commandedWrenchFromStiffnessAtToolInDesiredTool:
    | Wrench
    | undefined;
  /**
   * The component of our commanded wrench at the tool expressed with respect to the
   * `desired_tool` frame from our damping matrix [Newtons / Nm]
   */
  commandedWrenchFromDampingAtToolInDesiredTool:
    | Wrench
    | undefined;
  /**
   * The component of our commanded wrench at the tool expressed with respect to the
   * `desired_tool` frame from our feed forward wrench [Newtons / Nm]
   */
  commandedWrenchFromFeedForwardAtToolInDesiredTool:
    | Wrench
    | undefined;
  /**
   * The commanded total wrench at the tool expressed with respect to the `desired_tool`
   * frame. This wrench has been saturated to obey `max_force_mag` and `max_torque_mag`
   * [Newtons / Nm]
   */
  totalCommandedWrenchAtToolInDesiredTool:
    | Wrench
    | undefined;
  /**
   * Sometimes the arm cannot achieve the commanded wrench exactly because of the
   * underlying controller or arm pose. This looks at the joint torque sensors to
   * determine the actual force and torque being applied at the tool. [Newtons / Nm]
   */
  totalMeasuredWrenchAtToolInDesiredTool: Wrench | undefined;
}

export enum ArmImpedanceCommand_Feedback_Status {
  /** STATUS_UNKNOWN - STATUS_UNKNOWN should never be used. If used, an internal error has happened. */
  STATUS_UNKNOWN = 0,
  /**
   * STATUS_TRAJECTORY_COMPLETE - Tool frame has reached the end of the trajectory, and is close to the `desired_tool`
   * in directions with high stiffness and no feed forwards
   */
  STATUS_TRAJECTORY_COMPLETE = 1,
  /** STATUS_IN_PROGRESS - Robot is moving along the `desired_tool` trajectory */
  STATUS_IN_PROGRESS = 2,
  /**
   * STATUS_TRAJECTORY_STALLED - The arm has stopped making progress to the goal and the measured tool frame is far
   * from the `desired_tool` along directions where we expect good tracking
   */
  STATUS_TRAJECTORY_STALLED = 3,
  /**
   * STATUS_TRAJECTORY_CANCELLED - Detected an arm instability, so the commanded motion was cancelled. Consider lowering
   * stiffness or lowering both stiffness and damping to improve stability.
   */
  STATUS_TRAJECTORY_CANCELLED = 4,
  UNRECOGNIZED = -1,
}

export function armImpedanceCommand_Feedback_StatusFromJSON(object: any): ArmImpedanceCommand_Feedback_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return ArmImpedanceCommand_Feedback_Status.STATUS_UNKNOWN;
    case 1:
    case "STATUS_TRAJECTORY_COMPLETE":
      return ArmImpedanceCommand_Feedback_Status.STATUS_TRAJECTORY_COMPLETE;
    case 2:
    case "STATUS_IN_PROGRESS":
      return ArmImpedanceCommand_Feedback_Status.STATUS_IN_PROGRESS;
    case 3:
    case "STATUS_TRAJECTORY_STALLED":
      return ArmImpedanceCommand_Feedback_Status.STATUS_TRAJECTORY_STALLED;
    case 4:
    case "STATUS_TRAJECTORY_CANCELLED":
      return ArmImpedanceCommand_Feedback_Status.STATUS_TRAJECTORY_CANCELLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ArmImpedanceCommand_Feedback_Status.UNRECOGNIZED;
  }
}

export function armImpedanceCommand_Feedback_StatusToJSON(object: ArmImpedanceCommand_Feedback_Status): string {
  switch (object) {
    case ArmImpedanceCommand_Feedback_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case ArmImpedanceCommand_Feedback_Status.STATUS_TRAJECTORY_COMPLETE:
      return "STATUS_TRAJECTORY_COMPLETE";
    case ArmImpedanceCommand_Feedback_Status.STATUS_IN_PROGRESS:
      return "STATUS_IN_PROGRESS";
    case ArmImpedanceCommand_Feedback_Status.STATUS_TRAJECTORY_STALLED:
      return "STATUS_TRAJECTORY_STALLED";
    case ArmImpedanceCommand_Feedback_Status.STATUS_TRAJECTORY_CANCELLED:
      return "STATUS_TRAJECTORY_CANCELLED";
    case ArmImpedanceCommand_Feedback_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseArmCommand(): ArmCommand {
  return {};
}

export const ArmCommand = {
  encode(_: ArmCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ArmCommand {
    return {};
  },

  toJSON(_: ArmCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmCommand>, I>>(base?: I): ArmCommand {
    return ArmCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmCommand>, I>>(_: I): ArmCommand {
    const message = createBaseArmCommand();
    return message;
  },
};

function createBaseArmCommand_Request(): ArmCommand_Request {
  return {
    armCartesianCommand: undefined,
    armJointMoveCommand: undefined,
    namedArmPositionCommand: undefined,
    armVelocityCommand: undefined,
    armGazeCommand: undefined,
    armStopCommand: undefined,
    armDragCommand: undefined,
    armImpedanceCommand: undefined,
    params: undefined,
  };
}

export const ArmCommand_Request = {
  encode(message: ArmCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.armCartesianCommand !== undefined) {
      ArmCartesianCommand_Request.encode(message.armCartesianCommand, writer.uint32(26).fork()).ldelim();
    }
    if (message.armJointMoveCommand !== undefined) {
      ArmJointMoveCommand_Request.encode(message.armJointMoveCommand, writer.uint32(34).fork()).ldelim();
    }
    if (message.namedArmPositionCommand !== undefined) {
      NamedArmPositionsCommand_Request.encode(message.namedArmPositionCommand, writer.uint32(42).fork()).ldelim();
    }
    if (message.armVelocityCommand !== undefined) {
      ArmVelocityCommand_Request.encode(message.armVelocityCommand, writer.uint32(50).fork()).ldelim();
    }
    if (message.armGazeCommand !== undefined) {
      GazeCommand_Request.encode(message.armGazeCommand, writer.uint32(66).fork()).ldelim();
    }
    if (message.armStopCommand !== undefined) {
      ArmStopCommand_Request.encode(message.armStopCommand, writer.uint32(74).fork()).ldelim();
    }
    if (message.armDragCommand !== undefined) {
      ArmDragCommand_Request.encode(message.armDragCommand, writer.uint32(82).fork()).ldelim();
    }
    if (message.armImpedanceCommand !== undefined) {
      ArmImpedanceCommand_Request.encode(message.armImpedanceCommand, writer.uint32(98).fork()).ldelim();
    }
    if (message.params !== undefined) {
      ArmParams.encode(message.params, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.armCartesianCommand = ArmCartesianCommand_Request.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.armJointMoveCommand = ArmJointMoveCommand_Request.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.namedArmPositionCommand = NamedArmPositionsCommand_Request.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.armVelocityCommand = ArmVelocityCommand_Request.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.armGazeCommand = GazeCommand_Request.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.armStopCommand = ArmStopCommand_Request.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.armDragCommand = ArmDragCommand_Request.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.armImpedanceCommand = ArmImpedanceCommand_Request.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.params = ArmParams.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmCommand_Request {
    return {
      armCartesianCommand: isSet(object.armCartesianCommand)
        ? ArmCartesianCommand_Request.fromJSON(object.armCartesianCommand)
        : undefined,
      armJointMoveCommand: isSet(object.armJointMoveCommand)
        ? ArmJointMoveCommand_Request.fromJSON(object.armJointMoveCommand)
        : undefined,
      namedArmPositionCommand: isSet(object.namedArmPositionCommand)
        ? NamedArmPositionsCommand_Request.fromJSON(object.namedArmPositionCommand)
        : undefined,
      armVelocityCommand: isSet(object.armVelocityCommand)
        ? ArmVelocityCommand_Request.fromJSON(object.armVelocityCommand)
        : undefined,
      armGazeCommand: isSet(object.armGazeCommand) ? GazeCommand_Request.fromJSON(object.armGazeCommand) : undefined,
      armStopCommand: isSet(object.armStopCommand) ? ArmStopCommand_Request.fromJSON(object.armStopCommand) : undefined,
      armDragCommand: isSet(object.armDragCommand) ? ArmDragCommand_Request.fromJSON(object.armDragCommand) : undefined,
      armImpedanceCommand: isSet(object.armImpedanceCommand)
        ? ArmImpedanceCommand_Request.fromJSON(object.armImpedanceCommand)
        : undefined,
      params: isSet(object.params) ? ArmParams.fromJSON(object.params) : undefined,
    };
  },

  toJSON(message: ArmCommand_Request): unknown {
    const obj: any = {};
    if (message.armCartesianCommand !== undefined) {
      obj.armCartesianCommand = ArmCartesianCommand_Request.toJSON(message.armCartesianCommand);
    }
    if (message.armJointMoveCommand !== undefined) {
      obj.armJointMoveCommand = ArmJointMoveCommand_Request.toJSON(message.armJointMoveCommand);
    }
    if (message.namedArmPositionCommand !== undefined) {
      obj.namedArmPositionCommand = NamedArmPositionsCommand_Request.toJSON(message.namedArmPositionCommand);
    }
    if (message.armVelocityCommand !== undefined) {
      obj.armVelocityCommand = ArmVelocityCommand_Request.toJSON(message.armVelocityCommand);
    }
    if (message.armGazeCommand !== undefined) {
      obj.armGazeCommand = GazeCommand_Request.toJSON(message.armGazeCommand);
    }
    if (message.armStopCommand !== undefined) {
      obj.armStopCommand = ArmStopCommand_Request.toJSON(message.armStopCommand);
    }
    if (message.armDragCommand !== undefined) {
      obj.armDragCommand = ArmDragCommand_Request.toJSON(message.armDragCommand);
    }
    if (message.armImpedanceCommand !== undefined) {
      obj.armImpedanceCommand = ArmImpedanceCommand_Request.toJSON(message.armImpedanceCommand);
    }
    if (message.params !== undefined) {
      obj.params = ArmParams.toJSON(message.params);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmCommand_Request>, I>>(base?: I): ArmCommand_Request {
    return ArmCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmCommand_Request>, I>>(object: I): ArmCommand_Request {
    const message = createBaseArmCommand_Request();
    message.armCartesianCommand = (object.armCartesianCommand !== undefined && object.armCartesianCommand !== null)
      ? ArmCartesianCommand_Request.fromPartial(object.armCartesianCommand)
      : undefined;
    message.armJointMoveCommand = (object.armJointMoveCommand !== undefined && object.armJointMoveCommand !== null)
      ? ArmJointMoveCommand_Request.fromPartial(object.armJointMoveCommand)
      : undefined;
    message.namedArmPositionCommand =
      (object.namedArmPositionCommand !== undefined && object.namedArmPositionCommand !== null)
        ? NamedArmPositionsCommand_Request.fromPartial(object.namedArmPositionCommand)
        : undefined;
    message.armVelocityCommand = (object.armVelocityCommand !== undefined && object.armVelocityCommand !== null)
      ? ArmVelocityCommand_Request.fromPartial(object.armVelocityCommand)
      : undefined;
    message.armGazeCommand = (object.armGazeCommand !== undefined && object.armGazeCommand !== null)
      ? GazeCommand_Request.fromPartial(object.armGazeCommand)
      : undefined;
    message.armStopCommand = (object.armStopCommand !== undefined && object.armStopCommand !== null)
      ? ArmStopCommand_Request.fromPartial(object.armStopCommand)
      : undefined;
    message.armDragCommand = (object.armDragCommand !== undefined && object.armDragCommand !== null)
      ? ArmDragCommand_Request.fromPartial(object.armDragCommand)
      : undefined;
    message.armImpedanceCommand = (object.armImpedanceCommand !== undefined && object.armImpedanceCommand !== null)
      ? ArmImpedanceCommand_Request.fromPartial(object.armImpedanceCommand)
      : undefined;
    message.params = (object.params !== undefined && object.params !== null)
      ? ArmParams.fromPartial(object.params)
      : undefined;
    return message;
  },
};

function createBaseArmCommand_Feedback(): ArmCommand_Feedback {
  return {
    armCartesianFeedback: undefined,
    armJointMoveFeedback: undefined,
    namedArmPositionFeedback: undefined,
    armVelocityFeedback: undefined,
    armGazeFeedback: undefined,
    armStopFeedback: undefined,
    armDragFeedback: undefined,
    armImpedanceFeedback: undefined,
    status: 0,
  };
}

export const ArmCommand_Feedback = {
  encode(message: ArmCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.armCartesianFeedback !== undefined) {
      ArmCartesianCommand_Feedback.encode(message.armCartesianFeedback, writer.uint32(26).fork()).ldelim();
    }
    if (message.armJointMoveFeedback !== undefined) {
      ArmJointMoveCommand_Feedback.encode(message.armJointMoveFeedback, writer.uint32(34).fork()).ldelim();
    }
    if (message.namedArmPositionFeedback !== undefined) {
      NamedArmPositionsCommand_Feedback.encode(message.namedArmPositionFeedback, writer.uint32(42).fork()).ldelim();
    }
    if (message.armVelocityFeedback !== undefined) {
      ArmVelocityCommand_Feedback.encode(message.armVelocityFeedback, writer.uint32(50).fork()).ldelim();
    }
    if (message.armGazeFeedback !== undefined) {
      GazeCommand_Feedback.encode(message.armGazeFeedback, writer.uint32(66).fork()).ldelim();
    }
    if (message.armStopFeedback !== undefined) {
      ArmStopCommand_Feedback.encode(message.armStopFeedback, writer.uint32(74).fork()).ldelim();
    }
    if (message.armDragFeedback !== undefined) {
      ArmDragCommand_Feedback.encode(message.armDragFeedback, writer.uint32(82).fork()).ldelim();
    }
    if (message.armImpedanceFeedback !== undefined) {
      ArmImpedanceCommand_Feedback.encode(message.armImpedanceFeedback, writer.uint32(98).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(800).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.armCartesianFeedback = ArmCartesianCommand_Feedback.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.armJointMoveFeedback = ArmJointMoveCommand_Feedback.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.namedArmPositionFeedback = NamedArmPositionsCommand_Feedback.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.armVelocityFeedback = ArmVelocityCommand_Feedback.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.armGazeFeedback = GazeCommand_Feedback.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.armStopFeedback = ArmStopCommand_Feedback.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.armDragFeedback = ArmDragCommand_Feedback.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.armImpedanceFeedback = ArmImpedanceCommand_Feedback.decode(reader, reader.uint32());
          continue;
        case 100:
          if (tag !== 800) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmCommand_Feedback {
    return {
      armCartesianFeedback: isSet(object.armCartesianFeedback)
        ? ArmCartesianCommand_Feedback.fromJSON(object.armCartesianFeedback)
        : undefined,
      armJointMoveFeedback: isSet(object.armJointMoveFeedback)
        ? ArmJointMoveCommand_Feedback.fromJSON(object.armJointMoveFeedback)
        : undefined,
      namedArmPositionFeedback: isSet(object.namedArmPositionFeedback)
        ? NamedArmPositionsCommand_Feedback.fromJSON(object.namedArmPositionFeedback)
        : undefined,
      armVelocityFeedback: isSet(object.armVelocityFeedback)
        ? ArmVelocityCommand_Feedback.fromJSON(object.armVelocityFeedback)
        : undefined,
      armGazeFeedback: isSet(object.armGazeFeedback)
        ? GazeCommand_Feedback.fromJSON(object.armGazeFeedback)
        : undefined,
      armStopFeedback: isSet(object.armStopFeedback)
        ? ArmStopCommand_Feedback.fromJSON(object.armStopFeedback)
        : undefined,
      armDragFeedback: isSet(object.armDragFeedback)
        ? ArmDragCommand_Feedback.fromJSON(object.armDragFeedback)
        : undefined,
      armImpedanceFeedback: isSet(object.armImpedanceFeedback)
        ? ArmImpedanceCommand_Feedback.fromJSON(object.armImpedanceFeedback)
        : undefined,
      status: isSet(object.status) ? robotCommandFeedbackStatus_StatusFromJSON(object.status) : 0,
    };
  },

  toJSON(message: ArmCommand_Feedback): unknown {
    const obj: any = {};
    if (message.armCartesianFeedback !== undefined) {
      obj.armCartesianFeedback = ArmCartesianCommand_Feedback.toJSON(message.armCartesianFeedback);
    }
    if (message.armJointMoveFeedback !== undefined) {
      obj.armJointMoveFeedback = ArmJointMoveCommand_Feedback.toJSON(message.armJointMoveFeedback);
    }
    if (message.namedArmPositionFeedback !== undefined) {
      obj.namedArmPositionFeedback = NamedArmPositionsCommand_Feedback.toJSON(message.namedArmPositionFeedback);
    }
    if (message.armVelocityFeedback !== undefined) {
      obj.armVelocityFeedback = ArmVelocityCommand_Feedback.toJSON(message.armVelocityFeedback);
    }
    if (message.armGazeFeedback !== undefined) {
      obj.armGazeFeedback = GazeCommand_Feedback.toJSON(message.armGazeFeedback);
    }
    if (message.armStopFeedback !== undefined) {
      obj.armStopFeedback = ArmStopCommand_Feedback.toJSON(message.armStopFeedback);
    }
    if (message.armDragFeedback !== undefined) {
      obj.armDragFeedback = ArmDragCommand_Feedback.toJSON(message.armDragFeedback);
    }
    if (message.armImpedanceFeedback !== undefined) {
      obj.armImpedanceFeedback = ArmImpedanceCommand_Feedback.toJSON(message.armImpedanceFeedback);
    }
    if (message.status !== 0) {
      obj.status = robotCommandFeedbackStatus_StatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmCommand_Feedback>, I>>(base?: I): ArmCommand_Feedback {
    return ArmCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmCommand_Feedback>, I>>(object: I): ArmCommand_Feedback {
    const message = createBaseArmCommand_Feedback();
    message.armCartesianFeedback = (object.armCartesianFeedback !== undefined && object.armCartesianFeedback !== null)
      ? ArmCartesianCommand_Feedback.fromPartial(object.armCartesianFeedback)
      : undefined;
    message.armJointMoveFeedback = (object.armJointMoveFeedback !== undefined && object.armJointMoveFeedback !== null)
      ? ArmJointMoveCommand_Feedback.fromPartial(object.armJointMoveFeedback)
      : undefined;
    message.namedArmPositionFeedback =
      (object.namedArmPositionFeedback !== undefined && object.namedArmPositionFeedback !== null)
        ? NamedArmPositionsCommand_Feedback.fromPartial(object.namedArmPositionFeedback)
        : undefined;
    message.armVelocityFeedback = (object.armVelocityFeedback !== undefined && object.armVelocityFeedback !== null)
      ? ArmVelocityCommand_Feedback.fromPartial(object.armVelocityFeedback)
      : undefined;
    message.armGazeFeedback = (object.armGazeFeedback !== undefined && object.armGazeFeedback !== null)
      ? GazeCommand_Feedback.fromPartial(object.armGazeFeedback)
      : undefined;
    message.armStopFeedback = (object.armStopFeedback !== undefined && object.armStopFeedback !== null)
      ? ArmStopCommand_Feedback.fromPartial(object.armStopFeedback)
      : undefined;
    message.armDragFeedback = (object.armDragFeedback !== undefined && object.armDragFeedback !== null)
      ? ArmDragCommand_Feedback.fromPartial(object.armDragFeedback)
      : undefined;
    message.armImpedanceFeedback = (object.armImpedanceFeedback !== undefined && object.armImpedanceFeedback !== null)
      ? ArmImpedanceCommand_Feedback.fromPartial(object.armImpedanceFeedback)
      : undefined;
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseArmParams(): ArmParams {
  return { disableBodyForceLimiter: undefined };
}

export const ArmParams = {
  encode(message: ArmParams, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.disableBodyForceLimiter !== undefined) {
      BoolValue.encode({ value: message.disableBodyForceLimiter! }, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmParams {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.disableBodyForceLimiter = BoolValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmParams {
    return {
      disableBodyForceLimiter: isSet(object.disableBodyForceLimiter)
        ? Boolean(object.disableBodyForceLimiter)
        : undefined,
    };
  },

  toJSON(message: ArmParams): unknown {
    const obj: any = {};
    if (message.disableBodyForceLimiter !== undefined) {
      obj.disableBodyForceLimiter = message.disableBodyForceLimiter;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmParams>, I>>(base?: I): ArmParams {
    return ArmParams.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmParams>, I>>(object: I): ArmParams {
    const message = createBaseArmParams();
    message.disableBodyForceLimiter = object.disableBodyForceLimiter ?? undefined;
    return message;
  },
};

function createBaseArmVelocityCommand(): ArmVelocityCommand {
  return {};
}

export const ArmVelocityCommand = {
  encode(_: ArmVelocityCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmVelocityCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmVelocityCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ArmVelocityCommand {
    return {};
  },

  toJSON(_: ArmVelocityCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmVelocityCommand>, I>>(base?: I): ArmVelocityCommand {
    return ArmVelocityCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmVelocityCommand>, I>>(_: I): ArmVelocityCommand {
    const message = createBaseArmVelocityCommand();
    return message;
  },
};

function createBaseArmVelocityCommand_CylindricalVelocity(): ArmVelocityCommand_CylindricalVelocity {
  return { linearVelocity: undefined, maxLinearVelocity: undefined };
}

export const ArmVelocityCommand_CylindricalVelocity = {
  encode(message: ArmVelocityCommand_CylindricalVelocity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.linearVelocity !== undefined) {
      CylindricalCoordinate.encode(message.linearVelocity, writer.uint32(10).fork()).ldelim();
    }
    if (message.maxLinearVelocity !== undefined) {
      DoubleValue.encode({ value: message.maxLinearVelocity! }, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmVelocityCommand_CylindricalVelocity {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmVelocityCommand_CylindricalVelocity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linearVelocity = CylindricalCoordinate.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.maxLinearVelocity = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmVelocityCommand_CylindricalVelocity {
    return {
      linearVelocity: isSet(object.linearVelocity) ? CylindricalCoordinate.fromJSON(object.linearVelocity) : undefined,
      maxLinearVelocity: isSet(object.maxLinearVelocity) ? Number(object.maxLinearVelocity) : undefined,
    };
  },

  toJSON(message: ArmVelocityCommand_CylindricalVelocity): unknown {
    const obj: any = {};
    if (message.linearVelocity !== undefined) {
      obj.linearVelocity = CylindricalCoordinate.toJSON(message.linearVelocity);
    }
    if (message.maxLinearVelocity !== undefined) {
      obj.maxLinearVelocity = message.maxLinearVelocity;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmVelocityCommand_CylindricalVelocity>, I>>(
    base?: I,
  ): ArmVelocityCommand_CylindricalVelocity {
    return ArmVelocityCommand_CylindricalVelocity.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmVelocityCommand_CylindricalVelocity>, I>>(
    object: I,
  ): ArmVelocityCommand_CylindricalVelocity {
    const message = createBaseArmVelocityCommand_CylindricalVelocity();
    message.linearVelocity = (object.linearVelocity !== undefined && object.linearVelocity !== null)
      ? CylindricalCoordinate.fromPartial(object.linearVelocity)
      : undefined;
    message.maxLinearVelocity = object.maxLinearVelocity ?? undefined;
    return message;
  },
};

function createBaseArmVelocityCommand_CartesianVelocity(): ArmVelocityCommand_CartesianVelocity {
  return { frameName: "", velocityInFrameName: undefined };
}

export const ArmVelocityCommand_CartesianVelocity = {
  encode(message: ArmVelocityCommand_CartesianVelocity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.frameName !== "") {
      writer.uint32(10).string(message.frameName);
    }
    if (message.velocityInFrameName !== undefined) {
      Vec3.encode(message.velocityInFrameName, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmVelocityCommand_CartesianVelocity {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmVelocityCommand_CartesianVelocity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.frameName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.velocityInFrameName = Vec3.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmVelocityCommand_CartesianVelocity {
    return {
      frameName: isSet(object.frameName) ? globalThis.String(object.frameName) : "",
      velocityInFrameName: isSet(object.velocityInFrameName) ? Vec3.fromJSON(object.velocityInFrameName) : undefined,
    };
  },

  toJSON(message: ArmVelocityCommand_CartesianVelocity): unknown {
    const obj: any = {};
    if (message.frameName !== "") {
      obj.frameName = message.frameName;
    }
    if (message.velocityInFrameName !== undefined) {
      obj.velocityInFrameName = Vec3.toJSON(message.velocityInFrameName);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmVelocityCommand_CartesianVelocity>, I>>(
    base?: I,
  ): ArmVelocityCommand_CartesianVelocity {
    return ArmVelocityCommand_CartesianVelocity.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmVelocityCommand_CartesianVelocity>, I>>(
    object: I,
  ): ArmVelocityCommand_CartesianVelocity {
    const message = createBaseArmVelocityCommand_CartesianVelocity();
    message.frameName = object.frameName ?? "";
    message.velocityInFrameName = (object.velocityInFrameName !== undefined && object.velocityInFrameName !== null)
      ? Vec3.fromPartial(object.velocityInFrameName)
      : undefined;
    return message;
  },
};

function createBaseArmVelocityCommand_Request(): ArmVelocityCommand_Request {
  return {
    cylindricalVelocity: undefined,
    cartesianVelocity: undefined,
    angularVelocityOfHandRtOdomInHand: undefined,
    maximumAcceleration: undefined,
    endTime: undefined,
  };
}

export const ArmVelocityCommand_Request = {
  encode(message: ArmVelocityCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cylindricalVelocity !== undefined) {
      ArmVelocityCommand_CylindricalVelocity.encode(message.cylindricalVelocity, writer.uint32(10).fork()).ldelim();
    }
    if (message.cartesianVelocity !== undefined) {
      ArmVelocityCommand_CartesianVelocity.encode(message.cartesianVelocity, writer.uint32(18).fork()).ldelim();
    }
    if (message.angularVelocityOfHandRtOdomInHand !== undefined) {
      Vec3.encode(message.angularVelocityOfHandRtOdomInHand, writer.uint32(50).fork()).ldelim();
    }
    if (message.maximumAcceleration !== undefined) {
      DoubleValue.encode({ value: message.maximumAcceleration! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmVelocityCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmVelocityCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cylindricalVelocity = ArmVelocityCommand_CylindricalVelocity.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cartesianVelocity = ArmVelocityCommand_CartesianVelocity.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.angularVelocityOfHandRtOdomInHand = Vec3.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.maximumAcceleration = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmVelocityCommand_Request {
    return {
      cylindricalVelocity: isSet(object.cylindricalVelocity)
        ? ArmVelocityCommand_CylindricalVelocity.fromJSON(object.cylindricalVelocity)
        : undefined,
      cartesianVelocity: isSet(object.cartesianVelocity)
        ? ArmVelocityCommand_CartesianVelocity.fromJSON(object.cartesianVelocity)
        : undefined,
      angularVelocityOfHandRtOdomInHand: isSet(object.angularVelocityOfHandRtOdomInHand)
        ? Vec3.fromJSON(object.angularVelocityOfHandRtOdomInHand)
        : undefined,
      maximumAcceleration: isSet(object.maximumAcceleration) ? Number(object.maximumAcceleration) : undefined,
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
    };
  },

  toJSON(message: ArmVelocityCommand_Request): unknown {
    const obj: any = {};
    if (message.cylindricalVelocity !== undefined) {
      obj.cylindricalVelocity = ArmVelocityCommand_CylindricalVelocity.toJSON(message.cylindricalVelocity);
    }
    if (message.cartesianVelocity !== undefined) {
      obj.cartesianVelocity = ArmVelocityCommand_CartesianVelocity.toJSON(message.cartesianVelocity);
    }
    if (message.angularVelocityOfHandRtOdomInHand !== undefined) {
      obj.angularVelocityOfHandRtOdomInHand = Vec3.toJSON(message.angularVelocityOfHandRtOdomInHand);
    }
    if (message.maximumAcceleration !== undefined) {
      obj.maximumAcceleration = message.maximumAcceleration;
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmVelocityCommand_Request>, I>>(base?: I): ArmVelocityCommand_Request {
    return ArmVelocityCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmVelocityCommand_Request>, I>>(object: I): ArmVelocityCommand_Request {
    const message = createBaseArmVelocityCommand_Request();
    message.cylindricalVelocity = (object.cylindricalVelocity !== undefined && object.cylindricalVelocity !== null)
      ? ArmVelocityCommand_CylindricalVelocity.fromPartial(object.cylindricalVelocity)
      : undefined;
    message.cartesianVelocity = (object.cartesianVelocity !== undefined && object.cartesianVelocity !== null)
      ? ArmVelocityCommand_CartesianVelocity.fromPartial(object.cartesianVelocity)
      : undefined;
    message.angularVelocityOfHandRtOdomInHand =
      (object.angularVelocityOfHandRtOdomInHand !== undefined && object.angularVelocityOfHandRtOdomInHand !== null)
        ? Vec3.fromPartial(object.angularVelocityOfHandRtOdomInHand)
        : undefined;
    message.maximumAcceleration = object.maximumAcceleration ?? undefined;
    message.endTime = object.endTime ?? undefined;
    return message;
  },
};

function createBaseArmVelocityCommand_Feedback(): ArmVelocityCommand_Feedback {
  return {};
}

export const ArmVelocityCommand_Feedback = {
  encode(_: ArmVelocityCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmVelocityCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmVelocityCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ArmVelocityCommand_Feedback {
    return {};
  },

  toJSON(_: ArmVelocityCommand_Feedback): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmVelocityCommand_Feedback>, I>>(base?: I): ArmVelocityCommand_Feedback {
    return ArmVelocityCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmVelocityCommand_Feedback>, I>>(_: I): ArmVelocityCommand_Feedback {
    const message = createBaseArmVelocityCommand_Feedback();
    return message;
  },
};

function createBaseNamedArmPositionsCommand(): NamedArmPositionsCommand {
  return {};
}

export const NamedArmPositionsCommand = {
  encode(_: NamedArmPositionsCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NamedArmPositionsCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNamedArmPositionsCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): NamedArmPositionsCommand {
    return {};
  },

  toJSON(_: NamedArmPositionsCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<NamedArmPositionsCommand>, I>>(base?: I): NamedArmPositionsCommand {
    return NamedArmPositionsCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NamedArmPositionsCommand>, I>>(_: I): NamedArmPositionsCommand {
    const message = createBaseNamedArmPositionsCommand();
    return message;
  },
};

function createBaseNamedArmPositionsCommand_Request(): NamedArmPositionsCommand_Request {
  return { position: 0 };
}

export const NamedArmPositionsCommand_Request = {
  encode(message: NamedArmPositionsCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.position !== 0) {
      writer.uint32(8).int32(message.position);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NamedArmPositionsCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNamedArmPositionsCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.position = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NamedArmPositionsCommand_Request {
    return { position: isSet(object.position) ? namedArmPositionsCommand_PositionsFromJSON(object.position) : 0 };
  },

  toJSON(message: NamedArmPositionsCommand_Request): unknown {
    const obj: any = {};
    if (message.position !== 0) {
      obj.position = namedArmPositionsCommand_PositionsToJSON(message.position);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NamedArmPositionsCommand_Request>, I>>(
    base?: I,
  ): NamedArmPositionsCommand_Request {
    return NamedArmPositionsCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NamedArmPositionsCommand_Request>, I>>(
    object: I,
  ): NamedArmPositionsCommand_Request {
    const message = createBaseNamedArmPositionsCommand_Request();
    message.position = object.position ?? 0;
    return message;
  },
};

function createBaseNamedArmPositionsCommand_Feedback(): NamedArmPositionsCommand_Feedback {
  return { status: 0 };
}

export const NamedArmPositionsCommand_Feedback = {
  encode(message: NamedArmPositionsCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NamedArmPositionsCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNamedArmPositionsCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NamedArmPositionsCommand_Feedback {
    return { status: isSet(object.status) ? namedArmPositionsCommand_Feedback_StatusFromJSON(object.status) : 0 };
  },

  toJSON(message: NamedArmPositionsCommand_Feedback): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = namedArmPositionsCommand_Feedback_StatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NamedArmPositionsCommand_Feedback>, I>>(
    base?: I,
  ): NamedArmPositionsCommand_Feedback {
    return NamedArmPositionsCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NamedArmPositionsCommand_Feedback>, I>>(
    object: I,
  ): NamedArmPositionsCommand_Feedback {
    const message = createBaseNamedArmPositionsCommand_Feedback();
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseArmCartesianCommand(): ArmCartesianCommand {
  return {};
}

export const ArmCartesianCommand = {
  encode(_: ArmCartesianCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmCartesianCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmCartesianCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ArmCartesianCommand {
    return {};
  },

  toJSON(_: ArmCartesianCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmCartesianCommand>, I>>(base?: I): ArmCartesianCommand {
    return ArmCartesianCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmCartesianCommand>, I>>(_: I): ArmCartesianCommand {
    const message = createBaseArmCartesianCommand();
    return message;
  },
};

function createBaseArmCartesianCommand_Request(): ArmCartesianCommand_Request {
  return {
    rootFrameName: "",
    wristTformTool: undefined,
    rootTformTask: undefined,
    poseTrajectoryInTask: undefined,
    maximumAcceleration: undefined,
    maxLinearVelocity: undefined,
    maxAngularVelocity: undefined,
    maxPosTrackingError: undefined,
    maxRotTrackingError: undefined,
    forceRemainNearCurrentJointConfiguration: undefined,
    preferredJointConfiguration: undefined,
    xAxis: 0,
    yAxis: 0,
    zAxis: 0,
    rxAxis: 0,
    ryAxis: 0,
    rzAxis: 0,
    wrenchTrajectoryInTask: undefined,
    disableVelocityLimiting: undefined,
  };
}

export const ArmCartesianCommand_Request = {
  encode(message: ArmCartesianCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rootFrameName !== "") {
      writer.uint32(154).string(message.rootFrameName);
    }
    if (message.wristTformTool !== undefined) {
      SE3Pose.encode(message.wristTformTool, writer.uint32(50).fork()).ldelim();
    }
    if (message.rootTformTask !== undefined) {
      SE3Pose.encode(message.rootTformTask, writer.uint32(162).fork()).ldelim();
    }
    if (message.poseTrajectoryInTask !== undefined) {
      SE3Trajectory.encode(message.poseTrajectoryInTask, writer.uint32(18).fork()).ldelim();
    }
    if (message.maximumAcceleration !== undefined) {
      DoubleValue.encode({ value: message.maximumAcceleration! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.maxLinearVelocity !== undefined) {
      DoubleValue.encode({ value: message.maxLinearVelocity! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.maxAngularVelocity !== undefined) {
      DoubleValue.encode({ value: message.maxAngularVelocity! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.maxPosTrackingError !== undefined) {
      DoubleValue.encode({ value: message.maxPosTrackingError! }, writer.uint32(122).fork()).ldelim();
    }
    if (message.maxRotTrackingError !== undefined) {
      DoubleValue.encode({ value: message.maxRotTrackingError! }, writer.uint32(130).fork()).ldelim();
    }
    if (message.forceRemainNearCurrentJointConfiguration !== undefined) {
      writer.uint32(136).bool(message.forceRemainNearCurrentJointConfiguration);
    }
    if (message.preferredJointConfiguration !== undefined) {
      ArmJointPosition.encode(message.preferredJointConfiguration, writer.uint32(146).fork()).ldelim();
    }
    if (message.xAxis !== 0) {
      writer.uint32(64).int32(message.xAxis);
    }
    if (message.yAxis !== 0) {
      writer.uint32(72).int32(message.yAxis);
    }
    if (message.zAxis !== 0) {
      writer.uint32(80).int32(message.zAxis);
    }
    if (message.rxAxis !== 0) {
      writer.uint32(88).int32(message.rxAxis);
    }
    if (message.ryAxis !== 0) {
      writer.uint32(96).int32(message.ryAxis);
    }
    if (message.rzAxis !== 0) {
      writer.uint32(104).int32(message.rzAxis);
    }
    if (message.wrenchTrajectoryInTask !== undefined) {
      WrenchTrajectory.encode(message.wrenchTrajectoryInTask, writer.uint32(114).fork()).ldelim();
    }
    if (message.disableVelocityLimiting !== undefined) {
      BoolValue.encode({ value: message.disableVelocityLimiting! }, writer.uint32(170).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmCartesianCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmCartesianCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 19:
          if (tag !== 154) {
            break;
          }

          message.rootFrameName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.wristTformTool = SE3Pose.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.rootTformTask = SE3Pose.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.poseTrajectoryInTask = SE3Trajectory.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.maximumAcceleration = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.maxLinearVelocity = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.maxAngularVelocity = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.maxPosTrackingError = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.maxRotTrackingError = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.forceRemainNearCurrentJointConfiguration = reader.bool();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.preferredJointConfiguration = ArmJointPosition.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.xAxis = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.yAxis = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.zAxis = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.rxAxis = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.ryAxis = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.rzAxis = reader.int32() as any;
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.wrenchTrajectoryInTask = WrenchTrajectory.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.disableVelocityLimiting = BoolValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmCartesianCommand_Request {
    return {
      rootFrameName: isSet(object.rootFrameName) ? globalThis.String(object.rootFrameName) : "",
      wristTformTool: isSet(object.wristTformTool) ? SE3Pose.fromJSON(object.wristTformTool) : undefined,
      rootTformTask: isSet(object.rootTformTask) ? SE3Pose.fromJSON(object.rootTformTask) : undefined,
      poseTrajectoryInTask: isSet(object.poseTrajectoryInTask)
        ? SE3Trajectory.fromJSON(object.poseTrajectoryInTask)
        : undefined,
      maximumAcceleration: isSet(object.maximumAcceleration) ? Number(object.maximumAcceleration) : undefined,
      maxLinearVelocity: isSet(object.maxLinearVelocity) ? Number(object.maxLinearVelocity) : undefined,
      maxAngularVelocity: isSet(object.maxAngularVelocity) ? Number(object.maxAngularVelocity) : undefined,
      maxPosTrackingError: isSet(object.maxPosTrackingError) ? Number(object.maxPosTrackingError) : undefined,
      maxRotTrackingError: isSet(object.maxRotTrackingError) ? Number(object.maxRotTrackingError) : undefined,
      forceRemainNearCurrentJointConfiguration: isSet(object.forceRemainNearCurrentJointConfiguration)
        ? globalThis.Boolean(object.forceRemainNearCurrentJointConfiguration)
        : undefined,
      preferredJointConfiguration: isSet(object.preferredJointConfiguration)
        ? ArmJointPosition.fromJSON(object.preferredJointConfiguration)
        : undefined,
      xAxis: isSet(object.xAxis) ? armCartesianCommand_Request_AxisModeFromJSON(object.xAxis) : 0,
      yAxis: isSet(object.yAxis) ? armCartesianCommand_Request_AxisModeFromJSON(object.yAxis) : 0,
      zAxis: isSet(object.zAxis) ? armCartesianCommand_Request_AxisModeFromJSON(object.zAxis) : 0,
      rxAxis: isSet(object.rxAxis) ? armCartesianCommand_Request_AxisModeFromJSON(object.rxAxis) : 0,
      ryAxis: isSet(object.ryAxis) ? armCartesianCommand_Request_AxisModeFromJSON(object.ryAxis) : 0,
      rzAxis: isSet(object.rzAxis) ? armCartesianCommand_Request_AxisModeFromJSON(object.rzAxis) : 0,
      wrenchTrajectoryInTask: isSet(object.wrenchTrajectoryInTask)
        ? WrenchTrajectory.fromJSON(object.wrenchTrajectoryInTask)
        : undefined,
      disableVelocityLimiting: isSet(object.disableVelocityLimiting)
        ? Boolean(object.disableVelocityLimiting)
        : undefined,
    };
  },

  toJSON(message: ArmCartesianCommand_Request): unknown {
    const obj: any = {};
    if (message.rootFrameName !== "") {
      obj.rootFrameName = message.rootFrameName;
    }
    if (message.wristTformTool !== undefined) {
      obj.wristTformTool = SE3Pose.toJSON(message.wristTformTool);
    }
    if (message.rootTformTask !== undefined) {
      obj.rootTformTask = SE3Pose.toJSON(message.rootTformTask);
    }
    if (message.poseTrajectoryInTask !== undefined) {
      obj.poseTrajectoryInTask = SE3Trajectory.toJSON(message.poseTrajectoryInTask);
    }
    if (message.maximumAcceleration !== undefined) {
      obj.maximumAcceleration = message.maximumAcceleration;
    }
    if (message.maxLinearVelocity !== undefined) {
      obj.maxLinearVelocity = message.maxLinearVelocity;
    }
    if (message.maxAngularVelocity !== undefined) {
      obj.maxAngularVelocity = message.maxAngularVelocity;
    }
    if (message.maxPosTrackingError !== undefined) {
      obj.maxPosTrackingError = message.maxPosTrackingError;
    }
    if (message.maxRotTrackingError !== undefined) {
      obj.maxRotTrackingError = message.maxRotTrackingError;
    }
    if (message.forceRemainNearCurrentJointConfiguration !== undefined) {
      obj.forceRemainNearCurrentJointConfiguration = message.forceRemainNearCurrentJointConfiguration;
    }
    if (message.preferredJointConfiguration !== undefined) {
      obj.preferredJointConfiguration = ArmJointPosition.toJSON(message.preferredJointConfiguration);
    }
    if (message.xAxis !== 0) {
      obj.xAxis = armCartesianCommand_Request_AxisModeToJSON(message.xAxis);
    }
    if (message.yAxis !== 0) {
      obj.yAxis = armCartesianCommand_Request_AxisModeToJSON(message.yAxis);
    }
    if (message.zAxis !== 0) {
      obj.zAxis = armCartesianCommand_Request_AxisModeToJSON(message.zAxis);
    }
    if (message.rxAxis !== 0) {
      obj.rxAxis = armCartesianCommand_Request_AxisModeToJSON(message.rxAxis);
    }
    if (message.ryAxis !== 0) {
      obj.ryAxis = armCartesianCommand_Request_AxisModeToJSON(message.ryAxis);
    }
    if (message.rzAxis !== 0) {
      obj.rzAxis = armCartesianCommand_Request_AxisModeToJSON(message.rzAxis);
    }
    if (message.wrenchTrajectoryInTask !== undefined) {
      obj.wrenchTrajectoryInTask = WrenchTrajectory.toJSON(message.wrenchTrajectoryInTask);
    }
    if (message.disableVelocityLimiting !== undefined) {
      obj.disableVelocityLimiting = message.disableVelocityLimiting;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmCartesianCommand_Request>, I>>(base?: I): ArmCartesianCommand_Request {
    return ArmCartesianCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmCartesianCommand_Request>, I>>(object: I): ArmCartesianCommand_Request {
    const message = createBaseArmCartesianCommand_Request();
    message.rootFrameName = object.rootFrameName ?? "";
    message.wristTformTool = (object.wristTformTool !== undefined && object.wristTformTool !== null)
      ? SE3Pose.fromPartial(object.wristTformTool)
      : undefined;
    message.rootTformTask = (object.rootTformTask !== undefined && object.rootTformTask !== null)
      ? SE3Pose.fromPartial(object.rootTformTask)
      : undefined;
    message.poseTrajectoryInTask = (object.poseTrajectoryInTask !== undefined && object.poseTrajectoryInTask !== null)
      ? SE3Trajectory.fromPartial(object.poseTrajectoryInTask)
      : undefined;
    message.maximumAcceleration = object.maximumAcceleration ?? undefined;
    message.maxLinearVelocity = object.maxLinearVelocity ?? undefined;
    message.maxAngularVelocity = object.maxAngularVelocity ?? undefined;
    message.maxPosTrackingError = object.maxPosTrackingError ?? undefined;
    message.maxRotTrackingError = object.maxRotTrackingError ?? undefined;
    message.forceRemainNearCurrentJointConfiguration = object.forceRemainNearCurrentJointConfiguration ?? undefined;
    message.preferredJointConfiguration =
      (object.preferredJointConfiguration !== undefined && object.preferredJointConfiguration !== null)
        ? ArmJointPosition.fromPartial(object.preferredJointConfiguration)
        : undefined;
    message.xAxis = object.xAxis ?? 0;
    message.yAxis = object.yAxis ?? 0;
    message.zAxis = object.zAxis ?? 0;
    message.rxAxis = object.rxAxis ?? 0;
    message.ryAxis = object.ryAxis ?? 0;
    message.rzAxis = object.rzAxis ?? 0;
    message.wrenchTrajectoryInTask =
      (object.wrenchTrajectoryInTask !== undefined && object.wrenchTrajectoryInTask !== null)
        ? WrenchTrajectory.fromPartial(object.wrenchTrajectoryInTask)
        : undefined;
    message.disableVelocityLimiting = object.disableVelocityLimiting ?? undefined;
    return message;
  },
};

function createBaseArmCartesianCommand_Feedback(): ArmCartesianCommand_Feedback {
  return {
    status: 0,
    measuredPosTrackingError: 0,
    measuredRotTrackingError: 0,
    measuredPosDistanceToGoal: 0,
    measuredRotDistanceToGoal: 0,
  };
}

export const ArmCartesianCommand_Feedback = {
  encode(message: ArmCartesianCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.measuredPosTrackingError !== 0) {
      writer.uint32(17).double(message.measuredPosTrackingError);
    }
    if (message.measuredRotTrackingError !== 0) {
      writer.uint32(25).double(message.measuredRotTrackingError);
    }
    if (message.measuredPosDistanceToGoal !== 0) {
      writer.uint32(33).double(message.measuredPosDistanceToGoal);
    }
    if (message.measuredRotDistanceToGoal !== 0) {
      writer.uint32(41).double(message.measuredRotDistanceToGoal);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmCartesianCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmCartesianCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.measuredPosTrackingError = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.measuredRotTrackingError = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.measuredPosDistanceToGoal = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.measuredRotDistanceToGoal = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmCartesianCommand_Feedback {
    return {
      status: isSet(object.status) ? armCartesianCommand_Feedback_StatusFromJSON(object.status) : 0,
      measuredPosTrackingError: isSet(object.measuredPosTrackingError)
        ? globalThis.Number(object.measuredPosTrackingError)
        : 0,
      measuredRotTrackingError: isSet(object.measuredRotTrackingError)
        ? globalThis.Number(object.measuredRotTrackingError)
        : 0,
      measuredPosDistanceToGoal: isSet(object.measuredPosDistanceToGoal)
        ? globalThis.Number(object.measuredPosDistanceToGoal)
        : 0,
      measuredRotDistanceToGoal: isSet(object.measuredRotDistanceToGoal)
        ? globalThis.Number(object.measuredRotDistanceToGoal)
        : 0,
    };
  },

  toJSON(message: ArmCartesianCommand_Feedback): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = armCartesianCommand_Feedback_StatusToJSON(message.status);
    }
    if (message.measuredPosTrackingError !== 0) {
      obj.measuredPosTrackingError = message.measuredPosTrackingError;
    }
    if (message.measuredRotTrackingError !== 0) {
      obj.measuredRotTrackingError = message.measuredRotTrackingError;
    }
    if (message.measuredPosDistanceToGoal !== 0) {
      obj.measuredPosDistanceToGoal = message.measuredPosDistanceToGoal;
    }
    if (message.measuredRotDistanceToGoal !== 0) {
      obj.measuredRotDistanceToGoal = message.measuredRotDistanceToGoal;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmCartesianCommand_Feedback>, I>>(base?: I): ArmCartesianCommand_Feedback {
    return ArmCartesianCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmCartesianCommand_Feedback>, I>>(object: I): ArmCartesianCommand_Feedback {
    const message = createBaseArmCartesianCommand_Feedback();
    message.status = object.status ?? 0;
    message.measuredPosTrackingError = object.measuredPosTrackingError ?? 0;
    message.measuredRotTrackingError = object.measuredRotTrackingError ?? 0;
    message.measuredPosDistanceToGoal = object.measuredPosDistanceToGoal ?? 0;
    message.measuredRotDistanceToGoal = object.measuredRotDistanceToGoal ?? 0;
    return message;
  },
};

function createBaseArmJointMoveCommand(): ArmJointMoveCommand {
  return {};
}

export const ArmJointMoveCommand = {
  encode(_: ArmJointMoveCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmJointMoveCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmJointMoveCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ArmJointMoveCommand {
    return {};
  },

  toJSON(_: ArmJointMoveCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmJointMoveCommand>, I>>(base?: I): ArmJointMoveCommand {
    return ArmJointMoveCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmJointMoveCommand>, I>>(_: I): ArmJointMoveCommand {
    const message = createBaseArmJointMoveCommand();
    return message;
  },
};

function createBaseArmJointMoveCommand_Request(): ArmJointMoveCommand_Request {
  return { trajectory: undefined };
}

export const ArmJointMoveCommand_Request = {
  encode(message: ArmJointMoveCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.trajectory !== undefined) {
      ArmJointTrajectory.encode(message.trajectory, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmJointMoveCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmJointMoveCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.trajectory = ArmJointTrajectory.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmJointMoveCommand_Request {
    return { trajectory: isSet(object.trajectory) ? ArmJointTrajectory.fromJSON(object.trajectory) : undefined };
  },

  toJSON(message: ArmJointMoveCommand_Request): unknown {
    const obj: any = {};
    if (message.trajectory !== undefined) {
      obj.trajectory = ArmJointTrajectory.toJSON(message.trajectory);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmJointMoveCommand_Request>, I>>(base?: I): ArmJointMoveCommand_Request {
    return ArmJointMoveCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmJointMoveCommand_Request>, I>>(object: I): ArmJointMoveCommand_Request {
    const message = createBaseArmJointMoveCommand_Request();
    message.trajectory = (object.trajectory !== undefined && object.trajectory !== null)
      ? ArmJointTrajectory.fromPartial(object.trajectory)
      : undefined;
    return message;
  },
};

function createBaseArmJointMoveCommand_Feedback(): ArmJointMoveCommand_Feedback {
  return { status: 0, plannerStatus: 0, plannedPoints: [], timeToGoal: undefined };
}

export const ArmJointMoveCommand_Feedback = {
  encode(message: ArmJointMoveCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.plannerStatus !== 0) {
      writer.uint32(16).int32(message.plannerStatus);
    }
    for (const v of message.plannedPoints) {
      ArmJointTrajectoryPoint.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.timeToGoal !== undefined) {
      Duration.encode(message.timeToGoal, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmJointMoveCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmJointMoveCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.plannerStatus = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.plannedPoints.push(ArmJointTrajectoryPoint.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.timeToGoal = Duration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmJointMoveCommand_Feedback {
    return {
      status: isSet(object.status) ? armJointMoveCommand_Feedback_StatusFromJSON(object.status) : 0,
      plannerStatus: isSet(object.plannerStatus)
        ? armJointMoveCommand_Feedback_PlannerStatusFromJSON(object.plannerStatus)
        : 0,
      plannedPoints: globalThis.Array.isArray(object?.plannedPoints)
        ? object.plannedPoints.map((e: any) => ArmJointTrajectoryPoint.fromJSON(e))
        : [],
      timeToGoal: isSet(object.timeToGoal) ? Duration.fromJSON(object.timeToGoal) : undefined,
    };
  },

  toJSON(message: ArmJointMoveCommand_Feedback): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = armJointMoveCommand_Feedback_StatusToJSON(message.status);
    }
    if (message.plannerStatus !== 0) {
      obj.plannerStatus = armJointMoveCommand_Feedback_PlannerStatusToJSON(message.plannerStatus);
    }
    if (message.plannedPoints?.length) {
      obj.plannedPoints = message.plannedPoints.map((e) => ArmJointTrajectoryPoint.toJSON(e));
    }
    if (message.timeToGoal !== undefined) {
      obj.timeToGoal = Duration.toJSON(message.timeToGoal);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmJointMoveCommand_Feedback>, I>>(base?: I): ArmJointMoveCommand_Feedback {
    return ArmJointMoveCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmJointMoveCommand_Feedback>, I>>(object: I): ArmJointMoveCommand_Feedback {
    const message = createBaseArmJointMoveCommand_Feedback();
    message.status = object.status ?? 0;
    message.plannerStatus = object.plannerStatus ?? 0;
    message.plannedPoints = object.plannedPoints?.map((e) => ArmJointTrajectoryPoint.fromPartial(e)) || [];
    message.timeToGoal = (object.timeToGoal !== undefined && object.timeToGoal !== null)
      ? Duration.fromPartial(object.timeToGoal)
      : undefined;
    return message;
  },
};

function createBaseArmJointPosition(): ArmJointPosition {
  return { sh0: undefined, sh1: undefined, el0: undefined, el1: undefined, wr0: undefined, wr1: undefined };
}

export const ArmJointPosition = {
  encode(message: ArmJointPosition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sh0 !== undefined) {
      DoubleValue.encode({ value: message.sh0! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.sh1 !== undefined) {
      DoubleValue.encode({ value: message.sh1! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.el0 !== undefined) {
      DoubleValue.encode({ value: message.el0! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.el1 !== undefined) {
      DoubleValue.encode({ value: message.el1! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.wr0 !== undefined) {
      DoubleValue.encode({ value: message.wr0! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.wr1 !== undefined) {
      DoubleValue.encode({ value: message.wr1! }, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmJointPosition {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmJointPosition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sh0 = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sh1 = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.el0 = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.el1 = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.wr0 = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.wr1 = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmJointPosition {
    return {
      sh0: isSet(object.sh0) ? Number(object.sh0) : undefined,
      sh1: isSet(object.sh1) ? Number(object.sh1) : undefined,
      el0: isSet(object.el0) ? Number(object.el0) : undefined,
      el1: isSet(object.el1) ? Number(object.el1) : undefined,
      wr0: isSet(object.wr0) ? Number(object.wr0) : undefined,
      wr1: isSet(object.wr1) ? Number(object.wr1) : undefined,
    };
  },

  toJSON(message: ArmJointPosition): unknown {
    const obj: any = {};
    if (message.sh0 !== undefined) {
      obj.sh0 = message.sh0;
    }
    if (message.sh1 !== undefined) {
      obj.sh1 = message.sh1;
    }
    if (message.el0 !== undefined) {
      obj.el0 = message.el0;
    }
    if (message.el1 !== undefined) {
      obj.el1 = message.el1;
    }
    if (message.wr0 !== undefined) {
      obj.wr0 = message.wr0;
    }
    if (message.wr1 !== undefined) {
      obj.wr1 = message.wr1;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmJointPosition>, I>>(base?: I): ArmJointPosition {
    return ArmJointPosition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmJointPosition>, I>>(object: I): ArmJointPosition {
    const message = createBaseArmJointPosition();
    message.sh0 = object.sh0 ?? undefined;
    message.sh1 = object.sh1 ?? undefined;
    message.el0 = object.el0 ?? undefined;
    message.el1 = object.el1 ?? undefined;
    message.wr0 = object.wr0 ?? undefined;
    message.wr1 = object.wr1 ?? undefined;
    return message;
  },
};

function createBaseArmJointVelocity(): ArmJointVelocity {
  return { sh0: undefined, sh1: undefined, el0: undefined, el1: undefined, wr0: undefined, wr1: undefined };
}

export const ArmJointVelocity = {
  encode(message: ArmJointVelocity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sh0 !== undefined) {
      DoubleValue.encode({ value: message.sh0! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.sh1 !== undefined) {
      DoubleValue.encode({ value: message.sh1! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.el0 !== undefined) {
      DoubleValue.encode({ value: message.el0! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.el1 !== undefined) {
      DoubleValue.encode({ value: message.el1! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.wr0 !== undefined) {
      DoubleValue.encode({ value: message.wr0! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.wr1 !== undefined) {
      DoubleValue.encode({ value: message.wr1! }, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmJointVelocity {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmJointVelocity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sh0 = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sh1 = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.el0 = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.el1 = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.wr0 = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.wr1 = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmJointVelocity {
    return {
      sh0: isSet(object.sh0) ? Number(object.sh0) : undefined,
      sh1: isSet(object.sh1) ? Number(object.sh1) : undefined,
      el0: isSet(object.el0) ? Number(object.el0) : undefined,
      el1: isSet(object.el1) ? Number(object.el1) : undefined,
      wr0: isSet(object.wr0) ? Number(object.wr0) : undefined,
      wr1: isSet(object.wr1) ? Number(object.wr1) : undefined,
    };
  },

  toJSON(message: ArmJointVelocity): unknown {
    const obj: any = {};
    if (message.sh0 !== undefined) {
      obj.sh0 = message.sh0;
    }
    if (message.sh1 !== undefined) {
      obj.sh1 = message.sh1;
    }
    if (message.el0 !== undefined) {
      obj.el0 = message.el0;
    }
    if (message.el1 !== undefined) {
      obj.el1 = message.el1;
    }
    if (message.wr0 !== undefined) {
      obj.wr0 = message.wr0;
    }
    if (message.wr1 !== undefined) {
      obj.wr1 = message.wr1;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmJointVelocity>, I>>(base?: I): ArmJointVelocity {
    return ArmJointVelocity.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmJointVelocity>, I>>(object: I): ArmJointVelocity {
    const message = createBaseArmJointVelocity();
    message.sh0 = object.sh0 ?? undefined;
    message.sh1 = object.sh1 ?? undefined;
    message.el0 = object.el0 ?? undefined;
    message.el1 = object.el1 ?? undefined;
    message.wr0 = object.wr0 ?? undefined;
    message.wr1 = object.wr1 ?? undefined;
    return message;
  },
};

function createBaseArmJointTrajectoryPoint(): ArmJointTrajectoryPoint {
  return { position: undefined, velocity: undefined, timeSinceReference: undefined };
}

export const ArmJointTrajectoryPoint = {
  encode(message: ArmJointTrajectoryPoint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.position !== undefined) {
      ArmJointPosition.encode(message.position, writer.uint32(10).fork()).ldelim();
    }
    if (message.velocity !== undefined) {
      ArmJointVelocity.encode(message.velocity, writer.uint32(18).fork()).ldelim();
    }
    if (message.timeSinceReference !== undefined) {
      Duration.encode(message.timeSinceReference, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmJointTrajectoryPoint {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmJointTrajectoryPoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.position = ArmJointPosition.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.velocity = ArmJointVelocity.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.timeSinceReference = Duration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmJointTrajectoryPoint {
    return {
      position: isSet(object.position) ? ArmJointPosition.fromJSON(object.position) : undefined,
      velocity: isSet(object.velocity) ? ArmJointVelocity.fromJSON(object.velocity) : undefined,
      timeSinceReference: isSet(object.timeSinceReference) ? Duration.fromJSON(object.timeSinceReference) : undefined,
    };
  },

  toJSON(message: ArmJointTrajectoryPoint): unknown {
    const obj: any = {};
    if (message.position !== undefined) {
      obj.position = ArmJointPosition.toJSON(message.position);
    }
    if (message.velocity !== undefined) {
      obj.velocity = ArmJointVelocity.toJSON(message.velocity);
    }
    if (message.timeSinceReference !== undefined) {
      obj.timeSinceReference = Duration.toJSON(message.timeSinceReference);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmJointTrajectoryPoint>, I>>(base?: I): ArmJointTrajectoryPoint {
    return ArmJointTrajectoryPoint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmJointTrajectoryPoint>, I>>(object: I): ArmJointTrajectoryPoint {
    const message = createBaseArmJointTrajectoryPoint();
    message.position = (object.position !== undefined && object.position !== null)
      ? ArmJointPosition.fromPartial(object.position)
      : undefined;
    message.velocity = (object.velocity !== undefined && object.velocity !== null)
      ? ArmJointVelocity.fromPartial(object.velocity)
      : undefined;
    message.timeSinceReference = (object.timeSinceReference !== undefined && object.timeSinceReference !== null)
      ? Duration.fromPartial(object.timeSinceReference)
      : undefined;
    return message;
  },
};

function createBaseArmJointTrajectory(): ArmJointTrajectory {
  return { points: [], referenceTime: undefined, maximumVelocity: undefined, maximumAcceleration: undefined };
}

export const ArmJointTrajectory = {
  encode(message: ArmJointTrajectory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.points) {
      ArmJointTrajectoryPoint.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.referenceTime !== undefined) {
      Timestamp.encode(toTimestamp(message.referenceTime), writer.uint32(18).fork()).ldelim();
    }
    if (message.maximumVelocity !== undefined) {
      DoubleValue.encode({ value: message.maximumVelocity! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.maximumAcceleration !== undefined) {
      DoubleValue.encode({ value: message.maximumAcceleration! }, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmJointTrajectory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmJointTrajectory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.points.push(ArmJointTrajectoryPoint.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.referenceTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.maximumVelocity = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.maximumAcceleration = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmJointTrajectory {
    return {
      points: globalThis.Array.isArray(object?.points)
        ? object.points.map((e: any) => ArmJointTrajectoryPoint.fromJSON(e))
        : [],
      referenceTime: isSet(object.referenceTime) ? fromJsonTimestamp(object.referenceTime) : undefined,
      maximumVelocity: isSet(object.maximumVelocity) ? Number(object.maximumVelocity) : undefined,
      maximumAcceleration: isSet(object.maximumAcceleration) ? Number(object.maximumAcceleration) : undefined,
    };
  },

  toJSON(message: ArmJointTrajectory): unknown {
    const obj: any = {};
    if (message.points?.length) {
      obj.points = message.points.map((e) => ArmJointTrajectoryPoint.toJSON(e));
    }
    if (message.referenceTime !== undefined) {
      obj.referenceTime = message.referenceTime.toISOString();
    }
    if (message.maximumVelocity !== undefined) {
      obj.maximumVelocity = message.maximumVelocity;
    }
    if (message.maximumAcceleration !== undefined) {
      obj.maximumAcceleration = message.maximumAcceleration;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmJointTrajectory>, I>>(base?: I): ArmJointTrajectory {
    return ArmJointTrajectory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmJointTrajectory>, I>>(object: I): ArmJointTrajectory {
    const message = createBaseArmJointTrajectory();
    message.points = object.points?.map((e) => ArmJointTrajectoryPoint.fromPartial(e)) || [];
    message.referenceTime = object.referenceTime ?? undefined;
    message.maximumVelocity = object.maximumVelocity ?? undefined;
    message.maximumAcceleration = object.maximumAcceleration ?? undefined;
    return message;
  },
};

function createBaseGazeCommand(): GazeCommand {
  return {};
}

export const GazeCommand = {
  encode(_: GazeCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GazeCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGazeCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GazeCommand {
    return {};
  },

  toJSON(_: GazeCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GazeCommand>, I>>(base?: I): GazeCommand {
    return GazeCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GazeCommand>, I>>(_: I): GazeCommand {
    const message = createBaseGazeCommand();
    return message;
  },
};

function createBaseGazeCommand_Request(): GazeCommand_Request {
  return {
    targetTrajectoryInFrame1: undefined,
    frame1Name: "",
    toolTrajectoryInFrame2: undefined,
    frame2Name: "",
    wristTformTool: undefined,
    targetTrajectoryInitialVelocity: undefined,
    maximumAcceleration: undefined,
    maxLinearVelocity: undefined,
    maxAngularVelocity: undefined,
  };
}

export const GazeCommand_Request = {
  encode(message: GazeCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.targetTrajectoryInFrame1 !== undefined) {
      Vec3Trajectory.encode(message.targetTrajectoryInFrame1, writer.uint32(10).fork()).ldelim();
    }
    if (message.frame1Name !== "") {
      writer.uint32(18).string(message.frame1Name);
    }
    if (message.toolTrajectoryInFrame2 !== undefined) {
      SE3Trajectory.encode(message.toolTrajectoryInFrame2, writer.uint32(82).fork()).ldelim();
    }
    if (message.frame2Name !== "") {
      writer.uint32(90).string(message.frame2Name);
    }
    if (message.wristTformTool !== undefined) {
      SE3Pose.encode(message.wristTformTool, writer.uint32(74).fork()).ldelim();
    }
    if (message.targetTrajectoryInitialVelocity !== undefined) {
      DoubleValue.encode({ value: message.targetTrajectoryInitialVelocity! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.maximumAcceleration !== undefined) {
      DoubleValue.encode({ value: message.maximumAcceleration! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.maxLinearVelocity !== undefined) {
      DoubleValue.encode({ value: message.maxLinearVelocity! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.maxAngularVelocity !== undefined) {
      DoubleValue.encode({ value: message.maxAngularVelocity! }, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GazeCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGazeCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.targetTrajectoryInFrame1 = Vec3Trajectory.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.frame1Name = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.toolTrajectoryInFrame2 = SE3Trajectory.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.frame2Name = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.wristTformTool = SE3Pose.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.targetTrajectoryInitialVelocity = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.maximumAcceleration = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.maxLinearVelocity = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.maxAngularVelocity = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GazeCommand_Request {
    return {
      targetTrajectoryInFrame1: isSet(object.targetTrajectoryInFrame1)
        ? Vec3Trajectory.fromJSON(object.targetTrajectoryInFrame1)
        : undefined,
      frame1Name: isSet(object.frame1Name) ? globalThis.String(object.frame1Name) : "",
      toolTrajectoryInFrame2: isSet(object.toolTrajectoryInFrame2)
        ? SE3Trajectory.fromJSON(object.toolTrajectoryInFrame2)
        : undefined,
      frame2Name: isSet(object.frame2Name) ? globalThis.String(object.frame2Name) : "",
      wristTformTool: isSet(object.wristTformTool) ? SE3Pose.fromJSON(object.wristTformTool) : undefined,
      targetTrajectoryInitialVelocity: isSet(object.targetTrajectoryInitialVelocity)
        ? Number(object.targetTrajectoryInitialVelocity)
        : undefined,
      maximumAcceleration: isSet(object.maximumAcceleration) ? Number(object.maximumAcceleration) : undefined,
      maxLinearVelocity: isSet(object.maxLinearVelocity) ? Number(object.maxLinearVelocity) : undefined,
      maxAngularVelocity: isSet(object.maxAngularVelocity) ? Number(object.maxAngularVelocity) : undefined,
    };
  },

  toJSON(message: GazeCommand_Request): unknown {
    const obj: any = {};
    if (message.targetTrajectoryInFrame1 !== undefined) {
      obj.targetTrajectoryInFrame1 = Vec3Trajectory.toJSON(message.targetTrajectoryInFrame1);
    }
    if (message.frame1Name !== "") {
      obj.frame1Name = message.frame1Name;
    }
    if (message.toolTrajectoryInFrame2 !== undefined) {
      obj.toolTrajectoryInFrame2 = SE3Trajectory.toJSON(message.toolTrajectoryInFrame2);
    }
    if (message.frame2Name !== "") {
      obj.frame2Name = message.frame2Name;
    }
    if (message.wristTformTool !== undefined) {
      obj.wristTformTool = SE3Pose.toJSON(message.wristTformTool);
    }
    if (message.targetTrajectoryInitialVelocity !== undefined) {
      obj.targetTrajectoryInitialVelocity = message.targetTrajectoryInitialVelocity;
    }
    if (message.maximumAcceleration !== undefined) {
      obj.maximumAcceleration = message.maximumAcceleration;
    }
    if (message.maxLinearVelocity !== undefined) {
      obj.maxLinearVelocity = message.maxLinearVelocity;
    }
    if (message.maxAngularVelocity !== undefined) {
      obj.maxAngularVelocity = message.maxAngularVelocity;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GazeCommand_Request>, I>>(base?: I): GazeCommand_Request {
    return GazeCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GazeCommand_Request>, I>>(object: I): GazeCommand_Request {
    const message = createBaseGazeCommand_Request();
    message.targetTrajectoryInFrame1 =
      (object.targetTrajectoryInFrame1 !== undefined && object.targetTrajectoryInFrame1 !== null)
        ? Vec3Trajectory.fromPartial(object.targetTrajectoryInFrame1)
        : undefined;
    message.frame1Name = object.frame1Name ?? "";
    message.toolTrajectoryInFrame2 =
      (object.toolTrajectoryInFrame2 !== undefined && object.toolTrajectoryInFrame2 !== null)
        ? SE3Trajectory.fromPartial(object.toolTrajectoryInFrame2)
        : undefined;
    message.frame2Name = object.frame2Name ?? "";
    message.wristTformTool = (object.wristTformTool !== undefined && object.wristTformTool !== null)
      ? SE3Pose.fromPartial(object.wristTformTool)
      : undefined;
    message.targetTrajectoryInitialVelocity = object.targetTrajectoryInitialVelocity ?? undefined;
    message.maximumAcceleration = object.maximumAcceleration ?? undefined;
    message.maxLinearVelocity = object.maxLinearVelocity ?? undefined;
    message.maxAngularVelocity = object.maxAngularVelocity ?? undefined;
    return message;
  },
};

function createBaseGazeCommand_Feedback(): GazeCommand_Feedback {
  return {
    status: 0,
    gazingAtTarget: false,
    gazeToTargetRotationMeasured: 0,
    handPositionAtGoal: false,
    handDistanceToGoalMeasured: 0,
    handRollAtGoal: false,
    handRollToTargetRollMeasured: 0,
  };
}

export const GazeCommand_Feedback = {
  encode(message: GazeCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.gazingAtTarget !== false) {
      writer.uint32(16).bool(message.gazingAtTarget);
    }
    if (message.gazeToTargetRotationMeasured !== 0) {
      writer.uint32(45).float(message.gazeToTargetRotationMeasured);
    }
    if (message.handPositionAtGoal !== false) {
      writer.uint32(24).bool(message.handPositionAtGoal);
    }
    if (message.handDistanceToGoalMeasured !== 0) {
      writer.uint32(53).float(message.handDistanceToGoalMeasured);
    }
    if (message.handRollAtGoal !== false) {
      writer.uint32(32).bool(message.handRollAtGoal);
    }
    if (message.handRollToTargetRollMeasured !== 0) {
      writer.uint32(61).float(message.handRollToTargetRollMeasured);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GazeCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGazeCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.gazingAtTarget = reader.bool();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.gazeToTargetRotationMeasured = reader.float();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.handPositionAtGoal = reader.bool();
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.handDistanceToGoalMeasured = reader.float();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.handRollAtGoal = reader.bool();
          continue;
        case 7:
          if (tag !== 61) {
            break;
          }

          message.handRollToTargetRollMeasured = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GazeCommand_Feedback {
    return {
      status: isSet(object.status) ? gazeCommand_Feedback_StatusFromJSON(object.status) : 0,
      gazingAtTarget: isSet(object.gazingAtTarget) ? globalThis.Boolean(object.gazingAtTarget) : false,
      gazeToTargetRotationMeasured: isSet(object.gazeToTargetRotationMeasured)
        ? globalThis.Number(object.gazeToTargetRotationMeasured)
        : 0,
      handPositionAtGoal: isSet(object.handPositionAtGoal) ? globalThis.Boolean(object.handPositionAtGoal) : false,
      handDistanceToGoalMeasured: isSet(object.handDistanceToGoalMeasured)
        ? globalThis.Number(object.handDistanceToGoalMeasured)
        : 0,
      handRollAtGoal: isSet(object.handRollAtGoal) ? globalThis.Boolean(object.handRollAtGoal) : false,
      handRollToTargetRollMeasured: isSet(object.handRollToTargetRollMeasured)
        ? globalThis.Number(object.handRollToTargetRollMeasured)
        : 0,
    };
  },

  toJSON(message: GazeCommand_Feedback): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = gazeCommand_Feedback_StatusToJSON(message.status);
    }
    if (message.gazingAtTarget !== false) {
      obj.gazingAtTarget = message.gazingAtTarget;
    }
    if (message.gazeToTargetRotationMeasured !== 0) {
      obj.gazeToTargetRotationMeasured = message.gazeToTargetRotationMeasured;
    }
    if (message.handPositionAtGoal !== false) {
      obj.handPositionAtGoal = message.handPositionAtGoal;
    }
    if (message.handDistanceToGoalMeasured !== 0) {
      obj.handDistanceToGoalMeasured = message.handDistanceToGoalMeasured;
    }
    if (message.handRollAtGoal !== false) {
      obj.handRollAtGoal = message.handRollAtGoal;
    }
    if (message.handRollToTargetRollMeasured !== 0) {
      obj.handRollToTargetRollMeasured = message.handRollToTargetRollMeasured;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GazeCommand_Feedback>, I>>(base?: I): GazeCommand_Feedback {
    return GazeCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GazeCommand_Feedback>, I>>(object: I): GazeCommand_Feedback {
    const message = createBaseGazeCommand_Feedback();
    message.status = object.status ?? 0;
    message.gazingAtTarget = object.gazingAtTarget ?? false;
    message.gazeToTargetRotationMeasured = object.gazeToTargetRotationMeasured ?? 0;
    message.handPositionAtGoal = object.handPositionAtGoal ?? false;
    message.handDistanceToGoalMeasured = object.handDistanceToGoalMeasured ?? 0;
    message.handRollAtGoal = object.handRollAtGoal ?? false;
    message.handRollToTargetRollMeasured = object.handRollToTargetRollMeasured ?? 0;
    return message;
  },
};

function createBaseArmStopCommand(): ArmStopCommand {
  return {};
}

export const ArmStopCommand = {
  encode(_: ArmStopCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmStopCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmStopCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ArmStopCommand {
    return {};
  },

  toJSON(_: ArmStopCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmStopCommand>, I>>(base?: I): ArmStopCommand {
    return ArmStopCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmStopCommand>, I>>(_: I): ArmStopCommand {
    const message = createBaseArmStopCommand();
    return message;
  },
};

function createBaseArmStopCommand_Request(): ArmStopCommand_Request {
  return {};
}

export const ArmStopCommand_Request = {
  encode(_: ArmStopCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmStopCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmStopCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ArmStopCommand_Request {
    return {};
  },

  toJSON(_: ArmStopCommand_Request): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmStopCommand_Request>, I>>(base?: I): ArmStopCommand_Request {
    return ArmStopCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmStopCommand_Request>, I>>(_: I): ArmStopCommand_Request {
    const message = createBaseArmStopCommand_Request();
    return message;
  },
};

function createBaseArmStopCommand_Feedback(): ArmStopCommand_Feedback {
  return {};
}

export const ArmStopCommand_Feedback = {
  encode(_: ArmStopCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmStopCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmStopCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ArmStopCommand_Feedback {
    return {};
  },

  toJSON(_: ArmStopCommand_Feedback): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmStopCommand_Feedback>, I>>(base?: I): ArmStopCommand_Feedback {
    return ArmStopCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmStopCommand_Feedback>, I>>(_: I): ArmStopCommand_Feedback {
    const message = createBaseArmStopCommand_Feedback();
    return message;
  },
};

function createBaseArmImpedanceCommand(): ArmImpedanceCommand {
  return {};
}

export const ArmImpedanceCommand = {
  encode(_: ArmImpedanceCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmImpedanceCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmImpedanceCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ArmImpedanceCommand {
    return {};
  },

  toJSON(_: ArmImpedanceCommand): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmImpedanceCommand>, I>>(base?: I): ArmImpedanceCommand {
    return ArmImpedanceCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmImpedanceCommand>, I>>(_: I): ArmImpedanceCommand {
    const message = createBaseArmImpedanceCommand();
    return message;
  },
};

function createBaseArmImpedanceCommand_Request(): ArmImpedanceCommand_Request {
  return {
    rootFrameName: "",
    rootTformTask: undefined,
    wristTformTool: undefined,
    taskTformDesiredTool: undefined,
    feedForwardWrenchAtToolInDesiredTool: undefined,
    diagonalStiffnessMatrix: undefined,
    diagonalDampingMatrix: undefined,
    maxForceMag: undefined,
    maxTorqueMag: undefined,
    disableSafetyCheck: undefined,
  };
}

export const ArmImpedanceCommand_Request = {
  encode(message: ArmImpedanceCommand_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rootFrameName !== "") {
      writer.uint32(10).string(message.rootFrameName);
    }
    if (message.rootTformTask !== undefined) {
      SE3Pose.encode(message.rootTformTask, writer.uint32(18).fork()).ldelim();
    }
    if (message.wristTformTool !== undefined) {
      SE3Pose.encode(message.wristTformTool, writer.uint32(26).fork()).ldelim();
    }
    if (message.taskTformDesiredTool !== undefined) {
      SE3Trajectory.encode(message.taskTformDesiredTool, writer.uint32(34).fork()).ldelim();
    }
    if (message.feedForwardWrenchAtToolInDesiredTool !== undefined) {
      Wrench.encode(message.feedForwardWrenchAtToolInDesiredTool, writer.uint32(42).fork()).ldelim();
    }
    if (message.diagonalStiffnessMatrix !== undefined) {
      Vector.encode(message.diagonalStiffnessMatrix, writer.uint32(50).fork()).ldelim();
    }
    if (message.diagonalDampingMatrix !== undefined) {
      Vector.encode(message.diagonalDampingMatrix, writer.uint32(58).fork()).ldelim();
    }
    if (message.maxForceMag !== undefined) {
      DoubleValue.encode({ value: message.maxForceMag! }, writer.uint32(66).fork()).ldelim();
    }
    if (message.maxTorqueMag !== undefined) {
      DoubleValue.encode({ value: message.maxTorqueMag! }, writer.uint32(74).fork()).ldelim();
    }
    if (message.disableSafetyCheck !== undefined) {
      BoolValue.encode({ value: message.disableSafetyCheck! }, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmImpedanceCommand_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmImpedanceCommand_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rootFrameName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.rootTformTask = SE3Pose.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.wristTformTool = SE3Pose.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.taskTformDesiredTool = SE3Trajectory.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.feedForwardWrenchAtToolInDesiredTool = Wrench.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.diagonalStiffnessMatrix = Vector.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.diagonalDampingMatrix = Vector.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.maxForceMag = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.maxTorqueMag = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.disableSafetyCheck = BoolValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmImpedanceCommand_Request {
    return {
      rootFrameName: isSet(object.rootFrameName) ? globalThis.String(object.rootFrameName) : "",
      rootTformTask: isSet(object.rootTformTask) ? SE3Pose.fromJSON(object.rootTformTask) : undefined,
      wristTformTool: isSet(object.wristTformTool) ? SE3Pose.fromJSON(object.wristTformTool) : undefined,
      taskTformDesiredTool: isSet(object.taskTformDesiredTool)
        ? SE3Trajectory.fromJSON(object.taskTformDesiredTool)
        : undefined,
      feedForwardWrenchAtToolInDesiredTool: isSet(object.feedForwardWrenchAtToolInDesiredTool)
        ? Wrench.fromJSON(object.feedForwardWrenchAtToolInDesiredTool)
        : undefined,
      diagonalStiffnessMatrix: isSet(object.diagonalStiffnessMatrix)
        ? Vector.fromJSON(object.diagonalStiffnessMatrix)
        : undefined,
      diagonalDampingMatrix: isSet(object.diagonalDampingMatrix)
        ? Vector.fromJSON(object.diagonalDampingMatrix)
        : undefined,
      maxForceMag: isSet(object.maxForceMag) ? Number(object.maxForceMag) : undefined,
      maxTorqueMag: isSet(object.maxTorqueMag) ? Number(object.maxTorqueMag) : undefined,
      disableSafetyCheck: isSet(object.disableSafetyCheck) ? Boolean(object.disableSafetyCheck) : undefined,
    };
  },

  toJSON(message: ArmImpedanceCommand_Request): unknown {
    const obj: any = {};
    if (message.rootFrameName !== "") {
      obj.rootFrameName = message.rootFrameName;
    }
    if (message.rootTformTask !== undefined) {
      obj.rootTformTask = SE3Pose.toJSON(message.rootTformTask);
    }
    if (message.wristTformTool !== undefined) {
      obj.wristTformTool = SE3Pose.toJSON(message.wristTformTool);
    }
    if (message.taskTformDesiredTool !== undefined) {
      obj.taskTformDesiredTool = SE3Trajectory.toJSON(message.taskTformDesiredTool);
    }
    if (message.feedForwardWrenchAtToolInDesiredTool !== undefined) {
      obj.feedForwardWrenchAtToolInDesiredTool = Wrench.toJSON(message.feedForwardWrenchAtToolInDesiredTool);
    }
    if (message.diagonalStiffnessMatrix !== undefined) {
      obj.diagonalStiffnessMatrix = Vector.toJSON(message.diagonalStiffnessMatrix);
    }
    if (message.diagonalDampingMatrix !== undefined) {
      obj.diagonalDampingMatrix = Vector.toJSON(message.diagonalDampingMatrix);
    }
    if (message.maxForceMag !== undefined) {
      obj.maxForceMag = message.maxForceMag;
    }
    if (message.maxTorqueMag !== undefined) {
      obj.maxTorqueMag = message.maxTorqueMag;
    }
    if (message.disableSafetyCheck !== undefined) {
      obj.disableSafetyCheck = message.disableSafetyCheck;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmImpedanceCommand_Request>, I>>(base?: I): ArmImpedanceCommand_Request {
    return ArmImpedanceCommand_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmImpedanceCommand_Request>, I>>(object: I): ArmImpedanceCommand_Request {
    const message = createBaseArmImpedanceCommand_Request();
    message.rootFrameName = object.rootFrameName ?? "";
    message.rootTformTask = (object.rootTformTask !== undefined && object.rootTformTask !== null)
      ? SE3Pose.fromPartial(object.rootTformTask)
      : undefined;
    message.wristTformTool = (object.wristTformTool !== undefined && object.wristTformTool !== null)
      ? SE3Pose.fromPartial(object.wristTformTool)
      : undefined;
    message.taskTformDesiredTool = (object.taskTformDesiredTool !== undefined && object.taskTformDesiredTool !== null)
      ? SE3Trajectory.fromPartial(object.taskTformDesiredTool)
      : undefined;
    message.feedForwardWrenchAtToolInDesiredTool =
      (object.feedForwardWrenchAtToolInDesiredTool !== undefined &&
          object.feedForwardWrenchAtToolInDesiredTool !== null)
        ? Wrench.fromPartial(object.feedForwardWrenchAtToolInDesiredTool)
        : undefined;
    message.diagonalStiffnessMatrix =
      (object.diagonalStiffnessMatrix !== undefined && object.diagonalStiffnessMatrix !== null)
        ? Vector.fromPartial(object.diagonalStiffnessMatrix)
        : undefined;
    message.diagonalDampingMatrix =
      (object.diagonalDampingMatrix !== undefined && object.diagonalDampingMatrix !== null)
        ? Vector.fromPartial(object.diagonalDampingMatrix)
        : undefined;
    message.maxForceMag = object.maxForceMag ?? undefined;
    message.maxTorqueMag = object.maxTorqueMag ?? undefined;
    message.disableSafetyCheck = object.disableSafetyCheck ?? undefined;
    return message;
  },
};

function createBaseArmImpedanceCommand_Feedback(): ArmImpedanceCommand_Feedback {
  return {
    status: 0,
    transformsSnapshot: undefined,
    commandedWrenchFromStiffnessAtToolInDesiredTool: undefined,
    commandedWrenchFromDampingAtToolInDesiredTool: undefined,
    commandedWrenchFromFeedForwardAtToolInDesiredTool: undefined,
    totalCommandedWrenchAtToolInDesiredTool: undefined,
    totalMeasuredWrenchAtToolInDesiredTool: undefined,
  };
}

export const ArmImpedanceCommand_Feedback = {
  encode(message: ArmImpedanceCommand_Feedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.transformsSnapshot !== undefined) {
      FrameTreeSnapshot.encode(message.transformsSnapshot, writer.uint32(18).fork()).ldelim();
    }
    if (message.commandedWrenchFromStiffnessAtToolInDesiredTool !== undefined) {
      Wrench.encode(message.commandedWrenchFromStiffnessAtToolInDesiredTool, writer.uint32(26).fork()).ldelim();
    }
    if (message.commandedWrenchFromDampingAtToolInDesiredTool !== undefined) {
      Wrench.encode(message.commandedWrenchFromDampingAtToolInDesiredTool, writer.uint32(34).fork()).ldelim();
    }
    if (message.commandedWrenchFromFeedForwardAtToolInDesiredTool !== undefined) {
      Wrench.encode(message.commandedWrenchFromFeedForwardAtToolInDesiredTool, writer.uint32(42).fork()).ldelim();
    }
    if (message.totalCommandedWrenchAtToolInDesiredTool !== undefined) {
      Wrench.encode(message.totalCommandedWrenchAtToolInDesiredTool, writer.uint32(50).fork()).ldelim();
    }
    if (message.totalMeasuredWrenchAtToolInDesiredTool !== undefined) {
      Wrench.encode(message.totalMeasuredWrenchAtToolInDesiredTool, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArmImpedanceCommand_Feedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArmImpedanceCommand_Feedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transformsSnapshot = FrameTreeSnapshot.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.commandedWrenchFromStiffnessAtToolInDesiredTool = Wrench.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.commandedWrenchFromDampingAtToolInDesiredTool = Wrench.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.commandedWrenchFromFeedForwardAtToolInDesiredTool = Wrench.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.totalCommandedWrenchAtToolInDesiredTool = Wrench.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.totalMeasuredWrenchAtToolInDesiredTool = Wrench.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArmImpedanceCommand_Feedback {
    return {
      status: isSet(object.status) ? armImpedanceCommand_Feedback_StatusFromJSON(object.status) : 0,
      transformsSnapshot: isSet(object.transformsSnapshot)
        ? FrameTreeSnapshot.fromJSON(object.transformsSnapshot)
        : undefined,
      commandedWrenchFromStiffnessAtToolInDesiredTool: isSet(object.commandedWrenchFromStiffnessAtToolInDesiredTool)
        ? Wrench.fromJSON(object.commandedWrenchFromStiffnessAtToolInDesiredTool)
        : undefined,
      commandedWrenchFromDampingAtToolInDesiredTool: isSet(object.commandedWrenchFromDampingAtToolInDesiredTool)
        ? Wrench.fromJSON(object.commandedWrenchFromDampingAtToolInDesiredTool)
        : undefined,
      commandedWrenchFromFeedForwardAtToolInDesiredTool: isSet(object.commandedWrenchFromFeedForwardAtToolInDesiredTool)
        ? Wrench.fromJSON(object.commandedWrenchFromFeedForwardAtToolInDesiredTool)
        : undefined,
      totalCommandedWrenchAtToolInDesiredTool: isSet(object.totalCommandedWrenchAtToolInDesiredTool)
        ? Wrench.fromJSON(object.totalCommandedWrenchAtToolInDesiredTool)
        : undefined,
      totalMeasuredWrenchAtToolInDesiredTool: isSet(object.totalMeasuredWrenchAtToolInDesiredTool)
        ? Wrench.fromJSON(object.totalMeasuredWrenchAtToolInDesiredTool)
        : undefined,
    };
  },

  toJSON(message: ArmImpedanceCommand_Feedback): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = armImpedanceCommand_Feedback_StatusToJSON(message.status);
    }
    if (message.transformsSnapshot !== undefined) {
      obj.transformsSnapshot = FrameTreeSnapshot.toJSON(message.transformsSnapshot);
    }
    if (message.commandedWrenchFromStiffnessAtToolInDesiredTool !== undefined) {
      obj.commandedWrenchFromStiffnessAtToolInDesiredTool = Wrench.toJSON(
        message.commandedWrenchFromStiffnessAtToolInDesiredTool,
      );
    }
    if (message.commandedWrenchFromDampingAtToolInDesiredTool !== undefined) {
      obj.commandedWrenchFromDampingAtToolInDesiredTool = Wrench.toJSON(
        message.commandedWrenchFromDampingAtToolInDesiredTool,
      );
    }
    if (message.commandedWrenchFromFeedForwardAtToolInDesiredTool !== undefined) {
      obj.commandedWrenchFromFeedForwardAtToolInDesiredTool = Wrench.toJSON(
        message.commandedWrenchFromFeedForwardAtToolInDesiredTool,
      );
    }
    if (message.totalCommandedWrenchAtToolInDesiredTool !== undefined) {
      obj.totalCommandedWrenchAtToolInDesiredTool = Wrench.toJSON(message.totalCommandedWrenchAtToolInDesiredTool);
    }
    if (message.totalMeasuredWrenchAtToolInDesiredTool !== undefined) {
      obj.totalMeasuredWrenchAtToolInDesiredTool = Wrench.toJSON(message.totalMeasuredWrenchAtToolInDesiredTool);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArmImpedanceCommand_Feedback>, I>>(base?: I): ArmImpedanceCommand_Feedback {
    return ArmImpedanceCommand_Feedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArmImpedanceCommand_Feedback>, I>>(object: I): ArmImpedanceCommand_Feedback {
    const message = createBaseArmImpedanceCommand_Feedback();
    message.status = object.status ?? 0;
    message.transformsSnapshot = (object.transformsSnapshot !== undefined && object.transformsSnapshot !== null)
      ? FrameTreeSnapshot.fromPartial(object.transformsSnapshot)
      : undefined;
    message.commandedWrenchFromStiffnessAtToolInDesiredTool =
      (object.commandedWrenchFromStiffnessAtToolInDesiredTool !== undefined &&
          object.commandedWrenchFromStiffnessAtToolInDesiredTool !== null)
        ? Wrench.fromPartial(object.commandedWrenchFromStiffnessAtToolInDesiredTool)
        : undefined;
    message.commandedWrenchFromDampingAtToolInDesiredTool =
      (object.commandedWrenchFromDampingAtToolInDesiredTool !== undefined &&
          object.commandedWrenchFromDampingAtToolInDesiredTool !== null)
        ? Wrench.fromPartial(object.commandedWrenchFromDampingAtToolInDesiredTool)
        : undefined;
    message.commandedWrenchFromFeedForwardAtToolInDesiredTool =
      (object.commandedWrenchFromFeedForwardAtToolInDesiredTool !== undefined &&
          object.commandedWrenchFromFeedForwardAtToolInDesiredTool !== null)
        ? Wrench.fromPartial(object.commandedWrenchFromFeedForwardAtToolInDesiredTool)
        : undefined;
    message.totalCommandedWrenchAtToolInDesiredTool =
      (object.totalCommandedWrenchAtToolInDesiredTool !== undefined &&
          object.totalCommandedWrenchAtToolInDesiredTool !== null)
        ? Wrench.fromPartial(object.totalCommandedWrenchAtToolInDesiredTool)
        : undefined;
    message.totalMeasuredWrenchAtToolInDesiredTool =
      (object.totalMeasuredWrenchAtToolInDesiredTool !== undefined &&
          object.totalMeasuredWrenchAtToolInDesiredTool !== null)
        ? Wrench.fromPartial(object.totalMeasuredWrenchAtToolInDesiredTool)
        : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
