import { PropsWithChildren, ReactElement, memo } from 'react';
import ReactCollapsible, { CollapsibleProps } from 'react-collapsible';

import { Icon } from 'components/common/index';

import { SCollapsibleContainer } from './Collapsible.styled';

type TCollapsibleProps = PropsWithChildren &
  CollapsibleProps & {
    trigger: ReactElement<any> | string;
  };

const Collapsible = memo<TCollapsibleProps>(({ trigger, children, ...rest }) => (
  <SCollapsibleContainer>
    <ReactCollapsible
      trigger={
        <>
          {trigger}
          <Icon icon="arrow_left" transform="rotate(-90deg)" />
        </>
      }
      {...rest}
    >
      {children}
    </ReactCollapsible>
  </SCollapsibleContainer>
));

export default Collapsible;
