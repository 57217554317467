import { FC, PropsWithChildren } from 'react';

import { withAsyncState } from 'components/common/HOC/with-async-state/withAsyncState';

import RoleTypes from 'lib/constants/role-types';

import withRole from './withRole';

const RolesLayer: FC<PropsWithChildren> = ({ children }) => {
  return children;
};

export default withAsyncState(withRole(RoleTypes.BASIC)(RolesLayer));
