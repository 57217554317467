import { IotHubModule, sendDataToModule } from '../util';
import { SpotControlModuleAction } from './types';

type DefaultProps = {
  iotHubDeviceName: string;
};

type ClearFaultsPayload = {
  id: number;
};

type ClearFaultProps = DefaultProps & {
  payload: ClearFaultsPayload;
};

export const sendClearBehaviorFault = async ({ iotHubDeviceName, payload }: ClearFaultProps) => {
  return await sendDataToModule({
    iotHubDeviceName,
    iotHubModule: IotHubModule.SPOT_CONTROL_MODULE,
    actionName: SpotControlModuleAction.CLEAR_BEHAVIOR_FAULT,
    payload,
  });
};
